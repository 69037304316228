import audio_placeholder from "../assets/audio_placeholder.png";

const ChannelMediaGroupView = ({ media, setView, openFullView }) => {
  return (
    <div style={{ margin: "1em 0 3em", overflow: "auto" }}>
      {media.map((mediaItem, key) => (
        <div className="media-tray-container" key={key}>
          <div>
            <h4 className="media-tray-header">
              {mediaItem.section_name.toUpperCase()}
              <span style={{ color: "black" }}> {`(${mediaItem.count})`}</span>
            </h4>
          </div>

          <div className="media-tray">
            <div className="media-gallery">
              {mediaItem.fileData.slice(0, 3).map((image, key) => (
                <div
                  key={key}
                  onClick={() => openFullView(image)}
                  className="media-thumbnail-container"
                >
                  <img
                    src={
                      mediaItem.section_name.toLowerCase() === "audios"
                        ? audio_placeholder
                        : image.attachment_small_url
                    }
                    className="media-thumbnail"
                  />
                </div>
              ))}
            </div>
            {mediaItem.count > 3 && (
              <div
                style={{ margin: "0 4px", cursor: "pointer" }}
                onClick={() =>
                  setView(
                    mediaItem.mediaTypes,
                    mediaItem.section_name.toUpperCase()
                  )
                }
              >{`>`}</div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChannelMediaGroupView;
