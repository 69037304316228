import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router";
import send from "../../assets/Icons/Icon feather-arrow-left@2x.png";
import { getDocId, getHeaders } from "../../utils/utils";
import CommentInput from "./CommentInput";
import CommentsList from "./CommentsList";
import { Modal } from "react-responsive-modal";
import { useEffect, useState } from "react";
import MciUpload from "../../common/MciUpload";
import close from "../../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import MciVerificationStatus from "../../common/MciVerificationStatus";
import useFeedDetails from "../hooks/useFeedDetails";
import MciUploadSuccess from "../../common/MciUploadSuccess";

const MobileComments = ({
  profile_pic,
  feeds,
  setFeeds,
  user_verification_info,
}) => {
  const history = useHistory();
  const { feed_id, channel_id } = useParams();
  // let feed = {};
  // for (const item of feeds) {
  //   if (item.feed_info.feed_id === parseInt(feed_id)) {
  //     feed = item;
  //     break;
  //   }
  // }

  const { feed, setFeed } = useFeedDetails(feed_id, channel_id);

  const closeIcon = <img src={close}></img>;

  const [uploadModel, setUploadModel] = useState(false);
  const openUploadMci = () => setUploadModel(true);
  const closeUploadMci = () => setUploadModel(false);

  const [uploadMciSuccess, setUploadMciSuccess] = useState(false);
  const openUploadMciSuccess = () => setUploadMciSuccess(true);
  const closeUploadMciSuccess = () => setUploadMciSuccess(false);

  const [verificationStatusModal, setVerificaitonStatusModal] = useState(false);
  const openVerificationStatusModal = () => setVerificaitonStatusModal(true);
  const closeVerificationStatusModal = () => setVerificaitonStatusModal(false);

  const doc_id = getDocId();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const verifyUser = () => {
    if (!user_verification_info.isCommunityVerified) {
      if (user_verification_info.is_user_verified == 1) {
        openUploadMci();
        return false;
      }
      if (user_verification_info.is_user_verified == 2) {
        openVerificationStatusModal();
        return false;
      }
    }
    return true;
  };

  const deleteComment = async (feed_id, channel_id, social_interaction_id) => {
    const payload = {
      user_id: doc_id,
      channel_id: channel_id,
      feed_id,
      social_interaction_id,
    };
    const res = await fetch(baseUrl + "v1/feeds/comment/delete", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();

    if (data.status === "success") {
      setFeed((prevFeed) => ({
        ...prevFeed,
        feed_info: {
          ...prevFeed.feed_info,
          socialInteraction: data.data.socialInteraction,
        },
      }));
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed.feed_info.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    } else toast.error(data.errorMsg);
  };

  const updateComment = async (
    feed_id,
    channel_id,
    social_interaction_id,
    comment,
    attachments = []
  ) => {
    if (!verifyUser()) return;

    if (attachments.length === 0 && comment === "") {
      toast.error("cannot post empty comments");
      return;
    }

    let payload = JSON.stringify({
      user_id: doc_id,
      channel_id,
      feed_id,
      social_interaction_id,
      comment: comment,
      attachment_details: attachments,
    });

    payload = payload.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });

    payload = encodeURIComponent(payload);

    const res = await fetch(baseUrl + "v1/feeds/comment/update", {
      method: "POST",
      headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();

    if (data.status === "success") {
      setFeed((prevFeed) => ({
        ...prevFeed,
        feed_info: {
          ...prevFeed.feed_info,
          socialInteraction: data.data.socialInteraction,
        },
      }));
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed?.feed_info?.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    } else toast.error(data.errorMsg);
  };

  const addComment = async (feed_id, channel_id, comment, attachments = []) => {
    if (!verifyUser()) return;

    if (attachments.length === 0 && comment === "") {
      toast.error("Cannot post empty comment");
      return;
    }

    let payload = JSON.stringify({
      doc_id: doc_id,
      channel_id,
      feedTypeId: feed_id,
      socialInteraction: {
        type: "Comment",
        comment,
        attachment_details: attachments,
      },
    });

    payload = payload.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });

    payload = encodeURIComponent(payload);

    const res = await fetch(baseUrl + "v1/feedSocialInteractions", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    if (data.status === "success") {
      setFeed((prevFeed) => ({
        ...prevFeed,
        feed_info: {
          ...prevFeed.feed_info,
          socialInteraction: data.data.socialInteraction,
        },
      }));
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed.feed_info.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    } else toast.error(data.errorMsg);
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "white",
          zIndex: 20,
          // overflow: "auto",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            top: "0",
            left: "0",
            width: "100%",
            zIndex: "25",
            flexShrink: "0",
          }}
          className="mobile-header-wrapper sticky d-block d-lg-none">
          <div className="mobile-header position-relative ">
            <div style={{ display: "flex" }}>
              <div className="brand-logo ">
                <img
                  onClick={() => history.goBack()}
                  src={send}
                  style={{ width: "25px", marginTop: "-4px" }}
                  alt="timeline logo"
                />
              </div>

              <div className="pull-left">
                <p style={{ marginLeft: "10px" }}>
                  Comment &nbsp;
                  <span style={{ color: "green" }}>
                    {`(${feed?.feed_info?.socialInteraction?.commentsCount} Comments)`}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div style={{ overflowY: "auto" }}>
          <CommentsList
            feed={feed}
            deleteComment={deleteComment}
            updateComment={updateComment}
          />
        </div>
        <div
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            bottom: "0",
            right: "0",
            width: "100%",
            flexShrink: "0",
            background: "white",
            zIndex: "5",
            height: "100px",
          }}
          className="footer-area responsive-footer d-block d-lg-none"
        >
          <hr></hr>
          <div
            className="post-meta"
            style={{
              padding: "0",
              margin: "0",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CommentInput
              profile_pic={profile_pic}
              addComment={addComment}
              feed_id={feed?.feed_info?.feed_id}
              channel_id={channel_id}
              isFullFeedView={false}
            />
          </div>
        </div>
      </div>
      <Modal
        open={uploadModel}
        onClose={closeUploadMci}
        classNames={{ modal: "uploadMci" }}
        closeIcon={closeIcon}
      >
        <MciUpload
          closeUploadMci={closeUploadMci}
          openSuccessModal={openUploadMciSuccess}
        />
      </Modal>

      <Modal
        closeIcon={closeIcon}
        open={uploadMciSuccess}
        onClose={closeUploadMciSuccess}
        classNames={{ modal: "verificationModel" }}
      >
        <MciUploadSuccess closeUploadMciSuccess={closeUploadMciSuccess} />
      </Modal>

      <Modal
        open={verificationStatusModal}
        onClose={closeVerificationStatusModal}
        classNames={{ modal: "verificationModel" }}
        closeIcon={closeIcon}
      >
        <MciVerificationStatus
          is_user_verified={user_verification_info.is_user_verified}
          closeVerificationStatusModal={closeVerificationStatusModal}
        />
      </Modal>
    </>
  );
};

export default MobileComments;
