import { useState, useLayoutEffect, useRef, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import likeUnselected from "../../assets/dashboard_icons/social-icons/Like.svg";
import likeSelected from "../../assets/dashboard_icons/selected/Like.svg";
import commentUnselected from "../../assets/dashboard_icons/social-icons/Comment.svg";
import shareUnselected from "../../assets/dashboard_icons/social-icons/Share.svg";
import "../css/dashboard.css";
import FeedContent from "./FeedContent";
import {
  getDate,
  getDocId,
  getFeedTypeColor,
  getTime,
  getTimeAndDate,
  showUnicodeChars,
} from "../../utils/utils";
import FeedAttachments from "./FeedAttachments";
import CommentInput from "../social-interaction/CommentInput";
import ReactAudioPlayer from "react-audio-player";
import getWindowDimensions from "../../common/hooks/getWindowDimensions";
import Progressbar from "../../progressbar";
// import { FacebookShareButton } from "react-share";
// import { FacebookIcon } from "react-share";
const Feed = ({
  feed,
  profile_pic,
  updateLikeCount,
  addComment,
  openFullView,
  commentsListInfo,
  openLikesModal,
  submitSurvey,
  channels,
  registerWebinar,
  user_verification_info,
}) => {
  const doc_id = parseInt(getDocId());
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const [loaderSpinner, setloaderSpinner] = useState(true);
  const location = useLocation();
  const attachmentVideoRef = useRef(null);
  const attachmentAudioRef = useRef(null);
  const [displayViewMore, setDisplayViewMore] = useState(false);
  const commentDiv = useRef(null);
  const [feedLabelColor, setFeedLabelColor] = useState("#888686");

  useLayoutEffect(() => {
    commentDiv.current && shouldDisplayViewMore(commentDiv.current);
    const feedLabelColor = getFeedTypeColor(feedType);
    setFeedLabelColor(feedLabelColor);
    // getFeedLabelColor();
    //eslint-disable-next-line
  }, [feed?.feed_info?.socialInteraction?.comment_info?.comment_text]);

  const getChannel = (channel_id) => {
    const channel = channels.filter(
      (channel) => channel.channel_id === channel_id
    );
    return channel[0];
  };

  // const shareUrl =
  //   "https://www.linkedin.com/shareArticle?url=http%3A%2F%2Fgithub.com&mini=true";
  const shouldDisplayViewMore = ({ clientHeight, scrollHeight }) => {
    if (scrollHeight > clientHeight + 6) setDisplayViewMore(true);
    else setDisplayViewMore(false);
  };

  const history = useHistory();

  const channel = getChannel(feed.channel_id);

  const profile_pic_placeholder =
    "assets/images/profile/src_assets_images_Avatar Icon_30x30.png";

  // const profileUsername = (string) => {
  //   if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  //   var count = 35;
  //   var result = string.slice(0, count) + (string.length > count ? "..." : "");
  // };
  const userText = (string) => {
    var count = 30;
    if (string)
      return (
        string.charAt(0).toUpperCase() +
        string.slice(1, 30) +
        (string.length > count ? "..." : "")
      );
  };

  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getFeedContext = () => {
    if (location?.pathname.includes("/profile")) return "profile";
    if (location?.pathname.includes("/professional")) return "professional";
    if (location?.pathname.includes("/knowledge")) return "knowledge";
    if (location?.pathname.includes("/community")) return "community";
    else return "dashboard";
  };

  const feedContext = getFeedContext();

  const feedCreatorProfilePic =
    channel?.feed_provider_type === "Community" ||
    channel?.feed_provider_type === "Content"
      ? channel?.channel_logo === ""
        ? profile_pic_placeholder
        : channel?.channel_logo
      : feed?.post_creator?.profile_url &&
        feed?.post_creator?.profile_url !== ""
      ? feed?.post_creator?.profile_url + `?${Date.now()}`
      : profile_pic_placeholder;

  const feedCreator =
    channel?.feed_provider_type === "Community" ||
    channel?.feed_provider_type === "Content"
      ? channel?.feed_provider_name
      : feed?.post_creator.posted_by
      ? feed?.post_creator?.doc_id === doc_id
        ? "You"
        : feed?.post_creator?.posted_by
      : feed?.feed_info?.copyright;

  const feedCommentUserName = `${
    feed?.feed_info?.socialInteraction?.comment_info?.user_info?.doc_id ===
    doc_id
      ? "You"
      : feed?.feed_info?.socialInteraction?.comment_info?.user_info?.fname
  }`;

  const feedCommentProfilePic =
    feed.feed_info?.socialInteraction?.comment_info?.user_info &&
    feed.feed_info?.socialInteraction.comment_info.user_info.profile_url !== ""
      ? feed.feed_info?.socialInteraction?.comment_info.user_info.profile_url +
        `?${Date.now()}`
      : profile_pic_placeholder;

  const feedType =
    feed?.feed_info?.feed_type?.toUpperCase() === "ARTICLE"
      ? feed.feed_info.article_type.toUpperCase()
      : feed?.feed_info?.display_tag?.toUpperCase();

  const postingDate = new Date(feed.feed_info.posting_time);
  const today = new Date();

  const feedDate = feed.feed_info.posting_time
    ? today.toDateString() === postingDate.toDateString()
      ? getTime(postingDate)
      : getDate(postingDate)
    : "";

  return (
    <>
      <div className="feed-card">
        <div className="feed-header">
          <img
            className="feed-header-image"
            alt="profile pic"
            src={feedCreatorProfilePic}
          />
          <div className="feed-info-container">
            <div className="feed-poster-container">
              <Link
                className="feed-poster"
                style={{ color: "#333333" }}
                to={
                  channel?.feed_provider_type === "Community" ||
                  channel?.feed_provider_type === "Content"
                    ? "#"
                    : feed.post_creator.posted_by
                    ? `/profile/${feed.post_creator.doc_id}`
                    : "#"
                }
              >
                {userText(feedCreator)}
              </Link>
            </div>
            <div style={{ display: "flex ", marginTop: "3px" }}>
              <div
                className="dashboard-feed-type"
                style={{ backgroundColor: feedLabelColor }}
              >
                {feedType}
              </div>
              <div className="feed-date">{feedDate}</div>
            </div>
          </div>
        </div>
        {feed.feed_info.feed_type === "SURVEY" ||
        feed.feed_info.feed_type === "EVENT" ? (
          <>
            <div className="feed-content">
              <FeedContent
                feed={feed.feed_info}
                submitSurvey={submitSurvey}
                channel_id={feed.channel_id}
                attachmentVideoRef={attachmentVideoRef}
                attachmentAudioRef={attachmentAudioRef}
                openFullView={() => {
                  if (attachmentVideoRef.current)
                    attachmentVideoRef.current.pause();
                  if (attachmentAudioRef.current)
                    attachmentAudioRef.current.audioEl.current.pause();
                  if (windowDimensions.width <= 768)
                    history.push({
                      pathname: `/feed/${feed.feed_info.feed_id}/${feed.channel_id}`,
                      state: {
                        background: location,
                        profile_pic: profile_pic,
                        channels: channels,
                        user_verification_info: user_verification_info,
                        feedContext: feedContext,
                      },
                    });
                  else openFullView(feed);
                }}
                registerWebinar={registerWebinar}
              />
            </div>
          </>
        ) : (
          <>
            <div
              className="feed-content"
              onClick={() => {
                if (attachmentVideoRef.current)
                  attachmentVideoRef.current.pause();
                if (attachmentAudioRef.current)
                  attachmentAudioRef.current.audioEl.current.pause();
                if (windowDimensions.width <= 768)
                  history.push({
                    pathname: `/feed/${feed.feed_info.feed_id}/${feed.channel_id}`,
                    state: {
                      background: location,
                      profile_pic: profile_pic,
                      channels: channels,
                      user_verification_info: user_verification_info,
                      feedContext: feedContext,
                    },
                  });
                else openFullView(feed);
              }}
            >
              <div className="feed-title">
                {feed.feed_info?.feed_type === "Job Postings" && (
                  <div>
                    {" "}
                    JOB OPENING: {feed.feed_info.title.slice(0, 110) + " "}
                    with {feed.feed_info?.organization}
                  </div>
                )}
                {feed.feed_info?.feed_type !== "Job Postings" && (
                  <div> {feed.feed_info.title.slice(0, 110) + " "}</div>
                )}

                {feed.feed_info.title.length > 110 && (
                  <span
                    style={{
                      color: "#1daf76",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    ...Read More
                  </span>
                )}
              </div>
              <FeedContent
                feed={feed.feed_info}
                channel_id={feed.channel_id}
                registerWebinar={registerWebinar}
              />
            </div>
            <div
              onClick={() => {
                if (attachmentVideoRef.current)
                  attachmentVideoRef.current.pause();
                if (attachmentAudioRef.current)
                  attachmentAudioRef.current.audioEl.current.pause();
                if (windowDimensions.width <= 768)
                  history.push({
                    pathname: `/feed/${feed.feed_info.feed_id}/${feed.channel_id}`,
                    state: {
                      background: location,
                      profile_pic: profile_pic,
                      channels: channels,
                      user_verification_info: user_verification_info,
                      feedContext: feedContext,
                    },
                  });
                else openFullView(feed);
              }}
            >
              <FeedAttachments
                videoRef={attachmentVideoRef}
                audioRef={attachmentAudioRef}
                attachments={feed.feed_info.attachment_details}
              />
            </div>
          </>
        )}
        <div className="social-information">
          <div
            className="likes"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (windowDimensions.width <= 768)
                history.push({
                  pathname: `/likes/${feed.feed_info.feed_id}/${feed.channel_id}`,
                  state: { background: location },
                });
              else openLikesModal(feed);
            }}
          >
            <p className="people-like" style={{ cursor: "pointer" }}>
              {feed.feed_info?.socialInteraction?.isLike
                ? feed.feed_info?.socialInteraction?.likesCount === 1
                  ? `You like this`
                  : `You and ${
                      feed.feed_info?.socialInteraction?.likesCount - 1
                    }  people like this `
                : feed.feed_info?.socialInteraction?.likesCount === 0
                ? null
                : `${feed.feed_info?.socialInteraction?.likesCount} people like this `}
            </p>
          </div>
          <div className="views">
            {feed.feed_info?.socialInteraction?.viewCount !== 0 && (
              <p className="people-like">
                {feed.feed_info?.socialInteraction?.viewCount}
                {feed.feed_info?.socialInteraction?.viewCount === 1
                  ? " view"
                  : " views"}
              </p>
            )}
          </div>
        </div>
        <hr style={{ margin: 0 }}></hr>
        <div className="social-interaction">
          <div className="commentButton d-none d-lg-block">
            <div style={{ display: "flex" }}>
              {feed.feed_info?.socialInteraction?.isLike ? (
                <img
                  style={{ cursor: "pointer" }}
                  src={likeSelected}
                  onClick={() =>
                    updateLikeCount(
                      feed.feed_info?.feed_id,
                      feed.channel_id,
                      false
                    )
                  }
                  alt="Like"
                  className="social-icons"
                />
              ) : (
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    updateLikeCount(
                      feed.feed_info.feed_id,
                      feed.channel_id,
                      true
                    )
                  }
                  src={likeUnselected}
                  className="social-icons"
                  alt="Like"
                />
              )}
              <span className="interaction-like">
                {feed.feed_info?.socialInteraction?.likesCount === 0
                  ? null
                  : feed.feed_info?.socialInteraction?.likesCount}
              </span>
              <span className="interaction-like">
                Like
                {feed.feed_info?.socialInteraction?.likesCount > 1 ? "s" : null}
              </span>
            </div>
          </div>
          <div className="commentButton d-none d-lg-block">
            <div
              style={{ display: "flex" }}
              onClick={() => {
                if (windowDimensions.width <= 768)
                  history.push({
                    pathname: `/feed/${feed.feed_info.feed_id}/${feed.channel_id}`,
                    state: {
                      background: location,
                      profile_pic: profile_pic,
                      channels: channels,
                      user_verification_info: user_verification_info,
                      feedContext: feedContext,
                    },
                  });
                else openFullView(feed);
              }}
            >
              <span>
                <img
                  src={commentUnselected}
                  alt="Comment"
                  className="social-icons"
                />
              </span>
              <span className="interaction-comment">
                {feed?.feed_info?.socialInteraction?.commentsCount === 0
                  ? null
                  : feed?.feed_info?.socialInteraction?.commentsCount}
              </span>
              <span className="interaction-comment">
                Comment
                {feed.feed_info?.socialInteraction?.commentsCount > 1
                  ? "s"
                  : null}
              </span>
            </div>
          </div>
          <div className=" commentButton d-none d-lg-block">
            {/* <div style={{ display: "flex" }}>
              <span>
                <img
                  src={shareUnselected}
                  alt="share"
                  className="social-icons"
                />
              </span>
              <span className="interaction-share">
                {feed?.feed_info?.socialInteraction.shareCount === 0
                  ? null
                  : feed?.feed_info?.socialInteraction.shareCount}
              </span>
              <span className="interaction-share">
                {/* <FacebookIcon url={shareUrl}>
                  {shareCount => <span className="interaction-share">{shareCount}Share11</span>}
                </FacebookIcon> 
                {feed.feed_info.socialInteraction.shareCount > 1 ? "s" : null}
              </span>
            </div> */}
          </div>
        </div>
        {/* start Mobile responsive social-interaction */}
        <div className="social-interaction ">
          <div className=" d-block d-lg-none">
            {feed.feed_info?.socialInteraction?.isLike ? (
              <img
                src={likeSelected}
                onClick={() =>
                  updateLikeCount(
                    feed.feed_info.feed_id,
                    feed.channel_id,
                    false
                  )
                }
                className="social-icons-mobile"
                alt="Like"
              />
            ) : (
              <img
                onClick={() =>
                  updateLikeCount(feed.feed_info.feed_id, feed.channel_id, true)
                }
                src={likeUnselected}
                alt="Like"
                className="social-icons-mobile"
              />
            )}
            <span
              className="interaction-like-mobile"
              // onClick={() => openLikesModal(feed)}
            >
              {feed.feed_info?.socialInteraction?.likesCount === 0
                ? null
                : feed.feed_info?.socialInteraction?.likesCount}
            </span>
            <span
              style={{ marginLeft: "1px" }}
              className="interaction-like-mobile"
            >
              Like
              {feed.feed_info?.socialInteraction?.likesCount > 1 ? "s" : null}
            </span>
          </div>

          <div
            className="interaction-comment-mobile d-block d-lg-none"
            onClick={() => {
              if (windowDimensions.width <= 768)
                history.push({
                  pathname: `/comments/${feed.feed_info.feed_id}/${feed.channel_id}`,
                  state: {
                    background: location,
                    profile_pic: profile_pic,
                    user_verification_info: user_verification_info,
                    feedContext: feedContext,
                  },
                });
              else openFullView(feed);
            }}
          >
            <img
              src={commentUnselected}
              alt="Comments"
              style={{ width: "14px" }}
              className="social-icons-mobile"
            />
            <span style={{ marginRight: "1px", fontSize: "13px" }}>
              {feed?.feed_info?.socialInteraction?.commentsCount === 0
                ? null
                : feed?.feed_info?.socialInteraction?.commentsCount}
            </span>
            Comment
            {feed.feed_info?.socialInteraction?.commentsCount > 1 ? "s" : null}
          </div>
          <div className="interaction-comment-mobile d-block d-lg-none">
            {/* <img
              src={shareUnselected}
              alt="Share"
              className="social-icons-mobile"
            />{" "}
            <span style={{ marginRight: "1px", fontSize: "13px" }}>
              {feed?.feed_info?.socialInteraction.shareCount === 0
                ? null
                : feed?.feed_info?.socialInteraction.shareCount}
            </span>
            Share */}
            {/* {feed.feed_info.socialInteraction.shareCount > 1 ? "s" : null} */}
          </div>
        </div>
        {/* end Mobile responsive social-interaction */}

        {/* start Desktop social-interaction */}
        <hr style={{ margin: 0 }}></hr>
        <CommentInput
          profile_pic={profile_pic}
          addComment={addComment}
          feed_id={feed.feed_info.feed_id}
          channel_id={feed.channel_id}
        />
        {feed?.feed_info?.socialInteraction?.commentsCount === 0 ||
        (feed?.feed_info?.socialInteraction?.comment_info &&
          Object.keys(feed?.feed_info?.socialInteraction?.comment_info)
            .length === 0 &&
          Object.getPrototypeOf(
            feed?.feed_info?.socialInteraction?.comment_info
          ) === Object.prototype) ? null : (
          <div className="comment">
            <img
              className="comment-image"
              alt="profile pic"
              src={feedCommentProfilePic}
            />
            <div className="comment-info-container">
              <div className="comment-poster">
                <Link
                  style={{ color: "black" }}
                  to={`/profile/${feed?.feed_info?.socialInteraction?.comment_info?.user_info?.doc_id}`}
                >
                  {feedCommentUserName}
                </Link>
              </div>
              <div className="comment-date">
                {getTimeAndDate(
                  feed?.feed_info?.socialInteraction?.comment_info.timestamp
                )}
              </div>
              <div
                className="comment-text"
                ref={commentDiv}
                style={{ position: "relative" }}
              >
                {showUnicodeChars(
                  feed.feed_info?.socialInteraction?.comment_info?.comment_text
                )}
                {displayViewMore && (
                  <span
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      backgroundColor: "#f1f1f1",
                      zIndex: "3",
                      fontWeight: "bold",
                    }}
                  >
                    ....
                  </span>
                )}
              </div>
              {feed.feed_info?.socialInteraction?.comment_info
                .attachment_details &&
                feed.feed_info?.socialInteraction?.comment_info
                  ?.attachment_details.length !== 0 && (
                  <div className="comment-attachments">
                    {feed.feed_info?.socialInteraction?.comment_info?.attachment_details.map(
                      (attachment, index) => (
                        <span key={index}>
                          {attachment.attachment_type === "image" && (
                            <img
                              className="attachment"
                              style={{ height: "100%" }}
                              alt="attachment"
                              src={attachment.attachment_original_url}
                            />
                          )}
                          {attachment.attachment_type === "video" && (
                            <video
                              src={attachment.attachment_original_url}
                              className="attachment"
                              style={{ height: "100%" }}
                              controls="controls"
                            />
                          )}
                          {attachment.attachment_type === "audio" && (
                            <ReactAudioPlayer
                              style={{ backgroundColor: "whitesmoke" }}
                              controls
                              style={{ width: "100%" }}
                              src={attachment.attachment_original_url}
                            />
                          )}
                        </span>
                      )
                    )}
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Feed;
