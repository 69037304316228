import { useState, useEffect, useRef, useCallback } from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import React from "react";
import Progressbar from "../progressbar";
import { Link, useParams, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { BsExclamation } from "react-icons/bs";

const DeletePublications = ({
  getUserData,
  closeDeletePubModal,
  deletedData
}) => {

  const { type } = useParams();
  //console.log("data"+setCategoryListInfo)

  const history = useHistory();
  const user_id = getDocId();
  const headers = getHeaders();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const deletePublcation = async () => {
    let pub={
    }
    let payload = {
      user_id: user_id,
    };
    if (deletedData.pub_type == "print") {
      pub.print_pub_id=deletedData.print_pub_id;
      payload.print_pub_history = [pub];
    } else if (deletedData.pub_type == "online") {
      pub.online_pub_id=deletedData.online_pub_id
      payload.online_pub_history = [pub];
    }
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/delete", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      toast.success('Publication deleted');
      getUserData();
      closeDeletePubModal();
    } else {
      toast.error(data.errorMsg);
    }
  };


  return (
    <>
{/*       
      <Modal
        open={openPostSuccessView}
        blockScroll={true}
        closeIcon={closeIconHide}
        closeModal={hideSuccessView}
        classNames={{ modal: "postSuccess" }}
      > */}
      {/* <div className="container-fluid">
          <div
            className="row"
            style={{ backgroundColor: "#1e8a79", height: "281px" }}
          >
            <div
              className="col-sm-12 "
              style={{ marginTop: "30px", marginBottom: "20px" }}
            >
              <div
                                      style={{
                                        position: "absolute",
                                        height: "40px",
                                      }}
                                    >
                                      <BsExclamation
                                        style={iconBackgroundStyle}
                                        size={25}
                                      />
                                    </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "white",
                  font: "18px/20px roboto",
                  marginBottom: "20px",
                }}
              >
                Post has been created successfully.
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "white",
                  marginBottom: "30px",
                  font: "15px/20px roboto",
                }}
              >
                {" "}
                <div>Thank you for your contribution to the</div>
                <p>medical community</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              <button
                onClick={() => closeDeletePubModal()}
                type="button"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "5px",
                  margin: "auto",
                  width: "25%",
                }}
                className="submit-btn"
              >
                Close
              </button>
            </div>
          </div>
        </div> */}
         <div className="container-fluid">
          <div
            className="row"
            style={{marginTop:"25px" }}
          >
            
            <div
              className="col-sm-12 "
            >
                                      <BsExclamation
                                        style={iconBackgroundStyle}
                                        size={65}
                                      />
            </div>
            <div className="col-sm-12 " style={{marginTop:'35px'}}>
              <div
                style={{
                  color: "black",
                  marginBottom: "10px",
                  font: "16px/16px roboto",
                  fontWeight:"600px"
                }}
              >
                {" "}
                <p>Are you sure want to delete this?</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "grey",
                  marginBottom: "10px",
                  font: "16px/16px roboto",
                  fontWeight:"600px"
                }}
              >
                {" "}
                <p>This information will no longer visible in your profile </p>
              </div>
            </div>
            <div className="col-sm-12 " >
              
              <div style={{ margin: "30px 30px" }}>         
          
          <button type="submit" className="pub-save-button" onClick={() => deletePublcation()} style={{width:"7em",height:"2.5em"}}>
            Ok 
          </button>
          <button type="submit" className="pub-close-button"  onClick={closeDeletePubModal} style={{width:"7em",height:"2.5em"}}>
            Cancel
          </button>
        </div>
            </div>
          </div>
        </div> 
      {/* </Modal>  */}
    </>
  );
};

export default DeletePublications;
const iconBackgroundStyle = {
  backgroundColor: "rgb(30 138 121)",
  borderRadius: "50%",
  padding: "3px",
  color: "white",
};
