import { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { getHeaders } from "../utils/utils";

const usePostsLoader = (
  doc_id,
  other_user_id,
  nextFeedsData,
  setFeeds,
  reloadFeeds = false
) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  // const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  // const [feeds, setFeeds] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [channels, setChannels] = useState([]);

  const getDashBoardData = async () => {
    setLoading(true);
    setError(false);

    const payload = {
      user_id: doc_id,
      otherUserId: other_user_id,
      last_feed_id: nextFeedsData.lastFeedId,
    };

    const res = await fetch(baseUrl + "v1/feeds/user", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {
      setLoading(false);
      setError(true);
      setHasMore(false);
      // history.push("/login");
    });

    const data = await res.json();

    if (data.status === "success") {
      if (payload.last_feed_id === 0) setFeeds([...data.data.feed_data]);
      else
        setFeeds((prevFeeds) => {
          return [...prevFeeds, ...data.data.feed_data];
        });
      if (data.data.listofChannels)
        setChannels((prev) => {
          return [...prev, ...data.data.listofChannels];
        });
      setLoading(false);
      setHasMore(data.data.feed_data.length > 0);
    } else {
      setLoading(false);
      setError(true);
      setHasMore(false);
      // history.push("/login");
    }
  };

  useEffect(() => {
    getDashBoardData();
    // eslint-disable-next-line
  }, [nextFeedsData, reloadFeeds]);

  return { loading, error, channels, hasMore };
};

export default usePostsLoader;
