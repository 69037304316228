import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import toast from "react-hot-toast";
import logo1 from "../../assets/mobile-logo.png";
import S3 from "react-aws-s3";
import Progressbar from "../../progressbar";
import bgImage from "../../assets/pre_login_bg.png";
import "../css/signup.css";
import logo from "../../assets/logo192.png";

import ProfessionalInfo from "./ProfessionalInfo";

const MandatoryInfoScreen = ({ submitMandatoryInfo, isSpecialityEntered }) => {
  const url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const [spinner, setSpinner] = useState(false);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "100%",
          backgroundColor: "#1e8a79",
          padding: "0.5em",
        }}
      >
        <div>
          <img
            style={{
              width: "40px",
              height: "40px",
              float: "right",
            }}
            src={logo}
            alt="home logo"
          />
        </div>
        <div className="mandatoryInfoPopUpContainer">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <div className="signup-container" style={{}}>
              <h1>Welcome to WhiteCoats</h1>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <div className="signup-container">
              <h4 className="header-tag" style={{ margin: "10px 0px 15px" }}>
                India's leading professional success platform for doctors
              </h4>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="signup-container" style={{ textAlign: "center" }}>
              <h5
                className="header-tag"
                style={{ margin: "10px 0px 15px", width: "100%" }}
              >
                To get started, please fill in the information below to help us
                personalize your experience
              </h5>
            </div>
          </div>
          {/* <div class="row">
                  <div class="col-sm-12">
                    <div
                      style={{ border: "1px solid white", borderRadius: "5px" }}
                    />
                  </div>
                </div> */}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "80%" }}>
              <ProfessionalInfo
                submitMandatoryInfo={submitMandatoryInfo}
                isSpecialityEntered={isSpecialityEntered}
                // professionalInfo={professionalInfo}
                // auth_token={userAuthenticationDetails.auth_token}
              />
            </div>
          </div>
        </div>
        <Progressbar
          show={spinner}
          imageUrl={url}
          height="90"
          width="90"
          alignment="middle"
          alttext="Loading..."
        />
      </div>
    </>
  );
};

export default MandatoryInfoScreen;
