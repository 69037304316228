import { useState, useEffect, useRef, useCallback } from "react";
import { config, getDocId, getHeaders } from "../../utils/utils";
import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { useForm, Controller } from "react-hook-form";
import profile from "../../assets/profile_icons/default-profile.png";
import toast from "react-hot-toast";
import Select from "react-select";
import { MdEdit } from "react-icons/md";
import "../../profile/profile.css";
import { Modal } from "react-responsive-modal";
import { IoArrowBackOutline } from "react-icons/io5";
import close from "../../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import confirm from "../../assets/profile_icons/confirm.svg";
import edit from "../../assets/profile_icons/ic_cam.svg";
import Progressbar from "../../progressbar";
const MobilePersonal = (userInfo) => {
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  
  // console.log('%cPersonalInfo.js line:15 object', 'color: #007acc;', props);
  const [userData, setUserData] = useState();
  const profilePicInput = useRef(null);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const closeIcon = <img src={close} />;
  const {
    handleSubmit,
    register,
    control,
    reset,
    setValue: setFormValue,
    clearErrors,
    formState: { errors },
  } = useForm();
  const user_id = getDocId();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const doc_id = getDocId();
  const headers = getHeaders();
  const [specialities, setSpecialities] = useState();

  useEffect(() => {
    getSpecialties();
  }, []);
  const [cities, setCities] = useState([]);
  const [yearsOfExperience, setYearsOfExperience] = useState();
  const fileInput = useRef(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState("");
  const [imgPreviewType, setImgPreviewType] = useState("");

  useEffect(() => {
    let experience = [];
    experience.push({ value: 0, label: "0-1 years" });
  for (let i = 1; i <= 70; i++) {
      experience.push({ value: i, label: `${i} years` });
    }
    setYearsOfExperience(experience);
   
    getOriginalUser();

  }, []);

  useEffect(() => {    document.body.style.overflow = "hidden";    return () => {      document.body.style.overflow = "unset";    };  }, []);
  const getOriginalUser = async () => {
    const payload = {
      user_id,
    };
    const res = await fetch(baseUrl + "v1/userProfile/view", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();

    if (data.status === "success") {
      setUserData(data.data);
      setFormValue("fname", data?.data?.fname);
      setFormValue("lname", data?.data?.lname);
      setFormValue("salutation", data?.data?.user_salutation);
      setFormValue("speciality", {
        label: data?.data?.specialists,
        value: data?.data?.specialists,
      });
      // setFormValue("experience", {
      //   label: `${data?.data?.experience} years`,
      //   value: data?.data?.experience,
      // });


      setFormValue("specific_ask", data?.data?.socialInfo[0]?.specific_ask);
      setFormValue("subSpeciality", data?.data?.subSpeciality);
      setFormValue("about_me", data?.data?.socialInfo[0]?.about_me);
      console.log('%cMobilePersonal.js line:93 object', 'color: #007acc;', data?.data?.socialInfo[0]?.about_me);

      // setFormValue("speciality", {
      //   label:data?.data?.specialists,
      //   value: data?.data?.specialists,
      // });
      setFormValue("experience", {
        label: `${data?.data?.experience} years`,
        value: data?.data?.experience,
      });

    }
  };

  const showImagePreview = () => {
    let type = fileInput.current.files[0].type;
    if (type.includes("image")) type = "image";
    else if (type.includes("video")) type = "video";
    else if (type.includes("audio")) type = "audio";
    else if (type.includes("pdf")) type = "pdf";

    if (type === "image" || type === "pdf") {
      setIsFileUploaded(true);
      setImgPreviewType(type);
      setImgPreviewUrl(URL.createObjectURL(fileInput.current.files[0]));
    } else {
      toast.error("Only images and pdfs are allowed");
    }
  };
  const getSpecialties = async () => {
    const res = await fetch(baseUrl + "v1/getSpecialties", {
      method: "POST",
      headers,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let specialities = [];
    if (data.status === "success") {
      for (const speciality of data.data.specialities) {
        specialities.push({ value: speciality, label: speciality });
      }
    }
    setSpecialities(specialities);
  };
 



  const editProfilePic = () => {
    profilePicInput.current.value = "";
    profilePicInput.current.click();
  };

  const uploadImage = () => {
    let type = profilePicInput.current.files[0].type;
    if (type.includes("image")) type = "image";
    else if (type.includes("video")) type = "video";
    else if (type.includes("audio")) type = "audio";
    else if (type.includes("pdf")) type = "pdf";

    if (type === "image") {
      //   uploadProfilePic();
      setImgPreviewUrl(URL.createObjectURL(profilePicInput.current.files[0]));
      
    } else {
      toast.error("Please upload an image");
    }
  };
  const uploadProfilePic = async(formData) => {
    // e.preventDefault();
    const reqFormData = new FormData();
    let modifiedHeaders = { ...headers };
    let speciality=formData.speciality?.value;
    delete modifiedHeaders["Content-Type"];
    let image = profilePicInput.current.files[0];
    const specArray=[];
    specArray.push(speciality);

    let requestData={
      isUpdate:true,
      user_id:doc_id,
      user_first_name:formData.fname?.trim(),
      user_last_name:formData.lname?.trim(),
      user_salutation:formData.salutation?.trim(),
      user_type_id:userData?.user_type_id,
      subSpeciality:formData.subSpeciality?.trim(),
      specialities:Array.isArray(speciality)?speciality:specArray,
      specialists:userData?.specialists,
      experience:parseInt(formData.experience?.value),
      profile_pic_original_url: userData?.profile_pic_original_url,
      profile_pic_small_url:userData?.profile_pic_small_url,
      socialInfo: [
          {
            about_me: formData.about_me,
            specific_ask: formData.specific_ask,
            // blog_pg: userData?.blog_pg,
            // website_pg: userData?.website_pg,
            // fb_pg: userData?.fb_pg,
            // linkedin_pg:userData?.linkedin_pg,
            // twitter_pg:userData?.twitter_pg,
            // instagram_pg: userData?.instagram_pg,
            // pdf_url: userData?.pdf_url,



            blog_pg: userData?.socialInfo[0]?.blog_pg,
            website_pg: userData?.socialInfo[0]?.website_pg,
            fb_pg: userData?.socialInfo[0]?.fb_pg,
            linkedin_pg:userData?.socialInfo[0]?.linkedin_pg,
            twitter_pg:userData?.socialInfo[0]?.twitter_pg,
            instagram_pg: userData?.socialInfo[0]?.instagram_pg,
            pdf_url: userData?.socialInfo[0]?.pdf_url,
          }]
      }

    reqFormData.append("reqData", JSON.stringify(requestData));
     if (profilePicInput.current) reqFormData.append("file", image);
    // if(image == undefined){
    //   reqFormData.append("profile_pic_original_url", userData?.profile_pic_original_url);
    //   reqFormData.append("profile_pic_small_url", userData?.profile_pic_small_url);
    //   // const originalImage=userData?.profile_pic_original_url;
    //   // const nameSplit=originalImage.split("/");
    //   // const  duplicateName=nameSplit.pop();
    //   // reqFormData.append("file", duplicateName);
    // //  profile_pic_original_url=userData?.profile_pic_original_url;
    // }
    
    setloaderSpinner(true);
    const res = await fetch(baseUrl + "v1/userProfile/edit", {
      method: "POST",
      headers: modifiedHeaders,
      body: reqFormData
    }).catch((error) => {
      console.error(error);
       setloaderSpinner(false);
      if (navigator.onLine) {
        toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      setloaderSpinner(false);
      onOpenModal();
      // toast.success("Personal Info Updated successfully");
      
      getOriginalUser();
      reset();
    } else toast.error("Error Personal Info");
  };
 
  // const handleCloseUploadMci = () => {
  //   setUploadModel(false);
  // };
  return (
    <>

      <div style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "white",
          zIndex: 10,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100vw",
        }}>
        <div
          style={{
            position: "-webkit-sticky",
            position: "relative",
            top: "0",
            left: "0",
            width: "100%",
            zIndex: "25",
            flexShrink: "0",
            marginBottom: "10px",
          }}
          className="mobile-header-wrapper sticky d-block d-lg-none"
        >
          <div
            className="mobile-header position-relative "
            style={{
              backgroundColor: "#469b8d",
              font: "bold 20px / 20px roboto",
              textAlign: "center",
            }}
          >
            <div className="mobile-responsive">
              <div className="brand-logo " style={{ width: "106%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0 1em",
                  }}
                >
                  <Link to="/profile" >
                  <IoArrowBackOutline
                    color="white"
                    size={25}
                    // onClick={() =>history.replace("/profile")}
                   
                  />
                  </Link>
                  <h2 style={{ color: "white", marginLeft: "1.5em" }}>
                    Personal Info
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="personalInfo_container_mobile">
          <form >
            <div className="forms_container">

              {/* <div className="conatiner">
                <div className="personal-info" >
                 

                    {imgPreviewUrl == "" ? <img src={
                      userData?.profile_pic_original_url &&
                        userData?.profile_pic_original_url !== ""
                        ? userData.profile_pic_original_url
                        : profile
                    } className="img-responsive profile-photo" style={{ width: '130px', borderRadius: '50%', height: '130px' }} /> :
                      <div className="profile-pic" style={{ border: '1px solid black', borderRadius: '50%' }}>
                        <img src={
                          imgPreviewUrl
                        } className="img-responsive profile-photo" style={{ borderRadius: '50%', width: '130px', height: '130px'}} />
                      </div>}
                    <div
                      style={{
                        marginTop: "60px"
                      }}
                      onClick={editProfilePic}
                    >
                      <MdEdit
                        style={iconBackgroundStyle}
                        size={25}
                      />
                      <input
                        type="file"
                        accept=",jpg, .jpeg, .png"
                        onChange={uploadImage}
                        ref={profilePicInput}
                        style={{ display: "none" }}
                      />
                    </div>
                </div>

              </div> */}





              <div className="profile_pic_container">
                              
                              <div className="conatiner">
                                  <div className="profile_container">
                                  {imgPreviewUrl=="" ? <img src={
                                      userData?.profile_pic_original_url &&
                                      userData?.profile_pic_original_url !== ""
                                          ? userData.profile_pic_original_url
                                          : profile
                                  } className="img-responsive profile-photo" style={{width:'130px' , borderRadius:'50%', height: '130px'}} />:
                                  <div className="profile-pic" style={{borderRadius:'50%'}}>
                                  <img src={
                                      imgPreviewUrl
                                  } className="img-responsive profile-photo" style={{borderRadius:'50%',width:'130px',    height: '130px',}} />
                                  </div>}
                                       <div
                                    style={{
                                    marginLeft:'105px',
                                    marginTop:'-35px',
                                      cursor:"pointer"
                                    }}
                                    onClick={editProfilePic}
                                  >
                                    {/* <MdEdit
                                      style={iconBackgroundStyle}
                                      size={25}
                                    /> */}
                                    <img src={edit} style={{width: '50%',background: '#469b8d', padding: '3px',borderRadius: '15%'}}/>
                                    <input
                                      type="file"
                                      accept=",jpg, .jpeg, .png"
                                      onChange={uploadImage}
                                      ref={profilePicInput}
                                      style={{ display: "none" }}
                                    />
                                  </div>

                                  </div>

                              </div>
                          </div>

              <div className="row">
                <div className="col-sm-2"></div>
                <div className="col-sm-8">
                  <div className="container" style={{ marginTop: "30px", padding: 0 }}>
                    <div className="signup-name-row">
                      <select
                        {...register("salutation", { required: true })}
                        className="dr-dropdown"
                        style={{
                          backgroundColor: "#ffffff",
                          border: "1px solid gray",
                          height: "100%",
                          width: "60px",
                        }}
                      >
                        <option value="Dr.">Dr.</option>
                        <option value="Mr.">Mr.</option>
                        <option value="Mrs.">Mrs</option>
                        <option value="Ms.">Ms</option>
                      </select>
                      <div
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "#ffffff",
                          border: "1px solid gray",
                          height: "3.5em",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "100%",
                            paddingLeft: '5px'
                          }}
                        >


                          <input
                            style={{
                              // marginTop: "1em",
                              // paddingLeft: "5px",
                              background: "inherit",
                              width: "100%",
                              border: "none",
                            }}
                            type="text"
                            {...register("fname", {
                              required: true,
                              minLength: 1,
                              maxLength: 30,
                              pattern: /^\s*[A-za-z]+\s*$/,
                            })}
                            placeholder="Enter First Name *"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2"></div>
              </div>
              <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-7">
                  <div
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#ffffff",
                      border: "1px solid gray",
                      height: "3em",
                      width: "77%",
                      float:'right',
                      marginTop: "10px"
                    }}
                    className="signup-lastname">
                    <div
                      // className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      {/* <img src={person} alt="" style={{ padding: "1em" }} /> */}

                      <input
                        style={{
                          background: "inherit",
                          width: "100%",
                          border: "none",
                          paddingLeft: '5px'
                        }}
                        type="text"
                        {...register("lname", {
                          required: true,
                          minLength: 1,
                          maxLength: 30,
                          pattern: /^\s*[A-za-z]+\s*$/,
                        })}
                        placeholder="Enter Last Name *"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-1"></div>
              </div>

              {errors.fname?.type === "pattern" && (
                <div className="row error-title">
                  <span className="error-title-text">
                    Please don't use numbers, special characters or spaces
                  </span>
                </div>
              )}
              {errors.fname?.type === "required" && (
                <div className="row error-title">
                  <span className="error-title-text">
                    {" "}
                    Please enter your first name
                  </span>
                </div>
              )}
              {errors.fname?.type === "maxLength" && (
                <div className="row error-title">
                  <span className="error-title-text">max length is 30</span>
                </div>
              )}
              {errors.fname?.type === "minLength" && (
                <div className="row error-title">
                  <span className="error-title-text">min length is 1</span>
                </div>
              )}
              {errors.lname?.type === "pattern" && (
                <div className="row error-title">
                  <span className="error-title-text">
                    Please don't use numbers, special characters or spaces
                  </span>
                </div>
              )}
              {errors.lname?.type === "required" && (
                <div className="row error-title">
                  <span className="error-title-text">
                    {" "}
                    Please enter your last name
                  </span>
                </div>
              )}
              {errors.lname?.type === "maxLength" && (
                <div className="row error-title">
                  <span className="error-title-text">max length is 30</span>
                </div>
              )}
              {errors.lname?.type === "minLength" && (
                <div className="row error-title">
                  <span className="error-title-text">min length is 1</span>
                </div>
              )}

              <div
                style={{
                  borderRadius: "5px",
                  background: "#ffffff",
                  border: "1px solid gray",
                  height: "3em",
                  width: "100%",
                  margin: "1em 0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >

                  <Controller
                    name="speciality"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="Enter Speciality *"
                        options={specialities}
                        styles={{
                          control: (style) => ({
                            ...style,
                            background: "inherit",
                            border: 0,
                            boxShadow: "none",
                            padding: "5px 10px 10px 0",
                          }),
                          container: (style) => ({
                            ...style,
                            width: "100%",
                          }),
                          placeholder: (style) => ({
                            ...style,
                            fontSize: "14px",
                          }),
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              {errors.speciality && (
                <div className="row error-title">
                  <span className="error-title-text">
                    Please enter your speciality
                  </span>
                </div>
              )}
               <div className="row">
                <div className="col-sm-2"></div>
                <div className="col-sm-8">
                  <div
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#ffffff",
                      border: "1px solid gray",
                      height: "3em",
                      width: "100%",
                      marginTop: "0px"
                    }}
                    className="signup-lastname">
                    <div
                      // className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      {/* <img src={person} alt="" style={{ padding: "1em" }} /> */}

                      <input
                        style={{
                          background: "inherit",
                          width: "100%",
                          border: "none",
                          paddingLeft: '5px'
                        }}
                        type="text"
                        {...register("subSpeciality", {

                         
                         
                        })}
                        placeholder="Enter Sub-specility"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-2"></div>
              </div>
              
              <div
                className="row "
                style={{
                  marginTop: "1em",

                  height: "3em",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "5px",
                  border: "1px solid gray",
                  width: '100%',
                  marginLeft: '1px'
                }}
              >
                <div
                  className="col-12"
                  style={{ paddingLeft: "0px" }}
                >
                  <Controller
                    name="experience"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={yearsOfExperience}
                        placeholder="Years of Experience *"
                        styles={{
                          control: (style) => ({
                            ...style,
                            background: "inherit",
                            width: "100%",
                            border: 0,
                            boxShadow: "none",
                          }),
                          input: (style) => ({
                            ...style,
                            paddingLeft: "0px",
                          }),
                          container: (style) => ({
                            ...style,
                            marginTop: "0.45em",
                            marginLeft: "-5px",
                          }),
                        }}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
              {errors.experience?.type === "required" && (
                <div className="row error-title">
                  <span className="error-title-text">
                    Please enter your experience
                  </span>
                </div>
              )}
              {(errors.experience?.type === "max" ||
                errors.experience?.type === "min") && (
                  <div className="row error-title">
                    <span className="error-title-text">
                      Please enter value between 0-70
                    </span>
                  </div>
                )}
             
                          <div className="row">
                <div className="col-sm-2"></div>
                <div className="col-sm-8">
                  <div
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#ffffff",
                      border: "1px solid gray",
                      height: "4.5em",
                      width: "100%",
                      marginTop: "10px"
                    }}
                    className="signup-lastname">
                    <div
                      // className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      {/* <img src={person} alt="" style={{ padding: "1em" }} /> */}

                      <textarea
                        style={{
                          background: "inherit",
                          width: "100%",
                          border: "none",
                          padding: '8px'
                        }}
                        type="text"
                        {...register("about_me", {
                          
                         
                          maxLength: 256,
                         
                        })}
                        placeholder="One Line USP"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-2"></div>
              </div>
              {errors.about_me?.type === "maxLength" && (
                            <div className="row error-title">
                                <span className="error-title-text">max length is 256</span>
                            </div>
                        )}
                        
              <div className="row">
                <div className="col-sm-2"></div>
                <div className="col-sm-8">
                  <div
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#ffffff",
                      border: "1px solid gray",
                      height: "4.5em",
                      width: "100%",
                      marginTop: "10px"
                    }}
                    className="signup-lastname">
                    <div
                      // className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      {/* <img src={person} alt="" style={{ padding: "1em" }} /> */}

                      <textarea
                        style={{
                          background: "inherit",
                          width: "100%",
                          border: "none",
                          padding: '8px'
                        }}
                        type="text"
                        {...register("specific_ask", {
                                       
                          maxLength: 160,
                          pattern: {
                              value: /^[a-zA-Z ]+$/,
                              message: "invalid Specific Ask",
                          },
                      })}
                      placeholder="Specific Ask"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-2"></div>
              </div>
              {errors.specific_ask?.type === "maxLength" && (
                            <div className="row error-title">
                                <span className="error-title-text">max length is 160</span>
                            </div>
                        )}
                         {errors.specific_ask?.type === "pattern" && (
                            <div className="row error-title">
                                <span className="error-title-text">
                                    Please don't use numbers, special characters 
                                </span>
                            </div>
                        )}
            </div>
            <div>
          
          <Progressbar
            show={loaderSpinner}
            imageUrl={url}
            height="90"
            width="90"
            alignment="middle"
            alttext="Loading..."
          />
                        </div>  
            <div className="profile-save" style={{marginTop:'30px'}}>
                <button type="submit" className="mobile-profile-save" onClick={handleSubmit(uploadProfilePic)} style={{marginBottom:'10px'}}>
                  Save
                </button>

              </div>
          </form>
          
        </div>
        

        <Modal open={open} onClose={onCloseModal} center closeIcon={closeIcon} classNames={{ modal: "profileModel" }}>
         

        <div className="profile-footer-conatiner" style={{ padding: '20px 0px' }}>
        <img src={ confirm} className="img-responsive profile-photo" style={{ width: '70px', border: '1px solid black', borderRadius: '50%', height: '70px' }} />
          </div>

          <div className="profile-footer-conatiner" style={{ padding: '20px 0px' }}>
            <h3 style={{ color: "#00a5a5", fontWeight:'500',fontSize:'20px'}}>Profile Information Saved</h3>
          </div>

          <div className="profile-footer-conatiner">
            <button type="submit" onClick={onCloseModal} className="profile-close-button" style={{marginRight:'40px',borderColor:'grey'}}>
              Close
            </button>
          </div>
        </Modal>

      </div>
    </>
  );
};

export default MobilePersonal;
const iconBackgroundStyle = {
  backgroundColor: "rgb(30 138 121)",
  borderRadius: "50%",
  padding: "3px",
  color: "white",
};
