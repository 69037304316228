import { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { MdArrowBackIos, MdEdit, MdDeleteForever } from "react-icons/md";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import aoiBg from "../assets/new_profile_icons/area_of_interest.svg";
import { getDocId, getHeaders } from "../utils/utils";
import { IoIosCloseCircle } from "react-icons/io";
import { Modal } from "react-responsive-modal";

const MobileAreaOfInterests = () => {
  const user_id = getDocId();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const history = useHistory();

  const [userInfo, setUserInfo] = useState({});
  const firstRender = useRef(true);

  const { id } = useParams();

  const [deleteData, setDeleteData] = useState({});

  const [verifyUpdate, setVerifyUpdate] = useState(false);
  const closeVerifyUpdate = () => setVerifyUpdate(false);
  const openVerifyUpdate = () => setVerifyUpdate(true);

  const [verifyDelete, setVerifyDelete] = useState(false);
  const closeVerifyDelete = () => setVerifyDelete(false);
  const openVerifyDelete = () => setVerifyDelete(true);

  const [isEdit, setIsEdit] = useState(false);
  const [interestId, setInterestId] = useState(null);

  const scrollToId = useRef(null);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
  });

  const aoiField = watch("aoi");
  const isAoiNew = () => {
    const [res] = userInfo.areas_of_interest.filter(
      (interest) => interest.interest_id === interestId
    );
    return res.area_of_interest !== aoiField;
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      if (
        userInfo.areas_of_interest &&
        userInfo?.areas_of_interest?.length !== 0
      )
        if (id) {
          setEditValue(parseInt(id));
          firstRender.current = false;
        }
    }
  }, [userInfo]);

  useEffect(() => {
    if (scrollToId.current) {
      document.getElementById(`aoi${scrollToId.current}`).scrollIntoView();
      scrollToId.current = null;
    }
  }, [userInfo]);

  const setEditValue = (id) => {
    const [res] = userInfo?.areas_of_interest?.filter(
      (interest) => interest.interest_id === id
    );
    if (res) {
      setValue("aoi", res.area_of_interest);
      setIsEdit(true);
      setInterestId(id);
    }
  };

  const unSetEditValues = () => {
    reset({
      aoi: "",
    });
    setIsEdit(false);
    setInterestId(null);
  };

  const getUserInfo = async () => {
    const url = "v1/userProfile/view";

    const payload = {
      user_id,
    };

    const res = await fetch(baseUrl + url, {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {
      console.error(e);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
    });
    const data = await res.json();

    if (data.status === "success") {
      setUserInfo(data.data);
    } else {
      toast.error(data.errorMsg);
    }
  };

  const addInterests = async (formData) => {
    if (userInfo?.areas_of_interest?.length >= 20) {
      toast("Reached max limit");
      return;
    }

    if (formData.aoi.trim() === "") {
      toast("Please enter area of interest");
      return;
    }
    const payload = {
      user_id,
      areas_of_interest: [{ area_of_interest: formData.aoi.trim() }],
    };

    const res = await fetch(baseUrl + "v1/userProfile/create", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();
    console.log(data);

    if (data.status === "success") {
      scrollToId.current = data.data.areas_of_interest[0].interest_id;
      setUserInfo((prev) => ({
        ...prev,
        areas_of_interest: [
          ...prev.areas_of_interest,
          ...data.data.areas_of_interest,
        ],
      }));
      unSetEditValues();
      toast.success("Area Of Interest added");
    } else {
      let error = data.errorMsg;
      if (data.errorMsg === "Duplicate Entry")
        error = "Area of Interest already exists";
      toast.error(error);
    }
  };

  const editInterests = async (formData) => {
    if (formData.aoi.trim() === "") {
      toast("Please enter area of interest");
      return;
    }
    const payload = {
      user_id,
      areas_of_interest: [
        {
          interest_id: interestId,
          area_of_interest: formData.aoi.trim(),
        },
      ],
    };

    const res = await fetch(baseUrl + "v1/userProfile/update", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();
    console.log(data);

    if (data.status === "success") {
      setUserInfo((prev) => ({
        ...prev,
        areas_of_interest: prev.areas_of_interest.map((interest) => {
          if (interest.interest_id === interestId)
            return data.data.areas_of_interest[0];
          else return interest;
        }),
      }));
      closeVerifyUpdate();
      unSetEditValues();
      toast.success("Area Of Interest updated");
    } else {
      let error = data.errorMsg;
      if (data.errorMsg === "Duplicate Entry")
        error = "Area of Interest already exists";
      toast.error(error);
    }
  };

  const deleteInterests = async (id) => {
    console.log(id);

    const payload = {
      user_id,
      areas_of_interest: [{ interest_id: id }],
    };

    const res = await fetch(baseUrl + "v1/userProfile/delete", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();
    console.log(data);

    if (data.status === "success") {
      setUserInfo((prev) => ({
        ...prev,
        areas_of_interest: prev.areas_of_interest.filter(
          (interest) => interest.interest_id !== id
        ),
      }));
      closeVerifyDelete();
      toast.success("Area Of Interest deleted");
    } else toast.error(data.errorMsg);
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "white",
        zIndex: 10,
        // overflow: "auto",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100vw",
      }}
    >
      <div
        style={{
          backgroundColor: "rgb(30 138 121)",
          display: "flex",
          color: "white",
          padding: "1em",
          alignItems: "center",
        }}
      >
        <MdArrowBackIos
          style={{ color: "white" }}
          onClick={() => history.replace("/profile")}
          size={32}
        />
        Area Of Interest
      </div>
      {/* <div style={{ overflowY: "auto", flex: 1 }}>Mobile Area of interests</div> */}

      {userInfo.areas_of_interest &&
      userInfo?.areas_of_interest?.length !== 0 ? (
        <div style={{ overflowY: "auto", flex: 1 }}>
          {userInfo.areas_of_interest.map((interest) => (
            <>
              <div
                id={`aoi${interest.interest_id}`}
                style={{
                  padding: "1em",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    fontSize: "1.5rem",
                    width: "70%",
                  }}
                >
                  {interest.area_of_interest}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "30%",
                    justifyContent: "space-between",
                  }}
                >
                  <MdDeleteForever
                    onClick={() => {
                      if (interest.interest_id !== interestId) {
                        setDeleteData({
                          interest_id: interest.interest_id,
                          interest: interest.area_of_interest,
                        });
                        openVerifyDelete();
                        // deleteInterests(interest.interest_id)
                      }
                    }}
                    style={{
                      color:
                        interest.interest_id === interestId
                          ? "lightgray"
                          : "red",
                    }}
                    size={23}
                  />

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MdEdit
                      style={{ color: "rgb(30 138 121)" }}
                      size={18}
                      onClick={() => setEditValue(interest.interest_id)}
                    />
                    <span
                      style={{
                        color: "rgb(30 138 121)",
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        marginLeft: "1.3em",
                      }}
                    >
                      {interest.interest_id === interestId ? "Editing" : "Edit"}
                    </span>
                  </div>
                </div>
              </div>

              <hr style={{ margin: 0 }} />
            </>
          ))}
        </div>
      ) : (
        <div
          style={{
            overflowY: "auto",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={aoiBg} style={{ width: "10em" }} />
          <div
            style={{
              padding: "1em",
              textAlign: "center",
              color: "#1e8a79",
              lineHeight: "4.5rem",
              fontSize: "2rem",
            }}
          >
            Add your area of interest like <br /> - Heart Survey <br /> - Brain
            Surgery <br /> - Neurology
          </div>
        </div>
      )}
      <div
        style={{
          marginTop: "0.6em",
          padding: "1em",
          boxShadow: "0 -3px 5px rgb(0 0 0 / 0.2)",
        }}
      >
        <div style={inputDivStyle}>
          <input
            style={inputStyle}
            type="text"
            {...register("aoi", {
              required: true,
              maxLength: 25,
              pattern: /^[a-zA-Z\s]*$/,
            })}
            placeholder="Area of Interest"
            maxLength="100"
          />
          {isEdit && (
            <IoIosCloseCircle
              style={{
                cursor: "pointer",
                color: "darkgray",
              }}
              size={25}
              onClick={unSetEditValues}
            />
          )}
        </div>
        {errors.aoi?.type === "maxLength" && (
          <div style={errorMsg}>Please enter less that 25 characters</div>
        )}
        {errors.aoi?.type === "pattern" && (
          <div style={errorMsg}>Only letters allowed</div>
        )}

        {isEdit ? (
          <button
            type="button"
            style={{
              backgroundColor: isValid && isAoiNew() ? "#469b8d" : "dimgray",
              width: "100%",
              borderRadius: "30px",
              padding: "0.8em 0",
              color: "white",
              fontSize: "1.8rem",
            }}
            // onClick={handleSubmit(editInterests)}
            onClick={() => {
              if (isValid && isAoiNew()) openVerifyUpdate();
            }}
          >
            Update Interest
          </button>
        ) : (
          <button
            type="button"
            style={{
              backgroundColor: isDirty && isValid ? "#469b8d" : "dimgray",
              width: "100%",
              borderRadius: "30px",
              padding: "0.8em 0",
              color: "white",
              fontSize: "1.8rem",
            }}
            onClick={handleSubmit(addInterests)}
          >
            Add Interest
          </button>
        )}
      </div>

      <Modal
        open={verifyUpdate}
        onClose={closeVerifyUpdate}
        showCloseIcon={false}
        backdrop="static"
        center
        classNames={{
          modal: "otpModal",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          onClick={() => closeVerifyUpdate()}
        >
          <IoIosCloseCircle
            style={{
              cursor: "pointer",
              color: "lightgrey",
            }}
            size={25}
          />
        </div>

        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "black",
            fontWeight: "bold",
            marginTop: "1em",
          }}
        >
          Update Interest
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "1.8rem",
            color: "black",
            margin: "1em 1em",
          }}
        >
          Are you sure you want to update your interest?
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "3em",
            marginBottom: "1em",
          }}
        >
          <button
            style={{
              backgroundColor: "white",
              padding: "1em",
              borderRadius: "20px",
              fontSize: "1.2rem",
              width: "45%",
              border: "1px solid gray",
              color: "gray",
            }}
            onClick={() => closeVerifyUpdate()}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: "#469b8d",
              padding: "1em",
              borderRadius: "20px",
              fontSize: "1.2rem",
              width: "45%",
              // border: "1px solid gray",
              color: "white",
            }}
            onClick={handleSubmit(editInterests)}
          >
            Update
          </button>
        </div>
      </Modal>

      <Modal
        open={verifyDelete}
        onClose={closeVerifyDelete}
        showCloseIcon={false}
        backdrop="static"
        center
        classNames={{
          modal: "otpModal",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          onClick={() => closeVerifyDelete()}
        >
          <IoIosCloseCircle
            style={{
              cursor: "pointer",
              color: "lightgrey",
            }}
            size={25}
          />
        </div>

        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "black",
            fontWeight: "bold",
            marginTop: "1em",
          }}
        >
          Delete Interest
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "1.8rem",
            color: "black",
            margin: "1em 1.4em",
          }}
        >
          Do you want to delete{" "}
          <span style={{ fontWeight: "bold" }}>{deleteData.interest} </span>
          entry?
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "3em",
            marginBottom: "1em",
          }}
        >
          <button
            style={{
              backgroundColor: "white",
              padding: "1em",
              borderRadius: "20px",
              fontSize: "1.2rem",
              width: "45%",
              border: "1px solid gray",
              color: "gray",
            }}
            onClick={() => closeVerifyDelete()}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: "red",
              padding: "1em",
              borderRadius: "20px",
              fontSize: "1.2rem",
              width: "45%",
              // border: "1px solid gray",
              color: "white",
            }}
            onClick={() => deleteInterests(deleteData.interest_id)}
          >
            Delete
          </button>
        </div>
      </Modal>
    </div>
  );
};

const inputDivStyle = {
  background: "#ffffff",
  borderRadius: "5px",
  height: "3em",
  border: "1px solid slategray",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1em",
  marginBottom: "1em",
};

const inputStyle = {
  background: "inherit",
  width: "100%",
  border: "none",
};

const errorMsg = {
  color: "red",
  fontSize: "1.1rem",
  marginTop: "1em",
  marginBottom: "1em",
};

export default MobileAreaOfInterests;
