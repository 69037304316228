const MciUploadSuccess = ({ closeUploadMciSuccess }) => {
  return (
    <>
      <div style={{ marginTop: "15px" }}></div>
      <div className="verifaction">
        <p className="verification-title" style={{ color: "Green" }}>
          Congratulation
        </p>
        <p className="verification-sub-title">
          Your certificate has been upload successfully
        </p>
        <p className="verification-sub-title">
          We will verify your certificate and validate your profile
        </p>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          onClick={closeUploadMciSuccess}
          style={{
            backgroundColor: "#ffffff",
            border: "1px solid gray",
            color: "black",
            padding: "11px",
            width: "150px",
            fontsize: "11px",
            marginBottom: "10px",
            borderRadius: "9px",
          }}
          className="submit-btn"
        >
          Close
        </button>
      </div>
    </>
  );
};

export default MciUploadSuccess;
