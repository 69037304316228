import { useState, useRef, useEffect } from "react";
import * as React from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Link, useParams, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { Modal } from "react-responsive-modal";
import Progressbar from "../progressbar";
import { IoIosCloseCircle } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import "../profile/profile.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  smallRadioButton: {
    "& svg": {
      width: "1.5em",
      height: "1.5em",
    },
  },
});
const useStylesLabel = makeStyles((theme) => ({
  label: {
    fontSize: "1.5rem !important",
  },
}));
const styles = makeStyles((theme) => ({
  radio: {
    colorPrimary: {
      "&$checked": {
        color: "blue",
      },
    },
    checked: {},
  },
}));
const Publications = ({
  modalTitle,
  open,
  closePublicationModal,
  getUserData,
  userInfo,
  editClicked,
  editedData,
}) => {
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const history = useHistory();
  const user_id = getDocId();
  const classes = useStyles();
  const classesLabel = useStylesLabel();
  const classesRadio = styles;
  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm();

  const [journals, setJournals] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState("print");
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    autoSuggestions();
  }, []);

  const autoSuggestions = async () => {
    const payload = { journals: "journals" };
    const res = await fetch(baseUrl + "v2/autoSuggestions", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();
    let journals = [];
    if (data.status === "success") {
      for (const city of data.data.journals) {
        journals.push({ value: city, label: city });
      }     
      setJournals(journals);
      if(editClicked){
        setValue("title", editedData?.title);
        setValue("authornames", editedData?.author_names);
        if(editedData.journal == ""){
          setValue("journal", '');
        }else{
          setValue("journal", {
            label: editedData?.journal,
            value: editedData?.journal,
          });
        }
        if (editedData.pub_type == "print") {
          setSelectedValue("print");
          setValue("websiteurl", "");
        } else if (editedData.pub_type == "online") {
          setSelectedValue("online");
          setValue("websiteurl", editedData?.webpg_link);
        }
      }
    }
  };
  const setEmptyValues = async () => {
      setValue("title", '');
      setValue("authornames", '');
      setValue("journal", '');
      setValue("websiteurl", "");
  }
  const addPublication = async (post) => {
    console.log(post);
    setloaderSpinner(true);
    let pub = {
      author_names: post.authornames,
      journal: post.journal == undefined?'':post.journal.value,
      pub_type: selectedValue == "print" ? "print" : "online",
      title: post.title,
      webpg_link: selectedValue == "print" ? "" : post.websiteurl,
    };
    let payload = {
      user_id: user_id,
    };
    if (selectedValue == "print") {
      payload.print_pub_history = [pub];
    } else if (selectedValue == "online") {
      payload.online_pub_history = [pub];
    }
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/create", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      toast.success("Publication saved");
      reset();
      setEmptyValues();
      setSelectedValue(selectedValue);
      setloaderSpinner(false);
      getUserData();
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };

  const savePublication = async (post) => {
    console.log(post);
    setloaderSpinner(true);
    let pub = {
      author_names: post.authornames,
      journal: post.journal == undefined?'':post.journal.value,
      pub_type: selectedValue == "print" ? "print" : "online",
      title: post.title,
      webpg_link: selectedValue == "print" ? "" : post.websiteurl,
    };
    let payload = {
      user_id: user_id,
    };
    if (selectedValue == "print") {
      payload.print_pub_history = [pub];
    } else if (selectedValue == "online") {
      payload.online_pub_history = [pub];
    }   
    payload = encodeURIComponent(JSON.stringify(payload));
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/create", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      toast.success("Publication saved");
      closePublicationModal();
      reset();
      setEmptyValues();
      setSelectedValue(selectedValue);
      setloaderSpinner(false);
      getUserData();
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };

  const updatePublication = async (post) => {
    console.log(post);
    setloaderSpinner(true);
    let pub = {
      author_names: post.authornames,
      journal: post.journal == undefined?'':post.journal.value,
      pub_type: selectedValue == "print" ? "print" : "online",
      title: post.title,
      webpg_link: editedData.pub_type == "print" ? "" : post.websiteurl,
    };
    let payload = {
      user_id: user_id,
    };
    if (editedData.pub_type == "print") {
      pub.print_pub_id = editedData.print_pub_id;
      payload.print_pub_history = [pub];
    } else if (editedData.pub_type == "online") {
      pub.online_pub_id = editedData.online_pub_id;
      payload.online_pub_history = [pub];
    }
    
    payload = encodeURIComponent(JSON.stringify(payload));
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/update", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      toast.success("Profile updated");
      closePublicationModal();
      getUserData();
      reset();
      setSelectedValue(editedData.pub_type);
      setloaderSpinner(false);
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };
  return (
    <>
      <div>
        <form>
          <div className="container" style={{ padding: 0 }}>
            {!editClicked && (
              <div
                style={{
                  marginLeft: "30px",
                }}
              >
                <RadioGroup
                  row
                  name="controlled-radio-buttons-group"
                  value={selectedValue}
                  onChange={handleChange}
                  defaultValue="print"
                >
                  <FormControlLabel
                    className={classes.smallRadioButton}
                    classes={classesLabel}
                    value="print"
                    control={<Radio color="success" />}
                    label="Print"
                  />
                  <FormControlLabel
                    className={classes.smallRadioButton}
                    classes={classesLabel}
                    value="online"
                    control={<Radio color="success" />}
                    label="Online"
                  />
                </RadioGroup>
              </div>
            )}
            <div
              className="row"
              style={{
                marginTop:editClicked?"1.50em":"0.50em",
                marginBottom: "0em",
                background: "#ffffff",
                borderRadius: "9px",
                height: "2.5em",
                border: "1px solid grey",
                width: "300px",
                marginLeft: "30px",
              }}
            >
              <div className="col-12" style={{ paddingLeft: "0px" }}>
                <input
                  style={{
                    marginTop: "0.50em",
                    paddingLeft: "5px",
                    background: "inherit",
                    width: "100%",
                    border: "none",
                  }}
                  type="text"
                  {...register("title", {
                    required: true,
                    maxLength:100
                  })}
                  placeholder="Title *"
                />
              </div>
            </div>
            {errors.title?.type === "required" && (
              <div className="row error-title" style={{margin:"0.5em 0"}}>
                <span className="error-title-text" style={{ marginLeft: "30px",fontSize:"12px" }}>
                  Please enter the title
                </span>
              </div>
            )}
            {errors.title?.type === "maxLength" && (
              <div className="row error-title" style={{margin:"0.5em 0"}}>
              <span className="error-title-text" style={{ marginLeft: "30px",fontSize:"12px" }}>
                Please enter less than 100 characters
              </span>
              </div>
            )}
            <div
              className="row"
              style={{
                marginTop: "1em",
                marginBottom: "0em",
                background: "#ffffff",
                borderRadius: "9px",
                height: "2.5em",
                border: "1px solid grey",
                width: "300px",
                marginLeft: "30px",
              }}
            >
              <div className="col-12" style={{ paddingLeft: "0px" }}>
                <input
                  style={{
                    marginTop: "0.50em",
                    paddingLeft: "5px",
                    background: "inherit",
                    width: "100%",
                    border: "none",
                  }}
                  type="text"
                  {...register("authornames", {
                    maxLength:100
                  })}
                  placeholder="Author Names"
                />
              </div>
            </div>

            {errors.authornames?.type === "maxLength" && (
              <div className="row error-title" style={{margin:"0.5em 0"}}>
              <span className="error-title-text" style={{ marginLeft: "30px",fontSize:"12px" }}>
                Please enter less than 100 characters
              </span>
              </div>
            )}
            {selectedValue == "online" && (
              <div
                className="row"
                style={{
                  marginTop: "1em",
                  marginBottom: "0em",
                  background: "#ffffff",
                  borderRadius: "9px",
                  height: "2.5em",
                  border: "1px solid grey",
                  width: "300px",
                  marginLeft: "30px",
                }}
              >
                <div className="col-12" style={{ paddingLeft: "0px" }}>
                  <input
                    style={{
                      marginTop: "0.50em",
                      paddingLeft: "5px",
                      background: "inherit",
                      width: "100%",
                      border: "none",
                    }}
                    type="text"
                    {...register("websiteurl")}
                    placeholder="Website URL "
                  />
                </div>
              </div>
            )}

            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  borderRadius: "9px",
                  marginTop: "1em",
                  marginBottom: "0em",
                  background: "#ffffff",
                  borderRadius: "9px",
                  height: "2.5em",
                  border: "1px solid grey",
                  width: "300px",
                  marginLeft: "30px",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    paddingLeft: "0px",
                    marginTop: "-10px",
                  }}
                >
                  <Controller
                    name="journal"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={journals}
                        placeholder="Name of The Jounal"
                        styles={{
                          control: (style) => ({
                            ...style,
                            background: "inherit",
                            padding: "10px 0px 0px 0",
                            border: 0,
                            boxShadow: "none",
                          }),
                          valueContainer: (style) => ({
                            ...style,
                            padding: 2,
                          }),
                          placeholder: (style) => ({
                            ...style,
                            fontSize: "12px",
                          }),
                          container: (style) => ({
                            ...style,
                            width: "100%",
                          }),
                          menuList: (style) => ({
                            ...style,
                            height: "6.5em",
                          }),
                        }}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
              {!editClicked && (
                <div
                  style={{
                    borderRadius: "9px",
                    marginTop: "1em",
                    height: "2.5em",
                    marginLeft: "30px",
                  }}
                >
                  <button
                    style={{
                      border: "1px solid rgb(30 138 121)",
                      padding: "0.9rem 0.7rem",
                      borderRadius: "5px",
                      float: "right",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      onClick={handleSubmit(addPublication)}
                    >
                      <MdAdd size={20} />
                      <span>Add One More</span>
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>

        </form>
        
        </div>

        <div>
          {" "}
          <Progressbar
            show={loaderSpinner}
            imageUrl={url}
            height="90"
            width="90"
            alignment="middle"
            alttext="Loading..."
          />
        </div>
      <div style={{position: "absolute",marginTop:editClicked?"65%":"68%"}}>
              {!editClicked && (
            <div style={{ margin: "0px 30px" }}>
              <button
                type="submit"
                className="profile-save-button"
                style={{ height: "2.5em" }}
                onClick={handleSubmit(savePublication)}
              >
                Save
              </button>
              <button
                className="profile-close-button"
                style={{ height: "2.5em" }}
                onClick={closePublicationModal}
              >
                Close
              </button>
            </div>
          )}
          {editClicked && (
            <div style={{ margin: "0px 0px" }}>
              <button
                type="submit"
                className="pub-close-button"
                onClick={closePublicationModal}
                style={{ height: "2.5em" }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="pub-save-button"
                onClick={handleSubmit(updatePublication)}
                style={{ height: "2.5em" }}
              >
                Update Publication
              </button>
            </div>
          )}

      </div>
  </>
  );
};

export default Publications;

