import email from "../../assets/sign_up_and_sign_in_icons/Mail.svg";

const EmailInput = ({ register, errors, contactInputError, isOtpSent }) => {
  return (
    <>
      <div className="container" style={{ marginTop: "15px" }}>
        <div
          className="row"
          style={{
            marginTop: "1em",
            marginBottom: "1em",
            background: "#F6F6F6",
            borderRadius: "9px",
            height: "3.5em",
          }}
        >
          <div className="col-2">
            <img src={email} alt="" style={{ margin: "1.1em" }} />
          </div>

          <div className="col-10" style={{ paddingLeft: "0px" }}>
            <input
              style={{
                marginTop: "1.1em",
                paddingLeft: "5px",
                background: "inherit",
                width: "100%",
                border: "none",
              }}
              type="email"
              id="emailInput"
              readOnly={isOtpSent ? true : false}
              {...register("email", {
                required: true,
                maxLength: 35,
                pattern: {
                  value: /^\s*[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}\s*$/i,
                  message: "invalid email address",
                },
              })}
              placeholder="Enter Email"
            />
          </div>
        </div>
        <div className="row error-title">
          {errors.email?.type === "required" && (
            <span className="error-title-text">
              {" "}
              Please enter valid email ID
            </span>
          )}
        </div>

        <div className="row error-title">
          {errors.email?.type === "maxLength" && (
            <span className="error-title-text">max length is 35</span>
          )}
        </div>
        <div className="row error-title">
          {errors.email?.type === "pattern" && (
            <span className="error-title-text">
              Please enter valid email ID
            </span>
          )}
        </div>
        <div className="row error-title">
          {contactInputError.error && (
            <span className="error-title-text">
              {contactInputError.errorMsg}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default EmailInput;
