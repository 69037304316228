import { useState, useEffect } from "react";
import { getDocId, getHeaders } from "../../utils/utils";

const useGalleryLoader = (galleryData, channel_id, view) => {
  const doc_id = getDocId();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();

  const [gallery, setGallery] = useState([]);
  const [lastTimestamp, setLastTimestamp] = useState(0);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const getGallerySectionData = async () => {
    setLoading(true);
    setError(false);

    const payload = {
      mediaTypes: galleryData.galleryMediaType,
      doc_id,
      channel_ids: [channel_id],
      timestamp: galleryData.timestamp,
    };

    const res = await fetch(baseUrl + "v2/channel/getSectionData", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();

    if (data.status === "success") {
      if (data.data.mediaSections.length > 0) {
        if (payload.timestamp === 0) setGallery([...data.data.mediaSections]);
        else {
          setGallery((prev) => {
            let oldItems = [...prev];
            let newItems = [...data.data.mediaSections];
            const lastItem = oldItems[oldItems.length - 1];

            if (lastItem.title === newItems[0]?.title) {
              lastItem.fileData = [
                ...lastItem.fileData,
                ...newItems[0].fileData,
              ];
              newItems.shift();
            }

            return [...oldItems, ...newItems];
          });
        }
      }
      setLastTimestamp(data.data.timestamp);
      if (data.data.count) setCount(data.data.count);
      setLoading(false);
      setHasMore(data.data.mediaSections.length > 0);
    } else {
      setLoading(false);
      setError(true);
      setHasMore(false);
    }
  };

  useEffect(() => {
    if (view === "gallery") getGallerySectionData();
  }, [view, galleryData]);

  return { gallery, hasMore, loading, error, lastTimestamp, count };
};

export default useGalleryLoader;
