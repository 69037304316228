import { useState, useRef } from "react";
import { closeIcon, getDocId, getHeaders } from "../utils/utils";
import FullViewFeed from "../dashboard/feeds/FullViewFeed";
import { Modal } from "react-responsive-modal";
import styles from "./search.module.css";
import DahboardHeader from "../dashboard/DahboardHeader";
import DahboardRightside from "../dashboard/DahboardRightside";
import DahboardLeftside from "../dashboard/DahboardLeftside";
import { IoArrowBack } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { MdClear } from "react-icons/md";
import dummy from "../assets/profile_icons/default-profile.png";
import audio_placeholder from "../assets/audio_placeholder.png";
import clip from "text-clipper";
import loading2 from "../assets/loading2.gif";
import noResultsIcon from "../assets/searchIcons/Empty_Box.svg";
import SearchIcon from "../assets/searchIcons/Search_Feeds.svg";
import { IoIosArrowUp } from "react-icons/io";
import toast from "react-hot-toast";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const DesktopSearch = ({
  channels,
  filters,
  searchFeeds,
  searchText,
  setSearchText,
  clearSearch,
  showFeeds,
  selectedFilter,
  setSelectedFilter,
  searchResults,
  userData,
  scrollData,
  loader,
  handleUploadMci,
  handleVerificationPending,
}) => {
  const doc_id = getDocId();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const history = useHistory();

  const { loading, hasMore, error, noResults } = scrollData;

  const [fullView, setFullView] = useState(false);

  const feedsContainer = useRef();
  const filtersContainer = useRef();

  const [feedData, setFeedData] = useState({});

  const openFullView = (feed_id, channel_id) => {
    return (e) => {
      getFeed(feed_id, channel_id);
    };
  };

  const closeFullView = () => {
    setFullView(false);
  };

  const scrollFeedToTop = () =>
    feedsContainer.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  const getFeed = async (feed_id, channel_id) => {
    const payload = {
      doc_id,
      feedId: feed_id,
      channel_id,
    };

    const res = await fetch(baseUrl + "v3/fullViewFeed", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();

    if (data.status === "success") {
      setFeedData(data.data);
      setFullView(true);
    } else {
      toast(data.errorMsg);
    }
  };

  const verifyUser = () => {
    if (!userData.verificationInfo.isCommunityVerified) {
      if (userData.verificationInfo.is_user_verified == 1) {
        handleUploadMci();
        return false;
      }
      if (userData.verificationInfo.is_user_verified == 2) {
        handleVerificationPending();
        return false;
      }
    }
    return true;
  };

  const addComment = async (feed_id, channel_id, comment, attachments = []) => {
    if (!verifyUser()) return;

    if (attachments.length === 0 && comment === "") {
      toast.error("Cannot post empty comment");
      return;
    }

    let payload = JSON.stringify({
      doc_id: doc_id,
      channel_id,
      feedTypeId: feed_id,
      socialInteraction: {
        type: "Comment",
        comment,
        attachment_details: attachments,
      },
    });

    payload = payload.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });

    payload = encodeURIComponent(payload);

    const res = await fetch(baseUrl + "v1/feedSocialInteractions", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    if (data.status === "success")
      setFeedData((prevFeed) => ({
        ...prevFeed,
        feed_info: {
          ...prevFeed.feed_info,
          socialInteraction: data.data.socialInteraction,
        },
      }));
    else toast.error(data.errorMsg);
  };
  const updateLikeCount = async (feed_id, channel_id, isLike) => {
    if (!verifyUser()) return;

    const payload = {
      doc_id: doc_id,
      channel_id: channel_id,
      feedTypeId: feed_id,
      socialInteraction: {
        type: "Like",
        isLike,
      },
    };

    const res = await fetch(baseUrl + "v1/feedSocialInteractions", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    if (data.status === "success")
      setFeedData((prevFeed) => ({
        ...prevFeed,
        feed_info: {
          ...prevFeed.feed_info,
          socialInteraction: data.data.socialInteraction,
        },
      }));
    else toast.error(data.errorMsg);
  };

  const updateViewCount = async (channel_id, feed_id) => {
    const payload = {
      doc_id,
      channel_id: channel_id,
      feedId: feed_id,
    };

    const res = await fetch(baseUrl + "feeds/updateViewCount", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((err) => {
      console.log(err);
      return;
    });
    const data = await res.json();

    if (data.status === "success") {
      if (data.data.socialInteraction)
        setFeedData((prevFeed) => ({
          ...prevFeed,
          feed_info: {
            ...prevFeed.feed_info,
            socialInteraction: data.data.socialInteraction,
          },
        }));
      else
        setFeedData((prevFeed) => ({
          ...prevFeed,
          feed_info: {
            ...prevFeed.feed_info,
            socialInteraction: {
              ...prevFeed.feed_info.socialInteraction,
              viewCount: prevFeed.feed_info.socialInteraction.viewCount + 1,
            },
          },
        }));
    } else toast.error(data.errorMsg);
  };
  const submitSurvey = async (feed_id, channel_id, question_id, option_id) => {
    if (option_id.length === 0) {
      toast("Please select an option");
      return;
    }

    const payload = {
      feed_id,
      channel_id,
      user_id: doc_id,
      feed_survey: {
        questions: [
          {
            question_id,
            options: option_id.map((option) => ({
              option_id: option,
            })),
          },
        ],
      },
    };

    const res = await fetch(baseUrl + "v1/feed/survey/update", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();
    if (data.status === "success")
      setFeedData((prevFeed) => {
        const newFeed = { ...prevFeed };
        newFeed.feed_info.feed_survey.questions =
          newFeed.feed_info.feed_survey.questions.map((question, index) => ({
            ...question,
            options: data.data.feed_survey.questions[index].options,
          }));
        newFeed.feed_info.feed_survey.is_open = data.data.feed_survey.is_open;
        newFeed.feed_info.feed_survey.is_participated =
          data.data.feed_survey.is_participated;

        return newFeed;
      });
    else toast.error(data.errorMsg);
  };
  const deleteComment = async (feed_id, channel_id, social_interaction_id) => {
    const payload = {
      user_id: doc_id,
      channel_id: channel_id,
      feed_id,
      social_interaction_id,
    };
    const res = await fetch(baseUrl + "v1/feeds/comment/delete", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();

    if (data.status === "success")
      setFeedData((prevFeed) => ({
        ...prevFeed,
        feed_info: {
          ...prevFeed.feed_info,
          socialInteraction: data.data.socialInteraction,
        },
      }));
    else toast.error(data.errorMsg);
  };

  const updateComment = async (
    feed_id,
    channel_id,
    social_interaction_id,
    comment,
    attachments = []
  ) => {
    if (!verifyUser()) return;

    if (attachments.length === 0 && comment === "") {
      // addToast("Cannot post empty comment", {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
      return;
    }

    let payload = JSON.stringify({
      user_id: doc_id,
      channel_id,
      feed_id,
      social_interaction_id,
      comment: comment,
      attachment_details: attachments,
    });

    payload = payload.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });

    payload = encodeURIComponent(payload);

    const res = await fetch(baseUrl + "v1/feeds/comment/update", {
      method: "POST",
      headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();

    if (data.status === "success")
      setFeedData((prevFeed) => ({
        ...prevFeed,
        feed_info: {
          ...prevFeed.feed_info,
          socialInteraction: data.data.socialInteraction,
        },
      }));
    else toast.error(data.errorMsg);
  };
  const registerWebinar = async (e, feed_id, verify_user, title) => {
    e.preventDefault();

    if (verify_user) {
      if (!verifyUser()) return;
    }

    const payload = {
      user_id: doc_id,
      feed_id,
    };

    const res = await fetch(baseUrl + "v1/user/webinar/register", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
    });

    const data = await res.json();

    if (data.status === "success") {
      toast.success(`Thank you for registering for ${title}`);
      setFeedData((prevFeed) => {
        const newFeed = { ...prevFeed };
        newFeed.feed_info.event_details.is_user_registered = true;
        return newFeed;
      });
      return true;
    } else if (data.status === "error") toast.error("Failed to registered");

    return false;
  };

  return (
    <>
      <div>
        <header>
          <DahboardHeader
            userData={userData}
            handleUploadMci={handleUploadMci}
            handleVerificationPending={handleVerificationPending}
          />
        </header>
        <main className="main-wrapper pt-80" style={{ marginTop: "2em" }}>
          <div className="container">
            <div className="row">
              <div className="col-3">
                <DahboardLeftside
                  userInfo={userData}
                  handleUploadMci={handleUploadMci}
                />
              </div>
              <div className="col-6">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1em" }}
                >
                  {/* <IoArrowBack
                    size={25}
                    onClick={() => history.goBack()}
                    style={{ cursor: "pointer" }}
                  /> */}
                  <h3 style={{ color: "black" }}>Search Results</h3>
                </div>

                <form onSubmit={searchFeeds}>
                  <div
                    className={styles.searchBar}
                    style={{
                      borderColor:
                        searchText?.length < 3 ? "gray" : "rgb(30, 138, 121)",
                      backgroundColor: "white",
                      marginTop: "1em",
                      width: "100%",
                    }}
                  >
                    <input
                      className={styles.searchInput}
                      placeholder="Search"
                      type="text"
                      maxLength={70}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <button type="submit">
                      <BsSearch size={16} />
                    </button>
                    {searchText !== "" && (
                      <MdClear
                        onClick={clearSearch}
                        size={18}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </div>
                </form>

                {!showFeeds && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "80%",
                      flexDirection: "column",
                    }}
                  >
                    <img style={{ width: "10em" }} src={SearchIcon} />
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.8rem",
                        margin: "1em",
                      }}
                    >
                      {" "}
                      Search for feeds
                    </div>
                    <div style={{ textAlign: "center", fontSize: "1.5rem" }}>
                      Find feeds related to articles, cases, job posting <br />{" "}
                      and many more
                    </div>
                  </div>
                )}

                {showFeeds && (
                  <>
                    <div
                      className={styles.filters}
                      style={{ padding: "1em 2.7em" }}
                      ref={filtersContainer}
                    >
                      {filters?.searchType?.length > 0 && (
                        <div
                          style={{
                            boxShadow: "0 0 5px rgba(0, 0, 0,0.3)",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            padding: "6px 10px",
                            position: "absolute",
                            left: "15px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            filtersContainer.current.scrollBy({
                              left: -100,
                              behavior: "smooth",
                            })
                          }
                        >
                          <AiOutlineLeft size={10} />
                        </div>
                      )}
                      {filters?.searchType?.map((filter) => (
                        <div
                          className={styles.filter}
                          style={{
                            backgroundColor:
                              selectedFilter === filter.id
                                ? "rgb(30, 138, 121)"
                                : "white",
                            color:
                              selectedFilter === filter.id ? "white" : "black",
                          }}
                          onClick={() => setSelectedFilter(filter.id)}
                        >
                          <div style={{ whiteSpace: "nowrap" }}>
                            {filter.name}
                          </div>
                        </div>
                      ))}

                      {filters?.searchType?.length > 0 && (
                        <div
                          style={{
                            boxShadow: "0 0 5px rgba(0, 0, 0,0.3)",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            padding: "6px 10px",
                            position: "absolute",
                            right: "15px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            filtersContainer.current.scrollBy({
                              left: 100,
                              behavior: "smooth",
                            })
                          }
                        >
                          <AiOutlineRight size={10} />
                        </div>
                      )}
                    </div>
                    <div className={styles.feeds} ref={feedsContainer}>
                      {noResults && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            flexDirection: "column",
                          }}
                        >
                          <img style={{ width: "10em" }} src={noResultsIcon} />
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.8rem",
                              margin: "1em",
                            }}
                          >
                            {" "}
                            No Result Found
                          </div>
                        </div>
                      )}
                      {searchResults.map((item, i) => (
                        <>
                          <div
                            className={styles.feed}
                            onClick={openFullView(
                              item.feed_id,
                              item.channel_id
                            )}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                gap: "1em",
                                flex: 2,
                              }}
                            >
                              <div className="header">
                                {clip(item.title, 150)}
                              </div>
                              <div
                                className={styles.description}
                                dangerouslySetInnerHTML={{
                                  __html: clip(item.short_desc, 150, {
                                    html: true,
                                    maxLines: 3,
                                  }),
                                }}
                              ></div>
                              {/* <div className={styles.feedType}>Article</div> */}
                            </div>
                            {item.attachment_details &&
                              item.attachment_details.length > 0 && (
                                <img
                                  className={styles.feedImage}
                                  src={
                                    item.attachment_details[0].attachment_type.toLowerCase() ===
                                    "audio"
                                      ? audio_placeholder
                                      : item.attachment_details[0]
                                          .attachment_small_url
                                  }
                                />
                              )}
                          </div>
                          {/* <hr style={{ margin: 0 }} /> */}
                        </>
                      ))}
                      <div ref={loader} />
                      {loading && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img src={loading2} />
                        </div>
                      )}
                      {error && <div>Error</div>}

                      {searchResults?.length > 3 && (
                        <div
                          onClick={scrollFeedToTop}
                          style={{
                            position: "absolute",
                            bottom: "1em",
                            right: "2em",
                            zIndex: 2,
                            backgroundColor: "rgb(30, 138, 121)",
                            borderRadius: "50%",
                            boxShadow: "2px 3px 5px rgba(0 ,0 ,0 , 0.3)",
                            padding: "1em",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <IoIosArrowUp size={18} style={{ color: "white" }} />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="col-3">
                <DahboardRightside />
              </div>
            </div>
          </div>
        </main>
      </div>
      <Modal
        open={fullView}
        blockScroll={true}
        style={customStyles}
        onClose={closeFullView}
        closeIcon={closeIcon}
        classNames={{ modal: "feedModel" }}
        // ref={fullViewFeedRef}
      >
        <FullViewFeed
          close={closeFullView}
          feed={feedData}
          addComment={addComment}
          updateLikeCount={updateLikeCount}
          submitSurvey={submitSurvey}
          updateViewCount={updateViewCount}
          channels={channels}
          deleteComment={deleteComment}
          updateComment={updateComment}
          registerWebinar={registerWebinar}
        ></FullViewFeed>
      </Modal>
    </>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default DesktopSearch;
