import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { getHeaders, logout } from "../../utils/utils";

const useSearchFeedLoader = (doc_id, nextFeedsData,channelId,deptId,setMembersList,searchValue,setLastMemberId,setEmpty) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const getMemberList = async () => {
    console.log("this is from ")
    setLoading(true);
    setError(false);
    const payload = {
      channel_id: channelId,
      department_id:deptId,
      doc_id: doc_id,
      last_member_id:nextFeedsData.pageNum
    }
    const res = await fetch(baseUrl + "v2/community/membersInfo", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {
      setLoading(false);
      setError(true);
      setHasMore(false);
      return;
    });
    const data = await res.json();
    if (data.status === "success") {
      setEmpty(data?.department_members_info <= 0);
    if (data?.department_members_info && data?.department_members_info.length > 0) {   
      setLastMemberId(data?.last_member_id);
      if (payload.last_member_id === 0) setMembersList([...data?.department_members_info]);
      else
      setMembersList((prevFeeds) => {
          return [...new Set([...prevFeeds, ...data?.department_members_info])];
        });
  
    }

      setLoading(false);
      setHasMore(data?.department_members_info.length > 0);
    
    } else {
      setEmpty(true);
      setLoading(false);
      setError(true);
      setHasMore(false);
      return;
    }
  };
  const searchMembers = async () => {
    console.log("this is from ")
    setLoading(true);
    setError(false);
    const payload = {
      query: channelId,
      doc_id: deptId,
      pg_num: nextFeedsData.pageNum
    };
    const res = await fetch(baseUrl + "v2/searchContacts", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {
      setLoading(false);
      setError(true);
      setHasMore(false);
      return;
    });
    const data = await res.json();
    if (payload.pg_num === 0) 
    setMembersList([]);
    if (data.status === "success") {
        if (data.data?.feed_data && data.data?.feed_data?.length > 0) {
          setMembersList((prevFeeds) => {
           return [...new Set([...prevFeeds, ...data.data?.feed_data])];
        })
      }
      setLoading(false);
      setHasMore(data.data.feed_data.length > 0);
    } else {
      setLoading(false);
      setError(true);
      setHasMore(false);
      return;
    }
  };
  useEffect(() => {
      getMemberList();

}, [nextFeedsData]);
return { loading, error, hasMore };
};

export default useSearchFeedLoader;
