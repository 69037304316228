import { useState, useEffect, useRef, useCallback } from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import React from "react";
import S3 from "react-aws-s3";
import { Link, useParams, useHistory } from "react-router-dom";
import Progressbar from "../progressbar";
import "../createPost/createpost.css";
import ReactAudioPlayer from "react-audio-player";
import InputBase from "@mui/material/InputBase";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import clipImage from "../assets/Web Icons/Icon feather-paperclip.svg";
import doneImage from "../assets/Web Icons/Icon material-done.svg";
import closeImage from "../assets/Web Icons/Group 2831.svg";
import plusImage from "../assets/Web Icons/Icon feather-plus.svg";
import editImage from "../assets/Web Icons/Icon material-edit.svg";
import pdfImage from "../assets/Web Icons/Icon metro-file-pdf.svg";
import playImage from "../assets/Web Icons/Icon feather-play-circle.svg";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import back from "../assets/Icons/Icon feather-arrow-left@2x.png";
import { useForm, Controller, useWatch } from "react-hook-form";
import OutlinedInput from "@mui/material/OutlinedInput";
import "./css/signup.css";
import toast from "react-hot-toast";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import ListItemIcon from "@mui/material/ListItemIcon";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@mui/material/ListSubheader";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import { styled } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import { BsPlusCircle } from "react-icons/bs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { decode } from "html-entities";
import { MdEdit } from "react-icons/md";
import deleteIcon from "../assets/Delete-1X.png";
import { IoArrowBackOutline } from "react-icons/io5";
import InputTextLimit from "./InputTextLimit";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#1e8a79",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      // borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "rgb(0, 0 ,0 ,0.4)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgb(0, 0 ,0 ,0.4)",
    },
  },
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 8,
    position: "relative",
    backgroundColor: "rgb(221, 253, 247)",
    padding: "0px 0px 10px 10px",
    color: "rgb(30, 138, 121)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    font: "bold 14px / 20px roboto",
    height: "35px",
    // '&:focus': {
    //   borderRadius: 4,
    //   borderColor: '#80bdff',
    //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    // },
  },
}));

const useStyles = makeStyles((theme) => ({
  input: {
    height: "32px",
  },
  selectBorder: {
    "&:hover .Mui-focused .MuiOutlinedInput-notchedOutline": {
      // borderColor: "#1e8a79 !important",
    },
    "& .MuiOutlinedInput-input": {
      // color: "#1e8a79",
      font: " 13px/20px roboto !important",
    },
    "& .MuiOutlinedInput-input .MuiOutlinedInput-notchedOutline": {
      // borderColor: "#1e8a79",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 , 0 ,0 , 0.4) !important",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      // borderColor: "#1e8a79 !important",
      borderWidth: "1px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 , 0 ,0 , 0.4) !important",
    },
  },
  customOutline: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  formControl: {
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderColor: "blue",
    },
  },

  // select: {
  //   "&:before": {
  //     borderColor: "red",
  //   },
  // },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MobileCreatepost = ({
  defaultChannel = 0,
  background,
  profile_pic,
  user_verification_info,
}) => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm();

  const title = useWatch({
    control,
    name: "title",
  });

  const question = useWatch({
    control,
    name: "question",
  });

  const { type } = useParams();
  //console.log("data"+setCategoryListInfo)
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ color: [] }, { background: [] }],
    ],
  };
  const formats = ["bold", "italic", "underline", "color", "background"];

  const [spinner, setSpinner] = useState(false);
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const history = useHistory();
  const doc_id = getDocId();
  const headers = getHeaders();
  const profile_pic_placeholder =
    "assets/images/profile/src_assets_images_Avatar Icon_30x30.png";
  const postCreatorProfilePic = profile_pic_placeholder;
  const [showPost, setShowPost] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const closeIcon = <img src={close}></img>;
  const closeIconHide = <img src={close} style={{ display: "none" }}></img>;
  const [openPostView, setOpenPostView] = useState(false);
  const [openPostSuccessView, setOpenPostSuccessView] = useState(false);
  const [channelsData, setChannelsData] = useState([]);
  const [channels, setChannels] = useState([]);
  const [selectedShare, setSelectedShare] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [previewModal, setPreviewModal] = useState(false);
  const closePreviewModal = () => setPreviewModal(false);
  const [previewAttachment, setPreviewAttachment] = useState();
  const [attachError, setAttachError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [isAllSelected, setAllSelected] = useState(true);
  const [departmentValue, setDepartmentValue] = useState([]);
  const [departmentKey, setDepartmentKey] = useState([]);

  const [selectedSpec, setSelectedSpec] = useState([]);
  const [isAllSelectedSpec, setAllSelectedSpec] = useState(true);
  const [specialitiesValue, setSpecialitiesValue] = useState([]);
  const [specialitiesKey, setSpecialitiesKey] = useState([]);
  const [modalTitle, setModalTitle] = useState("Post A Case");
  //const isAllSelected =departments.length > 0 && selected.length === departments.length;
  useEffect(() => {
    document.body.style.overflow = "hidden";
    handlePostModal(type);
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  const [quilContent, setQuilContent] = useState("");
  //const isAllSelected =departments.length > 0 && selected.length === departments.length;
  const quilChange = (content, delta, source, editor) => {
    setQuilContent(editor.getHTML());
    console.log(editor.getHTML()); // rich text
    console.log(editor.getText()); // plain text
    console.log(editor.getLength()); // number of characters
  };
  const handlePostModal = (data) => {
    if (data === "1") {
      setModalTitle("Post A Case");
    } else if (data === "2") {
      setModalTitle("Share News/Announcements");
    } else if (data === "3") {
      setModalTitle("Ask A Question");
    }
    setQuilContent("");
    reset();
    changeChannels();
    setPostAttachments([]);
    setOpenPostView(true);
  };
  const handlePostModalClose = () => {
    setOpenPostView(false);
  };
  const showSuccessView = () => {
    setOpenPostView(false);
    setOpenPostSuccessView(true);
  };
  const hideSuccessView = (e) => {
    e.preventDefault();
    setOpenPostSuccessView(false);
    history.goBack();
  };

  const renderPostValue = () => {
    let data = "";
    let first = selected[0];
    let length = selected.length - 1;
    if (selected.length === departmentValue.length) {
      data = "All Members";
    } else if (selected.length == 1) {
      data = selected[selected.length - 1];
    } else if (selected.length >= 2) {
      data = first + " +" + length + " more";
    }
    return data;
  };
  const handleChange = async (event, child) => {
    const value = event.target.value;
    setValue("post", event.target.value);
    if (value[value.length - 1] === "all") {
      setAllSelected(selected.length === departmentValue.length ? false : true);
      setSelected(
        selected.length === departmentValue.length ? [] : departmentValue
      );
      setValue(
        "post",
        selected.length === departmentValue.length ? [] : departmentValue
      );
      const res = await trigger("post");
      return;
    }
    setSelected(value);
    const allSelected =
      departmentValue.length > 0 && value.length === departmentValue.length;
    setAllSelected(allSelected);
    const res = await trigger("post");
  };
  const renderSpecValue = () => {
    let data = "";
    let first = selectedSpec[0];
    let length = selectedSpec.length - 1;
    if (selectedSpec.length === specialitiesValue.length) {
      data = "All Specialities";
    } else if (selectedSpec.length == 1) {
      data = selectedSpec[selectedSpec.length - 1];
    } else if (selectedSpec.length >= 2) {
      data = first + " +" + length + " more";
    }
    return data;
  };
  const handleSpecial = async (event, child) => {
    const value = event.target.value;
    setValue("special", event.target.value);
    if (value[value.length - 1] === "all") {
      setAllSelectedSpec(
        selectedSpec.length === specialitiesValue.length ? false : true
      );
      setValue(
        "special",
        selectedSpec.length === specialitiesValue.length
          ? []
          : specialitiesValue
      );
      setSelectedSpec(
        selectedSpec.length === specialitiesValue.length
          ? []
          : specialitiesValue
      );
      const res = await trigger("special");
      return;
    }
    setSelectedSpec(value);
    const allSelected =
      specialitiesValue.length > 0 && value.length === specialitiesValue.length;
    setAllSelectedSpec(allSelected);
    const res = await trigger("special");
  };
  useEffect(() => {
    getChannels();
  }, []);

  const changeChannels = async () => {
    setChannelsData([]);
    channels.map((channel) => {
      const channelList = [];
      if (channel.feed_provider_type === "Network") {
        setSelectedShare(channel);
        setValue("share", channel);
        getSpecialities(channel.channel_id);
        channelList.push(channel);
      }
      if (channel.is_admin) {
        channelList.push(channel);
      }
      setChannelsData((prev) => {
        return [...prev, ...channelList];
      });
    });
  };
  const getChannels = async () => {
    setloaderSpinner(true);
    const payload = {
      doc_id,
      last_feed_time: 0,
      load_next: true,
      pg_num: 0,
    };
    const res = await fetch(baseUrl + "v3/getDashBoardData", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      setloaderSpinner(false);
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
      return;
    });

    const data = await res.json();
    if (data.status === "success") {
      setloaderSpinner(false);
      if (data.data.listofChannels) {
        const channelList = [];
        data.data.listofChannels.map((channel) => {
          if (defaultChannel === channel.channel_id) {
            setSelectedShare(channel);
            setValue("share", channel);
            getSpecialities(channel.channel_id, type);
          }

          if (channel.feed_provider_type === "Network") {
            if (defaultChannel === 0) {
              setValue("share", channel);
              setSelectedShare(channel);
              getSpecialities(channel.channel_id, type);
            }
            channelList.push(channel);
          }
          if (channel.is_admin) channelList.push(channel);
        });
        setChannelsData([...channelList]);
      }
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };

  const handleShare = (event) => {
    const data = event.target.value;
    setValue("share", event.target.value);
    setSelectedShare(data);
    getSpecialities(data.channel_id, 0);
    if (data.feed_provider_type != "Network") {
      setShowPost(true);
    } else {
      setShowPost(false);
    }
  };

  const getSpecialities = async (channel_id, type) => {
    const payload = {
      doc_id: doc_id,
      channel_id: channel_id,
    };

    const res = await fetch(baseUrl + "v2/getDepartmentsAndSpecialties", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
      return;
    });

    const data = await res.json();
    if (data.status === "success") {
      data.data.specialities.sort(function (a, b) {
        return a.speciality_name.localeCompare(b.speciality_name);
      });
      data.data.departments.sort(function (a, b) {
        return a.department_name.localeCompare(b.department_name);
      });
      setSpecialities(data.data.specialities);
      setDepartments(data.data.departments);
      if (data.data.departments.length !== 0) {
        const departmentIds = [];
        const departmentNames = [];
        data.data.departments.map((dep) => {
          departmentIds.push(dep.department_id);
          departmentNames.push(dep.department_name);
        });
        setDepartmentKey(departmentIds);
        setDepartmentValue(departmentNames);
        setValue("post", departmentNames);
        setSelected(departmentNames);
        setAllSelected(true);
        const res = await trigger("post");
      } else {
        setValue("post", "departmentNames");
      }
      if (data.data.specialities.length !== 0) {
        const specialityIds = [];
        const specialityNames = [];
        const caseSpecialityNames = [];
        data.data.specialities.map((spec) => {
          specialityIds.push(spec.speciality_id);
          specialityNames.push(spec.speciality_name);
        });
        setSpecialitiesKey(specialityIds);
        setSpecialitiesValue(specialityNames);
        setValue("special", specialityNames);
        if (type == 1) {
          if (data.data.related_specialties.length != 0) {
            let speciality_ids = data.data.specialities.filter((itemA) => {
              return data.data.related_specialties.find((itemB) => {
                return itemA.speciality_id === itemB;
              });
            });
            console.log(speciality_ids);
            speciality_ids.map((speciality) => {
              caseSpecialityNames.push(speciality.speciality_name);
            });
          }
          setAllSelectedSpec(false);
          setSelectedSpec(caseSpecialityNames);
        } else if (type == 2 || type == 3) {
          setAllSelectedSpec(true);
          setSelectedSpec(specialityNames);
        }
      }
    } else toast.error(data.errorMsg);
  };

  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const videoObserver = useRef(null);
  const audioObserver = useRef(null);
  const videoLoader = useCallback((node) => {
    if (videoObserver.current) videoObserver.current.disconnect();
    videoObserver.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          videoRef.current.pause();
        }
      });
    });
    if (node) videoObserver.current.observe(node);
  }, []);

  const audioLoader = useCallback((node) => {
    if (audioObserver.current) audioObserver.current.disconnect();
    audioObserver.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          audioRef.current.audioEl.current.pause();
        }
      });
    });
    if (node) audioObserver.current.observe(node);
  }, []);

  const showPreview = (attach) => {
    setPreviewAttachment("");
    setPreviewAttachment(attach);
    setPreviewModal(true);
    console.dir(attach);
  };
  const [isfileUploaded, setIsFileUploaded] = useState(false);
  const fileInput = useRef(null);
  const editFileInput = useRef(null);
  const [postSpinner, setPostSpinner] = useState(false);
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const [postAttachments, setPostAttachments] = useState([]);

  const [buttonDropdown, setButtonDropdown] = useState(false);

  const showImagePreview = () => {
    // if (type === "image") {
    //   setIsFileUploaded(true);
    //   setImgPreviewUrl(URL.createObjectURL(fileInput.current.files[0]));
    // } else {
    //   toast.error("Only images are allowed");
    // }

    let file = fileInput.current.files[0];
    let newFileName = fileInput.current.files[0].name;
    let ext = getExtension(newFileName);
    let type = fileInput.current.files[0].type;
    if (type.includes("image")) type = "image";
    else if (type.includes("video")) type = "video";
    else if (type.includes("audio")) type = "audio";
    else if (type.includes("pdf")) type = "pdf";
    setAttachError(false);
    if (
      type != "image" &&
      type != "pdf" &&
      type != "video" &&
      type != "audio"
    ) {
      setAttachError(true);
      fileInput.current.value = "";
      return false;
    }
    setSizeError(false);
    const fsize = fileInput.current.files[0].size;
    const fileSize = Math.round(fsize / 1024);
    if (fileSize >= 51200) {
      setSizeError(true);
      fileInput.current.value = "";
      return false;
    }
    const attachmentDeatails = [];
    // const ReactS3Client = new S3(config);
    // const data = await ReactS3Client.uploadFile(file, newFileName);
    const attachment = {
      file: file,
      attachment_type: type,
      attachment_extension: ext,
      attachment_original_url: URL.createObjectURL(fileInput.current.files[0]),
    };
    console.dir(attachment);
    attachmentDeatails.push(attachment);
    setPostAttachments((prev) => {
      return [...prev, ...attachmentDeatails];
    });
    fileInput.current.value = "";
  };
  const editFile = async (e, index) => {
    e.preventDefault();

    let file = e.target.files[0];
    let newFileName = e.target.files[0].name;
    let ext = getExtension(newFileName);
    let type = e.target.files[0].type;
    if (type.includes("image")) type = "image";
    else if (type.includes("video")) type = "video";
    else if (type.includes("audio")) type = "audio";
    else if (type.includes("pdf")) type = "pdf";
    setAttachError(false);
    if (
      type != "image" &&
      type != "pdf" &&
      type != "video" &&
      type != "audio"
    ) {
      setAttachError(true);
      fileInput.current.value = "";
      return false;
    }
    setSizeError(false);
    const fsize = e.target.files[0].size;
    const fileSize = Math.round(fsize / 1024);
    if (fileSize >= 51200) {
      setSizeError(true);
      e.target.value = "";
      return false;
    }
    const attachmentDeatails = [];
    // const ReactS3Client = new S3(config);
    // const data = await ReactS3Client.uploadFile(file, newFileName);
    const attachment = {
      file: file,
      attachment_type: type,
      attachment_extension: ext,
      attachment_original_url: URL.createObjectURL(e.target.files[0]),
    };
    console.dir(attachment);
    attachmentDeatails.push(attachment);
    let newArr = [...postAttachments]; // copying the old datas array
    newArr[index] = attachment; // replace e.target.value with whatever you want to change it to
    setPostAttachments(newArr);
    e.target.value = "";
  };
  const removeFile = async (e, index) => {
    e.preventDefault();
    setPostAttachments(postAttachments.filter((item, i) => i !== index));
  };

  const getExtension = (fileName) => {
    const lastDot = fileName.lastIndexOf(".");
    const ext = fileName.substring(lastDot + 1);
    return ext;
  };

  const postSubmit = async (post) => {
    console.log(post);
    setPostSpinner(true);
    const finalAttachments = [];
    const finalSpecialities = [];
    const finalDepartments = [];
    const ReactS3Client = new S3(config);
    if (postAttachments.length != 0) {
      await Promise.all(
        postAttachments.map(async (attach, i) => {
          let newFileName = i + attach.file.name;
          //const data = await ReactS3Client.uploadFile(attach.file, newFileName);
          await ReactS3Client.uploadFile(attach.file, newFileName)
            .then((data) => {
              console.log(data);
              if (data.status === 204) {
                const attachment = {
                  attachment_type: attach.attachment_type,
                  attachment_extension: attach.attachment_extension,
                  attachment_original_url: data.location,
                };
                console.log(attachment);
                finalAttachments.push(attachment);
              }
            })
            .catch((err) => {
              setloaderSpinner(false);
              console.log("Error", err);
              if (navigator.onLine) {
                // toast(JSON.stringify(error));
              } else {
                toast.error("You are offline now");
                return false;
              }
            });
        })
      ).catch((error) => {
        toast(error);
      });
    }
    console.log(postAttachments);
    console.log(finalAttachments);
    if (postAttachments.length != finalAttachments.length) {
      setloaderSpinner(false);
      if (navigator.onLine) {
        toast.error(
          "we are unable to save data right now. Please try after sometime"
        );
      } else {
        //toast.error("You are offline now");
      }
      return false;
    }
    if (selectedSpec.length != 0) {
      let speciality_ids = specialities.filter((itemA) => {
        return selectedSpec.find((itemB) => {
          return itemA.speciality_name === itemB;
        });
      });
      console.log(speciality_ids);
      speciality_ids.map((speciality) => {
        finalSpecialities.push(speciality.speciality_id);
      });
    }
    if (selected.length != 0) {
      let department_ids = departments.filter((itemA) => {
        return selected.find((itemB) => {
          return itemA.department_name === itemB;
        });
      });
      department_ids.map((department) => {
        finalDepartments.push(department.department_id);
      });
      console.log(department_ids);
    }
    let feed_type = "";
    if (modalTitle == "Post A Case") {
      feed_type = "case";
    } else if (modalTitle == "Share News/Announcements") {
      feed_type = "post";
    } else if (modalTitle == "Ask A Question") {
      feed_type = "post";
    }
    //if (post.description == undefined) post.description = "";
    console.log(quilContent);
    if (quilContent == "<p><br></p>") {
      quilContent = "";
    }
    let payload = {
      doc_id: doc_id,
      department_ids: finalDepartments.length == 0 ? [] : finalDepartments,
      feed_provider_type: selectedShare.feed_provider_type,
      feed_type: feed_type,
      title: modalTitle == "Ask A Question" ? post.question : post.title,
      channel_ids: [selectedShare.channel_id],
      attachment_details: finalAttachments,
      speciality_ids: finalSpecialities.length == 0 ? [] : finalSpecialities,
      isRetry: false,
      feed_desc: modalTitle == "Ask A Question" ? "" : quilContent,
    };
    if (modalTitle == "Share News/Announcements") {
      payload.feed_sub_type = "update";
    } else if (modalTitle == "Ask A Question") {
      payload.feed_sub_type = "question";
    }
    payload = encodeURIComponent(JSON.stringify(payload));
    console.log(payload);
    const res = await fetch(baseUrl + "v5/community/makePost", {
      method: "POST",
      headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      setPostSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      history.replace({
        pathname: `/feed/${data.data.feed_info.feed_id}/${data.data.channel_id}`,
        state: {
          background: background,
          profile_pic: profile_pic,
          channels: [],
          user_verification_info: user_verification_info,
          feedContext: getFeedContext(),
        },
      });
      setPostSpinner(false);
      // showSuccessView();
    } else {
      setPostSpinner(false);
      toast.error(data.errorMsg);
    }
  };

  const getFeedContext = () => {
    if (background?.pathname.includes("/profile")) return "profile";
    if (background?.pathname === "/professional") return "professional";
    if (background?.pathname === "/knowledge") return "knowledge";
    if (background?.pathname === "/community") return "community";
    else return "dashboard";
  };

  const getHelpTextHeader = () => {
    if (modalTitle === "Ask A Question")
      return "Get answers/tips from your peers from the medical community";
    else if (modalTitle === "Share News/Announcements")
      return "Share latest/useful developments in medicine or your work";
    else if (modalTitle === "Post A Case")
      return "Share and discuss interesting medical cases";
    else return "";
  };

  const getHelpText = () => {
    if (modalTitle === "Ask A Question") return questionHelpText;
    else if (modalTitle === "Share News/Announcements") return newsHelpText;
    else if (modalTitle === "Post A Case") return postHelpText;
    else return "";
  };

  const displayCommunitySubHeader = () => {
    let count = 0;
    channelsData.map((channel) => {
      if (channel.is_admin && count === 0) {
        count++;
      }
    });
    if (count > 0)
      return (
        <ListSubheader
          style={{
            margin: "5px",
            borderRadius: "8px",
            font: "bold 14px/20px roboto",
            padding: "5px 5px 2px 10px",
          }}
        >
          <ListItemText>
            <span className="menu-head">Share With a Group</span>
            <br></br>
            <span className="sub-head">
              Visible only to your community group members such as association
              members
            </span>
          </ListItemText>
        </ListSubheader>
      );
    else return null;
  };

  const renderShareWith = (option) => {
    if (option == null) return <span>select</span>;

    return (
      <ListItemText classes={{ root: "remove-margin" }}>
        <span className="menu-head">{option.feed_provider_name}</span>
      </ListItemText>
    );
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "white",
          zIndex: 10,
          // overflow: "auto",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100vw",
        }}
      >
        <div
          style={{
            position: "-webkit-sticky",
            position: "relative",
            top: "0",
            left: "0",
            width: "100%",
            zIndex: "25",
            flexShrink: "0",
            marginBottom: "10px",
          }}
          className="mobile-header-wrapper sticky d-block d-lg-none"
        >
          <div
            className="mobile-header position-relative "
            style={{
              backgroundColor: "#469b8d",
              font: "bold 20px / 20px roboto",
              textAlign: "center",
            }}
          >
            <div className="mobile-responsive">
              <div className="brand-logo " style={{ width: "106%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0 1em",
                  }}
                >
                  <IoArrowBackOutline
                    color="white"
                    size={25}
                    onClick={() => history.goBack()}
                  />
                  <h2 style={{ color: "white", marginLeft: "1.5em" }}>
                    {modalTitle}
                  </h2>
                </div>
                {/* <img
                  style={{ width: "25px", marginLeft: "5px" }}
                  onClick={() => history.goBack()}
                  src={back}
                  alt="timeline logo"
                /> */}
                {/* <div className="row">
                  <div className="col-3"> */}
                {/* <img
                      style={{
                        cursor: "pointer",
                        marginTop: "5px",
                        height: "25px",
                        marginLeft: "15px",
                      }}
                     
                      src={back}
                      alt="back"
                    /> */}

                {/* </div> */}
                {/* <div
                    className="col-9"
                    style={{
                      textAlign: "initial",
                      marginTop: "5px",
                      color: "white",
                      font: "normal normal medium Roboto",
                      fontSize: "20px",
                    }}
                  > */}

                {/* </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        {attachError && (
          <div style={{ color: "red" }}>
            {" "}
            Please upload image,pdf,video or audio files only
          </div>
        )}
        {sizeError && (
          <div style={{ color: "red" }}>
            {" "}
            File size too Big, please select a file less than 50MB
          </div>
        )}
        <div>
          {" "}
          <Progressbar
            show={loaderSpinner}
            imageUrl={url}
            height="90"
            width="90"
            alignment="middle"
            alttext="Loading..."
          />
        </div>
        {!loaderSpinner && (
          <div
            className="post-thumb-gallery img-gallery"
            style={{ overflowY: "auto" }}
          >
            <div className="row no-gutters">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12 ">
                    {/* <div className="row" style={{marginBottom:"20px"}}>
                <div className="col-sm-2"></div>
                <div className="col-sm-8">
                  <div>
                    <h2 style={{ color: "black",textAlign:"center" }}>{modalTitle}</h2>
                  </div>
                </div>
                <div className="col-sm-1"></div>
              </div> */}

                    <div className="row">
                      <div className="col-sm-1"></div>
                      <div className="col-sm-10">
                        <div className="post-modal-help-text-header">
                          {getHelpTextHeader()}
                        </div>
                        <div className="post-modal-help-text">
                          {getHelpText()}
                        </div>
                        <form>
                          {modalTitle == "Ask A Question" && (
                            <section>
                              <div
                                style={{
                                  margin: "0 0 1.5em 0",
                                }}
                              >
                                <Controller
                                  name="question"
                                  control={control}
                                  rules={{ required: true, maxLength: 500 }}
                                  render={({ field }) => (
                                    // <CssTextField
                                    //   {...field}
                                    //   style={{
                                    //     borderRadius: "9px",
                                    //   }}
                                    //   InputProps={{
                                    //     className: classes.input,
                                    //   }}
                                    //   id="outlined-basic"
                                    //   label="Type Your Question"
                                    //   variant="outlined"
                                    //   fullWidth
                                    //   size="small"
                                    // />
                                    <CssTextField
                                      {...field}
                                      fullWidth
                                      label="Type Your Question"
                                      variant="outlined"
                                      InputLabelProps={{
                                        style: {
                                          color: question
                                            ? question.length === 0
                                              ? "rgb(0, 0, 0, 0.6)"
                                              : "#469b8d"
                                            : "rgb(0, 0, 0, 0.6)",
                                          fontSize: 11,
                                        },
                                      }}
                                      size="small"
                                      multiline
                                      id="outlined-basic"
                                      rows={2}
                                      inputProps={{
                                        style: {
                                          fontSize: 15,
                                          height: "18px",
                                          padding: "5px",
                                        },
                                        maxLength: 500,
                                      }}
                                    />
                                  )}
                                />
                                <InputTextLimit
                                  control={control}
                                  name={"question"}
                                />
                                {/* <div
                                  className="post-modal-help-text"
                                  style={{
                                    textAlign: "right",
                                    margin: "0.3em 0.3em 0.3em",
                                  }}
                                >
                                  500
                                </div> */}
                                {errors.question?.type === "required" && (
                                  <div className="row">
                                    <span
                                      className="error-title-css"
                                      style={{
                                        margin: "5px 0px 0px 22px !important",
                                      }}
                                    >
                                      Please enter your question
                                    </span>
                                  </div>
                                )}
                                {errors.question?.type === "maxLength" && (
                                  <div className="row">
                                    <span
                                      className="error-title-css"
                                      style={{
                                        margin: "5px 0px 0px 22px !important",
                                      }}
                                    >
                                      Ask a question max length is 500
                                    </span>
                                  </div>
                                )}
                              </div>
                            </section>
                          )}
                          {modalTitle != "Ask A Question" && (
                            <section>
                              <div
                                style={{
                                  margin: "0 0 1.5em 0",
                                }}
                              >
                                <Controller
                                  name="title"
                                  control={control}
                                  rules={{ required: true, maxLength: 500 }}
                                  render={({ field }) => (
                                    <CssTextField
                                      {...field}
                                      fullWidth
                                      label="Title"
                                      variant="outlined"
                                      size="small"
                                      InputLabelProps={{
                                        style: {
                                          color: title
                                            ? title.length === 0
                                              ? "rgb(0, 0, 0, 0.6)"
                                              : "#469b8d"
                                            : "rgb(0, 0, 0, 0.6)",
                                          fontSize: 14,
                                        },
                                      }}
                                      multiline
                                      id="outlined-basic"
                                      rows={2}
                                      inputProps={{
                                        style: {
                                          fontSize: 15,
                                          height: "18px",
                                          padding: "5px",
                                        },
                                        maxLength: 500,
                                      }}
                                    />
                                  )}
                                />
                                <InputTextLimit
                                  control={control}
                                  name={"title"}
                                />
                                {errors.title?.type === "required" && (
                                  <div className="row">
                                    <span
                                      className="error-title-css"
                                      style={{
                                        margin: "5px 0px 0px 22px !important",
                                      }}
                                    >
                                      Please enter your title
                                    </span>
                                  </div>
                                )}
                                {errors.title?.type === "maxLength" && (
                                  <div className="row">
                                    <span
                                      className="error-title-css"
                                      style={{
                                        margin: "5px 0px 0px 22px !important",
                                      }}
                                    >
                                      Title max length is 500
                                    </span>
                                  </div>
                                )}
                              </div>
                            </section>
                          )}
                          {modalTitle != "Ask A Question" && (
                            <section>
                              <div
                                style={{
                                  margin: "0 0 1.5em 0",
                                }}
                              >
                                <Controller
                                  name="description"
                                  control={control}
                                  render={({ field }) => (
                                    // <CssTextField
                                    //   {...field}
                                    //   fullWidth
                                    //   label="Description (optional)"
                                    //   variant="outlined"
                                    //   size="small"
                                    //   multiline
                                    //   rows={3}
                                    // />
                                    <ReactQuill
                                      placeholder="Description (optional)"
                                      theme="snow"
                                      onChange={quilChange}
                                      modules={modules}
                                      formats={formats}
                                    />
                                  )}
                                />
                              </div>
                            </section>
                          )}

                          {/* <div className="post-card">
                            <div>
                              <div className="row" style={{ padding: "5px" }}>
                                <div className="col-sm-8">
                                  <label style={{ color: "#7c7a7a" }}>
                                    Attach Image/Pdf/Video/Audio
                                  </label>
                                </div>
                                <div
                                  className="col-sm-3"
                                  style={{ textAlign: "right" }}
                                >
                                  <img
                                    src={clipImage}
                                    style={{
                                      height: "15px",
                                    }}
                                    alt="paper clip logo"
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ padding: "5px" }}>
                                <div className="col-sm-8">
                                  <h6 style={{ color: "#7c7a7a" }}>
                                    Max 5 Attachments
                                  </h6>
                                </div>
                                <div
                                  className="col-sm-3"
                                  style={{ textAlign: "right" }}
                                ></div>
                              </div>
                              <div style={{ display: "flex" }}>
                                {postAttachments.length != 5 && (
                                  <div
                                    className="upload-plus"
                                    style={{ width: "70px" }}
                                  >
                                    <div
                                      className="row"
                                      style={{ height: "20px" }}
                                    >
                                      <div className="col-sm-12"></div>
                                    </div>
                                    <div
                                      className="row"
                                      style={{ textAlign: "center" }}
                                    >
                                      <div className="col-sm-12">
                                        <img
                                          style={{
                                            width: "30px",
                                            height: "30px",
                                            cursor: "pointer",
                                          }}
                                          src={plusImage}
                                          alt="plus"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            fileInput.current.click();
                                          }}
                                        />
                                        <input
                                          type="file"
                                          ref={fileInput}
                                          onChange={() => showImagePreview()}
                                          style={{
                                            display: "none",
                                          }}
                                          className="submit-btn"
                                          accept=".pdf, image/* ,audio/* ,video/*"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {postAttachments.length <= 5 && (
                                  <div className="wrapper">
                                    {postAttachments.map((attach, index) => (
                                      <div
                                        className="upload-body"
                                        style={{ width: "70px" }}
                                        key={index}
                                      >
                                        <div className="row">
                                          <div
                                            className="col-sm-12"
                                            style={{ textAlign: "right" }}
                                          >
                                            <img
                                              style={{
                                                width: "15px",
                                                height: "15px",
                                              }}
                                              src={closeImage}
                                              alt="knowledge"
                                              onClick={(e) =>
                                                removeFile(e, index)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className="row"
                                          style={{ textAlign: "center" }}
                                        >
                                          <div className="col-sm-12">
                                            <img
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                              }}
                                              src={
                                                attach.attachment_type ==
                                                "image"
                                                  ? attach.attachment_original_url
                                                  : attach.attachment_type ==
                                                      "video" ||
                                                    attach.attachment_type ==
                                                      "audio"
                                                  ? playImage
                                                  : attach.attachment_type ==
                                                    "pdf"
                                                  ? pdfImage
                                                  : attach.attachment_original_url
                                              }
                                              alt="post"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                showPreview(attach);
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className="row"
                                          style={{ maxWidth: "100%" }}
                                        >
                                          <div
                                            className="col-sm-5"
                                            style={{
                                              margin: "5px 0px 0px 18px",
                                              backgroundColor: "#1e8a79",
                                              height: "20px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                cursor: "pointer",
                                              }}
                                            >
                                              {" "}
                                              <span>
                                                <label
                                                  htmlFor={`file-upload-${index}`}
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <img
                                                    style={{
                                                      marginLeft: "-10px",
                                                      height: "18px",
                                                    }}
                                                    src={editImage}
                                                    alt="post"
                                                  />
                                                  <span
                                                    style={{
                                                      color: "white",
                                                      fontSize: "10px",
                                                      margin:
                                                        "5px 0px  0px 3px",
                                                      position: "absolute",
                                                    }}
                                                  >
                                                    {" "}
                                                    Edit
                                                  </span>
                                                </label>
                                                <input
                                                  id={`file-upload-${index}`}
                                                  type="file"
                                                  onChange={(e) =>
                                                    editFile(e, index)
                                                  }
                                                  style={{
                                                    display: "none",
                                                  }}
                                                  className="submit-btn"
                                                  accept=".pdf, image/* ,audio/* ,video/*"
                                                />
                                              </span>
                                            </div>
                                          </div>
                                          <div
                                            className="col-sm-4"
                                            style={{
                                              textAlign: "left",
                                              marginBottom: "5px",
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div> */}

                          <div className="post-modal-attachments">
                            <div
                              style={{ color: "#5d5d5d", fontSize: "0.9em" }}
                            >
                              Attach Image/PDF/Video/Audio
                            </div>
                            <div
                              className="post-modal-help-text"
                              style={{ margin: 0 }}
                            >
                              Max 5 Attachments
                            </div>

                            <div className="post-modal-file-container">
                              {postAttachments.length <= 5 &&
                                postAttachments.map((attach, index) => (
                                  <div className="post-modal-file">
                                    <div className="post-modal-file-delete">
                                      <img
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                          cursor: "pointer",
                                        }}
                                        src={deleteIcon}
                                        onClick={(e) => removeFile(e, index)}
                                      />
                                    </div>
                                    <img
                                      style={{
                                        height: " 150px",
                                        width: "150px",
                                        borderRadius: "5px",
                                      }}
                                      src={
                                        attach.attachment_type == "image"
                                          ? attach.attachment_original_url
                                          : attach.attachment_type == "video" ||
                                            attach.attachment_type == "audio"
                                          ? playImage
                                          : attach.attachment_type == "pdf"
                                          ? pdfImage
                                          : attach.attachment_original_url
                                      }
                                      alt="post"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        showPreview(attach);
                                      }}
                                    />
                                    <div>
                                      <label
                                        htmlFor={`file-upload-${index}`}
                                        style={{
                                          cursor: "pointer",
                                          margin: "0",
                                        }}
                                      >
                                        <div className="post-modal-file-edit">
                                          <MdEdit size={16} />
                                          Edit
                                        </div>
                                      </label>
                                      <input
                                        id={`file-upload-${index}`}
                                        type="file"
                                        onChange={(e) => editFile(e, index)}
                                        style={{
                                          display: "none",
                                        }}
                                        className="submit-btn"
                                        accept=".pdf, image/* ,audio/* ,video/*"
                                      />
                                    </div>
                                  </div>
                                ))}

                              {postAttachments.length != 5 && (
                                <div className="post-modal-file-upload">
                                  <div>
                                    <img
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        cursor: "pointer",
                                      }}
                                      src={plusImage}
                                      alt="knowledge"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        fileInput.current.click();
                                      }}
                                    />
                                    <input
                                      type="file"
                                      ref={fileInput}
                                      onChange={() => showImagePreview()}
                                      style={{
                                        display: "none",
                                      }}
                                      className="submit-btn"
                                      accept=".pdf, image/* ,audio/* ,video/*"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <section>
                            <div
                              style={{
                                width: "100%",
                                margin: "1em 0 1.5em 0",
                              }}
                            >
                              {specialities && specialities.length !== 0 && (
                                <div
                                  className="post-modal-help-text"
                                  style={{
                                    textAlign: "right",
                                    margin: "0.3em 0",
                                  }}
                                >
                                  Where do you wish to publish this post?
                                </div>
                              )}
                              {specialities && specialities.length !== 0 && (
                                <Controller
                                  name="special"
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field }) => (
                                    <FormControl fullWidth size="small">
                                      <InputLabel
                                        style={{
                                          color:
                                            selectedSpec.length === 0
                                              ? "rgb(0, 0, 0 , 0.6)"
                                              : "#469b8d",
                                        }}
                                        id="demo-multiple-checkbox-label"
                                      >
                                        Post On
                                      </InputLabel>
                                      <Select
                                        {...field}
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={selectedSpec}
                                        onChange={handleSpecial}
                                        input={
                                          <OutlinedInput label="Post On" />
                                        }
                                        renderValue={renderSpecValue}
                                        MenuProps={MenuProps}
                                        className={classes.selectBorder}
                                      >
                                        <MenuItem
                                          value="all"
                                          classes={{
                                            root: isAllSelectedSpec
                                              ? classes.selectedAll
                                              : "",
                                          }}
                                          style={{
                                            display: "flex",
                                            minHeight: "38px",
                                            marginLeft: "10px",
                                            backgroundColor: "white",
                                          }}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.selectAllText,
                                            }}
                                            primary="All Specialities"
                                            primaryTypographyProps={{
                                              style: { fontSize: 12 },
                                            }}
                                          />
                                          <ListItemIcon>
                                            <Checkbox
                                              style={{
                                                color: "#1e8a79",
                                                "&.Mui-checked": {
                                                  color: "#1e8a79",
                                                },
                                              }}
                                              classes={{
                                                indeterminate:
                                                  classes.indeterminateColor,
                                              }}
                                              checked={isAllSelectedSpec}
                                            />
                                          </ListItemIcon>
                                        </MenuItem>
                                        {specialitiesValue.map((option, i) => (
                                          <MenuItem
                                            id={i}
                                            value={option}
                                            style={{
                                              display: "flex",
                                              minHeight: "38px",
                                              marginLeft: "10px",
                                              backgroundColor: "white",
                                            }}
                                            key={i}
                                          >
                                            <ListItemText
                                              primary={option}
                                              primaryTypographyProps={{
                                                style: {
                                                  whiteSpace: "normal",
                                                  fontSize: 12,
                                                },
                                              }}
                                            />
                                            <ListItemIcon>
                                              <Checkbox
                                                style={{
                                                  color: "#1e8a79",
                                                  "&.Mui-checked": {
                                                    color: "#1e8a79",
                                                  },
                                                }}
                                                checked={
                                                  selectedSpec.indexOf(option) >
                                                  -1
                                                }
                                              />
                                            </ListItemIcon>
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  )}
                                />
                              )}
                              {errors.special && (
                                <div className="row">
                                  <span
                                    className="error-title-css"
                                    style={{
                                      margin: "5px 0px 0px 22px !important",
                                    }}
                                  >
                                    Select at least one speciality
                                  </span>
                                </div>
                              )}
                            </div>
                          </section>

                          {/* <section>
                            <label style={{ color: "#7c7a7a" }}>
                              Share With
                            </label>
                            <div
                              style={{
                                borderRadius: "9px",
                                height: "1.5em",
                                width: "100%",
                                margin: "0 0 2em 0",
                              }}
                            >
                              <Controller
                                name="share"
                                control={control}
                                render={({ field }) => (
                                  <FormControl fullWidth>
                                    <Select
                                      {...field}
                                      id="grouped-select"
                                      label=""
                                      input={<BootstrapInput />}
                                      value={selectedShare}
                                      onChange={handleShare}
                                      disabled={
                                        defaultChannel != 0 ? true : false
                                      }
                                    >
                                      {channelsData.map((channel, i) => {
                                        if (
                                          channel.feed_provider_type ===
                                          "Network"
                                        ) {
                                          return (
                                            <MenuItem
                                              style={{
                                                color: "rgb(30, 138, 121)",
                                                backgroundColor:
                                                  "rgb(221, 253, 247)",
                                                margin: "5px",
                                                borderRadius: "8px",
                                                font: "bold 14px/20px roboto",
                                                padding: "5px 5px 2px 10px",
                                                marginLeft: "15px",
                                                display: "flex",
                                              }}
                                              value={channel}
                                              key={channel.channel_id}
                                            >
                                              <ListItemText>
                                                <span className="menu-head">
                                                  {channel.feed_provider_name}
                                                </span>
                                                <br></br>
                                                <span className="sub-head">
                                                  Visible to all members on
                                                  WhiteCoats Network
                                                </span>
                                              </ListItemText>
                                            </MenuItem>
                                          );
                                        }
                                      })}

                                      {channelsData.map((channel, index) => {
                                        if (channel.is_admin && index == 1) {
                                          return (
                                            <ListSubheader
                                              style={{
                                                margin: "5px",
                                                borderRadius: "8px",
                                                font: "bold 14px/20px roboto",
                                                padding: "5px 5px 2px 10px",
                                              }}
                                              key={channel.channel_id}
                                            >
                                              <ListItemText>
                                                <span className="menu-head">
                                                  With Group
                                                </span>
                                                <br></br>
                                                <span className="sub-head">
                                                  Visible only to your community
                                                  group members such as
                                                  association members
                                                </span>
                                              </ListItemText>
                                            </ListSubheader>
                                          );
                                        }
                                      })}
                                      {channelsData.map((channel) => {
                                        if (channel.is_admin) {
                                          return (
                                            <MenuItem
                                              style={{
                                                backgroundColor: "white",
                                                color: "rgb(30, 138, 121)",
                                                margin: "5px",
                                                borderRadius: "8px",
                                                font: "13px/20px roboto",
                                                padding: "5px 5px 2px 10px",
                                                marginLeft: "15px",
                                                display: "flex",
                                              }}
                                              key={channel.channel_id}
                                              value={channel}
                                            >
                                       
                                              <ListItemText
                                                style={{ paddingTop: "10px" }}
                                              >
                                                <span className="menu-head">
                                                  {channel.feed_provider_name}
                                                </span>

                                              </ListItemText>
                                            </MenuItem>
                                          );
                                        }
                                      })}
                                    </Select>
                                  </FormControl>
                                )}
                              />
                            </div>
                          </section> */}

                          <div>
                            <div
                              className="post-modal-help-text"
                              style={{
                                textAlign: "right",
                                margin: "0.3em 0",
                              }}
                            >
                              Who should be able to see your post?
                            </div>
                            <Controller
                              name="share"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <FormControl fullWidth size="small">
                                  <InputLabel
                                    style={{
                                      color:
                                        selectedShare.length === 0
                                          ? "rgb(0, 0, 0 , 0.6)"
                                          : "#469b8d",
                                    }}
                                    id="share-dropdown"
                                  >
                                    Share with
                                  </InputLabel>
                                  <Select
                                    labelId="share-dropdown"
                                    {...field}
                                    value={selectedShare}
                                    onChange={handleShare}
                                    disabled={
                                      defaultChannel != 0 ? true : false
                                    }
                                    input={<OutlinedInput label="Share with" />}
                                    MenuProps={MenuProps}
                                    className={classes.selectBorder}
                                    renderValue={renderShareWith}
                                  >
                                    {channelsData.map((channel, i) => {
                                      if (
                                        channel.feed_provider_type === "Network"
                                      ) {
                                        return (
                                          <MenuItem
                                            style={{
                                              margin: "5px",
                                              borderRadius: "8px",
                                              font: "bold 14px/20px roboto",
                                              padding: "5px 5px 2px 10px",
                                            }}
                                            value={channel}
                                            key={channel.channel_id}
                                          >
                                            <ListItemText
                                              classes={{
                                                root: "remove-margin",
                                              }}
                                            >
                                              <span
                                                className="menu-head"
                                                style={{ color: "#1e8a79" }}
                                              >
                                                Share with{" "}
                                                {channel.feed_provider_name}
                                              </span>
                                              <br></br>
                                              <span className="sub-head">
                                                Visible to all members on
                                                WhiteCoats Network
                                              </span>
                                            </ListItemText>
                                          </MenuItem>
                                        );
                                      }
                                    })}
                                    {/* {channelsData.map((channel, index) => {
                                      if (channel.is_admin && index == 1) {
                                        return (
                                          <ListSubheader
                                            style={{
                                              margin: "5px",
                                              borderRadius: "8px",
                                              font: "bold 14px/20px roboto",
                                              padding: "5px 5px 2px 10px",
                                            }}
                                            key={channel.channel_id}
                                          >
                                            <ListItemText>
                                              <span className="menu-head">
                                                With Group
                                              </span>
                                              <br></br>
                                              <span className="sub-head">
                                                Visible only to your community
                                                group members such as
                                                association members
                                              </span>
                                            </ListItemText>
                                          </ListSubheader>
                                        );
                                      }
                                    })} */}
                                    {displayCommunitySubHeader()}
                                    {channelsData.map((channel) => {
                                      if (channel.is_admin) {
                                        return (
                                          <MenuItem
                                            style={{
                                              backgroundColor: "white",
                                              marginLeft: "15px",
                                              display: "flex",
                                            }}
                                            key={channel.channel_id}
                                            value={channel}
                                          >
                                            <ListItemText
                                              classes={{
                                                root: "remove-margin",
                                              }}
                                            >
                                              <span
                                                className="menu-head"
                                                style={{ color: "#1e8a79" }}
                                              >
                                                {channel.feed_provider_name}
                                              </span>
                                            </ListItemText>
                                          </MenuItem>
                                        );
                                      }
                                    })}
                                  </Select>
                                </FormControl>
                              )}
                            />
                          </div>

                          <section>
                            <div
                              style={{
                                width: "100%",
                                margin: "1em 0 1.5em 0",
                              }}
                            >
                              {departments && departments.length !== 0 && (
                                <div
                                  className="post-modal-help-text"
                                  style={{
                                    textAlign: "right",
                                    margin: "0.3em 0",
                                  }}
                                >
                                  Who should be able to see your post?
                                </div>
                              )}
                              {departments && departments.length !== 0 && (
                                <Controller
                                  name="post"
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field }) => (
                                    <FormControl fullWidth size="small">
                                      <InputLabel
                                        style={{
                                          color:
                                            selected.length === 0
                                              ? "rgb(0, 0, 0 , 0.6)"
                                              : "#469b8d",
                                        }}
                                        id="demo-multiple-checkbox-label"
                                      >
                                        Select Department
                                      </InputLabel>
                                      <Select
                                        {...field}
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={selected}
                                        onChange={handleChange}
                                        input={
                                          <OutlinedInput label="Select Department" />
                                        }
                                        renderValue={renderPostValue}
                                        MenuProps={MenuProps}
                                        className={classes.selectBorder}
                                      >
                                        <MenuItem
                                          value="all"
                                          classes={{
                                            root: isAllSelected
                                              ? classes.selectedAll
                                              : "",
                                          }}
                                          style={{
                                            display: "flex",
                                            minHeight: "38px",
                                            marginLeft: "10px",
                                            backgroundColor: "white",
                                          }}
                                        >
                                          <ListItemText
                                            classes={{
                                              primary: classes.selectAllText,
                                            }}
                                            primary="All Members"
                                            primaryTypographyProps={{
                                              style: {
                                                whiteSpace: "normal",
                                                fontSize: 12,
                                              },
                                            }}
                                          />
                                          <ListItemIcon>
                                            <Checkbox
                                              classes={{
                                                indeterminate:
                                                  classes.indeterminateColor,
                                              }}
                                              style={{
                                                color: "#1e8a79",
                                                "&.Mui-checked": {
                                                  color: "#1e8a79",
                                                },
                                              }}
                                              checked={isAllSelected}
                                            />
                                          </ListItemIcon>
                                        </MenuItem>
                                        {departmentValue.map((option, i) => (
                                          <MenuItem
                                            id={i}
                                            value={option}
                                            style={{
                                              display: "flex",
                                              minHeight: "38px",
                                              marginLeft: "10px",
                                              backgroundColor: "white",
                                            }}
                                            key={i}
                                          >
                                            <ListItemText
                                              primary={option}
                                              primaryTypographyProps={{
                                                style: {
                                                  whiteSpace: "normal",
                                                  fontSize: 12,
                                                },
                                              }}
                                            />
                                            <ListItemIcon>
                                              <Checkbox
                                                checked={
                                                  selected.indexOf(option) > -1
                                                }
                                                style={{
                                                  color: "#1e8a79",
                                                  "&.Mui-checked": {
                                                    color: "#1e8a79",
                                                  },
                                                }}
                                              />
                                            </ListItemIcon>
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  )}
                                />
                              )}
                              {departments &&
                                departments.length !== 0 &&
                                errors.post?.type === "required" && (
                                  <div className="row">
                                    <span
                                      className="error-title-css"
                                      style={{
                                        margin: "5px 0px 0px 22px !important",
                                      }}
                                    >
                                      Select at least one member group
                                    </span>
                                  </div>
                                )}
                            </div>
                          </section>

                          <div>
                            <Progressbar
                              show={postSpinner}
                              imageUrl={url}
                              height="90"
                              width="90"
                              alignment="middle"
                              alttext="Loading..."
                            />
                          </div>

                          {/* <div className="button-container">
                            <button
                              type="submit"
                              style={{
                                backgroundColor: "39B54A",
                                borderRadius: "9px",
                                marginTop: 0,
                              }}
                              className="submit-btn"
                            >
                              Post
                            </button>
                          </div> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className="post-modal-submit"
          style={{ marginBottom: 0, height: "6em" }}
        >
          <button
            type="submit"
            style={{ backgroundColor: "#469b8d" }}
            onClick={handleSubmit(postSubmit)}
            className="post-modal-submit-button"
          >
            POST
          </button>
        </div>
      </div>

      <Modal
        open={openPostSuccessView}
        blockScroll={true}
        closeIcon={closeIconHide}
        classNames={{ modal: "postSuccess" }}
      >
        <div className="container-fluid">
          <div
            className="row"
            style={{ backgroundColor: "#1e8a79", height: "301x" }}
          >
            <div
              className="col-sm-12 "
              style={{ marginTop: "30px", marginBottom: "30px" }}
            >
              <img
                src={doneImage}
                style={{
                  cursor: "pointer",
                  height: "40px",
                }}
                alt="done"
              />
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "white",
                  font: "20px/20px roboto",
                  marginBottom: "20px",
                }}
              >
                Post has been created successfully.
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "white",
                  marginBottom: "30px",
                  font: "16px/20px roboto",
                }}
              >
                {" "}
                <p>Thank you for your contribution to the</p>
                <p>medical community</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              <button
                type="button"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "5px",
                  margin: "auto",
                  width: "30%",
                  marginBottom: "115px",
                }}
                onClick={(e) => hideSuccessView(e)}
                className="submit-btn"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={previewModal}
        onClose={closePreviewModal}
        classNames={{ modal: "attach-model-res" }}
        closeIcon={closeIcon}
      >
        {previewAttachment && previewAttachment.attachment_type === "image" && (
          <img
            src={previewAttachment.attachment_original_url}
            style={{ height: "100%", width: "100%" }}
          ></img>
        )}
        {previewAttachment && previewAttachment.attachment_type === "video" && (
          <span>
            <video
              preload="metadata"
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "100% !important",
              }}
              controls="controls"
              ref={videoRef}
            >
              <source
                src={`${previewAttachment.attachment_original_url}#t=0.1`}
                type="video/mp4"
              />
            </video>
            <span ref={videoLoader}></span>
          </span>
        )}
        {previewAttachment && previewAttachment.attachment_type === "audio" && (
          <span>
            <ReactAudioPlayer
              style={{ border: "1px solid #f1f1f1" }}
              controls
              src={previewAttachment.attachment_original_url}
              // style={{
              //   maxHeight: "100% !important",
              // }}
              ref={(element) => {
                audioRef.current = element;
              }}
            />
            <span ref={audioLoader}></span>
          </span>
        )}
        {previewAttachment && previewAttachment.attachment_type === "pdf" && (
          <object
            data={previewAttachment.attachment_original_url}
            type="application/pdf"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <iframe
              style={{
                width: "100%",
                height: "100%",
              }}
              src={previewAttachment.attachment_original_url}
            />
          </object>
        )}
      </Modal>
    </>
  );
};

const questionHelpText = `Examples:
I have started a new hospital in Jaipur 2 years ago. Does anyone have references for accreditation consultants?

I am a pediatrician with 3 years of experience. Which college/hospital is the best for pediatric cardiac surgery in India?

I have a new practice in Pune. What is the best way to get patients online without commisions?

How to manage postpartum hemorrhage in a primi with RHD?`;

const newsHelpText = `Examples:
Check out the latest advisory from WHO on how to tackle long COVID in diabetic patients.

Bangalore Ophthalmic Society advises doctors against tie-ups with online platforms that charges commissions for patients.

I am looking to hire 3 medical officers for my new hospital in Indore. Please reach out if interested.
`;

const postHelpText = `Examples:
A 72-year old female presented with acute mental status and one week h/o periobital eye and neck swelling. She's a known case of stage 3 myeloma post stem cell transplant. On admission, she had bilateral optic disc edema, UTI. I am attaching how I managed this case and how the patient responded. Hope this helps the community.`;

export default MobileCreatepost;
