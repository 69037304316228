import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import {
  getDocId,
  config,
  getHeaders,
  getTime,
  getTimeAndDate,
  getDate,
  closeIcon,
  getFeedTypeColor,
} from "../../utils/utils";
import Progressbar from "../../progressbar";
import likeUnselected from "../../assets/dashboard_icons/social-icons/Like.svg";
import likeSelected from "../../assets/dashboard_icons/selected/Like.svg";
import commentUnselected from "../../assets/dashboard_icons/social-icons/Comment.svg";
import toast from "react-hot-toast";
import ReactAudioPlayer from "react-audio-player";
import back from "../../assets/Icons/Icon feather-arrow-left@2x.png";
import select from "../../assets/Icons/Ellipse 32.svg";
import unselect from "../../assets/Icons/Ellipse 33.svg";
import MciVerificationStatus from "../../common/MciVerificationStatus";
import MciUpload from "../../common/MciUpload";
import { Modal } from "react-responsive-modal";
import audio_placeholder from "../../assets/audio_placeholder.png";
import profile_pic_placeholder from "../../assets/profile_icons/default-profile.png";
import useFeedDetails from "../hooks/useFeedDetails";
import MciUploadSuccess from "../../common/MciUploadSuccess";
import getWindowDimensions from "../../common/hooks/getWindowDimensions";
import call from "../../assets/sign_up_and_sign_in_icons/Call.svg";
import TabContext from "@material-ui/lab/TabContext";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import TabList from "@material-ui/lab/TabList";
import { Tabs, Tab, withStyles } from "@material-ui/core";
import TabPanel from "@material-ui/lab/TabPanel";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import Button from "@material-ui/core/Button";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import IconButton from "@material-ui/core/IconButton";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/input";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import CountrySelect from "../../authentication/RegisterComponents/CountrySelect";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import callIcon from "../../assets/sign_up_and_sign_in_icons/Call.svg";
import emailIcon from "../../assets/sign_up_and_sign_in_icons/Mail.svg";
import en from "react-phone-number-input/locale/en.json";
import S3 from "react-aws-s3";
import { parsePhoneNumber } from "react-phone-number-input";
import Select from "react-select";
import ListItemText from "@mui/material/ListItemText";
import { Select as StyledButton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemIcon from "@mui/material/ListItemIcon";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: 0,
      padding: "15px",
      marginBottom: "30px",
      webkitBoxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
      boxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
    },
    "& .MuiTypography-body1": {
      font: "normal normal normal 20px/20px Roboto",
      fontWeight: 400,
    },
    flexGrow: 1,
    backgroundColor: "#f1f1f1",
  },
  tabs: {
    "& .MuiBox-root": {
      padding: "15px",
      marginTop: "10px",
    },

    "& .MuiTabPanel-root": {
      padding: "24px",
      minHeight: "600px",
    },
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: "#2a8f80",
      height: "4px",
    },
    "& .MuiTabPanel-root": {
      padding: "2px",
    },
    "& .MuiTabs-root": {
      marginTop: "-31px",
    },
    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      /* font-size: 11px; */
      font: "13px / 40px Roboto",
    },
    "& .MuiTab-root": {
      minWidth: "60px",
    },

    flexGrow: 1,
    backgroundColor: "#f1f1f1",
  },
}));

const MobileFullViewFeed = ({
  profile_pic,
  feeds,
  setFeeds,
  channels,
  user_verification_info,
  feedContext,
  background,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { feed_id, channel_id } = useParams();

  const { feed, setFeed, error } = useFeedDetails(feed_id, channel_id);

  // let feed = {};
  // for (const item of feeds) {
  //   if (item.feed_info.feed_id === parseInt(feed_id)) {
  //     feed = item;
  //     break;
  //   }
  // }

  const [isRegisterClicked, setIsRegisterClicked] = useState(false);

  const [uploadModel, setUploadModel] = useState(false);
  const openUploadMci = () => setUploadModel(true);
  const closeUploadMci = () => setUploadModel(false);

  const [uploadMciSuccess, setUploadMciSuccess] = useState(false);
  const openUploadMciSuccess = () => setUploadMciSuccess(true);
  const closeUploadMciSuccess = () => setUploadMciSuccess(false);

  const [verificationStatusModal, setVerificaitonStatusModal] = useState(false);
  const openVerificationStatusModal = () => setVerificaitonStatusModal(true);
  const closeVerificationStatusModal = () => setVerificaitonStatusModal(false);

  const getChannel = (channel_id) => {
    const channel = channels.filter(
      (channel) => channel.channel_id === channel_id
    );
    return channel[0];
  };

  const channel = getChannel(feed.channel_id);
  const [surveyAnswer, setSurveyAnswer] = useState([]);
  const handleSurvey = (val, is_multi_select, checked) => {
    if (is_multi_select)
      if (checked) setSurveyAnswer((prev) => [...prev, val]);
      else
        setSurveyAnswer((prev) => {
          const index = prev.indexOf(val);
          return prev.splice(index, 1);
        });
    else {
      if (checked) setSurveyAnswer([val]);
      else setSurveyAnswer([""]);
    }
  };

  const [feedLabelColor, setFeedLabelColor] = useState("#888686");
  const firstRender = useRef(true);
  const [contactInputError, setContactInputError] = useState({
    error: false,
  });
  const doc_id = getDocId();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();

  const [displayImage, setDisplayImage] = useState();

  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const [value, setValue] = useState("1");
  const [spinner, setSpinner] = useState(false);

  const classes = useStyles();

  const [tabid, setTabid] = useState(1);
  const handleChange = (event, newValue) => {
    window.scrollTo(0, 0);
    setTabid(newValue);
    setValue(newValue);
  };
  const [appDealineDate, setAppDealineDate] = useState(false);
  const [jobApplication, setJobApplication] = useState(
    feed?.feed_info?.is_applied
  );
  const [preferredSpecialities, setPreferredSpecialities] = useState([]);
  const [preferredLocations, setPreferredLocations] = useState([]);

  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue: setFormValue,
    trigger,
    formState: { errors },
  } = useForm();
  const [uploadCoverLetter, setUploadCoverLetter] = useState();
  const [userInfo, setUserInfo] = useState([]);
  const cvFile = useRef(null);
  const coverLetter = useRef(null);

  const [applicationType, setApplicationType] = useState(
    feed.feed_info?.application_type
  );
  const [dataFormat, setDateFormat] = useState();
  const userPhone = localStorage.getItem("phone");
  const phoneNumber = userPhone && parsePhoneNumber(`+${userPhone}`);
  const [country, setCountry] = useState(
    phoneNumber ? phoneNumber.country : "IN"
  );

  const email = localStorage.getItem("email");
  const speciality = feed.feed_info?.specialization;
  const city = feed.feed_info?.location;
  //const experience=feed.feed_info.min_experience;
  const [cities, setCities] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [selectCity, setSelectCity] = useState();
  const [selectSpecilization, setSelectSpecilization] = useState();
  const [yearsOfExperience, setYearsOfExperience] = useState();
  const [cvName, setCvName] = useState(null);
  const [clName, setCLName] = useState(null);
  const DOC_ID = localStorage.getItem("doc_id");
  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    document.body.style.overflow = "hidden";
    updateViewCount(channel_id, feed_id);

    let experience = [];
    experience.push({ value: 0, label: "0-1 years" });
    for (let i = 1; i <= 70; i++) {
      experience.push({ value: i, label: `${i} years` });
    }

    setYearsOfExperience(experience);

    autoSuggestions();
    setJobApplication(feed?.feed_info?.is_applied);
    if (!feed.feed_info?.is_applied) applicationDeadline();

    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }
    // window.scrollTo(0, 0);

    window.addEventListener("resize", handleResize);
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    reset();

    setContactInputError({
      error: false,
    });
  }, [country]);

  useEffect(() => {
    const feedLabelColor = getFeedTypeColor(feedType);
    setFeedLabelColor(feedLabelColor);
    // getFeedLabelColor();
    autoSuggestions();
    getUserData();
    setJobApplication(feed?.feed_info?.is_applied);
    if (!feed.feed_info?.is_applied) applicationDeadline();

    if (feed?.feed_info?.feed_type?.toUpperCase() === "SURVEY") {
      if (feed?.feed_info?.feed_survey?.questions)
        setDisplayImage(
          feed?.feed_info?.feed_survey?.questions[0]?.attachment_details[0]
        );
    } else {
      if (feed?.feed_info?.attachment_details)
        setDisplayImage(feed?.feed_info?.attachment_details[0]);
    }

    if (feed.feed_info?.application_type === 2) {
      const phoneNumber = userPhone && parsePhoneNumber(`+${userPhone}`);
      if (phoneNumber) {
        setCountry(phoneNumber.country);
        setFormValue("phone", phoneNumber.number);
      }
      console.log(email);

      if (
        feed?.feed_info?.locations != undefined &&
        feed?.feed_info?.locations.length >= 2
      ) {
        let labelLocation = {
          location_id: -1,
          location: "Preferred Job Location",
        };
        if (
          feed?.feed_info?.locations.filter(
            (x) => x.location_id === labelLocation.location_id
          ).length === 0
        ) {
          feed?.feed_info?.locations.splice(0, 0, labelLocation);
        }

        const plocations = [feed?.feed_info?.locations[0].location_id];
        setFormValue("plocations", plocations);
        setPreferredLocations(plocations);
      }

      if (
        feed?.feed_info?.specializations != undefined &&
        feed?.feed_info?.specializations.length >= 2
      ) {
        let labelSpeciality = {
          specialization_id: -1,
          specialization: "Preferred  Speciality",
        };
        if (
          feed?.feed_info?.specializations.filter(
            (x) => x.specialization_id === labelSpeciality.specialization_id
          ).length === 0
        ) {
          feed?.feed_info?.specializations.splice(0, 0, labelSpeciality);
        }
        const pspecialities = [
          feed?.feed_info?.specializations[0].specialization_id,
        ];
        setFormValue("pspecialities", pspecialities);
        setPreferredSpecialities(pspecialities);
        console.log(preferredSpecialities);
      }
      if (feed?.feed_info?.specializations.length == 1) {
        const pspecialities = [
          feed?.feed_info?.specializations[0].specialization_id,
        ];
        setFormValue("pspecialities", pspecialities);
        setPreferredSpecialities(pspecialities);
        console.log(preferredSpecialities);
      }
      if (feed?.feed_info?.locations.length == 1) {
        const plocations = [feed?.feed_info?.locations[0].location_id];
        setFormValue("plocations", plocations);
        setPreferredLocations(plocations);
      }

      // setFormValue("email", email)
      // setFormValue("city", { label: feed.feed_info.location, value: feed.feed_info.location })
      // setFormValue("speciality", { label: feed.feed_info.specialization, value: feed.feed_info.specialization })
      // setFormValue("experience", { label: `${feed.feed_info.max_experience} years`, value: feed.feed_info.max_experience })
    }
  }, [feed]);

  useEffect(() => {
    if (error) history.goBack();
  }, [error]);

  // const profile_pic_placeholder = "assets/images/photos/Group 1019.png";

  const postingDate = new Date(feed?.feed_info?.posting_time);
  const today = new Date();
  // const [displayStatus,setDisplayStatus] = useState(feed?.feed_info?.display_status);
  const displayStatus = feed?.feed_info?.display_status;

  const feedDate = feed?.feed_info?.posting_time
    ? today.toDateString() === postingDate.toDateString()
      ? getTime(postingDate)
      : getDate(postingDate)
    : "";

  const feedType =
    feed?.feed_info?.feed_type?.toUpperCase() === "ARTICLE"
      ? feed?.feed_info?.article_type?.toUpperCase()
      : feed?.feed_info?.display_tag?.toUpperCase();

  const eventLink =
    feed?.feed_info?.event_details?.link.includes("https://") ||
    feed?.feed_info?.event_details?.link.includes("http://")
      ? feed?.feed_info?.event_details?.link
      : `https://${feed?.feed_info?.event_details?.link}`;

  const calcWebinarStatus = () => {
    const currentTime = Date.now();

    if (!feed?.feed_info?.event_details) return "";

    if (feed.feed_info.event_details.has_cancelled) return "cancelled";

    const webinarStartTime = feed.feed_info.event_details.from_date;
    const webinarEndTime = feed.feed_info.event_details.to_date;

    if (currentTime < webinarStartTime) return "register";
    if (currentTime >= webinarStartTime && currentTime < webinarEndTime)
      return "join";
    if (currentTime >= webinarEndTime) return "ended";
  };

  const webinarStatus = calcWebinarStatus();

  const handleWebinarRegister = async (e) => {
    const isRegistered = await registerWebinar(
      e,
      feed.feed_info.feed_id,
      feed.feed_info.event_details.verify_user,
      feed.feed_info.title
    );
    setIsRegisterClicked(isRegistered);
  };

  const verifyUser = () => {
    if (!user_verification_info.isCommunityVerified) {
      if (user_verification_info.is_user_verified == 1) {
        openUploadMci();
        return false;
      }
      if (user_verification_info.is_user_verified == 2) {
        openVerificationStatusModal();
        return false;
      }
    }
    return true;
  };

  const updateLikeCount = async (feed_id, channel_id, isLike) => {
    if (!verifyUser()) return;

    const payload = {
      doc_id: doc_id,
      channel_id: channel_id,
      feedTypeId: feed_id,
      socialInteraction: {
        type: "Like",
        isLike,
      },
    };

    const res = await fetch(baseUrl + "v1/feedSocialInteractions", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    if (data.status === "success") {
      setFeed((prevFeed) => ({
        ...prevFeed,
        feed_info: {
          ...prevFeed.feed_info,
          socialInteraction: data.data.socialInteraction,
        },
      }));
      setFeeds(
        feeds.map((prevFeed) => {
          const newFeed = { ...prevFeed };
          if (prevFeed.feed_info.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    } else toast.error(data.errorMsg);
  };

  const updateViewCount = async (channel_id, feed_id) => {
    const payload = {
      doc_id,
      channel_id: channel_id,
      feedId: feed_id,
    };

    const res = await fetch(baseUrl + "feeds/updateViewCount", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    if (data.status === "success")
      if (data.data.socialInteraction) {
        setFeed((prevFeed) => ({
          ...prevFeed,
          feed_info: {
            ...prevFeed.feed_info,
            socialInteraction: data.data.socialInteraction,
          },
        }));
        setFeeds(
          feeds.map((prevFeed) => {
            const newFeed = { ...prevFeed };
            if (prevFeed.feed_info.feed_id === parseInt(feed_id))
              newFeed.feed_info.socialInteraction = data.data.socialInteraction;
            return newFeed;
          })
        );
      } else {
        setFeed((prevFeed) => ({
          ...prevFeed,
          feed_info: {
            ...prevFeed.feed_info,
            socialInteraction: {
              ...prevFeed.feed_info.socialInteraction,
              viewCount: prevFeed.feed_info.socialInteraction.viewCount + 1,
            },
          },
        }));
        setFeeds(
          feeds.map((feed) => {
            const newFeed = { ...feed };
            if (feed.feed_info.feed_id === feed_id)
              newFeed.feed_info.socialInteraction.viewCount += 1;
            return newFeed;
          })
        );
      }
    else toast.error(data.errorMsg);
  };

  const submitSurvey = async (feed_id, channel_id, question_id, option_id) => {
    if (option_id.length === 0) {
      toast("Please select an option");
      return;
    }

    const payload = {
      feed_id,
      channel_id,
      user_id: doc_id,
      feed_survey: {
        questions: [
          {
            question_id,
            options: option_id.map((option) => ({
              option_id: option,
            })),
          },
        ],
      },
    };

    const res = await fetch(baseUrl + "v1/feed/survey/update", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();
    if (data.status === "success") {
      setFeed((prevFeed) => {
        const newFeed = { ...prevFeed };
        newFeed.feed_info.feed_survey.questions =
          newFeed.feed_info.feed_survey.questions.map((question, index) => ({
            ...question,
            options: data.data.feed_survey.questions[index].options,
          }));
        newFeed.feed_info.feed_survey.is_open = data.data.feed_survey.is_open;
        newFeed.feed_info.feed_survey.is_participated =
          data.data.feed_survey.is_participated;

        return newFeed;
      });

      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed?.feed_info?.feed_id === feed_id) {
            newFeed.feed_info.feed_survey.questions =
              newFeed.feed_info.feed_survey.questions.map(
                (question, index) => ({
                  ...question,
                  options: data.data.feed_survey.questions[index].options,
                })
              );
            newFeed.feed_info.feed_survey.is_open =
              data.data.feed_survey.is_open;
            newFeed.feed_info.feed_survey.is_participated =
              data.data.feed_survey.is_participated;
          }

          return newFeed;
        })
      );
    } else toast.error(data.errorMsg);
  };

  const registerWebinar = async (e, feed_id, verify_user, title) => {
    e.preventDefault();

    if (verify_user) {
      if (!verifyUser()) return;
    }

    const payload = {
      user_id: doc_id,
      feed_id,
    };

    const res = await fetch(baseUrl + "v1/user/webinar/register", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
    });

    const data = await res.json();

    if (data.status === "success") {
      toast.success(`Thank you for registering for ${title}`);
      setFeed((prevFeed) => {
        const newFeed = { ...prevFeed };
        newFeed.feed_info.event_details.is_user_registered = true;
        return newFeed;
      });
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed?.feed_info?.feed_id === feed_id)
            newFeed.feed_info.event_details.is_user_registered = true;
          return newFeed;
        })
      );
      return true;
    } else if (data.status === "error") toast.error("Failed to registered");

    return false;
  };
  const autoSuggestions = async () => {
    const payload = { cities: "cities", specialities: "specialities" };
    const res = await fetch(baseUrl + "v2/autoSuggestions", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let cities = [];
    let specialities = [];
    if (data.status === "success") {
      for (const city of data.data.cities) {
        cities.push({ value: city, label: city });
      }

      for (const speciality of data.data.specialities) {
        specialities.push({ value: speciality, label: speciality });
      }
    }

    setCities(cities);
    setSpecialities(specialities);
    // console.log("cities data is"+ JSON.stringify(cities));
    // console.log(" specialities data is"+  JSON.stringify(specialities));
  };

  const applicationDeadline = () => {
    let currentTime = Date.now();
    if (currentTime > feed.feed_info?.application_deadline) {
      setAppDealineDate(true);
      setJobApplication(false);
    }
    // let date=new date(feed.feed_info.application_deadline);
    // const dateString = today.toLocaleDateString("en-GB");
    // let today = new Date();
    // let deadLineDate = getTimeAndDate(
    //   parseInt(feed.feed_info?.application_deadline)
    // );
    // let postingDateAndTime = getTimeAndDate(today);

    // if (postingDateAndTime > deadLineDate) {
    //   setAppDealineDate(true);
    //   setJobApplication(false);
    // }
  };

  const getUserData = async () => {
    const payload = {
      user_id: doc_id,
    };
    const res = await fetch(baseUrl + "v1/userProfile/view", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    if (data.status === "success") {
      setUserInfo(data.data);
      setDefaultFormValue(data.data);

      // console.log("test"+ userInfo.cnt_num)
    }
  };
  const setDefaultFormValue = (data) => {
    if (feed.feed_info?.application_type == 2) {
      const phoneNumber = userPhone && parsePhoneNumber(`+${userPhone}`);

      if (phoneNumber) {
        setCountry(phoneNumber.country);
        setFormValue("phone", phoneNumber.number);
      }
      setFormValue("city", {
        label: data.location,
        value: data.location,
      });
      setFormValue("email", data.cnt_email);
      // setFormValue("city", {
      //   label: feed.feed_info.location,
      //   value: feed.feed_info.location,
      // });
      setFormValue("speciality", {
        label: data.specialists,
        value: data.specialists,
      });
      setFormValue("experience", {
        label: `${data.experience} years`,
        value: data.experience,
      });
    }
  };
  const uploadCVFile = async (fileInput) => {
    let fileNameType = "";
    let file = fileInput?.current?.files[0];
    let newFileName = fileInput?.current?.files[0]?.name;
    let fileType = fileInput?.current?.files[0]?.type;
    let sendFile = newFileName.replaceAll(" ", "");
    const ReactS3Client = new S3(config);
    if (file.type == "") {
      fileNameType = "docs";
    }
    const data = await ReactS3Client.uploadFile(
      file ? file : fileNameType,
      sendFile
    );
    return data.location;
  };
  const validatePhoneInput = (phone) => {
    let isValid = true;
    if (!phone) {
      isValid = false;
      // error = "This field is required";
    }
    if (!isPossiblePhoneNumber(phone)) {
      isValid = false;
      // error = "Please enter valid number";
    }
    if (!isValidPhoneNumber(phone)) {
      isValid = false;
      // error = "Please enter valid number";
    }

    return isValid;
  };
  const onJobPostHandler = async (formData) => {
    let cvUrl = "";
    let coverLetterUrl = "";
    if (cvFile.current && cvFile.current.files.length > 0) {
      let cvType = cvFile.current.files[0].type;
      if (cvType == "") cvType = getExtension(cvFile.current.files[0].name);

      if (cvType.includes("docx")) cvType = "docx";
      else if (cvType.includes("doc")) cvType = "doc";
      else if (cvType.includes("ppt")) cvType = "ppt";
      else if (cvType.includes("pptx")) cvType = "pptx";
      else if (cvType.includes("pdf")) cvType = "pdf";

      if (
        cvType != "docx" &&
        cvType != "doc" &&
        cvType != "pdf" &&
        cvType != "ppt" &&
        cvType != "pptx"
      ) {
        cvFile.current.value = "";
        setCvName(null);
        toast.error("Please upload doc files only");
        return false;
      }
    }
    if (coverLetter.current && coverLetter.current.files.length > 0) {
      let coverLetterType = coverLetter.current.files[0].type;
      if (coverLetterType == "")
        coverLetterType = getExtension(coverLetter.current.files[0].name);

      if (coverLetterType.includes("docx")) coverLetterType = "docx";
      else if (coverLetterType.includes("doc")) coverLetterType = "doc";
      else if (coverLetterType.includes("ppt")) coverLetterType = "ppt";
      else if (coverLetterType.includes("pptx")) coverLetterType = "pptx";
      else if (coverLetterType.includes("pdf")) coverLetterType = "pdf";

      if (
        coverLetterType != "docx" &&
        coverLetterType != "doc" &&
        coverLetterType != "pdf" &&
        coverLetterType != "ppt" &&
        coverLetterType != "pptx"
      ) {
        coverLetter.current.value = "";
        setCLName(null);
        toast.error("Please upload doc files only");
        return false;
      }
    }
    if (cvFile.current && cvFile.current.files.length > 0)
      cvUrl = await uploadCVFile(cvFile);

    if (coverLetter.current && coverLetter.current.files.length > 0)
      coverLetterUrl = await uploadCVFile(coverLetter);

    if (feed.feed_info.cv_required) {
      if (cvUrl == "") {
        toast.error("CV Mandatory");
        return;
      }
    }
    if (feed.feed_info.cover_letter_required) {
      if (coverLetterUrl == "") {
        toast.error("CL Mandatory");
        return;
      }
    }
    let status = 2;
    if (feed.feed_info.application_type == 2) {
      status = 2;
    } else {
      status = 1;
    }
    let currentTime = Date.now();
    let spec = formData.speciality?.value;
    let payload = {
      user_id: DOC_ID,
      user_email: formData.email?.trim(),
      user_phone: formData.phone?.trim(),
      specialization: spec.toString(),
      location: formData.city?.value,
      applied_on: currentTime,
      clicked_on: currentTime,
      experience: parseInt(formData.experience?.value),
      job_id: feed.feed_info.job_id,
      status: status,
      application_type: feed.feed_info.application_type,
      applicant_cv: cvUrl,
      applicant_cover_letter: coverLetterUrl,
    };
    if (
      feed.feed_info.specializations.length != 0 &&
      feed.feed_info.locations.length != 0
    ) {
      payload.specializations = preferredSpecialities;
      payload.locations = preferredLocations;
    }
    console.log(payload);
    setSpinner(true);
    const res = await fetch(baseUrl + "v2/job/apply", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    setSpinner(false);
    const data = await res.json();
    if (data.status == "success") {
      toast("Application Submitted");
      setJobApplication(true);
      feed.feed_info.is_applied = true;
      // setFeeds(
      //   feeds.map((prevFeed) => {
      //     const newFeed = { ...prevFeed };
      //     if (prevFeed.feed_info.feed_id === feed.feed_info.feed_id)
      //       newFeed.feed_info.is_applied = true;
      //     return newFeed;
      //   })
      // );
      // setFeeds(
      //   feeds.map((feed) => {
      //     const newFeed = { ...feed };
      //     if (feed.feed_info.feed_id === feed_id)
      //       newFeed.feed_info.socialInteraction.viewCount += 1;
      //     return newFeed;
      //   })
      // );
    }
  };

  const getExtension = (fileName) => {
    const lastDot = fileName.lastIndexOf(".");
    const ext = fileName.substring(lastDot + 1);
    return ext;
  };
  const openLink = () => {
    let url = feed.feed_info.application_link;
    let openUrl;
    if (feed.feed_info.application_link.includes("www.")) {
      openUrl = url.replace("www.", "http://");
    }
    if (feed.feed_info.application_link.includes("https://")) {
      openUrl = url.replace("https://", "https://");
    }
    if (feed.feed_info.application_link.includes("http://")) {
      openUrl = url.replace("http://", "http://");
    }

    window.open(openUrl);
  };

  const changeLocations = (event) => {
    const value = event.target.value;
    setFormValue("plocations", value);
    setPreferredLocations(value);
    if (value.indexOf(-1) !== -1) {
      value.splice(0, 1);
    } else if (value.length == 0) {
      value.splice(0, 0, -1);
    }
    const res = trigger("plocations");
    return;
  };
  const changeSpecialities = (event) => {
    const value = event.target.value;
    setFormValue("pspecialities", value);
    setPreferredSpecialities(value);
    if (value.indexOf(-1) !== -1) {
      value.splice(0, 1);
    } else if (value.length == 0) {
      value.splice(0, 0, -1);
    }
    const res = trigger("pspecialities");
    return;
  };
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "white",
          zIndex: 10,
          // overflow: "auto",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100vw",
        }}
      >
        <div
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            top: "0",
            left: "0",
            width: "100%",
            zIndex: "25",
            flexShrink: "0",
          }}
          className="mobile-header-wrapper sticky d-block d-lg-none"
        >
          <div className="mobile-header position-relative ">
            <div className="mobile-responsive">
              <div className="brand-logo ">
                <img
                  style={{ width: "25px", marginLeft: "5px" }}
                  onClick={() => history.goBack()}
                  src={back}
                  alt="timeline logo"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="post-thumb-gallery img-gallery"
          // style={{ marginTop: "30px", overflowX: "scroll" }}
          style={{ overflowY: "auto" }}
        >
          <div className="row no-gutters">
            <div style={{ width: "100%" }}>
              {feed.feed_info?.feed_type === "Job Postings" && (
                <TabContext
                  value={value}
                  indicatorColor={{ backgroundColor: "green" }}
                >
                  <AppBar
                    className="mL"
                    position="sticky"
                    z-index="1"
                    style={{
                      backgroundColor: "red",
                      color: "black",
                      marginBottom: "15px",
                    }}
                  >
                    {!displayStatus && feed.feed_info?.about_us?.length == 0 && (
                      <div style={{ marginTop: "0px" }}>
                        <TabList
                          onChange={handleChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="tab example"
                          className={classes.tabs}
                        >
                          <Tab
                            label="About Role"
                            value="1"
                            centered
                            className={classes.tabs}
                          />
                          <Tab
                            className={classes.tabs}
                            label="Apply Now"
                            centered
                            value="3"
                          />
                        </TabList>
                      </div>
                    )}
                    {feed.feed_info?.about_us?.length > 0 && !displayStatus && (
                      <div style={{ marginTop: "0px" }}>
                        <TabList
                          onChange={handleChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="tab example"
                          className={classes.tabs}
                        >
                          <Tab
                            label="About Role"
                            value="1"
                            centered
                            className={classes.tabs}
                          />
                          <Tab
                            className={classes.tabs}
                            label="Organization"
                            value="2"
                            centered
                            TabIndicatorProps={{
                              style: { background: "green" },
                            }}
                          />
                          <Tab
                            className={classes.tabs}
                            label="Apply Now"
                            centered
                            value="3"
                          />
                        </TabList>
                      </div>
                    )}

                    {displayStatus && (
                      <div style={{ marginTop: "0px" }}>
                        <TabList
                          onChange={handleChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="tab example"
                          className={classes.tabs}
                        >
                          <Tab
                            label="About Role"
                            value="1"
                            centered
                            className={classes.tabs}
                          />
                          {/* {feed.feed_info.display_status &&  ( */}
                          <Tab
                            className={classes.tabs}
                            label={displayStatus ? "Organization" : ""}
                            value="2"
                            centered
                            TabIndicatorProps={{
                              style: { background: "green" },
                            }}
                          />
                          {/* )} */}
                          <Tab
                            className={classes.tabs}
                            label="Apply Now"
                            centered
                            value="3"
                          />
                        </TabList>
                      </div>
                    )}
                  </AppBar>

                  <TabPanel value="1" className={classes.tabs}>
                    <h2 style={{ color: "black" }}>Job Discription</h2>
                    {feed.feed_info.feed_type === "Job Postings" && (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: feed.feed_info.feed_desc,
                          }}
                        ></div>
                      </>
                    )}
                    <div className="container" style={{ padding: "0px" }}>
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                Title/Position:
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div>
                            <h3 style={{ color: "black", marginTop: "20px" }}>
                              {" "}
                              {feed.feed_info.title}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                Organization:
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                {" "}
                                {feed.feed_info.organization}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                Role:
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                {" "}
                                {feed.feed_info.role_type}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                {feed.feed_info.locations.length == 0
                                  ? "Location:"
                                  : "Locations:"}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          {feed.feed_info.locations.length == 0 ? (
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                {" "}
                                {feed.feed_info.location}
                              </h3>
                            </div>
                          ) : (
                            <div
                              style={{
                                color: "black",
                                marginTop: "20px",
                                width: "105%",
                                wordBreak: "break-all",
                              }}
                            >
                              {/* {feed?.feed_info?.locations.map((location,index) =>    
                              <span>
                                {" "}
                                { (index ? ', ': '') + location.location }
                              </span>
                            )} */}

                              {feed?.feed_info?.locations.map(
                                (location, index) => {
                                  if (location.location_id != -1) {
                                    return (
                                      <span>
                                        {location.location +
                                          (index ==
                                          feed?.feed_info?.locations.length - 1
                                            ? " "
                                            : ", ")}
                                      </span>
                                    );
                                  }
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                {" "}
                                Compensation:
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                {" "}
                                {feed.feed_info.compensation_range}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                Application DeadLine:
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                {`${getDate(
                                  parseInt(feed.feed_info.application_deadline)
                                )}`}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h2 style={{ color: "black", marginTop: "20px" }}>
                      <u>Minimum Qualification</u>
                    </h2>
                    <div className="container" style={{ padding: "0px" }}>
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                Experience
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div>
                            <h3 style={{ color: "black", marginTop: "20px" }}>
                              {feed.feed_info.max_experience !== 0 &&
                                feed.feed_info.max_experience !== "0" && (
                                  <h3
                                    style={{
                                      color: "black",
                                      marginTop: "20px",
                                    }}
                                  >
                                    {feed.feed_info.min_experience}-
                                    {feed.feed_info.max_experience} Years
                                  </h3>
                                )}

                              {feed.feed_info.min_experience > 1 &&
                                feed.feed_info.max_experience == 0 && (
                                  <h3
                                    style={{
                                      color: "black",
                                      marginTop: "20px",
                                    }}
                                  >
                                    {feed.feed_info.min_experience} Years
                                  </h3>
                                )}

                              {feed.feed_info.min_experience <= 1 &&
                                feed.feed_info.max_experience == 0 && (
                                  <h3
                                    style={{
                                      color: "black",
                                      marginTop: "20px",
                                    }}
                                  >
                                    {feed.feed_info.min_experience} Year
                                  </h3>
                                )}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <h3 style={{ color: "black", marginTop: "20px" }}>
                            {feed.feed_info.specializations.length == 0
                              ? "Specialization"
                              : "Specializations"}
                          </h3>
                        </div>
                        <div className="col-6">
                          {feed.feed_info.specializations.length == 0 ? (
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                {" "}
                                {feed.feed_info.specialization}
                              </h3>
                            </div>
                          ) : (
                            <div
                              style={{
                                color: "black",
                                marginTop: "20px",
                                width: "105%",
                                wordBreak: "break-all",
                              }}
                            >
                              {/* {feed?.feed_info?.specializations.map((speciality,index) =>    
                              <span>
                                {" "}
                                { (index ? ', ': '') + speciality.specialization }
                              </span>
                            )} */}
                              {feed?.feed_info?.specializations.map(
                                (speciality, index) => {
                                  if (speciality.specialization_id != -1) {
                                    return (
                                      <span>
                                        {speciality.specialization +
                                          (index ==
                                          feed?.feed_info?.specializations
                                            .length -
                                            1
                                            ? " "
                                            : ", ")}
                                      </span>
                                    );
                                  }
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                Qualification
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div>
                            {/* <TextField  value={feed.feed_info.min_qualification} id="standard-basic"  style={{width:'143px',marginTop: '15px',marginLeft:'5px'}} variant="standard" /> */}
                            <h3 style={{ color: "black", marginTop: "20px" }}>
                              {feed.feed_info.min_qualification}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              {feed.feed_info.focus_areas !== "" && (
                                <h3
                                  style={{
                                    color: "black",
                                    marginTop: "20px",
                                  }}
                                >
                                  Focus Areas
                                </h3>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div>
                            <h3 style={{ color: "black", marginTop: "20px" }}>
                              {feed.feed_info.focus_areas}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <h2 style={{ color: 'black', marginTop: '5px' ,marginBottom: '10px' }}><u>Other Requirements</u></h2> */}
                    <div style={{ marginBottom: "90px" }}>
                      {(feed?.feed_info?.other_criteria_1 != "" ||
                        feed?.feed_info?.other_criteria_2 != "" ||
                        feed?.feed_info?.other_criteria_3 != "") && (
                        <h2 style={{ color: "black", marginTop: "5px" }}>
                          <u>Other Requirements</u>
                        </h2>
                      )}
                      <h3
                        style={{
                          color: "black",
                          marginTop: "5px",
                          marginLeft: "15px",
                        }}
                      >
                        {feed?.feed_info?.other_criteria_1}
                      </h3>
                      <h3
                        style={{
                          color: "black",
                          marginTop: "5px",
                          marginLeft: "15px",
                        }}
                      >
                        {feed?.feed_info?.other_criteria_2}
                      </h3>
                      <h3
                        style={{
                          color: "black",
                          marginTop: "5px",
                          marginLeft: "15px",
                        }}
                      >
                        {feed?.feed_info?.other_criteria_3}
                      </h3>
                    </div>
                  </TabPanel>

                  {displayStatus && (
                    <div>
                      <TabPanel value="2" className={classes.tabs}>
                        <div className="contactDetails">
                          <div className="container" style={{ padding: "0px" }}>
                            <div className="row">
                              <div className="col-12">
                                <div className="about-us">
                                  <h3
                                    style={{
                                      color: "black",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {feed.feed_info?.about_us}
                                  </h3>
                                </div>
                                <div>
                                  <h2
                                    style={{
                                      color: "black",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Contact Details
                                  </h2>
                                </div>
                                <div>
                                  <h3
                                    style={{
                                      color: "black",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {feed.feed_info.role_contact_email}
                                  </h3>
                                </div>
                                <div>
                                  <h3
                                    style={{
                                      color: "black",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {feed.feed_info.role_contact_number}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </div>
                  )}

                  {feed.feed_info.about_us.length > 0 && !displayStatus && (
                    <div>
                      <TabPanel value="2" className={classes.tabs}>
                        <div className="contactDetails">
                          <div className="container" style={{ padding: "0px" }}>
                            <div className="row">
                              <div className="col-12">
                                <div>
                                  <h3
                                    style={{
                                      color: "black",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {feed.feed_info.about_us}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </div>
                  )}

                  <TabPanel value="3" className={classes.tabs}>
                    <div className="contactDetails">
                      {feed?.feed_info?.application_type === 2 && (
                        <div>
                          {jobApplication && (
                            <h3
                              style={{
                                color: "black",
                                marginTop: "150px",
                                textAlign: "center",
                              }}
                            >
                              Application Submitted
                            </h3>
                          )}

                          {appDealineDate && (
                            <h3
                              style={{
                                color: "black",
                                marginTop: "20px",
                                textAlign: "center",
                              }}
                            >
                              The application deadline has passed
                            </h3>
                          )}
                        </div>
                      )}
                      {!jobApplication && !appDealineDate && (
                        <div>
                          {feed?.feed_info?.application_type === 2 && (
                            <form onSubmit={handleSubmit(onJobPostHandler)}>
                              <div
                                className="container"
                                style={{ marginTop: "0px" }}
                              >
                                <div
                                  className="row"
                                  style={{
                                    marginTop: "1em",
                                    marginBottom: "0em",
                                    background: "#ffffff",
                                    borderRadius: "9px",
                                    height: "3.5em",
                                  }}
                                >
                                  <div className="col-2">
                                    <img
                                      src={emailIcon}
                                      alt=""
                                      style={{ margin: "1em" }}
                                    />
                                  </div>
                                  <div
                                    className="col-10"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <input
                                      style={{
                                        marginTop: "1em",
                                        paddingLeft: "5px",
                                        background: "inherit",
                                        width: "100%",
                                        border: "none",
                                      }}
                                      type="email"
                                      {...register("email", {
                                        required: true,
                                        maxLength: 35,
                                        pattern: {
                                          value:
                                            /^\s*[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}\s*$/i,
                                          message: "invalid email address",
                                        },
                                      })}
                                      placeholder="Enter Email *"
                                    />
                                  </div>
                                  <div className="row error-title">
                                    {errors.email?.type === "required" && (
                                      <span className="error-title-text">
                                        Please enter a valid email id
                                      </span>
                                    )}
                                  </div>
                                  <div className="row error-title">
                                    {errors.email?.type === "maxLength" && (
                                      <span className="error-title-text">
                                        max length is 35
                                      </span>
                                    )}
                                  </div>
                                  <div className="row error-title">
                                    {errors.email?.type === "pattern" && (
                                      <span className="error-title-text">
                                        Please enter valid email id
                                      </span>
                                    )}
                                  </div>
                                </div>

                                <div
                                  className="row "
                                  style={{
                                    marginTop: "1em",
                                    marginBottom: "1em",
                                    height: "3.5em",
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: "9px",
                                  }}
                                >
                                  <div
                                    className="col-5"
                                    style={{
                                      paddingLeft: "0px",
                                      backgroundColor: "#FFFFFF",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    <CountrySelect
                                      isRegister={false}
                                      value={country}
                                      onChange={setCountry}
                                      labels={en}
                                      style={{ borderRadius: "9px" }}
                                    />
                                  </div>
                                  <div
                                    className="col-7"
                                    style={{
                                      padding: "0px",
                                      backgroundColor: "#FFFFFF",
                                      borderRadius: "9px",
                                    }}
                                  >
                                    <div className="container">
                                      <div
                                        className="row"
                                        style={{
                                          backgroundColor: "#FFFFFF",
                                          borderRadius: "9px",
                                          height: "3.5em",
                                        }}
                                      >
                                        {/* <div className="col-2">
                                          <img
                                            src={call}
                                            alt=""
                                            style={{ margin: "1em" }}
                                          />
                                        </div> */}
                                        <div
                                          className="col-10"
                                          style={{ paddingLeft: "0px" }}
                                        >
                                          <Controller
                                            name="phone"
                                            control={control}
                                            rules={{
                                              required: true,
                                              validate: (value) =>
                                                validatePhoneInput(value),
                                            }}
                                            render={({
                                              field: { onChange, value },
                                            }) => (
                                              <PhoneInput
                                                country={country}
                                                value={value}
                                                onChange={onChange}
                                                placeholder="Contact Number *"
                                                style={{
                                                  marginTop: "1.1em",
                                                  paddingLeft: "5px",
                                                  backgroundColor: "FFFFFF",
                                                  width: "100%",
                                                  border: "none",
                                                }}
                                              />
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="row "
                                  style={{
                                    marginTop: "1em",
                                    marginBottom: "1em",
                                    height: "3.5em",
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: "9px",
                                  }}
                                >
                                  <div
                                    className="col-12"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <Controller
                                      name="speciality"
                                      control={control}
                                      rules={{ required: true }}
                                      render={({ field }) => (
                                        <Select
                                          {...field}
                                          placeholder="Enter Speciality *"
                                          options={specialities}
                                          styles={{
                                            control: (style) => ({
                                              ...style,
                                              background: "inherit",
                                              width: "100%",
                                              border: 0,
                                              boxShadow: "none",
                                            }),
                                            input: (style) => ({
                                              ...style,
                                              paddingLeft: "0px",
                                            }),
                                            container: (style) => ({
                                              ...style,
                                              marginTop: "0.45em",
                                              marginLeft: "-5px",
                                            }),
                                          }}
                                        />
                                      )}
                                    />
                                  </div>
                                </div>
                                {errors.speciality && (
                                  <div className="row error-title">
                                    <span className="error-title-text">
                                      Please enter your speciality
                                    </span>
                                  </div>
                                )}
                                <div
                                  className="row "
                                  style={{
                                    marginTop: "1em",
                                    marginBottom: "1em",
                                    height: "3.5em",
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: "9px",
                                  }}
                                >
                                  <div
                                    className="col-12"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <Controller
                                      name="city"
                                      control={control}
                                      rules={{ required: true }}
                                      render={({ field }) => (
                                        <Select
                                          {...field}
                                          placeholder="City *"
                                          options={cities}
                                          styles={{
                                            control: (style) => ({
                                              ...style,
                                              background: "inherit",
                                              width: "100%",
                                              border: 0,
                                              boxShadow: "none",
                                            }),
                                            input: (style) => ({
                                              ...style,
                                              paddingLeft: "0px",
                                            }),
                                            container: (style) => ({
                                              ...style,
                                              marginTop: "0.45em",
                                              marginLeft: "-5px",
                                            }),
                                          }}
                                        />
                                      )}
                                    />
                                  </div>
                                </div>

                                <div
                                  className="row "
                                  style={{
                                    marginTop: "1em",
                                    marginBottom: "1em",
                                    height: "3.5em",
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: "9px",
                                  }}
                                >
                                  <div
                                    className="col-12"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <Controller
                                      name="experience"
                                      control={control}
                                      rules={{ required: true }}
                                      render={({ field }) => (
                                        <Select
                                          {...field}
                                          options={yearsOfExperience}
                                          placeholder="Years of Experience *"
                                          styles={{
                                            control: (style) => ({
                                              ...style,
                                              background: "inherit",
                                              width: "100%",
                                              border: 0,
                                              boxShadow: "none",
                                            }),
                                            input: (style) => ({
                                              ...style,
                                              paddingLeft: "0px",
                                            }),
                                            container: (style) => ({
                                              ...style,
                                              marginTop: "0.45em",
                                              marginLeft: "-5px",
                                            }),
                                          }}
                                        />
                                      )}
                                    ></Controller>
                                  </div>
                                </div>
                                {errors.experience?.type === "required" && (
                                  <div className="row error-title">
                                    <span className="error-title-text">
                                      Please enter your experience
                                    </span>
                                  </div>
                                )}
                                {(errors.experience?.type === "max" ||
                                  errors.experience?.type === "min") && (
                                  <div className="row error-title">
                                    <span className="error-title-text">
                                      Please enter value between 0-70
                                    </span>
                                  </div>
                                )}
                                {feed?.feed_info?.specializations &&
                                  feed?.feed_info?.specializations.length !=
                                    0 && (
                                    <section
                                      className="row "
                                      style={{
                                        marginTop: "2em",
                                        marginBottom: "2em",
                                        height: "3.5em",
                                        backgroundColor: "#FFFFFF",
                                        borderRadius: "9px",
                                      }}
                                    >
                                      <div
                                        className="col-12"
                                        style={{
                                          borderRadius: "9px",
                                          width: "100%",
                                          marginTop: "25px",
                                        }}
                                      >
                                        <Controller
                                          name="pspecialities"
                                          control={control}
                                          rules={{
                                            required: true,
                                            validate: (value) => value != -1,
                                          }}
                                          render={({ field }) => (
                                            <FormControl fullWidth size="small">
                                              {/* <InputLabel
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                marginTop: "10px",
                                                marginLeft:"-20px"
                                              }}
                                              shrink={false}
                                            >
                                              {preferredSpecialities.length ===
                                                0 && "Preferred Speciality"}
                                            </InputLabel> */}
                                              <StyledButton
                                                {...field}
                                                id="demo-multiple-checkbox"
                                                multiple
                                                disabled={
                                                  feed?.feed_info
                                                    ?.specializations.length ==
                                                  1
                                                    ? true
                                                    : false
                                                }
                                                value={preferredSpecialities}
                                                onChange={changeSpecialities}
                                                input={<BootstrapInput />}
                                                renderValue={(selected) =>
                                                  feed?.feed_info?.specializations
                                                    .filter((name) =>
                                                      selected.includes(
                                                        name.specialization_id
                                                      )
                                                    )
                                                    .map(
                                                      (record) =>
                                                        record.specialization
                                                    )
                                                    .join(", ")
                                                }
                                                MenuProps={MenuProps}
                                              >
                                                {/* <MenuItem value="" disabled>
                                                <span
                                                  style={{ whiteSpace: "normal",fontSize: "12px" }}
                                                >
                                                  Preferred Speciality
                                                </span>
                                              </MenuItem> */}
                                                {feed?.feed_info?.specializations.map(
                                                  (speciality, i) => {
                                                    if (
                                                      speciality.specialization_id ==
                                                      -1
                                                    ) {
                                                      return (
                                                        <MenuItem
                                                          disabled
                                                          id={i}
                                                          value={
                                                            speciality.specialization_id
                                                          }
                                                          style={{
                                                            backgroundColor:
                                                              "white",
                                                            display: "flex",
                                                            marginLeft: "10px",
                                                            minHeight: "38px",
                                                          }}
                                                          key={i}
                                                        >
                                                          <ListItemText
                                                            primary={
                                                              speciality.specialization
                                                            }
                                                            primaryTypographyProps={{
                                                              style: {
                                                                whiteSpace:
                                                                  "normal",
                                                                fontSize: 12,
                                                              },
                                                            }}
                                                          />
                                                        </MenuItem>
                                                      );
                                                    }
                                                  }
                                                )}
                                                {feed?.feed_info?.specializations.map(
                                                  (speciality, i) => {
                                                    if (
                                                      speciality.specialization_id !=
                                                      -1
                                                    ) {
                                                      return (
                                                        <MenuItem
                                                          id={i}
                                                          value={
                                                            speciality.specialization_id
                                                          }
                                                          style={{
                                                            backgroundColor:
                                                              "white",
                                                            display: "flex",
                                                            marginLeft: "10px",
                                                            minHeight: "38px",
                                                          }}
                                                          key={i}
                                                        >
                                                          <ListItemText
                                                            primary={
                                                              speciality.specialization
                                                            }
                                                            primaryTypographyProps={{
                                                              style: {
                                                                whiteSpace:
                                                                  "normal",
                                                                fontSize: 12,
                                                              },
                                                            }}
                                                          />
                                                          <ListItemIcon>
                                                            <Checkbox
                                                              checked={
                                                                preferredSpecialities.indexOf(
                                                                  speciality.specialization_id
                                                                ) > -1
                                                              }
                                                              style={{
                                                                color:
                                                                  "#1e8a79",
                                                                "&.Mui-checked":
                                                                  {
                                                                    color:
                                                                      "#1e8a79",
                                                                  },
                                                              }}
                                                            />
                                                          </ListItemIcon>
                                                        </MenuItem>
                                                      );
                                                    }
                                                  }
                                                )}
                                              </StyledButton>
                                            </FormControl>
                                          )}
                                        />
                                      </div>

                                      {feed?.feed_info?.specializations &&
                                        feed?.feed_info?.specializations
                                          .length != 0 &&
                                        (errors.pspecialities?.type ===
                                          "required" ||
                                          errors.pspecialities) && (
                                          <div className="row">
                                            <span
                                              style={{
                                                margin: "21px 0px 0px 22px",
                                                color: "red",
                                              }}
                                            >
                                              Please select atleast one
                                              preferred specialisation
                                            </span>
                                          </div>
                                        )}
                                    </section>
                                  )}
                                {feed?.feed_info?.locations &&
                                  feed?.feed_info?.locations.length != 0 && (
                                    <section
                                      className="row "
                                      style={{
                                        marginTop: "1em",
                                        marginBottom: "1em",
                                        height: "3.5em",
                                        backgroundColor: "#FFFFFF",
                                        borderRadius: "9px",
                                      }}
                                    >
                                      <div
                                        className="col-12"
                                        style={{
                                          borderRadius: "9px",
                                          width: "100%",
                                          marginTop: "25px",
                                        }}
                                      >
                                        <Controller
                                          name="plocations"
                                          control={control}
                                          rules={{
                                            required: true,
                                            validate: (value) => value != -1,
                                          }}
                                          render={({ field }) => (
                                            <FormControl
                                              fullWidth
                                              size="medium"
                                            >
                                              {/* <InputLabel
                                              style={{
                                                color: "black",
                                                fontSize: "14px",
                                                marginTop: "10px",
                                                marginLeft:"-20px"
                                              }}
                                              shrink={false}
                                            >
                                              {preferredLocations.length ===
                                                0 && "Preferred Job Location"}
                                            </InputLabel> */}
                                              <StyledButton
                                                {...field}
                                                id="demo-multiple-checkbox"
                                                multiple
                                                disabled={
                                                  feed?.feed_info?.locations
                                                    .length == 1
                                                    ? true
                                                    : false
                                                }
                                                value={preferredLocations}
                                                onChange={changeLocations}
                                                input={<BootstrapInput />}
                                                renderValue={(selected) =>
                                                  feed?.feed_info?.locations
                                                    .filter((name) =>
                                                      selected.includes(
                                                        name.location_id
                                                      )
                                                    )
                                                    .map(
                                                      (record) =>
                                                        record.location
                                                    )
                                                    .join(", ")
                                                }
                                                MenuProps={MenuProps}
                                              >
                                                {feed?.feed_info?.locations.map(
                                                  (location, i) => {
                                                    if (
                                                      location.location_id == -1
                                                    ) {
                                                      return (
                                                        <MenuItem
                                                          disabled
                                                          id={i}
                                                          value={
                                                            location.location_id
                                                          }
                                                          style={{
                                                            backgroundColor:
                                                              "white",
                                                            display: "flex",
                                                            marginLeft: "10px",
                                                            minHeight: "38px",
                                                          }}
                                                          key={i}
                                                        >
                                                          <ListItemText
                                                            primary={
                                                              location.location
                                                            }
                                                            primaryTypographyProps={{
                                                              style: {
                                                                whiteSpace:
                                                                  "normal",
                                                                fontSize: 12,
                                                              },
                                                            }}
                                                          />
                                                        </MenuItem>
                                                      );
                                                    }
                                                  }
                                                )}
                                                {feed?.feed_info?.locations.map(
                                                  (location, i) => {
                                                    if (
                                                      location.location_id != -1
                                                    ) {
                                                      return (
                                                        <MenuItem
                                                          id={i}
                                                          value={
                                                            location.location_id
                                                          }
                                                          style={{
                                                            backgroundColor:
                                                              "white",
                                                            display: "flex",
                                                            marginLeft: "10px",
                                                            minHeight: "38px",
                                                          }}
                                                          key={i}
                                                        >
                                                          <ListItemText
                                                            primary={
                                                              location.location
                                                            }
                                                            primaryTypographyProps={{
                                                              style: {
                                                                whiteSpace:
                                                                  "normal",
                                                                fontSize: 12,
                                                              },
                                                            }}
                                                          />
                                                          <ListItemIcon>
                                                            <Checkbox
                                                              checked={
                                                                preferredLocations.indexOf(
                                                                  location.location_id
                                                                ) > -1
                                                              }
                                                              style={{
                                                                color:
                                                                  "#1e8a79",
                                                                "&.Mui-checked":
                                                                  {
                                                                    color:
                                                                      "#1e8a79",
                                                                  },
                                                              }}
                                                            />
                                                          </ListItemIcon>
                                                        </MenuItem>
                                                      );
                                                    }
                                                  }
                                                )}
                                              </StyledButton>
                                            </FormControl>
                                          )}
                                        />
                                      </div>

                                      {feed?.feed_info?.locations &&
                                        feed?.feed_info?.locations.length !=
                                          0 &&
                                        (errors.plocations?.type ===
                                          "required" ||
                                          errors.plocations) && (
                                          <div className="row">
                                            <span
                                              style={{
                                                margin: "21px 0px 0px 22px",
                                                color: "red",
                                              }}
                                            >
                                              Please select atleast one
                                              preferred location
                                            </span>
                                          </div>
                                        )}
                                    </section>
                                  )}

                                {feed.feed_info.cv_required && (
                                  <div
                                    className="row"
                                    style={{
                                      marginLeft: "1px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <div
                                      className="col-6"
                                      style={{ paddingLeft: "0px" }}
                                    >
                                      <h4
                                        style={{
                                          color: "black",
                                          marginTop: "10px",
                                        }}
                                      >
                                        CV
                                      </h4>
                                    </div>
                                    <div
                                      className="col-6"
                                      style={{ paddingLeft: "0px" }}
                                    >
                                      <input
                                        id="icon-button-file"
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={() =>
                                          setCvName(
                                            cvFile.current.files[0].name
                                          )
                                        }
                                        accept=".pdf,.docx,.doc"
                                        ref={cvFile}
                                      />
                                      <label htmlFor="icon-button-file">
                                        <IconButton
                                          color="primary"
                                          aria-label="upload picture"
                                          component="span"
                                        >
                                          <AttachFileIcon />
                                          {cvName ? cvName : "Upload CV"}
                                        </IconButton>
                                      </label>
                                    </div>
                                  </div>
                                )}

                                {feed.feed_info.cover_letter_required && (
                                  <div
                                    className="row"
                                    style={{ marginLeft: "1px" }}
                                  >
                                    <div
                                      className="col-6"
                                      style={{ paddingLeft: "0px" }}
                                    >
                                      <h4
                                        style={{
                                          color: "black",
                                          marginTop: "10px",
                                        }}
                                      >
                                        Coverring Letter
                                      </h4>
                                    </div>
                                    <div
                                      className="col-6"
                                      style={{ paddingLeft: "0px" }}
                                    >
                                      <input
                                        id="cv-button-file"
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={() =>
                                          setCLName(
                                            coverLetter.current.files[0].name
                                          )
                                        }
                                        accept=".pdf,.docx,.doc"
                                        ref={coverLetter}
                                      />
                                      <label htmlFor="cv-button-file">
                                        <IconButton
                                          color="primary"
                                          aria-label="upload picture"
                                          component="span"
                                        >
                                          <AttachFileIcon />{" "}
                                          {clName ? clName : "Upload CL"}
                                          {/* <AttachFileIcon />{coverLetter ? coverLetter : "Attach File"} */}
                                          {/* <span>Cover Letter</span> */}
                                        </IconButton>
                                      </label>
                                    </div>
                                  </div>
                                )}
                                <Progressbar
                                  show={spinner}
                                  imageUrl={url}
                                  height="90"
                                  width="90"
                                  alignment="middle"
                                  alttext="Loading..."
                                />
                                <div className="row">
                                  <div
                                    className="col-12"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <button
                                      style={{
                                        backgroundColor: "#0FB59B",
                                        borderRadius: "10px",
                                      }}
                                      className="submit-btn"
                                    >
                                      {" "}
                                      Submit Application{" "}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          )}
                        </div>
                      )}

                      {feed.feed_info.application_type === 3 && (
                        <div className="button-container">
                          <button
                            type="submit"
                            onClick={openLink}
                            target="_blank"
                            style={{
                              backgroundColor: "#0FB59B",
                              width: "300px",
                              borderRadius: "9px",
                              textAlign: "center",
                            }}
                            className="submit-btn"
                          >
                            {" "}
                            Fill Application
                          </button>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                </TabContext>
              )}
            </div>
            {feed.feed_info?.feed_type !== "Job Postings" && (
              <div className="col-12">
                {displayImage && (
                  <div
                    className="mobilePdf"
                    style={{
                      display: "flex",
                      minHeight:
                        displayImage.attachment_type === "audio"
                          ? "fit-content"
                          : "25em",
                      maxHeight: "25em",
                      width: "100%",
                      marginBottom: "1em",
                      justifyContent: "center",
                      position: "relative",
                      marginTop: "5px",
                      overflow: "scroll",
                    }}
                  >
                    {displayImage.attachment_type === "pdf" && (
                      <>
                        {
                          <div
                            className="iframe-loader"
                            style={{ width: "100%", height: "400px" }}
                          >
                            <iframe
                              id="iframeContainer"
                              src={`https://drive.google.com/viewerng/viewer?url=${displayImage.attachment_original_url}?pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
                              width="100%"
                              height="400px"
                            />
                          </div>
                        }
                      </>
                    )}
                    {displayImage.attachment_type === "image" && (
                      <img
                        src={displayImage.attachment_original_url}
                        style={{ width: "100%" }}
                      />
                    )}
                    {displayImage.attachment_type === "video" && (
                      <video
                        preload="metadata"
                        src={displayImage.attachment_original_url}
                        controls="controls"
                        style={{ width: "100%" }}
                      >
                        <source
                          src={`${displayImage.attachment_original_url}#t=0.1`}
                          type="video/mp4"
                        />
                      </video>
                    )}
                    {displayImage.attachment_type === "audio" && (
                      <ReactAudioPlayer
                        controls
                        src={displayImage.attachment_original_url}
                        style={{ width: "100%" }}
                      />
                    )}
                  </div>
                )}
                <div
                  style={{
                    marginBottom: "2em",
                    display: "flex",

                    justifyContent: "space-evenly",
                  }}
                >
                  {feed?.feed_info?.feed_type?.toUpperCase() !== "SURVEY" &&
                    feed?.feed_info?.attachment_details?.length > 1 &&
                    feed?.feed_info?.attachment_details.map(
                      (attachment, index) => (
                        <div
                          style={{
                            height: "6em",
                            width: "6em",
                            padding: "3px",
                          }}
                          key={index}
                        >
                          <img
                            style={{
                              borderRadius: "5px",
                              maxHeight: "100%",
                              maxWidth: "100%",
                            }}
                            src={
                              attachment.attachment_type === "audio"
                                ? audio_placeholder
                                : attachment.attachment_small_url
                            }
                            onClick={() => setDisplayImage(attachment)}
                          />
                        </div>
                      )
                    )}
                  {feed?.feed_info?.feed_type?.toUpperCase() === "SURVEY" &&
                    feed?.feed_info?.feed_survey?.questions[0]
                      ?.attachment_details.length > 1 &&
                    feed?.feed_info?.feed_survey?.questions[0]?.attachment_details.map(
                      (attachment, index) => (
                        <div
                          style={{
                            height: "6em",
                            width: "6em",
                            padding: "3px",
                          }}
                          key={index}
                        >
                          <img
                            style={{
                              borderRadius: "5px",
                              maxHeight: "100%",
                              maxWidth: "100%",
                            }}
                            src={
                              attachment.attachment_type === "audio"
                                ? audio_placeholder
                                : attachment.attachment_small_url
                            }
                            onClick={() => setDisplayImage(attachment)}
                          />
                        </div>
                      )
                    )}
                </div>
                <div className="responsive-header" style={{ padding: "10px" }}>
                  <div className="profile-thumb">
                    <Link>
                      <figure className="profile-thumb-middle">
                        <img
                          className="article-image"
                          src={
                            feed.post_creator?.profile_url &&
                            feed.post_creator.profile_url !== ""
                              ? feed.post_creator.profile_url
                              : profile_pic_placeholder
                          }
                          alt="profile picture"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="posted-author">
                    <h6>
                      <Link
                        className="fullview-feed-user"
                        to={
                          channel?.feed_provider_type === "Community" ||
                          channel?.feed_provider_type === "Content"
                            ? "#"
                            : feed?.post_creator?.posted_by
                            ? `/profile/${feed?.post_creator?.doc_id}`
                            : "#"
                        }
                      >
                        {channel?.feed_provider_type === "Community" ||
                        channel?.feed_provider_type === "Content"
                          ? channel?.feed_provider_name
                          : feed?.post_creator?.posted_by
                          ? feed?.post_creator?.doc_id === parseInt(doc_id)
                            ? "You"
                            : feed?.post_creator?.posted_by
                          : feed?.feed_info?.copyright}
                      </Link>
                    </h6>
                    <div style={{ display: "flex" }}>
                      <h6
                        className="fullview-feed-type feed-info"
                        style={{ backgroundColor: feedLabelColor }}
                      >
                        {feedType}
                      </h6>{" "}
                      <h6 className="feed-type">{feedDate}</h6>
                    </div>
                  </div>
                </div>
                <div className="profile-thumb">
                  <p
                    className="fullview-feeds-title"
                    style={{ padding: "10px" }}
                  >
                    {feed?.feed_info?.title}
                  </p>
                </div>
                <div style={{ margin: "1em 2em" }}>
                  {feed?.feed_info?.feed_type?.toUpperCase() !== "ARTICLE" &&
                  feed?.feed_info?.feed_type?.toUpperCase() !== "SURVEY" &&
                  feed?.feed_info?.feed_type?.toUpperCase() !== "EVENT" ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: feed?.feed_info?.feed_desc,
                      }}
                    ></div>
                  ) : null}
                  {feed?.feed_info?.feed_type?.toUpperCase() === "EVENT" &&
                    feed?.feed_info?.feed_sub_type === "EOI" && (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: feed?.feed_info?.feed_desc,
                          }}
                        ></div>
                        {!feed?.feed_info?.event_details?.has_cancelled &&
                          !feed?.feed_info?.event_details
                            ?.is_user_registered && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <a
                                href={eventLink}
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                <button
                                  style={{
                                    backgroundColor: "#0FB59B",
                                    borderRadius: "5px",
                                    width: "150px",
                                    height: "3em",
                                    margin: "0.5em",
                                  }}
                                >
                                  I am interested
                                </button>
                              </a>
                            </div>
                          )}
                      </>
                    )}

                  {feed?.feed_info?.feed_type?.toUpperCase() === "EVENT" &&
                    (feed.feed_info.feed_sub_type === "Webinar" ||
                      feed.feed_info.feed_sub_type === "WEBINAR") && (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: feed?.feed_info?.feed_desc,
                          }}
                        ></div>
                        <p>Webinar Date and Time</p>
                        <p>
                          {webinarStatus === "join" && `LIVE`}
                          {webinarStatus === "ended" && `ENDED`}
                          {webinarStatus === "cancelled" && `CANCELLED`}
                        </p>

                        <p>
                          {`${getDate(
                            feed?.feed_info?.event_details?.from_date
                          )}  ${getTime(
                            feed?.feed_info?.event_details?.from_date
                          )} to
                  ${getTime(feed?.feed_info?.event_details?.to_date)}`}
                        </p>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "0.5em",
                          }}
                        >
                          {webinarStatus === "register" &&
                            !isRegisterClicked &&
                            !feed?.feed_info?.event_details
                              ?.is_user_registered && (
                              <button
                                style={{
                                  backgroundColor: "#0FB59B",
                                  borderRadius: "5px",
                                  width: "150px",
                                  height: "3em",
                                  margin: "0.5em",
                                }}
                                onClick={(e) => {
                                  handleWebinarRegister(e);
                                }}
                              >
                                Register now
                              </button>
                            )}
                          {webinarStatus === "join" && (
                            <a
                              href={eventLink}
                              target="_blank"
                              style={{ color: "white" }}
                            >
                              <button
                                style={{
                                  backgroundColor: "#0FB59B",
                                  borderRadius: "5px",
                                  width: "150px",
                                  height: "3em",
                                  margin: "0.5em",
                                }}
                              >
                                Join
                              </button>
                            </a>
                          )}
                          {webinarStatus === "ended" &&
                            !feed?.feed_info?.event_details
                              ?.videos_uploaded && (
                              <p>The recorded video will be updated shortly</p>
                            )}
                          {isRegisterClicked && <p>You are registered</p>}
                          {feed?.feed_info?.event_details?.is_user_registered &&
                            webinarStatus === "register" &&
                            !isRegisterClicked && <p>You are registered</p>}
                        </div>
                      </>
                    )}

                  {feed?.feed_info?.feed_type?.toUpperCase() === "ARTICLE" &&
                  feed?.feed_info?.article_body[0]?.article_desc ? (
                    <>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: feed?.feed_info?.article_body[0].article_desc,
                        }}
                      ></div>
                      {feed?.feed_info?.article_body[0]?.links.map((link) => (
                        <a href={`https://${link.link_url}`} target="_blank">
                          {link.link_name}
                        </a>
                      ))}
                    </>
                  ) : null}
                  {feed?.feed_info?.feed_type?.toUpperCase() === "SURVEY" &&
                    feed?.feed_info?.feed_survey?.questions.map((question) => (
                      <div style={{ marginLeft: "1.5em" }}>
                        <div
                          style={{
                            fontSize: "0.9em",
                            marginBottom: "2em",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: question.description,
                          }}
                        ></div>
                        <div
                          className="text-muted"
                          style={{ fontSize: "0.9em", margin: "5px 0" }}
                        >
                          Survery
                          {question.is_multi_select
                            ? ` (You can select more than one option)`
                            : ` (Select one option)`}
                        </div>
                        <div>
                          {question.options.map((option, key) => (
                            <div
                              style={{
                                border: "1px solid black",
                                borderRadius: "4px",
                                minHeight: "2.5em",
                                marginBottom: "1em",
                                width: "90%",
                                padding: "0.2em",
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                position: "relative",
                                justifyContent:
                                  ((feed?.feed_info?.feed_survey
                                    .is_participated &&
                                    feed?.feed_info?.feed_survey
                                      .immediate_results) ||
                                    !feed?.feed_info?.feed_survey?.is_open) &&
                                  "space-between",
                              }}
                              key={key}
                            >
                              {((feed?.feed_info?.feed_survey.is_participated &&
                                feed?.feed_info?.feed_survey
                                  ?.immediate_results) ||
                                !feed?.feed_info.feed_survey.is_open) && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    backgroundColor: "rgba(34, 139, 34, 0.25)",
                                    height: "100%",
                                    width: `${option.participated_pct}%`,
                                  }}
                                ></div>
                              )}
                              {!(
                                (feed?.feed_info?.feed_survey.is_participated &&
                                  feed.feed_info.feed_survey
                                    .immediate_results) ||
                                !feed.feed_info.feed_survey.is_open
                              ) && (
                                <div style={{ padding: "0.5em" }}>
                                  {surveyAnswer.indexOf(option.option_id) !==
                                    -1 || option.is_selected ? (
                                    <img
                                      src={select}
                                      onClick={() =>
                                        !feed?.feed_info?.feed_survey
                                          .is_participated &&
                                        feed.feed_info.feed_survey?.is_open &&
                                        handleSurvey(
                                          option.option_id,
                                          question.is_multi_select,
                                          false
                                        )
                                      }
                                      alt="checkbox"
                                      tabIndex="0"
                                    />
                                  ) : (
                                    <img
                                      src={unselect}
                                      onClick={() =>
                                        !feed?.feed_info?.feed_survey
                                          .is_participated &&
                                        feed.feed_info.feed_survey?.is_open &&
                                        handleSurvey(
                                          option.option_id,
                                          question.is_multi_select,
                                          true
                                        )
                                      }
                                      alt="checkbox"
                                      tabIndex="0"
                                    />
                                  )}
                                </div>
                              )}
                              <div style={{ padding: "0.5em" }}>
                                {option.option}
                              </div>
                              {((feed?.feed_info?.feed_survey.is_participated &&
                                feed.feed_info.feed_survey.immediate_results) ||
                                !feed.feed_info.feed_survey.is_open) && (
                                <div
                                  style={{ padding: "0.5em", float: "right" }}
                                >{`${option.participated_pct}%`}</div>
                              )}
                            </div>
                          ))}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {!feed?.feed_info?.feed_survey.is_participated &&
                            feed.feed_info.feed_survey?.is_open && (
                              <button
                                style={{
                                  backgroundColor: "#0FB59B",
                                  borderRadius: "5px",
                                  width: "40%",
                                  height: "3em",
                                }}
                                className="submit-btn"
                                onClick={() =>
                                  submitSurvey(
                                    feed.feed_info.feed_id,
                                    feed.channel_id,
                                    question.question_id,
                                    surveyAnswer
                                  )
                                }
                              >
                                Submit
                              </button>
                            )}
                        </div>
                        {feed?.feed_info?.feed_survey.is_participated && (
                          <div
                            className="text-muted"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              // fontSize: "0.9em",
                              margin: "5px 0",
                              fontWeight: "bold",
                              width: "100%",
                            }}
                          >
                            Thanks for participating
                          </div>
                        )}
                        {feed?.feed_info?.feed_survey.is_participated &&
                          feed.feed_info.feed_survey?.is_open && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "0.9em",
                                marginTop: "1em",
                                width: "100%",
                              }}
                              className="text-muted"
                            >
                              Survey results will be announced once the survey
                              is closed
                            </div>
                          )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "0.9em",
                            marginTop: "1em",
                            width: "100%",
                          }}
                          className="text-muted"
                        >
                          {!feed.feed_info.feed_survey?.is_open
                            ? `Survey closed`
                            : feed.feed_info.feed_survey?.close_time
                            ? `Survey closes on ${getTimeAndDate(
                                feed.feed_info.feed_survey?.close_time
                              )}`
                            : null}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            bottom: "0",
            right: "0",
            width: "100%",
            flexShrink: "0",
            background: "white",
            zIndex: "5",
            // height: "100px",
          }}
          className="footer-area responsive-footer d-block d-lg-none"
        >
          <div className="social-information">
            <div
              className="likes"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push({
                  pathname: `/likes/${feed.feed_info.feed_id}/${feed.channel_id}`,
                  state: { background: background },
                });
              }}
            >
              <p className="interaction-like" style={{ cursor: "pointer" }}>
                {feed?.feed_info?.socialInteraction.isLike
                  ? feed?.feed_info?.socialInteraction.likesCount === 1
                    ? `You like this`
                    : `You and ${
                        feed?.feed_info.socialInteraction?.likesCount - 1
                      }  people like this `
                  : feed?.feed_info?.socialInteraction?.likesCount === 0
                  ? null
                  : `${feed?.feed_info?.socialInteraction?.likesCount} people like this `}
              </p>
            </div>
            <div className="views">
              {feed?.feed_info?.socialInteraction?.viewCount !== 0 && (
                <p className="interaction-like">
                  {feed?.feed_info?.socialInteraction?.viewCount} views
                </p>
              )}
            </div>
          </div>
          <hr style={{ marginTop: "0px", marginBottom: "0px" }}></hr>
          <div className="post-meta" style={{ marginBottom: "15px" }}>
            <div style={{ display: "flex" }}>
              {feed?.feed_info?.socialInteraction?.isLike ? (
                <img
                  style={{ cursor: "pointer" }}
                  src={likeSelected}
                  onClick={() =>
                    updateLikeCount(
                      feed.feed_info.feed_id,
                      feed.channel_id,
                      false
                    )
                  }
                  className="social-icons-mobile"
                />
              ) : (
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    updateLikeCount(
                      feed.feed_info.feed_id,
                      feed.channel_id,
                      true
                    )
                  }
                  src={likeUnselected}
                  className="social-icons-mobile"
                />
              )}
              <span
                className="interaction-like"
                onClick={() => {
                  history.push({
                    pathname: `/likes/${feed.feed_info.feed_id}/${feed.channel_id}`,
                    state: { background: background },
                  });
                }}
              >
                {feed?.feed_info?.socialInteraction?.likesCount === 0
                  ? " "
                  : `${feed?.feed_info?.socialInteraction?.likesCount} `}
                Like
                {feed?.feed_info?.socialInteraction?.likesCount > 1
                  ? "s"
                  : null}
              </span>
              {/* <span
                className="interaction-like"
                onClick={() => {
                  history.push({
                    pathname: `/likes/${feed.feed_info.feed_id}/${feed.channel_id}`,
                    state: { background: background },
                  });
                }}
              >
                Like
                {feed?.feed_info?.socialInteraction?.likesCount > 1
                  ? "s"
                  : null}
              </span> */}
            </div>
            <div className="comment-share-meta">
              <div style={{ display: "flex" }}>
                <span>
                  <img src={commentUnselected} className="social-icons" />
                </span>
                <span className="interaction-comment">
                  {feed?.feed_info?.socialInteraction.commentsCount === 0
                    ? null
                    : feed?.feed_info?.socialInteraction.commentsCount}
                </span>
                <span
                  className="interaction-comment"
                  onClick={() => {
                    history.push({
                      pathname: `/comments/${feed.feed_info.feed_id}/${feed.channel_id}`,
                      state: {
                        background: background,
                        profile_pic: profile_pic,
                        feedContext: feedContext,
                        user_verification_info: user_verification_info,
                      },
                    });
                  }}
                >
                  Comment
                  {feed?.feed_info?.socialInteraction?.commentsCount > 1
                    ? "s"
                    : null}
                </span>
              </div>
            </div>
            <div className="comment-share-meta"></div>
          </div>
        </div>
      </div>
      <Modal
        open={uploadModel}
        onClose={closeUploadMci}
        classNames={{ modal: "uploadMci" }}
        closeIcon={closeIcon}
      >
        <MciUpload
          closeUploadMci={closeUploadMci}
          openSuccessModal={openUploadMciSuccess}
        />
      </Modal>

      <Modal
        closeIcon={closeIcon}
        open={uploadMciSuccess}
        onClose={closeUploadMciSuccess}
        classNames={{ modal: "verificationModel" }}
      >
        <MciUploadSuccess closeUploadMciSuccess={closeUploadMciSuccess} />
      </Modal>

      <Modal
        open={verificationStatusModal}
        onClose={closeVerificationStatusModal}
        classNames={{ modal: "verificationModel" }}
        closeIcon={closeIcon}
      >
        <MciVerificationStatus
          is_user_verified={user_verification_info.is_user_verified}
          closeVerificationStatusModal={closeVerificationStatusModal}
        />
      </Modal>
    </>
  );
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 9,
    height: "35px",
    marginTop: "-4px",
    marginBottom: "-2em",
    //background: "#ffffff",
    borderRadius: "9px",
    // border: "1px solid black",
    //width: "450px",
    marginLeft: "-8px",
    //  padding: "15px",
    fontSize: "14px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default MobileFullViewFeed;
