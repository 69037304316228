import { Modal } from "react-responsive-modal";
import { IoIosCloseCircle } from "react-icons/io";

const PublicationsModal = ({ modalTitle, open, closeModal, children }) => {

  return (
    <Modal
      open={open}
      onClose={closeModal}
      showCloseIcon={false}
      classNames={{ modal: "profileModel" }}
      styles={{ modal: { padding: 0, overflow: "hidden",height:modalTitle == "Publications"?"75%":"68%", } }}
    >
      <div className="post-modal">
        {/* <form onSubmit={handleSubmit(bruh)}> */}
        <div className="post-modal-header" style={{height:"4em"}}>
          <span>{modalTitle}</span>
          <IoIosCloseCircle
            style={{ cursor: "pointer" }}
            onClick={() => closeModal()}
            size={32}
          />
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default PublicationsModal;
