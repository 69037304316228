import { useState, useEffect, useRef, useCallback } from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import React from "react";
import Progressbar from "../progressbar";
import { Link, useParams, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { BsExclamation } from "react-icons/bs";
import { MdEdit } from "react-icons/md";

const DeleteAwardMember = ({
  getUserData,
  closeDeleteAwardsMembershipModal,
  deletedData
}) => {

  const history = useHistory();
  const user_id = getDocId();
  const headers = getHeaders();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const deletePublcation = async () => {
    let payload = {
      user_id: user_id,
    };
    if(deletedData.event_id != undefined){
      let event = {
        event_id: deletedData.event_id,
      };
      payload.events = [event];  
    }else{
      let pub={
      }
      if (deletedData.type == "award") {
        pub.award_id=deletedData.award_id;
        payload.award_history = [pub];
      } else if (deletedData.type == "membership") {
        pub.prof_mem_id=deletedData.prof_mem_id
        payload.membership_history = [pub];
      }
    }
    
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/delete", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      if(deletedData.event_id != undefined){
        toast.success('Event deleted');
      }else{
        if (deletedData.type == "award") {
          toast.success('Award deleted');
        } else if (deletedData.type == "membership") {
          toast.success('Membership deleted');
        }   
      }  
      getUserData();
      closeDeleteAwardsMembershipModal();
    } else {
      toast.error(data.errorMsg);
    }
  };


  return (
    <>
         <div className="container-fluid">
          <div
            className="row"
            style={{marginTop:"25px" }}
          >
            
            <div
              className="col-sm-12 "
            >
                                      <BsExclamation
                                        style={iconBackgroundStyle}
                                        size={65}
                                      />
            </div>
            <div className="col-sm-12 " style={{marginTop:'35px'}}>
              <div
                style={{
                  color: "black",
                  marginBottom: "10px",
                  font: "16px/16px roboto",
                  fontWeight:"600px"
                }}
              >
                {" "}
                <p>Are you sure want to delete this?</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "grey",
                  marginBottom: "10px",
                  font: "16px/16px roboto",
                  fontWeight:"600px"
                }}
              >
                {" "}
                <p>This information will no longer visible in your profile </p>
              </div>
            </div>
            <div className="col-sm-12 " >
              
              <div style={{ margin: "30px 30px" }}>         
          
          <button type="submit" className="pub-save-button" onClick={() => deletePublcation()} style={{width:"7em",height:"2.5em"}}>
            Ok 
          </button>
          <button type="submit" className="pub-close-button"  onClick={closeDeleteAwardsMembershipModal} style={{width:"7em",height:"2.5em"}}>
            Cancel
          </button>
        </div>
            </div>
          </div>
        </div> 
      {/* </Modal>  */}
    </>
  );
};

export default DeleteAwardMember;
const iconBackgroundStyle = {
  backgroundColor: "rgb(30 138 121)",
  borderRadius: "50%",
  padding: "3px",
  color: "white",
};
