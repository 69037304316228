import { useState } from "react";
import { useForm } from "react-hook-form";
import unlock from "../../assets/sign_up_and_sign_in_icons/Unlock.svg";
import eyeOpen from "../../assets/sign_up_and_sign_in_icons/View.svg";
import eyeClose from "../../assets/Icons/Icon awesome-eye.svg";

const ResetPassword = ({ resetPassword, confirmPasswordError }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [passwordView, togglePasswordView] = useState(false);
  const [confirmPasswordView, toggleConfirmPasswordView] = useState(false);

  return (
    <form onSubmit={handleSubmit(resetPassword)}>
      <div className="container">
        <div
          className="row"
          style={{
            marginTop: "1em",
            marginBottom: "1em",
            background: "#F6F6F6",
            borderRadius: "9px",
            height: "3.5em",
          }}
        >
          <div className="col-2">
            <img src={unlock} alt="" style={{ margin: "1.1em" }} />
          </div>
          <div className="col-8">
            <input
              style={{
                marginTop: "1.1em",
                paddingLeft: "5px",
                background: "inherit",
                width: "100%",
                border: "none",
              }}
              type={passwordView ? "text" : "password"}
              {...register("pass", {
                required: true,
                minLength: 8,
                maxLength: 30,
                pattern: /^[$_@ A-Za-z0-9]+$/,
              })}
              placeholder="Enter new Password"
            />
          </div>
          <div className="col-2">
            {passwordView ? (
              <img
                src={eyeClose}
                alt="toggle password view"
                style={{ marginTop: "1.2em" }}
                onClick={() => togglePasswordView((prev) => !prev)}
              />
            ) : (
              <img
                src={eyeOpen}
                alt="toggle password view"
                style={{ marginTop: "1.2em" }}
                onClick={() => togglePasswordView((prev) => !prev)}
              />
            )}
          </div>
        </div>
        <div className="row error-title">
          {errors.pass?.type === "required" && (
            <span className="error-title-text">
              {" "}
              Minimum 8 Characters needed{" "}
            </span>
          )}
        </div>
        <div className="row error-title">
          {errors.pass?.type === "pattern" && (
            <span className="error-title-text">
              Please use only '$', '_' or '@' symbols
            </span>
          )}
        </div>
        <div className="row error-title">
          {errors.pass?.type === "maxLength" && (
            <span className="error-title-text">
              Maximum 30 Characters needed
            </span>
          )}
        </div>
        <div className="row error-title">
          {errors.pass?.type === "minLength" && (
            <span className="error-title-text">
              Minimum 8 Characters needed
            </span>
          )}
        </div>
        <div
          className="row"
          style={{
            marginTop: "1em",
            marginBottom: "1em",
            background: "#F6F6F6",
            borderRadius: "9px",
            height: "3.5em",
          }}
        >
          <div className="col-2">
            <img src={unlock} alt="" style={{ margin: "1.1em" }} />
          </div>
          <div className="col-8">
            <input
              style={{
                marginTop: "1.1em",
                paddingLeft: "5px",
                background: "inherit",
                width: "100%",
                border: "none",
              }}
              type={confirmPasswordView ? "text" : "password"}
              {...register("confirmPass", { required: true })}
              placeholder="Confirm Password"
            />
          </div>
          <div className="col-2">
            {confirmPasswordView ? (
              <img
                src={eyeClose}
                alt="toggle password view"
                style={{ marginTop: "1.2em" }}
                onClick={() => toggleConfirmPasswordView((prev) => !prev)}
              />
            ) : (
              <img
                src={eyeOpen}
                alt="toggle password view"
                style={{ marginTop: "1.2em" }}
                onClick={() => toggleConfirmPasswordView((prev) => !prev)}
              />
            )}
          </div>
        </div>
        <div className="row error-title">
          {errors.confirmPass?.type === "required" && (
            <span className="error-title-text"> This field is required</span>
          )}
        </div>
        <div className="row error-title">
          {confirmPasswordError.error && (
            <span className="error-title-text">
              {confirmPasswordError.errorMsg}
            </span>
          )}
        </div>
      </div>

      <div className="login-button">
        <button
          type="submit"
          style={{
            backgroundColor: "#0FB59B",
            borderRadius: "10px",
            height: "3em",
          }}
          className="submit-btn"
        >
          Change Password
        </button>
      </div>
    </form>
  );
};

export default ResetPassword;
