import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import Select from "react-select";

const CountrySelect = ({
  value = "IN",
  onChange,
  labels,
  isRegister,
  disabled = false,
  showFlag = true,
  ...rest
}) => {
  const countries = getCountries().map((country) => ({
    value: country,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {showFlag && (
            <img
              style={{ height: "1.5em", width: "1.5em" }}
              alt="flag"
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`}
            />
          )}
          &nbsp;
          {` ${labels[country]}    (${country})`}
        </div>

        <span style={{ margin: "0.5em 0 0 0.5em" }}>
          +{getCountryCallingCode(country)}
        </span>
      </div>
    ),
  }));

  const defaultValue = {
    value: value,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        {showFlag && (
          <img
            // style={{ height: "1em", width: "1em" }}
            style={
              isRegister
                ? { height: "1.5em", width: "1.5em" }
                : { height: "2em", width: "1em" }
            }
            alt="flag"
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`}
          />
        )}
        {/* <span style={{ margin: "0.5em ",fontSize:'12px' }}>
          {value} +{getCountryCallingCode(value)}
        </span> */}
        <span
          style={
            isRegister
              ? { fontSize: "12px" }
              : { padding: "10px", fontSize: "12px" }
          }
        >
          &nbsp; {value} +{getCountryCallingCode(value)}
        </span>
      </div>
    ),
  };

  return (
    <div
      style={
        isRegister
          ? {
              backgroundColor: "#F6F6F6",
              height: "100%",
              borderRadius: "5px",
              // width: "85%",
              padding: "8px 2px",
            }
          : {
              // border: "1px solid #6a6a6a",
              backgroundColor: "white",
              border: "1px solid #8a8a8a",
              borderRadius: "6px",
              height: "50px",
              marginTop: "2px",
              padding: "5px 0px 0px 0px",
            }
      }
    >
      <Select
        isDisabled={disabled}
        styles={{
          control: (style) => ({
            ...style,
            background: "inherit",
            width: "100%",
            height: "100%",
            border: 0,
            boxShadow: "none",
          }),
          input: (style) => ({
            ...style,
            paddingLeft: "0px",
          }),
          menu: (style) => ({
            ...style,
            width: "20em",
          }),
          indicatorSeparator: (style) => ({
            ...style,
            display: "none",
          }),
          menuList: (style) => ({
            ...style,
            height: "12em",
          }),
        }}
        onChange={(selectedOption) => {
          onChange(selectedOption.value || undefined);
        }}
        value={defaultValue}
        options={countries}
      />
    </div>
  );
};

export default CountrySelect;
