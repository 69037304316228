import { MdArrowBackIos } from "react-icons/md";
import { useState, useEffect } from "react";
import ContactDetailsForm from "./ContactDetailsForm";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import verified from "../assets/new_profile_icons/confirm.svg";
import { Modal } from "react-responsive-modal";
import { IoIosCloseCircle } from "react-icons/io";

const MobileContactDetailsForm = ({ userInfo }) => {
  const history = useHistory();

  const [successModal, setSuccessModal] = useState(false);
  const openSuccessModal = () => setSuccessModal(true);
  const closeSuccessModal = () => setSuccessModal(false);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const successFunc = () => {
    openSuccessModal();
  };

  const errorFunc = () => {
    toast.error("Unable to update, please try after some time");
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "white",
        zIndex: 10,
        // overflow: "auto",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100vw",
      }}
    >
      <div
        style={{
          backgroundColor: "rgb(30 138 121)",
          display: "flex",
          color: "white",
          padding: "1em",
          alignItems: "center",
        }}
      >
        <MdArrowBackIos
          style={{ color: "white" }}
          onClick={() => history.replace("/profile")}
          size={32}
        />
        Contact Details
      </div>
      <ContactDetailsForm
        userInfo={userInfo}
        isMobile={true}
        successFunc={successFunc}
        errorFunc={errorFunc}
      />

      <Modal
        open={successModal}
        onClose={closeSuccessModal}
        showCloseIcon={false}
        backdrop="static"
        center
        classNames={{
          modal: "otpModal",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          onClick={() => closeSuccessModal()}
        >
          <IoIosCloseCircle
            style={{
              cursor: "pointer",
              color: "lightgrey",
            }}
            size={25}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={verified} style={{ width: "5em" }} />
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "rgb(30 138 121)",
            marginTop: "1em",
          }}
        >
          Contact Details
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "rgb(30 138 121)",
            // marginBlock: "0.3em",
          }}
        >
          Saved
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // marginBlock: "1em",
            marginTop: "1em",
            marginBottom: "1em",
          }}
        >
          <button
            style={{
              backgroundColor: "white",
              padding: "1em",
              borderRadius: "20px",
              fontSize: "1.2rem",
              width: "45%",
              border: "1px solid gray",
              color: "gray",
            }}
            onClick={() => closeSuccessModal()}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default MobileContactDetailsForm;
