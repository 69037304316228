import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { getDocId, getHeaders } from "../../utils/utils";

const useFeedDetails = (feed_id, channel_id) => {
  const doc_id = getDocId();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const [feed, setFeed] = useState({});
  const [error, setError] = useState(false);

  const getFeed = async () => {
    const payload = {
      doc_id,
      feedId: feed_id,
      channel_id,
    };

    const res = await fetch(baseUrl + "v3/fullViewFeed", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();

    if (data.status === "success") setFeed(data.data);
    else {
      setError(true);
      toast(data.errorMsg);
    }
  };

  useEffect(() => {
    getFeed();
  }, []);

  return { feed, setFeed, error };
};

export default useFeedDetails;
