import Facebook from "../assets/Icons/facebook-inactive.png";
import FacebookSelect from "../assets/Icons/facebook-select.png";

import LinkedIn from "../assets/Icons/linked-inactive.png";
import LinkedInSelect from "../assets/Icons/linkedin-select.png";

import Instagram from "../assets/Icons/instagram-inactive.png";
import InstagramSelect from "../assets/Icons/instgram-select.png";

// import WhatsApp from "../assets/Icons/WhatsApp.svg";
import Youtube from "../assets/Icons/youtube-inactive.png";
import YoutubeSelect from "../assets/Icons/youtube-select.png";

import Twitter from "../assets/Icons/twitter-inactive.png";
import TwitterSelect from "../assets/Icons/twitter-select.png";

import { Link, useHistory } from "react-router-dom";
import { useState, useLayoutEffect, useRef } from "react";

const Footer = ({ pre_login }) => {
  const onBenefits = () => {
    window.open("https://www.whitecoats.com/about-us.php");
  };

  const [hoverState, setHoverState] = useState({
    youtube: false,
    facebook: false,
    twitter: false,
    linkedIn: false,
    instagram: false,
  });

  const updateHoverState = (updateValue, toggleValue) => {
    setHoverState((prev) => ({
      ...prev,
      [updateValue]: toggleValue,
    }));
  };
  const test = () => {
    window.open("https://www.whitecoats.com/about-us.php");
    //window.location.href = "https://google.com/contact";
  };

  return (
    <>
      {!pre_login && (
        <footer
          style={{ backgroundColor: "#FBFBFB", padding: "5px" }}
          className="card d-block d-lg-none"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="footer-conatiner">
                  {/* <p className="footer-title-mobile"> Sign In </p>
                  <p className="footer-title-mobile">
                    {" "}
                    <Link className="link" to="/signup">
                      Sign Up
                    </Link>{" "}
                  </p> */}

                  <p className="footer-title-mobile">
                    <span onClick={onBenefits}>About</span>
                  </p>

                  <p className="footer-title-mobile">
                    <Link
                      className="link"
                      onClick={() => {
                        window.open(
                          "https://www.whitecoats.com/contact-us.php"
                        );
                      }}
                    >
                      {" "}
                      Contact Us
                    </Link>{" "}
                  </p>

                  <p className="footer-title-mobile">
                    <Link
                      className="link"
                      onClick={() => {
                        window.open(
                          "https://www.whitecoats.com/whitecoats-network-benefits.php"
                        );
                      }}
                    >
                      {" "}
                      Benefits of WhiteCoats
                    </Link>
                  </p>
                </div>
                <div className="footer-conatiner">
                  {!pre_login && (
                    <Link to="/help">
                      <p className="footer-title-mobile"> Help </p>
                    </Link>
                  )}
                  <p className="footer-title-mobile">
                    {" "}
                    <Link
                      className="link"
                      onClick={() => {
                        window.open(
                          "https://www.whitecoats.com/terms-of-use.php"
                        );
                      }}
                    >
                      {" "}
                      Terms of Use{" "}
                    </Link>
                  </p>

                  <p className="footer-title-mobile">
                    <Link
                      className="link"
                      onClick={() => {
                        window.open(
                          "https://www.whitecoats.com/privacy-policy.php"
                        );
                      }}
                    >
                      {" "}
                      Privacy Policy{" "}
                    </Link>
                  </p>
                </div>
                <div className="footer-conatiner">
                  <p className="footer-title-mobile">
                    <Link
                      className="link"
                      onClick={() => {
                        window.open(
                          "https://www.whitecoats.com/community-guidelines.php"
                        );
                      }}
                    >
                      {" "}
                      Community Guidlines
                    </Link>{" "}
                  </p>
                  <p className="footer-title-mobile">
                    <Link
                      className="link"
                      onClick={() => {
                        window.open(
                          "https://www.whitecoats.com/compliance-certifications.php"
                        );
                      }}
                    >
                      {" "}
                      Compliances & Certificates
                    </Link>
                  </p>
                </div>
                <div className="profile-footer-conatiner">
                  <hr style={{ width: "300px" }}></hr>
                </div>
                <div className="footer-conatiner">
                  <div className="profile-thumb">
                    <p
                      className="footer-title-social-interaction"
                      style={{ marginTop: "5px" }}
                    >
                      {" "}
                      Follow us on:{" "}
                    </p>
                  </div>
                  <div className="profile-thumb">
                    <p>
                      <div
                        onMouseEnter={() => updateHoverState("facebook", true)}
                        onMouseLeave={() => updateHoverState("facebook", false)}
                      >
                        {hoverState.facebook ? (
                          <img
                            className="footer-icon"
                            src={FacebookSelect}
                            onClick={() => {
                              window.open(
                                "https://www.facebook.com/WhiteCoatsApp/"
                              );
                            }}
                            alt="Facebook link"
                          />
                        ) : (
                          <img
                            className="footer-icon"
                            src={Facebook}
                            alt="Facebook link"
                          />
                        )}
                      </div>
                    </p>
                  </div>
                  <div className="profile-thumb">
                    <p
                      onMouseEnter={() => updateHoverState("linkedIn", true)}
                      onMouseLeave={() => updateHoverState("linkedIn", false)}
                    >
                      {hoverState.linkedIn ? (
                        <img
                          className="footer-icon"
                          src={LinkedInSelect}
                          onClick={() => {
                            window.open(
                              "https://www.linkedin.com/company/whitecoats/"
                            );
                          }}
                          alt="Facebook link"
                        />
                      ) : (
                        <img
                          className="footer-icon"
                          src={LinkedIn}
                          alt="Facebook link"
                        />
                      )}
                    </p>
                  </div>
                  <div className="profile-thumb">
                    <div
                      onMouseEnter={() => updateHoverState("twitter", true)}
                      onMouseLeave={() => updateHoverState("twitter", false)}
                    >
                      {hoverState.twitter ? (
                        <img
                          className="footer-icon"
                          onClick={() => {
                            window.open("https://twitter.com/WhiteCoatsApp");
                          }}
                          src={TwitterSelect}
                          alt="Facebook link"
                        />
                      ) : (
                        <img
                          className="footer-icon"
                          src={Twitter}
                          alt="Facebook link"
                        />
                      )}
                    </div>
                  </div>

                  <div className="profile-thumb">
                    <div
                      onMouseEnter={() => updateHoverState("instagram", true)}
                      onMouseLeave={() => updateHoverState("instagram", false)}
                    >
                      {hoverState.instagram ? (
                        <img
                          className="footer-icon"
                          onClick={() =>
                            window.open(
                              "https://www.instagram.com/whitecoatsapp/?hl=en"
                            )
                          }
                          src={InstagramSelect}
                          alt="Facebook link"
                        />
                      ) : (
                        <img
                          className="footer-icon"
                          src={Instagram}
                          alt="Facebook link"
                        />
                      )}
                    </div>
                  </div>
                  <div className="profile-thumb">
                    <div
                      onMouseEnter={() => updateHoverState("youtube", true)}
                      onMouseLeave={() => updateHoverState("youtube", false)}
                    >
                      {hoverState.youtube ? (
                        <img
                          className="footer-icon"
                          src={YoutubeSelect}
                          onClick={() => {
                            window.open(
                              "https://www.youtube.com/c/WhiteCoatsApp"
                            );
                          }}
                          alt="Facebook link"
                        />
                      ) : (
                        <img
                          className="footer-icon"
                          src={Youtube}
                          alt="Facebook link"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="profile-footer-conatiner">
                  <hr style={{ width: "300px" }}></hr>
                </div>
                <div className="profile-footer-conatiner">
                  <div className="profile-thumb">
                    <p className="footer-title-mobile">
                      {" "}
                      © 2021 WhiteCoats | All Rights Reserved{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}

      <footer
        style={{ backgroundColor: "#FBFBFB" }}
        className="card d-none d-sm-block"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className={pre_login ? "col-10" : "col-8"}>
              <div className="footer-conatiner">
                {pre_login && (
                  <>
                    <div className="profile-thumb">
                      <p className="footer-title">
                        {" "}
                        <Link className="link" to="/login">
                          Sign In
                        </Link>{" "}
                      </p>
                    </div>
                    <div className="profile-thumb">
                      <p className="footer-title">
                        {" "}
                        <Link className="link" to="/signup">
                          Sign Up
                        </Link>{" "}
                      </p>
                    </div>
                  </>
                )}
                <div className="profile-thumb">
                  <p className="footer-title" style={{ color: "green" }}>
                    <Link className="link" onClick={onBenefits}>
                      About WhiteCoats
                    </Link>{" "}
                  </p>
                </div>
                <div className="profile-thumb">
                  <p className="footer-title">
                    {" "}
                    <Link
                      className="link"
                      onClick={() => {
                        window.open(
                          "https://www.whitecoats.com/whitecoats-network-benefits.php"
                        );
                      }}
                    >
                      Benefits of WhiteCoats
                    </Link>{" "}
                  </p>
                </div>
                {!pre_login && (
                  <div className="profile-thumb">
                    <Link to="/help">
                      <p className="footer-title"> Help </p>
                    </Link>
                  </div>
                )}
                <div className="profile-thumb">
                  <p className="footer-title">
                    <Link
                      className="link"
                      onClick={() => {
                        window.open(
                          "https://www.whitecoats.com/contact-us.php"
                        );
                      }}
                    >
                      {" "}
                      Contact Us{" "}
                    </Link>
                  </p>
                </div>
                <div className="profile-thumb">
                  <p className="footer-title">
                    {" "}
                    <Link
                      className="link"
                      onClick={() => {
                        window.open(
                          "https://www.whitecoats.com/privacy-policy.php"
                        );
                      }}
                    >
                      {" "}
                      Terms of Use
                    </Link>{" "}
                  </p>
                </div>
                <div className="profile-thumb">
                  <p className="footer-title">
                    <Link
                      className="link"
                      onClick={() => {
                        window.open(
                          "https://www.whitecoats.com/privacy-policy.php"
                        );
                      }}
                    >
                      Privacy Policy{" "}
                    </Link>{" "}
                  </p>
                </div>
              </div>
              <div className="profile-footer-conatiner">
                <div className="profile-thumb">
                  <p className="footer-title" style={{ marginRight: "20px" }}>
                    <Link
                      className="link"
                      onClick={() => {
                        window.open(
                          "https://www.whitecoats.com/community-guidelines.php"
                        );
                      }}
                    >
                      {" "}
                      Community Guidelines
                    </Link>
                  </p>
                </div>
                <div className="profile-thumb">
                  <p className="footer-title">
                    <Link
                      className="link"
                      onClick={() => {
                        window.open(
                          "https://www.whitecoats.com/compliance-certifications.php"
                        );
                      }}
                    >
                      {" "}
                      Compliances & Certifications
                    </Link>{" "}
                  </p>
                </div>
              </div>
              <div className="Follow-footer-conatiner"></div>
              <div className="profile-footer-conatiner">
                <hr style={{ width: "1000px" }}></hr>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="profile-thumb">
                  <p className="footer-title">
                    {" "}
                    © 2021 WhiteCoats | All Rights Reserved{" "}
                  </p>
                </div>
                <div>
                  <div className="profile-thumb">
                    <p className="footer-title-social-interaction">
                      Follow us on:
                    </p>
                  </div>
                  <div className="profile-thumb">
                    <div
                      onMouseEnter={() => updateHoverState("facebook", true)}
                      onMouseLeave={() => updateHoverState("facebook", false)}
                    >
                      {hoverState.facebook ? (
                        <img
                          className="footer-icon"
                          src={FacebookSelect}
                          onClick={() => {
                            window.open(
                              "https://www.facebook.com/WhiteCoatsApp/"
                            );
                          }}
                          style={{ width: "30px", height: "25px" }}
                          alt="Facebook link"
                        />
                      ) : (
                        <img
                          className="footer-icon"
                          src={Facebook}
                          style={{ width: "30px", height: "25px" }}
                          alt="Facebook link"
                        />
                      )}
                    </div>
                  </div>
                  <div className="profile-thumb" style={{ marginLeft: "2px" }}>
                    <p
                      onMouseEnter={() => updateHoverState("linkedIn", true)}
                      onMouseLeave={() => updateHoverState("linkedIn", false)}
                    >
                      {hoverState.linkedIn ? (
                        <img
                          className="footer-icon"
                          src={LinkedInSelect}
                          onClick={() => {
                            window.open(
                              "https://www.linkedin.com/company/whitecoats/"
                            );
                          }}
                          style={{ width: "27px", height: "25px" }}
                          alt="Facebook link"
                        />
                      ) : (
                        <img
                          className="footer-icon"
                          src={LinkedIn}
                          style={{ width: "30px", height: "25px" }}
                          alt="Facebook link"
                        />
                      )}
                    </p>
                  </div>

                  <div
                    className="profile-thumb"
                    style={{ marginLeft: "2px" }}
                    onMouseEnter={() => updateHoverState("twitter", true)}
                    onMouseLeave={() => updateHoverState("twitter", false)}
                  >
                    {hoverState.twitter ? (
                      <img
                        className="footer-icon"
                        onClick={() => {
                          window.open("https://twitter.com/WhiteCoatsApp");
                        }}
                        src={TwitterSelect}
                        style={{
                          width: "27px",
                          height: "25px",
                          marginLeft: "2px",
                        }}
                        alt="Facebook link"
                      />
                    ) : (
                      <img
                        className="footer-icon"
                        onClick={() => {
                          window.open("https://twitter.com/WhiteCoatsApp");
                        }}
                        src={Twitter}
                        style={{ width: "27px", height: "25px" }}
                        alt="Facebook link"
                      />
                    )}
                  </div>

                  <div
                    className="profile-thumb"
                    style={{ marginLeft: "2px" }}
                    onMouseEnter={() => updateHoverState("instagram", true)}
                    onMouseLeave={() => updateHoverState("instagram", false)}
                  >
                    {hoverState.instagram ? (
                      <img
                        className="footer-icon"
                        src={InstagramSelect}
                        style={{
                          width: "27px",
                          height: "25px",
                          marginLeft: "2px",
                        }}
                        onClick={() =>
                          window.open(
                            "https://www.instagram.com/whitecoatsapp/?hl=en"
                          )
                        }
                        alt="Facebook link"
                      />
                    ) : (
                      <img
                        className="footer-icon"
                        onClick={() =>
                          window.open(
                            "https://www.instagram.com/whitecoatsapp/?hl=en"
                          )
                        }
                        src={Instagram}
                        style={{ width: "27px", height: "25px" }}
                        alt="Facebook link"
                      />
                    )}
                  </div>
                  <div
                    className="profile-thumb"
                    style={{ marginLeft: "2px" }}
                    onMouseEnter={() => updateHoverState("youtube", true)}
                    onMouseLeave={() => updateHoverState("youtube", false)}
                  >
                    {hoverState.youtube ? (
                      <img
                        className="footer-icon"
                        src={YoutubeSelect}
                        onClick={() => {
                          window.open(
                            "https://www.youtube.com/c/WhiteCoatsApp"
                          );
                        }}
                        style={{
                          width: "27px",
                          height: "25px",
                          marginLeft: "2px",
                        }}
                        alt="Facebook link"
                      />
                    ) : (
                      <img
                        onClick={() => {
                          window.open(
                            "https://www.youtube.com/c/WhiteCoatsApp"
                          );
                        }}
                        className="footer-icon"
                        src={Youtube}
                        style={{ width: "27px", height: "25px" }}
                        alt="Facebook link"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
