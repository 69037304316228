import { useState, useEffect, useRef } from "react";
import PhoneInput from "react-phone-number-input/input";
import CountrySelect from "../authentication/RegisterComponents/CountrySelect";
import { Controller, useForm } from "react-hook-form";
import en from "react-phone-number-input/locale/en.json";
import { parsePhoneNumber } from "react-phone-number-input";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import arrow from "../assets/profile_icons/Icon awesome-external-link-square-alt.svg";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { FaBlogger } from "react-icons/fa";
import { formatTime, getHeaders } from "../utils/utils";
import { Modal } from "react-responsive-modal";
import toast from "react-hot-toast";
import useCountDown from "react-countdown-hook";
import OtpInput from "react-otp-input";
import { IoIosCloseCircle } from "react-icons/io";
import verified from "../assets/new_profile_icons/confirm.svg";

const ContactDetailsForm = ({
  userInfo,
  isMobile = false,
  closeModal = () => {},
  successFunc,
  errorFunc,
}) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const firstRender = useRef(true);
  const userPhone = userInfo.cnt_num;
  const phoneNumber = userPhone && parsePhoneNumber(`+${userPhone}`);
  const [country, setCountry] = useState(
    phoneNumber ? phoneNumber.country : "IN"
  );
  const [otpModal, setOtpModal] = useState(false);
  const openOtpModal = async () => {
    if (await sendOTP()) setOtpModal(true);
  };
  const closeOtpModal = () => setOtpModal(false);

  const [otpInvalid, setOtpInvalid] = useState(false);
  const [recievedOtp, setRecievedOtp] = useState();
  const [otp, setOtp] = useState("");
  const [enableResendOtp, setEnableResenOtp] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  const initialTime = 1800 * 1000;
  const interval = 1000;
  const [timeLeft, { start }] = useCountDown(initialTime, interval);

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const phoneField = watch("phone");

  const inputDivStyle = {
    background: "#ffffff",
    borderRadius: "5px",
    height: "3.125em",
    border: "1px solid slategray",
    width: isMobile ? "100%" : "70%",
    display: "flex",
    alignItems: "center",
    padding: "1em",
    // marginBlock: "1em",
    marginTop: "1em",
    marginBottom: "1em",
  };

  const getWebsiteUrl = () => {
    const websiteUrlItems = userInfo?.socialInfo[0]?.profile_url.split("/");
    const editablePart = websiteUrlItems.pop();
    const nonEditablePart = websiteUrlItems.join("/");
    return { editablePart, nonEditablePart };
  };

  useEffect(() => {
    const phoneNumber = parsePhoneNumber(`+${userInfo.cnt_num}`);
    if (phoneNumber) {
      setCountry(phoneNumber.country);
      setValue("phone", phoneNumber.number);
    }
    setValue("phoneCheck", userInfo.cnt_num_vis === "SHOW_ALL" ? true : false);
    setValue("email", userInfo.cnt_email);
    setValue(
      "emailCheck",
      userInfo.cnt_email_vis === "SHOW_ALL" ? true : false
    );
    const link = userInfo.socialInfo[0].profile_url.split("/").pop();
    setValue("link", link);
    setValue("blog", userInfo.socialInfo[0].blog_pg);
    setValue("website", userInfo.socialInfo[0].website_pg);
    setValue("facebook", userInfo.socialInfo[0].fb_pg);
    setValue("twitter", userInfo.socialInfo[0].twitter_pg);
    setValue("linkedin", userInfo.socialInfo[0].linkedin_pg);
    setValue("instagram", userInfo.socialInfo[0].instagram_pg);
  }, []);

  useEffect(() => {
    if (otpModal) {
      setOtp("");
      setOtpInvalid(false);
      start();
      setEnableResenOtp(false);
      setTimeout(function () {
        setEnableResenOtp(true);
      }, 30000);
    }
  }, [otpModal]);

  useEffect(() => {
    if (isOtpVerified) setIsOtpVerified(false);
    if (!isOtpVerified)
      if (phoneField)
        if (phoneField.trim().substring(1) === userInfo.cnt_num)
          setIsOtpVerified(true);
  }, [phoneField]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setValue("phone", "");
  }, [country]);

  useEffect(() => {
    setOtpInvalid(false);
  }, [otp]);

  const validatePhoneInput = (phone) => {
    let isValid = true;
    if (!phone) {
      isValid = false;
      // error = "This field is required";
    }
    if (!isPossiblePhoneNumber(phone)) {
      isValid = false;
      // error = "Please enter valid number";
    }
    if (!isValidPhoneNumber(phone)) {
      isValid = false;
      // error = "Please enter valid number";
    }

    return isValid;
  };

  const updateContactDetails = async (formData) => {
    if (
      formData.phone.trim().substring(1) !== userInfo.cnt_num &&
      !isOtpVerified
    ) {
      openOtpModal();
      return;
    }

    const payload = {
      user_id: userInfo.user_id,
      cnt_email: formData.email.trim(),
      cnt_num: formData.phone.trim().substring(1),
      cnt_email_vis: formData.emailCheck ? "SHOW_ALL" : "HIDE_FROM_ALL",
      cnt_num_vis: formData.phoneCheck ? "SHOW_ALL" : "HIDE_FROM_ALL",
      socialInfo: [
        {
          about_me: userInfo.socialInfo[0].about_me,
          specific_ask: userInfo.socialInfo[0].specific_ask,
          blog_pg: formData.blog,
          website_pg: formData.website,
          fb_pg: formData.facebook,
          linkedin_pg: formData.linkedin,
          twitter_pg: formData.twitter,
          instagram_pg: formData.instagram,
          profile_url:
            formData.link === ""
              ? userInfo.socialInfo[0].profile_url
              : `${getWebsiteUrl().nonEditablePart}/${formData.link}`,
          pdf_url: userInfo.socialInfo[0].pdf_url,
        },
      ],
    };

    const res = await fetch(baseUrl + "v1/userProfile/update", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();

    if (data.status === "success") {
      localStorage.setItem("email", data.data.cnt_email);
      localStorage.setItem("phone", data.data.cnt_num);
      successFunc();
    } else {
      toast.error(data.errorMsg);
    }
  };

  const submitOtp = async (event) => {
    event.preventDefault();

    if (timeLeft === 0) return;
    if (recievedOtp === otp) {
      toast.success("Otp verified");
      setIsOtpVerified(true);
      closeOtpModal();
    } else {
      setOtpInvalid(true);
    }
  };

  const sendOTP = async () => {
    if (!validatePhoneInput(getValues("phone"))) {
      toast.error("Invalid phone number");
      return;
    }
    setOtp("");

    let reqPayload = {
      contact: getValues("phone").trim().substring(1),
      contactType: "PHONE",
      isProfileUpdate: true,
      doc_id: userInfo.user_id,
    };

    const res = await fetch(baseUrl + "sendOTP", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(reqPayload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();

    if (data.status === "success") {
      setRecievedOtp(data.otp);
      toast.success("Otp sent");
      start();
      setEnableResenOtp(false);
      setTimeout(function () {
        setEnableResenOtp(true);
      }, 30000);
      return true;
    } else {
      toast.error(data.errorMsg);
      return false;
    }
  };

  return (
    <div
      style={
        isMobile
          ? { overflowY: "auto", padding: "1em" }
          : {
              padding: "2em 1.5em",
              height: "100%",
              width: "100%",
              overflow: "auto",
            }
      }
    >
      <form onSubmit={handleSubmit(updateContactDetails)}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: isMobile ? "100%" : "70%",
            marginBottom: "1em",
          }}
        >
          <div
            style={{
              width: isMobile ? "50%" : "30%",
              marginRight: "1em",
              marginTop: "-2px",
            }}
          >
            <CountrySelect
              value={country}
              isRegister={false}
              onChange={setCountry}
              labels={en}
              showFlag={false}
            />
          </div>
          <div
            style={{
              ...inputDivStyle,
              // marginBlock: 0,
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            <Controller
              name="phone"
              control={control}
              rules={{
                required: true,
                validate: (value) => validatePhoneInput(value),
              }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  country={country}
                  value={value}
                  onChange={onChange}
                  placeholder="Contact Number *"
                  style={{
                    background: "inherit",
                    width: "100%",
                    border: "none",
                  }}
                />
              )}
            />
            {isOtpVerified ? (
              <img style={{ width: "20px" }} src={verified} />
            ) : (
              <span
                style={{
                  color: "rgb(30 138 121)",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                }}
                onClick={() => openOtpModal()}
              >
                Verify
              </span>
            )}
          </div>
        </div>

        {errors.phone && (
          <div style={errorMsg}>Please enter a valid phone number</div>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            // marginBlock: "0.5em",
            marginTop: "0.5em",
            marginBottom: "0.5em",
          }}
        >
          <input
            style={{ width: "20px", height: "20px", margin: 0 }}
            type="checkbox"
            name="phoneCheck"
            {...register("phoneCheck")}
          />
          <span
            className="form-check-label"
            style={{
              marginLeft: "5px",
              fontSize: "1.1rem",
            }}
            htmlFor="phoneCheck"
          >
            Show My Contact Number to My Connects
          </span>
        </div>

        <div style={inputDivStyle}>
          <input
            style={inputStyle}
            type="email"
            {...register("email", {
              required: true,
              maxLength: 150,
              pattern: {
                value: /^\s*[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}\s*$/i,
                message: "invalid email address",
              },
            })}
            placeholder="Email ID"
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            // marginBlock: "0.5em",
            marginTop: "0.5em",
            marginBottom: "0.5em",
          }}
        >
          <input
            style={{ width: "20px", height: "20px", margin: 0 }}
            type="checkbox"
            name="emailCheck"
            {...register("emailCheck")}
          />
          <span
            className="form-check-label"
            style={{
              marginLeft: "5px",
              fontSize: "1.1rem",
            }}
            htmlFor="emailCheck"
          >
            Show My Email ID to My Connects
          </span>
        </div>
        {errors.email?.type === "required" && (
          <div style={errorMsg}>Please enter a valid email id</div>
        )}
        {errors.email?.type === "maxLength" && (
          <div style={errorMsg}>max length is 150</div>
        )}
        {errors.email?.type === "pattern" && (
          <div style={errorMsg}> Please enter valid email id</div>
        )}

        <div
          style={{
            // marginBlock: "1em",
            marginTop: "1em",
            marginBottom: "1em",
            fontSize: "1.4rem",
          }}
        >
          WhiteCoats URL
        </div>
        <div
          style={{
            // marginBlock: "1em",
            marginTop: "1em",
            marginBottom: "1em",
            color: "slategray",
            fontSize: "1.2rem",
          }}
        >
          <span>{`${getWebsiteUrl().nonEditablePart}/`}</span>
          <span style={{ color: "rgb(30 138 121)" }}>
            {getWebsiteUrl().editablePart}
          </span>
        </div>
        <div style={inputDivStyle}>
          <img src={arrow} style={{ width: "25px" }} />
          <div style={{ marginLeft: "1em", width: "100%" }}>
            <input
              style={inputStyle}
              type="text"
              {...register("link", {
                maxLength: 140,
                pattern: /^[0-9a-zA-Z_-]*$/,
              })}
              placeholder="link"
            />
          </div>
        </div>
        {errors.link?.type === "maxLength" && (
          <div style={errorMsg}>max length is 140 characters</div>
        )}
        {errors.link?.type === "pattern" && (
          <div style={errorMsg}>
            Only alphanumric characters, _ or - are allowed
          </div>
        )}

        <hr />
        <div
          style={{
            // marginBlock: "1em",
            marginTop: "1em",
            marginBottom: "1em",
            fontSize: "1.6rem",
          }}
        >
          Social Links
        </div>
        <div style={inputDivStyle}>
          <img src={arrow} style={{ width: "25px" }} />
          <div style={{ marginLeft: "1em", width: "100%" }}>
            <input
              style={inputStyle}
              type="text"
              {...register("website", {
                maxLength: 100,
              })}
              placeholder="Your Website"
            />
          </div>
        </div>
        {errors.website?.type === "maxLength" && (
          <div style={errorMsg}>Please enter less than 100 characters</div>
        )}

        <div style={inputDivStyle}>
          {/* <img src={arrow} style={{ width: "25px" }} /> */}
          <FaBlogger size={25} style={{ color: "grey" }} />
          <div style={{ marginLeft: "1em", width: "100%" }}>
            <input
              style={inputStyle}
              type="text"
              {...register("blog", {
                maxLength: 100,
              })}
              placeholder="Blog"
            />
          </div>
        </div>

        {errors.blog?.type === "maxLength" && (
          <div style={errorMsg}>Please enter less than 100 characters</div>
        )}

        <div style={inputDivStyle}>
          <FacebookIcon
            style={{ width: "25px", height: "25px", color: "grey" }}
          />
          <div style={{ marginLeft: "1em", width: "100%" }}>
            <input
              style={inputStyle}
              type="text"
              {...register("facebook", {
                maxLength: 100,
              })}
              placeholder="Facebook page"
            />
          </div>
        </div>
        {errors.facebook?.type === "maxLength" && (
          <div style={errorMsg}>Please enter less than 100 characters</div>
        )}

        <div style={inputDivStyle}>
          {/* <img src={arrow} style={{ width: "25px" }} /> */}
          <TwitterIcon
            style={{ width: "25px", height: "25px", color: "grey" }}
          />
          <div style={{ marginLeft: "1em", width: "100%" }}>
            <input
              style={inputStyle}
              type="text"
              {...register("twitter", {
                maxLength: 100,
              })}
              placeholder="Twitter"
            />
          </div>
        </div>

        {errors.twitter?.type === "maxLength" && (
          <div style={errorMsg}>Please enter less than 100 characters</div>
        )}

        <div style={inputDivStyle}>
          {/* <img src={arrow} style={{ width: "25px" }} /> */}
          <LinkedInIcon
            style={{ width: "25px", height: "25px", color: "grey" }}
          />

          <div style={{ marginLeft: "1em", width: "100%" }}>
            <input
              style={inputStyle}
              type="text"
              {...register("linkedin", {
                maxLength: 100,
              })}
              placeholder="Linkedin"
            />
          </div>
        </div>

        {errors.linkedin?.type === "maxLength" && (
          <div style={errorMsg}>Please enter less than 100 characters</div>
        )}

        <div style={inputDivStyle}>
          {/* <img src={arrow} style={{ width: "25px" }} /> */}
          <InstagramIcon
            style={{ width: "25px", height: "25px", color: "grey" }}
          />

          <div style={{ marginLeft: "1em", width: "100%" }}>
            <input
              style={inputStyle}
              type="text"
              {...register("instagram", {
                maxLength: 100,
              })}
              placeholder="Instagram"
            />
          </div>
        </div>

        {errors.instagram?.type === "maxLength" && (
          <div style={errorMsg}>Please enter less than 100 characters</div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: isMobile ? "100%" : "37%",
          }}
        >
          <button
            style={
              isMobile
                ? {
                    backgroundColor: "#469b8d",
                    borderRadius: "30px",
                    color: "white",
                    width: "100%",
                    padding: "0.7em",
                    fontSize: "2rem",
                  }
                : {
                    backgroundColor: "#469b8d",
                    padding: "0.8em 2.5em",
                    borderRadius: "15px",
                    color: "white",
                    fontSize: "1.2rem",
                  }
            }
            type="submit"
          >
            Save
          </button>
          {!isMobile && (
            <button
              style={{
                backgroundColor: "white",
                padding: "0.8em 2.5em",
                borderRadius: "15px",
                fontSize: "1.2rem",
                border: "1px solid #469b8d",
              }}
              type="button"
              onClick={() => closeModal()}
            >
              Close
            </button>
          )}
        </div>
      </form>
      <Modal
        open={otpModal}
        onClose={closeOtpModal}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        backdrop="static"
        center
        classNames={{
          modal: "otpModal",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          onClick={() => closeOtpModal()}
        >
          <IoIosCloseCircle
            style={{
              cursor: "pointer",
              color: "lightgrey",
            }}
            size={25}
          />
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "black",
            // marginBlock: "1em",
            marginTop: "1em",
            marginBottom: "1em",
          }}
        >
          Phone Verification
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "1.8rem",
            color: "black",
            // marginBlock: "1em",
            marginTop: "1em",
            marginBottom: "1em",
          }}
        >
          Enter OTP
        </div>
        <div>
          <form onSubmit={submitOtp}>
            <div className="otp-container">
              <OtpInput
                className="otp"
                value={otp}
                onChange={(newOtp) => {
                  setOtp(newOtp);
                }}
                isInputNum="true"
                inputStyle={{
                  width: "2em",
                  height: "2.5em",
                  border: "1px solid",
                  borderRadius: "5px",
                }}
                numInputs={4}
                separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // marginBlock: "1em",
                marginTop: "1em",
                marginBottom: "1em",
                fontSize: "1.2rem",
                padding: "1em",
              }}
            >
              <div>
                {timeLeft === 0 ? (
                  <span className="text-danger">Otp Expired</span>
                ) : (
                  <span>
                    OTP Expires in
                    <span style={{ color: "#1e8a79" }}>
                      {` ${formatTime(timeLeft)}`}
                    </span>
                  </span>
                )}
              </div>
              <div
                style={{
                  color: enableResendOtp ? "#1e8a79" : "#808080",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!enableResendOtp) return;
                  sendOTP();
                }}
              >
                <u>Resend OTP</u>
              </div>
            </div>

            {otpInvalid ? (
              <h5 style={{ color: "red", textAlign: "center" }}>
                OTP entered is not valid
              </h5>
            ) : null}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1em",
              }}
            >
              <button
                style={{
                  backgroundColor: "white",
                  padding: "1em",
                  borderRadius: "20px",
                  fontSize: "1.2rem",
                  width: "45%",
                  border: "1px solid gray",
                }}
                onClick={() => closeOtpModal()}
              >
                Cancel
              </button>
              <button
                style={{
                  backgroundColor:
                    otp.length === 4 ? "rgb(30 138 121)" : "gray",
                  borderRadius: "30px",
                  color: "white",
                  padding: "1em",
                  fontSize: "1.2rem",
                  width: "45%",
                }}
                type="submit"
                disabled={otp.length === 4 ? false : true}
              >
                Verify
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

const inputStyle = {
  background: "inherit",
  width: "100%",
  border: "none",
};

const errorMsg = {
  color: "red",
  fontSize: "1.1rem",
  // marginBlock: "1em",
  marginTop: "1em",
  marginBottom: "1em",
};

export default ContactDetailsForm;
