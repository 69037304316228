import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { getHeaders, logout } from "../../utils/utils";

const useLikesLoader = (doc_id , channel_id , feedTypeId , lastLikeId) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const headers = getHeaders();
    const history = useHistory();
  
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [likes, setLikes] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [likeData , setLikeData] = useState({})
 

    const  getLikes  = async () => {
      setLoading(true);
      setError(false);
      const payload = {
        doc_id,
        channel_id,
        feedTypeId ,
        lastLikeId,
      };
      const res = await fetch(baseUrl + "v1/feedSocialInteractions/getLikes", {
        method: "POST",
        headers,
        body: "reqData=" + JSON.stringify(payload),
      }).catch((e) => {
       
        setLoading(false);
        setError(true);
        setHasMore(false);
        logout(history);
        return;
      });
      const data = await res.json();
      if (data.status === "success") {
        setLikes((prevLikes) => {
          return [...new Set([...prevLikes, ...data.data.likes])];
        });
        setLikeData({
            newLastLikeId : data.data.lastLikeId,
            likesCount : data.data.socialInteraction?.likesCount
        })
        setLoading(false);
        setHasMore(lastLikeId != -1 );
      } else {
      
        setLoading(false);
        setError(true);
        setHasMore(false);
        logout(history);
        return;
      }
    };
  
    useEffect(() => {
      getLikes();
    }, [lastLikeId]);
  
    return { loading, error, likes,  hasMore, likeData  };
}

export default useLikesLoader
