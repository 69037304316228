import { useRef, useCallback } from "react";
import audio_placeholder from "../assets/audio_placeholder.png";
import { FaArrowLeft } from "react-icons/fa";

const ChannelMediaGalleryView = ({
  galleryDetails: { gallery, error, loading, hasMore, lastTimestamp, count },
  setView,
  openFullView,
  setGalleryData,
  galleryHeader,
}) => {
  const observer = useRef(null);

  const loader = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setGalleryData((prev) => ({
            ...prev,
            timestamp: lastTimestamp,
          }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <div style={{ margin: "1em 0 5em", overflow: "auto" }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div onClick={() => setView("group")}>
          <FaArrowLeft style={{ height: "1.3em", width: "1.3em" }} />
        </div>
        <div
          style={{
            fontFamily: "bold",
            fontSize: "1.3em",
          }}
        >
          {`${galleryHeader} (${count})`}
        </div>
        <div></div>
      </div>
      {gallery.map((galleryItem, key) => (
        <div className="media-tray-container" key={key}>
          <div>
            <h4 className="media-gallery-header">{galleryItem.title}</h4>
          </div>

          <div className="media-gallery">
            {galleryItem.fileData.map((file, key) => (
              <div
                key={key}
                className="media-thumbnail-container"
                onClick={() => openFullView(file)}
              >
                <img
                  src={
                    file.attachment_type.toLowerCase() === "audio"
                      ? audio_placeholder
                      : file.attachment_small_url
                  }
                  className="media-thumbnail"
                />
              </div>
            ))}
          </div>
        </div>
      ))}
      {loading && <div> Loading ....</div>}
      {error && <div>Error loading data</div>}
      <div ref={loader} />
    </div>
  );
};

export default ChannelMediaGalleryView;
