import { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ChannelMediaGalleryView from "./ChannelMediaGalleryView";
import ChannelMediaGroupView from "./ChannelMediaGroupView";
import { getDocId, getHeaders, closeIcon } from "../utils/utils";
import { Modal } from "react-responsive-modal";
import ReactAudioPlayer from "react-audio-player";
import useGalleryLoader from "./hooks/useGalleryLoader";
import { FeedFuncs } from "../community/Community";
import FullViewFeed from "../dashboard/feeds/FullViewFeed";
import getWindowDimensions from "../common/hooks/getWindowDimensions";
import toast from "react-hot-toast";
// import PDF from "../common/PDF";

const ChannelMedia = ({ channel,setFeeds }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const doc_id = getDocId();
  const headers = getHeaders();

  const location = useLocation();
  const history = useHistory();
  const {
    updateLikeCount,
    addComment,
    profile_pic,
    submitSurvey,
    updateViewCount,
    deleteComment,
    updateComment,
    registerWebinar,
    user_verification_info,
    openLikesModal,
    commentsListInfo,
    feedData,
    setFeedData,
  } = useContext(FeedFuncs);

  const [view, setView] = useState("group");
  const [galleryData, setGalleryData] = useState({
    mediaTypes: [],
    timestamp: 0,
  });
  const [galleryHeader, setGalleryHeader] = useState("");
  const [media, setMedia] = useState([]);

  const [filePreviewData, setFilePreviewData] = useState({});
  const [imgPreview, setImgPreview] = useState(false);
  const openImagePreview = () => setImgPreview(true);
  const closeImagePreview = () => setImgPreview(false);

  const fullViewFeedRef = useRef(null);
  const [viewFeed, setViewFeed] = useState(false);
  const openFeed = () => setViewFeed(true);
  const closeFeed = () => setViewFeed(false);

  // const [pdfTotalPages, setPdfTotalPages] = useState(null);
  // const [pdfPageNum, setPdfPageNum] = useState(1);

  // const onDocumentLoadSuccess = ({ numPages }) => {
  //   setPdfTotalPages(numPages);
  // };

  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }
    getChannelMedia();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const galleryDetails = useGalleryLoader(
    galleryData,
    channel.channel_id,
    view
  );

  const openFullView = (fileData) => {
    setFilePreviewData(fileData);
    openImagePreview();
  };

  const setGalleryView = (mediaType, header) => {
    setGalleryData({
      galleryMediaType: mediaType,
      timestamp: 0,
    });
    setGalleryHeader(header);
    setView("gallery");
  };

  const viewPost = () => {
    closeImagePreview();
    if (windowDimensions.width <= 768)
      history.push({
        pathname: `/feed/${filePreviewData.feedId}/${filePreviewData.channel_id}`,
        state: {
          background: location,
          profile_pic: profile_pic,
          channels: [],
          user_verification_info: user_verification_info,
          feedContext: getFeedContext(),
        },
      });
    else {
      getFeed();
    }
  };

  const getFeedContext = () => {
    if (location?.pathname.includes("/profile")) return "profile";
    if (location?.pathname === "/professional") return "professional";
    if (location?.pathname === "/knowledge") return "knowledge";
    if (location?.pathname === "/community") return "community";
    else return "dashboard";
  };

  const getChannelMedia = async () => {
    const payload = {
      doc_id: doc_id,
      channel_ids: [channel.channel_id],
    };

    const res = await fetch(baseUrl + "v2/channel/getMediaSections", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();

    if (data.status === "success") {
      setMedia(data.data.mediaSections);
    }
  };

  const getFeed = async () => {
    const payload = {
      doc_id,
      feedId: filePreviewData.feedId,
      channel_id: filePreviewData.channel_id,
    };

    const res = await fetch(baseUrl + "v3/fullViewFeed", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();

    if (data.status === "success") {
      setFeedData(data.data);
      openFeed();
    } else toast(data.errorMsg);
  };

  const getMediaPreview = (fileData) => {
    if (fileData.attachment_type == "image")
      return (
        <img
          src={fileData?.attachment_original_url}
          style={{ maxHeight: "100%", maxWidth: "100%" }}
        />
      );

    if (fileData.attachment_type === "video")
      return (
        <video
          preload="metadata"
          style={{ maxHeight: "100%", maxWidth: "100%" }}
          controls="controls"
        >
          <source
            src={`${fileData.attachment_original_url}#t=0.1`}
            type="video/mp4"
          />
        </video>
      );

    if (fileData.attachment_type === "audio")
      return (
        <ReactAudioPlayer
          controls
          src={fileData.attachment_original_url}
          style={{ width: "100%" }}
        />
      );

    if (fileData.attachment_type === "pdf")
      if (windowDimensions.width < 768)
        return (
          <div
            className="iframe-loader"
            style={{ width: "100%", height: "400px" }}
          >
            <iframe
              id="iframeContainer"
              src={`https://drive.google.com/viewerng/viewer?url=${fileData.attachment_original_url}?pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
              width="100%"
              height="400px"
            />
          </div>
        );
      else
        return (
          <object
            data={
              fileData.attachment_original_url.includes("http://")
                ? fileData.attachment_original_url.replace(
                    "http://",
                    "https://"
                  )
                : fileData.attachment_original_url
            }
            type="application/pdf"
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <iframe
              style={{ height: "100%", width: "100%" }}
              src={
                fileData.attachment_original_url.includes("http://")
                  ? fileData.attachment_original_url.replace(
                      "http://",
                      "https://"
                    )
                  : fileData.attachment_original_url
              }
            />
          </object>
        );

    // if (fileData.attachment_type === "pdf")
    //   return (
    //     <PDF
    //       src={fileData.attachment_original_url}
    //       onDocumentLoadSuccess={onDocumentLoadSuccess}
    //       pdfTotalPages={pdfTotalPages}
    //       pdfPageNum={pdfPageNum}
    //       setPdfPageNum={setPdfPageNum}
    //       width={500}
    //     />
    //   );
  };

  return (
    <>
      {
        {
          group: (
            <ChannelMediaGroupView
              media={media}
              openFullView={openFullView}
              setView={setGalleryView}
            />
          ),
          gallery: (
            <ChannelMediaGalleryView
              galleryDetails={galleryDetails}
              openFullView={openFullView}
              setView={setView}
              setGalleryData={setGalleryData}
              galleryHeader={galleryHeader}
            />
          ),
        }[view]
      }

      <Modal
        open={imgPreview}
        onClose={closeImagePreview}
        center
        showCloseIcon={false}
      >
        <div>
          <div className="channel-file-preview-container">
            {getMediaPreview(filePreviewData)}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <button
              style={{
                color: "white",
                background: "black",
                padding: "10px",
                borderRadius: "5px",
              }}
              onClick={() => viewPost()}
            >
              View Post
            </button>
            <button
              style={{
                color: "white",
                background: "black",
                padding: "10px",
                borderRadius: "5px",
              }}
              onClick={closeImagePreview}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={viewFeed}
        blockScroll={true}
        style={customStyles}
        onClose={closeFeed}
        closeIcon={closeIcon}
        classNames={{ modal: "feedModel" }}
        ref={fullViewFeedRef}
      >
        <FullViewFeed
          profile_pic={profile_pic}
          close={closeFeed}
          feed={feedData}
          setFeeds={setFeeds}

          addComment={addComment}
          updateLikeCount={updateLikeCount}
          submitSurvey={submitSurvey}
          updateViewCount={updateViewCount}
          deleteComment={deleteComment}
          updateComment={updateComment}
          openLikesModal={openLikesModal}
          openCommentMobileView={commentsListInfo}
          registerWebinar={registerWebinar}
          user_verification_info={user_verification_info}
        ></FullViewFeed>
      </Modal>
    </>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default ChannelMedia;
