import { useState, useEffect, useRef, useCallback } from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import React from "react";
import S3 from "react-aws-s3";
import { useHistory, useLocation } from "react-router-dom";
import "../createPost/createpost.css";
import { resolvePromise, uploadFileToS3 } from "../utils/utils";
import InputBase from "@mui/material/InputBase";
import medicalImage from "../assets/Web Icons/Icon awesome-notes-medical.svg";
import newspaperImage from "../assets/Web Icons/Icon awesome-newspaper.svg";
import circleImage from "../assets/Web Icons/Icon material-announcement.svg";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";

import doneImage from "../assets/Web Icons/Icon material-done.svg";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactAudioPlayer from "react-audio-player";
import { useForm, Controller } from "react-hook-form";

import "./css/signup.css";
import toast from "react-hot-toast";

import { makeStyles } from "@material-ui/core/styles";

import TextField from "@mui/material/TextField";
import { styled } from "@material-ui/core/styles";

import { BsPlusCircle } from "react-icons/bs";
import getWindowDimensions from "../common/hooks/getWindowDimensions";

import "react-quill/dist/quill.snow.css";

import questionIcon from "../assets/Ask A Question-1X.png";
import CreatePostModal from "./CreatePostModal";
import FullViewFeed from "../dashboard/feeds/FullViewFeed";
import dashboardServices from "../services/dashboardServices";
import Likes from "../dashboard/social-interaction/Likes";

const Createpost = ({
  channels,
  type = "ribbon",
  home,
  userInfo,
  handleUploadMci,
  handleVerificationPending,
  loadDashBoardData = () => {},
  loadKnowledgeData,
  loadProfessionalData,
  loadCommunityData,
  selectedChannel = 0,
}) => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm();
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ color: [] }, { background: [] }],
    ],
  };
  const formats = ["bold", "italic", "underline", "color", "background"];
  //console.log("data"+setCategoryListInfo)
  const [spinner, setSpinner] = useState(false);
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const history = useHistory();
  const location = useLocation();
  const doc_id = getDocId();
  const headers = getHeaders();
  const [showPost, setShowPost] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const closeIcon = <img src={close}></img>;
  const closeIconHide = <img src={close} style={{ display: "none" }}></img>;
  const [openPostView, setOpenPostView] = useState(false);
  const [openPostSuccessView, setOpenPostSuccessView] = useState(false);
  const [channelsData, setChannelsData] = useState([]);
  const [selectedShare, setSelectedShare] = useState(channelsData[0]);
  const [specialities, setSpecialities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [previewModal, setPreviewModal] = useState(false);
  const closePreviewModal = () => setPreviewModal(false);
  const [previewAttachment, setPreviewAttachment] = useState();

  const [selected, setSelected] = useState([]);
  const [isAllSelected, setAllSelected] = useState(true);
  const [departmentValue, setDepartmentValue] = useState([]);
  const [departmentKey, setDepartmentKey] = useState([]);

  const [selectedSpec, setSelectedSpec] = useState([]);
  const [isAllSelectedSpec, setAllSelectedSpec] = useState(true);
  const [specialitiesValue, setSpecialitiesValue] = useState([]);
  const [specialitiesKey, setSpecialitiesKey] = useState([]);
  const [modalTitle, setModalTitle] = useState("");

  const [isfileUploaded, setIsFileUploaded] = useState(false);
  const fileInput = useRef(null);
  const editFileInput = useRef(null);
  const [imgPreviewUrl, setImgPreviewUrl] = useState("");
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const [postAttachments, setPostAttachments] = useState([]);

  const [likesModal, setLikesModal] = useState(false);

  const openLikesModal = () => setLikesModal(true);
  const closeLikesModal = () => setLikesModal(false);

  const fullViewFeedRef = useRef(null);
  const [viewFeed, setViewFeed] = useState(false);
  const openFeed = () => setViewFeed(true);
  const closeFeed = () => setViewFeed(false);

  const [buttonDropdown, setButtonDropdown] = useState(false);
  const [headDropdown, setHeadDropdown] = useState(false);
  const [attachError, setAttachError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [quilContent, setQuilContent] = useState("");
  const [feedData, setFeedData] = useState({});
  //const isAllSelected =departments.length > 0 && selected.length === departments.length;
  const quilChange = (content, delta, source, editor) => {
    setQuilContent(editor.getHTML());
    // console.log(editor.getHTML()); // rich text
    // console.log(editor.getText()); // plain text
    // console.log(editor.getLength()); // number of characters
  };
  const handlePostModal = (data) => {
    if (!userInfo.verificationInfo.isCommunityVerified) {
      if (userInfo.verificationInfo.is_user_verified == 1) {
        handleUploadMci();
        return;
      }
      if (userInfo.verificationInfo.is_user_verified == 2) {
        handleVerificationPending();
        return;
      }
    }
    setQuilContent("");
    setloaderSpinner(false);
    setHeadDropdown((prev) => !prev);
    setButtonDropdown((prev) => !prev);
    //setModalTitle("");
    if (data === 1) {
      setModalTitle("Post A Case");
    } else if (data === 2) {
      setModalTitle("Share News/Announcements");
    } else if (data === 3) {
      setModalTitle("Ask A Question");
    }
    reset();
    changeChannels(data);
    setPostAttachments([]);
    setOpenPostView(true);
  };
  const handlePostModalClose = () => {
    setOpenPostView(false);
  };
  const showSuccessView = () => {
    setOpenPostView(false);
    setOpenPostSuccessView(true);
  };
  const hideSuccessView = (e) => {
    e.preventDefault();
    setOpenPostSuccessView(false);
  };

  const renderPostValue = () => {
    let data = "";
    let first = selected[0];
    let length = selected.length - 1;
    if (selected.length === departmentValue.length) {
      data = "All Members";
    } else if (selected.length == 1) {
      data = selected[selected.length - 1];
    } else if (selected.length >= 2) {
      data = first + " +" + length + " more";
    }
    return data;
  };
  const handleChange = async (event, child) => {
    const value = event.target.value;
    setValue("post", event.target.value);
    if (value[value.length - 1] === "all") {
      setAllSelected(selected.length === departmentValue.length ? false : true);
      setSelected(
        selected.length === departmentValue.length ? [] : departmentValue
      );
      setValue(
        "post",
        selected.length === departmentValue.length ? [] : departmentValue
      );
      const res = await trigger("post");
      return;
    }
    setSelected(value);
    const allSelected =
      departmentValue.length > 0 && value.length === departmentValue.length;
    setAllSelected(allSelected);
    const res = await trigger("post");
  };
  const renderSpecValue = () => {
    let data = "";
    let first = selectedSpec[0];
    let length = selectedSpec.length - 1;
    if (selectedSpec.length === specialitiesValue.length) {
      data = "All Specialities";
    } else if (selectedSpec.length == 1) {
      data = selectedSpec[selectedSpec.length - 1];
    } else if (selectedSpec.length >= 2) {
      data = first + " +" + length + " more";
    }
    return data;
  };
  const handleSpecial = async (event, child) => {
    const value = event.target.value;
    setValue("special", event.target.value);
    if (value[value.length - 1] === "all") {
      setAllSelectedSpec(
        selectedSpec.length === specialitiesValue.length ? false : true
      );
      setValue(
        "special",
        selectedSpec.length === specialitiesValue.length
          ? []
          : specialitiesValue
      );
      setSelectedSpec(
        selectedSpec.length === specialitiesValue.length
          ? []
          : specialitiesValue
      );
      const res = await trigger("special");
      return;
    }
    setSelectedSpec(value);
    const allSelected =
      specialitiesValue.length > 0 && value.length === specialitiesValue.length;
    setAllSelectedSpec(allSelected);
    const res = await trigger("special");
  };

  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const changeChannels = async (data) => {
    const channelList = [];

    channels.map((channel) => {
      if (selectedChannel === channel.channel_id) {
        setValue("share", channel);
        setSelectedShare(channel);
        getSpecialities(channel.channel_id, data);
      }

      if (channel.feed_provider_type === "Network") {
        if (selectedChannel === 0) {
          setValue("share", channel);
          setSelectedShare(channel);
          getSpecialities(channel.channel_id, data);
        }
        channelList.push(channel);
      }

      if (channel.is_admin) channelList.push(channel);
    });
    setChannelsData([...channelList]);
  };
  const handleShare = (event) => {
    const data = event.target.value;
    setValue("share", event.target.value);
    setSelectedShare(data);
    getSpecialities(data.channel_id, 0);
    if (data.feed_provider_type != "Network") {
      setShowPost(true);
    } else {
      setShowPost(false);
    }
  };

  const getSpecialities = async (channel_id, type) => {
    const payload = {
      doc_id: doc_id,
      channel_id: channel_id,
    };

    const res = await fetch(baseUrl + "v2/getDepartmentsAndSpecialties", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
      return;
    });

    const data = await res.json();
    if (data.status === "success") {
      data.data.specialities.sort(function (a, b) {
        return a.speciality_name.localeCompare(b.speciality_name);
      });
      data.data.departments.sort(function (a, b) {
        return a.department_name.localeCompare(b.department_name);
      });
      setSpecialities(data.data.specialities);
      setDepartments(data.data.departments);
      if (data.data.departments.length !== 0) {
        const departmentIds = [];
        const departmentNames = [];
        data.data.departments.map((dep) => {
          departmentIds.push(dep.department_id);
          departmentNames.push(dep.department_name);
        });
        setDepartmentKey(departmentIds);
        setDepartmentValue(departmentNames);
        setValue("post", departmentNames);
        setSelected(departmentNames);
        setAllSelected(true);
        const res = await trigger("post");
      } else {
        setValue("post", "departmentNames");
      }
      if (data.data.specialities.length !== 0) {
        const specialityIds = [];
        const specialityNames = [];
        const caseSpecialityNames = [];
        data.data.specialities.map((spec) => {
          specialityIds.push(spec.speciality_id);
          specialityNames.push(spec.speciality_name);
        });
        setSpecialitiesKey(specialityIds);
        setSpecialitiesValue(specialityNames);
        setValue("special", specialityNames);
        //if (modalTitle == "Post A Case") {
        if (type == 1) {
          if (data.data.related_specialties.length != 0) {
            let speciality_ids = data.data.specialities.filter((itemA) => {
              return data.data.related_specialties.find((itemB) => {
                return itemA.speciality_id === itemB;
              });
            });
            console.log(speciality_ids);
            speciality_ids.map((speciality) => {
              caseSpecialityNames.push(speciality.speciality_name);
            });
          }
          setSelectedSpec(caseSpecialityNames);
          setAllSelectedSpec(false);
        } else if (type == 2 || type == 3) {
          setSelectedSpec(specialityNames);
          setAllSelectedSpec(true);
        }
      }
    } else toast.error(data.errorMsg);
  };
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const videoObserver = useRef(null);
  const audioObserver = useRef(null);
  const videoLoader = useCallback((node) => {
    if (videoObserver.current) videoObserver.current.disconnect();
    videoObserver.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          videoRef.current.pause();
        }
      });
    });
    if (node) videoObserver.current.observe(node);
  }, []);

  const audioLoader = useCallback((node) => {
    if (audioObserver.current) audioObserver.current.disconnect();
    audioObserver.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          audioRef.current.audioEl.current.pause();
        }
      });
    });
    if (node) audioObserver.current.observe(node);
  }, []);

  const showPreview = (attach) => {
    setPreviewAttachment("");
    setPreviewAttachment(attach);
    setPreviewModal(true);
    console.dir(attach);
  };
  const showImagePreview = () => {
    // if (type === "image") {
    //   setIsFileUploaded(true);
    //   setImgPreviewUrl(URL.createObjectURL(fileInput.current.files[0]));
    // } else {
    //   toast.error("Only images are allowed");
    // }
    let file = fileInput.current.files[0];
    let newFileName = fileInput.current.files[0].name;
    let ext = getExtension(newFileName);
    let type = fileInput.current.files[0].type;
    if (type.includes("image")) type = "image";
    else if (type.includes("video")) type = "video";
    else if (type.includes("audio")) type = "audio";
    else if (type.includes("pdf")) type = "pdf";
    setAttachError(false);
    setSizeError(false);
    if (
      type != "image" &&
      type != "pdf" &&
      type != "video" &&
      type != "audio"
    ) {
      setAttachError(true);
      fileInput.current.value = "";
      return false;
    }
    const fsize = fileInput.current.files[0].size;
    const fileSize = Math.round(fsize / 1024);
    if (fileSize >= 51200) {
      setSizeError(true);
      fileInput.current.value = "";
      return false;
    }
    const attachmentDeatails = [];
    // const ReactS3Client = new S3(config);
    // const data = await ReactS3Client.uploadFile(file, newFileName);
    const attachment = {
      file: file,
      attachment_type: type,
      attachment_extension: ext,
      attachment_original_url: URL.createObjectURL(fileInput.current.files[0]),
    };
    console.dir(attachment);
    attachmentDeatails.push(attachment);
    setPostAttachments((prev) => {
      return [...prev, ...attachmentDeatails];
    });
    fileInput.current.value = "";
  };
  const editFile = async (e, index) => {
    e.preventDefault();

    let file = e.target.files[0];
    let newFileName = e.target.files[0].name;
    let ext = getExtension(newFileName);
    let type = e.target.files[0].type;
    if (type.includes("image")) type = "image";
    else if (type.includes("video")) type = "video";
    else if (type.includes("audio")) type = "audio";
    else if (type.includes("pdf")) type = "pdf";
    setAttachError(false);
    setSizeError(false);
    if (
      type != "image" &&
      type != "pdf" &&
      type != "video" &&
      type != "audio"
    ) {
      setAttachError(true);
      e.target.value = "";
      return false;
    }
    const fsize = e.target.files[0].size;
    const fileSize = Math.round(fsize / 1024);
    if (fileSize >= 51200) {
      setSizeError(true);
      e.target.value = "";
      return false;
    }
    const attachmentDeatails = [];
    // const ReactS3Client = new S3(config);
    // const data = await ReactS3Client.uploadFile(file, newFileName);
    const attachment = {
      file: file,
      attachment_type: type,
      attachment_extension: ext,
      attachment_original_url: URL.createObjectURL(e.target.files[0]),
    };
    console.dir(attachment);
    attachmentDeatails.push(attachment);
    let newArr = [...postAttachments]; // copying the old datas array
    newArr[index] = attachment; // replace e.target.value with whatever you want to change it to
    setPostAttachments(newArr);
    e.target.value = "";
  };
  const removeFile = async (e, index) => {
    e.preventDefault();
    setPostAttachments(postAttachments.filter((item, i) => i !== index));
  };

  const getExtension = (fileName) => {
    const lastDot = fileName.lastIndexOf(".");
    const ext = fileName.substring(lastDot + 1);
    return ext;
  };

  const refreshFeedsData = () => {
    setOpenPostView(false);
    if (location.pathname === "/dashboard" || location.pathname === "/")
      loadDashBoardData();
    else loadDashBoardData();
  };
  const postSubmit = async (post) => {
    console.log(post);
    setloaderSpinner(true);
    const finalAttachments = [];
    const finalSpecialities = [];
    const finalDepartments = [];
    const ReactS3Client = new S3(config);
    if (postAttachments.length != 0) {
      await Promise.all(
        postAttachments.map(async (attach, i) => {
          let newFileName = i + attach.file.name;
          await ReactS3Client.uploadFile(attach.file, newFileName)
            .then((data) => {
              console.log(data);
              if (data.status === 204) {
                const attachment = {
                  attachment_type: attach.attachment_type,
                  attachment_extension: attach.attachment_extension,
                  attachment_original_url: data.location,
                };
                console.log(attachment);
                finalAttachments.push(attachment);
              }
            })
            .catch((err) => {
              setloaderSpinner(false);
              console.log("Error", err);
              if (navigator.onLine) {
                // toast(JSON.stringify(error));
              } else {
                toast.error("You are offline now");
                return false;
              }
            });
        })
      ).catch((error) => {
        toast(error);
      });
    }
    console.log(postAttachments);
    console.log(finalAttachments);
    if (postAttachments.length != finalAttachments.length) {
      setloaderSpinner(false);
      if (navigator.onLine) {
        toast.error(
          "we are unable to save data right now. Please try after sometime"
        );
      } else {
        //toast.error("You are offline now");
      }
      return false;
    }
    if (selectedSpec.length != 0) {
      let speciality_ids = specialities.filter((itemA) => {
        return selectedSpec.find((itemB) => {
          return itemA.speciality_name === itemB;
        });
      });
      console.log(speciality_ids);
      speciality_ids.map((speciality) => {
        finalSpecialities.push(speciality.speciality_id);
      });
    }
    if (selected.length != 0) {
      let department_ids = departments.filter((itemA) => {
        return selected.find((itemB) => {
          return itemA.department_name === itemB;
        });
      });
      department_ids.map((department) => {
        finalDepartments.push(department.department_id);
      });
      console.log(department_ids);
    }
    let feed_type = "";
    if (modalTitle == "Post A Case") {
      feed_type = "case";
    } else if (modalTitle == "Share News/Announcements") {
      feed_type = "post";
    } else if (modalTitle == "Ask A Question") {
      feed_type = "post";
    }
    console.log(quilContent);
    if (quilContent === "<p><br></p>") {
      setQuilContent("");
    }

    // let quilContentDecode = decode(quilContent);
    // let decoded = quilContentDecode.replace(/&/g, '').replace(/%/g, '');
    let payload = {
      doc_id: doc_id,
      department_ids: finalDepartments.length == 0 ? [] : finalDepartments,
      feed_provider_type: selectedShare.feed_provider_type,
      feed_type: feed_type,
      title: modalTitle == "Ask A Question" ? post.question : post.title,
      channel_ids: [selectedShare.channel_id],
      attachment_details: finalAttachments,
      speciality_ids: finalSpecialities.length == 0 ? [] : finalSpecialities,
      isRetry: false,
      feed_desc: modalTitle == "Ask A Question" ? "" : quilContent,
    };
    if (modalTitle == "Share News/Announcements") {
      payload.feed_sub_type = "update";
    } else if (modalTitle == "Ask A Question") {
      payload.feed_sub_type = "question";
    }
    console.log(payload);
    payload = encodeURIComponent(JSON.stringify(payload));
    const res = await fetch(baseUrl + "v5/community/makePost", {
      method: "POST",
      headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      setloaderSpinner(false);
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast.error("You are offline now");
      return false;
    });
    if (res.ok) {
      const data = await res.json();
      if (data.status === "success") {
        setFeedData(data.data);
        openFeed();
        setloaderSpinner(false);
        // showSuccessView();
        refreshFeedsData();
        reset();
      } else {
        setloaderSpinner(false);
        toast.error(data.errorMsg);
      }
    } else {
      console.error("Problem: " + res);
    }
  };

  const checkUserVerification = (user_verification_info) => {
    if (!user_verification_info.isCommunityVerified) {
      if (user_verification_info.is_user_verified === 1) {
        handleUploadMci();
        return true;
      }

      if (user_verification_info.is_user_verified === 2) {
        handleVerificationPending();
        return true;
      }
    }
    return false;
  };

  const newPostRedirect = (type) => {
    if (!userInfo.verificationInfo.isCommunityVerified) {
      if (userInfo.verificationInfo.is_user_verified == 1) {
        handleUploadMci();
        return;
      }
      if (userInfo.verificationInfo.is_user_verified == 2) {
        handleVerificationPending();
        return;
      }
    }

    if (windowDimensions.width < 446)
      history.push({
        pathname: `/post/${type}`,
        state: {
          background: location,
          selectedChannel,
          profile_pic: userInfo?.profile_pic_small_url,
          user_verification_info: userInfo?.verificationInfo,
        },
      });
    else handlePostModal(type);
  };

  const {
    addCommentService,
    updateCommentService,
    deleteCommentService,
    updateLikeCountService,
    updateViewCountService,
    submitSurveyService,
    registerWebinarService,
  } = dashboardServices();

  const addComment = async (feed_id, channel_id, comment, attachments = []) => {
    if (checkUserVerification(userInfo?.verificationInfo)) return;

    if (attachments.length === 0 && comment === "") {
      toast.error("Cannot post empty comment");
      return;
    }

    const data = await addCommentService(
      feed_id,
      channel_id,
      comment,
      attachments
    );

    if (data.status === "success") {
      setFeedData((prev) => {
        const newFeed = { ...prev };
        newFeed.feed_info.socialInteraction = data.data.socialInteraction;
        return newFeed;
      });
    }
  };

  const updateComment = async (
    feed_id,
    channel_id,
    social_interaction_id,
    comment,
    attachments = []
  ) => {
    if (checkUserVerification(userInfo?.verificationInfo)) return;

    if (attachments.length === 0 && comment === "") {
      toast.error("Cannot post empty comment");
      return;
    }

    const data = await updateCommentService(
      feed_id,
      channel_id,
      social_interaction_id,
      comment,
      attachments
    );

    if (data.status === "success") {
      setFeedData((prev) => {
        const newFeed = { ...prev };
        newFeed.feed_info.socialInteraction = data.data.socialInteraction;
        return newFeed;
      });
    }
  };

  const deleteComment = async (feed_id, channel_id, social_interaction_id) => {
    const data = await deleteCommentService(
      feed_id,
      channel_id,
      social_interaction_id
    );
    if (data.status === "success") {
      setFeedData((prev) => {
        const newFeed = { ...prev };
        newFeed.feed_info.socialInteraction = data.data.socialInteraction;
        return newFeed;
      });
    }
  };

  const updateLikeCount = async (feed_id, channel_id, isLike) => {
    if (checkUserVerification(userInfo?.verificationInfo)) return;

    const data = await updateLikeCountService(feed_id, channel_id, isLike);

    if (data.status === "success") {
      setFeedData((prev) => {
        const newFeed = { ...prev };
        newFeed.feed_info.socialInteraction = data.data.socialInteraction;
        return newFeed;
      });
    }
  };

  const updateViewCount = async (channel_id, feed_id) => {
    const data = await updateViewCountService(channel_id, feed_id);

    if (data.status === "success") {
      if (data.data.socialInteraction)
        setFeedData((prev) => {
          const newFeed = { ...prev };
          newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        });
      else
        setFeedData((prev) => {
          const newFeed = { ...prev };
          newFeed.feed_info.socialInteraction.viewCount += 1;
          return newFeed;
        });
    }
  };

  const submitSurvey = async (feed_id, channel_id, question_id, option_id) => {
    if (option_id.length === 0) {
      toast("Please select an option");
      return;
    }

    const data = await submitSurveyService(
      feed_id,
      channel_id,
      question_id,
      option_id
    );

    if (data.status === "success") {
      if (data.data.socialInteraction)
        setFeedData((prev) => {
          const newFeed = { ...prev };
          newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        });
      else
        setFeedData((prevFeed) => {
          const newFeed = { ...prevFeed };
          newFeed.feed_info.feed_survey.questions =
            newFeed.feed_info.feed_survey.questions.map((question, index) => ({
              ...question,
              options: data.data.feed_survey.questions[index].options,
            }));
          newFeed.feed_info.feed_survey.is_open = data.data.feed_survey.is_open;
          newFeed.feed_info.feed_survey.is_participated =
            data.data.feed_survey.is_participated;

          return newFeed;
        });
    }
  };

  const registerWebinar = async (e, feed_id, verify_user, title) => {
    e.preventDefault();
    if (verify_user)
      if (checkUserVerification(userInfo?.verificationInfo)) return;

    const data = await registerWebinarService(feed_id);

    if (data.status === "success") {
      toast.success(`Thank you for registering for ${title}`);
      setFeedData((prevFeed) => {
        const newFeed = { ...prevFeed };
        newFeed.feed_info.event_details.is_user_registered = true;
        return newFeed;
      });
      return true;
    } else if (data.status === "error") toast.error("Failed to registered");

    return false;
  };

  return (
    <>
      {type === "ribbon" && (
        <div className="post-card">
          <div className="post-header">
            {/* <img
              className="post-header-image"
              alt="profile pic"
              src={
                userInfo?.profile_pic_original_url &&
                userInfo?.profile_pic_original_url !== ""
                  ? userInfo?.profile_pic_original_url
                  : profile
              }
            /> */}
            <div className="post-info-container">
              <div className="post-poster-container">
                <div className="post-poster">Create New Post</div>
              </div>
            </div>

            {/* <div style={{ width: "48%", marginTop: "12px", fontSize: "11px" }}>
              Share With
            </div>
            <div className="post-box">
              <InputBase
                style={{
                  color: "rgb(30, 138, 121)",
                  font: "bold 10px / 20px roboto",
                  paddingLeft: "11px",
                }}
                type="text"
                variant="outlined"
                fullWidth
                size="small"
                value="Everyone"
                disabled
              />
              <div style={{ textAlign: "right", marginTop: "-25px" }}>
                <IconButton aria-label="search" disabled>
                  <img
                    src={arrowImage}
                    style={{
                      cursor: "pointer",
                      height: "10px",
                    }}
                    alt="arrow logo"
                  />
                </IconButton>
              </div>
            </div> */}
          </div>
          <div className="post-box-head">
            <div className="post-box-item" onClick={() => newPostRedirect(3)}>
              {/* <div
                style={{
                  display: "flex ",
                  marginTop: "5px",
                  marginLeft: "5px",
                }}
              > */}
              <img
                src={circleImage}
                style={{
                  cursor: "pointer",
                  height: "24px",
                }}
                alt="circle logo"
              />
              <div className="post-box-item-text">
                <div className="post-box-item-label">Ask A Question</div>
                <p>
                  Get answers/tips from your peers from the medical community
                </p>
              </div>
              {/* </div> */}
            </div>
            <div className="vertical-divider" />
            <div
              className="post-box-item"
              // style={{
              //   backgroundColor: "rgb(221 253 247)",
              //   borderRadius: "5px",
              //   marginRight: "5px",
              //   cursor: "pointer",
              // }}
              onClick={() => newPostRedirect(1)}
            >
              {/* <div
                style={{
                  display: "flex ",
                  marginTop: "5px",
                  marginLeft: "5px",
                }}
              > */}
              <img
                src={medicalImage}
                style={{
                  cursor: "pointer",
                  height: "28px",
                }}
                alt="medical logo"
              />
              {/* </div> */}
              <div className="post-box-item-text">
                <div className="post-box-item-label">Post A Case</div>
                <p>Share and discuss interesting medical cases</p>
              </div>
            </div>
            <div className="vertical-divider" />

            <div
              className="post-box-item"
              // style={{
              //   backgroundColor: "white",
              //   borderRadius: "5px",
              //   marginRight: "5px",
              //   marginLeft: "5px",
              //   cursor: "pointer",
              // }}
              onClick={() => newPostRedirect(2)}
            >
              {/* <div
                style={{
                  display: "flex ",
                  marginTop: "5px",
                  marginLeft: "0px",
                }}
              > */}
              <img
                src={newspaperImage}
                style={{
                  cursor: "pointer",
                  height: "23px",
                }}
                alt="newspaper logo"
              />
              {/* </div> */}
              <div className="post-box-item-text">
                <div className="post-box-item-label">
                  Share News/Announcements
                </div>
                <p>Share latest/useful developments in medicine or your work</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {type === "button" && home === "false" && (
        <>
          <div
            className={`create-post-button  ${
              buttonDropdown && "create-post-button-click"
            }`}
            tabIndex="1"
            onClick={(e) => {
              setButtonDropdown((prev) => !prev);
            }}
            // onBlur={() => setButtonDropdown(false)}
          >
            <BsPlusCircle className="create-post-button-icon" />
            <span className="create-post-button-text">Create New Post</span>
          </div>

          {buttonDropdown && (
            <div className="post-button-dropdown-container">
              <div
                className="post-button-dropdown-item"
                onClick={() => newPostRedirect(3)}
              >
                <div className="post-button-dropdown-header">
                  <img
                    src={circleImage}
                    style={{
                      cursor: "pointer",
                      height: "20px",
                    }}
                    alt="medical logo"
                  />
                  <span className="post-button-dropdown-header-text">
                    Ask A Question
                  </span>
                </div>
                <div className="post-button-dropdown-text">
                  Get answers/tips from your peers from the medical community
                </div>
              </div>
              <hr />
              <div
                className="post-button-dropdown-item"
                // onClick={() => handlePostModal(1)}
                onClick={() => newPostRedirect(1)}
              >
                <div className="post-button-dropdown-header">
                  <img
                    src={medicalImage}
                    style={{
                      cursor: "pointer",
                      height: "20px",
                    }}
                    alt="medical logo"
                  />
                  <span className="post-button-dropdown-header-text">
                    Post A Case
                  </span>
                </div>
                <div className="post-button-dropdown-text">
                  Share and discuss interesting medical cases
                </div>
              </div>

              <hr />
              <div
                className="post-button-dropdown-item"
                onClick={() => newPostRedirect(2)}
              >
                <div className="post-button-dropdown-header">
                  <img
                    src={newspaperImage}
                    style={{
                      cursor: "pointer",
                      height: "20px",
                    }}
                    alt="medical logo"
                  />
                  <span className="post-button-dropdown-header-text">
                    Share News/Announcements
                  </span>
                </div>
                <div className="post-button-dropdown-text">
                  Share latest/useful developments in medicine or your work
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {home === "true" && (
        <>
          <div
            className="create-post-head-button create-post-button-click"
            onClick={(e) => {
              setHeadDropdown((prev) => !prev);
            }}
          >
            <div className="create-post-m">
              {" "}
              <BsPlusCircle className="create-post-head-button-icon" />
            </div>
            <div className="create-post-head-button-text">Create New Post</div>
          </div>

          {headDropdown && (
            <div className="post-button-dropdown-container">
              <div
                className="post-button-dropdown-item"
                onClick={() => handlePostModal(3)}
              >
                <div className="post-button-dropdown-header">
                  <img
                    src={circleImage}
                    style={{
                      cursor: "pointer",
                      height: "20px",
                    }}
                    alt="medical logo"
                  />
                  <span className="post-button-dropdown-header-text">
                    Ask A Question
                  </span>
                </div>
                <div className="post-button-dropdown-text">
                  Get answers/tips from your peers from the medical community
                </div>
              </div>
              <hr />
              <div
                className="post-button-dropdown-item"
                onClick={() => handlePostModal(1)}
              >
                <div className="post-button-dropdown-header">
                  <img
                    src={medicalImage}
                    style={{
                      cursor: "pointer",
                      height: "20px",
                    }}
                    alt="medical logo"
                  />
                  <span className="post-button-dropdown-header-text">
                    Post A Case
                  </span>
                </div>
                <div className="post-button-dropdown-text">
                  Share and discuss interesting medical cases
                </div>
              </div>
              <hr />
              <div
                className="post-button-dropdown-item"
                onClick={() => handlePostModal(2)}
              >
                <div className="post-button-dropdown-header">
                  <img
                    src={newspaperImage}
                    style={{
                      cursor: "pointer",
                      height: "20px",
                    }}
                    alt="medical logo"
                  />
                  <span className="post-button-dropdown-header-text">
                    Share News/Announcements
                  </span>
                </div>
                <div className="post-button-dropdown-text">
                  Share latest/useful developments in medicine or your work
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <Modal
        open={openPostView}
        onClose={handlePostModalClose}
        // closeIcon={closeIcon}
        showCloseIcon={false}
        classNames={{ modal: "postModel" }}
        styles={{ modal: { padding: 0 } }}
        closeOnOverlayClick={false}
      >
        <CreatePostModal
          modalTitle={modalTitle}
          specialities={specialities}
          selectedSpec={selectedSpec}
          handleSpecial={handleSpecial}
          renderSpecValue={renderSpecValue}
          isAllSelectedSpec={isAllSelectedSpec}
          specialitiesValue={specialitiesValue}
          channels={channels}
          channelsData={channelsData}
          selectedChannel={selectedChannel}
          handleShare={handleShare}
          selectedShare={selectedShare}
          handlePostModalClose={handlePostModalClose}
          departments={departments}
          departmentValue={departmentValue}
          selected={selected}
          isAllSelected={isAllSelected}
          handleChange={handleChange}
          renderPostValue={renderPostValue}
          fileInput={fileInput}
          showImagePreview={showImagePreview}
          postAttachments={postAttachments}
          editFile={editFile}
          showPreview={showPreview}
          removeFile={removeFile}
          attachError={attachError}
          sizeError={sizeError}
          postSubmit={postSubmit}
          handleSubmit={handleSubmit}
          control={control}
          errors={errors}
          quilChange={quilChange}
          loaderSpinner={loaderSpinner}
        />
      </Modal>

      <Modal
        open={openPostSuccessView}
        blockScroll={true}
        closeIcon={closeIconHide}
        classNames={{ modal: "postSuccess" }}
      >
        <div className="container-fluid">
          <div
            className="row"
            style={{ backgroundColor: "#1e8a79", height: "281px" }}
          >
            <div
              className="col-sm-12 "
              style={{ marginTop: "30px", marginBottom: "20px" }}
            >
              <img
                src={doneImage}
                style={{
                  cursor: "pointer",
                  height: "40px",
                }}
                alt="done"
              />
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "white",
                  font: "18px/20px roboto",
                  marginBottom: "20px",
                }}
              >
                Post has been created successfully.
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "white",
                  marginBottom: "30px",
                  font: "15px/20px roboto",
                }}
              >
                {" "}
                <div>Thank you for your contribution to the</div>
                <p>medical community</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              <button
                onClick={(e) => hideSuccessView(e)}
                type="button"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "5px",
                  margin: "auto",
                  width: "25%",
                }}
                className="submit-btn"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={previewModal}
        onClose={closePreviewModal}
        classNames={{ modal: "attach-model" }}
        closeIcon={closeIcon}
      >
        {previewAttachment && previewAttachment.attachment_type === "image" && (
          <img
            src={previewAttachment.attachment_original_url}
            style={{ height: "100%", width: "100%" }}
          ></img>
        )}
        {previewAttachment && previewAttachment.attachment_type === "video" && (
          <span>
            <video
              preload="metadata"
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "100% !important",
              }}
              controls="controls"
              ref={videoRef}
            >
              <source
                src={`${previewAttachment.attachment_original_url}#t=0.1`}
                type="video/mp4"
              />
            </video>
            <span ref={videoLoader}></span>
          </span>
        )}
        {previewAttachment && previewAttachment.attachment_type === "audio" && (
          <span>
            <ReactAudioPlayer
              style={{ border: "1px solid #f1f1f1" }}
              controls
              src={previewAttachment.attachment_original_url}
              // style={{
              //   maxHeight: "100% !important",
              // }}
              ref={(element) => {
                audioRef.current = element;
              }}
            />
            <span ref={audioLoader}></span>
          </span>
        )}
        {previewAttachment && previewAttachment.attachment_type === "pdf" && (
          <object
            data={previewAttachment.attachment_original_url}
            type="application/pdf"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <iframe
              style={{
                width: "100%",
                height: "100%",
              }}
              src={previewAttachment.attachment_original_url}
            />
          </object>
        )}
      </Modal>
      <Modal
        open={viewFeed}
        blockScroll={true}
        style={customStyles}
        onClose={closeFeed}
        closeIcon={closeIcon}
        classNames={{ modal: "feedModel" }}
        ref={fullViewFeedRef}
      >
        <FullViewFeed
          profile_pic={userInfo?.profile_pic_small_url}
          close={closeFeed}
          feed={feedData}
          addComment={addComment}
          updateLikeCount={updateLikeCount}
          submitSurvey={submitSurvey}
          updateViewCount={updateViewCount}
          deleteComment={deleteComment}
          updateComment={updateComment}
          openLikesModal={openLikesModal}
          registerWebinar={registerWebinar}
          user_verification_info={userInfo?.verificationInfo}
        ></FullViewFeed>
      </Modal>

      <Modal
        open={likesModal}
        onClose={closeLikesModal}
        classNames={{ modal: "feedsLikeModel" }}
        closeIcon={closeIcon}
      >
        <Likes feed={feedData}></Likes>
      </Modal>
    </>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default Createpost;
