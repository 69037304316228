import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getAuthToken, getDocId, getHeaders } from "../utils/utils";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";

import en from "react-phone-number-input/locale/en.json";
import { Controller, useForm } from "react-hook-form";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import { Modal } from "react-responsive-modal";
import { Mail, SystemUpdateTwoTone } from "@material-ui/icons";
import successIcon from "../assets/profile_icons/Group 2142.svg";
import mail from "../assets/profile_icons/Icon zocial-email.svg";
import call from "../assets/profile_icons/Icon awesome-phone-square-alt.svg";
import toast from "react-hot-toast";

import CountrySelect from "../authentication/RegisterComponents/CountrySelect";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      color: "#8B8B8B",
      padding: 0,
      fontSize: "1.5rem",
      fontFamily: "Roboto",
      fontWeight: 500,
      lineHeight: 1,
    },
    "& .MuiTypography-body1": {
      fontWeight: 400,
      lineHeight: 1.5,
      font: "normal normal normal 20px Roboto",
      letterSpacing: "0px",
      color: "#2B2B2B",
    },
    "& .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded":
      {
        width: "500px",
      },
    "& .MuiDialogActions-root": {
      display: "flex",
      padding: "8px",
      alignItems: "center",
      justifyContent: "flexEnd",
      backgroundColor: "#2a8f80",
    },

    "& .MuiFilledInput-input": {
      backgroundColor: "white",
      border: "1px solid #6A6A6A",
      borderRadius: "4px",
      fontSize: "14px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Help = () => {
  const [value, setValue] = useState("1");
  const classes = useStyles();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const auth_token = getAuthToken();

  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);
  const [country, setCountry] = useState("IN");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const headers = getHeaders();

  const [state, setstate] = useState({
    fullName_value: "",
    mobile_number_value: "",
    spinner_value: "",
  });
  // const handleChangeValue = (e) => {
  //   const { id, value } = e.target;
  //   setstate((prevState) => ({
  //     ...prevState,
  //     [id]: value,
  //   }));
  // };
  // const handleChangeValue1 = (e) => {
  //   const { id, value } = e.target;
  //   if (e.target.value.match("^[a-zA-Z ]*$") != null) {
  //     window.alert("chandu");
  //   }
  //   setstate((prevState) => ({
  //     ...prevState,
  //     [id]: value,
  //   }));
  // };

  const validatePhoneInput = (phone) => {
    let isValid = true;
    if (!phone) {
      isValid = false;
      // error = "This field is required";
    }
    if (!isPossiblePhoneNumber(phone)) {
      isValid = false;
      // error = "Please enter valid number";
    }
    if (!isValidPhoneNumber(phone)) {
      isValid = false;
      // error = "Please enter valid number";
    }

    return isValid;
  };

  const handleClick = async (formData) => {
    if (value == "1") {
      toast.error("Please select preferred time ");
      return;
    }
    const request_payload = {
      full_name: formData.name,
      cnt_num: formData.phone.substring(1),
      preferred_time: value,
    };
    const res = await fetch(baseUrl + "/contactSupport", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(request_payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();
    if (data.status === "success") {
      reset({});
      openModal();
      setValue("1");
    }
  };
  return (
    <>
      <div className="col-lg-9 col-md-3">
        <div className="profile-help">
          <div className="card profile-help-card">
            <div className="help-container">
              <h5 className="help-title">Get in touch </h5>
              <div style={{ marginTop: "10px" }}>
                <span>
                  <img
                    src={mail}
                    style={{
                      marginLeft: "30px",
                      marginRight: "10px",
                      width: "25px",
                    }}
                  />
                  <span className="whitecoats-info">
                    Support@whitecoats.com
                  </span>
                </span>
              </div>
              <br></br>
              <span style={{ marginTop: "10px" }}>
                <img
                  src={call}
                  style={{
                    marginLeft: "30px",
                    marginRight: "10px",
                    width: "25px",
                  }}
                />
                <span className="whitecoats-info">+917093993112</span>
              </span>
            </div>
            <hr></hr>
            <div className="help-container">
              <p className="help-title">or request a call back </p>
              <div className="">
                <form
                  className={classes.root}
                  autoComplete="off"
                  onSubmit={handleSubmit(handleClick)}
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <div>
                        <input
                          type="text"
                          className="help-form"
                          id="fullName_value"
                          {...register("name", {
                            required: true,
                            minLength: 1,
                            maxLength: 30,
                            pattern: /^\s*[A-za-z]+\s*$/,
                          })}
                          placeholder="Your full name"
                        />
                      </div>
                      <div className="row error-title">
                        {errors.name?.type === "pattern" && (
                          <span className="error-title-text">
                            Please don't use numbers, special characters or
                            spaces
                          </span>
                        )}
                      </div>
                      <div className="row error-title">
                        {errors.name?.type === "required" && (
                          <span className="error-title-text">
                            {" "}
                            Please enter your first name
                          </span>
                        )}
                      </div>
                      <div className="row error-title">
                        {errors.name?.type === "maxLength" && (
                          <span className="error-title-text">
                            max length is 30
                          </span>
                        )}
                      </div>
                      <div className="row error-title">
                        {errors.name?.type === "minLength" && (
                          <span className="error-title-text">
                            min length is 1
                          </span>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-12" style={{ paddingLeft: "0px" }}>
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                marginLeft: "15px",
                                width: "150px",
                                marginTop: "15px",
                                marginRight: "10px",
                              }}
                            >
                              <CountrySelect
                                value={country}
                                isRegister={false}
                                onChange={setCountry}
                                labels={en}
                              />
                            </div>
                            <div>
                              <Controller
                                name="phone"
                                control={control}
                                rules={{
                                  required: true,
                                  validate: (value) =>
                                    validatePhoneInput(value),
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <PhoneInput
                                    country={country}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Contact Number *"
                                    className="help-mobile"
                                    // style={{
                                    //   marginTop: "1.1em",
                                    //   paddingLeft: "5px",
                                    //   background: "inherit",
                                    //   borderRadius: "6px",
                                    //   width: "343px",
                                    //   height: "50px",
                                    //   border: "1px solid #8a8a8a",
                                    // }}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row error-title">
                        {errors.phone && (
                          <span className="error-title-text">
                            Please enter a valid phone number
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-block d-lg-none">
                    <select
                      name="time"
                      className="mobile-help-form"
                      value={value}
                      onChange={handleChange}
                      style={{ marginTop: "10px" }}
                    >
                      <option>Select preferred time</option>
                      <option style={{ width: "200px" }} value="8AM to 10PM">
                        8AM to 10PM
                      </option>
                      <option value="10AM to 12PM">10AM to 12PM</option>
                      <option value="12PM to 2PM">12PM to 2PM</option>
                      <option value="2PM to 4PM">2PM to 4PM</option>
                      <option value="4PM to 8PM">4PM to 8PM</option>
                    </select>
                  </div>
                  <div className="d-none d-sm-block">
                    <FormControl component="fieldset" id="spinner_value">
                      <div style={{ marginTop: "10px" }}>
                        <h5 className="help-title">Select preferred time </h5>
                      </div>
                      <RadioGroup
                        aria-label="select"
                        id="spinner_value"
                        value={value}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="8AM to 10PM"
                          control={<Radio />}
                          label="8 AM to 10 AM"
                        />
                        <FormControlLabel
                          value="10AM to 12PM"
                          control={<Radio />}
                          label="10 AM to 12 PM"
                        />
                        <FormControlLabel
                          value="12PM to 2PM"
                          control={<Radio />}
                          label="12 PM to 2 PM"
                        />
                        <FormControlLabel
                          value="2PM to 4PM"
                          control={<Radio />}
                          label="2 PM to 4 PM"
                        />
                        <FormControlLabel
                          value="4PM to 8PM"
                          control={<Radio />}
                          label="4 PM to 8 PM"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <button
                    type="submit"
                    style={{
                      backgroundColor: "#0FB59B",
                      marginBottom: "20px",
                      borderRadius: "30px",
                      width: "180px",
                    }}
                    className="submit-btn"
                  >
                    Call me Back
                  </button>
                </form>
              </div>

              {/* <div className="d-block d-lg-none">
                <form
                  className={classes.root}
                  autoComplete="off"
                  onSubmit={handleSubmit(handleClick)}
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <div>
                        <input
                          type="text"
                          className="help-form"
                          id="fullName_value"
                          {...register("name", {
                            required: true,
                            minLength: 1,
                            maxLength: 30,
                            pattern: /^\s*[A-za-z]+\s*$/,
                          })}
                          placeholder="Your full name"
                        />
                      </div>
                      <div className="row error-title">
                        {errors.name?.type === "pattern" && (
                          <span className="error-title-text">
                            Please don't use numbers, special characters or
                            spaces
                          </span>
                        )}
                      </div>
                      <div className="row error-title">
                        {errors.name?.type === "required" && (
                          <span className="error-title-text">
                            {" "}
                            Please enter your first name
                          </span>
                        )}
                      </div>
                      <div className="row error-title">
                        {errors.name?.type === "maxLength" && (
                          <span className="error-title-text">
                            max length is 30
                          </span>
                        )}
                      </div>
                      <div className="row error-title">
                        {errors.name?.type === "minLength" && (
                          <span className="error-title-text">
                            min length is 1
                          </span>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-12" style={{ paddingLeft: "0px" }}>
                          <div style={{ display: "flex" }}>
                            <p
                              style={{
                                marginLeft: "15px",
                                width: "140px",
                                marginTop: "15px",
                                marginRight: "10px",
                              }}
                            >
                              <CountrySelect
                                value={country}
                                isRegister={false}
                                onChange={setCountry}
                                labels={en}
                              />
                            </p>
                            <p>
                              <Controller
                                name="phone"
                                control={control}
                                rules={{
                                  required: true,
                                  validate: (value) =>
                                    validatePhoneInput(value),
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <PhoneInput
                                    country={country}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Contact Number *"
                                    style={{
                                      marginTop: "1.1em",
                                      paddingLeft: "5px",
                                      background: "inherit",
                                      borderRadius: "6px",
                                      width: "180px",
                                      height: "50px",
                                      border: "1px solid #8a8a8a",
                                    }}
                                  />
                                )}
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row error-title">
                        {errors.phone && (
                          <span className="error-title-text">
                            Please enter a valid phone number
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-block d-lg-none">
                    <select
                      name="time"
                      className="mobile-help-form"
                      value={value}
                      onChange={handleChange}
                      style={{ marginTop: "10px" }}
                    >
                      <option>Select preferred time</option>
                      <option style={{ width: "200px" }} value="8AM to 10PM">
                        8AM to 10PM
                      </option>
                      <option value="10AM to 12PM">10AM to 12PM</option>
                      <option value="12PM to 2PM">12PM to 2PM</option>
                      <option value="2PM to 4PM">2PM to 4PM</option>
                      <option value="4PM to 8PM">4PM to 8PM</option>
                    </select>
                  </div>
                  <div className="d-none d-sm-block">
                    <FormControl component="fieldset" id="spinner_value">
                      <div style={{ marginTop: "10px" }}>
                        <h5 className="help-title">Select preferred time </h5>
                      </div>
                      <RadioGroup
                        aria-label="select"
                        id="spinner_value"
                        value={value}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="8AM to 10PM"
                          control={<Radio />}
                          label="8 AM to 10 AM"
                        />
                        <FormControlLabel
                          value="10AM to 12PM"
                          control={<Radio />}
                          label="10 AM to 12 PM"
                        />
                        <FormControlLabel
                          value="12PM to 2PM"
                          control={<Radio />}
                          label="12 PM to 2 PM"
                        />
                        <FormControlLabel
                          value="2PM to 4PM"
                          control={<Radio />}
                          label="2 PM to 4 PM"
                        />
                        <FormControlLabel
                          value="4PM to 8PM"
                          control={<Radio />}
                          label="4 PM to 8 PM"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <button
                    type="submit"
                    style={{
                      backgroundColor: "#0FB59B",
                      marginBottom: "20px",
                      borderRadius: "30px",
                      width: "180px",
                    }}
                    className="submit-btn"
                  >
                    Call me Back
                  </button>
                </form>
              </div> */}
            </div>
          </div>
        </div>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogActions style={{ backgroundColor: "#2a8f80" }}>
              <Button onClick={handleClose} color="primary" autoFocus>
                X
              </Button>
            </DialogActions>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <img
                src={successIcon}
                style={{ height: "100%", width: "70%" }}
              ></img>
            </div>

            <p
              style={{
                font: "normal normal normal 15px/18px Roboto",
                padding: "10px 20px 0px 20px",
                /* letter-spacing: 0px; */
                color: "#585757",
              }}
            >
              Thanks for your interest in WhiteCoats, we have received your
              request and will get back shortly
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={handleClose}
                style={{
                  backgroundColor: "#ffffff",
                  border: "1px solid gray",
                  color: "black",
                  padding: "11px",
                  width: "150px",
                  fontsize: "12px",
                  marginBottom: "10px",
                  borderRadius: "9px",
                }}
                className="submit-btn"
              >
                Close
              </button>
            </div>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default Help;
