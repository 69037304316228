import { useState, useEffect, useRef, useCallback } from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import AppBar from "@material-ui/core/AppBar";
import { Link } from "react-router-dom";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Feeds from "../dashboard/feeds/Feeds";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, withStyles } from "@material-ui/core";
import DahboardHeader from "../dashboard/DahboardHeader";
import DahboardLeftside from "../dashboard/DahboardLeftside";
import DahboardRightside from "../dashboard/DahboardRightside";
import DahboardFooter from "../dashboard/DahboardFooter";
import FullViewFeed from "../dashboard/feeds/FullViewFeed";
import Categories from "../common/Categories.js";
import toast from "react-hot-toast";
import { Modal } from "react-responsive-modal";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import Progressbar from "../progressbar";
import send from "../assets/Icons/Icon feather-arrow-left@2x.png";
import S3 from "react-aws-s3";
import certificate from "../assets/dashboard_icons/Group 948.png";
import Likes from "../dashboard/social-interaction/Likes";
import CommentInput from "../dashboard/social-interaction/CommentInput";
import CommentsList from "../dashboard/social-interaction/CommentsList";
import useCommunityFeedLoader from "../dashboard/hooks/useCommunityFeedLoader";
import MedicalEvents from "./MedicalEvents";
import KnowledgePosts from "./KnowledgePosts";
import DrugReference from "./DrugReference";
import { useParams } from "react-router-dom";
import SubCategoryList from "../discoveryCategory/SubCategoryList";
import getWindowDimensions from "../common/hooks/getWindowDimensions";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MciVerificationStatus from "../common/MciVerificationStatus";
import MciUpload from "../common/MciUpload";
import MciUploadSuccess from "../common/MciUploadSuccess";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: 0,
      padding: "15px",
      marginBottom: "30px",
      webkitBoxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
      boxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
    },
    "& .MuiTypography-body1": {
      font: "normal normal normal 20px/20px Roboto",
      fontWeight: 400,
    },
    "& .PrivateTabIndicator-colorSecondary-4": {
      backgroundColor: "#1e8a79",
      border: "2px solid",
    },
    "& .PrivateTabIndicator-colorSecondary-6": {
      backgroundColor: "#2a8f80",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    "& .MuiBox-root": {
      padding: "15px",
      marginTop: "10px",
    },

    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: "#2a8f80",
      height: "4px",
    },
    "& .MuiTabPanel-root": {
      padding: "2px",
    },
    "& .MuiTabs-root": {
      marginTop: "-31px",
    },

    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      /* font-size: 11px; */
      font: "13px / 40px Roboto",
    },
    "& .MuiTab-root": {
      minWidth: "60px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  test: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: 0,
      padding: "15px",
      marginBottom: "30px",
      webkitBoxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
      boxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
    },
    "& .MuiTypography-body1": {
      font: "normal normal normal 20px/20px Roboto",
      fontWeight: 400,
    },
    "& .PrivateTabIndicator-colorSecondary-4": {
      backgroundColor: "#1e8a79",
      border: "2px solid",
    },
    "& .PrivateTabIndicator-colorSecondary-6": {
      backgroundColor: "#2a8f80",
    },

    "& .MuiTabPanel-root": {
      padding: "2px",
    },
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },

    "& .MuiBox-root": {
      padding: "0px",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: "#2a8f80",
      height: "4px",
    },

    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      /* font-size: 11px; */
      font: "13px / 40px Roboto",
    },
    "& .MuiTab-root": {
      minWidth: "27px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Knowledge = ({ feeds, setFeeds }) => {
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const playstore = "assets/images/playstore.png";
  const appstore = "assets/images/appstore.png";
  const downloadlink = "assets/images/downloadlink.png";
  const { tabId, Id } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const doc_id = getDocId();
  const headers = getHeaders();
  const classes = useStyles();
  const [value, setValue] = useState(tabId ? tabId : "1");
  const [urlPlay, setUrlPlay] = useState(
    "https://play.google.com/store/apps/details?id=com.vam.whitecoats"
  );
  const [urlApp, setUrlApp] = useState(
    "https://apps.apple.com/in/app/whitecoats/id1060971730"
  );

  const ondisplayCategoryNameHandler = (data) => {
    console.log("dashboard" + data);
    // data.unread_count=9;
    setSelectedCategoryData(data);
    setCategoryName(data.category_name);
    setCategoryNameLabel("Search " + data.category_name);
  };
  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [tabid, setTabid] = useState(1);
  const handleChange = (event, newValue) => {
    window.scrollTo(0, 0);
    setTabid(newValue);
    setValue(newValue);
    setCategoryId(0);
  };
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleUploadMci = () => {
    setUploadModel(true);
  };

  const [commentsListInfo, setCommentsListInfo] = useState(false);
  const handleOpenFullViewComent = (feed) => {
    setModalData(feed);
    setCommentsListInfo(true);
  };
  const handleCloseFullViewComment = () => {
    setCommentsListInfo(false);
  };

  const handleCloseUploadMci = () => {
    setUploadModel(false);
  };

  const [categoryId, setCategoryId] = useState(0);
  const [feedsErrorMsg, setFeedsErrorMsg] = useState("Could not load Feeds");
  const [requestLocation, setRequestLocation] = useState("Knowledge");
  const [categoryName, setCategoryName] = useState();
  const [selectedCategoryData, setSelectedCategoryData] = useState();
  const [categoryNameLabel, setCategoryNameLabel] = useState();

  const [updateCategoryUnreadCount, setUpdateCategoryUnreadCount] = useState({
    categoryId: null,
    newUnreadCount: null,
  });

  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);

  const [isMciUploaded, setIsMciUploaded] = useState(1);
  const [uploadMciSuccess, setUploadMciSuccess] = useState(false);
  const openUploadMciSuccess = () => setUploadMciSuccess(true);
  const closeUploadMciSuccess = () => {
    setIsMciUploaded(2);
    setUploadMciSuccess(false);
  };
  const [open, setOpen] = useState(false);

  const [modalData, setModalData] = useState({});

  const fullViewFeedRef = useRef(null);

  const fileInput = useRef(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState("");
  const [uploadModel, setUploadModel] = useState(false);

  const deleteUploadedImage = (e) => {
    e.preventDefault();

    fileInput.current.value = "";
    setImgPreviewUrl("");
    setIsFileUploaded(false);
  };
  const reUploadImage = (e) => {
    deleteUploadedImage(e);
    fileInput.current.click();
  };
  const showImagePreview = () => {
    let type = fileInput.current.files[0].type;
    if (type.includes("image")) type = "image";
    else if (type.includes("video")) type = "video";
    else if (type.includes("audio")) type = "audio";
    else if (type.includes("pdf")) type = "pdf";

    if (type === "image") {
      setIsFileUploaded(true);
      setImgPreviewUrl(URL.createObjectURL(fileInput.current.files[0]));
    } else {
      toast.error("Only images are allowed");
    }
  };

  const [openFullView, setOpenFullView] = useState(false);
  const handleOpenFullView = (feed) => {
    // window.scrollTo(0, 0);
    setModalData(feed);
    setOpenFullView(true);
  };
  const handleCloseFullView = () => {
    setOpenFullView(false);
  };

  const [openVerificationPending, setOpenVerificationPending] = useState(false);

  const handleVerificationPending = (feed) => {
    // handleUploadMci();
    setOpenVerificationPending(true);
  };
  const handleCloseVerificationPending = () => {
    setOpenVerificationPending(false);
  };

  const handleClickOpen = (feed) => {
    setModalData(feed);
    if (feed.feed_info.socialInteraction.likesCount == 0) {
      return;
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const [subCategoryId, setSubCategoryId] = useState(undefined)
  // const [nextFeedsData, setNextFeedsData] = useState({
  //   pageNum: 0,
  //   tabId : tabid
  // });

  // const { loading, error, channels, hasMore, category } = useCommunityFeedLoader(
  //   doc_id,
  //   nextFeedsData,
  //   setFeeds,
  //   subCategoryId,
  // );
  useEffect(() => {
    if (isMciUploaded === 2) fetchUserData();
  }, [isMciUploaded]);

  const saveFile = async (url, doc_id) => {
    const payload = {
      doc_id,
      attachment_original_url: url,
    };

    setloaderSpinner(true);
    const res = await fetch(baseUrl + "v2/upload", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        setFeedsErrorMsg(errorMsg);
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        setFeedsErrorMsg(errorMsg);
        toast(errorMsg);
      }
      return;
    });

    const data = await res.json();
    setloaderSpinner(false);

    if (data.status === "success") {
      // addToast("uploaded Successfully", {
      //   appearance: "success",
      //   autoDismiss: true,
      // });
      handleCloseUploadMci();
      openUploadMciSuccess();
      fetchUserData();
    } else toast.error(data.errorMsg);
  };
  const uploadFile = async (e, fileInput, doc_id) => {
    e.preventDefault();
    let file = fileInput.current.files[0];
    let newFileName = fileInput.current.files[0].name;

    const ReactS3Client = new S3(config);
    setloaderSpinner(true);
    const data = await ReactS3Client.uploadFile(file, newFileName);
    setloaderSpinner(false);

    saveFile(data.location, doc_id);
  };
  const fetchUserData = async () => {
    // const IP_ADDRESS = await getClientIp();
    // headers["x-device-id"] = IP_ADDRESS;
    const payload = {
      user_id: doc_id,
    };
    const res = await fetch(baseUrl + "v1/userProfile/view", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    if (data.status === "success") {
      localStorage.setItem("email", data.data.cnt_email);
      localStorage.setItem("phone", data.data.cnt_num);
      setUserData(data.data);
    } else {
      toast.error(data.errorMsg);
    }
  };

  const verifyUser = () => {
    if (!userData.verificationInfo.isCommunityVerified) {
      if (userData.verificationInfo.is_user_verified == 1) {
        handleUploadMci();
        return false;
      }
      if (userData.verificationInfo.is_user_verified == 2) {
        handleVerificationPending();
        return false;
      }
    }
    return true;
  };

  const addComment = async (feed_id, channel_id, comment, attachments = []) => {
    if (!verifyUser()) return;

    if (attachments.length === 0 && comment === "") {
      toast.error("Cannot post empty comment");
      return;
    }

    let payload = JSON.stringify({
      doc_id: doc_id,
      channel_id,
      feedTypeId: feed_id,
      socialInteraction: {
        type: "Comment",
        comment,
        attachment_details: attachments,
      },
    });

    payload = payload.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });

    payload = encodeURIComponent(payload);

    const res = await fetch(baseUrl + "v1/feedSocialInteractions", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    if (data.status === "success")
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed.feed_info.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    else toast.error(data.errorMsg);
  };
  const updateLikeCount = async (feed_id, channel_id, isLike) => {
    if (!verifyUser()) return;

    const payload = {
      doc_id: doc_id,
      channel_id: channel_id,
      feedTypeId: feed_id,
      socialInteraction: {
        type: "Like",
        isLike,
      },
    };

    const res = await fetch(baseUrl + "v1/feedSocialInteractions", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    if (data.status === "success")
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed.feed_info.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    else toast.error(data.errorMsg);
  };
  const updateViewCount = async (channel_id, feed_id) => {
    const payload = {
      doc_id,
      channel_id: channel_id,
      feedId: feed_id,
    };

    const res = await fetch(baseUrl + "feeds/updateViewCount", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((err) => {
      console.log(err);
      return;
    });
    const data = await res.json();

    if (data.status === "success") {
      if (data.data.socialInteraction)
        setFeeds(
          feeds.map((feed) => {
            const newFeed = { ...feed };
            if (feed.feed_info.feed_id === feed_id)
              newFeed.feed_info.socialInteraction = data.data.socialInteraction;
            return newFeed;
          })
        );
      else
        setFeeds(
          feeds.map((feed) => {
            const newFeed = { ...feed };
            if (feed.feed_info.feed_id === feed_id)
              newFeed.feed_info.socialInteraction.viewCount += 1;
            return newFeed;
          })
        );
    } else toast.error(data.errorMsg);
  };
  const submitSurvey = async (feed_id, channel_id, question_id, option_id) => {
    if (option_id.length === 0) {
      toast("Please select an option");
      return;
    }

    const payload = {
      feed_id,
      channel_id,
      user_id: doc_id,
      feed_survey: {
        questions: [
          {
            question_id,
            options: option_id.map((option) => ({
              option_id: option,
            })),
          },
        ],
      },
    };

    const res = await fetch(baseUrl + "v1/feed/survey/update", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();
    if (data.status === "success")
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed?.feed_info?.feed_id === feed_id) {
            newFeed.feed_info.feed_survey.questions =
              newFeed.feed_info.feed_survey.questions.map(
                (question, index) => ({
                  ...question,
                  options: data.data.feed_survey.questions[index].options,
                })
              );
            newFeed.feed_info.feed_survey.is_open =
              data.data.feed_survey.is_open;
            newFeed.feed_info.feed_survey.is_participated =
              data.data.feed_survey.is_participated;
          }

          return newFeed;
        })
      );
    else toast.error(data.errorMsg);
  };
  const deleteComment = async (feed_id, channel_id, social_interaction_id) => {
    setloaderSpinner(true);
    const payload = {
      user_id: doc_id,
      channel_id: channel_id,
      feed_id,
      social_interaction_id,
    };
    const res = await fetch(baseUrl + "v1/feeds/comment/delete", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();
    setloaderSpinner(false);
    if (data.status === "success")
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed.feed_info.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    else toast.error(data.errorMsg);
  };
  const closeIcon = <img src={close}></img>;
  const updateComment = async (
    feed_id,
    channel_id,
    social_interaction_id,
    comment,
    attachments = []
  ) => {
    if (!verifyUser()) return;

    if (attachments.length === 0 && comment === "") {
      // addToast("Cannot post empty comment", {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
      return;
    }

    let payload = JSON.stringify({
      user_id: doc_id,
      channel_id,
      feed_id,
      social_interaction_id,
      comment: comment,
      attachment_details: attachments,
    });

    payload = payload.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });

    payload = encodeURIComponent(payload);

    setloaderSpinner(true);
    const res = await fetch(baseUrl + "v1/feeds/comment/update", {
      method: "POST",
      headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();
    setloaderSpinner(false);
    if (data.status === "success")
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed?.feed_info?.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    else toast.error(data.errorMsg);
  };
  const registerWebinar = async (e, feed_id, verify_user, title) => {
    e.preventDefault();

    if (verify_user) {
      if (!verifyUser()) return;
    }

    const payload = {
      user_id: doc_id,
      feed_id,
    };

    const res = await fetch(baseUrl + "v1/user/webinar/register", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
    });

    const data = await res.json();

    if (data.status === "success") {
      toast.success(`Thank you for registering for ${title}`);
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed?.feed_info?.feed_id === feed_id)
            newFeed.feed_info.event_details.is_user_registered = true;
          return newFeed;
        })
      );
      return true;
    } else if (data.status === "error") toast.error("Failed to registered");

    return false;
  };

  return (
    <>
      {windowDimensions.width <= 425 && categoryId != 0 ? (
        <header id="header d-block d-lg-none">
          <div className="container">
            <div className="navbar-header">
              <div className="mobile-header-wrapper sticky d-block d-lg-none">
                <div
                  className="position-relative "
                  style={{
                    backgroundColor: "green",
                    height: "60px",
                    textAlign: "center",
                  }}
                >
                  <div className="mobile-responsive">
                    <div className="brand-logo ">
                      <div className="row">
                        <div className="col-4">
                          <Link to="/knowledge">
                            <img
                              style={{
                                cursor: "pointer",
                                marginTop: "10px",
                                marginRight: "40px",
                              }}
                              onClick="{()=>{history.goBack()}}"
                              src={send}
                              alt="back"
                            />
                          </Link>
                        </div>
                        <div className="col-8">
                          <p
                            style={{
                              textAlign: "initial",
                              marginTop: "20px",
                              color: "white",
                              font: "normal normal medium Roboto",
                              fontSize: "20px",
                            }}
                          >
                            {categoryName}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <header>
          <DahboardHeader
            userData={userData}
            handleUploadMci={handleUploadMci}
            handleVerificationPending={handleVerificationPending}
          ></DahboardHeader>
        </header>
      )}
      <main>
        <div className="main-wrapper pt-80" style={{ marginTop: "2em" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-3  d-none d-sm-block" id="left">
                <DahboardLeftside
                  userInfo={userData}
                  handleUploadMci={handleUploadMci}
                ></DahboardLeftside>
              </div>
              <div className="col-lg-6 " id="middle">
                <TabContext
                  value={value}
                  indicatorColor={{ backgroundColor: "green" }}
                >
                  <AppBar
                    className="mL"
                    position="sticky"
                    z-index="1"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      marginBottom: "15px",
                      top: "85px",
                    }}
                  >
                    <div style={{ marginTop: "-31px" }}>
                      <TabList
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="tab example"
                        className={classes.tabs}
                      >
                        <Tab
                          label="Feeds"
                          value="1"
                          centered
                          className={classes.tabs}
                        />
                        <Tab
                          className={classes.tabs}
                          label="Drug Reference"
                          value="2"
                          TabIndicatorProps={{
                            style: { background: "green" },
                          }}
                        />
                        <Tab
                          className={classes.tabs}
                          label="Medical Events"
                          value="3"
                        />
                      </TabList>
                    </div>
                  </AppBar>

                  {value == 1 && (
                    <div>
                      {" "}
                      {windowDimensions.width <= 425 &&
                      categoryId != 0 ? null : (
                        <Categories
                          ondisplayCategoryName={ondisplayCategoryNameHandler}
                          setCategoryId={setCategoryId}
                          requestLocation={requestLocation}
                          setSelectedCategoryData={setSelectedCategoryData}
                          updateCategoryUnreadCount={updateCategoryUnreadCount}
                        />
                      )}
                    </div>
                  )}
                  {/* { value == 1 && <div style={{marginTop:"32px"}}>
                        <Categories setCategoryId={setCategoryId} requestLocation={requestLocation}/>
                            </div> }  
                         */}
                  <TabPanel value="1" className={classes.tabs}>
                    <KnowledgePosts
                      feeds={feeds}
                      setFeeds={setFeeds}
                      profile_pic={userData?.profile_pic_small_url}
                      updateLikeCount={updateLikeCount}
                      addComment={addComment}
                      submitSurvey={submitSurvey}
                      // openFullView={handleOpenFullView}
                      commentsListInfo={handleOpenFullViewComent}
                      openLikesModal={handleClickOpen}
                      //channels={channels}
                      registerWebinar={registerWebinar}
                      user_verification_info={userData?.verificationInfo}
                      setUpdateCategoryUnreadCount={
                        setUpdateCategoryUnreadCount
                      }
                      deleteComment={deleteComment}
                      updateComment={updateComment}
                      updateViewCount={updateViewCount}
                      categoryId={categoryId}
                      selectedCategoryData={selectedCategoryData}
                      categoryNameLabel={categoryNameLabel}
                    />
                  </TabPanel>
                  <TabPanel value="2" className={classes.tabs}>
                    <div className="wow fadeIn bg-site-main padding-35px-top padding-20px-bottom">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12 col-sm-12 col-xs-12 margin-30px-bottom sm-margin-50px-bottom xs-margin-30px-bottom wow fadeInUp">
                            <div className="row">
                              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                                <h6
                                  className="alt-font text-white font-weight-600"
                                  style={{ margin: "5" }}
                                >
                                  For accessing this feature
                                </h6>
                                <h6 className="alt-font text-white font-weight-600">
                                  Download Mobile App
                                </h6>
                                <p className="text-medium text-white text-center">
                                  Join the WhiteCoats network at NO COST today
                                  to see how this exclusive digital platform can
                                  help be effective and successful
                                </p>
                                <div className="row row-flex">
                                  <div className="col-sm-6">
                                    <div className="profile-thumb1 ">
                                      <a href={urlPlay} target="_blank">
                                        <figure className="profile-thumb-small profile-active">
                                          <img
                                            src={playstore}
                                            alt="download app"
                                          />
                                        </figure>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="profile-thumb1 ">
                                      <a href={urlApp} target="_blank">
                                        <figure className="profile-thumb-small profile-active">
                                          <img
                                            src={appstore}
                                            alt="download app"
                                          />
                                        </figure>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value="3" className={classes.tabs}>
                    <MedicalEvents
                      feeds={feeds}
                      setFeeds={setFeeds}
                      profile_pic={userData?.profile_pic_small_url}
                      updateLikeCount={updateLikeCount}
                      addComment={addComment}
                      submitSurvey={submitSurvey}
                      //  openFullView={handleOpenFullView}
                      commentsListInfo={handleOpenFullViewComent}
                      openLikesModal={handleClickOpen}
                      //   channels={channels}
                      registerWebinar={registerWebinar}
                      user_verification_info={userData?.verificationInfo}
                      deleteComment={deleteComment}
                      updateComment={updateComment}
                      updateViewCount={updateViewCount}
                    ></MedicalEvents>
                  </TabPanel>
                </TabContext>
              </div>
              <div className="col-lg-3 d-none d-sm-block" id="right">
                <DahboardRightside></DahboardRightside>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="d-block d-sm-none">
        <DahboardFooter
          handleUploadMci={handleUploadMci}
          userInfo={userData}
          handleVerificationPending={handleVerificationPending}
        ></DahboardFooter>
      </footer>
      {/* <Modal
        open={openFullView}
        blockScroll={true}
        style={customStyles}
        onClose={handleCloseFullView}
        closeIcon={closeIcon}
        classNames={{ modal: "feedModel" }}
        ref={fullViewFeedRef}
      >
        <FullViewFeed
          profile_pic={userData?.profile_pic_small_url}
          close={handleCloseFullView}
          feed={modalData}
          addComment={addComment}
          updateLikeCount={updateLikeCount}
          submitSurvey={submitSurvey}
          updateViewCount={updateViewCount}
       //   channels={channels}
          deleteComment={deleteComment}
          updateComment={updateComment}
          openLikesModal={handleClickOpen}
          openCommentMobileView={handleOpenFullViewComent}
          registerWebinar={registerWebinar}
          is_user_verified={userData?.verificationInfo?.is_user_verified}
        ></FullViewFeed>
      </Modal> */}

      {/* <div>
        <Progressbar
          show={loaderSpinner}
          imageUrl={url}
          height="90"
          width="90"
          alignment="middle"
          alttext="Loading..."
        />
      </div> */}
      <Modal
        open={open}
        onClose={handleClose}
        classNames={{ modal: "feedsLikeModel" }}
        closeIcon={closeIcon}
      >
        <Likes feed={modalData}></Likes>
      </Modal>

      {/* Mobile responce Comment list */}
      <Modal
        open={commentsListInfo}
        blockScroll={true}
        onClose={handleCloseFullViewComment}
        classNames={{ modal: "feedsLikeModel" }}
        styles={{
          modal: {
            height: "100vh",
            width: "100%",
            padding: "0",
            margin: "0",
            borderRadius: "0",
            overflow: "visible",
          },
          modalContainer: {
            overflow: "visible",
          },
        }}
        showCloseIcon={false}
        feed={modalData}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div
            className="mobile-header-wrapper d-block d-lg-none"
            style={{
              position: "-webkit-sticky",
              position: "sticky",
              top: "0",
              left: "0",
              width: "100%",
              zIndex: "5",
              flexShrink: "0",
            }}
          >
            <div className="mobile-header position-relative ">
              <div style={{ display: "flex" }}>
                <div className="brand-logo ">
                  <img
                    onClick={handleCloseFullViewComment}
                    src={send}
                    style={{ width: "25px", marginTop: "-4px" }}
                    alt="timeline logo"
                  />
                </div>
                <div className="pull-left">
                  <p style={{ marginLeft: "10px" }}>
                    Comment &nbsp;
                    <span style={{ color: "green" }}>
                      {`(${modalData?.feed_info?.socialInteraction?.commentsCount} Comments)`}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ overflowY: "auto" }}>
            <CommentsList
              feed={modalData}
              deleteComment={deleteComment}
              updateComment={updateComment}
            />
          </div>
          <div
            className="footer-area  d-block d-lg-none"
            style={{
              position: "-webkit-sticky",
              position: "sticky",
              bottom: "0",
              right: "0",
              width: "100%",
              flexShrink: "0",
              background: "white",
              zIndex: "5",
              height: "100px",
            }}
          >
            <hr></hr>
            <div
              className="post-meta"
              style={{
                padding: "0",
                margin: "0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CommentInput
                profile_pic={userData?.profile_pic_small_url}
                addComment={addComment}
                feed_id={modalData?.feed_info?.feed_id}
                channel_id={modalData.channel_id}
                isFullFeedView={false}
              />
            </div>
          </div>
        </div>
      </Modal>

      {/* profile verification model */}
      <Modal
        open={openVerificationPending}
        onClose={handleCloseVerificationPending}
        classNames={{ modal: "verificationModel" }}
        closeIcon={closeIcon}
      >
        <MciVerificationStatus
          is_user_verified={userData?.verificationInfo?.is_user_verified}
          closeVerificationStatusModal={handleCloseVerificationPending}
        />
      </Modal>

      <Modal
        open={uploadModel}
        onClose={handleCloseUploadMci}
        classNames={{ modal: "uploadMci" }}
        closeIcon={closeIcon}
      >
        <MciUpload
          openSuccessModal={openUploadMciSuccess}
          closeUploadMci={handleCloseUploadMci}
        />
      </Modal>

      <Modal
        closeIcon={closeIcon}
        open={uploadMciSuccess}
        onClose={closeUploadMciSuccess}
        classNames={{ modal: "verificationModel" }}
      >
        <MciUploadSuccess closeUploadMciSuccess={closeUploadMciSuccess} />
      </Modal>
    </>
  );
};
export default Knowledge;
