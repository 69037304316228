import { useState, useEffect, useRef, useCallback } from "react";
import { config, getDocId, getHeaders } from "../../utils/utils";
import React from "react";
import Progressbar from "../../progressbar";
import { Link, useParams, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { BsExclamation } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { IoArrowBackOutline } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import close from "../../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import "../../profile/profile.css";
import { Modal } from "react-responsive-modal";
import doneImage from "../../assets/Web Icons/Icon material-done.svg";

const DeleteAvailable = ({
  getUserData,
  closeDeletePubModal,
  deletedData
}) => {

  const { type } = useParams();
  //console.log("data"+setCategoryListInfo)

  const history = useHistory();
  const user_id = getDocId();
  const headers = getHeaders();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const deleteExperience = async () => {
   
    let payload = {
        user_id: user_id,
        prof_history: [
            {
               
                prof_id:201
            }
        ]
    }
    
    const res = await fetch(baseUrl + "v1/userProfile/delete", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      toast.success('Publication deleted');
      getUserData();
      closeDeletePubModal();
    } else {
      toast.error(data.errorMsg);
    }
  };


  return (
    <>

          <div className="container-fluid">
              <div className="row" style={{ marginTop: "50px" }} >
                  <div className="col-sm-12 ">
                      <BsExclamation style={iconBackgroundStyle} size={65} />
                  </div>
                  <div className="col-sm-12 " style={{ marginTop: '15px' }}>
                      <div style={{ color: "black", marginBottom: "10px", font: "16px/16px roboto", fontWeight: "600px" }}>
                          <p>Are you sure want to delete this?</p>
                      </div>
                  </div>
                  <div className="col-sm-12 ">
                      <div style={{ color: "grey", marginBottom: "10px", font: "16px/16px roboto", fontWeight: "600px" }}>
                          <p>This information will no longer visible in your profile </p>
                      </div>
                  </div>
                  <div className="col-sm-12 " >
                      <div style={{ margin: "30px 30px" }}>
                          <button type="submit" className="pub-save-button" onClick={() => deleteExperience()} style={{ width: "7em", height: "2.5em" }}>
                              Ok
                          </button>
                          <button type="submit" className="pub-close-button" onClick={closeDeletePubModal} style={{ width: "7em", height: "2.5em" }}>
                              Cancel
                          </button>
                      </div>
                  </div>
              </div>
          </div> 
      {/* </Modal>  */}
    </>
  );
};

export default DeleteAvailable;
const iconBackgroundStyle = {
  backgroundColor: "rgb(30 138 121)",
  borderRadius: "50%",
  padding: "3px",
  color: "white",
};
