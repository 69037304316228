import React from "react";

const HealthCheck = () => {
  return (
    <h3 style={{ textAlign: "center", color: "black" }}>
      Hey There!! The App is Healthy
    </h3>
  );
};

export default HealthCheck;
