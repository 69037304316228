import { useEffect, useState } from "react";
import { getDocId, getHeaders } from "../../utils/utils";

const useChannelFeedsLoader = (channel_id, last_feed_id, setFeeds) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const doc_id = getDocId();

  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(false);
  const [nextLastFeedId, setNextLastFeedId] = useState(last_feed_id);

  const getTimelineFeedsData = async () => {
    setLoading(true);
    setError(false);
    const payload = {
      doc_id,
      channel_id,
      last_feed_id,
      load_next: true,
    };
    const res = await fetch(baseUrl + "v3/channelTimeline", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {});
    const data = await res.json();

    if (data.status === "success") {
      console.log(payload.last_feed_id);
      if (payload.last_feed_id === 0) setFeeds(data.data.feed_data);
      else setFeeds((prev) => [...prev, ...data.data.feed_data]);
      setHasMore(data.data.feed_data.length > 0);
      setNextLastFeedId(data.data.last_feed_id);
    } else {
      setError(true);
      setHasMore(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTimelineFeedsData();
  }, [last_feed_id]);

  return { loading, hasMore, error, nextLastFeedId };
};

export default useChannelFeedsLoader;
