import { config, getDocId, getHeaders } from "../utils/utils";
import { useState, useEffect, React, useCallback } from "react";
import { Link } from "react-router-dom";
import "./Channels.css";
import Card from "@mui/material/Card";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GooglePlusIcon from "@mui/icons-material/Google";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

import CallIcon from "@mui/icons-material/Call";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import WebIcon from "@mui/icons-material/Web";

const AboutChannel = ({ selectedChannel }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const doc_id = getDocId();
  const headers = getHeaders();
  const [aboutData, setAboutData] = useState();
  useEffect(() => {
    getChannelAbout();
  }, []);

  const getChannelAbout = async () => {
    const payload = {
      doc_id: doc_id,
      channel_id: selectedChannel.channel_id,
    };
    const res = await fetch(baseUrl + "v2/channel/about", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {});
    const data = await res.json();
    if (data.status === "success") {
      setAboutData(data?.data);
    }
  };
  return (
    <div style={{ marginBottom: "50px" }}>
      <div className="aboutChannelConatiner">
        <div>
          <Card
            sx={{
              minHeight: "120px",
              padding: "5px",
              marginBottom: "15px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={
                  aboutData?.channel_type == "Community"
                    ? aboutData?.logo_original_url
                    : aboutData?.logo_url
                }
                width="90%"
                height="70%"
              ></img>
            </div>
          </Card>
        </div>
        <div>
          <Card
            sx={{
              minHeight: "120px",
              padding: "5px",
              marginBottom: "15px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <div>
              <p className="about-title">{aboutData?.title}</p>
            </div>
            <div>
              {" "}
              <p className="about-discription">
                <div
                  dangerouslySetInnerHTML={{ __html: aboutData?.description }}
                />
              </p>
            </div>
          </Card>
        </div>
        <div>
          <Card
            sx={{
              minHeight: "120px",
              padding: "5px",
              marginBottom: "15px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <p className="about-title">CONTACT DETAILS</p>

            {aboutData?.contact_details?.contact_number != "" && (
              <p className="about-title">
                {" "}
                <CallIcon />
                <span
                  className="about-contact discription"
                  onClick={() => {
                    window.open(aboutData?.contact_details?.contact_number);
                  }}
                >
                  {aboutData?.contact_details?.contact_number}
                </span>
              </p>
            )}
            {aboutData?.contact_details?.email != "" && (
              <p className="about-title">
                {" "}
                <EmailIcon />
                <span
                  className="about-contact discription"
                  onClick={() => {
                    window.open(aboutData?.contact_details?.email);
                  }}
                >
                  {aboutData?.contact_details?.email}
                </span>
              </p>
            )}
            {aboutData?.contact_details?.location != "" && (
              <p className="about-title">
                {" "}
                <AddLocationIcon /> {aboutData?.contact_details?.location}
              </p>
            )}
            {aboutData?.contact_details?.website != "" && (
              <p className="about-title">
                {" "}
                <WebIcon />
                <span
                  className="about-contact discription"
                  onClick={() => {
                    window.open(aboutData?.contact_details?.website);
                  }}
                >
                  {aboutData?.contact_details?.website}
                </span>
              </p>
            )}
            {aboutData?.contact_details?.facebook_page != "" && (
              <p className="about-title">
                {" "}
                <FacebookIcon />
                <span
                  className="about-contact discription"
                  onClick={() => {
                    window.open(aboutData?.contact_details?.facebook_page);
                  }}
                >
                  {aboutData?.contact_details?.facebook_page}
                </span>
              </p>
            )}
            {aboutData?.contact_details?.linkedin != "" && (
              <p className="about-title">
                {" "}
                <LinkedInIcon />
                <span
                  className="about-contact discription"
                  onClick={() => {
                    window.open(aboutData?.contact_details?.linkedin);
                  }}
                >
                  {aboutData?.contact_details?.linkedin}
                </span>
              </p>
            )}
            {aboutData?.contact_details?.twitter != "" && (
              <p className="about-title">
                {" "}
                <TwitterIcon />
                <span
                  className="about-contact discription"
                  onClick={() => {
                    window.open(aboutData?.contact_details?.twitter);
                  }}
                >
                  {aboutData?.contact_details?.twitter}
                </span>
              </p>
            )}
            {aboutData?.contact_details?.instagram != "" && (
              <p className="about-title">
                {" "}
                <InstagramIcon />
                <span
                  className="about-contact discription"
                  onClick={() => {
                    window.open(aboutData?.contact_details?.instagram);
                  }}
                >
                  {aboutData?.contact_details?.instagram}
                </span>
              </p>
            )}
            {aboutData?.contact_details?.google_plus != "" && (
              <p className="about-title">
                {" "}
                <GooglePlusIcon />
                <span
                  className="about-contact discription"
                  onClick={() => {
                    window.open(aboutData?.contact_details?.google_plus);
                  }}
                >
                  {aboutData?.contact_details?.google_plus}
                </span>
              </p>
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AboutChannel;
