import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { loginService } from "../services/authenticationService";
import PasswordLogin from "./LoginComponents/PasswordLogin";
import OtpLogin from "./LoginComponents/OtpLogin";
import logo from "../assets/logo4.png";
import { resolvePromise, ttlForDocId } from "../utils/utils";
import bgImage from "../assets/pre_login_bg.png";
import artwork from "../assets/pre_login_artwork.png";
import Footer from "../common/Footer";

const Login = () => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loginMode, setLoginMode] = useState("password");
  const login = async (formData, remember) => {
    let payload = {
      app_uname: formData.email.trim(),
      app_pass: formData.pass,
      stay_logged_in: true,
    };
    const [data, error] = await resolvePromise(() => loginService(payload));
    if (error) {
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    }

    if (data.status === "success") {
      if (data.data.doctor_valid === true) {
        const expiryDate = new Date().getTime() + ttlForDocId;

        if (remember) {
          localStorage.setItem("expiry_date", expiryDate);
          localStorage.setItem("doc_id", data.data.doc_id);
          localStorage.setItem("auth_token", data.data.security_token);
        }
        if (!remember) {
          sessionStorage.setItem("doc_id", data.data.doc_id);
          sessionStorage.setItem("auth_token", data.data.security_token);
        }
        localStorage.setItem("remember", remember);

        if (!data.data.is_user_active) {
          localStorage.setItem("isRegistrationComplete", "false");
          history.push("/signup/professionalInfo");
          toast("Your registration is not complete yet");
          return;
        } else {
          localStorage.setItem("isRegistrationComplete", "true");
        }
        // Redirect to dashboard
        history.push("/dashboard");
      } else {
        toast.error("Invalid credentials");
      }
    }
  };

  const navigate = (to) => setLoginMode(to);

  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ height: "100vh" }}>
          <div
            className="col-sm-5 d-none d-sm-block"
            style={{ backgroundColor: "#f1f1f1" }}
          >
            <div
              className="timeline-logo"
              style={{ margin: "10px 0px 0px 20px" }}
            >
              <Link to="/">
                <img
                  src={logo}
                  style={{ width: "150px", height: "auto" }}
                  alt="timeline logo"
                />
              </Link>
            </div>
            <div>
              <div style={{ padding: "1em", margin: "2em 0em" }}>
                <img
                  className="login-image"
                  src={artwork}
                  alt="timeline logo"
                  style={{
                    width: "45%",
                    height: "auto",
                    opacity: "1",
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>
              <div style={{ width: "70%", margin: "auto" }}>
                <h3
                  style={{
                    color: "#1B8C7A",
                    font: "normal normal bold 2em/1.2em Roboto",
                    letterSpacing: "0px",
                    opacity: "1",
                    textAlign: "center",
                    width: "auto",
                    padding: "0.5em",
                  }}
                >
                  Welcome to WhiteCoats
                </h3>
                <h4
                  style={{
                    font: "normal normal normal 1.5em/1.2em Roboto",
                    color: "#6D6D6D",
                    textAlign: "center",
                    opacity: "1",
                  }}
                >
                  A Digital Networking and Collaboration Platform for Doctors
                </h4>
                <br />
                <h6
                  style={{
                    font: "normal normal normal 1em Roboto",
                    color: "#6D6D6D",
                    textAlign: "center",
                    opacity: "1",
                  }}
                >
                  Clinical Discussions | Case Reports | Guidelines | Jobs | Drug
                  References & much more
                </h6>
                {/* <h4 style={{ font: "normal normal normal 1.5em/1.2em Roboto",color: "#6D6D6D",textAlign: "center",  opacity: "1"  }}>Case Reports
                </h4>
                <h4 style={{ font: "normal normal normal 1.5em/1.2em Roboto",color: "#6D6D6D",textAlign: "center",  opacity: "1"  }}>Guidelines
                </h4>
              
                <h4 style={{ font: "normal normal normal 1.5em/1.2em Roboto",color: "#6D6D6D",textAlign: "center",  opacity: "1"  }}>Jobs
                </h4>
                <h4 style={{ font: "normal normal normal 1.5em/1.2em Roboto",color: "#6D6D6D",textAlign: "center",  opacity: "1"  }}>Drug References & much more
                </h4> */}
              </div>
            </div>
          </div>

          <div
            className="col-sm-7 "
            style={{
              backgroundImage: `url(${bgImage})`,
              height: "auto",
              backgroundColor: "#1e8a79",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundAttachment: "fixed",
              backgroundSize: "cover",
            }}
          >
            <div className="row">
              <div className="col-sm-2"></div>
              <div className="col-sm-8">
                <div className="form-group">
                  <div
                    style={{
                      display: "flex",
                      marginTop: "100px",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <div>
                      <h1
                        className="signIn-header"
                        onClick={() => navigate("password")}
                      >
                        Sign In
                      </h1>
                      <div
                        style={{
                          border: "1px solid #FFEE00",
                          backgroundColor: "#FFEE00",
                          opacity: "1",
                          width: "90%",
                          height: "0px",

                          display:
                            loginMode === "password" ? "inline-block" : "none",
                        }}
                      ></div>
                    </div> */}

                    <div>
                      <h1 className="signIn-header">Sign In With OTP</h1>
                      <div
                        style={{
                          border: "1px solid #FFEE00",
                          backgroundColor: "#FFEE00",
                          opacity: "1",
                          width: "100%",
                          height: "0px",
                          display: "inline-block",
                        }}
                      ></div>
                    </div>
                    <Link
                      style={{
                        backgroundColor: "white",
                        color: "green",
                        padding: "10px",
                      }}
                      to="/signup"
                    >
                      <span style={{ fontSize: "20px" }}> Sign Up Now </span>
                    </Link>
                  </div>
                </div>
                <div className="form-group">
                  <h4 style={{ fontSize: "15px", color: "white" }}>
                    Enter to continue and explore WhiteCoats
                  </h4>
                </div>
                {/* {
                  {
                    password: <PasswordLogin login={login} />,
                    otp: <OtpLogin />,
                  }[loginMode]
                } */}
                <OtpLogin />
              </div>
              <div className="col-sm-2"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer pre_login={true} />
    </>
  );
};

export default Login;
