import { useState, useEffect } from "react";
import { getDocId, getHeaders } from "../utils/utils";
import toast from "react-hot-toast";

const useSearch = (nextFeedsData, selectedFilter, searchText, setShowFeeds) => {
  const user_id = getDocId();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [searchResults, setSearchResults] = useState([0, 0, 0, 0, 0, 0]);
  const [filters, setFilters] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [noResults, setNoResults] = useState(false);

  const searchFeeds = async (e) => {
    setShowFeeds(true);
    setLoading(true);
    setError(false);
    setNoResults(false);

    const url = baseUrl + "v2/search";
    const payload = {
      last_feed_id: nextFeedsData.last_feed_id, // Send zero initially  & on pagination  last feed id from results
      filter: { searchType: [selectedFilter] }, // send applied filter id received in results
      user_id,
      searchIn: "feeds",
      searchText,
    };

    const res = await fetch(url, {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      setLoading(false);
      setError(true);
      setHasMore(false);
      return;
    });

    const data = await res.json();

    if (data.status === "success") {
      if (payload.last_feed_id === 0) {
        if (!data.data.feed_data || data.data.feed_data.length === 0) {
          setNoResults(true);
          // setShowFeeds(false);
          // return;
        }
        setSearchResults([...data.data.feed_data]);
        setFilters(data.data.filters);
      } else setSearchResults((prev) => [...prev, ...data.data.feed_data]);

      setHasMore(data.data.feed_data.length > 0);
    } else {
      setError(true);
      setHasMore(false);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (searchText !== "") searchFeeds();
  }, [nextFeedsData]);

  return {
    loading,
    error,
    hasMore,
    searchResults,
    setSearchResults,
    filters,
    noResults,
    setNoResults,
  };
};

export default useSearch;
