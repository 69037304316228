import React from "react";

const MciVerificationStatus = ({
  is_user_verified,
  closeVerificationStatusModal,
}) => {
  return (
    <>
      <div style={{ marginTop: "15px" }}></div>
      <div className="verifaction">
        <p className="verification-title">
          {is_user_verified === 1 && "Profile Verification Pending"}
          {is_user_verified === 2 && "Profile Under Verification"}
        </p>
        <p className="verification-sub-title">
          {is_user_verified === 1 && "Your profile verification is pending"}
          {is_user_verified === 2 &&
            "Your certificate has been upload successfully"}
        </p>
        <p className="verification-sub-title">
          {is_user_verified === 1 &&
            "Please verify your profile to get full access"}
          {is_user_verified === 2 &&
            "We will verify your certificate and validate your profile"}
        </p>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          onClick={closeVerificationStatusModal}
          style={{
            backgroundColor: "#ffffff",
            border: "1px solid gray",
            color: "black",
            padding: "11px",
            width: "150px",
            fontsize: "11px",
            marginBottom: "10px",
            borderRadius: "9px",
          }}
          className="submit-btn"
        >
          Close
        </button>
      </div>
    </>
  );
};

export default MciVerificationStatus;
