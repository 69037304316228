import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { getHeaders, logout } from "../../utils/utils";

const useFeedLoader = (
  doc_id,
  nextFeedsData,
  setFeeds,
  isMandatoryInfoEntered = true
) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  // const [feeds, setFeeds] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [channels, setChannels] = useState([]);

  const getDashBoardData = async () => {
    setLoading(true);
    setError(false);
    const payload = {
      doc_id,
      last_feed_time: nextFeedsData.lastFeedTime,
      load_next: true,
      pg_num: nextFeedsData.pageNum,
    };
    const res = await fetch(baseUrl + "v3/getDashBoardData", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {
      setLoading(false);
      setError(true);
      setHasMore(false);
      return;
    });
    const data = await res.json();
    if (data.status === "success") {
      if (payload.pg_num === 0) setFeeds([...data.data.feed_data]);
      else
        setFeeds((prevFeeds) => {
          return [...new Set([...prevFeeds, ...data.data.feed_data])];
        });
      if (data.data.listofChannels)
        setChannels((prev) => {
          return [...prev, ...data.data.listofChannels];
        });
      setLoading(false);
      setHasMore(data.data.feed_data.length > 0);
    } else {
      setLoading(false);
      setError(true);
      setHasMore(false);
      return;
    }
  };

  useEffect(() => {
    if (isMandatoryInfoEntered) getDashBoardData();
  }, [nextFeedsData, isMandatoryInfoEntered]);
  return { loading, error, channels, hasMore };
};

export default useFeedLoader;
