import error from "../assets/404.png";
import "./notfound.css";

function Notfound() {
  return (
    <div className="error-page">
      <div className="error-content">
        <div className="container">
          <img src={error} alt="" className="img-responsive" />
          <div className="error-message">
            <h1 className="error-title">Whoops!</h1>
            <p>
              Looks like you are lost. But don't worry there is plenty to see!!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notfound;
