import { Component } from "react";

const divStyle = {
  textAlign: "center",
};

export default class Progressbar extends Component {
  render() {
    if (this.props.show) {
      return (
        <div style={divStyle}>
          <img
            alt={this.props.alttext}
            src={this.props.imageUrl}
            height={this.props.height}
            width={this.props.width}
            align={this.props.alignment}
          />
        </div>
      );
    }

    return null;
  }
}
