import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getHeaders, logout } from "../../utils/utils";

const useCommunityFeedLoader = (
  doc_id,
  nextFeedsData,
  setFeeds,
  subCategoryId = undefined
) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [category, setCategory] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryChecked, setSubCategoryChecked] = useState([]);
  const [nextLastFeedId, setNextLastFeedId] = useState([]);
  // const [feeds, setFeeds] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [channels, setChannels] = useState([]);
  const getCommunityData = async () => {
    if (nextFeedsData.pageNum === 0) setFeeds([]);
    setLoading(true);
    setError(false);
    const payload = {
      user_id: doc_id,
      tag_id: nextFeedsData.tabId,
      pg_num: nextFeedsData.pageNum,
      sub_category_ids: subCategoryId?.length > 0 ? subCategoryId : undefined,
    };

    const res = await fetch(baseUrl + "v1/app/tag/content", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {
      setLoading(false);
      setError(true);
      setHasMore(false);
      logout(history);
      return;
    });
    const data = await res.json();
    // setCategory(data.data)
    // setFeeds([]);
    if (data.status === "success") {
      if (
        data.status === "success" &&
        data.data === null &&
        data.data == undefined
      ) {
        // setFeeds([]);
        return;
      }
      if (data.data.feed_data == undefined) {
        return;
      }
      // if (nextFeedsData.pageNum == 0) setFeeds([]);
      if (data.data.feed_data)
        if (payload.pg_num === 0) setFeeds([...data.data.feed_data]);
        else
          setFeeds((prevFeeds) => {
            return [...new Set([...prevFeeds, ...data.data.feed_data])];
          });

      if (data.data.listofChannels)
        setChannels((prev) => {
          return [...prev, ...data.data.listofChannels];
        });
      if (data.data.category_ids && category.length === 0)
        setCategory([...data.data.category_ids]);

      setLoading(false);
      if (data.data.feed_data) setHasMore(data.data.feed_data.length > 0);
      else setHasMore(false);
    } else {
      setLoading(false);
      setError(true);
      setHasMore(false);
      logout(history);
      return;
    }
  };

  const getFilteredFeeds = async () => {
    setLoading(true);
    setError(false);

    const payload = {
      pg_num: nextFeedsData.pageNum,
      last_feed_id: nextFeedsData.last_feed_id,
      searchCriteria: {
        userName: "",
        specialist: nextFeedsData.filters.speciality
          ? nextFeedsData.filters.speciality
          : "",
        location: nextFeedsData.filters.location
          ? nextFeedsData.filters.location
          : "",
        workplace: "",
      },
      user_id: doc_id,
      searchIn: "jobs",
      searchText: "",
    };

    const res = await fetch(baseUrl + "v1/job/search", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();

    console.log(data);
    if (data.status === "success") {
      if (payload.pg_num === 0) setFeeds([...data.data.feed_data]);
      else
        setFeeds((prevFeeds) => {
          return [...new Set([...prevFeeds, ...data.data.feed_data])];
        });

      if (data.data.last_feed_id === -1) setHasMore(false);
      setNextLastFeedId(data.data.last_feed_id);
      // setHasMore(data.data.feed_data.length > 0);
    } else {
      setError(true);
      setHasMore(false);
      logout(history);
    }
    setLoading(false);
  };

  const getSubCategories = () => {
    // category.map((cat) => {
    //   getCategory(cat);
    // });
    if (category.length > 0) getCategory(category[0]);
  };

  const getCategory = async (category_id) => {
    const payload = {
      user_id: doc_id,
      category_id,
      pg_num: 0,
    };
    const res = await fetch(baseUrl + "v1/feed/categories/get", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    if (data.status === "success") {
      if (data.data.sub_categories) {
        // if (payload.pg_num === 0)
        //   setCategoryList([...data.data.sub_categories]);
        // else

        setCategoryList((prev) => {
          return [...prev, ...data.data.sub_categories];
        });
      }
    }
  };

  useEffect(() => {
    getSubCategories();
  }, [category]);

  useEffect(() => {
    let categoryLength = categoryList.length;
    let checkedArray = [];
    for (let i = 0; i < categoryLength; i++) checkedArray.push(false);
    setSubCategoryChecked(checkedArray);
  }, [categoryList]);

  useEffect(() => {
    if (nextFeedsData.filters) getFilteredFeeds();
    else getCommunityData();
  }, [nextFeedsData]);

  return {
    loading,
    error,
    channels,
    hasMore,
    categoryList,
    subCategoryChecked,
    nextLastFeedId,
    setSubCategoryChecked,
  };
};

export default useCommunityFeedLoader;
