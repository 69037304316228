import React, { useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
// import PDFViewer from 'pdf-viewer-reactjs'
import {
  getAuthToken,
  config,
  getBrowser,
  getDate,
  getDocId,
  getFeedTypeColor,
  getHeaders,
  getTime,
  getTimeAndDate,
} from "../../utils/utils";
import toast from "react-hot-toast";
import closeIcons from "../../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import CommentsList from "../social-interaction/CommentsList";
import ReactAudioPlayer from "react-audio-player";
import CommentInput from "../social-interaction/CommentInput";
import likeUnselected from "../../assets/dashboard_icons/social-icons/Like.svg";
import likeSelected from "../../assets/dashboard_icons/selected/Like.svg";
import commentUnselected from "../../assets/dashboard_icons/social-icons/Comment.svg";
import shareUnselected from "../../assets/dashboard_icons/social-icons/Share.svg";
import select from "../../assets/Icons/Ellipse 32.svg";
import unselect from "../../assets/Icons/Ellipse 33.svg";
import { Modal } from "react-responsive-modal";
import Likes from "../social-interaction/Likes";
import back from "../../assets/Icons/Icon feather-arrow-left@2x.png";
import audio_placeholder from "../../assets/audio_placeholder.png";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import PDFViewer from 'pdf-viewer-reactjs'
import { MobilePDFReader } from "react-read-pdf";
import Progressbar from "../../progressbar";
import getWindowDimensions from "../../common/hooks/getWindowDimensions";

import TabContext from "@material-ui/lab/TabContext";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import TabList from "@material-ui/lab/TabList";
import { Tabs, Tab, withStyles } from "@material-ui/core";
import TabPanel from "@material-ui/lab/TabPanel";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import Button from "@material-ui/core/Button";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import IconButton from "@material-ui/core/IconButton";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/input";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import CountrySelect from "../../authentication/RegisterComponents/CountrySelect";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import callIcon from "../../assets/sign_up_and_sign_in_icons/Call.svg";
import emailIcon from "../../assets/sign_up_and_sign_in_icons/Mail.svg";
import en from "react-phone-number-input/locale/en.json";
import S3 from "react-aws-s3";
import { parsePhoneNumber } from "react-phone-number-input";
import Select from "react-select";
import ListItemText from "@mui/material/ListItemText";
import { Select as StyledButton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemIcon from "@mui/material/ListItemIcon";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: 0,
      padding: "15px",
      marginBottom: "30px",
      webkitBoxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
      boxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
    },
    "& .MuiTypography-body1": {
      font: "normal normal normal 20px/20px Roboto",
      fontWeight: 400,
    },
    flexGrow: 1,
    backgroundColor: "#f1f1f1",
  },
  tabs: {
    "& .MuiBox-root": {
      padding: "15px",
      marginTop: "10px",
    },

    "& .MuiTabPanel-root": {
      padding: "24px",
      minHeight: "600px",
    },
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: "#2a8f80",
      height: "4px",
    },
    "& .MuiTabPanel-root": {
      padding: "2px",
    },
    "& .MuiTabs-root": {
      marginTop: "-31px",
    },
    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      /* font-size: 11px; */
      font: "13px / 40px Roboto",
    },
    "& .MuiTab-root": {
      minWidth: "60px",
    },

    flexGrow: 1,
    backgroundColor: "#f1f1f1",
  },
}));
const FullViewFeed = ({
  profile_pic,
  feed,
  close,
  addComment,
  updateLikeCount,
  submitSurvey,
  updateViewCount,
  channels = [],
  updateComment,
  deleteComment,
  openCommentMobileView,
  registerWebinar,
  setFeeds = () => {},
  user_verification_info,
}) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const doc_id = getDocId();
  const headers = getHeaders();
  const profile_pic_placeholder = "assets/images/photos/Group 1019.png";
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const [value, setValue] = useState("1");
  const [spinner, setSpinner] = useState(false);
  const classes = useStyles();
  const [jobApplication, setJobApplication] = useState(
    feed.feed_info?.is_applied ? feed.feed_info?.is_applied : false
  );
  const [preferredSpecialitiesData, setPreferredSpecialitiesData] = useState(
    feed.feed_info?.specializations
  );
  const [preferredLocationsData, setPreferredLocationsData] = useState(
    feed.feed_info?.locations
  );
  const [preferredSpecialities, setPreferredSpecialities] = useState([]);
  const [preferredLocations, setPreferredLocations] = useState([]);
  const [displayStatus, setDisplayStatus] = useState(
    feed?.feed_info?.display_status ? feed.feed_info.display_status : false
  );

  const [tabid, setTabid] = useState(1);
  const [appDealineDate, setAppDealineDate] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue: setFormValue,
    trigger,
    formState: { errors },
  } = useForm();
  const [uploadCoverLetter, setUploadCoverLetter] = useState();
  const [userInfo, setUserInfo] = useState([]);
  const cvFile = useRef(null);
  const coverLetter = useRef(null);
  const [applicationType, setApplicationType] = useState(
    feed?.feed_info?.application_type
  );
  const [dataFormat, setDateFormat] = useState();
  const userPhone = localStorage.getItem("phone");
  const phoneNumber = userPhone && parsePhoneNumber(`+${userPhone}`);
  const [country, setCountry] = useState(
    phoneNumber ? phoneNumber.country : "IN"
  );

  const email = localStorage.getItem("email");
  //const experience=feed.feed_info.min_experience;
  const [cities, setCities] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [selectCity, setSelectCity] = useState();
  const [selectSpecilization, setSelectSpecilization] = useState();
  const [yearsOfExperience, setYearsOfExperience] = useState();
  const [cvName, setCvName] = useState(null);
  const [clName, setCLName] = useState(null);
  const DOC_ID = localStorage.getItem("doc_id");

  const browser = getBrowser();
  const history = useHistory();
  const [likesModal, setLikesModal] = useState(false);
  const [isRegisterClicked, setIsRegisterClicked] = useState(false);
  const openLikesModal = () => {
    if (feed.feed_info.socialInteraction.likesCount == 0) {
      return;
    }
    setLikesModal(true);
  };

  const location = useLocation();
  //  const handleClickOpen = (feed) => {
  //   setModalData(feed);
  //   if (feed.feed_info.socialInteraction.likesCount == 0) {
  //     return;
  //   }
  //   setOpen(true);
  // };
  const [feedLabelColor, setFeedLabelColor] = useState("#888686");
  const [surveyAnswer, setSurveyAnswer] = useState([]);

  const closeLikesModal = () => setLikesModal(false);

  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );
  const handleChangeCites = (event, newValue) => {
    setSelectCity(event.target.value);
  };
  const handleChangeSpecialization = (event, newValue) => {
    setSelectSpecilization(event.target.value);
  };
  useEffect(() => {
    let experience = [];
    experience.push({ value: 0, label: "0-1 years" });
    for (let i = 1; i <= 70; i++) {
      experience.push({ value: i, label: `${i} years` });
    }
    setYearsOfExperience(experience);

    autoSuggestions();
    if (!feed.feed_info?.is_applied) applicationDeadline();
    getUserData();
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }

    loadDataOnlyOnce();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const loadDataOnlyOnce = () => {
    console.log("loadDataOnlyOnce");

    if (
      preferredLocationsData != undefined &&
      preferredLocationsData.length >= 2
    ) {
      let labelLocation = {
        location_id: -1,
        location: "Preferred Job Location",
      };
      if (
        preferredLocationsData.filter(
          (x) => x.location_id === labelLocation.location_id
        ).length === 0
      ) {
        preferredLocationsData.splice(0, 0, labelLocation);
      }

      const plocations = [preferredLocationsData[0].location_id];
      setFormValue("plocations", plocations);
      setPreferredLocations(plocations);
      console.log(preferredSpecialities);
    }

    if (
      preferredSpecialitiesData != undefined &&
      preferredSpecialitiesData.length >= 2
    ) {
      let labelSpeciality = {
        specialization_id: -1,
        specialization: "Preferred  Speciality",
      };
      if (
        preferredSpecialitiesData.filter(
          (x) => x.specialization_id === labelSpeciality.specialization_id
        ).length === 0
      ) {
        preferredSpecialitiesData.splice(0, 0, labelSpeciality);
      }

      const pspecialities = [preferredSpecialitiesData[0].specialization_id];
      setFormValue("pspecialities", pspecialities);
      setPreferredSpecialities(pspecialities);
      console.log(preferredSpecialities);
    }
    if (
      preferredLocationsData != undefined &&
      preferredLocationsData.length == 1
    ) {
      const plocations = [preferredLocationsData[0].location_id];
      setFormValue("plocations", plocations);
      setPreferredLocations(plocations);
      console.log(preferredSpecialities);
    }

    if (
      preferredSpecialitiesData != undefined &&
      preferredSpecialitiesData.length == 1
    ) {
      const pspecialities = [preferredSpecialitiesData[0].specialization_id];
      setFormValue("pspecialities", pspecialities);
      setPreferredSpecialities(pspecialities);
      console.log(preferredSpecialities);
    }
  };
  useEffect(() => {
    updateViewCount(feed?.channel_id, feed?.feed_info?.feed_id);
    const feedLabelColor = getFeedTypeColor(feedType);
    setFeedLabelColor(feedLabelColor);
    // getFeedLabelColor();
    getUserData();
    setDisplayImage(
      feed?.feed_info?.feed_type.toUpperCase() === "SURVEY"
        ? feed?.feed_info?.feed_survey.questions[0].attachment_details[0]
        : feed?.feed_info?.attachment_details[0]
    );
    //renderLocations();
    // setPreferredLocations(
    //   feed.feed_info.locations.length == 0 ? '':feed.feed_info.locations
    // );
  }, []);

  const [displayImage, setDisplayImage] = useState(
    feed?.feed_info?.feed_type.toUpperCase() === "SURVEY"
      ? feed?.feed_info?.feed_survey.questions[0].attachment_details[0]
      : feed?.feed_info?.attachment_details[0]
  );

  const getChannel = (channel_id) => {
    const channel = channels.filter(
      (channel) => channel.channel_id === channel_id
    );
    return channel[0];
  };

  const channel = getChannel(feed.channel_id);

  const handleSurvey = (val, is_multi_select, checked) => {
    if (is_multi_select)
      if (checked) setSurveyAnswer((prev) => [...prev, val]);
      else
        setSurveyAnswer((prev) => {
          const index = prev.indexOf(val);
          return prev.splice(index, 1);
        });
    else {
      if (checked) setSurveyAnswer([val]);
      else setSurveyAnswer([""]);
    }
  };
  const userText = (string) => {
    var count = 20;
    if (string)
      return (
        string.charAt(0).toUpperCase() +
        string.slice(1, 20) +
        (string.length > count ? "..." : "")
      );
  };

  const profileUsername = (string) => {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
    var count = 35;
    var result = string.slice(0, count) + (string.length > count ? "..." : "");
  };

  const closeIcon = <img src={closeIcons} />;
  const postingDate = new Date(feed?.feed_info?.posting_time);
  const today = new Date();

  const feedDate = feed?.feed_info?.posting_time
    ? today.toDateString() === postingDate.toDateString()
      ? getTime(postingDate)
      : getDate(postingDate)
    : "";

  const getFeedType = () => {
    return feed?.feed_info?.feed_type.toUpperCase() === "ARTICLE"
      ? feed?.feed_info?.article_type.toUpperCase()
      : feed?.feed_info?.display_tag.toUpperCase();
  };

  const handleChange = (event, newValue) => {
    setTabid(newValue);
    setValue(newValue);
  };

  const feedType = getFeedType();

  const eventLink =
    feed?.feed_info?.event_details?.link.includes("https://") ||
    feed?.feed_info?.event_details?.link.includes("http://")
      ? feed?.feed_info?.event_details?.link
      : `https://${feed?.feed_info?.event_details?.link}`;

  const calcWebinarStatus = () => {
    const currentTime = Date.now();

    if (!feed?.feed_info?.event_details) return "";

    if (feed.feed_info.event_details.has_cancelled) return "cancelled";

    const webinarStartTime = feed.feed_info.event_details.from_date;
    const webinarEndTime = feed.feed_info.event_details.to_date;

    if (currentTime < webinarStartTime) return "register";
    if (currentTime >= webinarStartTime && currentTime < webinarEndTime)
      return "join";
    if (currentTime >= webinarEndTime) return "ended";

    return "";
  };

  const webinarStatus = calcWebinarStatus();

  const handleWebinarRegister = async (e) => {
    const isRegistered = await registerWebinar(
      e,
      feed?.feed_info?.feed_id,
      feed?.feed_info?.event_details.verify_user,
      feed?.feed_info?.title
    );
    setIsRegisterClicked(isRegistered);
  };
  const autoSuggestions = async () => {
    const payload = { cities: "cities", specialities: "specialities" };
    const res = await fetch(baseUrl + "v2/autoSuggestions", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let cities = [];
    let specialities = [];
    if (data.status === "success") {
      for (const city of data.data.cities) {
        cities.push({ value: city, label: city });
      }

      for (const speciality of data.data.specialities) {
        specialities.push({ value: speciality, label: speciality });
      }
    }

    setCities(cities);
    setSpecialities(specialities);
    // console.log("cities data is"+ JSON.stringify(cities));
    // console.log(" specialities data is"+  JSON.stringify(specialities));
  };
  const applicationDeadline = () => {
    // let today = new Date();
    // let test = today.toLocaleString();

    // let deadLineDate = getTimeAndDate(
    //   parseInt(feed.feed_info?.application_deadline)
    // );
    // var currentTimeStamp = Date.parse(new Date());
    let currentTime = Date.now();
    if (currentTime > feed.feed_info?.application_deadline) {
      setAppDealineDate(true);
      setJobApplication(false);
    }
    // if (timeStampToDate > deadLineDate) {
    //   setAppDealineDate(true);
    //   setJobApplication(false);
    // }
  };

  const getUserData = async () => {
    const payload = {
      user_id: doc_id,
    };
    const res = await fetch(baseUrl + "v1/userProfile/view", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    if (data.status === "success") {
      setUserInfo(data.data);
      setDefaultFormValue(data.data);

      // console.log("test"+ userInfo.cnt_num)
    }
  };
  const setDefaultFormValue = (data) => {
    if (feed?.feed_info?.application_type == 2) {
      const phoneNumber = userPhone && parsePhoneNumber(`+${userPhone}`);

      if (phoneNumber) {
        setCountry(phoneNumber.country);
        setFormValue("phone", phoneNumber.number);
      }
      setFormValue("city", {
        label: data.location,
        value: data.location,
      });
      setFormValue("email", data.cnt_email);
      // setFormValue("city", {
      //   label: feed.feed_info.location,
      //   value: feed.feed_info.location,
      // });
      setFormValue("speciality", {
        label: data.specialists,
        value: data.specialists,
      });
      setFormValue("experience", {
        label: `${data.experience} years`,
        value: data.experience,
      });
    }
  };
  const uploadCVFile = async (fileInput) => {
    let file = fileInput?.current?.files[0];
    let newFileName = fileInput?.current?.files[0]?.name;
    let fileType = fileInput?.current?.files[0]?.type;
    let sendFile = newFileName.replaceAll(" ", "");
    const ReactS3Client = new S3(config);
    const data = await ReactS3Client.uploadFile(file, sendFile);
    return data.location;
  };
  const validatePhoneInput = (phone) => {
    let isValid = true;
    if (!phone) {
      isValid = false;
      // error = "This field is required";
    }
    if (!isPossiblePhoneNumber(phone)) {
      isValid = false;
      // error = "Please enter valid number";
    }
    if (!isValidPhoneNumber(phone)) {
      isValid = false;
      // error = "Please enter valid number";
    }

    return isValid;
  };
  const onJobPostHandler = async (formData) => {
    let cvUrl = "";
    let coverLetterUrl = "";
    if (cvFile.current && cvFile.current.files.length > 0) {
      let cvType = cvFile.current.files[0].type;
      if (cvType == "") cvType = getExtension(cvFile.current.files[0].name);
      if (cvType.includes("docx")) cvType = "docx";
      else if (cvType.includes("doc")) cvType = "doc";
      else if (cvType.includes("ppt")) cvType = "ppt";
      else if (cvType.includes("pptx")) cvType = "pptx";
      else if (cvType.includes("pdf")) cvType = "pdf";
      if (
        cvType != "docx" &&
        cvType != "doc" &&
        cvType != "pdf" &&
        cvType != "ppt" &&
        cvType != "pptx"
      ) {
        cvFile.current.value = "";
        setCvName(null);
        toast.error("Please upload doc files only");
        return false;
      }
    }
    if (coverLetter.current && coverLetter.current.files.length > 0) {
      let coverLetterType = coverLetter.current.files[0].type;
      if (coverLetterType == "")
        coverLetterType = getExtension(coverLetter.current.files[0].name);

      if (coverLetterType.includes("docx")) coverLetterType = "docx";
      else if (coverLetterType.includes("doc")) coverLetterType = "doc";
      else if (coverLetterType.includes("ppt")) coverLetterType = "ppt";
      else if (coverLetterType.includes("pptx")) coverLetterType = "pptx";
      else if (coverLetterType.includes("pdf")) coverLetterType = "pdf";
      if (
        coverLetterType != "docx" &&
        coverLetterType != "doc" &&
        coverLetterType != "pdf" &&
        coverLetterType != "ppt" &&
        coverLetterType != "pptx"
      ) {
        coverLetter.current.value = "";
        setCLName(null);
        toast.error("Please upload doc files only");
        return false;
      }
    }
    setloaderSpinner(true);
    if (cvFile.current && cvFile.current.files.length > 0)
      cvUrl = await uploadCVFile(cvFile);

    if (coverLetter.current && coverLetter.current.files.length > 0)
      coverLetterUrl = await uploadCVFile(coverLetter);

    if (feed.feed_info.cv_required) {
      if (cvUrl == "") {
        toast.error("CV Mandatory");
        setloaderSpinner(false);
        return;
      }
    }
    if (feed.feed_info.cover_letter_required) {
      if (coverLetterUrl == "") {
        toast.error("CL Mandatory");
        setloaderSpinner(false);
        return;
      }
    }
    let status = 2;
    if (feed.feed_info.application_type == 2) {
      status = 2;
    } else {
      status = 1;
    }
    let currentTime = Date.now();
    let spec = formData.speciality?.value;
    let payload = {
      user_id: DOC_ID,
      user_email: formData.email?.trim(),
      user_phone: formData.phone?.trim(),
      specialization: spec.toString(),
      location: formData.city?.value,
      applied_on: currentTime,
      clicked_on: currentTime,
      experience: parseInt(formData.experience?.value),
      job_id: feed.feed_info.job_id,
      status: status,
      application_type: feed.feed_info.application_type,
      applicant_cv: cvUrl,
      applicant_cover_letter: coverLetterUrl,
    };
    if (
      feed.feed_info.specializations.length != 0 &&
      feed.feed_info.locations.length != 0
    ) {
      payload.specializations = preferredSpecialities;
      payload.locations = preferredLocations;
    }
    console.log(payload);
    setloaderSpinner(true);
    const res = await fetch(baseUrl + "v2/job/apply", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();
    if (data.status == "success") {
      toast("Application Submitted");
      setJobApplication(true);
      feed.feed_info.is_applied = true;
      // setFeeds((prevFeeds) =>
      //   prevFeeds.map((prevFeed) => {
      //     const newFeed = { ...prevFeed };
      //     if (prevFeed.feed_info.feed_id === feed.feed_info.feed_id)
      //       newFeed.feed_info.is_applied = true;
      //     return newFeed;
      //   })
      // );
      setloaderSpinner(false);
    } else {
      setloaderSpinner(false);
    }
  };

  const getExtension = (fileName) => {
    const lastDot = fileName.lastIndexOf(".");
    const ext = fileName.substring(lastDot + 1);
    return ext;
  };
  const openLink = () => {
    let url = feed.feed_info.application_link;
    let openUrl;
    if (feed.feed_info.application_link.includes("www.")) {
      openUrl = url.replace("www.", "http://");
    }
    if (feed.feed_info.application_link.includes("https://")) {
      openUrl = url.replace("https://", "https://");
    }
    if (feed.feed_info.application_link.includes("http://")) {
      openUrl = url.replace("http://", "http://");
    }

    window.open(openUrl);
  };

  const changeLocations = (event) => {
    const value = event.target.value;
    setFormValue("plocations", value);
    setPreferredLocations(value);
    if (value.indexOf(-1) !== -1) {
      value.splice(0, 1);
    } else if (value.length == 0) {
      value.splice(0, 0, -1);
    }
    const res = trigger("plocations");
    return;
  };
  const changeSpecialities = (event) => {
    const value = event.target.value;
    console.log(preferredSpecialities);
    setFormValue("pspecialities", value);
    setPreferredSpecialities(value);
    if (value.indexOf(-1) !== -1) {
      value.splice(0, 1);
    } else if (value.length == 0) {
      value.splice(0, 0, -1);
    }
    const res = trigger("pspecialities");
    return;
  };
  return (
    <>
      <div>
        <div className="container d-none d-sm-block">
          <div className="row" style={{ padding: "0px", minHeight: "80px" }}>
            <div
              className="col-lg-7 "
              style={{
                borderRight: "1px solid #C1C1C1",
                // height: "475px",
              }}
            >
              {/* <div className="pull-right v1"></div> */}
              <div className="d-flex align-items-center">
                <div
                  className="feed-title"
                  style={{
                    marginTop: "10px",
                    overflowWrap: "break-word",
                    width: "100%",
                    whiteSpace: "pre-line",
                  }}
                >
                  {feed?.feed_info?.title}
                </div>
              </div>
            </div>
            <div className="col-lg-5 ">
              <div
                className="feed-fullview"
                style={{ marginTop: "10px", marginBottom: "5px" }}
              >
                <div className=" d-flex align-items-center">
                  <div className="feed-header" style={{ padding: "0" }}>
                    <img
                      className="feed-header-image"
                      src={
                        channel?.feed_provider_type === "Community" ||
                        channel?.feed_provider_type === "Content"
                          ? channel?.channel_logo === ""
                            ? profile_pic_placeholder
                            : channel?.channel_logo
                          : feed?.post_creator?.profile_url &&
                            feed?.post_creator?.profile_url !== ""
                          ? feed.post_creator.profile_url
                          : profile_pic_placeholder
                      }
                    />
                    <div className="feed-info-container">
                      <div
                        className="feed-poster"
                        style={{
                          marginTop: "5px",
                          font: "normal normal normal 18px/20px Roboto",
                        }}
                      >
                        <Link
                          style={{ color: "black" }}
                          to={
                            channel?.feed_provider_type === "Community" ||
                            channel?.feed_provider_type === "Content"
                              ? "#"
                              : feed?.post_creator?.posted_by
                              ? `/profile/${feed?.post_creator?.doc_id}`
                              : "#"
                          }
                        >
                          {channel?.feed_provider_type === "Community" ||
                          channel?.feed_provider_type === "Content"
                            ? channel?.feed_provider_name
                            : feed?.post_creator?.posted_by
                            ? feed?.post_creator?.doc_id === parseInt(doc_id)
                              ? "You"
                              : userText(feed?.post_creator?.posted_by)
                            : feed?.feed_info?.copyright}
                        </Link>
                      </div>

                      <div style={{ display: "flex ", marginTop: "3px" }}>
                        <div
                          className="dashboard-feed-type"
                          style={{ backgroundColor: feedLabelColor }}
                        >
                          {feedType}
                        </div>
                        <div className="feed-date">{feedDate}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr
          style={{
            border: "1px solid rgba(0,0,0,.1)",
            marginTop: "0em",
            marginBottom: "0em",
          }}
        />
        <div className="container d-none d-lg-block" style={{ height: "85%" }}>
          <div className="row" style={{ height: "100%" }}>
            <div
              className="col-lg-7"
              style={{
                borderRight: "1px solid #C1C1C1",
                height: "100%",
                overflow: "auto",
              }}
            >
              {feed?.feed_info?.feed_type === "Job Postings" && (
                <TabContext
                  value={value}
                  indicatorColor={{ backgroundColor: "green" }}
                >
                  <AppBar
                    className="mL"
                    position="sticky"
                    z-index="1"
                    style={{
                      backgroundColor: "red",
                      color: "black",
                      marginBottom: "15px",
                      top: "30px",
                    }}
                  >
                    {!displayStatus && feed.feed_info?.about_us?.length == 0 && (
                      <div style={{ marginTop: "-31px" }}>
                        <TabList
                          onChange={handleChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="tab example"
                          className={classes.tabs}
                        >
                          <Tab
                            label="About Role"
                            value="1"
                            centered
                            className={classes.tabs}
                          />
                          <Tab
                            className={classes.tabs}
                            label="Apply Now"
                            value="3"
                            centered
                          />
                        </TabList>
                      </div>
                    )}

                    {feed.feed_info?.about_us?.length > 0 && !displayStatus && (
                      <div style={{ marginTop: "-31px" }}>
                        <TabList
                          onChange={handleChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="tab example"
                          className={classes.tabs}
                        >
                          <Tab
                            label="About Role"
                            value="1"
                            centered
                            className={classes.tabs}
                          />
                          <Tab
                            className={classes.tabs}
                            label="Organization"
                            value="2"
                            TabIndicatorProps={{
                              style: { background: "green" },
                            }}
                          />
                          <Tab
                            className={classes.tabs}
                            label="Apply Now"
                            value="3"
                            centered
                          />
                        </TabList>
                      </div>
                    )}

                    {displayStatus && (
                      <div style={{ marginTop: "-31px" }}>
                        <TabList
                          onChange={handleChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="tab example"
                          className={classes.tabs}
                        >
                          <Tab
                            label="About Role"
                            value="1"
                            centered
                            className={classes.tabs}
                          />
                          <Tab
                            className={classes.tabs}
                            label="Organization"
                            value="2"
                            TabIndicatorProps={{
                              style: { background: "green" },
                            }}
                          />
                          <Tab
                            className={classes.tabs}
                            label="Apply Now"
                            value="3"
                            centered
                          />
                        </TabList>
                      </div>
                    )}
                  </AppBar>
                  <TabPanel
                    value="1"
                    className={classes.tabs}
                    style={{ minHeight: "500px" }}
                  >
                    <h2 style={{ color: "black", marginTop: "5px" }}>
                      Job Description
                    </h2>
                    {feed.feed_info.feed_type === "Job Postings" && (
                      <>
                        <div
                          style={{ padding: "0px 0px 0px 10px" }}
                          dangerouslySetInnerHTML={{
                            __html: feed.feed_info.feed_desc,
                          }}
                        ></div>
                      </>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                Title/Position:
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div>
                            <h3 style={{ color: "black", marginTop: "20px" }}>
                              {" "}
                              {feed.feed_info.title}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                Organization:
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                {" "}
                                {feed.feed_info.organization}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                Role:
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                {" "}
                                {feed.feed_info.role_type}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                {feed.feed_info.locations.length == 0
                                  ? "Location:"
                                  : "Locations:"}
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          {feed.feed_info.locations.length == 0 ? (
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                {" "}
                                {feed.feed_info.location}
                              </h3>
                            </div>
                          ) : (
                            <div
                              style={{
                                color: "black",
                                marginTop: "20px",
                                width: "115%",
                                wordBreak: "break-all",
                              }}
                            >
                              {/* {preferredLocationsData1.map(
                                (location, index) => (
                                  <span>
                                    {" "}
                                    {(index ? ", " : "") + location.location}
                                  </span>
                                )
                              )}
                               */}
                              {feed?.feed_info?.locations.map(
                                (location, index) => {
                                  if (location.location_id != -1) {
                                    return (
                                      <span>
                                        {location.location +
                                          (index ==
                                          feed?.feed_info?.locations.length - 1
                                            ? " "
                                            : ", ")}
                                      </span>
                                    );
                                  }
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                Compensation:
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                {" "}
                                {feed.feed_info.compensation_range}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                Application DeadLine:
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                {`${getDate(
                                  parseInt(feed.feed_info.application_deadline)
                                )}`}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h2 style={{ color: "black", marginTop: "20px" }}>
                      <u>Minimum Qualification</u>
                    </h2>
                    <div className="container">
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                Experience
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div>
                            {/* {res.members_count === 1
                            ? res.members_count > 1
                              ? `Members ` + res.members_count
                              : `Member ` + res.members_count
                            : res.members_count === 0
                            ? null
                            : `Members ` + res.members_count} */}

                            {/* {feed.feed_info.max_experience!==0
                            ? feed.feed_info.min_experience > 1 
                              ?  feed.feed_info.min_experience +  ` Year `
                              : feed.feed_info.min_experience  + `-` + feed.feed_info.max_experience + ` Years `
                            : feed.feed_info.min_experience  + `-` +
                            feed.feed_info.max_experience +`Years `
                              } */}

                            {feed.feed_info.max_experience !== 0 &&
                              feed.feed_info.max_experience !== "0" && (
                                <h3
                                  style={{ color: "black", marginTop: "20px" }}
                                >
                                  {feed.feed_info.min_experience}-
                                  {feed.feed_info.max_experience} Years
                                </h3>
                              )}

                            {feed.feed_info.min_experience > 1 &&
                              feed.feed_info.max_experience == 0 && (
                                <h3
                                  style={{ color: "black", marginTop: "20px" }}
                                >
                                  {feed.feed_info.min_experience} Years
                                </h3>
                              )}

                            {feed.feed_info.min_experience <= 1 &&
                              feed.feed_info.max_experience == 0 && (
                                <h3
                                  style={{ color: "black", marginTop: "20px" }}
                                >
                                  {feed.feed_info.min_experience} Year
                                </h3>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div>
                            <h3 style={{ color: "black", marginTop: "20px" }}>
                              {feed.feed_info.specializations.length == 0
                                ? "Specialization"
                                : "Specializations"}
                            </h3>
                          </div>
                        </div>
                        <div className="col-6">
                          {feed.feed_info.specializations.length == 0 ? (
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                {" "}
                                {feed.feed_info.specialization}
                              </h3>
                            </div>
                          ) : (
                            <div
                              style={{
                                color: "black",
                                marginTop: "20px",
                                width: "115%",
                                wordBreak: "break-all",
                              }}
                            >
                              {feed?.feed_info?.specializations.map(
                                (speciality, index) => {
                                  if (speciality.specialization_id != -1) {
                                    return (
                                      <span>
                                        {speciality.specialization +
                                          (index ==
                                          feed?.feed_info?.specializations
                                            .length -
                                            1
                                            ? " "
                                            : ", ")}
                                      </span>
                                    );
                                  }
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <h3 style={{ color: "black", marginTop: "20px" }}>
                                Qualification
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div>
                            <h3 style={{ color: "black", marginTop: "20px" }}>
                              {feed.feed_info.min_qualification}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              {feed.feed_info.focus_areas !== "" && (
                                <h3
                                  style={{ color: "black", marginTop: "20px" }}
                                >
                                  Focus Areas
                                </h3>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div>
                            <h3 style={{ color: "black", marginTop: "20px" }}>
                              {feed.feed_info.focus_areas}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div>
                        {(feed?.feed_info?.other_criteria_1 != "" ||
                          feed?.feed_info?.other_criteria_2 != "" ||
                          feed?.feed_info?.other_criteria_3 != "") && (
                          <h2 style={{ color: "black", marginTop: "5px" }}>
                            <u>Other Requirements</u>
                          </h2>
                        )}
                        <h3
                          style={{
                            color: "black",
                            marginTop: "5px",
                            marginLeft: "15px",
                          }}
                        >
                          {feed?.feed_info?.other_criteria_1}
                        </h3>
                        <h3
                          style={{
                            color: "black",
                            marginTop: "5px",
                            marginLeft: "15px",
                          }}
                        >
                          {feed?.feed_info?.other_criteria_2}
                        </h3>
                        <h3
                          style={{
                            color: "black",
                            marginTop: "5px",
                            marginLeft: "15px",
                          }}
                        >
                          {feed?.feed_info?.other_criteria_3}
                        </h3>
                      </div>
                    </div>
                  </TabPanel>

                  {displayStatus && (
                    <div>
                      <TabPanel
                        value="2"
                        className={classes.tabs}
                        style={{ minHeight: "500px" }}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-12">
                              <div className="about-us">
                                <h3
                                  style={{ color: "black", marginTop: "10px" }}
                                >
                                  {feed.feed_info?.about_us}
                                </h3>
                              </div>
                              <div>
                                <h2
                                  style={{ color: "black", marginTop: "10px" }}
                                >
                                  Contact Details
                                </h2>
                              </div>

                              <div className="contact-us">
                                <h3
                                  style={{ color: "black", marginTop: "10px" }}
                                >
                                  {feed.feed_info.role_contact_email}
                                </h3>
                              </div>
                              <div className="contact-us">
                                <h3
                                  style={{ color: "black", marginTop: "10px" }}
                                >
                                  {feed.feed_info.role_contact_number}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </div>
                  )}

                  {feed.feed_info?.about_us?.length > 0 && !displayStatus && (
                    <div>
                      <TabPanel
                        value="2"
                        className={classes.tabs}
                        style={{ minHeight: "500px" }}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-12">
                              <div>
                                <h3
                                  style={{ color: "black", marginTop: "10px" }}
                                >
                                  {feed.feed_info.about_us}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </div>
                  )}

                  <TabPanel
                    value="3"
                    className={classes.tabs}
                    style={{ minHeight: "500px" }}
                  >
                    <div>
                      {jobApplication && (
                        <h3
                          style={{
                            color: "black",
                            marginTop: "150px",
                            textAlign: "center",
                          }}
                        >
                          Application Submitted
                        </h3>
                      )}
                      {appDealineDate && (
                        <h3
                          style={{
                            color: "black",
                            marginTop: "20px",
                            textAlign: "center",
                          }}
                        >
                          The application deadline has passed
                        </h3>
                      )}
                    </div>
                    {!jobApplication && !appDealineDate && (
                      <div>
                        <form onSubmit={handleSubmit(onJobPostHandler)}>
                          {feed.feed_info?.application_type == 2 && (
                            <div className="container">
                              <div
                                className="row"
                                style={{
                                  marginTop: "1em",
                                  marginBottom: "0em",
                                  background: "#ffffff",
                                  borderRadius: "9px",
                                  height: "3em",
                                  border: "1px solid black",
                                  width: "500px",
                                  marginLeft: "1px",
                                }}
                              >
                                <div
                                  className="col-10"
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <input
                                    style={{
                                      marginTop: "1em",
                                      paddingLeft: "5px",
                                      background: "inherit",
                                      width: "100%",
                                      border: "none",
                                    }}
                                    type="email"
                                    {...register("email", {
                                      required: true,
                                      maxLength: 35,
                                      pattern: {
                                        value:
                                          /^\s*[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}\s*$/i,
                                        message: "invalid email address",
                                      },
                                    })}
                                    placeholder="Enter Email *"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div
                                  className="col-12"
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        marginLeft: "15px",
                                        width: "150px",
                                        marginTop: "15px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <CountrySelect
                                        value={country}
                                        isRegister={false}
                                        onChange={setCountry}
                                        labels={en}
                                      />
                                    </div>
                                    <div>
                                      <Controller
                                        name="phone"
                                        control={control}
                                        rules={{
                                          required: true,
                                          validate: (value) =>
                                            validatePhoneInput(value),
                                        }}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <PhoneInput
                                            country={country}
                                            value={value}
                                            onChange={onChange}
                                            placeholder="Contact Number *"
                                            className="help-mobile"
                                          />
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  marginTop: "1em",
                                  marginBottom: "0em",
                                  background: "#ffffff",
                                  borderRadius: "9px",
                                  height: "3em",
                                  border: "1px solid black",
                                  width: "500px",
                                  marginLeft: "1px",
                                }}
                              >
                                <div
                                  className="col-12"
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <Controller
                                    name="speciality"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        placeholder="Enter Speciality *"
                                        options={specialities}
                                        styles={{
                                          control: (style) => ({
                                            ...style,
                                            background: "inherit",
                                            width: "100%",
                                            border: 0,
                                            boxShadow: "none",
                                          }),
                                          input: (style) => ({
                                            ...style,
                                            paddingLeft: "0px",
                                          }),
                                          container: (style) => ({
                                            ...style,
                                            marginTop: "0.45em",
                                            marginLeft: "-5px",
                                          }),
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              {errors.speciality && (
                                <div className="row error-title">
                                  <span className="error-title-text">
                                    Please enter your speciality
                                  </span>
                                </div>
                              )}
                              <div
                                className="row"
                                style={{
                                  marginTop: "1em",
                                  marginBottom: "0em",
                                  background: "#ffffff",
                                  borderRadius: "9px",
                                  height: "3em",
                                  border: "1px solid black",
                                  width: "500px",
                                  marginLeft: "1px",
                                }}
                              >
                                <div
                                  className="col-12"
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <Controller
                                    name="city"
                                    control={control}
                                    // value={feed.feed_info.specialization}
                                    // {...register("city", {
                                    //   required: true,
                                    // })}

                                    rules={{ required: true }}
                                    render={({ field }) => (
                                      <Select
                                        // value={{label : feed.feed_info.location, value : feed.feed_info.location}}
                                        {...field}
                                        placeholder="City *"
                                        options={cities}
                                        styles={{
                                          control: (style) => ({
                                            ...style,
                                            background: "inherit",
                                            width: "100%",
                                            border: 0,
                                            boxShadow: "none",
                                          }),
                                          input: (style) => ({
                                            ...style,
                                            paddingLeft: "0px",
                                          }),
                                          container: (style) => ({
                                            ...style,
                                            marginTop: "0.45em",
                                            marginLeft: "-5px",
                                          }),
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              {errors.city && (
                                <div className="row error-title">
                                  <span className="error-title-text">
                                    Please enter your city
                                  </span>
                                </div>
                              )}
                              <div
                                className="row"
                                style={{
                                  marginTop: "1em",
                                  marginBottom: "0em",
                                  background: "#ffffff",
                                  borderRadius: "9px",
                                  height: "3em",
                                  border: "1px solid black",
                                  width: "500px",
                                  marginLeft: "1px",
                                }}
                              >
                                <div
                                  className="col-12"
                                  style={{ paddingLeft: "0px" }}
                                >
                                  <Controller
                                    name="experience"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        className="myclass"
                                        classNamePrefix="myclass"
                                        options={yearsOfExperience}
                                        placeholder="Years of Experience *"
                                        styles={{
                                          control: (style) => ({
                                            ...style,
                                            background: "inherit",
                                            width: "100%",
                                            border: 0,
                                            boxShadow: "none",
                                          }),
                                          input: (style) => ({
                                            ...style,
                                            paddingLeft: "0px",
                                          }),
                                          container: (style) => ({
                                            ...style,
                                            marginTop: "0.45em",
                                            marginLeft: "-5px",
                                          }),
                                        }}
                                      />
                                    )}
                                  ></Controller>
                                </div>
                              </div>
                              {errors.experience?.type === "required" && (
                                <div className="row error-title">
                                  <span className="error-title-text">
                                    Please enter your experience
                                  </span>
                                </div>
                              )}
                              {(errors.experience?.type === "max" ||
                                errors.experience?.type === "min") && (
                                <div className="row error-title">
                                  <span className="error-title-text">
                                    Please enter value between 0-70
                                  </span>
                                </div>
                              )}

                              {preferredSpecialitiesData &&
                                preferredSpecialitiesData.length != 0 && (
                                  <section>
                                    <div
                                      style={{
                                        borderRadius: "9px",
                                        width: "100%",
                                        marginTop: "25px",
                                      }}
                                    >
                                      <Controller
                                        name="pspecialities"
                                        control={control}
                                        rules={{
                                          required: true,
                                          validate: (value) => value != -1,
                                        }}
                                        render={({ field }) => (
                                          <FormControl>
                                            {/* <InputLabel
                                              style={{
                                                color: "black",
                                                fontSize: "12px",
                                                marginTop: "25px",
                                              }}
                                              shrink={false}
                                            >
                                              {preferredSpecialities.length ===
                                                0 && "Preferred Speciality"}
                                            </InputLabel> */}
                                            <StyledButton
                                              {...field}
                                              id="demo-multiple-checkbox"
                                              multiple
                                              disabled={
                                                preferredSpecialitiesData.length ==
                                                1
                                                  ? true
                                                  : false
                                              }
                                              value={preferredSpecialities}
                                              onChange={changeSpecialities}
                                              input={<BootstrapInput />}
                                              renderValue={(selected) =>
                                                preferredSpecialitiesData
                                                  .filter((name) =>
                                                    selected.includes(
                                                      name.specialization_id
                                                    )
                                                  )
                                                  .map(
                                                    (record) =>
                                                      record.specialization
                                                  )
                                                  .join(", ")
                                              }
                                              MenuProps={MenuProps}
                                            >
                                              {/* <MenuItem value="" disabled>
                                                <span
                                                  style={{ whiteSpace: "normal",fontSize: "12px" }}
                                                >
                                                  Preferred Speciality
                                                </span>
                                              </MenuItem> */}
                                              {preferredSpecialitiesData.map(
                                                (speciality, i) => {
                                                  if (
                                                    speciality.specialization_id ==
                                                    -1
                                                  ) {
                                                    return (
                                                      <MenuItem
                                                        disabled
                                                        id={i}
                                                        value={
                                                          speciality.specialization_id
                                                        }
                                                        style={{
                                                          backgroundColor:
                                                            "white",
                                                          display: "flex",
                                                          marginLeft: "10px",
                                                          minHeight: "38px",
                                                        }}
                                                        key={i}
                                                      >
                                                        <ListItemText
                                                          primary={
                                                            speciality.specialization
                                                          }
                                                          primaryTypographyProps={{
                                                            style: {
                                                              fontSize: 12,
                                                              whiteSpace:
                                                                "normal",
                                                            },
                                                          }}
                                                        />
                                                      </MenuItem>
                                                    );
                                                  }
                                                }
                                              )}
                                              {preferredSpecialitiesData.map(
                                                (speciality, i) => {
                                                  if (
                                                    speciality.specialization_id !=
                                                    -1
                                                  ) {
                                                    return (
                                                      <MenuItem
                                                        id={i}
                                                        value={
                                                          speciality.specialization_id
                                                        }
                                                        style={{
                                                          backgroundColor:
                                                            "white",
                                                          display: "flex",
                                                          marginLeft: "10px",
                                                          minHeight: "38px",
                                                        }}
                                                        key={i}
                                                      >
                                                        <ListItemText
                                                          primary={
                                                            speciality.specialization
                                                          }
                                                          primaryTypographyProps={{
                                                            style: {
                                                              fontSize: 12,
                                                              whiteSpace:
                                                                "normal",
                                                            },
                                                          }}
                                                        />
                                                        <ListItemIcon>
                                                          <Checkbox
                                                            checked={
                                                              preferredSpecialities.indexOf(
                                                                speciality.specialization_id
                                                              ) > -1
                                                            }
                                                            style={{
                                                              color: "#1e8a79",
                                                              "&.Mui-checked": {
                                                                color:
                                                                  "#1e8a79",
                                                              },
                                                            }}
                                                          />
                                                        </ListItemIcon>
                                                      </MenuItem>
                                                    );
                                                  }
                                                }
                                              )}
                                            </StyledButton>
                                          </FormControl>
                                        )}
                                      />
                                    </div>

                                    {preferredSpecialitiesData &&
                                      preferredSpecialitiesData.length != 0 &&
                                      (errors.pspecialities?.type ===
                                        "required" ||
                                        errors.pspecialities) && (
                                        <div className="row">
                                          <span
                                            className="error-title-css"
                                            style={{
                                              margin:
                                                "35px 0px 0px 22px !important",
                                            }}
                                          >
                                            Please select atleast one preferred
                                            specialisation
                                          </span>
                                        </div>
                                      )}
                                  </section>
                                )}
                              {preferredLocationsData &&
                                preferredLocationsData.length != 0 && (
                                  <section>
                                    <div
                                      style={{
                                        borderRadius: "9px",
                                        width: "100%",
                                        marginTop: "25px",
                                      }}
                                    >
                                      <Controller
                                        name="plocations"
                                        control={control}
                                        rules={{
                                          required: true,
                                          validate: (value) => value != -1,
                                        }}
                                        render={({ field }) => (
                                          <FormControl>
                                            {/* <InputLabel
                                              style={{
                                                color: "black",
                                                fontSize: "12px",
                                                marginTop: "25px",
                                              }}
                                              shrink={false}
                                            >
                                              {preferredLocations.length ===
                                                0 && "Preferred Job Location"}
                                            </InputLabel> */}
                                            <StyledButton
                                              {...field}
                                              id="demo-multiple-checkbox"
                                              multiple
                                              disabled={
                                                preferredLocationsData.length ==
                                                1
                                                  ? true
                                                  : false
                                              }
                                              value={preferredLocations}
                                              onChange={changeLocations}
                                              input={<BootstrapInput />}
                                              renderValue={(selected) =>
                                                preferredLocationsData
                                                  .filter((name) =>
                                                    selected.includes(
                                                      name.location_id
                                                    )
                                                  )
                                                  .map(
                                                    (record) => record.location
                                                  )
                                                  .join(", ")
                                              }
                                              MenuProps={MenuProps}
                                            >
                                              {preferredLocationsData.map(
                                                (location, i) => {
                                                  if (
                                                    location.location_id == -1
                                                  ) {
                                                    return (
                                                      <MenuItem
                                                        disabled
                                                        id={i}
                                                        value={
                                                          location.location_id
                                                        }
                                                        style={{
                                                          backgroundColor:
                                                            "white",
                                                          display: "flex",
                                                          marginLeft: "10px",
                                                          minHeight: "38px",
                                                        }}
                                                        key={i}
                                                      >
                                                        <ListItemText
                                                          primary={
                                                            location.location
                                                          }
                                                          primaryTypographyProps={{
                                                            style: {
                                                              whiteSpace:
                                                                "normal",
                                                              fontSize: 12,
                                                            },
                                                          }}
                                                        />
                                                      </MenuItem>
                                                    );
                                                  }
                                                }
                                              )}
                                              {preferredLocationsData.map(
                                                (location, i) => {
                                                  if (
                                                    location.location_id != -1
                                                  ) {
                                                    return (
                                                      <MenuItem
                                                        id={i}
                                                        value={
                                                          location.location_id
                                                        }
                                                        style={{
                                                          backgroundColor:
                                                            "white",
                                                          display: "flex",
                                                          marginLeft: "10px",
                                                          minHeight: "38px",
                                                        }}
                                                        key={i}
                                                      >
                                                        <ListItemText
                                                          primary={
                                                            location.location
                                                          }
                                                          primaryTypographyProps={{
                                                            style: {
                                                              whiteSpace:
                                                                "normal",
                                                              fontSize: 12,
                                                            },
                                                          }}
                                                        />
                                                        <ListItemIcon>
                                                          <Checkbox
                                                            checked={
                                                              preferredLocations.indexOf(
                                                                location.location_id
                                                              ) > -1
                                                            }
                                                            style={{
                                                              color: "#1e8a79",
                                                              "&.Mui-checked": {
                                                                color:
                                                                  "#1e8a79",
                                                              },
                                                            }}
                                                          />
                                                        </ListItemIcon>
                                                      </MenuItem>
                                                    );
                                                  }
                                                }
                                              )}
                                            </StyledButton>
                                          </FormControl>
                                        )}
                                      />
                                    </div>

                                    {preferredLocationsData &&
                                      preferredLocationsData.length != 0 &&
                                      (errors.plocations?.type === "required" ||
                                        errors.plocations) && (
                                        <div className="row">
                                          <span
                                            className="error-title-css"
                                            style={{
                                              margin:
                                                "35px 0px 0px 22px !important",
                                            }}
                                          >
                                            Please select atleast one preferred
                                            location
                                          </span>
                                        </div>
                                      )}
                                  </section>
                                )}

                              {feed.feed_info.cv_required && (
                                <div
                                  className="row"
                                  style={{
                                    marginLeft: "1px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <div
                                    className="col-6"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <h4
                                      style={{
                                        color: "black",
                                        marginTop: "10px",
                                      }}
                                    >
                                      CV
                                    </h4>
                                  </div>
                                  <div
                                    className="col-6"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <input
                                      id="icon-button-file"
                                      type="file"
                                      name="type"
                                      style={{ display: "none" }}
                                      onChange={() =>
                                        setCvName(cvFile.current.files[0].name)
                                      }
                                      accept=".pdf,.docx,.doc"
                                      ref={cvFile}
                                    />
                                    <label htmlFor="icon-button-file">
                                      <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="span"
                                      >
                                        <AttachFileIcon />
                                        {cvName ? cvName : "Upload CV"}
                                      </IconButton>
                                    </label>
                                  </div>
                                </div>
                              )}

                              {feed.feed_info.cover_letter_required && (
                                <div
                                  className="row"
                                  style={{ marginLeft: "1px" }}
                                >
                                  <div
                                    className="col-6"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <h4
                                      style={{
                                        color: "black",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Coverring Letter
                                    </h4>
                                  </div>
                                  <div
                                    className="col-6"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    <input
                                      id="cv-button-file"
                                      type="file"
                                      name="type"
                                      style={{ display: "none" }}
                                      onChange={() =>
                                        setCLName(
                                          coverLetter.current.files[0].name
                                        )
                                      }
                                      accept=".docx,.pdf,.doc"
                                      ref={coverLetter}
                                    />
                                    <label htmlFor="cv-button-file">
                                      <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="span"
                                      >
                                        <AttachFileIcon />{" "}
                                        {clName ? clName : "Upload CL"}
                                        {/* <AttachFileIcon />{coverLetter ? coverLetter : "Attach File"} */}
                                        {/* <span>Cover Letter</span> */}
                                      </IconButton>
                                    </label>
                                  </div>
                                </div>
                              )}
                              <Progressbar
                                show={loaderSpinner}
                                imageUrl={url}
                                height="90"
                                width="90"
                                alignment="middle"
                                alttext="Loading..."
                              />
                              <div className="button-container">
                                <button
                                  type="submit"
                                  style={{
                                    backgroundColor: "#0FB59B",
                                    width: "300px",
                                    borderRadius: "9px",
                                    textAlign: "center",
                                  }}
                                  className="submit-btn"
                                >
                                  {" "}
                                  Submit Application
                                </button>
                              </div>
                            </div>
                          )}
                          {feed.feed_info.application_type == 3 && (
                            <div className="button-container">
                              <button
                                type="submit"
                                onClick={openLink}
                                style={{
                                  backgroundColor: "#0FB59B",
                                  width: "300px",
                                  borderRadius: "9px",
                                  textAlign: "center",
                                }}
                                className="submit-btn"
                              >
                                {" "}
                                Fill Application
                              </button>
                            </div>
                          )}
                        </form>
                      </div>
                    )}
                    {/* } */}
                    {/* <div>
                      <h2></h2>
                    </div> */}
                  </TabPanel>
                </TabContext>
              )}

              {displayImage && feed?.feed_info?.feed_type !== "Job Postings" && (
                <div
                  style={{
                    display: "flex",
                    minHeight:
                      displayImage.attachment_type === "audio"
                        ? "fit-content"
                        : "25em",
                    maxHeight: "25em",
                    width: "100%",
                    marginBottom: "1em",
                    justifyContent: "center",
                    position: "relative",
                    marginTop: "5px",
                  }}
                >
                  {displayImage.attachment_type === "pdf" && (
                    <>
                      {
                        // browser.name === "Chrome" ? (
                        //   <Document
                        //     file={displayImage.attachment_original_url}
                        //     onLoadSuccess={onDocumentLoadSuccess}
                        //   >
                        //     <Page pageNumber={pageNumber} />
                        //     <p>
                        //       Page {pageNumber} of {numPages}
                        //     </p>
                        //   </Document>
                        // ) :

                        //                         <iframe src={`https://drive.google.com/viewerng/viewer?
                        // url=${displayImage.attachment_original_url}?
                        // pid=explorer&efh=false&a=v&chrome=false&embedded=true`} width="400px" height="300px"  />

                        //  <iframe
                        //     src={displayImage.attachment_original_url}
                        //   style={{ width: "100%" ,height: "400px",border: "1px solid #red",marginBottom:'10px'}} />
                        // <iframe id="iframeContainer" src={`https://drive.google.com/viewerng/viewer?url=${displayImage.attachment_original_url}?pid=explorer&efh=false&a=v&chrome=false&embedded=true`} width="400px" height="300px"  />

                        <object
                          data={
                            displayImage.attachment_original_url.includes(
                              "http://"
                            )
                              ? displayImage.attachment_original_url.replace(
                                  "http",
                                  "https"
                                )
                              : displayImage.attachment_original_url
                          }
                          type="application/pdf"
                          width="100%"
                          height="400"
                        >
                          <iframe
                            src={
                              displayImage.attachment_original_url.includes(
                                "http://"
                              )
                                ? displayImage.attachment_original_url.replace(
                                    "http",
                                    "https"
                                  )
                                : displayImage.attachment_original_ur
                            }
                          >
                            <p>This browser does not support PDF!</p>
                          </iframe>
                        </object>
                        //  <PDFViewer document={{ url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf' }}  />

                        // <MobilePDFReader url={displayImage.attachment_original_url}/>
                      }
                      {/* <p style={{ marginBottom:'20px' }}> 
                       <MobilePDFReader url={displayImage.attachment_original_url} /></p> */}
                      <span
                        style={{
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                          backgroundColor: "#f6f6f6",
                          zIndex: "3",
                          height: "1.5em",
                          width: "8em",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open(
                            displayImage.attachment_original_url,
                            "_blank"
                          )
                        }
                      >
                        Open in new tab{" "}
                      </span>
                    </>
                  )}
                  {displayImage.attachment_type === "image" && (
                    <img
                      src={displayImage.attachment_original_url}
                      style={{ width: "100%" }}
                    />
                  )}
                  {displayImage.attachment_type === "video" && (
                    <video
                      preload="metadata"
                      src={displayImage.attachment_original_url}
                      controls="controls"
                      style={{ width: "100%" }}
                    >
                      <source
                        src={`${displayImage.attachment_original_url}#t=0.1`}
                        type="video/mp4"
                      />
                    </video>
                  )}
                  {displayImage.attachment_type === "audio" && (
                    <ReactAudioPlayer
                      controls
                      src={displayImage.attachment_original_url}
                      style={{ width: "100%" }}
                    />
                  )}
                </div>
              )}
              {feed?.feed_info?.feed_type !== "Job Postings" && (
                <div
                  style={{
                    marginBottom: "2em",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  {feed?.feed_info?.feed_type?.toUpperCase() != "SURVEY" &&
                    feed?.feed_info?.attachment_details.length > 1 &&
                    feed?.feed_info?.attachment_details.map(
                      (attachment, index) => (
                        <div
                          style={{
                            height: "6em",
                            width: "6em",
                            padding: "3px",
                          }}
                          key={index}
                        >
                          <img
                            style={{
                              borderRadius: "5px",
                              maxHeight: "100%",
                              maxWidth: "100%",
                            }}
                            src={
                              attachment.attachment_type === "audio"
                                ? audio_placeholder
                                : attachment.attachment_small_url
                            }
                            onClick={() => setDisplayImage(attachment)}
                          />
                        </div>
                      )
                    )}
                  {feed?.feed_info?.feed_type?.toUpperCase() === "SURVEY" &&
                    feed?.feed_info?.feed_survey?.questions[0]
                      .attachment_details.length > 1 &&
                    feed?.feed_info?.feed_survey?.questions[0].attachment_details.map(
                      (attachment, index) => (
                        <div
                          style={{
                            height: "6em",
                            width: "6em",
                            padding: "3px",
                          }}
                          key={index}
                        >
                          <img
                            style={{
                              borderRadius: "5px",
                              maxHeight: "100%",
                              maxWidth: "100%",
                            }}
                            src={
                              attachment.attachment_type === "audio"
                                ? audio_placeholder
                                : attachment.attachment_small_url
                            }
                            onClick={() => setDisplayImage(attachment)}
                          />
                        </div>
                      )
                    )}
                </div>
              )}
              {feed?.feed_info?.feed_type !== "Job Postings" && (
                <div
                  className="feed-card1"
                  style={{ wordWrap: "break-word", padding: "10px" }}
                >
                  <div style={{ marginBottom: "1em" }}>
                    {feed?.feed_info?.short_desc}
                  </div>
                  {feed?.feed_info?.feed_type.toUpperCase() !== "ARTICLE" &&
                  feed?.feed_info?.feed_type.toUpperCase() !== "SURVEY" &&
                  feed?.feed_info?.feed_type.toUpperCase() !== "EVENT" ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: feed?.feed_info?.feed_desc,
                      }}
                    ></div>
                  ) : null}

                  {feed?.feed_info?.feed_type.toUpperCase() === "EVENT" &&
                    feed?.feed_info?.feed_sub_type === "EOI" && (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: feed?.feed_info?.feed_desc,
                          }}
                        ></div>
                        {!feed?.feed_info?.event_details.has_cancelled &&
                          !feed?.feed_info?.event_details
                            .is_user_registered && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <a
                                href={eventLink}
                                target="_blank"
                                style={{ color: "white" }}
                              >
                                <button
                                  style={{
                                    backgroundColor: "#0FB59B",
                                    borderRadius: "5px",
                                    width: "150px",
                                    height: "3em",
                                    margin: "0.5em",
                                  }}
                                >
                                  I am interested
                                </button>
                              </a>
                            </div>
                          )}
                      </>
                    )}

                  {feed?.feed_info?.feed_type.toUpperCase() === "EVENT" &&
                    (feed?.feed_info?.feed_sub_type === "Webinar" ||
                      feed?.feed_info?.feed_sub_type === "WEBINAR") && (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: feed?.feed_info?.feed_desc,
                          }}
                        ></div>
                        <p>Webinar Date and Time</p>
                        <p>
                          {webinarStatus === "join" && `LIVE`}
                          {webinarStatus === "ended" && `ENDED`}
                          {webinarStatus === "cancelled" && `CANCELLED`}
                        </p>

                        <p>
                          {`${getDate(
                            feed?.feed_info?.event_details.from_date
                          )}  ${getTime(
                            feed?.feed_info?.event_details.from_date
                          )} to
                  ${getTime(feed?.feed_info?.event_details.to_date)}`}
                        </p>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "0.5em",
                          }}
                        >
                          {webinarStatus === "register" &&
                            !isRegisterClicked &&
                            !feed?.feed_info?.event_details
                              .is_user_registered && (
                              <button
                                style={{
                                  backgroundColor: "#0FB59B",
                                  borderRadius: "5px",
                                  width: "150px",
                                  height: "3em",
                                  margin: "0.5em",
                                }}
                                onClick={(e) => {
                                  handleWebinarRegister(e);
                                }}
                              >
                                Register now
                              </button>
                            )}
                          {webinarStatus === "join" && (
                            <a
                              href={eventLink}
                              target="_blank"
                              style={{ color: "white" }}
                            >
                              <button
                                style={{
                                  backgroundColor: "#0FB59B",
                                  borderRadius: "5px",
                                  width: "150px",
                                  height: "3em",
                                  margin: "0.5em",
                                }}
                              >
                                Join
                              </button>
                            </a>
                          )}
                          {webinarStatus === "ended" &&
                            !feed?.feed_info?.event_details.videos_uploaded && (
                              <p>The recorded video will be updated shortly</p>
                            )}
                          {isRegisterClicked && <p>You are registered</p>}
                          {feed?.feed_info?.event_details.is_user_registered &&
                            webinarStatus === "register" &&
                            !isRegisterClicked && <p>You are registered</p>}
                        </div>
                      </>
                    )}

                  {feed?.feed_info?.feed_type.toUpperCase() === "ARTICLE" &&
                  feed?.feed_info?.article_body[0]?.article_desc ? (
                    <>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: feed?.feed_info?.article_body[0].article_desc,
                        }}
                      ></div>
                      {feed?.feed_info?.article_body[0].links.map((link) => (
                        <a href={`https://${link.link_url}`} target="_blank">
                          {link.link_name}
                        </a>
                      ))}
                    </>
                  ) : null}

                  {feed?.feed_info?.feed_type.toUpperCase() === "SURVEY" &&
                    feed?.feed_info?.feed_survey?.questions.map((question) => (
                      <div style={{ marginLeft: "1.5em" }}>
                        <div
                          style={{
                            fontSize: "0.9em",
                            marginBottom: "2em",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: question.description,
                          }}
                        ></div>
                        <div
                          className="text-muted"
                          style={{ fontSize: "0.9em", margin: "5px 0" }}
                        >
                          Survery
                          {question.is_multi_select
                            ? ` (You can select more than one option)`
                            : ` (Select one option)`}
                        </div>
                        <div>
                          {question.options.map((option, key) => (
                            <div
                              style={{
                                border: "1px solid black",
                                borderRadius: "4px",
                                minHeight: "2.5em",
                                marginBottom: "1em",
                                width: "90%",
                                padding: "0.2em",
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                position: "relative",
                                justifyContent:
                                  ((feed.feed_info.feed_survey
                                    .is_participated &&
                                    feed.feed_info.feed_survey
                                      .immediate_results) ||
                                    !feed.feed_info.feed_survey.is_open) &&
                                  "space-between",
                              }}
                              key={key}
                            >
                              {((feed.feed_info.feed_survey.is_participated &&
                                feed.feed_info.feed_survey.immediate_results) ||
                                !feed.feed_info.feed_survey.is_open) && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    backgroundColor: "rgba(34, 139, 34, 0.25)",
                                    height: "100%",
                                    width: `${option.participated_pct}%`,
                                  }}
                                ></div>
                              )}
                              {!(
                                (feed.feed_info.feed_survey.is_participated &&
                                  feed.feed_info.feed_survey
                                    .immediate_results) ||
                                !feed.feed_info.feed_survey.is_open
                              ) && (
                                <div style={{ padding: "0.5em" }}>
                                  {surveyAnswer.indexOf(option.option_id) !==
                                    -1 || option.is_selected ? (
                                    <img
                                      src={select}
                                      onClick={() =>
                                        !feed.feed_info.feed_survey
                                          .is_participated &&
                                        feed.feed_info.feed_survey?.is_open &&
                                        handleSurvey(
                                          option.option_id,
                                          question.is_multi_select,
                                          false
                                        )
                                      }
                                      alt="checkbox"
                                      tabIndex="0"
                                    />
                                  ) : (
                                    <img
                                      src={unselect}
                                      onClick={() =>
                                        !feed.feed_info.feed_survey
                                          .is_participated &&
                                        feed.feed_info.feed_survey?.is_open &&
                                        handleSurvey(
                                          option.option_id,
                                          question.is_multi_select,
                                          true
                                        )
                                      }
                                      alt="checkbox"
                                      tabIndex="0"
                                    />
                                  )}
                                </div>
                              )}
                              <div style={{ padding: "0.5em" }}>
                                {option.option}
                              </div>
                              {((feed.feed_info.feed_survey.is_participated &&
                                feed.feed_info.feed_survey.immediate_results) ||
                                !feed.feed_info.feed_survey.is_open) && (
                                <div
                                  style={{ padding: "0.5em", float: "right" }}
                                >{`${option.participated_pct}%`}</div>
                              )}
                            </div>
                          ))}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {!feed.feed_info.feed_survey.is_participated &&
                            feed.feed_info.feed_survey?.is_open && (
                              <button
                                style={{
                                  backgroundColor: "#0FB59B",
                                  borderRadius: "5px",
                                  width: "40%",
                                  height: "3em",
                                }}
                                className="submit-btn"
                                onClick={() =>
                                  submitSurvey(
                                    feed.feed_info.feed_id,
                                    feed.channel_id,
                                    question.question_id,
                                    surveyAnswer
                                  )
                                }
                              >
                                Submit
                              </button>
                            )}
                        </div>
                        {feed.feed_info.feed_survey.is_participated && (
                          <div
                            className="text-muted"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              // fontSize: "0.9em",
                              margin: "5px 0",
                              fontWeight: "bold",
                              width: "100%",
                            }}
                          >
                            Thanks for participating
                          </div>
                        )}
                        {feed.feed_info.feed_survey.is_participated &&
                          feed.feed_info.feed_survey?.is_open && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "0.9em",
                                marginTop: "1em",
                                width: "100%",
                              }}
                              className="text-muted"
                            >
                              Survey results will be announced once the survey
                              is closed
                            </div>
                          )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "0.9em",
                            marginTop: "1em",
                            width: "100%",
                          }}
                          className="text-muted"
                        >
                          {!feed.feed_info.feed_survey?.is_open
                            ? `Survey closed`
                            : feed.feed_info.feed_survey?.close_time
                            ? `Survey closes on ${getTimeAndDate(
                                feed.feed_info.feed_survey?.close_time
                              )}`
                            : null}
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>

            <div
              className="col-lg-5 "
              style={{ height: "100%", marginTop: "5px" }}
            >
              {/* <Progressbar
                show={loaderSpinner}
                imageUrl={url}
                height="90"
                width="90"
                alignment="middle"
                alttext="Loading..."
              /> */}
              <div className="profile-verification">
                <div className="profile_full">
                  <span>
                    Comments
                    <span style={{ color: "green" }}>
                      ({feed?.feed_info?.socialInteraction?.commentsCount}{" "}
                      Comments)
                    </span>
                  </span>
                </div>
                <div className="profile_full">
                  <span>
                    {feed?.feed_info?.socialInteraction?.viewCount} Views
                  </span>
                </div>
              </div>
              <div style={{ height: "85%" }}>
                <CommentsList
                  feed={feed}
                  updateComment={updateComment}
                  deleteComment={deleteComment}
                />
              </div>

              <div style={{ width: "100%", marginBottom: "20px" }}>
                <div className="social-interaction" style={{ padding: "0" }}>
                  <div className="likeButton" style={{ padding: "5px" }}>
                    {feed?.feed_info?.socialInteraction?.isLike ? (
                      <img
                        src={likeSelected}
                        onClick={() =>
                          updateLikeCount(
                            feed.feed_info.feed_id,
                            feed.channel_id,
                            false
                          )
                        }
                        className="social-icons-mobile"
                      />
                    ) : (
                      <img
                        onClick={() =>
                          updateLikeCount(
                            feed.feed_info.feed_id,
                            feed.channel_id,
                            true
                          )
                        }
                        src={likeUnselected}
                        className="social-icons-mobile"
                      />
                    )}
                    <span
                      onClick={() => {
                        if (windowDimensions.width <= 768)
                          history.push({
                            pathname: `/likes/${feed.feed_info.feed_id}/${feed.channel_id}`,
                            state: { background: location },
                          });
                        else openLikesModal(feed);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {feed?.feed_info?.socialInteraction?.likesCount === 0
                        ? null
                        : feed?.feed_info?.socialInteraction?.likesCount}{" "}
                      Like
                      {feed?.feed_info?.socialInteraction?.likesCount > 1
                        ? "s"
                        : null}
                    </span>

                    <Modal
                      open={likesModal}
                      onClose={closeLikesModal}
                      closeIcon={closeIcon}
                      classNames="feedModel"
                    >
                      <Likes feed={feed}></Likes>
                    </Modal>
                  </div>
                </div>
                <div style={{ marginTop: "50px" }}></div>
                <div
                  style={{
                    width: "100%",
                    position: "fixed",
                    bottom: "5px",
                    width: "428px",
                  }}
                >
                  <CommentInput
                    isFullFeedView={true}
                    addComment={addComment}
                    feed_id={feed?.feed_info?.feed_id}
                    channel_id={feed?.channel_id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 9,
    //    height: "35px",
    marginTop: "0em",
    marginBottom: "0em",
    background: "#ffffff",
    borderRadius: "9px",
    border: "1px solid black",
    width: "450px",
    marginLeft: "1px",
    padding: "15px",
    fontSize: "12px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default FullViewFeed;
