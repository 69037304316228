import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import { useState } from "react";

import toast, { Toaster } from "react-hot-toast";
import Login from "./authentication/Login";
import Dashboard from "./dashboard/Dashboard";
import Signup from "./authentication/Signup";
import ForgotPassword from "./authentication/ForgotPassword";
import Timeline from "./profile/Timeline.js";
import { getClientIp, getIsLoggedIn } from "./utils/utils";
import Notfound from "./common/Notfound";
import { useEffect } from "react";
import HealthCheck from "./common/HealthCheck";
import Likes from "./dashboard/social-interaction/Likes";
import MobileComments from "./dashboard/social-interaction/MobileComments";
import MobileFullViewFeed from "./dashboard/social-interaction/MobileFullViewFeed";
import Professional from "./professional/Professional";
import Knowledge from "./knowledge/Knowledge";
import Community from "./community/Community";
import MobilePublications from "./profile/MobilePublications.js";
import MobileMembership from "./profile/MobileMembership.js";
import MobileAwards from "./profile/MobileAwards.js";
import MobileEvents from "./profile/MobileEvents.js";
import CommunityPosts from "./community/CommunityPosts";
import CategoryList from "./discoveryCategory/SubCategoryList";
import MobileCreatepost from "./createPost/MobileCreatepost.js";
import MobileContactDetailsForm from "./profile/MobileContactDetailsForm";
import EditPersonalInfo from "./profile/EditPersonalDeatils/EditPersonalInfo";
import EditPersonalMobileView from "./profile/EditPersonalDeatils/EditPersonalMobileView";
import MobilePersonal from "./profile/EditPersonalDeatils/MobilePersonal";
import MobileQualifications from "./profile/MobileQualifications";
import MobileAreaOfInterests from "./profile/MobileAreaOfInterests";
import MobileAvailableAt from "./profile/AvailbaleAt/MobileAvailableAt";
import Search from "./search/Search";

// import Test from "./profile/Publications1";
const requireLogin = (to, from, next) => {
  // if (to.match.path == "/signup/professionalInfo") {
  //   const isRegistrationComplete = localStorage.getItem(
  //     "isRegistrationComplete"
  //   );
  //   if (isRegistrationComplete === "false") next();
  //   else next.redirect("/");
  //   return;
  // }

  if (
    (to.match.path === "/login" ||
      to.match.path === "/forgotPassword" ||
      to.match.path === "/signup") &&
    to.meta.auth
  ) {
    // if (localStorage.getItem("isRegistrationComplete") === "false") next();
    if (getIsLoggedIn()) next.redirect("/");
    else next();

    return;
  }

  if (to.meta.auth) {
    // if (localStorage.getItem("isRegistrationComplete") === "false")
    //   next.redirect("signup/professionalInfo");
    if (getIsLoggedIn()) next();
    else next.redirect("/login");
    return;
  }

  next();
};

function App() {
  const [dashboardFeeds, setDashboardFeeds] = useState([]);
  const [profileFeeds, setProfileFeeds] = useState([]);
  const [knowledgeFeeds, setKnowledgeFeeds] = useState([]);
  const [communityFeeds, setCommunityFeeds] = useState([]);
  const [professionalFeeds, setProfessionalFeeds] = useState([]);

  const routes = [
    // {
    //   path: "/",
    //   component: <Dashboard feeds={feeds} setFeeds={setFeeds} />,
    //   authentication: { auth: true },
    // },
    {
      path: "/login",
      component: Login,
      authentication: { auth: true },
    },
    {
      path: "/signup",
      component: Signup,
      authentication: { auth: true },
    },
    // {
    //   path: "/signup/professionalInfo",
    //   component: MandatoryInfoScreen,
    //   authentication: { auth: true },
    // },
    {
      path: "/forgotPassword",
      component: ForgotPassword,
      authentication: { auth: true },
    },

    {
      path: "/help",
      component: Timeline,
      authentication: { auth: true },
    },
    {
      path: "/health",
      component: HealthCheck,
      authentication: { auth: false },
    },
    // {
    //   path: "/updateProfile",
    //   component: EditPersonalInfo,
    //   authentication: { auth: false },
    // },
    // {
    //   path: "/category/:id",
    //   component:CategoryList ,
    //   authentication: { auth: true },
    // },
    // {
    //   path: "/subCategory",
    //   component:{CategoryList} ,
    //   authentication: { auth: true },
    // },
    // {
    //   path: "/disributionCategory",
    //   component:{CategoryList} ,
    //   authentication: { auth: true },
    // },
  ];

  const routeComponents = routes.map(
    ({ path, component, authentication }, key) => (
      <GuardedRoute
        path={path}
        exact
        component={component}
        meta={authentication}
        key={key}
      />
    )
  );

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    (async () => {
      const IP_ADDRESS = await getClientIp();
      localStorage.setItem("ip_address", IP_ADDRESS);
    })();

    window.addEventListener(
      "online",
      function (e) {
        toast.success("Back online!");
      },
      false
    );

    window.addEventListener(
      "offline",
      function (e) {
        toast.error("You are offline now");
      },
      false
    );
  }, []);

  return (
    <>
      <Router>
        {/* <ScrollToTop /> */}
        <GuardProvider
          guards={[requireLogin]}
          // loading={Loading}
          error={Notfound}
        >
          <ModalSwitch
            routeComponents={routeComponents}
            dashboardFeeds={dashboardFeeds}
            setDashboardFeeds={setDashboardFeeds}
            profileFeeds={profileFeeds}
            setProfileFeeds={setProfileFeeds}
            knowledgeFeeds={knowledgeFeeds}
            setKnowledgeFeeds={setKnowledgeFeeds}
            communityFeeds={communityFeeds}
            setCommunityFeeds={setCommunityFeeds}
            professionalFeeds={professionalFeeds}
            setProfessionalFeeds={setProfessionalFeeds}
          />
        </GuardProvider>
      </Router>
      <Toaster />
    </>
  );
}

function ModalSwitch({
  routeComponents,
  dashboardFeeds,
  setDashboardFeeds,
  profileFeeds,
  setProfileFeeds,
  knowledgeFeeds,
  setKnowledgeFeeds,
  communityFeeds,
  setCommunityFeeds,
  professionalFeeds,
  setProfessionalFeeds,
}) {
  let location = useLocation();

  let background = location.state && location.state.background;
  let profile_pic = location.state && location.state.profile_pic;
  let channels = location.state && location.state.channels;
  let user_verification_info =
    location.state && location.state.user_verification_info;
  let feedContext = location.state && location.state.feedContext;
  let defaultChannel = location.state && location.state.selectedChannel;
  let userInfo = location.state && location.state.userInfo;
  // let is_profile = location.state && location.state.is_profile;

  let feeds = dashboardFeeds;
  let setFeeds = setDashboardFeeds;

  if (feedContext === "profile") {
    feeds = profileFeeds;
    setFeeds = setProfileFeeds;
  } else if (feedContext === "knowledge") {
    feeds = knowledgeFeeds;
    setFeeds = setKnowledgeFeeds;
  } else if (feedContext === "community") {
    feeds = communityFeeds;
    setFeeds = setCommunityFeeds;
  } else if (feedContext === "professional") {
    feeds = professionalFeeds;
    setFeeds = setProfessionalFeeds;
  } else {
    feeds = dashboardFeeds;
    setFeeds = setDashboardFeeds;
  }

  // let feeds = is_profile ? profileFeeds : dashboardFeeds;
  // let setFeeds = is_profile ? setProfileFeeds : setDashboardFeeds;

  return (
    <>
      <Switch location={background || location}>
        {routeComponents}
        <GuardedRoute
          path="/"
          exact
          meta={{ auth: true }}
          render={(props) => (
            <Dashboard
              {...props}
              feeds={dashboardFeeds}
              setFeeds={setDashboardFeeds}
            />
          )}
        />
        <GuardedRoute
          path={[
            "/feed/:feed_id/:channel_id",
            "/likes/:feed_id/:channel_id",
            "/comments/:feed_id/:channel_id",
          ]}
          exact
          meta={{ auth: true }}
          render={() => <Redirect to="/" />}
        />
        <GuardedRoute
          path="/dashboard"
          exact
          meta={{ auth: true }}
          render={(props) => (
            <Dashboard
              {...props}
              feeds={dashboardFeeds}
              setFeeds={setDashboardFeeds}
            />
          )}
        />

        <GuardedRoute
          path="/knowledge/:tabId?/:Id?"
          exact
          meta={{ auth: true }}
          render={(props) => (
            <Knowledge
              {...props}
              feeds={knowledgeFeeds}
              setFeeds={setKnowledgeFeeds}
            />
          )}
        />
        <GuardedRoute
          path="/community/:tabId?/:Id?"
          exact
          meta={{ auth: true }}
          render={(props) => (
            <Community
              {...props}
              feeds={communityFeeds}
              setFeeds={setCommunityFeeds}
            />
          )}
        />
        {/* <GuardedRoute
          path="/community"
          exact
          meta={{ auth: true }}
          render={(props) => (
            <CommunityPosts {...props} feeds={communityFeeds} setFeeds={setCommunityFeeds} />
          )}
        /> */}
        <GuardedRoute
          path="/professional/:tabId?/:Id?"
          exact
          meta={{ auth: true }}
          render={(props) => (
            <Professional
              {...props}
              feeds={professionalFeeds}
              setFeeds={setProfessionalFeeds}
            />
          )}
        />

        <GuardedRoute
          path={["/profile/:id?", "/help"]}
          exact
          meta={{ auth: true }}
          render={(props) => (
            <Timeline
              {...props}
              feeds={profileFeeds}
              setFeeds={setProfileFeeds}
            />
          )}
        />
        <GuardedRoute
          meta={{ auth: true }}
          path="/membership/:user"
          children={<MobileMembership />}
        />
        <GuardedRoute
          meta={{ auth: true }}
          path="/personal/:user_id"
          children={<MobilePersonal />}
        />
        <GuardedRoute
          meta={{ auth: true }}
          path="/awards/:user"
          children={<MobileAwards />}
        />
        <GuardedRoute
          meta={{ auth: true }}
          path="/events/:user"
          children={<MobileEvents />}
        />
        <GuardedRoute
          path="/edit/contactDetails"
          meta={{ auth: true }}
          children={<MobileContactDetailsForm userInfo={userInfo} />}
        />
        <GuardedRoute
          path="/publication/:user"
          meta={{ auth: true }}
          children={<MobilePublications />}
        />
        <GuardedRoute
          path="/AvailableAt/:user"
          meta={{ auth: true }}
          children={<MobileAvailableAt />}
        />
        <GuardedRoute
          path="/qualifications"
          meta={{ auth: true }}
          children={<MobileQualifications />}
        />
        <GuardedRoute
          path="/interests/:id?"
          meta={{ auth: true }}
          children={<MobileAreaOfInterests />}
        />
        <GuardedRoute
          path="/search/:query?"
          meta={{ auth: true }}
          children={<Search />}
        />

        <GuardedRoute path="*" component={Notfound} />
      </Switch>
      {background && (
        <Switch>
          <GuardedRoute
            meta={{ auth: true }}
            path="/likes/:feed_id/:channel_id"
            children={<Likes />}
          />
          <GuardedRoute
            meta={{ auth: true }}
            path="/post/:type"
            children={
              <MobileCreatepost
                defaultChannel={defaultChannel}
                background={background}
                profile_pic={profile_pic}
                user_verification_info={user_verification_info}
              />
            }
          />

          {/* <GuardedRoute
            meta={{ auth: true }}
            path="updateProfile"
            children={
              <Test
                defaultChannel={defaultChannel}
                background={background}
                profile_pic={profile_pic}
                user_verification_info={user_verification_info}
              />
            }
          /> */}

          <GuardedRoute
            path="/comments/:feed_id/:channel_id"
            meta={{ auth: true }}
            children={
              <MobileComments
                profile_pic={profile_pic}
                feeds={feeds}
                setFeeds={setFeeds}
                user_verification_info={user_verification_info}
              />
            }
          />
          <GuardedRoute
            path="/feed/:feed_id/:channel_id"
            meta={{ auth: true }}
            children={
              <MobileFullViewFeed
                profile_pic={profile_pic}
                feeds={feeds}
                setFeeds={setFeeds}
                channels={channels}
                user_verification_info={user_verification_info}
                feedContext={feedContext}
                background={background}
              />
            }
          />
        </Switch>
      )}
    </>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
