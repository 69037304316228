import { useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { useForm, Controller, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import closeImage from "../assets/Web Icons/Group 2831.svg";
import plusImage from "../assets/Web Icons/Icon feather-plus.svg";
import deleteIcon from "../assets/Delete-1X.png";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ReactQuill from "react-quill";
import ListSubheader from "@mui/material/ListSubheader";
import InputBase from "@mui/material/InputBase";
// import editImage from "../assets/Web Icons/Icon material-edit.svg";
import { MdEdit } from "react-icons/md";
import pdfImage from "../assets/Web Icons/Icon metro-file-pdf.svg";
import playImage from "../assets/Web Icons/Icon feather-play-circle.svg";
import InputTextLimit from "./InputTextLimit";
import Progressbar from "../progressbar";

const CreatePostModal = ({
  modalTitle,
  specialities,
  selectedSpec,
  handleSpecial,
  renderSpecValue,
  isAllSelectedSpec,
  specialitiesValue,
  channels,
  channelsData,
  selectedChannel,
  handleShare,
  selectedShare,
  handlePostModalClose,
  departments,
  departmentValue,
  selected,
  isAllSelected,
  handleChange,
  renderPostValue,
  fileInput,
  showImagePreview,
  postAttachments,
  editFile,
  showPreview,
  removeFile,
  attachError,
  sizeError,
  postSubmit,
  handleSubmit,
  control,
  errors,
  quilChange,
  loaderSpinner,
}) => {
  //   const {
  //     handleSubmit,
  //     register,
  //     control,
  //     setValue,
  //     reset,
  //     formState: { errors },
  //   } = useForm();
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";

  const classes = useStyles();
  //   const [quilContent, setQuilContent] = useState("");

  //   const quilChange = (content, delta, source, editor) => {
  //     setQuilContent(editor.getHTML());
  //     console.log(editor.getHTML()); // rich text
  //     console.log(editor.getText()); // plain text
  //     console.log(editor.getLength()); // number of characters
  //   };

  const title = useWatch({
    control,
    name: "title",
  });

  const question = useWatch({
    control,
    name: "question",
  });

  const getHelpTextHeader = () => {
    if (modalTitle === "Ask A Question")
      return "Get answers/tips from your peers from the medical community";
    else if (modalTitle === "Share News/Announcements")
      return "Share latest/useful developments in medicine or your work";
    else if (modalTitle === "Post A Case")
      return "Share and discuss interesting medical cases";
    else return "";
  };

  const getHelpText = () => {
    if (modalTitle === "Ask A Question") return questionHelpText;
    else if (modalTitle === "Share News/Announcements") return newsHelpText;
    else if (modalTitle === "Post A Case") return postHelpText;
    else return "";
  };

  const displayCommunitySubHeader = () => {
    let count = 0;
    channelsData.map((channel) => {
      if (channel.is_admin && count === 0) {
        count++;
      }
    });
    if (count > 0)
      return (
        <ListSubheader
          style={{
            margin: "5px",
            borderRadius: "8px",
            font: "bold 14px/20px roboto",
            padding: "5px 5px 2px 10px",
          }}
        >
          <ListItemText>
            <span className="menu-head">Share with a Group</span>
            <br></br>
            <span className="sub-head">
              Visible only to your community group members such as association
              members
            </span>
          </ListItemText>
        </ListSubheader>
      );
    else return null;
  };

  const renderShareWith = (option) => {
    if (option == null) return <span>select</span>;

    return (
      <ListItemText classes={{ root: "remove-margin" }}>
        <span className="menu-head">{option.feed_provider_name}</span>
      </ListItemText>
    );
  };

  return (
    <div className="post-modal">
      {/* <form onSubmit={handleSubmit(bruh)}> */}
      <div className="post-modal-header">
        <span>{modalTitle}</span>
        <IoIosCloseCircle
          style={{ cursor: "pointer" }}
          onClick={() => handlePostModalClose()}
          size={32}
        />
      </div>

      <div className="post-modal-body">
        <div className="post-modal-help-text-container">
          <div className="post-modal-help-text-header">
            {getHelpTextHeader()}
          </div>
          <div className="post-modal-help-text">{getHelpText()}</div>
        </div>

        <div className="post-modal-form">
          <form>
            <div className="post-modal-form-field">
              {modalTitle != "Ask A Question" && (
                <div>
                  <Controller
                    name="title"
                    control={control}
                    rules={{ required: true, maxLength: 500 }}
                    render={({ field }) => (
                      // <CssTextField
                      //   {...field}
                      //   style={{
                      //     borderRadius: "9px",
                      //   }}
                      //   InputProps={{
                      //     className: classes.input,
                      //   }}
                      //   id="outlined-basic"
                      //   label="Title"
                      //   variant="outlined"
                      //   fullWidth
                      //   size="small"
                      // />
                      <CssTextField
                        {...field}
                        fullWidth
                        label="Title"
                        InputLabelProps={{
                          style: {
                            color: title
                              ? title.length === 0
                                ? "rgb(0, 0, 0, 0.6)"
                                : "#469b8d"
                              : "rgb(0, 0, 0, 0.6)",
                            fontSize: 14,
                          },
                        }}
                        variant="outlined"
                        size="small"
                        multiline
                        id="outlined-basic"
                        rows={2}
                        inputProps={{
                          style: {
                            fontSize: 14,
                            height: "18px",
                            padding: "5px",
                          },
                          maxLength: 500,
                        }}
                      />
                    )}
                  />
                  <InputTextLimit control={control} name={"title"} />
                  {errors.title?.type === "required" && (
                    <div className="row">
                      <span
                        className="error-title-css"
                        style={{
                          margin: "5px 0px 0px 22px !important",
                        }}
                      >
                        Please enter your title
                      </span>
                    </div>
                  )}
                  {errors.title?.type === "maxLength" && (
                    <div className="row">
                      <span
                        className="error-title-css"
                        style={{
                          margin: "5px 0px 0px 22px !important",
                        }}
                      >
                        Title max length is 500
                      </span>
                    </div>
                  )}
                </div>
              )}

              {modalTitle === "Ask A Question" && (
                <div>
                  <Controller
                    name="question"
                    control={control}
                    rules={{ required: true, maxLength: 500 }}
                    render={({ field }) => (
                      // <CssTextField
                      //   {...field}
                      //   style={{
                      //     borderRadius: "9px",
                      //   }}
                      //   InputProps={{
                      //     className: classes.input,
                      //   }}
                      //   id="outlined-basic"
                      //   label="Type Your Question"
                      //   variant="outlined"
                      //   fullWidth
                      //   size="small"
                      // />
                      <CssTextField
                        {...field}
                        fullWidth
                        label="Type Your Question"
                        variant="outlined"
                        InputLabelProps={{
                          style: {
                            color: question
                              ? question.length === 0
                                ? "rgb(0, 0, 0, 0.6)"
                                : "#469b8d"
                              : "rgb(0, 0, 0, 0.6)",
                            fontSize: 11,
                          },
                        }}
                        size="small"
                        multiline
                        id="outlined-basic"
                        rows={2}
                        inputProps={{
                          style: {
                            fontSize: 15,
                            height: "18px",
                            padding: "5px",
                          },
                          maxLength: 500,
                        }}
                      />
                    )}
                  />
                  <InputTextLimit control={control} name={"question"} />
                  {errors.question?.type === "required" && (
                    <div className="row">
                      <span
                        className="error-title-css"
                        style={{
                          margin: "5px 0px 0px 22px !important",
                        }}
                      >
                        Please enter your question
                      </span>
                    </div>
                  )}
                  {errors.question?.type === "maxLength" && (
                    <div className="row">
                      <span
                        className="error-title-css"
                        style={{
                          margin: "5px 0px 0px 22px !important",
                        }}
                      >
                        Ask a question max length is 500
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="post-modal-form-field">
              {modalTitle != "Ask A Question" && (
                <section>
                  <div
                    style={{
                      margin: "0 0 1.5em 0",
                    }}
                  >
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <ReactQuill
                          placeholder="Description (optional)"
                          theme="snow"
                          onChange={quilChange}
                          modules={modules}
                          formats={formats}
                        />
                      )}
                    />
                  </div>
                </section>
              )}
            </div>
            <div className="post-modal-attachments">
              <div style={{ color: "#5d5d5d", fontSize: "0.9em" }}>
                Attach Image/PDF/Video/Audio
              </div>
              <div className="post-modal-help-text" style={{ margin: 0 }}>
                Max 5 Attachments
              </div>

              <div className="post-modal-file-container">
                {postAttachments.length <= 5 &&
                  postAttachments.map((attach, index) => (
                    <div className="post-modal-file">
                      <div className="post-modal-file-delete">
                        <img
                          style={{
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",
                          }}
                          src={deleteIcon}
                          onClick={(e) => removeFile(e, index)}
                        />
                      </div>
                      <img
                        style={{
                          height: " 150px",
                          width: "150px",
                          borderRadius: "5px",
                        }}
                        src={
                          attach.attachment_type == "image"
                            ? attach.attachment_original_url
                            : attach.attachment_type == "video" ||
                              attach.attachment_type == "audio"
                            ? playImage
                            : attach.attachment_type == "pdf"
                            ? pdfImage
                            : attach.attachment_original_url
                        }
                        alt="post"
                        onClick={(e) => {
                          e.preventDefault();
                          showPreview(attach);
                        }}
                      />
                      <div>
                        <label
                          htmlFor={`file-upload-${index}`}
                          style={{ cursor: "pointer", margin: "0" }}
                        >
                          <div className="post-modal-file-edit">
                            <MdEdit size={16} />
                            Edit
                          </div>
                        </label>
                        <input
                          id={`file-upload-${index}`}
                          type="file"
                          onChange={(e) => editFile(e, index)}
                          style={{
                            display: "none",
                          }}
                          className="submit-btn"
                          accept=".pdf, image/* ,audio/* ,video/*"
                        />
                      </div>
                    </div>
                  ))}

                {postAttachments.length != 5 && (
                  <div className="post-modal-file-upload">
                    <div>
                      <img
                        style={{
                          width: "40px",
                          height: "40px",
                          cursor: "pointer",
                        }}
                        src={plusImage}
                        alt="knowledge"
                        onClick={(e) => {
                          e.preventDefault();
                          fileInput.current.click();
                        }}
                      />
                      <input
                        type="file"
                        ref={fileInput}
                        onChange={() => showImagePreview()}
                        style={{
                          display: "none",
                        }}
                        className="submit-btn"
                        accept=".pdf, image/* ,audio/* ,video/*"
                      />
                    </div>
                  </div>
                )}
              </div>
              {attachError && (
                <div style={{ color: "red" }}>
                  {" "}
                  Please upload image,pdf,video or audio files only
                </div>
              )}
              {sizeError && (
                <div style={{ color: "red" }}>
                  {" "}
                  File size too Big, please select a file less than 50MB
                </div>
              )}
            </div>
            <div
              className="post-modal-form-field"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                // gridTemplateColumns: "minmax(0 , 1fr)  minmax(0,1fr)",
                columnGap: "1em",
              }}
            >
              <div>
                {specialities && specialities.length !== 0 && (
                  <div
                    className="post-modal-help-text"
                    style={{
                      textAlign: "right",
                      margin: "0.3em 0",
                      fontSize: "0.6em",
                    }}
                  >
                    Where do you wish to publish this post?
                  </div>
                )}
                {specialities && specialities.length !== 0 && (
                  <Controller
                    name="special"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={{
                            color:
                              selectedSpec.length === 0
                                ? "rgb(0, 0, 0 , 0.6)"
                                : "#469b8d",
                          }}
                          id="demo-multiple-checkbox-label"
                        >
                          Post On
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={selectedSpec}
                          onChange={handleSpecial}
                          input={<OutlinedInput label="Post On" />}
                          renderValue={renderSpecValue}
                          MenuProps={MenuProps}
                          className={classes.selectBorder}
                        >
                          <MenuItem
                            value="all"
                            classes={{
                              root: isAllSelectedSpec
                                ? classes.selectedAll
                                : "",
                            }}
                            style={{
                              display: "flex",
                              minHeight: "38px",
                              marginLeft: "10px",
                              backgroundColor: "white",
                            }}
                          >
                            <ListItemText
                              classes={{
                                primary: classes.selectAllText,
                              }}
                              primary="All Specialities"
                              primaryTypographyProps={{
                                style: { whiteSpace: "normal", fontSize: 12 },
                              }}
                            />

                            <ListItemIcon>
                              <Checkbox
                                style={{
                                  color: "#1e8a79",
                                  "&.Mui-checked": {
                                    color: "#1e8a79",
                                  },
                                }}
                                classes={{
                                  indeterminate: classes.indeterminateColor,
                                }}
                                checked={isAllSelectedSpec}
                              />
                            </ListItemIcon>
                          </MenuItem>
                          {specialitiesValue.map((option, i) => (
                            <MenuItem
                              id={i}
                              value={option}
                              style={{
                                backgroundColor: "white",
                                display: "flex",
                                marginLeft: "10px",
                                minHeight: "38px",
                              }}
                              key={i}
                            >
                              <ListItemText
                                primary={option}
                                primaryTypographyProps={{
                                  style: { whiteSpace: "normal", fontSize: 12 },
                                }}
                              />

                              <ListItemIcon>
                                <Checkbox
                                  style={{
                                    color: "#1e8a79",
                                    "&.Mui-checked": {
                                      color: "#1e8a79",
                                    },
                                  }}
                                  checked={selectedSpec.indexOf(option) > -1}
                                />
                              </ListItemIcon>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                )}
                {errors.special && (
                  <div className="row">
                    <span
                      className="error-title-css"
                      style={{ margin: "5px 0px 0px 22px !important" }}
                    >
                      Select at least one speciality
                    </span>
                  </div>
                )}
              </div>
              <div>
                <div
                  className="post-modal-help-text"
                  style={{
                    textAlign: "right",
                    margin: "0.3em 0",
                    fontSize: "0.6em",
                  }}
                >
                  Who should be able to see your post?
                </div>
                <Controller
                  name="share"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel
                        style={{
                          color:
                            selectedShare.length === 0
                              ? "rgb(0, 0, 0 , 0.6)"
                              : "#469b8d",
                        }}
                        id="share-dropdown"
                      >
                        Share with
                      </InputLabel>
                      <Select
                        labelId="share-dropdown"
                        {...field}
                        value={selectedShare}
                        onChange={handleShare}
                        disabled={selectedChannel != 0 ? true : false}
                        input={<OutlinedInput label="Share with" />}
                        MenuProps={MenuProps}
                        renderValue={renderShareWith}
                        className={classes.selectBorder}
                      >
                        {channelsData.map((channel, i) => {
                          if (channel.feed_provider_type === "Network") {
                            return (
                              <MenuItem
                                style={{
                                  margin: "5px",
                                  borderRadius: "8px",
                                  font: "bold 14px/20px roboto",
                                  padding: "5px 5px 2px 10px",
                                }}
                                value={channel}
                                key={channel.channel_id}
                              >
                                <ListItemText
                                  classes={{ root: "remove-margin" }}
                                >
                                  <span
                                    className="menu-head"
                                    style={{ color: "#1e8a79" }}
                                  >
                                    Share with {channel.feed_provider_name}
                                  </span>
                                  <br></br>
                                  <span className="sub-head">
                                    Visible to all members on WhiteCoats Network
                                  </span>
                                </ListItemText>
                              </MenuItem>
                            );
                          }
                        })}
                        {displayCommunitySubHeader()}
                        {channels.map((channel) => {
                          if (channel.is_admin) {
                            return (
                              <MenuItem
                                style={{
                                  backgroundColor: "white",
                                  marginLeft: "15px",
                                  display: "flex",
                                }}
                                key={channel.channel_id}
                                value={channel}
                              >
                                <ListItemText
                                  classes={{ root: "remove-margin" }}
                                >
                                  <span
                                    className="menu-head"
                                    style={{ color: "#1e8a79" }}
                                  >
                                    {channel.feed_provider_name}
                                  </span>
                                </ListItemText>
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </div>
            </div>
            <div className="post-modal-form-field">
              {departments && departments.length != 0 && (
                <div
                  className="post-modal-help-text"
                  style={{
                    textAlign: "right",
                    margin: "0.3em 0",
                    fontSize: "0.6em",
                  }}
                >
                  Who should be able to see your post?
                </div>
              )}
              {departments && departments.length != 0 && (
                <Controller
                  name="post"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel
                        style={{
                          color:
                            selected.length === 0
                              ? "rgb(0, 0, 0 , 0.6)"
                              : "#469b8d",
                        }}
                        id="demo-multiple-checkbox-label"
                      >
                        Select Department
                      </InputLabel>
                      <Select
                        {...field}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selected}
                        onChange={handleChange}
                        input={<OutlinedInput label="Select Department" />}
                        renderValue={renderPostValue}
                        MenuProps={MenuProps}
                        className={classes.selectBorder}
                      >
                        <MenuItem
                          value="all"
                          classes={{
                            root: isAllSelected ? classes.selectedAll : "",
                          }}
                          style={{
                            display: "flex",
                            minHeight: "38px",
                            marginLeft: "10px",
                            backgroundColor: "white",
                          }}
                        >
                          <ListItemText
                            classes={{
                              primary: classes.selectAllText,
                            }}
                            primary="All Members"
                            primaryTypographyProps={{
                              style: { whiteSpace: "normal", fontSize: 12 },
                            }}
                          />
                          <ListItemIcon>
                            <Checkbox
                              classes={{
                                indeterminate: classes.indeterminateColor,
                              }}
                              style={{
                                color: "#1e8a79",
                                "&.Mui-checked": {
                                  color: "#1e8a79",
                                },
                              }}
                              checked={isAllSelected}
                            />
                          </ListItemIcon>
                        </MenuItem>
                        {departmentValue.map((option, i) => (
                          <MenuItem
                            id={i}
                            value={option}
                            style={{
                              backgroundColor: "white",
                              display: "flex",
                              marginLeft: "10px",
                              minHeight: "38px",
                            }}
                            key={i}
                          >
                            <ListItemText
                              primary={option}
                              primaryTypographyProps={{
                                style: { whiteSpace: "normal", fontSize: 12 },
                              }}
                            />
                            <ListItemIcon>
                              <Checkbox
                                checked={selected.indexOf(option) > -1}
                                style={{
                                  color: "#1e8a79",
                                  "&.Mui-checked": {
                                    color: "#1e8a79",
                                  },
                                }}
                              />
                            </ListItemIcon>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              )}
              {departments &&
                departments.length != 0 &&
                errors.post?.type === "required" && (
                  <div className="row">
                    <span
                      className="error-title-css"
                      style={{
                        margin: "5px 0px 0px 22px !important",
                      }}
                    >
                      Select at least one member group
                    </span>
                  </div>
                )}
            </div>
            <div>
              <Progressbar
                show={loaderSpinner}
                imageUrl={url}
                height="90"
                width="90"
                alignment="middle"
                alttext="Loading..."
              />
            </div>
          </form>
        </div>
      </div>

      <div className="post-modal-submit">
        <button
          type="submit"
          onClick={handleSubmit(postSubmit)}
          className="post-modal-submit-button"
        >
          POST
        </button>
      </div>
      {/* </form> */}
    </div>
  );
};

const questionHelpText = `Examples:
I have started a new hospital in Jaipur 2 years ago. Does anyone have references for accreditation consultants?

I am a pediatrician with 3 years of experience. Which college/hospital is the best for pediatric cardiac surgery in India?

I have a new practice in Pune. What is the best way to get patients online without commisions?

How to manage postpartum hemorrhage in a primi with RHD?`;

const newsHelpText = `Examples:
Check out the latest advisory from WHO on how to tackle long COVID in diabetic patients.

Bangalore Ophthalmic Society advises doctors against tie-ups with online platforms that charges commissions for patients.

I am looking to hire 3 medical officers for my new hospital in Indore. Please reach out if interested.
`;

const postHelpText = `Examples:
A 72-year old female presented with acute mental status and one week h/o periobital eye and neck swelling. She's a known case of stage 3 myeloma post stem cell transplant. On admission, she had bilateral optic disc edema, UTI. I am attaching how I managed this case and how the patient responded. Hope this helps the community.`;

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ color: [] }, { background: [] }],
  ],
};

const formats = ["bold", "italic", "underline", "color", "background"];

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#1e8a79",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      //  borderColor: '#1e8a79',
    },
    "&:hover fieldset": {
      borderColor: "rgb(0, 0 ,0 ,0.4)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgb(0, 0 ,0 ,0.4)",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  input: {
    height: "32px",
  },
  selectBorder: {
    "&:hover .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1e8a79 !important",
    },
    "& .MuiOutlinedInput-input": {
      //   color: "#1e8a79",
      font: " 13px/20px roboto !important",
    },
    "& .MuiOutlinedInput-input .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1e8a79",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 , 0 ,0 , 0.4) !important",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1e8a79 !important",
      borderWidth: "1px !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0 , 0 ,0 , 0.4) !important",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1e8a79 !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      borderColor: "#1e8a79 !important",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      borderColor: "#1e8a79 !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#1e8a79 !important",
    },
  },
  customOutline: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  formControl: {
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderColor: "blue",
    },
  },

  // select: {
  //   "&:before": {
  //     borderColor: "red",
  //   },
  // },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 8,
    position: "relative",
    backgroundColor: "rgb(221, 253, 247)",
    padding: "0px 0px 10px 10px",
    color: "rgb(30, 138, 121)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    font: "bold 14px / 20px roboto",
    height: "35px",
    // '&:focus': {
    //   borderRadius: 4,
    //   borderColor: '#80bdff',
    //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    // },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default CreatePostModal;
