import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { getOsInfo } from "../utils/utils";
import homeIcon from "../assets/Icons/Group 1019.svg";
import download from "../assets/Icons/Group 1021.png";
import testIcon from "../assets/mobile-footerIcons/Icons-01.jpg";
import { DiMitlicence } from "react-icons/di";
import home from "../assets/mobile_headerIcons/home.svg";
import knowledge from "../assets/mobile_headerIcons/Group 7.svg";
import community from "../assets/mobile_headerIcons/Group 9.svg";
import professional from "../assets/mobile_headerIcons/Group 11.svg";
import knowledgeInactive from "../assets/mobile_headerIcons/Group 8.svg";
import communityInactive from "../assets/mobile_headerIcons/Group 10.svg";
import professionalInactive from "../assets/mobile_headerIcons/Group 12.svg";
import homeInactive from "../assets/mobile_headerIcons/home_inactive.png";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import medicalImage from "../assets/Web Icons/Icon awesome-notes-medical.svg";
import newspaperImage from "../assets/Web Icons/Icon awesome-newspaper.svg";
import circleImage from "../assets/Web Icons/Icon material-announcement.svg";
import postImage from "../assets/Web Icons/Icon ionic-ios-add-circle-outline.svg";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import questionIcon from "../assets/Ask A Question-1X.png";

import { styled } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
const StyledFab = styled(Fab)({});
const DahboardFooter = ({
  handleUploadMci,
  userInfo,
  handleVerificationPending,
}) => {
  const location = useLocation();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const history = useHistory();
  const OS = getOsInfo();
  const [iconState, setIconState] = useState("dashboard");
  const [url, setUrl] = useState(
    "https://play.google.com/store/apps/details?id=com.vam.whitecoats"
  );

  useEffect(() => {
    getPage();
    if (OS === "Android") setUrl("market://details?id=com.vam.whitecoats");
    if (OS === "IOS")
      setUrl("https://apps.apple.com/in/app/whitecoats/id1060971730");
    // eslint-disable-next-line
  }, []);
  const getPage = () => {
    if (location.pathname.includes("/knowledge")) setIconState("knowledge");
    else if (
      location.pathname.includes("/dashboard") ||
      location.pathname === "/"
    )
      setIconState("dashboard");
    else if (location.pathname.includes("/professional"))
      setIconState("professional");
    else if (location.pathname.includes("/community"))
      setIconState("community");
    else setIconState("none");
  };

  const toggleDrawer = (event, anchor, open) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const verifyUser = () => {
    if (!userInfo.verificationInfo.isCommunityVerified) {
      if (userInfo.verificationInfo.is_user_verified == 1) {
        handleUploadMci();
        return false;
      }
      if (userInfo.verificationInfo.is_user_verified == 2) {
        handleVerificationPending();
        return false;
      }
    }
    return true;
  };

  const openCreatePost = (type) => {
    if (!verifyUser()) return;
    history.push({
      pathname: `/post/${type}`,
      state: {
        background: location,
        profile_pic: userInfo?.profile_pic_small_url,
        user_verification_info: userInfo?.verificationInfo,
      },
    });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    >
      <AppBar
        position="static"
        style={{
          backgroundColor: "white",
          color: "black",
          marginTop: "0px",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <Typography
            style={{
              font: "bold 20px / 20px roboto",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            Create New Post
          </Typography>
          <IconButton
            style={{ marginLeft: "47%" }}
            onClick={(e) => toggleDrawer(e, anchor, false)}
          >
            <CancelOutlinedIcon fontSize="medium" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <List
        onClick={(e) => toggleDrawer(e, anchor, false)}
        onKeyDown={(e) => toggleDrawer(e, anchor, false)}
      >
        <ListItem button key="Ask A Question" onClick={() => openCreatePost(3)}>
          <ListItemIcon>
            <img
              src={circleImage}
              style={{
                cursor: "pointer",
                height: "20px",
              }}
              alt="circle logo"
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                style={{
                  color: "rgb(30, 138, 121)",
                  font: "bold 15px / 20px roboto",
                }}
              >
                Ask A Question
              </Typography>
            }
            secondary="Get answers/tips from your peers from the medicinal community"
          />
        </ListItem>

        <Divider />
        <ListItem button key="New Case" onClick={() => openCreatePost(1)}>
          <ListItemIcon>
            <img
              src={medicalImage}
              style={{
                cursor: "pointer",
                height: "20px",
              }}
              alt="medical logo"
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                style={{
                  color: "rgb(30, 138, 121)",
                  font: "bold 15px / 20px roboto",
                }}
              >
                Post A Case
              </Typography>
            }
            secondary="Share and discuss interesting medical cases"
          />
        </ListItem>

        <Divider />
        <ListItem
          button
          key="Share News/Announcements"
          onClick={() => openCreatePost(2)}
        >
          <ListItemIcon>
            <img
              src={newspaperImage}
              style={{
                cursor: "pointer",
                height: "20px",
              }}
              alt="newspaper logo"
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                style={{
                  color: "rgb(30, 138, 121)",
                  font: "bold 15px / 20px roboto",
                }}
              >
                Share News/Announcements
              </Typography>
            }
            secondary="Share latest/useful developments in medicine or your work"
          />
        </ListItem>
        <Divider />
      </List>
    </Box>
  );
  return (
    <div className="footer-area reveal-footer">
      <div className="container" style={{ backgroundColor: "white" }}>
        <div className="row">
          <div className="col-12">
            <div
              className="mobile-footer-inner d-flex "
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <div className="profile-thumb ">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (location.pathname.includes("/dashboard"))
                      window.location.reload();
                    else history.push("/dashboard");
                  }}
                >
                  {iconState === "dashboard" ? (
                    <>
                      <span>
                        <img
                          style={{ cursor: "pointer" }}
                          src={home}
                          alt="home"
                        />
                      </span>
                      <span style={{ fontSize: "12px", color: "#2a8f80" }}>
                        Home
                      </span>
                    </>
                  ) : (
                    <>
                      <span>
                        <img
                          style={{ cursor: "pointer" }}
                          src={homeInactive}
                          alt="knowledge"
                        />
                      </span>
                      <span style={{ fontSize: "12px" }}>Home</span>
                    </>
                  )}
                </div>
              </div>
              <div className="profile-thumb1 ">
                <figure className="profile-thumb-small profile-active">
                  <div
                    style={{
                      padding: "0",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      if (location.pathname.includes("/knowledge"))
                        window.location.reload();
                      else history.push("/knowledge");
                    }}
                  >
                    {iconState === "knowledge" ? (
                      <>
                        <span>
                          <img
                            style={{ cursor: "pointer" }}
                            src={knowledge}
                            alt="knowledge"
                          />
                        </span>
                        <span style={{ fontSize: "12px", color: "#2a8f80" }}>
                          Knowledge
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          <img
                            style={{ cursor: "pointer" }}
                            src={knowledgeInactive}
                            alt="knowledge"
                          />
                        </span>
                        <span style={{ fontSize: "12px" }}>Knowledge</span>
                      </>
                    )}
                  </div>
                </figure>
              </div>
              <div className="profile-thumb1 ">
                <figure className="profile-thumb-small profile-active">
                  <div
                    style={{
                      padding: "0",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <>
                      <span>
                        {/* <img
                            style={{ cursor: "pointer",position:"absolute",top:"-27px",left:"43%",zIndex:"1",right:"0" }}
                            src={knowledge}
                            alt="post"
                          /> */}
                        <StyledFab
                          color="secondary"
                          aria-label="add"
                          className="fab"
                        >
                          <img
                            style={{ cursor: "pointer", height: "25px" }}
                            src={postImage}
                            alt="post"
                            onClick={(e) => {
                              if (!verifyUser()) return;
                              toggleDrawer(e, "bottom", true);
                            }}
                          />
                          <SwipeableDrawer
                            anchor="bottom"
                            open={state["bottom"]}
                            onClose={(e) => toggleDrawer(e, "bottom", false)}
                            onOpen={(e) => toggleDrawer(e, "bottom", true)}
                          >
                            {list("bottom")}
                          </SwipeableDrawer>
                        </StyledFab>
                      </span>
                      <span style={{ fontSize: "12px", marginTop: "20px" }}>
                        Post
                      </span>
                    </>
                  </div>
                </figure>
              </div>
              <div className="profile-thumb1 ">
                <figure className="profile-thumb-small profile-active">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (location.pathname.includes("/community"))
                        window.location.reload();
                      else history.push("/community");
                    }}
                  >
                    {iconState === "community" ? (
                      <>
                        <span>
                          <img
                            style={{ cursor: "pointer" }}
                            src={community}
                            alt="knowledge"
                          />
                        </span>
                        <span style={{ fontSize: "12px", color: "#2a8f80" }}>
                          Community
                        </span>
                      </>
                    ) : (
                      <>
                        {" "}
                        <span>
                          <img
                            style={{ cursor: "pointer" }}
                            src={communityInactive}
                            alt="knowledge"
                          />
                        </span>
                        <span style={{ fontSize: "12px" }}>Community</span>
                      </>
                    )}
                  </div>
                </figure>
              </div>
              <div className="profile-thumb1 ">
                <figure className="profile-thumb-small profile-active">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (location.pathname.includes("/professional"))
                        window.location.reload();
                      else history.push("/professional");
                    }}
                  >
                    {iconState === "professional" ? (
                      <>
                        <span>
                          <img
                            style={{ cursor: "pointer" }}
                            src={professional}
                            alt="knowledge"
                          />
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#2a8f80",
                            margin: "2px 0px 0px 0px",
                          }}
                        >
                          Professional
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          <img
                            style={{ cursor: "pointer" }}
                            src={professionalInactive}
                            alt="knowledge"
                          />
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            margin: "2px 0px 0px 0px",
                          }}
                        >
                          Professional
                        </span>
                      </>
                    )}
                  </div>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DahboardFooter;
