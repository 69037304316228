import { useState, useRef, useCallback } from "react";
import { getDocId } from "../utils/utils";
import Feeds from "../dashboard/feeds/Feeds";
import FullViewFeed from "../dashboard/feeds/FullViewFeed";
import { Modal } from "react-responsive-modal";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import Progressbar from "../progressbar";
import useCommunityFeedLoader from "../dashboard/hooks/useCommunityFeedLoader";
import JobFilters from "./JobFilters";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Opportunities = ({
  feeds,
  setFeeds,
  profile_pic,
  updateLikeCount,
  addComment,
  submitSurvey,
  commentsListInfo,
  openLikesModal,
  registerWebinar,
  user_verification_info,
  feedsErrorMsg,
  deleteComment,
  updateComment,
  updateViewCount,
}) => {
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const [loaderSpinner, setloaderSpinner] = useState(true);
  const doc_id = getDocId();

  const [modalData, setModalData] = useState({});
  const fullViewFeedRef = useRef(null);
  const [openFullView, setOpenFullView] = useState(false);
  const handleOpenFullView = (feed) => {
    // window.scrollTo(0, 0);
    setModalData(feed);
    setOpenFullView(true);
  };
  const handleCloseFullView = () => {
    setOpenFullView(false);
  };
  const [nextFeedsData, setNextFeedsData] = useState({
    pageNum: 0,
    tabId: 10,
  });

  const { loading, error, channels, hasMore, categoryList, nextLastFeedId } =
    useCommunityFeedLoader(doc_id, nextFeedsData, setFeeds);

  const observer = useRef(null);
  const loader = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setNextFeedsData((prev) => ({
            ...prev,
            pageNum: prev.pageNum + 1,
            last_feed_id: nextLastFeedId,
          }));
        }
      });
      if (node) observer.current.observe(node);
    },

    [loading, hasMore]
  );

  const closeIcon = <img src={close}></img>;
  return (
    <div>
      <JobFilters setNextFeedsData={setNextFeedsData} />
      <Feeds
        feeds={feeds}
        profile_pic={profile_pic}
        updateLikeCount={updateLikeCount}
        addComment={addComment}
        submitSurvey={submitSurvey}
        openFullView={handleOpenFullView}
        commentsListInfo={commentsListInfo}
        openLikesModal={openLikesModal}
        channels={channels}
        registerWebinar={registerWebinar}
        user_verification_info={user_verification_info}
      />
      {loading && (
        <div>
          <Progressbar
            show={loaderSpinner}
            imageUrl={url}
            height="90"
            width="90"
            alignment="middle"
            alttext="Loading..."
          />
        </div>
      )}
      {feeds.length === 0 && <div>No Feeds to show </div>}
      {error && <div>{feedsErrorMsg}</div>}
      <div ref={loader} />

      <Modal
        open={openFullView}
        blockScroll={true}
        style={customStyles}
        onClose={handleCloseFullView}
        closeIcon={closeIcon}
        classNames={{ modal: "feedModel" }}
        ref={fullViewFeedRef}
      >
        <FullViewFeed
          profile_pic={profile_pic}
          close={handleCloseFullView}
          feed={modalData}
          setFeeds={setFeeds}

          addComment={addComment}
          updateLikeCount={updateLikeCount}
          submitSurvey={submitSurvey}
          updateViewCount={updateViewCount}
          channels={channels}
          deleteComment={deleteComment}
          updateComment={updateComment}
          openLikesModal={openLikesModal}
          openCommentMobileView={commentsListInfo}
          registerWebinar={registerWebinar}
          user_verification_info={user_verification_info}
        ></FullViewFeed>
      </Modal>
    </div>
  );
};

export default Opportunities;
