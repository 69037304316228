import { useState, useEffect, useRef } from "react";
import { getDocId, getHeaders, renderDays } from "../../utils/utils";

import Progressbar from "../../progressbar";
import { useParams, useHistory } from "react-router-dom";
import experience from "../../assets/new_profile_icons/experience.svg";
import "react-responsive-modal/styles.css";
import { useForm, Controller, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import Select from "react-select";
import Box from "@mui/material/Box";
import { MdArrowBackIos, MdEdit, MdDeleteForever } from "react-icons/md";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { IoIosCloseCircle } from "react-icons/io";
import "../../profile/profile.css";
import { Modal } from "react-responsive-modal";
import Switch from "@mui/material/Switch";
import expCurrentIcon from "../../assets/new_profile_icons/ic_exp_1.svg";
import expPastIcon from "../../assets/new_profile_icons/ic_exp_2.svg";
import Drawer from "@mui/material/Drawer";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { IoIosRadioButtonOn, IoIosRadioButtonOff } from "react-icons/io";
import moment from "moment";
import { FaCalendarAlt, FaCaretDown, FaAddressCard } from "react-icons/fa";
import InputAdornment from "@mui/material/InputAdornment";

const MobileAvailableAt = () => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    watch,
    trigger,
    formState: { errors, isValid, isDirty },
  } = useForm({ mode: "onChange" });

  const { user } = useParams();
  const history = useHistory();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const user_id = getDocId();
  const headers = getHeaders();

  const [userInfo, setUserInfo] = useState({});
  const [cities, setCities] = useState([]);

  const [drawer, setDrawer] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [profId, setProfId] = useState(null);

  const working = watch("working_here");

  const defaultAvailableDays = {
    MON: false,
    TUE: false,
    WED: false,
    THU: false,
    FRI: false,
    SAT: false,
    SUN: false,
  };

  const [availableDays, setAvailableDays] = useState(defaultAvailableDays);
  const [department, setDepartment] = useState("");
  const [showOnCard, setShowOnCard] = useState(false);
  const [isShowOnCard, setIsShowOnCard] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const handleFromDate = (newValue) => setFromDate(newValue);

  const [toDate, setToDate] = useState(null);
  const handleToDate = (newValue) => setToDate(newValue);

  const [startedOn, setStartedOn] = useState(false);
  const openStartedOn = () => setStartedOn(true);
  const closeStartedOn = () => setStartedOn(false);

  const [workedTill, setWorkedTill] = useState(false);
  const openWorkedTill = () => {
    if (fromDate) setWorkedTill(true);
  };
  const closeWorkedTill = () => setWorkedTill(false);

  const [toTiming, setToTiming] = useState(null);
  const handleToTiming = (newValue) => setToTiming(newValue);

  const [availableTill, setAvailableTill] = useState(false);
  const openAvailableTill = () => setAvailableTill(true);
  const closeAvailableTill = () => setAvailableTill(false);

  const [fromTiming, setFromTiming] = useState(null);
  const handleFromTiming = (newValue) => setFromTiming(newValue);

  const [availableFrom, setAvailableFrom] = useState(false);
  const openAvailableFrom = () => setAvailableFrom(true);
  const closeAvailableFrom = () => setAvailableFrom(false);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const [otherUser, setOtherUser] = useState(false);

  const [loaderSpinner, setloaderSpinner] = useState(false);

  const [verifyUpdate, setVerifyUpdate] = useState(false);
  const closeVerifyUpdate = () => setVerifyUpdate(false);
  const openVerifyUpdate = () => setVerifyUpdate(true);

  const [verifyDelete, setVerifyDelete] = useState(false);
  const closeVerifyDelete = () => setVerifyDelete(false);
  const openVerifyDelete = () => setVerifyDelete(true);

  const [deleteData, setDeleteData] = useState({});

  const scrollToId = useRef(null);

  useEffect(() => {
    if (user == "true") {
      setOtherUser(true);
    } else {
      setOtherUser(false);
    }

    getUserInfo();
    autoSuggestions();
  }, []);

  // useEffect(() => {
  //   if (!working && checkShowOnCard(isShowOnCard))
  //     setValue("working_here", true);
  // }, [working]);

  useEffect(() => {
    if (scrollToId.current) {
      document.getElementById(`exp${scrollToId.current}`).scrollIntoView();
      scrollToId.current = null;
    }
  }, [userInfo]);

  const getUserInfo = async () => {
    const url = "v1/userProfile/view";

    const payload = {
      user_id,
    };

    const res = await fetch(baseUrl + url, {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {
      console.error(e);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
    });
    const data = await res.json();

    if (data.status === "success") {
      setUserInfo(data.data);
    } else {
      toast.error(data.errorMsg);
    }
  };

  const handleDays = (day) => (e) => {
    setAvailableDays((prev) => ({
      ...prev,
      [day]: !prev[day],
    }));
  };

  const toggleDrawer =
    (open, isEdit = false, prof_id = 0) =>
    (event) => {
      if (
        event &&
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      setIsEdit(isEdit);
      if (isEdit) setEditValues(prof_id);
      else resetForm();

      setDrawer(open);
    };

  const setEditValues = (prof_id) => {
    const [res] = userInfo.prof_history.filter(
      (exp) => exp.prof_id === prof_id
    );
    if (res) {
      setValue("location", { label: res.location, value: res.location });
      setValue("workplace", res.workplace);
      setValue("designation", res.designation);
      setValue("working_here", res.working_here);
      if (res.from_date && res?.from_date > 0) setFromDate(res.from_date);
      if (!res.working_here)
        if (res.to_date && res?.to_date > 0) setToDate(res.to_date);

      setIsShowOnCard(res.show_on_card);

      if (res.working_here && res.available_days) {
        if (
          res.available_days.to_time &&
          res.available_days?.to_time > -19800001
        )
          setToTiming(res.available_days.to_time);
        if (
          res.available_days.from_time &&
          res.available_days?.from_time > -19800001
        )
          setFromTiming(res.available_days.from_time);
        setDepartment(res.available_days.department);
        extractAvailableDays(res.available_days.week_of_days);
        setShowOnCard(res.show_on_card);
      }
      setProfId(prof_id);
    }
  };

  const resetForm = () => {
    reset({
      designation: "",
      workplace: "",
      location: null,
      working_here: false,
    });
    setFromDate(null);
    setToDate(null);
    setToTiming(null);
    setFromTiming(null);
    setAvailableDays(defaultAvailableDays);
    setShowOnCard(false);
    setDepartment("");
  };

  const getAvailableDays = () => {
    let available_days = [];

    let dayMapping = {
      MON: 1,
      TUE: 2,
      WED: 3,
      THU: 4,
      FRI: 5,
      SAT: 6,
      SUN: 7,
    };

    for (let day in availableDays) {
      if (availableDays[day]) available_days.push(dayMapping[day]);
    }

    return available_days;
  };

  const extractAvailableDays = (available_days) => {
    let dayMapping = {
      1: "MON",
      2: "TUE",
      3: "WED",
      4: "THU",
      5: "FRI",
      6: "SAT",
      7: "SUN",
    };
    setAvailableDays(defaultAvailableDays);
    for (let day of available_days) {
      setAvailableDays((prev) => ({
        ...prev,
        [dayMapping[day]]: true,
      }));
    }
  };

  const autoSuggestions = async () => {
    const payload = { cities: "cities" };

    const res = await fetch(baseUrl + "v2/autoSuggestions", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let cities = [];

    if (data.status === "success") {
      for (let city of data.data.cities) {
        cities.push({ value: city, label: city });
      }
    }

    setCities(cities);
  };

  const deleteExperience = async (prof_id) => {
    console.log(prof_id);
    setloaderSpinner(true);
    const payload = { user_id: user_id, prof_history: [{ prof_id }] };
    const res = await fetch(baseUrl + "v1/userProfile/delete", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      setloaderSpinner(false);
      setUserInfo((prev) => ({
        ...prev,
        prof_history: prev.prof_history.filter(
          (exp) => exp.prof_id !== prof_id
        ),
      }));

      toast.success("Experience deleted");
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }

    closeVerifyDelete();
  };

  const addExperience = async (formData) => {
    const payload = {
      user_id: user_id,
      prof_history: [
        {
          workplace: formData.workplace,
          location: formData?.location?.value,
          designation: formData.designation,
          from_date: fromDate ? fromDate : 0,
          to_date: toDate && !working ? toDate : 0,
          working_here: formData.working_here,
          show_on_card: working ? showOnCard : false,
          user_google_places_info: {},
          user_google_city_info: {},
          is_exact_location: false,
          is_exact_city: false,
          available_days: {
            week_of_days: working ? getAvailableDays() : [],
            from_time: working ? (fromTiming ? fromTiming : -19800001) : 0,
            to_time: working ? (toTiming ? toTiming : -19800001) : 0,
            department: working ? department : "",
          },
        },
      ],
    };

    console.dir(payload);

    const res = await fetch(baseUrl + "v1/userProfile/create", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);

      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      toast.success("Experience added");
      getUserInfo();
      setDrawer(false);
      resetForm();
      scrollToId.current = data.data.prof_history[0].prof_id;
    } else {
      toast.error(data.errorMsg);
    }
  };

  const updateExperience = async (formData) => {
    const payload = {
      user_id: user_id,
      prof_history: [
        {
          prof_id: profId,
          workplace: formData.workplace,
          location: formData?.location?.value,
          designation: formData.designation,
          from_date: fromDate ? fromDate : 0,
          to_date: toDate && !working ? toDate : 0,
          working_here: formData.working_here,
          show_on_card: working ? showOnCard : false,
          user_google_places_info: {},
          user_google_city_info: {},
          is_exact_location: false,
          is_exact_city: false,
          available_days: {
            week_of_days: working ? getAvailableDays() : [],
            from_time: working ? (fromTiming ? fromTiming : -19800001) : 0,
            to_time: working ? (toTiming ? toTiming : -19800001) : 0,
            department: working ? department : "",
          },
        },
      ],
    };

    console.log(payload);

    const res = await fetch(baseUrl + "v1/userProfile/update", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);

      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      toast.success("Experience updated");
      getUserInfo();
      resetForm();
    } else {
      toast.error(data.errorMsg);
    }

    closeVerifyUpdate();
  };

  const sortExperiences = (exps) => {
    if (!exps) return [];
    const res = [...exps];
    res.sort((a, b) => {
      if (b.working_here && a.working_here) {
        if (!b.from_date && a.from_date) return -1;
        else if (b.from_date && !a.from_date) return 1;
        else if (!b.from_date && !a.from_date) return b.prof_id - a.prof_id;
        else
          return (
            new Date(b.from_date).getTime() - new Date(a.from_date).getTime()
          );
      } else if (b.working_here && !a.working_here) return 1;
      else if (!b.working_here && a.working_here) return -1;
      else {
        if (a.to_date && b.to_date) {
          if (
            new Date(b.to_date).getFullYear() ===
            new Date(a.to_date).getFullYear()
          )
            return (
              new Date(b.from_date).getTime() - new Date(a.from_date).getTime()
            );
          return new Date(b.to_date).getTime() - new Date(a.to_date).getTime();
        } else if (a.to_date && !b.to_date) return -1;
        else if (!a.to_date && b.to_date) return 1;
        else if (a.from_date && b.from_date)
          return (
            new Date(b.from_date).getTime() - new Date(a.from_date).getTime()
          );
        else if (!a.from_date && b.from_date) return 1;
        else if (a.from_date && !b.from_date) return -1;
        else return b.prof_id - a.prof_id;
      }
    });
    return res;
  };

  const checkShowOnCard = (show_on_card) => {
    if (show_on_card)
      toast(
        "WhiteCoats requires at least one workplace to be displayed on your card"
      );
    return show_on_card;
  };

  const list = () => (
    <Box sx={{ width: "auto" }} role="presentation">
      <div style={{ padding: "1em" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          onClick={toggleDrawer(false)}
        >
          <IoIosCloseCircle
            style={{
              cursor: "pointer",
              color: "lightgrey",
            }}
            size={25}
          />
        </div>

        <div style={{ ...inputDivStyle }}>
          <input
            style={inputStyle}
            type="text"
            {...register("workplace", {
              required: true,
              maxLength: 80,
              pattern: /^[a-zA-Z\s&-',]*$/,
            })}
            placeholder="Name of the Hospital or Clinic"
          />
        </div>
        {errors.workplace?.type === "required" && (
          <div style={errorMsg}>Please enter name of hospital or clinic</div>
        )}
        {errors.workplace?.type === "maxLength" && (
          <div style={errorMsg}>Please enter less than 80 characters</div>
        )}
        {errors.workplace?.type === "pattern" && (
          <div style={errorMsg}>Only letters & - ' , are allowed</div>
        )}

        <div style={inputDivStyle}>
          <Controller
            name="location"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Select
                {...field}
                placeholder="City or Town"
                isClearable={true}
                options={cities}
                styles={{
                  control: (style) => ({
                    ...style,
                    background: "inherit",
                    border: 0,
                    boxShadow: "none",
                    // padding: "10px 10px 10px 0",
                  }),
                  container: (style) => ({
                    ...style,
                    width: "100%",
                  }),
                  placeholder: (style) => ({
                    ...style,
                    fontSize: "14px",
                  }),
                  indicatorsContainer: (style) => ({
                    ...style,
                    display: "none",
                  }),
                  valueContainer: (style) => ({
                    ...style,
                    padding: 0,
                  }),
                  menuList: (style) => ({
                    ...style,
                    height: "9em",
                  }),
                }}
              />
            )}
          />
        </div>
        {errors.location?.type === "required" && (
          <div style={errorMsg}>Please enter your city of residence</div>
        )}

        <div style={{ ...inputDivStyle }}>
          <input
            style={inputStyle}
            type="text"
            {...register("designation", {
              maxLength: 50,
              pattern: /^[a-zA-Z\s]*$/,
            })}
            placeholder="Designation"
          />
        </div>

        {errors.designation?.type === "maxLength" && (
          <div style={errorMsg}>Please enter less than 50 characters</div>
        )}
        {errors.designation?.type === "pattern" && (
          <div style={errorMsg}>Only letters allowed</div>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            // marginBlock: "0.5em",
            marginTop: "0.5em",
            marginBottom: "0.5em",
          }}
        >
          <input
            style={{ width: "20px", height: "20px", margin: 0 }}
            type="checkbox"
            name="working"
            {...register("working_here")}
            onChange={(e) => {
              if (checkShowOnCard(isShowOnCard)) {
                e.target.checked = true;
                return;
              }
              setValue("working_here", e.target.checked);
            }}
          />
          <span
            className="form-check-label"
            style={{
              marginLeft: "5px",
              fontSize: "1.1rem",
            }}
            htmlFor="working"
          >
            Presently working here
          </span>
        </div>

        <div
          style={{
            marginTop: "1em",
            marginBottom: "1em",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDatePicker
              clearable
              label="Started On"
              inputFormat="DD/MM/YYYY"
              value={fromDate}
              sx={{
                "& .MuiGrid-container": { display: "none" },
              }}
              open={startedOn}
              onOpen={openStartedOn}
              onClose={closeStartedOn}
              maxDate={toDate ? moment(toDate) : moment(new Date())}
              minDate={moment(new Date(1970, 0, 1))}
              onChange={handleFromDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={openStartedOn}>
                          <FaCalendarAlt style={{ color: "#469b8d" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    label: {
                      zIndex: 0,
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
          {/* <span style={{ whiteSpace: "pre" }}> - </span> */}
          {working ? (
            <div
              style={{ fontSize: "1.3rem", fontWeight: "bold", width: "45%" }}
            >
              - Till now
            </div>
          ) : (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                clearable
                label="Worked Till"
                inputFormat="DD/MM/YYYY"
                sx={{
                  "& .MuiGrid-container": { display: "none" },
                }}
                value={toDate}
                open={workedTill}
                onOpen={openWorkedTill}
                onClose={closeWorkedTill}
                maxDate={moment(new Date())}
                minDate={
                  fromDate ? moment(fromDate) : moment(new Date(1970, 0, 1))
                }
                onChange={handleToDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" onClick={openWorkedTill}>
                            <FaCalendarAlt style={{ color: "#469b8d" }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      label: {
                        zIndex: 0,
                      },
                    }}
                  />
                )}
                disabled={!fromDate}
              />
            </LocalizationProvider>
          )}
        </div>

        {working && (
          <>
            <div style={{ marginTop: "1em", marginBottom: "1em" }}>
              <span style={{ fontWeight: "bold", fontSize: "1.4rem" }}>
                Available Days
              </span>
              <span
                style={{ marginLeft: "1em", color: "gray", fontSize: "1.4rem" }}
              >
                (Tap on days to Select)
              </span>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "1em",
                marginBottom: "1em",
                gap: "10px",
                justifyContent: "space-evenly",
              }}
            >
              {Object.keys(availableDays).map((key) => (
                <div
                  style={{
                    ...dayStyle,
                    backgroundColor: availableDays[key] ? "#469b8d" : "white",
                    color: availableDays[key] ? "white" : "black",
                    border: availableDays[key]
                      ? "1px solid #469b8d"
                      : "1px solid black ",
                  }}
                  onClick={handleDays(key)}
                >
                  {key}
                </div>
              ))}
            </div>

            <div style={{ marginTop: "1em", marginBottom: "1em" }}>
              <span style={{ fontWeight: "bold", fontSize: "1.4rem" }}>
                Timings
              </span>
            </div>
            <div
              style={{
                marginTop: "1em",
                marginBottom: "1em",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  clearable
                  label="From"
                  value={fromTiming}
                  open={availableFrom}
                  onOpen={openAvailableFrom}
                  onClose={closeAvailableFrom}
                  onChange={handleFromTiming}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={openAvailableFrom}>
                              <FaCaretDown
                                size={18}
                                style={{ color: "gray" }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <span style={{ whiteSpace: "pre" }}> - </span>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  clearable
                  label="to"
                  value={toTiming}
                  open={availableTill}
                  onOpen={openAvailableTill}
                  onClose={closeAvailableTill}
                  onChange={handleToTiming}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={openAvailableTill}>
                              <FaCaretDown
                                size={18}
                                style={{ color: "gray" }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div
              style={{
                marginTop: "1em",
                marginBottom: "1em",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => setDepartment("OPD")}
              >
                {department === "OPD" ? (
                  <IoIosRadioButtonOn
                    style={{ color: "rgb(70, 155, 141)" }}
                    size={20}
                  />
                ) : (
                  <IoIosRadioButtonOff size={20} />
                )}
                <span
                  style={{
                    marginLeft: "1em",
                    fontSize: "1.3rem",
                    color: department === "OPD" ? "rgb(70, 155, 141)" : "black",
                  }}
                >
                  OPD
                </span>
              </div>
              <div
                style={{
                  marginLeft: "2em",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => setDepartment("OT")}
              >
                {department === "OT" ? (
                  <IoIosRadioButtonOn
                    size={20}
                    style={{ color: "rgb(70, 155, 141)" }}
                  />
                ) : (
                  <IoIosRadioButtonOff size={20} />
                )}
                <span
                  style={{
                    marginLeft: "1em",
                    fontSize: "1.3rem",
                    color: department === "OT" ? "rgb(70, 155, 141)" : "black",
                  }}
                >
                  OT
                </span>
              </div>
            </div>
            <div
              style={{
                marginTop: "1em",
                marginBottom: "1em",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: "2em", fontSize: "1.3rem" }}>
                Show on my card
              </span>
              <Switch
                checked={showOnCard}
                // onChange={(e) => {setShowOnCard(e.target.checked)}}
                onChange={(e) => {
                  if (checkShowOnCard(isShowOnCard)) return;
                  setShowOnCard(e.target.checked);
                }}
                sx={{
                  "& .Mui-checked+.MuiSwitch-track": {
                    backgroundColor: "#469b8d !important",
                  },
                  "& .Mui-checked": {
                    color: "#469b8d !important",
                  },
                }}
                {...label}
              />
            </div>
          </>
        )}

        {isEdit ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <button
              style={{
                backgroundColor: "white",
                padding: "1em",
                borderRadius: "30px",
                fontSize: "1.4rem",
                width: "30%",
                border: "1px solid lightgrey",
                color: "gray",
              }}
              onClick={toggleDrawer(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              style={{
                backgroundColor: isValid ? "#469b8d" : "dimgray",
                width: "60%",
                borderRadius: "30px",
                padding: "1em 0",
                color: "white",
                fontSize: "1.4rem",
              }}
              onClick={() => {
                if (isValid) {
                  setDrawer(false);
                  openVerifyUpdate();
                }
              }}
            >
              Update Experience
            </button>{" "}
          </div>
        ) : (
          <button
            type="button"
            style={{
              backgroundColor: isDirty && isValid ? "#469b8d" : "dimgray",
              width: "100%",
              borderRadius: "30px",
              padding: "1em 0",
              color: "white",
              fontSize: "1.8rem",
            }}
            onClick={handleSubmit(addExperience)}
          >
            Add Experience
          </button>
        )}
      </div>
    </Box>
  );

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "white",
          zIndex: 10,
          // overflow: "auto",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100vw",
        }}
      >
        <div
          style={{
            backgroundColor: "rgb(30 138 121)",
            display: "flex",
            color: "white",
            padding: "1em",
            alignItems: "center",
          }}
        >
          <MdArrowBackIos
            style={{ color: "white" }}
            onClick={() => history.replace("/profile")}
            size={32}
          />
          Experience
        </div>

        {!loaderSpinner &&
        userInfo.prof_history &&
        userInfo?.prof_history?.length !== 0 ? (
          <div style={{ overflowY: "auto", flex: 1 }}>
            {sortExperiences(userInfo.prof_history).map((exp) => (
              <>
                <div
                  id={`exp${exp.prof_id}`}
                  style={{
                    padding: "1em",
                    display: "flex",
                  }}
                >
                  <div style={{ width: "15%" }}>
                    <img
                      src={exp.working_here ? expCurrentIcon : expPastIcon}
                      style={{ width: "1.5em" }}
                    />
                  </div>
                  <div style={{ width: "85%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "stretch",
                      }}
                    >
                      <div
                        style={{
                          overflowWrap: "break-word",
                          color: "#469b8d",
                          fontWeight: "bold",
                          fontSize: "1.8rem",
                          maxWidth: "80%",
                        }}
                      >
                        {exp.workplace}
                      </div>
                      {exp.show_on_card && (
                        <FaAddressCard
                          size={25}
                          style={{
                            marginLeft: "1em",
                          }}
                        />
                      )}
                    </div>
                    {exp.location && exp?.location !== "" && (
                      <div
                        style={{
                          fontSize: "1.2rem",
                          color: "black",
                          fontStyle: "italic",
                          fontWeight: "bold",
                          marginTop: "1em",
                        }}
                      >
                        {exp.location}
                      </div>
                    )}
                    {exp.designation && exp?.designation !== "" && (
                      <div
                        style={{
                          fontSize: "1.2rem",
                          color: "gray",
                          marginTop: "1em",
                        }}
                      >
                        {exp.designation}
                      </div>
                    )}
                    <div
                      style={{
                        // display: "flex",
                        // justifyContent: "space-between",
                        fontWeight: "bold",
                        // color: "gray",
                        fontSize: "1.2rem",
                        marginTop: "1em",
                        width: "100%",
                      }}
                    >
                      {/* <div style={{ width: "50%" }}> */}
                      {`
                         ${
                           exp.from_date && !exp.to_date && !exp.working_here
                             ? `Since`
                             : ""
                         }
                        ${
                          exp.from_date
                            ? `
                        
                            ${new Date(exp.from_date).toLocaleDateString(
                              "en-GB"
                            )}`
                            : " "
                        } ${
                        (exp.from_date &&
                          exp.to_date &&
                          exp?.from_date < exp?.to_date) ||
                        exp.working_here
                          ? "-"
                          : " "
                      } ${
                        exp.working_here
                          ? "Present"
                          : exp.to_date && exp.from_date
                          ? `
                          ${new Date(exp.to_date).toLocaleDateString("en-GB")}`
                          : " "
                      } `}
                      {/* </div> */}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "1em",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "1.2rem",
                          color: "gray",
                          width: "60%",
                        }}
                      >
                        {`${
                          exp.available_days.department
                            ? exp.available_days.department + ", "
                            : ""
                        } ${
                          exp.available_days.week_of_days.length !== 0
                            ? renderDays(exp.available_days.week_of_days) + ", "
                            : ""
                        } ${
                          exp.available_days.from_time <= -19800001
                            ? ""
                            : new Date(
                                exp.available_days.from_time
                              ).toLocaleTimeString()
                        } ${
                          exp.available_days.from_time <= -19800001 ? "" : "-"
                        } ${
                          exp.available_days.to_time <= -19800001
                            ? ""
                            : new Date(
                                exp.available_days.to_time
                              ).toLocaleTimeString()
                        } `}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "40%",
                          justifyContent: "space-between",
                        }}
                      >
                        <MdDeleteForever
                          onClick={() => {
                            if (checkShowOnCard(exp.show_on_card)) return;
                            setDeleteData({
                              prof_id: exp.prof_id,
                              degree: exp.workplace,
                            });
                            openVerifyDelete();
                          }}
                          style={{ color: "red" }}
                          size={23}
                        />

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={toggleDrawer(true, true, exp.prof_id)}
                        >
                          <MdEdit
                            style={{ color: "rgb(30 138 121)" }}
                            size={18}
                          />
                          <span
                            style={{
                              color: "rgb(30 138 121)",
                              fontSize: "1.2rem",
                              fontWeight: "bold",
                              marginLeft: "1.3em",
                            }}
                          >
                            Edit
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr style={{ margin: 0 }} />
              </>
            ))}
          </div>
        ) : (
          <div
            style={{
              overflowY: "auto",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={experience} style={{ width: "10em" }} />
            <div
              style={{
                padding: "1em",
                textAlign: "center",
                color: "#1e8a79",
                lineHeight: "4.5rem",
                fontSize: "2rem",
              }}
            >
              Share your work experience with others
            </div>
          </div>
        )}

        <div>
          {" "}
          <Progressbar
            show={loaderSpinner}
            imageUrl={url}
            height="90"
            width="90"
            alignment="middle"
            alttext="Loading..."
          />
        </div>
        {!otherUser && (
          <div
            className="post-modal-submit"
            style={{
              marginBottom: 0,
              padding: "1em",
              height: "6em",
              boxShadow: "0 -3px 5px rgb(0 0 0 / 0.2)",
            }}
          >
            <button
              type="button"
              style={{
                backgroundColor: "#469b8d",
                width: "100%",
                borderRadius: "30px",
                padding: "1em 0",
                color: "white",
                fontSize: "1.8rem",
              }}
              onClick={toggleDrawer(true)}
            >
              Add Experience
            </button>
            <Drawer
              sx={{ "& .MuiDrawer-paper": { borderRadius: "15px 15px 0 0" } }}
              anchor="bottom"
              open={drawer}
              // variant="persistent"
              onClose={toggleDrawer(false)}
            >
              {list()}
            </Drawer>
          </div>
        )}
      </div>
      <Modal
        open={verifyUpdate}
        onClose={closeVerifyUpdate}
        showCloseIcon={false}
        backdrop="static"
        center
        classNames={{
          modal: "otpModal",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => closeVerifyUpdate()}
        >
          <IoIosCloseCircle
            style={{
              cursor: "pointer",
              color: "lightgrey",
            }}
            size={25}
          />
        </div>

        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "black",
            fontWeight: "bold",
            marginTop: "1em",
          }}
        >
          Update Experience
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "1.8rem",
            color: "black",
            margin: "1em 1.4em",
          }}
        >
          Are you sure you want to update your experience data?
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "3em",
            marginBottom: "1em",
          }}
        >
          <button
            style={{
              backgroundColor: "white",
              padding: "1em",
              borderRadius: "20px",
              fontSize: "1.2rem",
              width: "45%",
              border: "1px solid gray",
              color: "gray",
            }}
            onClick={() => closeVerifyUpdate()}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: "#469b8d",
              padding: "1em",
              borderRadius: "20px",
              fontSize: "1.2rem",
              width: "45%",
              // border: "1px solid gray",
              color: "white",
            }}
            onClick={handleSubmit(updateExperience)}
          >
            Update
          </button>
        </div>
      </Modal>

      <Modal
        open={verifyDelete}
        onClose={closeVerifyDelete}
        showCloseIcon={false}
        backdrop="static"
        center
        classNames={{
          modal: "otpModal",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => closeVerifyDelete()}
        >
          <IoIosCloseCircle
            style={{
              cursor: "pointer",
              color: "lightgrey",
            }}
            size={25}
          />
        </div>

        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "black",
            fontWeight: "bold",
            marginTop: "1em",
          }}
        >
          Delete Experience
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "1.8rem",
            color: "black",
            margin: "1em 1.4em",
          }}
        >
          Do you want to delete{" "}
          <span style={{ fontWeight: "bold" }}>{deleteData.workplace} </span>
          entry?
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "3em",
            marginBottom: "1em",
          }}
        >
          <button
            style={{
              backgroundColor: "white",
              padding: "1em",
              borderRadius: "20px",
              fontSize: "1.2rem",
              width: "45%",
              border: "1px solid gray",
              color: "gray",
            }}
            onClick={() => closeVerifyDelete()}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: "red",
              padding: "1em",
              borderRadius: "20px",
              fontSize: "1.2rem",
              width: "45%",
              // border: "1px solid gray",
              color: "white",
            }}
            onClick={() => deleteExperience(deleteData.prof_id)}
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

const inputDivStyle = {
  background: "#ffffff",
  borderRadius: "5px",
  height: "3.125em",
  border: "1px solid slategray",
  width: "100%",
  display: "flex",
  alignItems: "center",
  padding: "1em",
  marginBlock: "1em",
};

const inputStyle = {
  background: "inherit",
  width: "100%",
  border: "none",
};

const errorMsg = {
  color: "red",
  fontSize: "1.1rem",
  marginBlock: "1em",
};

const dayStyle = {
  border: "1px solid black ",
  borderRadius: "5px",
  backgroundColor: "white",
  width: "4em",
  padding: "0.6em",
  fontSize: "1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
};

export default MobileAvailableAt;
