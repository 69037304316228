import { useState, useEffect, useRef, useCallback } from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import * as React from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
//import 'react-date-picker/dist/entry.nostyle';
import Progressbar from "../progressbar";
import { Link, useParams, useHistory } from "react-router-dom";
import event from "../assets/new_profile_icons/events.svg";
import ic_event from "../assets/new_profile_icons/ic_event.svg";
import { useForm, Controller, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import Select from "react-select";
import { MdEdit } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";
import { BiCalendar } from "react-icons/bi";
import { FiCalendar } from "react-icons/fi";
import deleteIcon from "../assets/Delete-1X.png";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";
import { MdArrowBackIos } from "react-icons/md";
import Typography from "@mui/material/Typography";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import "../profile/profile.css";
import { Modal } from "react-responsive-modal";

const useStyles = makeStyles({
  input: {
    fontFamily: "sans-serif",
    fontSize: "14px",
    height: "50px",
    height: "100%",
    "& .react-date-picker__wrapper": {
      padding: "0 10px",
      borderColor: "#ccc",
      borderRadius: "4px",
    },
    "& .react-date-picker--open": {
      borderColor: "red",
    },
  },
  label: {
    width: "58px",
    marginTop: "-7px",
    marginLeft: "12px",
    backgroundColor: "white",
    position: "absolute",
    zIndex: "2000",
    fontSize: "11px",
    fontFamily: "sans-serif",
    paddingLeft: "10px",
  },
});

const MobileEvents = ({}) => {
  const {
    submitMandatoryInfo,
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm();

  const { user } = useParams();
  const history = useHistory();
  const user_id = getDocId();
  const headers = getHeaders();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  //////////////////////////////
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const [otherUser, setOtherUser] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const classes = useStyles();
  const [valueDate, onChange] = useState(new Date());
  const [locations, setLocations] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [editClicked, setEditClicked] = useState(false);
  const [editedData, setEditedData] = useState();
  const [deletedData, setDeletedData] = useState();
  const closeIconHide = <img src={close}></img>;
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const [openPostSuccessView, setOpenPostSuccessView] = useState(false);
  const [updatePubModal, setUpdatePubModal] = useState(false);
  const closeUpdatePubModal = () => {
    setUpdatePubModal(false);
  };
  const defaultStartDate = new Date();
  const defaultEndDate = new Date();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleStartDate = (value) => {
    setStartDate(value);
    setEndDate(value);
  };
  const handleEndDate = (value) => {
    setEndDate(value);
  };
  const showUpdatePubModal = (data) => {
    setState({ ...state, ["bottom"]: false });
    setUpdatePubModal(true);
  };

  useEffect(() => {
    if (user == "true") {
      setOtherUser(true);
    } else {
      setOtherUser(false);
    }
    getUserInfo();
    autoSuggestions();
  }, []);

  const getUserInfo = async () => {
    setloaderSpinner(true);
    const payload = {
      user_id,
    };
    const res = await fetch(baseUrl + "v1/userProfile/view", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();

    if (data.status === "success") {
      setUserInfo(data.data);
      setloaderSpinner(false);
      //setDefaultFormValue(data.data);
    }
  };

  const autoSuggestions = async () => {
    const payload = { cities: "cities" };

    const res = await fetch(baseUrl + "v2/autoSuggestions", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let cities = [];

    if (data.status === "success") {
      for (const city of data.data.cities) {
        cities.push({ value: city, label: city });
      }
    }
    setLocations(cities);
  };
  const editEventPop = (e, data) => {
    console.log(data);
    setValue("title", data?.event_name);
    setValue("start_date", data?.event_start_date);
    setValue("end_date", data?.event_end_date);
    setStartDate(new Date(data.event_start_date));
    setEndDate(new Date(data.event_end_date));
    if (data.event_location != "") {
      setValue("location", {
        label: data?.event_location,
        value: data?.event_location,
      });
    } else {
      setValue("location", "");
    }
    setEditClicked(true);
    setEditedData(data);
    toggleDrawer(e, "bottom", true);
  };

  const hideSuccessView = () => {
    setValue("location", "");
    setValue("title", "");
    setDeletedData("");
    setOpenPostSuccessView(false);
  };
  const deleteEventPop = (event, data) => {
    setOpenPostSuccessView(true);
    setDeletedData(data);
  };

  const toggleDrawer = (event, anchor, open) => {
    if (!open) {
      reset();
      setEditClicked(false);
      setDeletedData("");
      setEditedData("");
      setValue("location", "");
      setValue("title", "");
      setStartDate("");
      setEndDate("");
    }
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    >
      <AppBar
        position="static"
        style={{
          backgroundColor: "white",
          color: "black",
          marginTop: "0px",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <Typography
            style={{
              font: "bold 20px / 20px roboto",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          ></Typography>
          <IconButton
            style={{ marginLeft: "92%" }}
            onClick={(e) => toggleDrawer(e, anchor, false)}
          >
            <CancelOutlinedIcon fontSize="large" />
          </IconButton>
        </Toolbar>
        <form>
          <div className="container">
            <div
              style={{
                marginTop: "1em",
                marginBottom: "0em",
                background: "#ffffff",
                borderRadius: "9px",
                height: "3em",
                border: "1px solid grey",
                width: "100%",
              }}
            >
              <div style={{ paddingLeft: "0px" }}>
                <input
                  style={{
                    marginTop: "0.90em",
                    paddingLeft: "5px",
                    background: "inherit",
                    width: "100%",
                    border: "none",
                  }}
                  type="text"
                  {...register("title", {
                    required: true,
                    maxLength: 30,
                    pattern: /^[!@#&*+ 0-9a-zA-Z-]*$/,
                  })}
                  placeholder="Event Title *"
                />
              </div>
            </div>
            {errors.title?.type === "required" && (
              <div
                className="row error-title"
                style={{marginLeft:"0px"}}
              >
                <span className="error-title-text">Please enter the title</span>
              </div>
            )}
            {errors.title?.type === "maxLength" && (
              <div
                className="row error-title"
                style={{marginLeft:"0px"}}
              >
                <span className="error-title-text">
                  Please enter less than 30 characters
                </span>
              </div>
            )}

            {errors.title?.type === "pattern" && (
              <div
                className="row error-title"
                style={{marginLeft:"0px"}}
              >
                <span className="error-title-text">
                  Only Alphanumeric characters !@#&*+ or - are allowed
                </span>
              </div>
            )}
            <div>
              <div
                style={{
                  borderRadius: "9px",
                  marginTop: "1em",
                  marginBottom: "0em",
                  background: "#ffffff",
                  borderRadius: "9px",
                  height: "3em",
                  border: "1px solid grey",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    paddingLeft: "0px",
                    marginTop: "-5px",
                  }}
                >
                  <Controller
                    name="location"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={locations}
                        placeholder="Event Location"
                        styles={{
                          control: (style) => ({
                            ...style,
                            background: "inherit",
                            padding: "10px 10px 10px 0",
                            border: 0,
                            boxShadow: "none",
                          }),
                          valueContainer: (style) => ({
                            ...style,
                            padding: 2,
                          }),
                          placeholder: (style) => ({
                            ...style,
                            fontSize: "13px",
                          }),
                          container: (style) => ({
                            ...style,
                            width: "100%",
                          }),
                          menuList: (style) => ({
                            ...style,
                            overflowY: "scroll",
                          }),
                        }}
                      />
                    )}
                  ></Controller>
                </div>
              </div>
            </div>

            <div>
              <div className="row">
                <div
                  className="col-6"
                  style={{
                    borderRadius: "9px",
                    marginTop: "1em",
                    marginBottom: "0em",
                    background: "#ffffff",
                    borderRadius: "9px",
                    height: "3em",
                  }}
                >
                  <DatePicker
                    placeholderText="Start Date"
                    minDate={new Date()}
                    value={startDate || defaultStartDate}
                    onChange={(e) => handleStartDate(e)}
                    className={classes.input}
                    format="dd/MM/y"
                    clearIcon={null}
                    calendarIcon={
                      <BiCalendar
                        style={{
                          backgroundColor: "rgb(70, 155, 141)",
                          color: "white",
                        }}
                        size={22}
                      />
                    }
                  />
                </div>
                <div
                  className="col-6"
                  style={{
                    borderRadius: "9px",
                    marginTop: "1em",
                    marginBottom: "0em",
                    background: "#ffffff",
                    borderRadius: "9px",
                    height: "3em",
                    paddingLeft:"30px"
                  }}
                >
                  <DatePicker
                    placeholderText="End Date"
                    format="dd/MM/y"
                    minDate={startDate}
                    value={endDate || defaultEndDate}
                    onChange={handleEndDate}
                    className={classes.input}
                    clearIcon={null}
                    calendarIcon={
                      <BiCalendar
                        style={{
                          backgroundColor: "rgb(70, 155, 141)",
                          color: "white",
                        }}
                        size={22}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </form>

        {!editClicked && (
          <div style={{ padding: "1em" }}>
            <button
              type="submit"
              style={{
                backgroundColor: "#469b8d",
                height: "3.5em",
                width: "100%",
                //marginLeft: "15px",
                margintop: "5em",
              }}
              className="post-modal-submit-button"
              onClick={handleSubmit(saveEvents)}
            >
              Add Event
            </button>
          </div>
        )}
        {/* {!editClicked && <div style={{ margin: "70px 30px" }}>
          <button type="submit" className="profile-save-button" onClick={handleSubmit(savePublication)} >
            Save
          </button>
          <button type="submit" className="profile-close-button" onClick={(e) => toggleDrawer(e, anchor, false)}>
            Close
          </button>
        </div>} */}
        {editClicked && (
          <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between",padding: "1em" }}>
            <button
              type="submit"
              className="pub-close-button"
              onClick={(e) => toggleDrawer(e, anchor, false)}
              style={{ marginLeft: "0px" }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="pub-save-button"
              onClick={handleSubmit(showUpdatePubModal)}
            >
              Update Event
            </button>
          </div>
        )}
      </AppBar>
    </Box>
  );

  const deleteEvent = async () => {
    console.log(editedData);
    setloaderSpinner(true);
    let event = {
      event_id: deletedData.event_id,
    };
    let payload = {
      user_id: user_id,
    };
    payload.events = [event];
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/delete", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      setloaderSpinner(false);
      setEmptyValues();
      setOpenPostSuccessView(false);
      toast.success("Event deleted");
      setEditClicked(false);
      setDeletedData("");
      setEditedData("");
      setStartDate("");
      setEndDate("");
      reset();
      getUserInfo();
    } else {
      setloaderSpinner(false);
      setOpenPostSuccessView(false);
      toast.error(data.errorMsg);
    }
  };

  const saveEvents = async (post) => {
    console.log(post);
    if (userInfo?.events?.length >= 25) {
      toast("Reached max limit");
      return;
    }
    setloaderSpinner(true);
    let event = {
      event_start_date: startDate == "" ? defaultStartDate : startDate,
      event_name: post?.title,
      event_location: post.location == undefined || post.location == "" ? "" : post?.location?.value,
      event_end_date: endDate == "" ? defaultEndDate : endDate,
    };
    let payload = {
      user_id: user_id,
    };
    payload.events = [event];
    payload = encodeURIComponent(JSON.stringify(payload));
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/create", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      setValue("location", "");
      setEditClicked(false);
      setDeletedData("");
      setEditedData("");
      setStartDate("");
      setEndDate("");
      toast.success("Event saved");
      setState({ ...state, ["bottom"]: false });
      setEmptyValues();
      setloaderSpinner(false);
      getUserInfo();
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };

  const updateEvent = async (post) => {
    console.log(post);
    setloaderSpinner(true);

    let event = {
      event_id: editedData.event_id,
      event_start_date: startDate == "" ? defaultStartDate : startDate,
      event_name: post?.title,
      event_location: post.location == "" ? "" : post?.location?.value,
      event_end_date: endDate == "" ? defaultEndDate : endDate,
    };
    let payload = {
      user_id: user_id,
    };
    payload.events = [event];
    payload = encodeURIComponent(JSON.stringify(payload));
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/update", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      setValue("location", "");
      setUpdatePubModal(false);
      setEmptyValues();
      setEditClicked(false);
      setDeletedData("");
      setEditedData("");
      setStartDate("");
      setEndDate("");
      setState({ ...state, ["bottom"]: false });
      toast.success("Event updated");
      setloaderSpinner(false);
      getUserInfo();
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };
  
  const setEmptyValues = async () => {
    setValue("location", "");
    setValue("title", "");
    setStartDate("");
    setEndDate("");
  };
  //toggleDrawer(e, "bottom", true);
  ///ebiz-web/app/m/v1/userProfile/delete
  ///ebiz-web/app/m/v1/userProfile/Update
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "white",
          zIndex: 10,
          // overflow: "auto",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100vw",
        }}
      >
        <div
          style={{
            position: "-webkit-sticky",
            position: "relative",
            top: "0",
            left: "0",
            width: "100%",
            zIndex: "25",
            flexShrink: "0",
            marginBottom: "10px",
          }}
          className="mobile-header-wrapper sticky d-block d-lg-none"
        >
          <div
            className="mobile-header position-relative "
            style={{
              backgroundColor: "#469b8d",
              font: "bold 20px / 20px roboto",
              textAlign: "center",
            }}
          >
            <div className="mobile-responsive">
              <div className="brand-logo " style={{ width: "106%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0 1em",
                  }}
                >
                  <MdArrowBackIos
                    color="white"
                    size={25}
                    onClick={() => history.goBack()}
                  />
                  <h2 style={{ color: "white", marginLeft: "1.5em" }}>
                    Events
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!loaderSpinner && (
          <div
            className="conatctDetails"
            style={{ height: "80%", overflow: "auto" }}
          >
            <div>
              {!(!userInfo.events || userInfo?.events?.length === 0) ? (
                <Typography>
                  {userInfo.events
                    ? userInfo.events.map((pub, key) => (
                        <div key={key}>
                          <div className="line"></div>
                          <div
                            style={{
                              padding: "0px",
                              marginLeft: "17px",
                            }}
                          >
                            <p className="print-head">
                              <img
                                src={ic_event}
                                className="print-img"
                                style={{
                                  width: "35px",
                                }}
                              ></img>{" "}
                              <span
                                style={{
                                  color: "rgb(30, 138, 121)",
                                  font: "normal normal bold 16px/16px Roboto",
                                  display: "block",
                                  marginTop: "-30px",
                                  marginLeft: "45px",
                                }}
                              >
                                {" "}
                                {pub.event_name}
                              </span>
                            </p>
                            <div
                              className="author-title"
                              style={{
                                marginLeft: "45px",
                                marginTop: "15px",
                                marginBottom: "10px",
                              }}
                            >
                              {pub.event_location}
                            </div>
                            <div
                              style={{
                                color: "black",
                                font: "normal normal bold 14px/14px Roboto",
                                marginLeft: "45px",
                              }}
                            >
                              {new Date(
                                pub.event_start_date
                              ).toLocaleDateString()}
                              {"-"}
                              {new Date(
                                pub.event_end_date
                              ).toLocaleDateString()}
                            </div>

                            {!otherUser && (
                              <div>
                                <p className="print-head edit-delete">
                                  <div
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      color: "red",
                                    }}
                                    onClick={(e) => deleteEventPop(e, pub)}
                                  >
                                    <MdDeleteForever size={25} />
                                  </div>
                                  <div
                                    style={{
                                      height: "25px",
                                      width: "55px",
                                      marginLeft: "20px",
                                      fontSize: "15px",
                                    }}
                                    onClick={(e) => {
                                      editEventPop(e, pub);
                                    }}
                                  >
                                    <MdEdit size={18} />
                                    Edit
                                  </div>
                                </p>
                              </div>
                            )}
                            <hr
                              style={{
                                marginTop: "15px",
                                marginBottom: "15px",
                              }}
                            ></hr>
                          </div>
                        </div>
                      )).reverse()
                    : ""}
                </Typography>
              ) : (
                <div style={{ marginBottom: "30%" }}>
                  <img src={event} className="mPub"></img>
                  <div
                    className="pub-head"
                    style={{ font: "16px/25px roboto" }}
                  >
                    Let others know about the events that you are attending
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div>
          {" "}
          <Progressbar
            show={loaderSpinner}
            imageUrl={url}
            height="90"
            width="90"
            alignment="middle"
            alttext="Loading..."
          />
        </div>
        {!otherUser && (
          <div
            className="post-modal-submit"
            style={{ marginBottom: 0, height: "6em" }}
          >
            <button
              type="submit"
              style={{
                backgroundColor: "#469b8d",
                height: "3.5em",
                width: "20em",
              }}
              onClick={(e) => {
                toggleDrawer(e, "bottom", true);
              }}
              className="post-modal-submit-button"
            >
              Add Event
            </button>
            <SwipeableDrawer
              anchor="bottom"
              open={state["bottom"]}
              onClose={(e) => toggleDrawer(e, "bottom", false)}
              onOpen={(e) => toggleDrawer(e, "bottom", true)}
            >
              {list("bottom")}
            </SwipeableDrawer>
          </div>
        )}
      </div>
      <Modal
        open={updatePubModal}
        closeModal={closeUpdatePubModal}
        showCloseIcon={true}
        onClose={closeUpdatePubModal}
        classNames={{ modal: "edit-delete-model" }}
        styles={{
          modal: { padding: 0, overflow: "hidden", backgroundColor: "white" },
        }}
      >
        <div className="container-fluid">
          <div className="row" style={{ marginTop: "30px" }}>
            <div className="col-sm-12 " style={{ marginTop: "35px" }}>
              <div
                style={{
                  color: "black",
                  font: "20px/20px roboto",
                  marginBottom: "30px",
                  fontWeight: "bold",
                }}
              >
                Update Event
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "grey",
                  marginBottom: "10px",
                  font: "18px/18px roboto",
                }}
              >
                {" "}
                <p>Are you sure want to</p>
                <p>Update your event entry?</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              <div style={{ margin: "30px 0px" }}>
                <button
                  type="submit"
                  className="pub-close-button"
                  onClick={closeUpdatePubModal}
                  style={{ width: "7em", height: "2.5em" }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="pub-save-button"
                  onClick={handleSubmit(updateEvent)}
                  style={{ width: "7em", height: "2.5em" }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={openPostSuccessView}
        blockScroll={true}
        onClose={hideSuccessView}
        closeIcon={closeIconHide}
        closeModal={hideSuccessView}
        classNames={{ modal: "edit-delete-model" }}
        styles={{ modal: { backgroundColor: "white" } }}
      >
        <div className="container-fluid">
          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "black",
                  font: "20px/20px roboto",
                  marginBottom: "30px",
                  fontWeight: "bold",
                }}
              >
                Delete Event
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "grey",
                  marginBottom: "10px",
                  font: "18px/18px roboto",
                  fontWeight: "600px",
                }}
              >
                {" "}
                <p>Do you want delete</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "black",
                  marginBottom: "10px",
                  font: "16px/16px roboto",
                  fontWeight: "bold",
                }}
              >
                {" "}
                <p
                  style={{
                    display: "inline-block",
                    width: "300px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {deletedData?.event_name}
                </p>
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "grey",
                  marginBottom: "10px",
                  font: "18px/18px roboto",
                }}
              >
                {" "}
                <p>Event?</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              <div style={{ margin: "20px -15px" }}>
                <button
                  type="submit"
                  className="pub-close-button"
                  onClick={(e) => hideSuccessView(e)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="pub-save-button"
                  style={{ backgroundColor: "#FF2F00", marginLeft: "20px" }}
                  onClick={() => deleteEvent()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MobileEvents;
