import React, { useState, useRef, useCallback } from "react";
// import Button from "@material-ui/core/Button";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
import toast from "react-hot-toast";
import { Link, useParams, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { getAuthToken, getDocId, getHeaders } from "../../utils/utils";
import useLikesLoader from "../hooks/useLikesLoader";
import profile_pic_placeholder from "../../assets/profile_icons/default-profile.png";
import send from "../../assets/Icons/Icon feather-arrow-left@2x.png";

const Likes = ({ feed }) => {
  // const profile_pic_placeholder =
  //   "assets/images/profile/src_assets_images_Avatar Icon_30x30.png";
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const doc_id = getDocId();
  const headers = getHeaders();
  const [modalData, setModalData] = useState({});
  const history = useHistory();

  useEffect(() => {
    if (!feed) document.body.style.overflow = "hidden";

    return () => {
      if (!feed) document.body.style.overflow = "unset";
    };
  }, []);

  const { feed_id, channel_id } = useParams();

  const [lastLikeId, setLastLikeId] = useState(0);

  const { loading, hasMore, error, likes, likeData } = useLikesLoader(
    doc_id,
    feed ? feed.channel_id : channel_id,
    feed ? feed.feed_info.feed_id : feed_id,
    lastLikeId
  );
  const observer = useRef(null);

  const loader = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setLastLikeId(likeData.newLastLikeId);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  const userText = (string) => {
    var count = 15;
    if (string)
      return (
        string.charAt(0).toUpperCase() +
        string.slice(1, 15) +
        (string.length > count ? ".." : "")
      );
  };

  const profileUsername = (string) => {
    var count = 20;
    if (string)
      return (
        string.charAt(0).toUpperCase() +
        string.slice(1, 20) +
        (string.length > count ? "..." : "")
      );
  };

  return (
    <div
      style={
        !feed
          ? {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "white",
              zIndex: 20,
              overflow: "auto",
            }
          : {}
      }
    >
      {!feed && (
        <header>
          <div className="mobile-header-wrapper ">
            <div className="mobile-header position-relative ">
              <div style={{ display: "flex" }}>
                <div className="brand-logo ">
                  <img
                    onClick={() => history.goBack()}
                    src={send}
                    style={{ width: "25px", marginTop: "-4px" }}
                    alt="timeline logo"
                  />
                </div>

                <div className="pull-left">
                  <span className="feed-user">
                    Likes
                    <span style={{ color: "green", fontWeight: "600" }}>
                      {" "}
                      ({likeData.likesCount} likes)
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </header>
      )}

      {/* start desktop browsers like list */}
      <div className="container" style={{ height: "570px" }}>
        {feed && (
          <>
            {" "}
            <div className="like-count" style={{ marginTop: "8px" }}>
              <span className="feed-user">
                Likes
                <span style={{ color: "green", fontWeight: "600" }}>
                  {" "}
                  ({likeData.likesCount} likes)
                </span>
              </span>
            </div>
            <hr />{" "}
          </>
        )}
        {likes?.map((feedLikes, i) => (
          <>
            <div
              key={i}
              className="row "
              style={{ margin: "10px 0px 10px 0px" }}
            >
              <div className="col-lg-12 ">
                <div style={{ display: "flex" }}>
                  <div>
                    <Link>
                      <figure className="profile-thumb-like">
                        <img
                          className="like-image"
                          src={
                            feedLikes.profile_pic_original_url &&
                            feedLikes.profile_pic_original_url !== ""
                              ? feedLikes.profile_pic_original_url
                              : profile_pic_placeholder
                          }
                          alt="profile picture"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="like-container">
                    <h6 style={{ padding: "0px 0px 0px 15px" }}>
                      <Link
                        className="feed-like-user"
                        style={{ marginLeft: "0px" }}
                        to={`/profile/${feedLikes.doc_id}`}
                      >
                        {feedLikes.doc_id === parseInt(doc_id)
                          ? "You"
                          : `${profileUsername(feedLikes.user_full_name)}`}
                      </Link>
                    </h6>
                    <div style={{ display: "flex", marginLeft: "10px" }}>
                      <h6 className="feed-like-type">
                        {feedLikes.speciality}{" "}
                      </h6>
                      <h6 className="feed-like-type">
                        {userText(feedLikes.location)}{" "}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-11 ">
                <div className="like-container">
                  <h6 style={{ padding: "0px 0px 0px 15px" }}>
                    <Link
                      className="feed-like-user"
                      style={{ marginLeft: "0px" }}
                      to={`/profile/${feedLikes.doc_id}`}
                    >
                      {feedLikes.doc_id === parseInt(doc_id)
                        ? "You"
                        : `${profileUsername(feedLikes.user_full_name)}`}
                    </Link>
                  </h6>
                  <div style={{ display: "flex", marginLeft: "10px" }}>
                    <h6 className="feed-like-type">{feedLikes.speciality} </h6>{" "}
                    <h6 className="feed-like-type">{feedLikes.location} </h6>
                  </div>
                </div>
              </div> */}
            </div>
            <hr style={{ marginTop: "0px", marginBottom: "0px" }}></hr>
          </>
        ))}
        {loading && <div> Loading ....</div>}
        {error && <div> Error ....</div>}
        <div ref={loader} />
      </div>
      {/* end desktop browsers like list */}

      {/* start mobile browsers like list */}
      {/* <div className="container d-block d-lg-none" style={{ width: "270px" }}>
        <div className="like-count" style={{ marginTop: "8px" }}>
          <span className="feed-user">
            Likes
            <span style={{ color: "green", fontWeight: "600" }}>
              {" "}
              ({modalData.socialInteraction?.likesCount} likes)
            </span>
          </span>
        </div>
        {likes.map((feedLikes, i) => (
          <>
            <div key={i} className="row">
              <div className="col-lg-12 ">
                <Link>
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "10px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <figure className="profile-thumb-middle">
                      <img
                        className="profile-image"
                        src={
                          feedLikes.profile_pic_original_url &&
                          feedLikes.profile_pic_original_url !== ""
                            ? feedLikes.profile_pic_original_url
                            : profile_pic_placeholder
                        }
                        alt="profile picture"
                      />
                    </figure>
                    <div className="like-container-mobile">
                      <h6>
                        <Link
                          className="like-user"
                          to={`/profile/${feedLikes.doc_id}`}
                        >
                          {feedLikes.doc_id === parseInt(doc_id)
                            ? "You"
                            : `${profileUsername(feedLikes.user_full_name)}`}
                        </Link>
                      </h6>
                      <div style={{ display: "flex", marginLeft: "10px" }}>
                        <h6 className="feed-like-type">
                          {feedLikes.speciality}{" "}
                        </h6>
                        <h6 className="feed-like-type">
                          {feedLikes.location}{" "}
                        </h6>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <hr style={{ marginTop: "0px", marginBottom: "0px" }}></hr>
          </>
        ))}
           {loading && <div> Loading ....</div>}
                                  {error && <div> Error ....</div>}
        <div ref={loader} />
      </div> */}
      {/* end mobile browsers like list */}
    </div>
  );
};
export default Likes;
