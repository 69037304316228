import { useState, useRef, useEffect } from "react";
import * as React from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Link, useParams, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { Modal } from "react-responsive-modal";
import Progressbar from "../progressbar";
import { BiCalendar } from "react-icons/bi";
import { MdAdd } from "react-icons/md";
import "../profile/profile.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  input: {
    fontFamily: "sans-serif",
    fontSize: "14px",
    height: "40px",
    "& .react-date-picker__wrapper": {
      padding: "0 10px",
      borderColor: "#ccc",
      borderRadius: "4px",
    },
    "& .react-date-picker--open": {
      borderColor: "red",
    },
  },
  label: {
    width: "58px",
    marginTop: "-7px",
    marginLeft: "12px",
    backgroundColor: "white",
    position: "absolute",
    zIndex: "2000",
    fontSize: "11px",
    fontFamily: "sans-serif",
    paddingLeft: "10px",
  },
  smallRadioButton: {
    "& svg": {
      width: "1.5em",
      height: "1.5em",
    },
  },
});
const useStylesLabel = makeStyles((theme) => ({
  label: {
    fontSize: "1.5rem !important",
  },
}));
const styles = makeStyles((theme) => ({
  radio: {
    colorPrimary: {
      "&$checked": {
        color: "blue",
      },
    },
    checked: {},
  },
}));
const Events = ({
  modalTitle,
  open,
  closeEventsModal,
  getUserData,
  userInfo,
  editClicked,
  editedData,
}) => {
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const history = useHistory();
  const user_id = getDocId();
  const classes = useStyles();
  const classesLabel = useStylesLabel();
  const classesRadio = styles;
  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm();

  const [locations, setLocations] = useState([]);
  const [loaderSpinner, setloaderSpinner] = useState(false);

  const defaultStartDate = new Date();
  const defaultEndDate = new Date();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleStartDate = (value) => {
    setStartDate(value);
    setEndDate(value);
  };
  const handleEndDate = (value) => {
    setEndDate(value);
  };
  useEffect(() => {
    autoSuggestions();
  }, []);

  const autoSuggestions = async () => {
    const payload = { cities: "cities" };
    const res = await fetch(baseUrl + "v2/autoSuggestions", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let cities = [];

    if (data.status === "success") {
      for (const city of data.data.cities) {
        cities.push({ value: city, label: city });
      }
      setLocations(cities);
      if (editClicked) {
        setValue("title", editedData?.event_name);
        if (editedData.event_location == "") {
          setValue("location", "");
        } else {
          setValue("location", {
            label: editedData?.event_location,
            value: editedData?.event_location,
          });
        }
        setStartDate(new Date(editedData.event_start_date));
        setEndDate(new Date(editedData.event_end_date));
      }
    }
  };
  const setEmptyValues = async () => {
    setValue("location", "");
    setValue("title", "");
    setStartDate("");
    setEndDate("");
  };
  const addEvent = async (post) => {
    console.log(post);
    setloaderSpinner(true);
    let event = {
      event_start_date: startDate == "" ? defaultStartDate : startDate,
      event_name: post?.title,
      event_location: post.location == undefined || post.location == "" ? "" : post?.location?.value,
      event_end_date: endDate == "" ? defaultEndDate : endDate,
    };
    let payload = {
      user_id: user_id,
    };
    payload.events = [event];
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/create", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      toast.success("Event added");
      reset();
      setEmptyValues();
      setloaderSpinner(false);
      getUserData();
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };

  const saveEvents = async (post) => {
    console.log(post);
    if (userInfo?.events?.length >= 25) {
      toast("Reached max limit");
      return;
    }
    setloaderSpinner(true);

    let event = {
      event_start_date: startDate == "" ? defaultStartDate : startDate,
      event_name: post?.title,
      event_location: post.location == undefined ? "" : post?.location?.value,
      event_end_date: endDate == "" ? defaultEndDate : endDate,
    };
    let payload = {
      user_id: user_id,
    };
    payload.events = [event];
    payload = encodeURIComponent(JSON.stringify(payload));
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/create", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      toast.success("Event saved");
      closeEventsModal();
      reset();
      setEmptyValues();
      setloaderSpinner(false);
      getUserData();
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };

  const updateEvent = async (post) => {
    console.log(post);
    setloaderSpinner(true);
    let event = {
      event_id: editedData.event_id,
      event_start_date: startDate == "" ? defaultStartDate : startDate,
      event_name: post?.title,
      event_location: post.location == "" ? "" : post?.location?.value,
      event_end_date: endDate == "" ? defaultEndDate : endDate,
    };
    let payload = {
      user_id: user_id,
    };
    payload.events = [event];
    payload = encodeURIComponent(JSON.stringify(payload));
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/update", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      toast.success("Event updated");
      closeEventsModal();
      getUserData();
      reset();
      setloaderSpinner(false);
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };
  return (
    <>
      <div>
        <form>
          <div className="container" style={{ padding: 0 }}>
            <div
              className="row"
              style={{
                marginTop: editClicked ? "1.50em" : "1.50em",
                marginBottom: "0em",
                background: "#ffffff",
                borderRadius: "9px",
                height: "2.5em",
                border: "1px solid #ccc",
                width: "300px",
                marginLeft: "30px",
              }}
            >
              <div className="col-12" style={{ paddingLeft: "0px" }}>
                <input
                  style={{
                    marginTop: "0.50em",
                    paddingLeft: "5px",
                    background: "inherit",
                    width: "100%",
                    border: "none",
                  }}
                  type="text"
                  {...register("title", {
                    required: true,
                    maxLength: 30,
                    pattern: /^[!@#&*+ 0-9a-zA-Z-]*$/,
                  })}
                  placeholder="Title *"
                />
              </div>
            </div>
            {errors.title?.type === "required" && (
              <div className="row error-title" style={{ margin: "5px" }}>
                <span
                  className="error-title-text"
                  style={{ marginLeft: "30px", fontSize: "12px" }}
                >
                  Please enter the title
                </span>
              </div>
            )}
            {errors.title?.type === "maxLength" && (
              <div className="row error-title" style={{ margin: "5px" }}>
                <span
                  className="error-title-text"
                  style={{ marginLeft: "30px", fontSize: "12px" }}
                >
                  Please enter less than 30 characters
                </span>
              </div>
            )}
            {errors.title?.type === "pattern" && (
              <div className="row error-title" style={{ margin: "5px" }}>
                <span
                  className="error-title-text"
                  style={{ marginLeft: "30px", fontSize: "12px" }}
                >
                  Only Alphanumeric characters !@#&*+ or - are allowed
                </span>
              </div>
            )}

            <div
              style={{
                borderRadius: "9px",
                marginTop: "1em",
                marginBottom: "0em",
                background: "#ffffff",
                borderRadius: "9px",
                height: "2.5em",
                border: "1px solid #ccc",
                width: "300px",
                marginLeft: "30px",
              }}
            >
              <div
                style={{
                  height: "100%",
                  paddingLeft: "0px",
                  marginTop: "-10px",
                }}
              >
                <Controller
                  name="location"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={locations}
                      placeholder="Event Location"
                      styles={{
                        control: (style) => ({
                          ...style,
                          background: "inherit",
                          padding: "10px 0px 0px 0px",
                          border: 0,
                          boxShadow: "none",
                        }),
                        valueContainer: (style) => ({
                          ...style,
                          padding: 2,
                        }),
                        placeholder: (style) => ({
                          ...style,
                          fontSize: "12px",
                        }),
                        container: (style) => ({
                          ...style,
                          width: "100%",
                        }),
                        menuList: (style) => ({
                          ...style,
                          height: "9em",
                        }),
                      }}
                    />
                  )}
                ></Controller>
              </div>
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <div>
                <div
                  className="row"
                  style={{ padding: "10px", marginLeft: "3px" }}
                >
                  <div
                    className="col-6"
                    style={{
                      borderRadius: "9px",
                      marginTop: "1em",
                      marginBottom: "0em",
                      background: "#ffffff",
                      borderRadius: "9px",
                      height: "3em",
                    }}
                  >
                    <DatePicker
                      placeholderText="Start Date"
                      minDate={new Date()}
                      value={startDate || defaultStartDate}
                      onChange={(e) => handleStartDate(e)}
                      className={classes.input}
                      format="dd/MM/y"
                      clearIcon={null}
                      calendarIcon={
                        <BiCalendar
                          style={{
                            backgroundColor: "rgb(70, 155, 141)",
                            color: "white",
                          }}
                          size={22}
                        />
                      }
                    />
                  </div>
                  <div
                    className="col-6"
                    style={{
                      borderRadius: "9px",
                      marginTop: "1em",
                      marginBottom: "0em",
                      background: "#ffffff",
                      borderRadius: "9px",
                      height: "3em",
                    }}
                  >
                    <DatePicker
                      placeholderText="End Date"
                      format="dd/MM/y"
                      minDate={startDate}
                      value={endDate || defaultEndDate}
                      onChange={handleEndDate}
                      className={classes.input}
                      clearIcon={null}
                      calendarIcon={
                        <BiCalendar
                          style={{
                            backgroundColor: "rgb(70, 155, 141)",
                            color: "white",
                          }}
                          size={22}
                        />
                      }
                    />
                  </div>
                </div>
              </div>
              {!editClicked && (
                <div
                  style={{
                    borderRadius: "9px",
                    marginTop: "1.7em",
                    height: "2.5em",
                    marginLeft: "30px",
                  }}
                >
                  <button
                    style={{
                      border: "1px solid rgb(30 138 121)",
                      padding: "0.9rem 0.7rem",
                      borderRadius: "5px",
                      float: "right",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      onClick={handleSubmit(addEvent)}
                    >
                      <MdAdd size={20} />
                      <span>Add One More</span>
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>

      <div>
        {" "}
        <Progressbar
          show={loaderSpinner}
          imageUrl={url}
          height="90"
          width="90"
          alignment="middle"
          alttext="Loading..."
        />
      </div>
      <div style={{ position: "absolute", marginTop: "57%" }}>
        {!editClicked && (
          <div style={{ margin: "0px 30px" }}>
            <button
              type="submit"
              className="profile-save-button"
              style={{ height: "2.5em" }}
              onClick={handleSubmit(saveEvents)}
            >
              Save
            </button>
            <button
              className="profile-close-button"
              style={{ height: "2.5em" }}
              onClick={closeEventsModal}
            >
              Close
            </button>
          </div>
        )}
        {editClicked && (
          <div style={{ margin: "0px 0px" }}>
            <button
              type="submit"
              className="pub-close-button"
              onClick={closeEventsModal}
              style={{ height: "2.5em" }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="pub-save-button"
              onClick={handleSubmit(updateEvent)}
              style={{ height: "2.5em" }}
            >
              Update Event
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Events;
