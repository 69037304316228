import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { getHeaders, logout } from "../../utils/utils";

const useCommentLoader = (
  doc_id,
  channel_id,
  feedTypeId,
  lastCommentId,
  socialInteraction
) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentsData, setCommentsData] = useState({});
  const [hasMore, setHasMore] = useState(true);

  const getComments = async (newComments) => {
    setLoading(true);
    setError(false);
    if (newComments) setComments([]);

    const payload = {
      doc_id,
      channel_id,
      feedTypeId,
      lastCommentId: newComments ? 0 : lastCommentId,
    };

    const res = await fetch(baseUrl + "v1/feedSocialInteractions/getComments", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {
      setLoading(false);
      setError(true);
      setHasMore(false);
      return;
    });

    const data = await res.json();

    if (data.status === "success") {
      if (payload.lastCommentId === 0) setComments([...data.data.comments]);
      else
        setComments((prevComments) => {
          return [...new Set([...prevComments, ...data.data.comments])];
        });

      setCommentsData((prev) => ({
        ...prev,
        channel_id: data.data.channel_id,
        feedTypeId: data.data.feedTypeId,
        lastCommentId: data.data.lastCommentId,
        socialInteraction: data.data.socialInteraction,
      }));

      setLoading(false);
      setHasMore(data.data.lastCommentId !== -1);
    } else {
      setLoading(false);
      setError(true);
      setHasMore(false);
      return;
    }
  };

  useEffect(() => {
    if (feedTypeId && channel_id) getComments();
  }, [lastCommentId]);

  useEffect(() => {
    if (feedTypeId && channel_id) getComments(true);
  }, [socialInteraction]);

  return { loading, error, comments, hasMore, commentsData };
};

export default useCommentLoader;
