import { useState, useEffect, useRef, useCallback } from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import React from "react";
import Progressbar from "../progressbar";
import { Link, useParams, useHistory } from "react-router-dom";
import publication from "../assets/new_profile_icons/publication.svg";
import online from "../assets/new_profile_icons/ic_online.svg";
import print from "../assets/new_profile_icons/ic_print.svg";
import "react-responsive-modal/styles.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useForm, Controller, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import Select from "react-select";
import { MdEdit } from "react-icons/md";
import deleteIcon from "../assets/Delete-1X.png";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";
import { MdArrowBackIos } from "react-icons/md";
import Typography from "@mui/material/Typography";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { IoArrowBackOutline } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import "../profile/profile.css";
import { Modal } from "react-responsive-modal";
import doneImage from "../assets/Web Icons/Icon material-done.svg";

const useStyles = makeStyles({
  smallRadioButton: {
    "& svg": {
      width: "1.5em",
      height: "1.5em",
    },
  },
});
const useStylesLabel = makeStyles((theme) => ({
  label: {
    fontSize: "1.5rem !important",
  },
}));
const styles = makeStyles((theme) => ({
  radio: {
    colorPrimary: {
      "&$checked": {
        color: "blue",
      },
    },
    checked: {},
  },
}));
const MobilePublications = ({
  defaultChannel,
  background,
  profile_pic,
  user_verification_info,
}) => {
  const {
    submitMandatoryInfo,
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm();

  const { user } = useParams();
  //console.log("data"+setCategoryListInfo)

  const history = useHistory();
  const user_id = getDocId();
  const headers = getHeaders();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  //////////////////////////////
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const [otherUser, setOtherUser] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const classes = useStyles();
  const classesLabel = useStylesLabel();
  const classesRadio = styles;
  const [journals, setJournals] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState("print");
  const [userInfo, setUserInfo] = useState([]);
  const [editClicked, setEditClicked] = useState(false);
  const [editedData, setEditedData] = useState();
  const [deletedData, setDeletedData] = useState();
  const closeIconHide = <img src={close} ></img>;
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const [openPostSuccessView, setOpenPostSuccessView] = useState(false);
  const [updatePubModal, setUpdatePubModal] = useState(false);
  const closeUpdatePubModal = () => {    
    setValue("journal",'');
    setSelectedValue("print"); 
    reset();
    setEditClicked(false); 
    setDeletedData('');
    setEditedData('');
    setUpdatePubModal(false);
  };
  
  const showUpdatePubModal = (data) => {  
      setState({ ...state, ["bottom"]: false });   
      setUpdatePubModal(true);
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if(user == "true"){
      setOtherUser(true);
    }else{
      setOtherUser(false);
    }
    
    getUserInfo();
    autoSuggestions();
  }, []);

  const getUserInfo = async () => {
    setloaderSpinner(true);
    const payload = {
      user_id,
    };
    const res = await fetch(baseUrl + "v1/userProfile/view", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();

    if (data.status === "success") {
      setUserInfo(data.data);
      setloaderSpinner(false);
      //setDefaultFormValue(data.data);
    }
  };

  const autoSuggestions = async () => {
    const payload = { journals: "journals" };

    const res = await fetch(baseUrl + "v2/autoSuggestions", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let journals = [];

    if (data.status === "success") {
      for (const city of data.data.journals) {
        journals.push({ value: city, label: city });
      }
    }

    setJournals(journals);
  };
  const editPrintPub = (e, data) => {  
    console.log(data)  
    setValue("title", data?.title);
    setValue("authornames", data?.author_names);
    if(data.journal == ""){
      setValue("journal", '');
    }else{
      setValue("journal", {
        label: data?.journal,
        value: data?.journal,
      });
    }  
    setSelectedValue("print"); 
    setEditClicked(true);
    setEditedData(data);
    toggleDrawer(e, "bottom", true);
  };
  
  const hideSuccessView = () => {
    setDeletedData('');
    setOpenPostSuccessView(false);
  };
  const deletePrintPub = (event, data) => {
    setOpenPostSuccessView(true);
    setDeletedData(data);
  };
  const deleteOnlinePub = (event, data) => {   
    setOpenPostSuccessView(true);
    setDeletedData(data);
  };
  const editOnlinePub = (e, data) => {    
    setValue("title", data?.title);
    setValue("authornames", data?.author_names);    
    if(data.journal == ""){
      setValue("journal", '');
    }else{
      setValue("journal", {
        label: data?.journal,
        value: data?.journal,
      });
    }  
    setValue("websiteurl", data?.webpg_link);
    setSelectedValue("online");
    setEditClicked(true); 
    setEditedData(data);    
    toggleDrawer(e, "bottom", true); 
  };
  const toggleDrawer = (event, anchor, open) => {
    if(!open){
      setValue("journal",'');
      setSelectedValue("print"); 
      reset();
      setEditClicked(false); 
      setDeletedData('');
      setEditedData('');
    }   
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    >
      <AppBar
        position="static"
        style={{
          backgroundColor: "white",
          color: "black",
          marginTop: "0px",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <Typography
            style={{
              font: "bold 20px / 20px roboto",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          ></Typography>
          <IconButton
            style={{ marginLeft: "92%" }}
            onClick={(e) => toggleDrawer(e, anchor, false)}
          >
            <CancelOutlinedIcon fontSize="large" />
          </IconButton>
        </Toolbar>
        <form>
        <div style={{ padding: "1em" }}>
          {!editClicked && <div
          >
            <RadioGroup
              row
              name="controlled-radio-buttons-group"
              value={selectedValue}
              onChange={handleChange}
              defaultValue="print"
            >
              <FormControlLabel
                className={classes.smallRadioButton}
                classes={classesLabel}
                value="print"
                control={<Radio color="success" />}
                label="Print"
              />
              <FormControlLabel
                className={classes.smallRadioButton}
                classes={classesLabel}
                value="online"
                control={<Radio color="success" />}
                label="Online"
              />
            </RadioGroup>
          </div>}
          <div
            style={{
              marginTop: "1em",
              marginBottom: "0em",
              background: "#ffffff",
              borderRadius: "9px",
              height: "3em",
              border: "1px solid grey",
              width: "100%",
            }}
          >
            <div  style={{ paddingLeft: "10px",marginTop:"10px" }}>
              <input
                style={{
                  background: "inherit",
                  width: "100%",
                  border: "none",
                }}
                type="text"
                {...register("title", {
                  required: true,
                  maxLength:100
                })}
                placeholder="Title *"
              />
            </div>
          </div>
          {errors.title?.type === "required" && (
          <div className="row error-title">
            <span className="error-title-text" style={{ marginLeft: "0px",fontSize:"14px" }}>
              Please enter the title
            </span>
          </div>
        )}
        {errors.title?.type === "maxLength" && (
          <div className="row error-title" >
          <span className="error-title-text" style={{ marginLeft: "0px",fontSize:"14px" }}>
            Please enter less than 100 characters
          </span>
          </div>
        )}
          <div
            style={{
              marginTop: "1em",
              marginBottom: "0em",
              background: "#ffffff",
              borderRadius: "9px",
              height: "3em",
              border: "1px solid grey",
              width: "100%",
            }}
          >
            <div  style={{ paddingLeft: "10px",marginTop:"10px" }}>
              <input
                style={{
                  background: "inherit",
                  width: "100%",
                  border: "none",
                }}
                type="text"
                {...register("authornames", {
                  maxLength:100
                })}
                placeholder="Author Names"
              />
            </div>
          </div>
          {errors.authornames?.type === "maxLength" && (
          <div className="row error-title" >
          <span className="error-title-text" style={{ marginLeft: "0px",fontSize:"14px" }}>
            Please enter less than 100 characters
          </span>
          </div>
        )}

          {selectedValue == "online" && (
            <div
              style={{
                marginTop: "1em",
                marginBottom: "0em",
                background: "#ffffff",
                borderRadius: "9px",
                height: "3em",
                border: "1px solid grey",
                width: "100%",
              }}
            >
              <div  style={{ paddingLeft: "10px",marginTop:"10px" }}>
                <input
                  style={{
                    marginTop: "0.50em",
                    paddingLeft: "5px",
                    background: "inherit",
                    width: "100%",
                    border: "none",
                  }}
                  type="text"
                  {...register("websiteurl")}
                  placeholder="Website URL "
                />
              </div>
            </div>
          )}
          <div>
            <div
              style={{
                borderRadius: "9px",
                marginTop: "1em",
                marginBottom: "0em",
                background: "#ffffff",
                borderRadius: "9px",
                height: "3em",
                border: "1px solid grey",
                width: "100%",
              }}
            >
              <div
                style={{
                  height: "100%",
                  paddingLeft: "0px",
                  marginTop: "-5px",
                }}
              >
                <Controller
                  name="journal"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={journals}
                      placeholder="Name of The Jounal"
                      styles={{
                        control: (style) => ({
                          ...style,
                          background: "inherit",
                          padding: "10px 10px 10px 0",
                          border: 0,
                          boxShadow: "none",
                        }),

                        placeholder: (style) => ({
                          ...style,
                          fontSize: "13px",
                        }),
                        container: (style) => ({
                          ...style,
                          width: "100%",
                        }),
                        menuList: (style) => ({
                          ...style,
                          overflowY: "scroll",
                        }),
                      }}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </div>
        </div>
        </form>
        {!editClicked && 
        // <div style={{ margin: "30px 30px" }}>
        //   <button type="submit" className="profile-save-button" onClick={handleSubmit(savePublication)} >
        //     Save
        //   </button>
        //   <button type="submit" className="profile-close-button" onClick={(e) => toggleDrawer(e, anchor, false)}>
        //     Close
        //   </button>
        // </div>
        <div style={{ padding: "1em" }}>
        <button
          type="submit"
          style={{
            backgroundColor: "#469b8d",
            height: "3em",
            width: "100%",
            margintop: "5em",
          }}
          className="post-modal-submit-button"
          onClick={handleSubmit(savePublication)}
        >
          Add Publication
        </button>
      </div>}
        {editClicked && <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between",padding: "1em" }}>         
          <button type="submit" className="pub-close-button" onClick={(e) => toggleDrawer(e, anchor, false)} style={{marginLeft:"0px"}}>
            Cancel
          </button>
          <button type="submit" className="pub-save-button" onClick={handleSubmit(showUpdatePubModal)}>
            Update Publication
          </button>
        </div>}
      </AppBar>
    </Box>
  );

  const deletePublcation = async () => {
    console.log(editedData);
    setloaderSpinner(true);
    let pub={
    }
    let payload = {
      user_id: user_id,
    };
    if (deletedData.pub_type == "print") {
      pub.print_pub_id=deletedData.print_pub_id;
      payload.print_pub_history = [pub];
    } else if (deletedData.pub_type == "online") {
      pub.online_pub_id=deletedData.online_pub_id
      payload.online_pub_history = [pub];
    }
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/delete", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {     
      setloaderSpinner(false);
      setOpenPostSuccessView(false);
      toast.success('Publication deleted');
      setEditClicked(false); 
      setDeletedData('');
      setEditedData('');
      reset();
      getUserInfo();
    } else {
      setloaderSpinner(false);
      setOpenPostSuccessView(false);
      toast.error(data.errorMsg);
    }
  };


  const savePublication = async (post) => {
    console.log(post);
    setloaderSpinner(true);
    let pub={
      author_names: post.authornames,
      journal: post.journal == undefined?'':post.journal.value,
      pub_type: selectedValue == "print" ?"print":"online",
      title: post.title,
      webpg_link: selectedValue == "print" ? "":post.websiteurl
    }
    let payload = {
      user_id: user_id,
    };
    if (selectedValue == "print") {
      payload.print_pub_history = [pub];
    } else if (selectedValue == "online") {
      payload.online_pub_history = [pub];
    }
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/create", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      setEditClicked(false); 
      setDeletedData('');
      setEditedData('');
      toast.success('Publication saved');
      setState({ ...state, ["bottom"]: false });
      reset();
      setSelectedValue("print"); 
      setloaderSpinner(false);
      getUserInfo();
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };
  
  const updatePublication = async (post) => {
    console.log(post);
    setloaderSpinner(true);
    let pub={
      author_names: post.authornames,
      journal: post.journal == undefined?'':post.journal.value,
      pub_type: selectedValue == "print" ?"print":"online",
      title: post.title,
      webpg_link: editedData.pub_type == "print" ? "":post.websiteurl
    }
    let payload = {
      user_id: user_id,
    };
    if (editedData.pub_type == "print") {
      pub.print_pub_id=editedData.print_pub_id;
      payload.print_pub_history = [pub];
    } else if (editedData.pub_type == "online") {
      pub.online_pub_id=editedData.online_pub_id;
      payload.online_pub_history = [pub];
    }
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/update", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {  
      setUpdatePubModal(false);
      reset(); 
      setEditClicked(false); 
      setDeletedData('');
      setEditedData('');
      setState({ ...state, ["bottom"]: false });   
      toast.success('Profile updated');
      setSelectedValue("print"); 
      setloaderSpinner(false);
      getUserInfo();
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };
  //toggleDrawer(e, "bottom", true);
///ebiz-web/app/m/v1/userProfile/delete
///ebiz-web/app/m/v1/userProfile/Update
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "white",
          zIndex: 10,
          // overflow: "auto",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100vw",
        }}
      >
        <div
          style={{
            position: "-webkit-sticky",
            position: "relative",
            top: "0",
            left: "0",
            width: "100%",
            zIndex: "25",
            flexShrink: "0",
            marginBottom: "10px",
          }}
          className="mobile-header-wrapper sticky d-block d-lg-none"
        >
          <div
            className="mobile-header position-relative "
            style={{
              backgroundColor: "#469b8d",
              font: "bold 20px / 20px roboto",
              textAlign: "center",
            }}
          >
            <div className="mobile-responsive">
              <div className="brand-logo " style={{ width: "106%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0 1em",
                  }}
                >
                  <MdArrowBackIos
                    color="white"
                    size={25}
                    onClick={() => history.goBack()}
                    //onClick={() => history.push("/profile")}
                  />
                  <h2 style={{ color: "white", marginLeft: "1.5em" }}>
                    Publications
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!loaderSpinner && <div
          className="conatctDetails"
          style={{ height: "80%", overflow: "auto" }}
        >
          <div>
            {!(
              (!userInfo.print_pub_history ||
                userInfo?.print_pub_history?.length === 0) &&
              (!userInfo.online_pub_history ||
                userInfo?.online_pub_history?.length === 0)
            ) ? (
              <Typography>
                {userInfo.print_pub_history
                  ? userInfo.print_pub_history.map((pub, key) => (
                      <div key={key}>
                        <div className="line"></div>
                        <div
                          style={{
                            padding: "0px",
                            marginLeft: "17px",
                          }}
                        >
                          <p className="print-head">
                            <img src={print} className="print-img"></img>{" "}
                            Printed
                          </p>
                          <h6
                            className="timeline-title"
                            style={{
                              color: "rgb(30, 138, 121)",
                              margin: "10px 0px 10px 0px",
                              wordBreak:"break-all"
                            }}
                          >
                            {pub.title}
                          </h6>

                          <div className="author-title">{pub.author_names}</div>
                          <div
                            className="text-muted"
                            style={{ fontSize: "15px" }}
                          >
                            {pub.journal}
                          </div>
                          {!otherUser && <div>
                            <p
                              className="print-head edit-delete"
                              style={{
                                marginTop: "5px",
                              }}
                            >
                              <img
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => deletePrintPub(e,pub)}
                                src={deleteIcon}
                              />

                              <div
                                style={{
                                  height: "25px",
                                  width: "65px",
                                  marginLeft: "30px",
                                  fontSize: "16px",
                                }}
                                onClick={(e) =>{ editPrintPub(e,pub)}}
                              >
                                <MdEdit size={18} />
                                Edit
                              </div>
                            </p>
                          </div>}
                          <hr></hr>
                        </div>
                      </div>
                    ))
                  : ""}
                {userInfo.online_pub_history ? (
                  userInfo.online_pub_history.map((pub, key) => (
                    <div key={key}>
                      <div className="line"></div>
                      <div
                        style={{
                          padding: "0px",
                          marginTop: "5px",
                        }}
                      >
                        <p
                         className="print-head"
                          style={{
                            padding: "0px",
                            marginLeft: "15px",
                          }}
                        >
                          <img src={online} className="print-img"></img> Online
                        </p>
                        <div
                          style={{
                            marginLeft: "15px",
                          }}
                        >
                          <h6
                            className="timeline-title"
                            style={{
                              color: "rgb(30, 138, 121)",
                              margin: "10px 0px 10px 0px",
                              wordBreak:"break-all"
                            }}
                          >
                            {pub.title}
                          </h6>

                          <div className="author-title">{pub.author_names}</div>
                          <div
                            className="text-muted"
                            style={{ fontSize: "15px" }}
                          >
                            {pub.journal}
                          </div>

                          <div
                            className="text-muted"
                            style={{ fontSize: "15px" }}
                          >
                            {pub.webpg_link}
                          </div>
                          
                          {!otherUser && <div>
                            <p
                              className="print-head"
                              style={{
                                display: "flex",
                                justifyContent: "right",
                                marginBottom: "10px",
                                marginTop: "10px",
                              }}
                            >
                              <img
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => deleteOnlinePub(e,pub)}
                                src={deleteIcon}
                              />

                              <div
                                style={{
                                  height: "25px",
                                  width: "65px",
                                  marginLeft: "30px",
                                  fontSize: "16px",
                                }}
                                onClick={(e) =>{ editOnlinePub(e,pub)}}
                              >
                                <MdEdit size={18} />
                                Edit
                              </div>
                            </p>
                          </div>}
                          <hr></hr>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className="line"></div>
                  </>
                )}
              </Typography>
            ) : (
              <div style={{ marginBottom: "30%" }}>
                <img src={publication} className="mPub"></img>
                <div className="pub-head">
                  <p>Add your Publication and show it </p>
                  <p>to your fellow collegue </p>
                </div>
              </div>
            )}
          </div>
        </div>}

        <div>
          {" "}
          <Progressbar
            show={loaderSpinner}
            imageUrl={url}
            height="90"
            width="90"
            alignment="middle"
            alttext="Loading..."
          />
        </div>
        {!otherUser && <div
          className="post-modal-submit"
          style={{ marginBottom: 0, height: "6em" }}
        >
          <button
            type="submit"
            style={{
              backgroundColor: "#469b8d",
              height: "3.5em",
              width: "20em",
            }}
            onClick={(e) => {
              toggleDrawer(e, "bottom", true);
            }}
            className="post-modal-submit-button"
          >
            Add Publication
          </button>
          <SwipeableDrawer
            anchor="bottom"
            open={state["bottom"]}
            onClose={(e) => toggleDrawer(e, "bottom", false)}
            onOpen={(e) => toggleDrawer(e, "bottom", true)}
          >
            {list("bottom")}
          </SwipeableDrawer>
        </div>}
      </div>
      <Modal
          open={updatePubModal}
          closeModal={closeUpdatePubModal}
          showCloseIcon={true}
          onClose={closeUpdatePubModal}
          classNames={{ modal: "edit-delete-model" }}
          styles={{ modal: { padding: 0, overflow: "hidden",backgroundColor:"white" } }}
        >
          <div className="container-fluid">
          <div
            className="row"
            style={{marginTop:"70px" }}
          >
            <div className="col-sm-12 ">
            <div
                style={{
                  color: "black",
                  font: "18px/21px roboto",
                  marginBottom: "30px",
                  fontWeight:"bold"
                }}
              >
                Update Publication
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "grey",
                  marginBottom: "10px",
                  font: "18px/21px roboto",
                  fontWeight:"600px"
                }}
              >
                {" "}
                <p>Are  you sure want to</p>
                <p>Update your publication  entry?</p>
              </div>
            </div>
            <div className="col-sm-12 " >
              
              <div style={{ margin: "30px 0px" }}>         
              <button type="submit" className="pub-close-button"  onClick={closeUpdatePubModal} style={{width:"7em",height:"2.5em",marginLeft:"0em"}}>
            Cancel
          </button>
          <button type="submit" className="pub-save-button" onClick={handleSubmit(updatePublication)} style={{width:"7em",height:"2.5em"}}>
            Update 
          </button>
          
        </div>
            </div>
          </div>
        </div> 
        </Modal>
      <Modal
        open={openPostSuccessView}
        blockScroll={true}
        onClose={hideSuccessView}
        closeIcon={closeIconHide}
        closeModal={hideSuccessView}
        classNames={{ modal: "edit-delete-model" }}
        styles={{ modal: { backgroundColor:"white" } }}
      >
        <div className="container-fluid">
          <div
            className="row"
            style={{height: "251px",marginTop:"45px" }}
          >
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "black",
                  font: "18px/21px roboto",
                  marginBottom: "30px",
                  fontWeight:"bold"
                }}
              >
                Delete Publication
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "grey",
                  marginBottom: "10px",
                  font: "18px/21px roboto",
                  fontWeight:"600px"
                }}
              >
                {" "}
                <p>Do you want delete</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "black",
                  marginBottom: "10px",
                  font: "18px/21px roboto",
                  fontWeight:"bold"
                }}
              >
                {" "}
                <p style={{wordBreak:"break-all"}}>{deletedData?.title}</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "grey",
                  marginBottom: "10px",
                  font: "20px/20px roboto",
                  fontWeight:"600px"
                }}
              >
                {" "}
                <p>Publication?</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              
              <div style={{ margin: "25px -0px" }}>         
          <button type="submit" className="pub-close-button"  onClick={(e) => hideSuccessView(e)} style={{ height: "2.5em",marginLeft:"0em" }}>
            Cancel
          </button>
          <button type="submit" className="pub-save-button" style={{backgroundColor:"#FF2F00",marginLeft:"20px",height: "2.5em",width:"7em"}} onClick={() => deletePublcation()}>
            Delete 
          </button>
        </div>
            </div>
          </div>
        </div>
      </Modal> 
    </>
  );
};

export default MobilePublications;
