import { Modal } from "react-responsive-modal";
import { IoIosCloseCircle } from "react-icons/io";
import { useRef } from "react";

const EditModal = ({
  modalTitle,
  open,
  closeModal,
  children,
  modalClassName = "postModel",
  ...rest
}) => {
  const focusElement = useRef(null);
  return (
    <Modal
      ref={focusElement}
      initialFocusRef={focusElement}
      open={open}
      onClose={closeModal}
      showCloseIcon={false}
      classNames={{ modal: modalClassName }}
      styles={{ modal: { padding: 0 } }}
      {...rest}
    >
      <div className="post-modal">
        {/* <form onSubmit={handleSubmit(bruh)}> */}
        <div className="post-modal-header">
          <span>{modalTitle}</span>
          <IoIosCloseCircle
            style={{ cursor: "pointer" }}
            onClick={() => closeModal()}
            size={32}
          />
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default EditModal;
