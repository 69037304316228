import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
// import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import person from "../../assets/Icons/Vector.svg";
import call from "../../assets/sign_up_and_sign_in_icons/Call.svg";
import email from "../../assets/sign_up_and_sign_in_icons/Mail.svg";
// import Tooltip from "@material-ui/core/Tooltip";
// import PhoneInput2 from "../ForgotPasswordComponents/PhoneInput";
// import { useMediaPredicate } from "react-media-hook";
import doctor from "../../assets/Icons/Doctor.svg";
import Select from "react-select";

import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import CountrySelect from "./CountrySelect";
import { getHeaders } from "../../utils/utils";

import toast from "react-hot-toast";

const PersonalInfo = ({ sendOTP }) => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();

  const [country, setCountry] = useState("IN");
  const [specialities, setSpecialities] = useState();

  useEffect(() => {
    getSpecialties();
  }, []);

  useEffect(() => {
    setValue("phone", "");
    clearErrors(["phone"]);
    // eslint-disable-next-line
  }, [country]);

  const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
  useEffect(() => {
    if (isPhoneEmpty) {
      clearErrors(["phone"]);
      setIsPhoneEmpty(false);
    }
  }, [isPhoneEmpty]);

  const validatePhoneInput = (phone) => {
    let isValid = true;

    if (!phone) {
      isValid = false;
      // error = "This field is required";
    }
    if (!isPossiblePhoneNumber(phone)) {
      isValid = false;
      // error = "Please enter valid number";
    }
    if (!isValidPhoneNumber(phone)) {
      isValid = false;
      // error = "Please enter valid number";
    }

    return isValid;
  };
  // const biggerThan400 = useMediaPredicate("(min-width: 400px)");

  const getSpecialties = async () => {
    const res = await fetch(baseUrl + "v1/getSpecialties", {
      method: "POST",
      headers,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let specialities = [];
    if (data.status === "success") {
      for (const speciality of data.data.specialities) {
        specialities.push({ value: speciality, label: speciality });
      }
    }
    setSpecialities(specialities);
  };

  return (
    <form onSubmit={handleSubmit((dt) => sendOTP(dt))}>
      <div className="container" style={{ marginTop: "10px", padding: 0 }}>
        <div className="signup-name-row">
          <select
            {...register("salutation", { required: true })}
            className="dr-dropdown"
            style={{
              backgroundColor: "#F6F6F6",
              height: "100%",
              width: "60px",
            }}
          >
            <option value="Dr.">Dr.</option>
            <option value="Mr.">Mr.</option>
            <option value="Mrs.">Mrs</option>
            <option value="Ms.">Ms</option>
          </select>

          <div
            style={{
              borderRadius: "9px",
              background: "#F6F6F6",
              height: "3.5em",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img src={person} alt="" style={{ padding: "1em" }} />

              <input
                style={{
                  // marginTop: "1em",
                  // paddingLeft: "5px",
                  background: "inherit",
                  width: "100%",
                  border: "none",
                }}
                type="text"
                {...register("fname", {
                  required: true,
                  minLength: 1,
                  maxLength: 30,
                  pattern: /^\s*[A-za-z]+\s*$/,
                })}
                placeholder="Enter First Name *"
              />
            </div>
          </div>

          <div
            style={{
              borderRadius: "9px",
              background: "#F6F6F6",
              height: "3.5em",
              width: "100%",
            }}
            className="signup-lastname"
          >
            <div
              // className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img src={person} alt="" style={{ padding: "1em" }} />

              <input
                style={{
                  background: "inherit",
                  width: "100%",
                  border: "none",
                }}
                type="text"
                {...register("lname", {
                  required: true,
                  minLength: 1,
                  maxLength: 30,
                  pattern: /^\s*[A-za-z]+\s*$/,
                })}
                placeholder="Enter Last Name *"
              />
            </div>
          </div>
        </div>

        {errors.fname?.type === "pattern" && (
          <div className="row error-title">
            <span className="error-title-text">
              Please don't use numbers, special characters or spaces
            </span>
          </div>
        )}
        {errors.fname?.type === "required" && (
          <div className="row error-title">
            <span className="error-title-text">
              {" "}
              Please enter your first name
            </span>
          </div>
        )}
        {errors.fname?.type === "maxLength" && (
          <div className="row error-title">
            <span className="error-title-text">max length is 30</span>
          </div>
        )}
        {errors.fname?.type === "minLength" && (
          <div className="row error-title">
            <span className="error-title-text">min length is 1</span>
          </div>
        )}
        {errors.lname?.type === "pattern" && (
          <div className="row error-title">
            <span className="error-title-text">
              Please don't use numbers, special characters or spaces
            </span>
          </div>
        )}
        {errors.lname?.type === "required" && (
          <div className="row error-title">
            <span className="error-title-text">
              {" "}
              Please enter your last name
            </span>
          </div>
        )}
        {errors.lname?.type === "maxLength" && (
          <div className="row error-title">
            <span className="error-title-text">max length is 30</span>
          </div>
        )}
        {errors.lname?.type === "minLength" && (
          <div className="row error-title">
            <span className="error-title-text">min length is 1</span>
          </div>
        )}
        <div
          // className="row"
          // style={{
          //   margin: "1em 0",
          //   background: "#F6F6F6",
          //   borderRadius: "9px",
          //   height: "3.5em",
          // }}
          style={{
            borderRadius: "9px",
            background: "#F6F6F6",
            height: "3.5em",
            width: "100%",
            margin: "1em 0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <img src={email} alt="" style={{ margin: "1em" }} />
            <input
              style={{
                background: "inherit",
                width: "100%",
                border: "none",
              }}
              type="email"
              {...register("email", {
                required: true,
                maxLength: 150,
                pattern: {
                  value: /^\s*[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}\s*$/i,
                  message: "invalid email address",
                },
              })}
              placeholder="Enter Email *"
            />
          </div>
        </div>
        {errors.email?.type === "required" && (
          <div className="row error-title">
            <span className="error-title-text">
              Please enter a valid email id
            </span>
          </div>
        )}
        {errors.email?.type === "maxLength" && (
          <div className="row error-title">
            <span className="error-title-text">max length is 150</span>
          </div>
        )}
        {errors.email?.type === "pattern" && (
          <div className="row error-title">
            <span className="error-title-text">
              Please enter valid email id
            </span>
          </div>
        )}

        <div className="row " style={{ margin: "1em 0", height: "3.5em" }}>
          <div className="col-5 col-sm-4" style={{ paddingLeft: "0px" }}>
            <CountrySelect
              isRegister={true}
              value={country}
              onChange={setCountry}
              labels={en}
            />
          </div>
          <div
            className="col-7 col-sm-8"
            style={{
              padding: "0px",
              background: "#F6F6F6",
              borderRadius: "9px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img src={call} alt="" style={{ margin: "1em" }} />

              <Controller
                name="phone"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => validatePhoneInput(value),
                }}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    country={country}
                    value={value}
                    onChange={(value) => {
                      if (!value) setIsPhoneEmpty(true);
                      onChange(value);
                    }}
                    placeholder="Contact Number *"
                    // international={true}
                    // withCountryCallingCode={true}
                    style={{
                      background: "inherit",
                      width: "100%",
                      border: "none",
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>

        {errors.phone && (
          <div className="row error-title">
            <span className="error-title-text">
              Please enter a valid phone number
            </span>
          </div>
        )}

        <div
          // className="row"
          // style={{
          //   margin: "1em 0",
          //   background: "#F6F6F6",
          //   borderRadius: "9px",
          //   height: "3.5em",
          // }}
          style={{
            borderRadius: "9px",
            background: "#F6F6F6",
            height: "3.5em",
            width: "100%",
            margin: "1em 0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <img src={doctor} alt="" style={{ margin: "1em" }} />
            <Controller
              name="speciality"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Enter Speciality *"
                  options={specialities}
                  styles={{
                    control: (style) => ({
                      ...style,
                      background: "inherit",
                      border: 0,
                      boxShadow: "none",
                      padding: "10px 10px 10px 0",
                    }),
                    container: (style) => ({
                      ...style,
                      width: "100%",
                    }),
                    placeholder: (style) => ({
                      ...style,
                      fontSize: "14px",
                    }),
                  }}
                />
              )}
            />
          </div>
        </div>
        {errors.speciality && (
          <div className="row error-title">
            <span className="error-title-text">
              Please enter your speciality
            </span>
          </div>
        )}
      </div>

      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
      >
        <input
          style={{ width: "20px", height: "20px", margin: 0 }}
          type="checkbox"
          {...register("policy", { required: true })}
        />
        <span
          className="form-check-label"
          style={{
            marginLeft: "5px",
            color: "white",
            fontSize: "0.8em",
          }}
          htmlFor="exampleCheck"
        >
          I agree with the WhiteCoats{" "}
          <a
            href="https://www.whitecoats.com/terms-of-use.html"
            target="blank"
            style={{
              color: "yellow",
              textDecoration: "underline",
            }}
          >
            Terms of Use
          </a>{" "}
          &{" "}
          <a
            href="https://www.whitecoats.com/privacy-policy.html"
            target="blank"
            style={{
              color: "yellow",
              textDecoration: "underline",
            }}
          >
            Privacy Policy
          </a>{" "}
        </span>
      </div>
      <div className="error-title">
        {errors.policy && (
          <span className="error-title-text">
            Please agree with terms. Read before you submit
          </span>
        )}
      </div>
      <div className="button-container">
        <button
          type="submit"
          style={{
            backgroundColor: "#0FB59B",
            borderRadius: "9px",
            marginTop: 0,
          }}
          className="submit-btn"
        >
          Create My Account
        </button>
      </div>
    </form>
  );
};

export default PersonalInfo;
