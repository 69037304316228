import call from "../../assets/sign_up_and_sign_in_icons/Call.svg";
import PhoneInput from "react-phone-number-input/input";
import { Controller } from "react-hook-form";

import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

const PhoneInput2 = ({
  errors,
  contactInputError = {
    error: false,
  },
  isOtpSent = false,
  control,
}) => {
  const validatePhoneInput = (phone) => {
    let isValid = true;

    if (!phone) {
      isValid = false;
      // error = "This field is required";
    }
    if (!isPossiblePhoneNumber(phone)) {
      isValid = false;
      // error = "Please enter valid number";
    }
    if (!isValidPhoneNumber(phone)) {
      isValid = false;
      // error = "Please enter valid number";
    }

    return isValid;
  };

  return (
    <div className="container" style={{ marginTop: "15px" }}>
      <div
        className="row"
        style={{
          marginTop: "1em",
          marginBottom: "1em",
          background: "#F6F6F6",
          borderRadius: "9px",
          height: "3.5em",
        }}
      >
        <div className="col-2">
          <img src={call} alt="" style={{ margin: "1.1em" }} />
        </div>

        <div className="col-10" style={{ paddingLeft: "0px" }}>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: true,
              validate: (value) => validatePhoneInput(value),
              // pattern: /[^a-zA-Z]+/,
            }}
            readOnly={isOtpSent ? true : false}
            defaultValue={"+91 "}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                // country="IN"
                // defaultCountry="IN"
                // defaultValue="+91 "

                value={value}
                onChange={onChange}
                international={true}
                withCountryCallingCode={true}
                style={{
                  marginTop: "1.1em",
                  paddingLeft: "5px",
                  background: "inherit",
                  width: "100%",
                  border: "none",
                }}
                disabled={isOtpSent ? true : false}
              />
            )}
          />
        </div>
      </div>
      <div className="row error-title">
        {/* {errors.phone?.type === "required" && (
          <span className="error-title-text">
            {" "}
            Please enter a valid phone number
          </span>
        )} */}
        {errors.phone && (
          <span className="error-title-text">
            Please enter a valid phone number
          </span>
        )}
      </div>
      <div className="row error-title">
        {contactInputError.error && (
          <span className="error-title-text">{contactInputError.errorMsg}</span>
        )}
      </div>
    </div>
  );
};

export default PhoneInput2;
