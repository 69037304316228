import { useState, useEffect } from "react";

import toast from "react-hot-toast";
import useCountDown from "react-countdown-hook";
import OtpInput from "react-otp-input";
import { Modal } from "react-responsive-modal";
import { formatTime } from "../../utils/utils";
import password from "../../assets/Icons/password.svg";

const OtpModal = ({
  open,
  closeModal,
  recievedOtp,
  setRecievedOtp,
  signup,
  formData,
}) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const headers = {
    accept: "application/json; charset=ISO-8859-1",
    "x-time-zone": "+5.30",
    "x-device-id": "911422351920594",
    "x-app-version": "1.0.50",
    "x-app-channel-name": "IOS",
    "x-device-model": "iPhone 4S",
    "x-device-os-version": "9.3.4",
    "x-stay-loggedin": " yes ",
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  };
  const [otpInvalid, setOtpInvalid] = useState(false);

  const phone = formData.phone;
  const email = formData.email;

  const [state, setstate] = useState({
    otp: "",
  });

  const [enableResendOtp, setEnableResenOtp] = useState(false);

  const initialTime = 1800 * 1000;
  const interval = 1000;
  const [timeLeft, { start }] = useCountDown(initialTime, interval);

  // const [resendTimeLeft, { start: startResendTimer }] = useCountDown(
  //   30000,
  //   1000
  // );

  useEffect(() => {
    if (open) {
      start();
      // startResendTimer();
      setstate((prevState) => ({
        ...prevState,
        otp: "",
      }));
      setEnableResenOtp(false);
      setTimeout(function () {
        setEnableResenOtp(true);
      }, 30000);
    }

    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    setOtpInvalid(false);
  }, [state]);

  const submitOtp = async (event) => {
    event.preventDefault();

    if (timeLeft === 0) return;
    if (recievedOtp === state.otp) {
      professionDetails();
    } else {
      setOtpInvalid(true);
    }
  };

  const professionDetails = () => {
    signup(formData);
  };

  const reSendOTP = async () => {
    if (!enableResendOtp) return;

    setstate((prevState) => ({
      ...prevState,
      otp: "",
    }));

    let reqPayload = {
      contact: phone,
      contactType: "PHONE",
      isRegister: true,
      email: email,
    };

    const res = await fetch(baseUrl + "sendOTP", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(reqPayload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();

    if (data.status === "success") {
      setRecievedOtp(data.otp);
      toast.success("otp sent");
      start();
      setEnableResenOtp(false);
      setTimeout(function () {
        setEnableResenOtp(true);
      }, 30000);
    } else {
      toast.error(data.errorMsg);
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      backdrop="static"
      center
      classNames={{
        modal: "otpModal",
      }}
    >
      <div>
        <h4
          style={{
            color: "black",
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          Phone Number Verification
        </h4>
      </div>

      <div className="otp-container">
        <img
          src={password}
          style={{ width: "40px", marginTop: "10px", marginBottom: "15px" }}
          alt="timeline logo"
        />
      </div>
      <h5
        style={{
          color: "black",
          marginTop: "10px",
          marginBottom: "15px",
          textAlign: "center",
        }}
      >
        You will get your OTP via SMS on this number {phone}
      </h5>
      <form onSubmit={submitOtp}>
        <div className="otp-container">
          <OtpInput
            className="otp"
            value={state.otp}
            onChange={(otp) => {
              setstate((prevState) => ({
                ...prevState,
                otp,
              }));
            }}
            isInputNum="true"
            inputStyle={{
              width: "2em",
              height: "35px",
              backgroundColor: "whitesmoke",
              border: "1px solid",
            }}
            numInputs={4}
            separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
          />
        </div>
        <div className="otp-container">
          <p
            style={{ color: "#1e8a79", marginTop: "10px", textAlign: "center" }}
          >
            <button
              type="submit"
              className="submit-btn"
              style={{ width: "200px", height: "42px", borderRadius: "9px" }}
            >
              Verify OTP{" "}
            </button>
          </p>
        </div>
        <div className="otp-container">
          <h5
            style={{
              color: "#1e8a79",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            {timeLeft === 0 ? (
              <span className="text-danger">Otp Expired</span>
            ) : (
              <span>OTP Expires in {formatTime(timeLeft)} mins</span>
            )}
          </h5>
        </div>
        {otpInvalid ? (
          <h5 style={{ color: "red", textAlign: "center" }}>
            OTP entered is not valid
          </h5>
        ) : null}
        <hr style={{ color: "#1e8a79" }}></hr>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <h5 style={{ color: "#1e8a79" }}>Did not received OTP?</h5>

          <h5
            style={{
              color: enableResendOtp ? "#1e8a79" : "#808080",
              cursor: "pointer",
            }}
            onClick={reSendOTP}
          >
            Resend OTP
          </h5>

          {/* {!enableResendOtp && (
            <h5 style={{ color: "#1e8a79" }}>
              Resend Otp in {formatTime(resendTimeLeft)}
            </h5>
          )} */}
        </div>
      </form>
    </Modal>
  );
};

export default OtpModal;
