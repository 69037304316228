import { BsExclamation } from "react-icons/bs";
import { Modal } from "react-responsive-modal";
const ConfirmDeleteModal = ({ open, closeModal, deleteEntry, id }) => {
  return (
    <Modal
      open={open}
      showCloseIcon={true}
      onClose={closeModal}
      center
      classNames={{ modal: "deleteModal" }}
      styles={{
        modal: { minWidth: "20em", borderRadius: "15px", maxWidth: "500px" },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
          marginBottom: "2.5em",
        }}
      >
        <BsExclamation style={iconBackgroundStyle} size={65} />
      </div>

      <div
        style={{
          color: "black",
          marginBottom: "10px",
          font: "16px/16px roboto",
          fontWeight: "600px",
          textAlign: "center",
          marginTop: "1em",
        }}
      >
        {" "}
        <p>Are you sure want to delete this?</p>
      </div>

      <div
        style={{
          color: "grey",
          marginBottom: "10px",
          font: "16px/16px roboto",
          fontWeight: "600px",
          textAlign: "center",
          marginTop: "0.8em",
        }}
      >
        {" "}
        <p>This information will no longer visible in your profile </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          //   margin: "30px 30px",
          margin: "2.5em 1em 1em",
        }}
      >
        <button
          type="submit"
          style={{
            backgroundColor: "#469b8d",

            borderRadius: "30px",

            width: "45%",

            color: "white",
            width: "7em",
            height: "2.5em",
          }}
          onClick={() => {
            deleteEntry(id);
            closeModal();
          }}
        >
          Ok
        </button>
        <button
          type="submit"
          className="pub-close-button"
          onClick={closeModal}
          style={{ width: "7em", height: "2.5em" }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

const iconBackgroundStyle = {
  backgroundColor: "rgb(30 138 121)",
  borderRadius: "50%",
  padding: "3px",
  color: "white",
};

export default ConfirmDeleteModal;
