import React, { useState, useCallback, useRef } from "react";
import {
  getAuthToken,
  getDocId,
  getTimeAndDate,
  getHeaders,
} from "../../utils/utils";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Comment from "./Comment";
import useCommentLoader from "../hooks/useCommentLoader";
import { Loader } from "../../common/Loader";

import Progressbar from "../../progressbar";
const CommentsList = ({ feed, deleteComment, updateComment }) => {
  const doc_id = getDocId();

  const [spinner, setSpinner] = useState(false);
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";

  const [lastCommentId, setLastCommentId] = useState(0);
  const { loading, hasMore, error, comments, commentsData } = useCommentLoader(
    doc_id,
    feed?.channel_id,
    feed?.feed_info?.feed_id,
    lastCommentId,
    feed?.feed_info?.socialInteraction
  );

  const observer = useRef(null);
  const loader = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setLastCommentId(commentsData.lastCommentId);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <>
      <div className="full-view" style={{ height: "100%" }}>
        {!feed?.feed_info?.socialInteraction?.commentsCount && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "25px",
              marginTop: "50px",
            }}
          >
            {/* {feed?.feed_info?.socialInteraction?.commentsCount} */}
            <span
              style={{ color: "black", textAlign: "center", fontSize: "20px" }}
            >
              Be the first person to comment
            </span>
          </div>
        )}
        <div style={{ overflow: "auto", height: "100%", width: "100%" }}>
          {comments?.map((comment, index) => (
            <Comment
              key={index}
              comment={comment}
              deleteComment={deleteComment}
              updateComment={updateComment}
              feed_id={commentsData.feedTypeId}
              channel_id={commentsData.channel_id}
              social_interaction_id={comment.social_interaction_id}
            />
          ))}
          <div ref={loader} style={{ marginBottom: "50px" }} />
          {loading && (
            <div>
              {" "}
              <Progressbar
                show={spinner}
                imageUrl={url}
                height="90"
                width="90"
                alignment="middle"
                alttext="Loading..."
              />
            </div>
          )}
          {error && <div> Error ....</div>}
        </div>
      </div>
    </>
  );
};
export default CommentsList;
