import Card from "@mui/material/Card";
import { useEffect, useState, useRef, useCallback } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import { config, getHeaders, logout, getDocId } from "../utils/utils";
import FullViewFeed from "../dashboard/feeds/FullViewFeed";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import CommentInput from "../dashboard/social-interaction/CommentInput";
import CommentsList from "../dashboard/social-interaction/CommentsList";
import send from "../assets/Icons/Icon feather-arrow-left@2x.png";
import Likes from "../dashboard/social-interaction/Likes";
import toast from "react-hot-toast";
import useDiscoveryCategoryFeedLoader from "../dashboard/hooks/useDiscoveryCategoryFeedLoader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import S3 from "react-aws-s3";
import certificate from "../assets/dashboard_icons/Group 948.png";
import InputBase from "@mui/material/InputBase";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import useSpecialityFeedsLoader from "../dashboard/hooks/useSpecialityFeedsLoader";
import getWindowDimensions from "../common/hooks/getWindowDimensions";
import audio_placeholder from "../assets/audio_placeholder.png";
import MciUpload from "../common/MciUpload";
import MciUploadSuccess from "../common/MciUploadSuccess";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const SpecialityFeeds = ({
  categoryId,
  specialityId,
  categoryName,
  categoryType,
  user_verification_info,
  channels,
  profile_pic,
  changeHeader,
}) => {
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const doc_id = getDocId();
  const history = useHistory();
  const [category, setCategory] = useState();
  const [value, setValue] = useState("");
  const [categoryNameLabel, setCategoryNameLabel] = useState(
    "Search " + categoryName
  );
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [openFullView, setOpenFullView] = useState(false);
  const closeIcon = <img src={close}></img>;
  const [nextFeedsData, setNextFeedsData] = useState({
    pageNum: 0,
  });
  const handleOpenFullView = (feed) => {
    // window.scrollTo(0, 0);
    setModalData(feed);
    setOpenFullView(true);
  };
  const handleCloseFullView = () => {
    setOpenFullView(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseFullViewComment = () => {
    setCommentsListInfo(false);
  };
  const handleCloseVerificationPending = () => {
    setOpenVerificationPending(false);
  };
  const userText = (string) => {
    var count = 100;
    if (string)
      return (
        string.charAt(0).toUpperCase() +
        string.slice(1, 100) +
        (string.length > count ? "..." : "")
      );
  };
  const textTitle = (string) => {
    var count = 100;
    if (string)
      return (
        string.charAt(0).toUpperCase() +
        string.slice(1, 105) +
        (string.length > count ? "<p>Read more</p>" : "")
      );
  };
  const fullViewFeedRef = useRef(null);
  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);
  const [uploadMciSuccess, setUploadMciSuccess] = useState(false);
  const openUploadMciSuccess = () => setUploadMciSuccess(true);
  const closeUploadMciSuccess = () => setUploadMciSuccess(false);
  const [feedsErrorMsg, setFeedsErrorMsg] = useState("Could not load Feeds");
  const fileInput = useRef(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState("");
  const [uploadModel, setUploadModel] = useState(false);
  const [commentsListInfo, setCommentsListInfo] = useState(false);
  // const [headerTitle, setHeaderTitle] = useState(setCategoryListInfo);
  const [userData, setUserData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  // const [shouldDisplayFeeds , setShouldDisplayFeeds] = useState(true);
  const location = useLocation();
  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    fetchUserData();
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleUploadMci = () => {
    setUploadModel(true);
  };

  const handleClickOpen = (feed) => {
    setModalData(feed);
    if (feed.feed_info.socialInteraction.likesCount == 0) {
      return;
    }
    setOpen(true);
  };
  const handleCloseUploadMci = () => {
    setUploadModel(false);
  };
  const deleteUploadedImage = (e) => {
    e.preventDefault();
    fileInput.current.value = "";
    setImgPreviewUrl("");
    setIsFileUploaded(false);
  };
  const reUploadImage = (e) => {
    deleteUploadedImage(e);
    fileInput.current.click();
  };
  const [openVerificationPending, setOpenVerificationPending] = useState(false);
  const handleVerificationPending = (feed) => {
    // handleUploadMci();
    setOpenVerificationPending(true);
  };
  const showImagePreview = () => {
    let type = fileInput.current.files[0].type;
    if (type.includes("image")) type = "image";
    else if (type.includes("video")) type = "video";
    else if (type.includes("audio")) type = "audio";
    else if (type.includes("pdf")) type = "pdf";

    if (type === "image") {
      setIsFileUploaded(true);
      setImgPreviewUrl(URL.createObjectURL(fileInput.current.files[0]));
    } else {
      toast.error("Only images are allowed");
    }
  };
  const uploadFile = async (e, fileInput, doc_id) => {
    e.preventDefault();
    let file = fileInput.current.files[0];
    let newFileName = fileInput.current.files[0].name;

    const ReactS3Client = new S3(config);
    setloaderSpinner(true);
    const data = await ReactS3Client.uploadFile(file, newFileName);
    setloaderSpinner(false);

    saveFile(data.location, doc_id);
  };
  const saveFile = async (url, doc_id) => {
    const payload = {
      doc_id,
      attachment_original_url: url,
    };

    setloaderSpinner(true);
    const res = await fetch(baseUrl + "v2/upload", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        setFeedsErrorMsg(errorMsg);
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        setFeedsErrorMsg(errorMsg);
        toast(errorMsg);
      }
      return;
    });

    const data = await res.json();
    setloaderSpinner(false);
    if (data.status === "success") {
      handleCloseUploadMci();
      openUploadMciSuccess();
      fetchUserData();
    } else toast.error(data.errorMsg);
  };
  const getFullView = async (reqData) => {
    const payload = {
      doc_id: doc_id,
      channel_id: reqData.channel_id,
      type: reqData.feed_type_id,
      feedId: reqData.feed_id,
    };
    const res = await fetch(baseUrl + "v3/fullViewFeed", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    if (data.status === "success") {
      //setModelData(data.data?.feed_info);
      handleOpenFullView(data.data);
    }
  };
  const getFeedContext = () => {
    if (location?.pathname === "/knowledge") {
      changeHeader = true;
    } else if (location?.pathname === "/dashboard") {
      changeHeader = false;
    }

    if (location?.pathname.includes("/profile")) return "profile";
    if (location?.pathname === "/professional") return "professional";
    if (location?.pathname === "/knowledge") return "knowledge";
    if (location?.pathname === "/community") return "community";
    else return "dashboard";
  };

  const feedContext = getFeedContext();
  const fetchUserData = async () => {
    const payload = {
      user_id: doc_id,
    };
    const res = await fetch(baseUrl + "v1/userProfile/view", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    if (data.status === "success") {
      localStorage.setItem("email", data.data.cnt_email);
      localStorage.setItem("phone", data.data.cnt_num);
      setUserData(data.data);
    } else {
      toast.error(data.errorMsg);
    }
  };

  const verifyUser = () => {
    if (!userData.verificationInfo.isCommunityVerified) {
      if (userData.verificationInfo.is_user_verified == 1) {
        handleUploadMci();
        return false;
      }
      if (userData.verificationInfo.is_user_verified == 2) {
        handleVerificationPending();
        return false;
      }
    }
    return true;
  };

  const addComment = async (feed_id, channel_id, comment, attachments = []) => {
    if (!verifyUser()) return;

    if (attachments.length === 0 && comment === "") {
      toast.error("Cannot post empty comment");
      return;
    }

    let payload = JSON.stringify({
      doc_id: doc_id,
      channel_id,
      feedTypeId: feed_id,
      socialInteraction: {
        type: "Comment",
        comment,
        attachment_details: attachments,
      },
    });

    payload = payload.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });

    payload = encodeURIComponent(payload);

    const res = await fetch(baseUrl + "v1/feedSocialInteractions", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    if (data.status === "success")
      setModalData((prev) => {
        const newFeed = { ...prev };
        newFeed.feed_info.socialInteraction = data.data.socialInteraction;
        return newFeed;
      });
    else toast.error(data.errorMsg);
  };
  const updateLikeCount = async (feed_id, channel_id, isLike) => {
    if (!verifyUser()) return;

    const payload = {
      doc_id: doc_id,
      channel_id: channel_id,
      feedTypeId: feed_id,
      socialInteraction: {
        type: "Like",
        isLike,
      },
    };

    const res = await fetch(baseUrl + "v1/feedSocialInteractions", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    if (data.status === "success")
      setModalData((prev) => {
        const newFeed = { ...prev };

        newFeed.feed_info.socialInteraction = data.data.socialInteraction;
        return newFeed;
      });
    else toast.error(data.errorMsg);
  };
  const updateViewCount = async (channel_id, feed_id) => {
    const payload = {
      doc_id,
      channel_id: channel_id,
      feedId: feed_id,
    };

    const res = await fetch(baseUrl + "feeds/updateViewCount", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((err) => {
      console.log(err);
      return;
    });
    const data = await res.json();

    if (data.status === "success") {
      if (data.data.socialInteraction)
        setModalData((prev) => {
          const newFeed = { ...prev };

          newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        });
      else
        setModalData((prev) => {
          const newFeed = { ...prev };
          newFeed.feed_info.socialInteraction.viewCount += 1;
          return newFeed;
        });
      // setFeeds(
      //   feeds.map((feed) => {
      //     const newFeed = { ...feed };
      //     if (feed.feed_info.feed_id === feed_id)
      //       newFeed.feed_info.socialInteraction.viewCount += 1;
      //     return newFeed;
      //   })
      // );
    } else toast.error(data.errorMsg);
  };
  const submitSurvey = async (feed_id, channel_id, question_id, option_id) => {
    if (option_id.length === 0) {
      toast("Please select an option");
      return;
    }

    const payload = {
      feed_id,
      channel_id,
      user_id: doc_id,
      feed_survey: {
        questions: [
          {
            question_id,
            options: option_id.map((option) => ({
              option_id: option,
            })),
          },
        ],
      },
    };

    const res = await fetch(baseUrl + "v1/feed/survey/update", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();
    if (data.status === "success")
      setModalData((prev) => {
        const newFeed = { ...prev };
        newFeed.feed_info.feed_survey.questions =
          newFeed.feed_info.feed_survey.questions.map((question, index) => ({
            ...question,
            options: data.data.feed_survey.questions[index].options,
          }));
        newFeed.feed_info.feed_survey.is_open = data.data.feed_survey.is_open;
        newFeed.feed_info.feed_survey.is_participated =
          data.data.feed_survey.is_participated;

        return newFeed;
      });
    else toast.error(data.errorMsg);
  };
  const deleteComment = async (feed_id, channel_id, social_interaction_id) => {
    setloaderSpinner(true);
    const payload = {
      user_id: doc_id,
      channel_id: channel_id,
      feed_id,
      social_interaction_id,
    };
    const res = await fetch(baseUrl + "v1/feeds/comment/delete", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();
    setloaderSpinner(false);
    if (data.status === "success")
      setModalData((prev) => {
        const newFeed = { ...prev };

        newFeed.feed_info.socialInteraction = data.data.socialInteraction;
        return newFeed;
      });
    else toast.error(data.errorMsg);
  };
  const updateComment = async (
    feed_id,
    channel_id,
    social_interaction_id,
    comment,
    attachments = []
  ) => {
    if (!verifyUser()) return;

    if (attachments.length === 0 && comment === "") {
      // addToast("Cannot post empty comment", {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
      return;
    }

    let payload = JSON.stringify({
      user_id: doc_id,
      channel_id,
      feed_id,
      social_interaction_id,
      comment: comment,
      attachment_details: attachments,
    });

    payload = payload.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });

    payload = encodeURIComponent(payload);

    setloaderSpinner(true);
    const res = await fetch(baseUrl + "v1/feeds/comment/update", {
      method: "POST",
      headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();
    setloaderSpinner(false);
    if (data.status === "success")
      setModalData((prev) => {
        const newFeed = { ...prev };

        newFeed.feed_info.socialInteraction = data.data.socialInteraction;
        return newFeed;
      });
    else toast.error(data.errorMsg);
  };
  const registerWebinar = async (e, feed_id, verify_user, title) => {
    e.preventDefault();

    if (verify_user) {
      if (!verifyUser()) return;
    }

    const payload = {
      user_id: doc_id,
      feed_id,
    };

    const res = await fetch(baseUrl + "v1/user/webinar/register", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
    });

    const data = await res.json();

    if (data.status === "success") {
      toast.success(`Thank you for registering for ${title}`);
      // setFeeds(
      //   feeds.map((feed) => {
      //     const newFeed = { ...feed };
      //     if (feed?.feed_info?.feed_id === feed_id)
      //       newFeed.feed_info.event_details.is_user_registered = true;
      //     return newFeed;
      //   })
      // );
      setModalData((prev) => {
        const newFeed = { ...prev };
        newFeed.feed_info.event_details.is_user_registered = true;
        return newFeed;
      });
      return true;
    } else if (data.status === "error") toast.error("Failed to registered");

    return false;
  };
  const [feedsList, setFeedsList] = useState([]);
  useEffect(() => {}, [feedsList]);
  const { loading, error, hasMore } = useSpecialityFeedsLoader(
    doc_id,
    nextFeedsData,
    categoryId,
    categoryType,
    setFeedsList,
    specialityId,
    searchValue
  );
  const observer = useRef(null);
  const loader = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setNextFeedsData((prev) => ({
            ...prev,
            pageNum: prev.pageNum + 1,
            // lastFeedTime: feeds[feeds.length - 1]?.feed_info?.updated_time,
          }));
        }
      });
      if (node) observer.current.observe(node);
    },

    [loading, hasMore]
  );
  const searchClick = async (key) => {
    console.log(key);
    if (searchValue == "") {
      toast.error("Please provide a search keyword");
      return;
    }
    const payload = {
      category_type: categoryType,
      category_id: categoryId,
      pg_num: 0,
      filter: { search_value: searchValue },
    };
    const res = await fetch(baseUrl + "v2/search/feeds", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();

    if (data.status === "success") setFeedsList([]);
    if (data.data?.feed_data && data.data?.feed_data?.length > 0) {
      setFeedsList((prevFeeds) => {
        return [...new Set([...prevFeeds, ...data.data?.feed_data])];
      });
    }
  };
  const searchClear = async () => {
    setSearchValue("");
    setNextFeedsData(() => ({
      pageNum: 0,
    }));
  };
  return (
    <div style={{ marginBottom: "40px" }}>
      <AppBar
        className={changeHeader ? "search-header-dis" : "search-header"}
        position="sticky"
      >
        <InputBase
          style={{ fontSize: "15px", marginTop: "-3px" }}
          placeholder={categoryNameLabel}
          type="text"
          variant="outlined"
          fullWidth
          size="small"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
        />
        <div className="search-button">
          <IconButton aria-label="search">
            <SearchIcon onClick={(event) => searchClick(event.target.value)} />
          </IconButton>
        </div>
        <div className="close-button">
          {searchValue && (
            <IconButton onClick={() => searchClear()}>
              <CancelRoundedIcon />
            </IconButton>
          )}
        </div>
      </AppBar>
      <Card sx={{ minHeight: "120px", padding: "5px" }}>
        <div>
          {feedsList.map((res) => (
            <div class="container" style={{ marginBottom: "0px" }}>
              <div
                class="row"
                onClick={(e) => {
                  if (windowDimensions.width <= 768)
                    history.push({
                      pathname: `/feed/${res.feed_id}/${res.channel_id}`,
                      state: {
                        background: location,
                        profile_pic: profile_pic,
                        channels: channels,
                        user_verification_info: user_verification_info,
                        feedContext: feedContext,
                      },
                    });
                  else getFullView(res);
                }}
              >
                <div class="col-4">
                  {res.attachment_details.map((data) => (
                    <img
                      style={{ cursor: "pointer" }}
                      width="100%"
                      src={
                        data.attachment_type === "audio"
                          ? audio_placeholder
                          : data.attachment_small_url
                      }
                    />
                  ))}
                </div>
                <div class="col-8" style={{ paddingLeft: "0px" }}>
                  <p
                    style={{
                      overflowWrap: "break-word",
                      font: "bold 15px / 15px roboto",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: userText(res?.title),
                    }}
                  ></p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: res.short_desc
                        ? res.short_desc.slice(0, 75) +
                          (res.short_desc.length > 75
                            ? "<span style='color:#1daf76;text-decoration:underline;cursor:pointer'>...Read more </span>"
                            : "")
                        : "",
                    }}
                  ></div>
                </div>
              </div>
              <hr />
            </div>
          ))}
          {loading && <div> Loading ....</div>}
          {error && <div>{feedsErrorMsg}</div>}
          {/* <div ref={loader} /> */}
        </div>
        {searchValue != "" && feedsList.length === 0 && (
          <div>
            <div class="container" style={{ marginBottom: "0px" }}>
              <div class="row">
                <div class="col-3"></div>
                <div
                  class="col-6"
                  style={{ marginBottom: "8px", textAlign: "center" }}
                >
                  <SearchOffIcon style={{ fontSize: 50, color: "#aba6a6" }} />
                </div>
                <div class="col-3"></div>
                <div class="col-3"></div>
                <div class="col-6" style={{ textAlign: "center" }}>
                  <div style={{ fontSize: "18px" }}> No results found.</div>
                </div>
                <div class="col-3"></div>
              </div>
            </div>
          </div>
        )}
      </Card>

      <Modal
        open={openFullView}
        blockScroll={true}
        style={customStyles}
        onClose={handleCloseFullView}
        closeIcon={closeIcon}
        classNames={{ modal: "feedModel" }}
        ref={fullViewFeedRef}
      >
        <FullViewFeed
          profile_pic={profile_pic}
          close={handleCloseFullView}
          feed={modalData}
          setFeeds={setFeedsList}
          addComment={addComment}
          updateLikeCount={updateLikeCount}
          submitSurvey={submitSurvey}
          updateViewCount={updateViewCount}
          channels={channels}
          deleteComment={deleteComment}
          updateComment={updateComment}
          openLikesModal={handleClickOpen}
          openCommentMobileView={commentsListInfo}
          registerWebinar={registerWebinar}
          user_verification_info={user_verification_info}
        ></FullViewFeed>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        classNames={{ modal: "feedsLikeModel" }}
        closeIcon={closeIcon}
      >
        <Likes feed={modalData}></Likes>
      </Modal>

      {/* Mobile responce Comment list */}
      <Modal
        open={commentsListInfo}
        blockScroll={true}
        onClose={handleCloseFullViewComment}
        classNames={{ modal: "feedsLikeModel" }}
        styles={{
          modal: {
            height: "100vh",
            width: "100%",
            padding: "0",
            margin: "0",
            borderRadius: "0",
            overflow: "visible",
          },
          modalContainer: {
            overflow: "visible",
          },
        }}
        showCloseIcon={false}
        feed={modalData}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div
            className="mobile-header-wrapper d-block d-lg-none"
            style={{
              position: "-webkit-sticky",
              position: "sticky",
              top: "0",
              left: "0",
              width: "100%",
              zIndex: "5",
              flexShrink: "0",
            }}
          >
            <div className="mobile-header position-relative ">
              <div style={{ display: "flex" }}>
                <div className="brand-logo ">
                  <img
                    onClick={handleCloseFullViewComment}
                    src={send}
                    style={{ width: "25px", marginTop: "-4px" }}
                    alt="timeline logo"
                  />
                </div>
                <div className="pull-left">
                  <p style={{ marginLeft: "10px" }}>
                    Comment &nbsp;
                    <span style={{ color: "green" }}>
                      {`(${modalData?.feed_info?.socialInteraction?.commentsCount} Comments)`}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ overflowY: "auto" }}>
            <CommentsList
              feed={modalData}
              deleteComment={deleteComment}
              updateComment={updateComment}
            />
          </div>
          <div
            className="footer-area  d-block d-lg-none"
            style={{
              position: "-webkit-sticky",
              position: "sticky",
              bottom: "0",
              right: "0",
              width: "100%",
              flexShrink: "0",
              background: "white",
              zIndex: "5",
              height: "100px",
            }}
          >
            <hr></hr>
            <div
              className="post-meta"
              style={{
                padding: "0",
                margin: "0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CommentInput
                profile_pic={userData?.profile_pic_small_url}
                addComment={addComment}
                feed_id={modalData?.feed_info?.feed_id}
                channel_id={modalData.channel_id}
                isFullFeedView={false}
              />
            </div>
          </div>
        </div>
      </Modal>

      {/* profile verification model */}
      <Modal
        open={openVerificationPending}
        onClose={handleCloseVerificationPending}
        classNames={{ modal: "verificationModel" }}
        closeIcon={closeIcon}
      >
        <div style={{ marginTop: "15px" }}></div>
        <div className="verifaction">
          <p className="verification-title">
            {userData?.verificationInfo?.is_user_verified === 1 &&
              "Profile Verification Pending"}
            {userData?.verificationInfo?.is_user_verified === 2 &&
              "Profile Under Verification"}
          </p>
          <p className="verification-sub-title">
            {userData?.verificationInfo?.is_user_verified === 1 &&
              "Your profile verification is pending"}
            {userData?.verificationInfo?.is_user_verified === 2 &&
              "Your certificate has been upload successfully"}
          </p>
          <p className="verification-sub-title">
            {userData?.verificationInfo?.is_user_verified === 1 &&
              "Please verify your profile to get full access"}
            {userData?.verificationInfo?.is_user_verified === 2 &&
              "We will verify your certificate and validate your profile"}
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={handleCloseVerificationPending}
            style={{
              backgroundColor: "#ffffff",
              border: "1px solid gray",
              color: "black",
              padding: "11px",
              width: "150px",
              fontsize: "11px",
              marginBottom: "10px",
              borderRadius: "9px",
            }}
            className="submit-btn"
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        open={uploadModel}
        onClose={handleCloseUploadMci}
        classNames={{ modal: "uploadMci" }}
        closeIcon={closeIcon}
      >
        <MciUpload
          closeUploadMci={handleCloseUploadMci}
          openSuccessModal={openUploadMciSuccess}
        />
      </Modal>
      <Modal
        closeIcon={closeIcon}
        open={uploadMciSuccess}
        onClose={closeUploadMciSuccess}
        classNames={{ modal: "verificationModel" }}
      >
        <MciUploadSuccess closeUploadMciSuccess={closeUploadMciSuccess} />
      </Modal>
    </div>
  );
};

export default SpecialityFeeds;
