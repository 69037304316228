import { useState, useEffect, useRef } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import qualificationsBg from "../assets/new_profile_icons/qualifications.svg";
import qualificationIcon from "../assets/new_profile_icons/ic_qualification.svg";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { IoIosCloseCircle } from "react-icons/io";
import { MdEdit, MdDeleteForever } from "react-icons/md";
import CreatableSelect from "react-select/creatable";
import { getDocId, getHeaders } from "../utils/utils";
import toast from "react-hot-toast";
import { Modal } from "react-responsive-modal";

const MobileQualifications = () => {
  const user_id = getDocId();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const history = useHistory();

  const [drawer, setDrawer] = useState(false);
  const [degrees, setDegrees] = useState([]);
  const [universities, setUniversities] = useState([]);

  const [isEdit, setIsEdit] = useState(false);
  const [acadId, setAcadId] = useState(null);

  const [deleteData, setDeleteData] = useState({});

  const [userInfo, setUserInfo] = useState({});

  const [verifyUpdate, setVerifyUpdate] = useState(false);
  const closeVerifyUpdate = () => setVerifyUpdate(false);
  const openVerifyUpdate = () => setVerifyUpdate(true);

  const [verifyDelete, setVerifyDelete] = useState(false);
  const closeVerifyDelete = () => setVerifyDelete(false);
  const openVerifyDelete = () => setVerifyDelete(true);

  const scrollToId = useRef(null);

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    trigger,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });

  const pursuing = watch("pursuing");

  useEffect(() => {
    getUserInfo();
    autoSuggestions();
  }, []);

  useEffect(() => {
    const resetYearOfGraduation = async () => {
      if (pursuing) {
        setValue("graduation", "");
        const res = await trigger("graduation");
      }
    };

    resetYearOfGraduation();
  }, [pursuing]);

  useEffect(() => {
    if (scrollToId.current) {
      document.getElementById(`acad${scrollToId.current}`).scrollIntoView();
      scrollToId.current = null;
    }
  }, [userInfo]);

  const toggleDrawer =
    (open, isEdit = false, acad_id = 0) =>
    (event) => {
      if (
        event &&
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      setIsEdit(isEdit);
      if (isEdit) setEditValues(acad_id);
      else reset();

      setDrawer(open);
    };

  const getUserInfo = async () => {
    const url = "v1/userProfile/view";

    const payload = {
      user_id,
    };

    const res = await fetch(baseUrl + url, {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {
      console.error(e);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
    });
    const data = await res.json();

    if (data.status === "success") {
      setUserInfo(data.data);
    } else {
      toast.error(data.errorMsg);
    }
  };

  const setEditValues = (acad_id) => {
    const [res] = userInfo.acad_history.filter(
      (qualification) => qualification.acad_id === acad_id
    );
    if (res) {
      setValue("degree", { label: res.degree, value: res.degree });
      setValue("college", res.college);
      if (res.university !== "")
        setValue("university", {
          label: res.university,
          value: res.university,
        });
      setValue("pursuing", res.currently_pursuing);
      if (!res.currently_pursuing) setValue("graduation", res.passing_year);
      setAcadId(acad_id);
    }
  };

  const autoSuggestions = async () => {
    const payload = { degrees: "degrees", universities: "universities" };

    const res = await fetch(baseUrl + "v2/autoSuggestions", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let universities = [];
    let degrees = [];

    if (data.status === "success") {
      for (const university in data.data.universities) {
        universities.push({ value: university, label: university });
      }
      for (const degree of data.data.degrees) {
        degrees.push({ value: degree, label: degree });
      }
    }

    setDegrees(degrees);
    setUniversities(universities);
  };

  const editQualification = async (formData) => {
    const payload = {
      user_id: user_id,
      acad_history: [
        {
          acad_id: acadId,
          degree: encodeURIComponent(formData.degree.value.trim()),
          college: encodeURIComponent(formData?.college?.trim()),
          university: formData.university
            ? encodeURIComponent(formData.university.value.trim())
            : "",
          currently_pursuing: formData.pursuing,
          passing_year: formData.pursuing ? 0 : formData.graduation,
        },
      ],
    };

    const res = await fetch(baseUrl + "/v1/userProfile/update", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    console.log(data);
    if (data.status === "success") {
      setUserInfo((prev) => ({
        ...prev,
        acad_history: prev.acad_history.map((qualification) => {
          if (qualification.acad_id === acadId)
            return data.data.acad_history[0];
          else return qualification;
        }),
      }));
      closeVerifyUpdate();

      toast.success("Qualification updated");
    } else toast.error(data.errorMsg);
  };

  const addQualification = async (formData) => {
    const payload = {
      user_id: user_id,
      acad_history: [
        {
          degree: encodeURIComponent(formData.degree.value.trim()),
          college: encodeURIComponent(formData?.college?.trim()),
          university: formData.university
            ? encodeURIComponent(formData.university.value.trim())
            : "",
          currently_pursuing: formData.pursuing,
          passing_year: formData.pursuing ? 0 : formData.graduation,
        },
      ],
    };

    const res = await fetch(baseUrl + "v1/userProfile/create", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    console.log(data);
    if (data.status === "success") {
      scrollToId.current = data.data.acad_history[0].acad_id;
      setUserInfo((prev) => ({
        ...prev,
        acad_history: insertNewQualification(
          prev.acad_history,
          data.data.acad_history
        ),
      }));
      setDrawer(false);
      toast.success("Qualification added");
    } else toast.error(data.errorMsg);
  };

  const deleteQualification = async (acad_id) => {
    const payload = {
      user_id,
      acad_history: [
        {
          acad_id,
        },
      ],
    };

    const res = await fetch(baseUrl + "/v1/userProfile/delete", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    console.log(data);
    if (data.status === "success") {
      setUserInfo((prev) => ({
        ...prev,
        acad_history: prev.acad_history.filter(
          (qualification) => qualification.acad_id !== acad_id
        ),
      }));
      closeVerifyDelete();
      toast.success("Qualification deleted");
    } else toast.error(data.errorMsg);
  };

  const insertNewQualification = (oldArray, newItem) => {
    if (!oldArray) return [...newItem];

    const res = [...oldArray, ...newItem];
    res.sort((a, b) => {
      if (b.currently_pursuing) return 1;
      else if (a.currently_pursuing) return -1;
      else if (a.currently_pursuing && b.currently_pursuing) return 0;
      return b.passing_year - a.passing_year;
    });
    return res;
  };

  const sortQualifications = (arr) => {
    if(!arr) return []
    const res = [...arr];
    res.sort((a, b) => {
      if (a.currently_pursuing && b.currently_pursuing)
        return b.acad_id - a.acad_id;
      else if (a.currently_pursuing && !b.currently_pursuing) return -1;
      else if (!a.currently_pursuing && b.currently_pursuing) return 1;
      else {
        if (b.passing_year === a.passing_year) return b.acad_id - a.acad_id;
        else return b.passing_year - a.passing_year;
      }
    });
    return res;
  };

  const list = () => (
    <Box sx={{ width: "auto" }} role="presentation">
      <div style={{ padding: "1em" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={toggleDrawer(false)}
        >
          <IoIosCloseCircle
            style={{
              cursor: "pointer",
              color: "darkgray",
            }}
            size={25}
          />
        </div>
        <div style={{ ...inputDivStyle }}>
          {/* <input
            style={inputStyle}
            type="text"
            {...register("degree", {
              required: true,
              maxLength: 35,
            })}
            placeholder="Degree"
          /> */}
          <Controller
            name="degree"
            control={control}
            rules={{
              required: true,
              validate: {
                mlen: (val) => !(val?.value?.length > 100),
                specialChar: (val) => /^[a-zA-Z&.(),/\s-]*$/.test(val?.value),
              },
            }}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                placeholder="Degree *"
                options={degrees}
                isClearable={true}
                styles={{
                  control: (style) => ({
                    ...style,
                    background: "inherit",
                    border: 0,
                    boxShadow: "none",
                    // padding: "10px 10px 10px 0",
                  }),
                  container: (style) => ({
                    ...style,
                    width: "100%",
                  }),
                  placeholder: (style) => ({
                    ...style,
                    fontSize: "14px",
                  }),
                  indicatorsContainer: (style) => ({
                    ...style,
                    display: "none",
                  }),
                  valueContainer: (style) => ({
                    ...style,
                    padding: 0,
                  }),
                  menuList: (style) => ({
                    ...style,
                    height: "9em",
                  }),
                }}
              />
            )}
          />
        </div>
        {errors.degree?.type === "required" && (
          <div style={errorMsg}>Please enter your degree</div>
        )}
        {errors.degree?.type === "mlen" && (
          <div style={errorMsg}>Please enter less than 100 characters</div>
        )}
        {errors.degree?.type === "specialChar" && (
          <div style={errorMsg}>
            Only letters, period & - ( ) / and comma are allowed
          </div>
        )}
        <div
          style={{
            display: "flex",
            // marginBlock: "1em",
            marginTop: "1em",
            marginBottom: "1em",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              ...inputDivStyle,
              // marginBlock: 0,
              marginTop: 0,
              marginBottom: 0,
              width: "50%",
              backgroundColor: pursuing ? "lightgray" : "#ffffff",
            }}
          >
            <input
              style={inputStyle}
              type="number"
              {...register("graduation", {
                required: !pursuing,
                min: 1900,
                max: new Date().getFullYear(),
              })}
              disabled={pursuing}
              placeholder="Year of Graduation *"
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              // marginBlock: "0.5em",
              marginTop: "0.5em",
              marginBottom: "0.5em",
            }}
          >
            <input
              style={{ width: "20px", height: "20px", margin: 0 }}
              type="checkbox"
              name="pursuing"
              {...register("pursuing")}
            />
            <span
              className="form-check-label"
              style={{
                marginLeft: "1em",
                fontSize: "1.1rem",
                color: "gray",
              }}
              htmlFor="pursuing"
            >
              Currently Pursuing
            </span>
          </div>
        </div>
        {errors.graduation?.type === "max" && (
          <div style={errorMsg}>
            Year of graduation cannot be greater than current year
          </div>
        )}
        {errors.graduation?.type === "min" && (
          <div style={errorMsg}>Please provide valid year above 1900</div>
        )}
        {errors.graduation?.type === "required" && (
          <div style={errorMsg}>Please enter graduation year</div>
        )}
        <div style={inputDivStyle}>
          {/* <input
            style={inputStyle}
            type="text"
            {...register("university", {
              maxLength: 35,
            })}
            placeholder="University"
          /> */}
          <Controller
            name="university"
            control={control}
            rules={{
              validate: {
                mlen: (val) => !(val?.value?.length > 100),
                specialChar: (val) => /^[a-zA-Z().,\s]*$/.test(val?.value),
              },
            }}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                placeholder="University"
                menuPlacement="top"
                isClearable={true}
                options={universities}
                styles={{
                  control: (style) => ({
                    ...style,
                    background: "inherit",
                    border: 0,
                    boxShadow: "none",
                    // padding: "10px 10px 10px 0",
                  }),
                  container: (style) => ({
                    ...style,
                    width: "100%",
                  }),
                  placeholder: (style) => ({
                    ...style,
                    fontSize: "14px",
                  }),
                  indicatorsContainer: (style) => ({
                    ...style,
                    display: "none",
                  }),
                  valueContainer: (style) => ({
                    ...style,
                    padding: 0,
                  }),
                  menuList: (style) => ({
                    ...style,
                    height: "9em",
                  }),
                }}
              />
            )}
          />
        </div>
        {errors?.university?.type === "mlen" && (
          <div style={errorMsg}>Please enter less than 100 characters</div>
        )}
        {errors?.university?.type === "specialChar" && (
          <div style={errorMsg}>
            Only letters, brackets, period and comma are allowed
          </div>
        )}
        <div style={inputDivStyle}>
          <input
            style={inputStyle}
            type="text"
            {...register("college", {
              maxLength: 100,
              pattern: /^[a-zA-Z&,\s]*$/,
            })}
            placeholder="College"
          />
        </div>
        {errors.college?.type === "maxLength" && (
          <div style={errorMsg}>Please enter less than 100 characters</div>
        )}
        {errors.college?.type === "pattern" && (
          <div style={errorMsg}>Only letters, & and comma are allowed</div>
        )}
        {isEdit ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <button
              style={{
                backgroundColor: "white",
                padding: "1em",
                borderRadius: "30px",
                fontSize: "1.4rem",
                width: "30%",
                border: "1px solid lightgrey",
                color: "gray",
              }}
              onClick={toggleDrawer(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              style={{
                backgroundColor: isValid ? "#469b8d" : "dimgray",
                width: "60%",
                borderRadius: "30px",
                padding: "1em 0",
                color: "white",
                fontSize: "1.4rem",
              }}
              // onClick={handleSubmit(editQualification)}
              onClick={() => {
                if (isValid) {
                  setDrawer(false);
                  openVerifyUpdate();
                }
              }}
            >
              Update Qualification
            </button>{" "}
          </div>
        ) : (
          <button
            type="button"
            style={{
              backgroundColor: isDirty && isValid ? "#469b8d" : "dimgray",
              width: "100%",
              borderRadius: "30px",
              padding: "1em 0",
              color: "white",
              fontSize: "1.8rem",
            }}
            onClick={handleSubmit(addQualification)}
          >
            Add Qualification
          </button>
        )}
      </div>
    </Box>
  );

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "white",
        zIndex: 10,
        // overflow: "auto",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100vw",
      }}
    >
      <div
        style={{
          backgroundColor: "rgb(30 138 121)",
          display: "flex",
          color: "white",
          padding: "1em",
          alignItems: "center",
        }}
      >
        <MdArrowBackIos
          style={{ color: "white", cursor: "pointer" }}
          onClick={() => history.replace("/profile")}
          size={32}
        />
        Qualifications
      </div>

      {userInfo.acad_history && userInfo?.acad_history?.length !== 0 ? (
        <div style={{ overflowY: "auto", flex: 1 }}>
          {sortQualifications(userInfo.acad_history).map((qualification) => (
            <>
              <div
                id={`acad${qualification.acad_id}`}
                style={{
                  padding: "1em",
                  display: "flex",
                }}
              >
                <div style={{ width: "15%" }}>
                  <img src={qualificationIcon} style={{ width: "1.5em" }} />
                </div>
                <div style={{ width: "85%" }}>
                  <div
                    style={{
                      color: "#469b8d",
                      fontSize: "1.8rem",
                      fontWeight: "bold",
                      overflowWrap: "break-word",
                    }}
                  >
                    {qualification.degree}
                  </div>
                  {qualification.university &&
                    qualification?.university !== "" && (
                      <div
                        style={{
                          fontSize: "1.2rem",
                          color: "black",
                          fontStyle: "italic",
                          fontWeight: "bold",
                          marginTop: "1em",
                          overflowWrap: "break-word",
                        }}
                      >
                        {qualification.university}
                      </div>
                    )}
                  {qualification.college && qualification?.college !== "" && (
                    <div
                      style={{
                        fontSize: "1.2rem",
                        color: "gray",
                        marginTop: "1em",
                        overflowWrap: "break-word",
                      }}
                    >
                      {qualification.college}
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "1em",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "1.2rem",

                        width: "50%",
                      }}
                    >
                      Graduation Year :{" "}
                      {qualification.currently_pursuing
                        ? "Currently Pursuing"
                        : qualification.passing_year}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "40%",
                        justifyContent: "space-between",
                      }}
                    >
                      <MdDeleteForever
                        onClick={() => {
                          setDeleteData({
                            acad_id: qualification.acad_id,
                            degree: qualification.degree,
                          });
                          openVerifyDelete();
                        }}
                        style={{ color: "red", cursor: "pointer" }}
                        size={23}
                      />

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={toggleDrawer(
                          true,
                          true,
                          qualification.acad_id
                        )}
                      >
                        <MdEdit
                          style={{ color: "rgb(30 138 121)" }}
                          size={18}
                        />
                        <span
                          style={{
                            color: "rgb(30 138 121)",
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            marginLeft: "1.3em",
                          }}
                        >
                          Edit
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr style={{ margin: 0 }} />
            </>
          ))}
        </div>
      ) : (
        <div
          style={{
            overflowY: "auto",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={qualificationsBg} style={{ width: "10em" }} />
          <div
            style={{
              padding: "1em",
              textAlign: "center",
              color: "#1e8a79",
              lineHeight: "4.5rem",
              fontSize: "2rem",
            }}
          >
            Share your academic qualifications and be discovered
          </div>
        </div>
      )}

      <div
        className="post-modal-submit"
        style={{
          marginBottom: 0,
          padding: "1em",
          height: "6em",
          boxShadow: "0 -3px 5px rgb(0 0 0 / 0.2)",
        }}
      >
        <button
          type="button"
          style={{
            backgroundColor: "#469b8d",
            width: "100%",
            borderRadius: "30px",
            padding: "1em 0",
            color: "white",
            fontSize: "1.8rem",
            cursor: "pointer",
          }}
          onClick={toggleDrawer(true)}
        >
          Add Qualifications
        </button>
      </div>

      <Drawer
        sx={{ "& .MuiDrawer-paper": { borderRadius: "15px 15px 0 0" } }}
        anchor="bottom"
        open={drawer}
        // variant="persistent"
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>

      <Modal
        open={verifyUpdate}
        onClose={closeVerifyUpdate}
        showCloseIcon={false}
        backdrop="static"
        center
        classNames={{
          modal: "otpModal",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => closeVerifyUpdate()}
        >
          <IoIosCloseCircle
            style={{
              cursor: "pointer",
              color: "lightgrey",
            }}
            size={25}
          />
        </div>

        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "black",
            fontWeight: "bold",
            marginTop: "1em",
          }}
        >
          Update Qualification
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "1.8rem",
            color: "black",
            margin: "1em 1.4em",
          }}
        >
          Are you sure you want to update your qualification data?
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "3em",
            marginBottom: "1em",
          }}
        >
          <button
            style={{
              backgroundColor: "white",
              padding: "1em",
              borderRadius: "20px",
              fontSize: "1.2rem",
              width: "45%",
              border: "1px solid gray",
              color: "gray",
            }}
            onClick={() => closeVerifyUpdate()}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: "#469b8d",
              padding: "1em",
              borderRadius: "20px",
              fontSize: "1.2rem",
              width: "45%",
              // border: "1px solid gray",
              color: "white",
            }}
            onClick={handleSubmit(editQualification)}
          >
            Update
          </button>
        </div>
      </Modal>

      <Modal
        open={verifyDelete}
        onClose={closeVerifyDelete}
        showCloseIcon={false}
        backdrop="static"
        center
        classNames={{
          modal: "otpModal",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => closeVerifyDelete()}
        >
          <IoIosCloseCircle
            style={{
              cursor: "pointer",
              color: "lightgrey",
            }}
            size={25}
          />
        </div>

        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "black",
            fontWeight: "bold",
            marginTop: "1em",
          }}
        >
          Delete Qualification
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "1.8rem",
            color: "black",
            margin: "1em 1.4em",
          }}
        >
          Do you want to delete{" "}
          <span style={{ fontWeight: "bold" }}>{deleteData.degree} </span>
          Degree?
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "3em",
            marginBottom: "1em",
          }}
        >
          <button
            style={{
              backgroundColor: "white",
              padding: "1em",
              borderRadius: "20px",
              fontSize: "1.2rem",
              width: "45%",
              border: "1px solid gray",
              color: "gray",
            }}
            onClick={() => closeVerifyDelete()}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: "red",
              padding: "1em",
              borderRadius: "20px",
              fontSize: "1.2rem",
              width: "45%",
              // border: "1px solid gray",
              color: "white",
            }}
            onClick={() => deleteQualification(deleteData.acad_id)}
          >
            Delete
          </button>
        </div>
      </Modal>
    </div>
  );
};

const inputDivStyle = {
  background: "#ffffff",
  borderRadius: "5px",
  height: "3.125em",
  border: "1px solid slategray",
  width: "100%",
  display: "flex",
  alignItems: "center",
  padding: "1em",
  // marginBlock: "1em",
  marginTop: "1em",
  marginBottom: "1em",
};

const inputStyle = {
  background: "inherit",
  width: "100%",
  border: "none",
};

const errorMsg = {
  color: "red",
  fontSize: "1.1rem",
  // marginBlock: "1em",
  marginTop: "1em",
  marginBottom: "1em",
};

export default MobileQualifications;
