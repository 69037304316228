import { useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import getWindowDimensions from "../common/hooks/getWindowDimensions";
import toast from "react-hot-toast";
import { BsSearch } from "react-icons/bs";
import { MdClear } from "react-icons/md";
import dummy from "../assets/profile_icons/default-profile.png";
import DesktopSearch from "./DesktopSearch";
import MobileSearch from "./MobileSearch";
import { closeIcon, getDocId, getHeaders } from "../utils/utils";
import useSearch from "./useSearch";
import Modal from "react-responsive-modal";
import MciUpload from "../common/MciUpload";
import MciVerificationStatus from "../common/MciVerificationStatus";
import MciUploadSuccess from "../common/MciUploadSuccess";

const Search = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const doc_id = getDocId();

  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  const { query } = useParams();

  const [channels, setChannels] = useState([]);

  const [userData, setUserData] = useState({});

  const [searchText, setSearchText] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [showFeeds, setShowFeeds] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState(0);

  const [nextFeedsData, setNextFeedsData] = useState({
    last_feed_id: 0,
  });

  const { searchResults, setSearchResults, filters, ...scrollData } = useSearch(
    nextFeedsData,
    selectedFilter,
    searchKey,
    setShowFeeds
  );

  const [uploadModel, setUploadModel] = useState(false);
  const [openVerificationPending, setOpenVerificationPending] = useState(false);
  const [uploadMciSuccess, setUploadMciSuccess] = useState(false);
  const [isMciUploaded, setIsMciUploaded] = useState(1);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }

    if (query) {
      setSearchText(query);
      setSearchKey(query);
    }
    // setNextFeedsData({
    //   last_feed_id: 0,
    // });

    getChannels();
    fetchUserData();

    document.body.style.overflow = "hidden";

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    setSearchResults([]);
    setNextFeedsData({
      last_feed_id: 0,
    });
  }, [selectedFilter]);

  const clearSearch = () => {
    setSearchText("");
    setShowFeeds(false);
    scrollData.setNoResults(false);
  };

  const searchFeeds = async (e) => {
    e.preventDefault();
    if (searchText === "") {
      toast("Please provide a search keyword", {
        duration: 1500,
      });
      return;
    }
    if (searchText.length < 3) {
      toast("Please enter minimum 3 characters for search", {
        duration: 1500,
      });
      return;
    }

    setSearchKey(searchText);
    setNextFeedsData({
      last_feed_id: 0,
    });
  };

  const fetchUserData = async () => {
    const payload = {
      user_id: doc_id,
    };
    const res = await fetch(baseUrl + "v1/userProfile/view", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    if (data.status === "success") {
      localStorage.setItem("email", data.data.cnt_email);
      localStorage.setItem("phone", data.data.cnt_num);
      setUserData(data.data);
    } else {
      toast.error(data.errorMsg);
    }
  };

  const getChannels = async () => {
    const payload = {
      doc_id,
      last_feed_time: 0,
      load_next: true,
      pg_num: 0,
    };
    const res = await fetch(baseUrl + "v3/getDashBoardData", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
      return;
    });

    const data = await res.json();
    if (data.status === "success") {
      if (data.data.listofChannels)
        setChannels((prev) => {
          return [...prev, ...data.data.listofChannels];
        });
    } else toast.error(data.errorMsg);
  };

  const observer = useRef(null);

  const loader = useCallback(
    (node) => {
      if (scrollData.loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && scrollData.hasMore) {
          setNextFeedsData((prev) => ({
            ...prev,
            last_feed_id: searchResults[searchResults.length - 1].feed_id,
          }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [scrollData.loading, scrollData.hasMore]
  );

  const handleUploadMci = () => {
    setUploadModel(true);
  };
  const handleCloseUploadMci = () => {
    setUploadModel(false);
  };

  const handleVerificationPending = (feed) => {
    // handleUploadMci();
    setOpenVerificationPending(true);
  };
  const handleCloseVerificationPending = () => {
    setOpenVerificationPending(false);
  };

  const openUploadMciSuccess = () => setUploadMciSuccess(true);
  const closeUploadMciSuccess = () => {
    setIsMciUploaded(2);
    setUploadMciSuccess(false);
  };

  return (
    <>
      {windowDimensions.width < 728 ? (
        <MobileSearch
          searchText={searchText}
          filters={filters}
          searchFeeds={searchFeeds}
          setSearchText={setSearchText}
          clearSearch={clearSearch}
          showFeeds={showFeeds}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          searchResults={searchResults}
          userData={userData}
          scrollData={scrollData}
          loader={loader}
          handleUploadMci={handleUploadMci}
          handleVerificationPending={handleVerificationPending}
          channels={channels}
        />
      ) : (
        <DesktopSearch
          channels={channels}
          searchText={searchText}
          filters={filters}
          searchFeeds={searchFeeds}
          setSearchText={setSearchText}
          clearSearch={clearSearch}
          showFeeds={showFeeds}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          searchResults={searchResults}
          userData={userData}
          scrollData={scrollData}
          loader={loader}
          handleUploadMci={handleUploadMci}
          handleVerificationPending={handleVerificationPending}
        />
      )}

      <Modal
        open={openVerificationPending}
        onClose={handleCloseVerificationPending}
        classNames={{ modal: "verificationModel" }}
        closeIcon={closeIcon}
      >
        <MciVerificationStatus
          is_user_verified={userData?.verificationInfo?.is_user_verified}
          closeVerificationStatusModal={handleCloseVerificationPending}
        />
      </Modal>

      <Modal
        open={uploadModel}
        onClose={handleCloseUploadMci}
        classNames={{ modal: "uploadMci" }}
        closeIcon={closeIcon}
      >
        <MciUpload
          closeUploadMci={handleCloseUploadMci}
          openSuccessModal={openUploadMciSuccess}
        />
      </Modal>

      <Modal
        closeIcon={closeIcon}
        open={uploadMciSuccess}
        onClose={closeUploadMciSuccess}
        classNames={{ modal: "verificationModel" }}
      >
        <MciUploadSuccess closeUploadMciSuccess={closeUploadMciSuccess} />
      </Modal>
    </>
  );
};

export default Search;
