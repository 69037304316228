import { useState, useCallback, useRef, useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
// import testvideo from "../../assets/file_example_MP4_640_3MG.mp4";
// import testaudio from "../../assets/file_example_MP3_1MG.mp3";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import getWindowDimensions from "../../common/hooks/getWindowDimensions";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { getBrowser } from "../../utils/utils";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
const FeedAttachments = ({ attachments = [], videoRef, audioRef }) => {
  const classes = useStyles();

  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const browser = getBrowser();
  // const videoRef = useRef(null);
  // const audioRef = useRef(null);
  const videoObserver = useRef(null);
  const audioObserver = useRef(null);
  const videoLoader = useCallback((node) => {
    if (videoObserver.current) videoObserver.current.disconnect();
    videoObserver.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          videoRef.current.pause();
        }
      });
    });
    if (node) videoObserver.current.observe(node);
  }, []);

  const audioLoader = useCallback((node) => {
    if (audioObserver.current) audioObserver.current.disconnect();
    audioObserver.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          audioRef.current.audioEl.current.pause();
        }
      });
    });
    if (node) audioObserver.current.observe(node);
  }, []);

  if (attachments.length === 0) return null;

  return (
    <div className="feed-image-container">
      <div
        className={`${attachments.length === 1 && "feed-attachments-1"}
                    ${attachments.length === 2 && "feed-attachments-2"}
                     ${attachments.length >= 3 && "feed-attachments-3"}`}
      >
        {attachments.map((attachment, i) => {
          if (i > 2) return null;
          <div key={i} className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <img
                    key={i}
                    className={
                      i === 0
                        ? `${
                            attachments.length >= 3
                              ? "attachment-3"
                              : "attachment"
                          }`
                        : "attachment"
                    }
                    alt="attachment"
                    src={attachment.attachment_original_url}
                    // style={{ maxHeight: "400px" }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper}>xs=6</Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper}>xs=6</Paper>
              </Grid>
            </Grid>
          </div>;

          if (attachment.attachment_type === "image")
            return (
              <img
                key={i}
                // className={
                //   i === 0
                //     ? `${
                //         attachments.length >= 3 ? "attachment-3" : "attachment"
                //       }`
                //     : "attachment-2"
                // }
                style={{
                  width: "100%",
                  minHeight: "150px",
                  maxHeight:
                    i === 0 &&
                    (attachments.length === 1 || attachments.length === 2)
                      ? "500px"
                      : i === 0 && attachments.length >= 3
                      ? "300px"
                      : i === 1 && attachments.length === 2
                      ? "300px"
                      : i === 1 && attachments.length >= 3
                      ? "300px"
                      : i === 2
                      ? "150px"
                      : "100%",
                }}
                alt="attachment"
                src={attachment.attachment_original_url}
                // style={{ maxHeight: "400px" }}
              />
            );

          if (attachment.attachment_type === "video")
            return (
              <span onClick={(e) => e.stopPropagation()} key={i}>
                <video
                  preload="metadata"
                  // src={attachment.attachment_original_url}
                  // className={
                  //   i === 0
                  //     ? `${
                  //         attachments.length >= 3
                  //           ? "attachment-3"
                  //           : "attachment"
                  //       }`
                  //     : "attachment"
                  // }
                  style={{
                    width: "100%",
                    minHeight: "150px",
                    maxHeight:
                      i === 0 &&
                      (attachments.length === 1 || attachments.length === 2)
                        ? "500px"
                        : i === 0 && attachments.length >= 3
                        ? "300px"
                        : i === 1 && attachments.length === 2
                        ? "300px"
                        : i === 1 && attachments.length >= 3
                        ? "300px"
                        : i === 2
                        ? "150px"
                        : "100%",
                  }}
                  controls="controls"
                  // style={{border: "1px solid #f1f1f1"}}
                  className="dashboard-videoAttachment"
                  // style={{ height: "100%" }}
                  ref={videoRef}
                >
                  <source
                    src={`${attachment.attachment_original_url.replace(/^http:\/\//i, 'https://')}#t=0.1`}
                    type="video/mp4"
                  />
                </video>
                <span ref={videoLoader}></span>
              </span>
            );

          if (attachment.attachment_type === "audio")
            return (
              <span onClick={(e) => e.stopPropagation()} key={i}>
                <ReactAudioPlayer
                  style={{ border: "1px solid #f1f1f1" }}
                  controls
                  src={attachment.attachment_original_url}
                  className="dashboard-audioAttachment"
                  style={{
                    // width: '100%',

                    maxHeight:
                      i === 0 &&
                      (attachments.length === 1 || attachments.length === 2)
                        ? "500px"
                        : i === 0 && attachments.length >= 3
                        ? "300px"
                        : i === 1 && attachments.length === 2
                        ? "300px"
                        : i === 1 && attachments.length >= 3
                        ? "300px"
                        : i === 2
                        ? "150px"
                        : "100%",
                  }}
                  ref={(element) => {
                    audioRef.current = element;
                  }}
                />
                <span ref={audioLoader}></span>
              </span>
            );
          if (attachment.attachment_type === "pdf")
            if (windowDimensions.width <= 768)
              return (
                <div
                  // style={{
                  //   position: "relative",
                  //   textAlign: "center",
                  //   color: "black",
                  // }}
                  key={i}
                >
                  {attachments.length > 1 ? (
                    <img
                      src={attachment.attachment_small_url}
                      style={{
                        width: "100%",
                        height:
                          i === 0 &&
                          (attachments.length === 1 || attachments.length === 2)
                            ? "500px"
                            : i === 0 && attachments.length >= 3
                            ? "300px"
                            : i === 1 && attachments.length === 2
                            ? "300px"
                            : i === 1 && attachments.length >= 3
                            ? "300px"
                            : i === 2
                            ? "150px"
                            : "100%",
                      }}
                    />
                  ) : (
                    <div
                      className="iframe-loader"
                      key={i}
                      style={{ width: "100%", height: "400px" }}
                    >
                      <iframe
                        id="iframeContainer"
                        src={`https://drive.google.com/viewerng/viewer?url=${attachment.attachment_original_url}?pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
                        width="100%"
                        height="400px"
                      />
                    </div>
                  )}
                  {/* <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      backgroundColor: "#5454b8",
                      zIndex: "3",
                      height: "2em",
                      width: "8em",
                      cursor: "pointer",
                      borderRadius: "5px",
                      verticalAlign: "center",
                      lineHeight: "2em",
                    }}
                    onClick={() =>
                      window.open(attachment.attachment_original_url, "_blank")
                    }
                  >
                    Download
                  </span> */}
                </div>
              );
            else
              return (
                <object
                  key={i}
                  data={
                    attachment.attachment_original_url.includes("http://")
                      ? attachment.attachment_original_url.replace(
                          "http://",
                          "https://"
                        )
                      : attachment.attachment_original_url
                  }
                  type="application/pdf"
                  style={{
                    width: "100%",
                    height:
                      i === 0 &&
                      (attachments.length === 1 || attachments.length === 2)
                        ? "500px"
                        : i === 0 && attachments.length >= 3
                        ? "300px"
                        : i === 1 && attachments.length === 2
                        ? "300px"
                        : i === 1 && attachments.length >= 3
                        ? "300px"
                        : i === 2
                        ? "150px"
                        : "100%",
                  }}
                >
                  <iframe
                    // className={
                    //   i === 0
                    //     ? `${
                    //         attachments.length >= 3 ? "attachment-3" : "attachment"
                    //       }`
                    //     : "attachment"
                    // }
                    key={i}
                    style={{
                      width: "100%",
                      height:
                        i === 0 &&
                        (attachments.length === 1 || attachments.length === 2)
                          ? "500px"
                          : i === 0 && attachments.length >= 3
                          ? "300px"
                          : i === 1 && attachments.length === 2
                          ? "300px"
                          : i === 1 && attachments.length >= 3
                          ? "300px"
                          : i === 2
                          ? "150px"
                          : "100%",
                    }}
                    src={
                      attachment.attachment_original_url.includes("http://")
                        ? attachment.attachment_original_url.replace(
                            "http://",
                            "https://"
                          )
                        : attachment.attachment_original_url
                    }
                    // style={{ height: "400px" ,border: "1px solid #f1f1f1"}}
                    // style={{ height: "100%" }}
                  />
                </object>
              );

          return null;
        })}
      </div>
      {attachments.length > 3 && (
        <div className="show-more-images">
          <span className="feed-image-hover-text">
            +{attachments.length - 3} more
          </span>
        </div>
      )}
    </div>
  );
};

export default FeedAttachments;
