import OtpInput from "react-otp-input";
import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { parsePhoneNumber } from "react-phone-number-input";

import toast from "react-hot-toast";
// import { loginService } from "../../services/authenticationService";
// import { sendRegistrationOtp } from "../../services/authenticationService";
import call from "../../assets/sign_up_and_sign_in_icons/Call.svg";
import PhoneInput from "react-phone-number-input/input";
import { Controller } from "react-hook-form";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { formatTime, getHeaders, ttlForDocId } from "../../utils/utils";
import useCountDown from "react-countdown-hook";
import CountrySelect from "../RegisterComponents/CountrySelect";
import en from "react-phone-number-input/locale/en.json";

const OtpLogin = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const history = useHistory();
  const headers = getHeaders();

  const firstRender = useRef(true);

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [contactInputError, setContactInputError] = useState({
    error: false,
  });
  const [loginDetails, setLoginDetails] = useState({});
  const [otpVerified, setOtpVerified] = useState("");
  const initialTime = 1800 * 1000;
  const interval = 1000;
  const [timeLeft, { start }] = useCountDown(initialTime, interval);
  const [inputOtp, setInputOtp] = useState("");
  const [enableResendOtp, setEnableResendOtp] = useState(false);

  const userPhone = localStorage.getItem("phone");
  const phoneNumber = userPhone && parsePhoneNumber(`+${userPhone}`);
  const [country, setCountry] = useState(
    phoneNumber ? phoneNumber.country : "IN"
  );

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  // const ph = parsePhoneNumber(`+${userPhone}`).nationalNumber;

  // useLayoutEffect(() => {
  //  if(firstRender.current){
  //    firstRender.current = false;
  //    return;
  //  }
  // })

  const setDefaultPhone = () => {
    const phoneNumber = userPhone && parsePhoneNumber(`+${userPhone}`);
    if (phoneNumber) {
      setCountry(phoneNumber.country);
      setValue("phone", phoneNumber.number);
    }
  };

  useEffect(() => {
    setDefaultPhone();
  }, []);

  useEffect(() => {
    setOtpVerified("");
  }, [inputOtp]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    reset();
    setContactInputError({
      error: false,
    });
    // eslint-disable-next-line
  }, [country]);

  const login = async () => {
    let payload = {
      app_uname: loginDetails.email ? loginDetails.email : loginDetails.phone,
      app_pass: loginDetails.otp,
      stay_logged_in: true,
      isOTP: true,
    };

    const res = await fetch(baseUrl + "v2/restore/loginProfile", {
      // const res = await fetch(baseUrl + "login", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    if (data.status === "success") {
      if (data.data.doctor_valid === true) {
        const expiryDate = new Date().getTime() + ttlForDocId;

        localStorage.setItem("expiry_date", expiryDate);
        localStorage.setItem("doc_id", data.data.doc_id);
        localStorage.setItem("auth_token", data.data.security_token);
        localStorage.setItem("is_user_active", data.data.is_user_active);
        localStorage.setItem(
          "is_speciality_added",
          data.data?.personal_info?.speciality ? "true" : "false"
        );

        // Redirect to dashboard
        history.push({
          pathname: "/dashboard",
          // state: {
          //   is_user_active: data.data.is_user_active,
          //   is_speciality_added: data.data?.personal_info?.speciality
          //     ? true
          //     : false,
          // },
        });
      } else {
        toast.error("Invalid credentials");
      }
    } else {
      toast.error(data.errorMsg);
    }
  };

  const checkOtp = (e) => {
    e.preventDefault();
    if (timeLeft === 0) return;
    if (loginDetails.otp === inputOtp) {
      login();
    } else setOtpVerified("failed");
  };

  const validatePhoneInput = (phone) => {
    let isValid = true;
    if (!phone) {
      isValid = false;
      // error = "This field is required";
    }
    if (!isPossiblePhoneNumber(phone)) {
      isValid = false;
      // error = "Please enter valid number";
    }
    if (!isValidPhoneNumber(phone)) {
      isValid = false;
      // error = "Please enter valid number";
    }

    return isValid;
  };

  const sendOTP = async (formData) => {
    setOtpVerified("");
    setInputOtp("");

    const payload = {
      contact: formData.phone.trim().substring(1),
      contactType: "PHONE",
    };

    const res = await fetch(baseUrl + "sendOTP", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();

    if (data.status === "success") {
      start();
      setIsOtpSent(true);
      // setRecievedOtp(data.otp);
      setLoginDetails({
        ...loginDetails,
        email: data.cnt_email,
        phone: data.cnt_num,
        otp: data.otp,
      });
      setContactInputError({
        error: false,
      });
      toast.success("otp sent");
      setEnableResendOtp(false);
      setTimeout(function () {
        setEnableResendOtp(true);
      }, 30000);
    } else {
      setContactInputError({
        error: true,
        errorMsg: "No Account exists with this Phone Number",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(sendOTP)}>
      <div style={{ marginTop: "25px" }}>
        <div className="row " style={{ margin: "1em 0", height: "3.5em" }}>
          <div className="col-5 col-sm-4" style={{ paddingLeft: "0px" }}>
            <CountrySelect
              isRegister={true}
              value={country}
              onChange={setCountry}
              labels={en}
              disabled={isOtpSent}
            />
          </div>
          <div
            className="col-7 col-sm-8"
            style={{
              padding: "0px",
              background: "#F6F6F6",
              borderRadius: "9px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img src={call} alt="" style={{ margin: "1em" }} />

              <Controller
                name="phone"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => validatePhoneInput(value),
                }}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    country={country}
                    value={value}
                    onChange={(value) => {
                      if (!value) {
                        reset();
                        setContactInputError({
                          error: false,
                        });
                      }
                      onChange(value);
                    }}
                    placeholder="Contact Number *"
                    style={{
                      background: "inherit",
                      width: "100%",
                      border: "none",
                    }}
                    disabled={isOtpSent}
                  />
                )}
              />
            </div>
          </div>
        </div>
        {errors.phone && (
          <div className="row error-title">
            <span className="error-title-text">
              Please enter a valid phone number
            </span>
          </div>
        )}
        {contactInputError.error && (
          <div className="row error-title">
            <span className="error-title-text">
              {contactInputError.errorMsg}
            </span>
          </div>
        )}
      </div>

      {isOtpSent && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="">
              <span style={{ color: "white" }}>An OTP has been sent</span>
            </div>
            <div className="text-white">
              <span
                onClick={() => {
                  setIsOtpSent(false);
                  setOtpVerified("");
                }}
                style={{ color: "yellow", cursor: "pointer" }}
              >
                Edit Phone number
              </span>
            </div>
          </div>

          <div className="form-group">
            <label style={{ color: "white" }}>Enter OTP</label>
            <div style={{ display: "flex" }}>
              <OtpInput
                className="otp"
                value={inputOtp}
                onChange={(otp) => {
                  setInputOtp(otp);
                }}
                isInputNum="true"
                inputStyle={{
                  width: "2em",
                  height: "35px",
                  backgroundColor: "whitesmoke",
                  border: "1px solid",
                }}
                numInputs={4}
                separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
              />

              {enableResendOtp && (
                <button
                  type="submit"
                  style={{
                    marginLeft: "30px",
                    color: "yellow",
                    cursor: "pointer",
                  }}
                >
                  Resend OTP?
                </button>
              )}
            </div>
          </div>
          {timeLeft === 0 ? (
            <div className="error-title">
              <span className="error-title-text">Otp Expired</span>
            </div>
          ) : (
            <span style={{ color: "white" }}>
              OTP Expires in {formatTime(timeLeft)} mins
            </span>
          )}
          {otpVerified === "failed" && (
            <div className="error-title">
              <span className="error-title-text">
                You Have Entered an Invalid OTP
              </span>
            </div>
          )}
        </>
      )}

      {isOtpSent ? (
        <div className="login-button">
          <button
            onClick={(e) => checkOtp(e)}
            style={{
              backgroundColor: "#0FB59B",
              borderRadius: "10px",
            }}
            className="submit-btn"
          >
            Verify
          </button>
        </div>
      ) : (
        <div className="login-button" style={{ marginTop: "25px" }}>
          <button
            type="submit"
            style={{
              backgroundColor: "#0FB59B",
              borderRadius: "10px",
              height: "3em",
              marginTop: 0,
            }}
            className="submit-btn"
          >
            Request OTP
          </button>
        </div>
      )}
    </form>
  );
};

export default OtpLogin;
