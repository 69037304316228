import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  config,
  formatNumberToDisplay,
  getAuthToken,
  getDocId,
  getHeaders,
  logout,
  renderDays,
} from "../utils/utils";
import getWindowDimensions from "../common/hooks/getWindowDimensions";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DahboardHeader from "../dashboard/DahboardHeader";
import { useHistory, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import closeIcons from "../assets/dashboard_icons/addIcon.png";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import profile from "../assets/profile_icons/default-profile.png";
import mail from "../assets/profile_icons/Icon zocial-email.svg";
import call from "../assets/profile_icons/Icon awesome-phone-square-alt.svg";
// import copyright from "../assets/profile_icons/Group 2191.svg";
// import shareicon from "../assets/profile_icons/Icon ionic-md-arrow-dropup.svg";
import certificate from "../assets/dashboard_icons/Group 948.png";
import online from "../assets/new_profile_icons/ic_online.svg";
import print from "../assets/new_profile_icons/ic_print.svg";
import ic_membership from "../assets/new_profile_icons/ic_membership.svg";
import ic_award from "../assets/new_profile_icons/ic_award.svg";
import ic_event from "../assets/new_profile_icons/ic_event.svg";
import arrow from "../assets/profile_icons/Icon awesome-external-link-square-alt.svg";
import Help from "./Help";
import { useParams } from "react-router-dom";
import logoutIcon from "../assets/profile_icons/Icon awesome-sign-out-alt.svg";
import helpIcon from "../assets/profile_icons/Icon ionic-ios-help-circle.svg";
import profileIcon from "../assets/profile_icons/Iconly-Bold-Profile.svg";
import DahboardRightside from "../dashboard/DahboardRightside";
import Feeds from "../dashboard/feeds/Feeds";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import FullViewFeed from "../dashboard/feeds/FullViewFeed";
import CommentsList from "../dashboard/social-interaction/CommentsList";
import Likes from "../dashboard/social-interaction/Likes";
import usePostsLoader from "./usePostsLoader";
import S3 from "react-aws-s3";
import Footer from "../common/Footer";
import CommentInput from "../dashboard/social-interaction/CommentInput";
import send from "../assets/Icons/Icon feather-arrow-left@2x.png";
import MciVerificationStatus from "../common/MciVerificationStatus";
import MciUpload from "../common/MciUpload";
import MciUploadSuccess from "../common/MciUploadSuccess";
import EditModal from "./EditModal";
import PublicationsModal from "./PublicationsModal";
import DeletePublications from "./DeletePublications";
import deleteIcon from "../assets/Delete-2x.png";
import { HiLink } from "react-icons/hi";
import { BsPlusCircle } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import { MdVerifiedUser } from "react-icons/md";
import Publications from "./Publications";
import Events from "./Events";
import AwardsMembership from "./AwardsMembership";
import DeleteAwardMember from "./DeleteAwardMember";
import { MdArrowBackIos } from "react-icons/md";
import { IoMegaphoneOutline, IoCloseCircleOutline } from "react-icons/io5";
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from "react-icons/io";
import ContactDetailsForm from "./ContactDetailsForm";
import { parsePhoneNumber } from "react-phone-number-input";
import "./profile.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { FaBlogger, FaAddressCard } from "react-icons/fa";
import EditPersonalInfo from "./EditPersonalDeatils/EditPersonalInfo";
import EditPersonalMobileView from "./EditPersonalDeatils/EditPersonalMobileView";
import AvailableAt from "./AvailbaleAt/AvailableAt";
import QualificationsForm from "./QualificationsForm";
import { MdDeleteForever, MdOutlineArrowDownward } from "react-icons/md";
import qualificationIcon from "../assets/new_profile_icons/ic_qualification.svg";
import AreaOfInterests from "./AreaOfInterests";
import expCurrentIcon from "../assets/new_profile_icons/ic_exp_1.svg";
import expPastIcon from "../assets/new_profile_icons/ic_exp_2.svg";
import DeleteAvailable from "./AvailbaleAt/DeleteAvailable";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: 0,
      padding: "15px",
      marginBottom: "30px",
      webkitBoxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
      boxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
    },
    "& .MuiTypography-body1": {
      font: "normal normal normal 20px/20px Roboto",
      fontWeight: 400,
    },
    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      fontSize: "12px",
      fontWeight: "600",
    },

    "& .MuiPaper-elevation4 ": {
      // boxShadow:" 0px 2px 4px - 1px rgb(0 0 0 / 20 %)",Abou
      //  "0px 4px 5px 0px rgb(0 0 0 / 14 %)":
      //   "0px 1px 10px 0px rgb(0 0 0 / 12 %)"
      boxShadow: "0px 0px 0px 0px",
    },
    "& .MuiAppBar-positionStatic": {
      position: "static",
      borderBottom: "1px solid #cccccc",
    },

    "& .makeStyles-root-1 .MuiAccordion-rounded:last-child": {
      padding: "15px",
      border: "1px solid red",
      marginBottom: "30px",
    },
    "&  .PrivateTabIndicator-colorSecondary-4": {
      backgroundColor: "#1e8a79",
      border: "2px solid",
    },

    "& .makeStyles-root-6": {
      backgroundColor: "#1e8a79",
      border: "2px solid",
    },
    "& .MuiAccordionDetails-root ": {
      display: "block",
      padding: "0px 0px 0px",
    },
    "& > *": {
      marginTop: theme.spacing(2),
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
export default function Timeline({ feeds, setFeeds }) {
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const location = useLocation();
  const isHelp = location.pathname.includes("/help") ? "help" : "profile";
  const { id } = useParams();
  const user_id = getDocId();
  const otherUser = id ? (id === user_id ? false : true) : false;
  const doc_id = otherUser ? id : user_id;
  const [reloadFeeds, setReloadFeeds] = useState(false);
  // const profile_pic_placeholder = "/assets/images/photos/default-profile.png";
  const baseUrl = process.env.REACT_APP_BASE_URL;
  // const auth_token = getAuthToken();
  const [navigation, setNavigation] = useState(isHelp);
  const navigate = (to) => setNavigation(to);
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();
  const [value, setValue] = React.useState("1");
  // const [pageNum, setPageNum] = useState(0);
  // const [feeds, setFeeds] = useState([]);
  const [onlineIndex, setOnlineIndex] = useState(2);
  const [userInfo, setUserInfo] = useState([]);
  const [originalUser, setOriginalUser] = useState({});
  const history = useHistory();
  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  const [isQualificationEdit, setIsQualificationEdit] = useState(false);
  const [acadId, setAcadId] = useState(null);

  const [isAoiEdit, setIsAoiEdit] = useState(false);
  const [interestId, setInterestId] = useState(null);
  const [isExperienceEdit, setIsExperienceEdit] = useState(false);
  const [profId, setProfId] = useState(null);

  // const [channels, setChannels] = useState([]);
  const [modalData, setModalData] = useState({});

  const [imgPreviewUrl, setImgPreviewUrl] = useState("");

  const profilePicInput = useRef(null);

  const [fullViewModal, setFullViewModal] = useState(false);
  const openFullViewModal = (feed) => {
    setModalData(feed);
    setFullViewModal(true);
  };
  const closeFullViewModal = () => setFullViewModal(false);

  const [likesModal, setLikesModal] = useState(false);
  const openLikesModal = (feed) => {
    setModalData(feed);
    setLikesModal(true);
  };
  const closeLikesModal = () => setLikesModal(false);

  const [commentModal, setCommentModal] = useState(false);
  const [workExperience, setWorkExperience] = useState(true);
  const openCommentModal = (feed) => {
    setModalData(feed);
    setCommentModal(true);
  };
  const closeCommentModal = () => setCommentModal(false);

  const [openVerificationPending, setOpenVerificationPending] = useState(false);

  const handleVerificationPending = (feed) => {
    // handleUploadMci();
    setOpenVerificationPending(true);
  };
  const handleCloseVerificationPending = () => {
    setOpenVerificationPending(false);
  };

  const [uploadModel, setUploadModel] = useState(false);

  const handleUploadMci = () => {
    setUploadModel(true);
  };

  const handleCloseUploadMci = () => {
    setUploadModel(false);
  };

  const [isMciUploaded, setIsMciUploaded] = useState(1);
  const [uploadMciSuccess, setUploadMciSuccess] = useState(false);
  const openUploadMciSuccess = () => setUploadMciSuccess(true);
  const closeUploadMciSuccess = () => {
    setIsMciUploaded(2);
    setUploadMciSuccess(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const setArrowDown = () => {
    setNoOfAwards(userInfo?.award_history?.length);
    setNoOfMems(userInfo?.membership_history?.length);
  };
  const setArrowUp = () => {
    setNoOfAwards(2);
    setNoOfMems(2);
  };
  const setPubArrowDown = () => {
    setNoOfPrints(userInfo?.print_pub_history?.length);
    setNoOfOnline(userInfo?.online_pub_history?.length);
  };
  const setPubArrowUp = () => {
    setNoOfPrints(2);
    setNoOfOnline(2);
  };
  const [publicationModal, setPublicationModal] = useState(false);
  const [deletePubModal, setDeletePubModal] = useState(false);
  const closePublicationModal = () => {
    setPublicationModal(false);
    setEditClicked(false);
    setEditedData("");
  };
  const closeDeletePubModal = () => {
    setDeletePubModal(false);
    setEditClicked(false);
    setDeletedData("");
  };
  const [editClicked, setEditClicked] = useState(false);
  const [editedData, setEditedData] = useState();
  const [deletedData, setDeletedData] = useState();

  const openPublicationModal = () => {
    if (windowDimensions.width <= 768)
      history.push({
        pathname: `/publication/${otherUser}`,
      });
    else {
      setEditClicked(false);
      setEditedData("");
      setPublicationModal(true);
    }
  };

  const deletePublication = (event, data) => {
    setEditClicked(false);
    setDeletedData(data);
    setDeletePubModal(true);
  };
  const editPublication = (e, data) => {
    setEditClicked(true);
    setEditedData(data);
    setPublicationModal(true);
  };

  const [eventsModal, setEventsModal] = useState(false);
  const [deleteEventsModal, setDeleteEventsModal] = useState(false);
  const closeEventsModal = () => {
    setEventsModal(false);
    setEditClicked(false);
    setEditedData("");
  };
  const closeDeleteEventsModal = () => {
    setDeleteEventsModal(false);
    setEditClicked(false);
    setDeletedData("");
  };

  const openEventsModal = () => {
    if (windowDimensions.width <= 768)
      history.push({
        pathname: `/events/${otherUser}`,
      });
    else {
      setEditClicked(false);
      setEditedData("");
      setEventsModal(true);
    }
  };
  const deleteEvents = (event, data) => {
    setEditClicked(false);
    setDeletedData(data);
    setDeleteMembershipModal(true);
  };
  const editEvents = (e, data) => {
    setEditClicked(true);
    setEditedData(data);
    setEventsModal(true);
  };

  const deleteAvailability = (event, data) => {
    setEditClicked(false);
    setDeletedData(data);
    setDeletePubModal(true);
  };
  const editAvailability = (e, data) => {
    setEditClicked(true);
    setEditedData(data);
    setAvailableAtDetailsModal(true);
  };

  const [awardsMembershipModal, setAwardsMembershipModal] = useState(false);
  const [deleteMembershipModal, setDeleteMembershipModal] = useState(false);
  const closeAwardsMembershipModal = () => {
    setAwardsMembershipModal(false);
    setEditClicked(false);
    setEditedData("");
  };
  const openMembershipModal = () => {
    history.push({
      pathname: `/membership/${otherUser}`,
    });
  };

  const openAwardsModal = () => {
    history.push({
      pathname: `/awards/${otherUser}`,
    });
  };
  const closeDeleteAwardsMembershipModal = () => {
    setDeleteMembershipModal(false);
    setEditClicked(false);
    setDeletedData("");
  };
  const deleteAwardsMembership = (event, data) => {
    setEditClicked(false);
    setDeletedData(data);
    setDeleteMembershipModal(true);
  };
  const editAwardsMembership = (e, data) => {
    setEditClicked(true);
    setEditedData(data);
    setAwardsMembershipModal(true);
  };
  const openAwardsMembershipModal = () => {
    setAwardsMembershipModal(true);
  };
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);

  const fileInput = useRef(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const [contactDetailsModal, setContactDetailsModal] = useState(false);
  const closeContactDetailsModal = () => setContactDetailsModal(false);
  const openContactDetailsModal = () => setContactDetailsModal(true);

  const [qualificationsModal, setQualificationsModal] = useState(false);
  const closeQualificationsModal = () => {
    setIsQualificationEdit(false);
    setQualificationsModal(false);
  };
  const openQualificationsModal = () => setQualificationsModal(true);

  const [aoiModal, setAoiModal] = useState(false);
  const openAoiModal = () => setAoiModal(true);
  const closeAoiModal = () => {
    setIsAoiEdit(false);
    setAoiModal(false);
  };

  const [confirmDelete, setConfirmDelete] = useState(false);
  const closeConfirmDelete = () => setConfirmDelete(false);
  const openConfirmDelete = (id, deleteFunc) => {
    setDeleteData({
      id,
      delete: deleteFunc,
    });
    setConfirmDelete(true);
  };

  const [deleteData, setDeleteData] = useState({
    id: 0,
    delete: () => {},
  });

  const [personalDetailsModal, setPersonalDetailsModal] = useState(false);
  const closePersonalDetailsModal = () => setPersonalDetailsModal(false);
  const openPersonalDetailsModal = () => setPersonalDetailsModal(true);

  const [availableAtDetailsModal, setAvailableAtDetailsModal] = useState(false);
  const closeAvailableAtDetailsModal = () => {
    setIsExperienceEdit(false);
    setAvailableAtDetailsModal(false);
  };
  // const openAvailableAtDetailsModal = () => setAvailableAtDetailsModal(true);
  const [noOfQualifications, setNoOfQualifications] = useState(2);
  const [noOfAvailableAt, setNoOfAvailableAt] = useState(2);
  const [noOfExperience, setNoOfExperience] = useState(2);
  const [noOfEvents, setNoOfEvents] = useState(2);
  const [noOfAwards, setNoOfAwards] = useState(2);
  const [noOfMems, setNoOfMems] = useState(2);
  const [noOfPrints, setNoOfPrints] = useState(2);
  const [noOfOnline, setNoOfOnline] = useState(2);

  const openAvailableAtDetailsModal = () => {
    if (windowDimensions.width <= 768)
      history.push({
        pathname: `/AvailableAt/${otherUser}`,
      });
    else {
      setAvailableAtDetailsModal(true);
    }
  };
  const saveFile = async (url, doc_id) => {
    const payload = {
      doc_id,
      attachment_original_url: url,
    };

    setloaderSpinner(true);
    const res = await fetch(baseUrl + "v2/upload", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();
    setloaderSpinner(false);

    if (data.status === "success") {
      // addToast("uploaded Successfully", {
      //   appearance: "success",
      //   autoDismiss: true,
      // });
      handleCloseUploadMci();
      openUploadMciSuccess();
      getUserData();
    } else toast.error(data.errorMsg);
  };

  const uploadFile = async (e, fileInput, doc_id) => {
    e.preventDefault();
    let file = fileInput.current.files[0];
    let newFileName = fileInput.current.files[0].name;

    const ReactS3Client = new S3(config);
    setloaderSpinner(true);
    const data = await ReactS3Client.uploadFile(file, newFileName);
    setloaderSpinner(false);

    saveFile(data.location, doc_id);
  };

  const deleteUploadedImage = (e) => {
    e.preventDefault();

    fileInput.current.value = "";
    setImgPreviewUrl("");
    setIsFileUploaded(false);
  };
  const reUploadImage = (e) => {
    deleteUploadedImage(e);
    fileInput.current.click();
  };
  const showImagePreview = () => {
    let type = fileInput.current.files[0].type;
    if (type.includes("image")) type = "image";
    else if (type.includes("video")) type = "video";
    else if (type.includes("audio")) type = "audio";
    else if (type.includes("pdf")) type = "pdf";

    if (type === "image") {
      setIsFileUploaded(true);
      setImgPreviewUrl(URL.createObjectURL(fileInput.current.files[0]));
    } else {
      toast.error("Only images are allowed");
    }
  };

  const headers = getHeaders();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange1 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);

    getUserData();
    if (otherUser) getOriginalUser();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMciUploaded === 2) getUserData();
  }, [isMciUploaded]);

  const [nextFeedsData, setNextFeedsData] = useState({
    // pageNum: 0,
    lastFeedId: 0,
  });
  const { loading, error, channels, hasMore } = usePostsLoader(
    user_id,
    doc_id,
    nextFeedsData,
    setFeeds,
    reloadFeeds
  );

  const observer = useRef(null);
  const loader = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setNextFeedsData((prev) => ({
            ...prev,
            lastFeedId: feeds[feeds.length - 1]?.feed_info?.feed_id,
          }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  const getOriginalUser = async () => {
    const payload = {
      user_id,
    };
    const res = await fetch(baseUrl + "v1/userProfile/view", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();

    if (data.status === "success") {
      setOriginalUser(data.data);
    }
  };

  const getUserData = async () => {
    const url = otherUser
      ? "v1/userProfile/view?other=true"
      : "v1/userProfile/view";

    const payload = {
      user_id,
      otherUserId: otherUser ? id : undefined,
    };

    const res = await fetch(baseUrl + url, {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
    });
    const data = await res.json();
    const pHistory = data.data;

    const workExperience = pHistory?.prof_history?.map((res, index) => {
      setWorkExperience(res.working_here);
      return res.working_here;
    });

    if (data.status === "success") {
      data.data.events.reverse();
      setUserInfo(data.data);
      // if (data?.data?.award_history?.length > 2) {
      //   setNoOfAwards(userInfo?.award_history?.length);
      // }
      // if (data?.data?.membership_history?.length > 2) {
      //   setNoOfMems(userInfo?.membership_history?.length);
      // }
      // if (data?.data?.print_pub_history?.length > 2) {
      //   setNoOfPrints(userInfo?.print_pub_history?.length);
      // }
      // if (data?.data?.online_pub_history?.length > 2) {
      //   setNoOfOnline(userInfo?.online_pub_history?.length);
      // }
      if (data.data?.print_pub_history?.length == 0) {
        setOnlineIndex(2);
      } else if (data.data?.print_pub_history?.length == 1) {
        setOnlineIndex(1);
      } else if (data.data.print_pub_history?.length >= 2) {
        setOnlineIndex(0);
      } else if (data.data.print_pub_history == undefined) {
        setOnlineIndex(2);
      }
    } else {
      toast.error(data.errorMsg);
      history.push("/login");
    }
    return data;
  };

  //  const getExperience=userInfo.prof_history.map((exp, key) => {
  //   // console.log("experience"+exp.working_here?exp.working_here:'null');
  //  // return exp.working_here?exp.working_here:'null';

  //   });
  const checkUserVerification = () => {
    const user = otherUser ? originalUser : userInfo;

    if (!user?.verificationInfo.isCommunityVerified) {
      if (user?.verificationInfo.is_user_verified === 1) {
        handleUploadMci();
        return true;
      }
      if (user?.verificationInfo.is_user_verified === 2) {
        handleVerificationPending();
        return true;
      }
    }
    return false;
  };

  const addComment = async (feed_id, channel_id, comment, attachments = []) => {
    if (checkUserVerification()) return;

    if (attachments.length === 0 && comment === "") {
      toast.error("Cannot post empty comment");
      return;
    }
    let payload = JSON.stringify({
      doc_id: user_id,
      channel_id,
      feedTypeId: feed_id,
      socialInteraction: {
        type: "Comment",
        comment,
        attachment_details: attachments,
      },
    });

    payload = payload.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });

    payload = encodeURIComponent(payload);

    const res = await fetch(baseUrl + "v1/feedSocialInteractions", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    if (data.status === "success")
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed.feed_info.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    else toast.error(data.errorMsg);
  };

  const deleteComment = async (feed_id, channel_id, social_interaction_id) => {
    setloaderSpinner(true);
    const payload = {
      user_id: user_id,
      channel_id: channel_id,
      feed_id,
      social_interaction_id,
    };
    const res = await fetch(baseUrl + "v1/feeds/comment/delete", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();
    setloaderSpinner(false);
    if (data.status === "success")
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed.feed_info.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    else toast.error(data.errorMsg);
  };

  const updateComment = async (
    feed_id,
    channel_id,
    social_interaction_id,
    comment,
    attachments = []
  ) => {
    if (checkUserVerification()) return;

    if (attachments.length === 0 && comment === "") {
      // addToast("Cannot post empty comment", {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
      return;
    }

    let payload = JSON.stringify({
      user_id: user_id,
      channel_id,
      feed_id,
      social_interaction_id,
      comment: comment,
      attachment_details: attachments,
    });

    payload = payload.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });

    payload = encodeURIComponent(payload);

    setloaderSpinner(true);
    const res = await fetch(baseUrl + "v1/feeds/comment/update", {
      method: "POST",
      headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();
    setloaderSpinner(false);
    if (data.status === "success")
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed?.feed_info?.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    else toast.error(data.errorMsg);
  };
  const updateLikeCount = async (feed_id, channel_id, isLike) => {
    if (checkUserVerification()) return;

    const payload = {
      doc_id: user_id,
      channel_id: channel_id,
      feedTypeId: feed_id,
      socialInteraction: {
        type: "Like",
        isLike,
      },
    };

    const res = await fetch(baseUrl + "v1/feedSocialInteractions", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    if (data.status === "success")
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed.feed_info.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    else toast.error(data.errorMsg);
  };
  const updateViewCount = async (channel_id, feed_id) => {
    const payload = {
      doc_id: user_id,
      channel_id: channel_id,
      feedId: feed_id,
    };

    const res = await fetch(baseUrl + "feeds/updateViewCount", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    if (data.status === "success")
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed.feed_info.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    else toast.error(data.errorMsg);
  };
  const submitSurvey = async (feed_id, channel_id, question_id, option_id) => {
    const payload = {
      feed_id,
      channel_id,
      user_id: user_id,
      feed_survey: {
        questions: [
          {
            question_id,
            options: [
              {
                option_id,
              },
            ],
          },
        ],
      },
    };

    const res = await fetch(baseUrl + "v1/feed/survey/update", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();
  };
  const profileName = (string) => {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const closeIcon = <img src={close} />;

  const registerWebinar = async (e, feed_id, verify_user, title) => {
    e.preventDefault();

    if (verify_user) {
      if (checkUserVerification()) return;
    }

    const payload = {
      user_id: user_id,
      feed_id,
    };

    const res = await fetch(baseUrl + "v1/user/webinar/register", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
    });

    const data = await res.json();

    if (data.status === "success") {
      toast.success(`Thank you for registering for ${title}`);
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed?.feed_info?.feed_id === feed_id)
            newFeed.feed_info.event_details.is_user_registered = true;
          return newFeed;
        })
      );
      return true;
    } else if (data.status === "error") toast.error("Failed to registered");

    return false;
  };

  const copyLink = (copyText) => {
    navigator.clipboard.writeText(copyText);
    toast.success("Copied");
  };

  const verifyEmail = async () => {
    const payload = {
      user_id: user_id,
      cnt_email: userInfo.cnt_email,
      cnt_num: userInfo.cnt_num,
      cnt_num_vis: userInfo.cnt_num_vis,
      cnt_email_vis: userInfo.cnt_email_vis,
      socialInfo: userInfo.socialInfo,
    };

    const res = await fetch(baseUrl + "v1/userProfile/email/verify", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
    });

    const data = await res?.json();

    if (data?.status === "success")
      toast.success("Verification link has been sent to your email");
    else
      toast.error("Unable to send verification link, please try again later");
  };

  const editProfilePic = () => {
    profilePicInput.current.value = "";
    profilePicInput.current.click();
  };

  const uploadImage = () => {
    let type = profilePicInput.current.files[0].type;
    if (type.includes("image")) type = "image";
    else if (type.includes("video")) type = "video";
    else if (type.includes("audio")) type = "audio";
    else if (type.includes("pdf")) type = "pdf";

    if (type === "image") {
      uploadProfilePic();
    } else {
      toast.error("Please upload an image");
    }
  };

  const uploadProfilePic = async () => {
    const formData = new FormData();
    let modifiedHeaders = { ...headers };
    delete modifiedHeaders["Content-Type"];
    let image = profilePicInput.current.files[0];

    const reqData = {
      isUpdate: true,
      user_id: user_id,
      user_first_name: userInfo.user_first_name,
      user_last_name: userInfo.user_last_name,
      user_salutation: userInfo.user_salutation,
      experience: userInfo.experience,
      user_type_id: userInfo.user_type_id,
      subSpeciality: userInfo.subSpeciality,
      specialities: userInfo.specialities,
      specialists: userInfo.specialists,
    };

    formData.append("reqData", JSON.stringify(reqData));

    if (profilePicInput.current) formData.append("file", image);

    const res = await fetch(baseUrl + "v1/userProfile/edit", {
      method: "POST",
      headers: modifiedHeaders,
      body: formData,
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
    });

    const data = await res.json();

    if (data.status === "success") {
      setUserInfo((prev) => ({
        ...prev,
        profile_pic_original_url: data.data.profile_pic_original_url,
        profile_pic_small_url: data.data.profile_pic_small_url,
      }));
      setReloadFeeds((prev) => !prev);
      // getUserData();
    } else toast.error("Error uploading file");
  };

  const contactDetailsSuccessFunc = () => {
    getUserData();
    closeContactDetailsModal();
    toast.success("Updated successfully");
  };

  const contactDetailsErrorFunc = () => {
    toast.error("Unable to update, please try after some time");
  };

  const getNumber = (number) => {
    const phoneNumber = parsePhoneNumber(`+${number}`);
    if (phoneNumber)
      return `+${phoneNumber.countryCallingCode} ${phoneNumber.nationalNumber}`;
    else return "";
  };

  const getEditPersonalMobileView = () => {
    if (windowDimensions.width <= 768)
      history.push({
        pathname: `/personal/${user_id}`,
      });
  };

  const getEditPersonalMobileView1 = () => {
    //history.push("/updateProfile")

    history.push({
      pathname: "/updateProfile",

      state: {
        background: location,

        profile_pic: userInfo?.profile_pic_small_url,

        user_verification_info: userInfo?.verificationInfo,
      },
    });
  };

  const editQualification = (acad_id) => {
    setAcadId(acad_id);
    setIsQualificationEdit(true);
    openQualificationsModal();
  };

  const deleteQualification = async (acad_id) => {
    const payload = {
      user_id,
      acad_history: [
        {
          acad_id,
        },
      ],
    };

    const res = await fetch(baseUrl + "/v1/userProfile/delete", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    console.log(data);
    if (data.status === "success") {
      setUserInfo((prev) => ({
        ...prev,
        acad_history: prev.acad_history.filter(
          (qualification) => qualification.acad_id !== acad_id
        ),
      }));

      toast.success("Qualification deleted");
    } else toast.error(data.errorMsg);
  };

  const deleteInterests = async (id) => {
    console.log(id);

    const payload = {
      user_id,
      areas_of_interest: [{ interest_id: id }],
    };

    const res = await fetch(baseUrl + "v1/userProfile/delete", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();
    console.log(data);

    if (data.status === "success") {
      setUserInfo((prev) => ({
        ...prev,
        areas_of_interest: prev.areas_of_interest.filter(
          (interest) => interest.interest_id !== id
        ),
      }));

      toast.success("Area Of Interest deleted");
    } else toast.error(data.errorMsg);
  };

  const editInterest = (interest_id) => () => {
    if (otherUser) return;
    if (windowDimensions.width < 728) history.push(`/interests/${interest_id}`);
    else {
      setInterestId(interest_id);
      setIsAoiEdit(true);
      openAoiModal();
    }
  };

  const editExperience = (prof_id) => {
    setProfId(prof_id);
    setIsExperienceEdit(true);
    openAvailableAtDetailsModal();
  };

  const deleteExperience = async (prof_id) => {
    setloaderSpinner(true);
    const payload = { user_id: user_id, prof_history: [{ prof_id }] };
    const res = await fetch(baseUrl + "v1/userProfile/delete", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      setloaderSpinner(false);
      setUserInfo((prev) => ({
        ...prev,
        prof_history: prev.prof_history.filter(
          (exp) => exp.prof_id !== prof_id
        ),
      }));
      toast.success("Experience deleted");
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };

  const sortExperiences = (exps) => {
    if (!exps) return [];
    const res = [...exps];
    res.sort((a, b) => {
      if (b.working_here && a.working_here) {
        if (!b.from_date && a.from_date) return -1;
        else if (b.from_date && !a.from_date) return 1;
        else if (!b.from_date && !a.from_date) return b.prof_id - a.prof_id;
        else
          return (
            new Date(b.from_date).getTime() - new Date(a.from_date).getTime()
          );
      } else if (b.working_here && !a.working_here) return 1;
      else if (!b.working_here && a.working_here) return -1;
      else {
        if (a.to_date && b.to_date) {
          if (
            new Date(b.to_date).getFullYear() ===
            new Date(a.to_date).getFullYear()
          )
            return (
              new Date(b.from_date).getTime() - new Date(a.from_date).getTime()
            );
          return new Date(b.to_date).getTime() - new Date(a.to_date).getTime();
        } else if (a.to_date && !b.to_date) return -1;
        else if (!a.to_date && b.to_date) return 1;
        else if (a.from_date && b.from_date)
          return (
            new Date(b.from_date).getTime() - new Date(a.from_date).getTime()
          );
        else if (!a.from_date && b.from_date) return 1;
        else if (a.from_date && !b.from_date) return -1;
        else return b.prof_id - a.prof_id;
      }
    });
    return res;
  };

  const sortQualifications = (arr) => {
    if (!arr) return [];
    const res = [...arr];
    res.sort((a, b) => {
      if (a.currently_pursuing && b.currently_pursuing)
        return b.acad_id - a.acad_id;
      else if (a.currently_pursuing && !b.currently_pursuing) return -1;
      else if (!a.currently_pursuing && b.currently_pursuing) return 1;
      else {
        if (b.passing_year === a.passing_year) return b.acad_id - a.acad_id;
        else return b.passing_year - a.passing_year;
      }
    });
    return res;
  };

  const checkShowOnCard = (show_on_card) => {
    if (show_on_card)
      toast(
        "WhiteCoats requires at least one workplace to be displayed on your card"
      );
    return show_on_card;
  };

  return (
    <>
      <div style={{ margin: "auto", height: "100%" }}>
        {windowDimensions.width > 768 && (
          <header>
            <DahboardHeader
              userData={otherUser ? originalUser : userInfo}
              handleUploadMci={handleUploadMci}
              handleVerificationPending={handleVerificationPending}
            ></DahboardHeader>
          </header>
        )}
        <main>
          <div
            className="d-none d-sm-block"
            style={{ marginTop: "80px" }}
          ></div>
          {/* <div
            className="d-block d-lg-none"
            style={{ marginTop: "60px" }}
          ></div> */}
          <div className="timeline"></div>
          <div className="container">
            <div className="row ">
              {!otherUser && (
                <div className="col-lg-2 col-md-3  d-none d-sm-block ">
                  <div className="profile-card">
                    <a
                      style={{
                        marginTop: "10px",
                        cursor: "pointer",
                        color: "#8D8D8D",
                        padding: "5px 0px 0px 5px",
                        fontSize: "12px",
                      }}
                    >
                      Profile Settings
                    </a>
                    <hr style={{ marginTop: "5px" }}></hr>
                    <div className="widget-body">
                      <ul className="author-into" style={{ listStyle: "none" }}>
                        <li
                          className="timeline-title"
                          onClick={() => navigate("profile")}
                          style={{
                            cursor: "pointer",
                            color: "#8D8D8D",
                            backgroundColor:
                              navigation === "profile" ? "#DFF2EF" : "white",
                            padding: "10px",
                          }}
                        >
                          <img src={profileIcon}></img>
                          <span
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              fontWeight: "400",
                              color:
                                navigation === "profile"
                                  ? " #2A8F80"
                                  : "#8D8D8D",
                            }}
                          >
                            My Profile
                          </span>
                        </li>
                        <li
                          className="timeline-title"
                          onClick={() => navigate("help")}
                          style={{
                            color: "#8D8D8D",
                            cursor: "pointer",
                            backgroundColor:
                              navigation === "help" ? "#DFF2EF" : "white",
                            padding: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <img src={helpIcon}></img>
                          <span
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              fontWeight: "400",
                              color:
                                navigation === "help" ? " #2A8F80" : "#8D8D8D",
                            }}
                          >
                            Help
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="profile-card" style={{ marginTop: "20px" }}>
                    <div className="widget-body">
                      <p
                        onClick={() => logout(history)}
                        style={{
                          cursor: "pointer",
                          color: "#8D8D8D",
                          padding: "15px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={logoutIcon}
                          style={{ marginRight: "10px" }}
                        ></img>
                        Logout
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {
                {
                  profile: (
                    <div
                      className={` ${
                        otherUser ? "col-lg-9" : "col-lg-10"
                      } col-md-9`}
                      style={{ backgroundColor: "white", padding: "0px" }}
                    >
                      {/* start desktop section for time line */}
                      <div>
                        <div
                          className="timeline-cover d-none d-sm-block"
                          style={{
                            backgroundImage: `url("/assets/profile_icons/Group 2180.png")`,
                          }}
                        >
                          <div className="timeline-nav-bar">
                            <div className="row mx-0">
                              <div className="col-md-3 ">
                                <div className="profile-info">
                                  <img
                                    src={
                                      userInfo?.profile_pic_original_url &&
                                      userInfo?.profile_pic_original_url !== ""
                                        ? userInfo.profile_pic_original_url +
                                          `?${Date.now()}`
                                        : profile
                                    }
                                    className="img-responsive profile-photo"
                                  />
                                  {!otherUser && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        bottom: "20px",
                                        right: "22px",
                                        cursor: "pointer",
                                      }}
                                      onClick={editProfilePic}
                                    >
                                      <MdEdit
                                        style={iconBackgroundStyle}
                                        size={25}
                                      />
                                      <input
                                        type="file"
                                        accept=",jpg, .jpeg, .png"
                                        onChange={uploadImage}
                                        ref={profilePicInput}
                                        style={{ display: "none" }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-9"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end desktop section for time line */}

                      {/* start responsie section for time line */}

                      <div
                        className="d-block d-lg-none"
                        style={{
                          backgroundColor: "#1e8a79",
                          height: "6em",
                        }}
                      >
                        <MdArrowBackIos
                          style={{
                            color: "white",
                            margin: "0.9em",
                            cursor: "pointer",
                          }}
                          size={32}
                          onClick={() => history.push("/dashboard")}
                        />

                        <div
                          style={{
                            transform: "translate(30% ,-20%)",
                            width: "fit-content",
                          }}
                        >
                          <img
                            src={
                              userInfo?.profile_pic_original_url &&
                              userInfo?.profile_pic_original_url !== ""
                                ? userInfo.profile_pic_original_url +
                                  `?${Date.now()}`
                                : profile
                            }
                            alt=""
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50%",
                            }}
                          />
                          {!otherUser && (
                            <div
                              style={{
                                position: "absolute",
                                bottom: "10px",
                                right: "0",
                                cursor: "pointer",
                              }}
                              onClick={editProfilePic}
                            >
                              <MdEdit style={iconBackgroundStyle} size={25} />
                              <input
                                type="file"
                                accept=",jpg, .jpeg, .png"
                                onChange={uploadImage}
                                ref={profilePicInput}
                                style={{ display: "none" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        className="d-block d-lg-none"
                        style={{ marginTop: "60px" }}
                      >
                        <div>
                          <div
                            style={{
                              margin: "1em",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              wordBreak: "break-all",
                            }}
                            className="timeline-username-container"
                          >
                            <p className="timeline-title">
                              {userInfo?.user_salutation}&nbsp;
                              {profileName(userInfo?.user_full_name)}
                            </p>

                            {!otherUser && (
                              <button
                                style={{
                                  border: "1px solid rgb(30 138 121)",
                                  padding: "0.7rem 1.5rem",
                                  borderRadius: "20px",
                                  backgroundColor: "#ddfdf7",
                                }}
                                onClick={getEditPersonalMobileView}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "3.5em",
                                  }}
                                >
                                  <MdEdit
                                    style={iconBackgroundStyle}
                                    size={20}
                                  />
                                  <span>Edit</span>
                                </div>
                              </button>
                            )}
                          </div>
                          <div
                            className="timeline-username-specialities"
                            style={{ margin: "1em" }}
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                color: "#444444",
                                display: "flex",
                                justifyContent: "space-between",
                                width: "60%",
                                alignItems: "center",
                              }}
                            >
                              {userInfo?.specialities}
                              <span>
                                {userInfo?.experience}&nbsp;
                                {!userInfo?.experience
                                  ? ""
                                  : userInfo?.experience > 1
                                  ? "Years"
                                  : "Year"}
                              </span>
                            </div>
                          </div>
                          {userInfo?.subSpeciality != "" && (
                            <div
                              className="timeline-username-specialities"
                              style={{ margin: "1em" }}
                            >
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "#444444",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "60%",
                                  alignItems: "center",
                                }}
                              >
                                {userInfo?.subSpeciality}
                              </div>
                            </div>
                          )}

                          <div style={{ margin: "1em" }}>
                            {userInfo?.socialInfo?.map((res) => (
                              <p style={{ fontSize: "1.5rem" }}>
                                {res.about_me && res?.about_me !== ""
                                  ? res.about_me
                                  : !otherUser
                                  ? `One line USP "Introduce yourself! We all would like to know you better"`
                                  : ""}
                              </p>
                            ))}
                          </div>
                          {!otherUser && (
                            <div
                              style={{
                                backgroundColor: "rgb(221 253 247)",
                                color: "slategray",
                                padding: "1em",
                                marginLeft: "-1em",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  marginLeft: "1em",
                                  fontSize: "1.5rem",
                                }}
                              >
                                <IoMegaphoneOutline size={25} />
                                <span
                                  style={{
                                    marginLeft: "1em",
                                    color: "#1e8a79",
                                  }}
                                >
                                  {userInfo?.socialInfo?.map((res) => (
                                    <p style={{ fontSize: "1.5rem" }}>
                                      {res.specific_ask &&
                                      res?.specific_ask !== ""
                                        ? res.specific_ask
                                        : !otherUser
                                        ? `Let others know what you exactly need"`
                                        : ""}
                                    </p>
                                  ))}
                                  {/* Let others know what you exactly need */}
                                  {}
                                </span>
                              </div>
                            </div>
                          )}
                          <div
                            className="feed-fullview"
                            style={{
                              // marginBlock: "1em",
                              margin: "1em 2em",
                              // marginInline: "2em",
                              // marginRight : "2em",
                              // marginLeft : "2em",
                            }}
                          >
                            <div className="timeline-connection">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span className="connection-title">
                                  {userInfo?.activity_count?.connects_cnt}
                                </span>
                                {userInfo?.activity_count?.connects_cnt
                                  ? userInfo?.activity_count?.connects_cnt > 1
                                    ? "Connects"
                                    : "Connect"
                                  : ""}
                              </div>
                            </div>
                            <div className="timeline-connection">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span className="connection-title">
                                  {formatNumberToDisplay(
                                    userInfo?.activity_count?.followers_count
                                  )}
                                </span>
                                Followers
                              </div>
                            </div>
                            <div className="timeline-connection">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span className="connection-title">
                                  {formatNumberToDisplay(
                                    userInfo?.activity_count?.following_count
                                  )}
                                </span>
                                Following
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* end responsie section for time line */}

                      <div
                        className="d-none d-sm-block"
                        style={{ marginTop: "80px" }}
                      >
                        <div
                        // className="container"
                        // style={{ marginLeft: "20px" }}
                        >
                          {!otherUser && (
                            <button
                              style={{
                                border: "1px solid rgb(30 138 121)",
                                padding: "1rem 1.7rem",
                                borderRadius: "20px",
                                float: "right",
                                marginTop: "-60px",
                                marginRight: "30px",
                              }}
                              onClick={openPersonalDetailsModal}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "3.5em",
                                }}
                              >
                                <MdEdit style={iconBackgroundStyle} size={20} />
                                <span>Edit</span>
                              </div>
                            </button>
                          )}
                          <div style={{ marginLeft: "25px" }}>
                            <div>
                              <div
                                style={{
                                  // marginBlock: "1rem" ,
                                  marginTop: "1rem",
                                  marginBottom: "1rem",
                                }}
                                className="timeline-username-container"
                              >
                                <p className="timeline-title">
                                  {userInfo?.user_salutation}&nbsp;
                                  {profileName(userInfo?.user_full_name)}
                                </p>
                              </div>
                              <div
                                style={{
                                  // marginBlock: "1rem",
                                  marginTop: "1rem",
                                  marginBottom: "1rem",
                                }}
                                className="timeline-username-specialities"
                              >
                                <h6 className="speciality-title">
                                  {userInfo?.specialities}
                                  <div
                                    style={{
                                      display: "inline",
                                      borderLeft: "1px solid gray",
                                      // marginInline: "0.8em",
                                      marginRight: "0.8em",
                                      marginLeft: "0.8em",
                                    }}
                                  ></div>
                                  {userInfo?.experience}&nbsp;
                                  {!userInfo?.experience
                                    ? ""
                                    : userInfo?.experience > 1
                                    ? "Years"
                                    : "Year"}
                                </h6>
                              </div>

                              <div
                                style={{
                                  // marginBlock: "1rem",
                                  marginTop: "1rem",
                                  marginBottom: "1rem",
                                }}
                                className="timeline-username-specialities"
                              >
                                <h6 className="speciality-title">
                                  {userInfo?.subSpeciality}
                                </h6>
                              </div>
                              <div
                                style={{
                                  //  marginBlock: "1rem",
                                  marginTop: "1rem",
                                  marginBottom: "1rem",
                                }}
                              >
                                {userInfo?.socialInfo?.map((res) => (
                                  <p>
                                    {res.about_me && res?.about_me !== ""
                                      ? res.about_me
                                      : !otherUser
                                      ? `One line USP "Introduce yourself! We all would like to know you better"`
                                      : ""}
                                  </p>
                                ))}
                              </div>
                              {!otherUser && (
                                <div
                                  style={{
                                    backgroundColor: "rgb(221 253 247)",
                                    color: "slategray",
                                    padding: "1em",
                                    marginLeft: "-25px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      marginLeft: "0.6em",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IoMegaphoneOutline size={25} />
                                    <span style={{ marginLeft: "1em" }}>
                                      {/* Let others know what you exactly need */}
                                      {userInfo?.socialInfo?.map((res) => (
                                        <p style={{ fontSize: "1.5rem" }}>
                                          {res.specific_ask &&
                                          res?.specific_ask !== ""
                                            ? res.specific_ask
                                            : !otherUser
                                            ? `Let others know what you exactly need"`
                                            : ""}
                                        </p>
                                      ))}
                                    </span>
                                  </div>
                                </div>
                              )}
                              <div
                                className="feed-fullview"
                                style={{ width: "40%", marginTop: "1em" }}
                              >
                                <div className="timeline-connection">
                                  <p
                                    className="connection-title"
                                    style={{
                                      borderBottom: "1px solid #2a8f80",
                                    }}
                                  >
                                    {/* <u>   */}
                                    {userInfo?.activity_count?.connects_cnt}
                                    <span className="following">
                                      {userInfo?.activity_count?.connects_cnt
                                        ? userInfo?.activity_count
                                            ?.connects_cnt > 1
                                          ? "Connects"
                                          : "Connect"
                                        : ""}
                                    </span>
                                    {/* </u> */}
                                  </p>
                                </div>
                                <div className="timeline-connection">
                                  <p>
                                    <span className="timeline-followers">
                                      {formatNumberToDisplay(
                                        userInfo?.activity_count
                                          ?.followers_count
                                      )}
                                    </span>
                                    <span className="following">Followers</span>
                                  </p>
                                </div>
                                <div className="timeline-connection">
                                  <p>
                                    <span className="timeline-followers">
                                      {formatNumberToDisplay(
                                        userInfo?.activity_count
                                          ?.following_count
                                      )}
                                    </span>
                                    <span className="following">Following</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={classes.root}
                        style={{ marginTop: "15px" }}
                      >
                        <TabContext
                          value={value}
                          indicatorColor={{ backgroundColor: "green" }}
                        >
                          <AppBar
                            position="static"
                            style={{ backgroundColor: "white", color: "black" }}
                          >
                            <TabList
                              onChange={handleChange}
                              aria-label="simple tabs example"
                              TabIndicatorProps={{
                                style: {
                                  backgroundColor: "#1e8a79",
                                  borderRadius: "5px",
                                  height: "5px",
                                  border: "none",
                                },
                              }}
                            >
                              <Tab
                                label="About"
                                value="1"
                                style={{
                                  fontSize: "1.5rem",
                                  color: value === "1" ? "#1e8a79" : "black",
                                  minWidth:
                                    windowDimensions.width < 600
                                      ? "100px"
                                      : "160px",
                                }}
                              />
                              <Tab
                                label="Posts"
                                style={{
                                  fontSize: "1.5rem",
                                  color: value === "2" ? "#1e8a79" : "black",
                                  minWidth:
                                    windowDimensions.width < 600
                                      ? "100px"
                                      : "160px",
                                }}
                                value="2"
                              />
                            </TabList>
                          </AppBar>
                          <TabPanel value="1">
                            {/* Start Profile About Section   */}
                            {/* <div>
                              {userInfo?.socialInfo?.map((res) => (
                                <p>
                                  {res.about_me ? (
                                    <Accordion
                                      style={{
                                        boxShadow: " 0px 0px 1px 0px",
                                        border: "1px solid #e8e8e8",
                                      }}
                                      expanded={
                                        res.about_me
                                          ? true
                                          : expanded === "about"
                                      }
                                      onChange={handleChange1("about")}
                                    >
                                      <AccordionSummary
                                        expandIcon={
                                          expanded === "about" ? (
                                            <img
                                              // src={closeIcons}
                                              style={{ width: "30px" }}
                                            />
                                          ) : (
                                            <img
                                              // src={closeIcons}
                                              style={{ width: "30px" }}
                                            />
                                          )
                                        }
                                        // expandIcon={<closeIcons />}
                                        aria-controls="pannel-content"
                                        id="pannel-header"
                                      >
                                        <Typography
                                          className={classes.heading}
                                          className="contact-title"
                                        >
                                          About
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Typography>
                                          <p className="aboutme-title">
                                            {res.about_me}
                                          </p>
                                        </Typography>
                                      </AccordionDetails>
                                    </Accordion>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              ))}
                            </div> */}
                            {/* End Profile About section   */}

                            {/* Start Profile Contact Details section   */}
                            <div className="profile-card-container">
                              <div className="profile-card-header">
                                Contact Details
                                {!otherUser && (
                                  <MdEdit
                                    style={iconBackgroundStyle}
                                    size={25}
                                    onClick={() => {
                                      if (windowDimensions.width <= 768)
                                        history.push({
                                          pathname: "/edit/contactDetails",
                                          state: {
                                            userInfo: userInfo,
                                          },
                                        });
                                      else openContactDetailsModal();
                                    }}
                                  />
                                )}
                              </div>
                              <div
                                style={
                                  windowDimensions.width < 728
                                    ? { display: "block" }
                                    : {
                                        display: "grid",
                                        gridTemplateColumns: "1fr 2fr",
                                      }
                                }
                              >
                                <div className="profile-card-entry">
                                  <img
                                    src={call}
                                    className="profile-card-entry-icon"
                                  />
                                  <div className="profile-card-entry-text">
                                    {!otherUser && getNumber(userInfo?.cnt_num)}
                                    {otherUser &&
                                    userInfo?.connect_status == 3 &&
                                    userInfo?.cnt_num_vis == "SHOW_ALL" ? (
                                      <span>
                                        {getNumber(userInfo?.cnt_num)}
                                      </span>
                                    ) : otherUser &&
                                      userInfo?.connect_status == 3 &&
                                      userInfo?.cnt_num_vis ==
                                        "HIDE_FROM_ALL" ? (
                                      <span>Not shared</span>
                                    ) : otherUser ? (
                                      <span>Not shared</span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {!otherUser && (
                                    <MdVerifiedUser
                                      style={{
                                        color: "rgb(30 138 121)",
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="profile-card-entry">
                                  <img
                                    src={mail}
                                    className="profile-card-entry-icon"
                                  />
                                  <div className="profile-card-entry-text">
                                    {!otherUser && userInfo?.cnt_email}
                                    {otherUser &&
                                    userInfo?.connect_status == 3 &&
                                    userInfo?.cnt_email_vis == "SHOW_ALL" ? (
                                      <span>{userInfo?.cnt_email}</span>
                                    ) : otherUser &&
                                      userInfo?.connect_status == 3 &&
                                      userInfo?.cnt_email_vis ==
                                        "HIDE_FROM_ALL" ? (
                                      <span>Not shared</span>
                                    ) : otherUser ? (
                                      <span>Not shared</span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {!otherUser ? (
                                    !userInfo?.verificationInfo
                                      ?.cnt_email_verified ? (
                                      <button
                                        style={{
                                          fontSize: "1.35rem",
                                          color: "rgb(30 138 121)",
                                          margin: "0 1em",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => verifyEmail()}
                                      >
                                        Verify email
                                      </button>
                                    ) : (
                                      <MdVerifiedUser
                                        style={{
                                          color: "rgb(30 138 121)",
                                        }}
                                      />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              {userInfo?.socialInfo?.map((profile_id, key) => (
                                <div
                                  key={key}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={arrow}
                                    className="profile-card-entry-icon"
                                  />
                                  <div
                                    className="profile-card-entry-text"
                                    style={{
                                      color: "blue",
                                    }}
                                  >
                                    {profile_id.profile_url}
                                  </div>
                                  {windowDimensions.width > 728 && (
                                    <button
                                      style={{
                                        fontSize: "1.35rem",
                                        backgroundColor: "#f4f4f4",
                                        margin: "0 1em",
                                        padding: "5px",
                                        borderRadius: "3px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        copyLink(profile_id.profile_url)
                                      }
                                    >
                                      <HiLink
                                        style={iconBackgroundStyle}
                                        size={20}
                                      />
                                      <span
                                        style={{
                                          marginLeft: "5px",
                                        }}
                                      >
                                        {" "}
                                        Copy link
                                      </span>
                                    </button>
                                  )}
                                </div>
                              ))}
                              {userInfo.socialInfo
                                ? userInfo.socialInfo[0].website_pg &&
                                  userInfo.socialInfo[0].website_pg !== ""
                                  ? userInfo?.socialInfo?.map(
                                      (profile_id, key) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            // marginBlock: "1em",
                                            marginTop: "1em",
                                            marginBottom: "1em",
                                          }}
                                        >
                                          <img
                                            src={arrow}
                                            className="profile-card-entry-icon"
                                          />
                                          <div
                                            className="profile-card-entry-text"
                                            style={{
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              window.open(
                                                `https://${profile_id.website_pg}`
                                              )
                                            }
                                          >
                                            {profile_id.website_pg}
                                          </div>
                                        </div>
                                      )
                                    )
                                  : ""
                                : ""}
                              {userInfo.socialInfo
                                ? userInfo.socialInfo[0].blog_pg &&
                                  userInfo.socialInfo[0].blog_pg !== ""
                                  ? userInfo?.socialInfo?.map(
                                      (profile_id, key) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            // marginBlock: "1em",
                                            marginTop: "1em",
                                            marginBottom: "1em",
                                          }}
                                        >
                                          <FaBlogger
                                            size={
                                              windowDimensions.width <= 768
                                                ? 20
                                                : 25
                                            }
                                            style={{ color: "grey" }}
                                          />
                                          <div
                                            className="profile-card-entry-text"
                                            style={{
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              window.open(
                                                `https://${profile_id.blog_pg}`
                                              )
                                            }
                                          >
                                            {profile_id.blog_pg}
                                          </div>
                                        </div>
                                      )
                                    )
                                  : ""
                                : ""}
                              {userInfo.socialInfo
                                ? userInfo.socialInfo[0].fb_pg &&
                                  userInfo.socialInfo[0].fb_pg !== ""
                                  ? userInfo?.socialInfo?.map(
                                      (profile_id, key) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            // marginBlock: "1em",
                                            marginTop: "1em",
                                            marginBottom: "1em",
                                          }}
                                        >
                                          <FacebookIcon
                                            style={{
                                              width:
                                                windowDimensions.width <= 768
                                                  ? "20px"
                                                  : "25px",
                                              height:
                                                windowDimensions.width <= 768
                                                  ? "20px"
                                                  : "25px",
                                              color: "grey",
                                            }}
                                          />
                                          <div
                                            className="profile-card-entry-text"
                                            style={{
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              window.open(
                                                `https://${profile_id.fb_pg}`
                                              )
                                            }
                                          >
                                            {profile_id.fb_pg}
                                          </div>
                                        </div>
                                      )
                                    )
                                  : ""
                                : ""}

                              {userInfo.socialInfo
                                ? userInfo.socialInfo[0].twitter_pg &&
                                  userInfo.socialInfo[0].twitter_pg !== ""
                                  ? userInfo?.socialInfo?.map(
                                      (profile_id, key) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            // marginBlock: "1em",
                                            marginTop: "1em",
                                            marginBottom: "1em",
                                          }}
                                        >
                                          <TwitterIcon
                                            style={{
                                              width:
                                                windowDimensions.width <= 768
                                                  ? "20px"
                                                  : "25px",
                                              height:
                                                windowDimensions.width <= 768
                                                  ? "20px"
                                                  : "25px",
                                              color: "grey",
                                            }}
                                          />
                                          <div
                                            className="profile-card-entry-text"
                                            style={{
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              window.open(
                                                `https://${profile_id.twitter_pg}`
                                              )
                                            }
                                          >
                                            {profile_id.twitter_pg}
                                          </div>
                                        </div>
                                      )
                                    )
                                  : ""
                                : ""}
                              {userInfo.socialInfo
                                ? userInfo.socialInfo[0].linkedin_pg &&
                                  userInfo.socialInfo[0].linkedin_pg !== ""
                                  ? userInfo?.socialInfo?.map(
                                      (profile_id, key) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            // marginBlock: "1em",
                                            marginTop: "1em",
                                            marginBottom: "1em",
                                          }}
                                        >
                                          <LinkedInIcon
                                            style={{
                                              width:
                                                windowDimensions.width <= 768
                                                  ? "20px"
                                                  : "25px",
                                              height:
                                                windowDimensions.width <= 768
                                                  ? "20px"
                                                  : "25px",
                                              color: "grey",
                                            }}
                                          />
                                          <div
                                            className="profile-card-entry-text"
                                            style={{
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              window.open(
                                                `https://${profile_id.linkedin_pg}`
                                              )
                                            }
                                          >
                                            {profile_id.linkedin_pg}
                                          </div>
                                        </div>
                                      )
                                    )
                                  : ""
                                : ""}
                              {userInfo.socialInfo
                                ? userInfo.socialInfo[0].instagram_pg &&
                                  userInfo.socialInfo[0].instagram_pg !== ""
                                  ? userInfo?.socialInfo?.map(
                                      (profile_id, key) => (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            // marginBlock: "1em",
                                            marginTop: "1em",
                                            marginBottom: "1em",
                                          }}
                                        >
                                          <InstagramIcon
                                            style={{
                                              width:
                                                windowDimensions.width <= 768
                                                  ? "20px"
                                                  : "25px",
                                              height:
                                                windowDimensions.width <= 768
                                                  ? "20px"
                                                  : "25px",
                                              color: "grey",
                                            }}
                                          />
                                          <div
                                            className="profile-card-entry-text"
                                            style={{
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              window.open(
                                                `https://${profile_id.instagram_pg}`
                                              )
                                            }
                                          >
                                            {profile_id.instagram_pg}
                                          </div>
                                        </div>
                                      )
                                    )
                                  : ""
                                : ""}
                            </div>

                            {/* <div className="conatctDetails">
                              {userInfo?.cnt_num ? (
                                <Accordion
                                  style={{
                                    boxShadow: " 0px 0px 1px 0px",
                                    border: "1px solid #e8e8e8",
                                  }}
                                  expanded={
                                    userInfo
                                      ? true
                                      : expanded === "conatctDetails"
                                  }
                                  onChange={handleChange1("conatctDetails")}
                                >
                                  <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        font: "normal normal normal 22px/20px Roboto",
                                        fontWeight: "400",

                                        color: "#4b4b4b",
                                      }}
                                    >
                                      Contact Details
                                      <MdEdit
                                        style={iconBackgroundStyle}
                                        size={25}
                                        onClick={() => {
                                          if (windowDimensions.width <= 768)
                                            history.push({
                                              pathname: "/edit/contactDetails",
                                              state: {
                                                background: location,
                                                userInfo: userInfo,
                                              },
                                            });
                                          else openContactDetailsModal();
                                        }}
                                      />
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Typography>
                                      <div className="contact-details d-none d-sm-block">
                                        <div className="container">
                                          <div className="row">
                                            <div
                                              className="col-12"
                                              style={{ marginBottom: "10px" }}
                                            >
                                              <div style={{ display: "flex" }}>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <img
                                                    src={call}
                                                    style={{
                                                      width: "25px",
                                                    }}
                                                  />
                                                  <p
                                                    style={{
                                                      margin: "0 1em",
                                                    }}
                                                    className="aboutme-title"
                                                  >
                                                    {!otherUser &&
                                                      userInfo?.cnt_num}
                                                    {otherUser &&
                                                    userInfo?.connect_status ==
                                                      3 &&
                                                    userInfo?.cnt_num_vis ==
                                                      "SHOW_ALL" ? (
                                                      <span>
                                                        {userInfo?.cnt_num}
                                                      </span>
                                                    ) : otherUser &&
                                                      userInfo?.connect_status ==
                                                        3 &&
                                                      userInfo?.cnt_num_vis ==
                                                        "HIDE_FROM_ALL" ? (
                                                      <span>Not shared</span>
                                                    ) : otherUser ? (
                                                      <span>Not shared</span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </p>
                                                  {!otherUser && (
                                                    <MdVerifiedUser
                                                      style={{
                                                        color:
                                                          "rgb(30 138 121)",
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    marginLeft: "50px",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <img
                                                    src={mail}
                                                    style={{
                                                      width: "25px",
                                                    }}
                                                  />

                                                  <p
                                                    className="aboutme-title"
                                                    style={{
                                                      margin: "0 1em",
                                                    }}
                                                  >
                                                    {!otherUser &&
                                                      userInfo?.cnt_email}
                                                    {otherUser &&
                                                    userInfo?.connect_status ==
                                                      3 &&
                                                    userInfo?.cnt_email_vis ==
                                                      "SHOW_ALL" ? (
                                                      <span>
                                                        {userInfo?.cnt_email}
                                                      </span>
                                                    ) : otherUser &&
                                                      userInfo?.connect_status ==
                                                        3 &&
                                                      userInfo?.cnt_email_vis ==
                                                        "HIDE_FROM_ALL" ? (
                                                      <span>Not shared</span>
                                                    ) : otherUser ? (
                                                      <span>Not shared</span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </p>
                                                  {!otherUser &&
                                                    !userInfo?.verificationInfo
                                                      ?.cnt_email_verified && (
                                                      <button
                                                        style={{
                                                          fontSize: "1.35rem",
                                                          color:
                                                            "rgb(30 138 121)",
                                                          margin: "0 1em",
                                                        }}
                                                        onClick={() =>
                                                          verifyEmail()
                                                        }
                                                      >
                                                        Verify email
                                                      </button>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <br></br>
                                      </div>

                                      <div className="contact-details d-block d-lg-none">
                                        <div className="container">
                                          <div className="row">
                                            <div
                                              className="col-sm-12"
                                              style={{ marginBottom: "10px" }}
                                            >
                                              <span>
                                                <img
                                                  src={call}
                                                  style={{ width: "20px" }}
                                                />

                                                <span
                                                  style={{
                                                    wordBreak: "break-all",
                                                    fontSize: "1.4rem",
                                                    fontFamily: "Roboto",
                                                    marginLeft: "1em",
                                                  }}
                                                >
                                                  {!otherUser &&
                                                    userInfo?.cnt_num}
                                                  {otherUser &&
                                                  userInfo?.connect_status ==
                                                    3 &&
                                                  userInfo?.cnt_num_vis ==
                                                    "SHOW_ALL"
                                                    ? userInfo?.cnt_num
                                                    : otherUser &&
                                                      userInfo?.connect_status ==
                                                        3 &&
                                                      userInfo?.cnt_num_vis ==
                                                        "HIDE_FROM_ALL"
                                                    ? " Not shared"
                                                    : otherUser
                                                    ? " Not shared"
                                                    : ""}
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <br></br>
                                      </div>
                                      <div className="contact-details d-block d-lg-none">
                                        <div className="container">
                                          <div className="row">
                                            <div className="col-sm-12">
                                              <span
                                                style={{
                                                  display: "flex",
                                                  wordBreak: "break-word",
                                                }}
                                              >
                                                <img
                                                  src={mail}
                                                  style={{ width: "20px" }}
                                                />
                                                <span
                                                  style={{
                                                    wordBreak: "break-all",
                                                    fontSize: "1.4rem",
                                                    fontFamily: "Roboto",
                                                    marginLeft: "1em",
                                                  }}
                                                >
                                                  {!otherUser &&
                                                    userInfo?.cnt_email}
                                                  {otherUser &&
                                                  userInfo?.connect_status ==
                                                    3 &&
                                                  userInfo?.cnt_email_vis ==
                                                    "SHOW_ALL"
                                                    ? userInfo?.cnt_email
                                                    : otherUser &&
                                                      userInfo?.connect_status ==
                                                        3 &&
                                                      userInfo?.cnt_email_vis ==
                                                        "HIDE_FROM_ALL"
                                                    ? "Not shared"
                                                    : otherUser
                                                    ? "Not shared"
                                                    : ""}
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <br></br>
                                      </div>

                                      <div className="container">
                                        <div className="row">
                                          <div className="col-sm-12">
                                            {userInfo?.socialInfo?.map(
                                              (profile_id, key) => (
                                                <p
                                                  key={key}
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <img
                                                    src={arrow}
                                                    style={{ width: "25px" }}
                                                  />
                                                  <p
                                                    className="aboutme-title"
                                                    style={{
                                                      marginLeft: "10px",
                                                      wordBreak: "break-all",
                                                      margin: "0 1em",
                                                      color: "blue",
                                                    }}
                                                  >
                                                    {profile_id.profile_url}
                                                  </p>
                                                  <button
                                                    style={{
                                                      fontSize: "1.35rem",
                                                      backgroundColor:
                                                        "#f4f4f4",
                                                      margin: "0 1em",
                                                      padding: "5px",
                                                      borderRadius: "3px",
                                                    }}
                                                    onClick={() =>
                                                      copyLink(
                                                        profile_id.profile_url
                                                      )
                                                    }
                                                  >
                                                    <HiLink
                                                      style={
                                                        iconBackgroundStyle
                                                      }
                                                      size={20}
                                                    />
                                                    <span
                                                      style={{
                                                        marginLeft: "5px",
                                                      }}
                                                    >
                                                      {" "}
                                                      Copy link
                                                    </span>
                                                  </button>
                                                </p>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </Typography>
                                  </AccordionDetails>
                                </Accordion>
                              ) : (
                                ""
                              )}
                            </div> */}
                            {/* End Profile Contact Details Section   */}

                            {/* Start Profile Area of Interest Section   */}
                            {!otherUser ||
                            !(
                              !userInfo.areas_of_interest ||
                              userInfo?.areas_of_interest?.length === 0
                            ) ? (
                              <div className="profile-card-container">
                                <div className="profile-card-header">
                                  Area of Interest
                                  {!otherUser && (
                                    <img
                                      src={closeIcons}
                                      style={{
                                        width:
                                          windowDimensions.width < 728
                                            ? "25px"
                                            : "30px",
                                        height:
                                          windowDimensions.width < 728
                                            ? "25px"
                                            : "30px",
                                        cursor: "pointers",
                                      }}
                                      onClick={() => {
                                        if (windowDimensions.width < 768)
                                          history.push("/interests");
                                        else openAoiModal();
                                      }}
                                    />
                                  )}
                                </div>

                                {userInfo?.areas_of_interest?.map((res) => (
                                  <div
                                    style={{
                                      display: "inline-block",
                                      marginRight: "1em",
                                      marginTop: "1em",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        backgroundColor: "rgb(221, 253, 247)",
                                        border: "1px solid rgb(30, 138, 121)",
                                        borderRadius: "30px",
                                        padding: "0.3em  0.8em",
                                        alignItems: "center",
                                        justifyContent: otherUser
                                          ? "center"
                                          : "space-between",
                                        minWidth: otherUser ? "5em" : "7em",
                                      }}
                                      onClick={editInterest(res.interest_id)}
                                    >
                                      <span
                                        style={{
                                          fontSize: "1.4rem",
                                          marginRight: !otherUser && "1.5em",
                                        }}
                                      >
                                        {res.area_of_interest}
                                      </span>
                                      {!otherUser && (
                                        <IoCloseCircleOutline
                                          style={{ cursor: "pointer" }}
                                          size={23}
                                          onClick={(e) => {
                                            if (otherUser) return;
                                            e.stopPropagation();
                                            if (!otherUser)
                                              // deleteInterests(res.interest_id);
                                              openConfirmDelete(
                                                res.interest_id,
                                                deleteInterests
                                              );
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                ))}

                                {(!userInfo.areas_of_interest ||
                                  userInfo?.areas_of_interest?.length ===
                                    0) && (
                                  <div
                                    style={{
                                      fontSize: "1.5rem",
                                      padding: "0px",
                                      marginTop: "1em",
                                    }}
                                  >
                                    Please specify your area of interest clearly
                                    for the audience to know you better
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            {/* {!otherUser ||
                            !(
                              !userInfo.areas_of_interest ||
                              userInfo?.areas_of_interest?.length === 0
                            ) ? (
                              <div className="conatctDetails">
                                <p>
                                  <Accordion
                                    style={{
                                      boxShadow: " 0px 0px 1px 0px",
                                      border: "1px solid #e8e8e8",
                                    }}
                                    expanded={
                                      userInfo?.areas_of_interest
                                        ? true
                                        : expanded === "panel-Interest"
                                    }
                                    onChange={handleChange1("panel-Interest")}
                                  >
                                    <AccordionSummary
                                      expandIcon={
                                        expanded === "panel-Interest" ? (
                                          <img
                                            // src={closeIcons}
                                            style={{ width: "30px" }}
                                          />
                                        ) : (
                                          <img
                                            // src={closeIcons}
                                            style={{ width: "30px" }}
                                          />
                                        )
                                      }
                                      // expandIcon={<AddIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography
                                        className={classes.heading}
                                        className="contact-title"
                                      >
                                        Area of Interest
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Typography>
                                        {userInfo?.areas_of_interest?.map(
                                          (res) => (
                                            <p
                                              className="aboutme-title"
                                              style={{
                                                padding: "0px",
                                                marginLeft: "15px",
                                              }}
                                            >
                                              {res.area_of_interest}
                                            </p>
                                          )
                                        )}
                                        {(!userInfo.areas_of_interest ||
                                          userInfo?.areas_of_interest
                                            ?.length === 0) && (
                                          <span
                                            style={{
                                              fontSize: "1.5rem",
                                              padding: "0px",
                                              marginLeft: "15px",
                                            }}
                                          >
                                            Please specify your area of interest
                                            clearly for the audience to know you
                                            better
                                          </span>
                                        )}
                                      </Typography>
                                    </AccordionDetails>
                                  </Accordion>
                                </p>
                              </div>
                            ) : (
                              ""
                            )} */}
                            {/* End Profile Area of Interest Section   */}

                            {/* Start Profile  Available At Section   */}

                            {!otherUser ||
                            !(
                              !userInfo.prof_history ||
                              userInfo?.prof_history?.filter(
                                (exp) => exp.working_here
                              ).length === 0
                            ) ? (
                              <div
                                className="profile-card-container"
                                style={{
                                  position: "relative",
                                  marginBottom: "1.8em",
                                }}
                              >
                                <div className="profile-card-header">
                                  Available At
                                  {!otherUser && (
                                    <img
                                      src={closeIcons}
                                      style={{
                                        width:
                                          windowDimensions.width < 728
                                            ? "25px"
                                            : "30px",
                                        height:
                                          windowDimensions.width < 728
                                            ? "25px"
                                            : "30px",
                                        cursor: "pointer",
                                      }}
                                      onClick={openAvailableAtDetailsModal}
                                    />
                                  )}
                                </div>
                                {sortExperiences(
                                  userInfo?.prof_history?.filter(
                                    (exp) => exp.working_here
                                  )
                                )
                                  .slice(0, noOfAvailableAt)
                                  .map((exp, key) => {
                                    if (exp.working_here)
                                      return (
                                        <>
                                          <div
                                            style={{
                                              // padding: "1em",
                                              display: "flex",
                                              marginTop: "1.5em",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width:
                                                  windowDimensions.width < 728
                                                    ? "100%"
                                                    : "95%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  // color: "#469b8d",

                                                  display: "flex",
                                                  alignItems: "stretch",
                                                }}
                                                onClick={() => {
                                                  if (
                                                    windowDimensions.width <
                                                      728 &&
                                                    !otherUser
                                                  )
                                                    openAvailableAtDetailsModal();
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    overflowWrap: "break-word",
                                                    fontWeight: "bold",
                                                    fontSize:
                                                      windowDimensions.width <
                                                      728
                                                        ? "1.7rem"
                                                        : "1.8rem",
                                                    maxWidth: "80%",
                                                  }}
                                                >
                                                  {exp.workplace}
                                                </div>
                                                {exp.show_on_card && (
                                                  <FaAddressCard
                                                    size={25}
                                                    style={{
                                                      marginLeft: "1em",
                                                    }}
                                                  />
                                                )}
                                              </div>

                                              {exp.location &&
                                                exp?.location !== "" && (
                                                  <div
                                                    style={{
                                                      fontSize: "1.5rem",
                                                      color:
                                                        windowDimensions.width <
                                                        728
                                                          ? "gray"
                                                          : "black",
                                                      marginTop: "0.5em",
                                                      overflowWrap:
                                                        "break-word",
                                                    }}
                                                  >
                                                    {exp.location}
                                                  </div>
                                                )}

                                              {exp.designation &&
                                                exp?.designation !== "" && (
                                                  <div
                                                    style={{
                                                      fontSize: "1.5rem",
                                                      color:
                                                        windowDimensions.width <
                                                        728
                                                          ? "gray"
                                                          : "black",
                                                      marginTop: "0.5em",
                                                      overflowWrap:
                                                        "break-word",
                                                    }}
                                                  >
                                                    {exp.designation}
                                                  </div>
                                                )}
                                              <div
                                                style={{
                                                  fontWeight: "bold",
                                                  fontStyle: "italic",
                                                  fontSize:
                                                    windowDimensions.width < 728
                                                      ? "1.3rem"
                                                      : "1.5rem",
                                                  fontWeight: "bold",
                                                  overflowWrap: "break-word",
                                                  marginTop: "0.5em",
                                                }}
                                              >
                                                {`${
                                                  exp.available_days.department
                                                    ? exp.available_days
                                                        .department + ", "
                                                    : ""
                                                } ${
                                                  exp.available_days
                                                    .week_of_days.length !== 0
                                                    ? renderDays(
                                                        exp.available_days
                                                          .week_of_days
                                                      ) + ", "
                                                    : ""
                                                } ${
                                                  exp.available_days
                                                    .from_time <= -19800001
                                                    ? ""
                                                    : new Date(
                                                        exp.available_days.from_time
                                                      ).toLocaleTimeString()
                                                } ${
                                                  exp.available_days
                                                    .from_time <= -19800001
                                                    ? ""
                                                    : "-"
                                                } ${
                                                  exp.available_days.to_time <=
                                                  -19800001
                                                    ? ""
                                                    : new Date(
                                                        exp.available_days.to_time
                                                      ).toLocaleTimeString()
                                                } `}
                                              </div>
                                            </div>
                                            {windowDimensions.width > 728 &&
                                              !otherUser && (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "26%",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                    onClick={(e) => {
                                                      editExperience(
                                                        exp.prof_id
                                                      );
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: "black",
                                                        fontSize: "1.3rem",

                                                        marginRight: "0.3em",
                                                      }}
                                                    >
                                                      Edit
                                                    </span>
                                                    <MdEdit
                                                      style={
                                                        iconBackgroundStyle
                                                      }
                                                      size={20}
                                                    />
                                                  </div>

                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      padding:"10px"
                                                    }}
                                                    onClick={
                                                      (e) => {
                                                        if (
                                                          checkShowOnCard(
                                                            exp.show_on_card
                                                          )
                                                        )
                                                          return;
                                                        openConfirmDelete(
                                                          exp.prof_id,
                                                          deleteExperience
                                                        );
                                                      }
                                                      // deleteExperience(
                                                      //   exp.prof_id
                                                      // )
                                                    }
                                                  >
                                                    <span
                                                      style={{
                                                        color: "black",
                                                        fontSize: "1.3rem",

                                                        marginRight: "0.3em",
                                                      }}
                                                    >
                                                      Remove
                                                    </span>
                                                    <MdDeleteForever
                                                      style={{
                                                        ...iconBackgroundStyle,
                                                        backgroundColor: "red",
                                                      }}
                                                      size={20}
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                          </div>

                                          {windowDimensions.width < 728 && (
                                            <hr />
                                          )}
                                        </>
                                      );
                                  })}
                                {userInfo?.prof_history?.filter(
                                  (exp) => exp.working_here
                                ).length > 2 &&
                                  windowDimensions.width < 728 && (
                                    <div
                                      style={{
                                        border: "1px solid rgb(30 138 121) ",
                                        color: "rgb(30 138 121)",
                                        backgroundColor: "#ddfdf7",
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: "bold",
                                        fontSize: "1.3rem",
                                        padding: "0.5em 0 ",
                                      }}
                                      onClick={() => {
                                        if (otherUser) {
                                          if (noOfAvailableAt <= 2)
                                            setNoOfAvailableAt(
                                              !userInfo.prof_history ||
                                                userInfo?.prof_history?.filter(
                                                  (exp) => exp.working_here
                                                ).length
                                            );
                                          else setNoOfAvailableAt(2);
                                        } else openAvailableAtDetailsModal();
                                      }}
                                    >
                                      {noOfAvailableAt <= 2
                                        ? "View More"
                                        : "View Less"}
                                    </div>
                                  )}
                                {(!userInfo.prof_history ||
                                  userInfo?.prof_history?.filter(
                                    (exp) => exp.working_here
                                  ).length > 2) &&
                                  windowDimensions.width > 728 && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        bottom: "-14px",
                                        left: "50%",
                                        transform: "translate(-50%)",
                                      }}
                                    >
                                      {noOfAvailableAt <= 2 ? (
                                        <IoMdArrowRoundDown
                                          style={{
                                            ...iconBackgroundStyle,
                                            backgroundColor: "lightgray",
                                            color: "rgb(30 138 121)",
                                          }}
                                          size={28}
                                          onClick={() =>
                                            setNoOfAvailableAt(
                                              !userInfo.prof_history ||
                                                userInfo?.prof_history?.filter(
                                                  (exp) => exp.working_here
                                                ).length
                                            )
                                          }
                                        />
                                      ) : (
                                        <IoMdArrowRoundUp
                                          style={{
                                            ...iconBackgroundStyle,
                                            backgroundColor: "lightgray",
                                            color: "rgb(30 138 121)",
                                          }}
                                          size={28}
                                          onClick={() => setNoOfAvailableAt(2)}
                                        />
                                      )}
                                    </div>
                                  )}

                                {(!userInfo.prof_history ||
                                  userInfo?.prof_history?.filter(
                                    (exp) => exp.working_here
                                  ).length === 0) && (
                                  <span
                                    style={{
                                      fontSize: "1.5rem",
                                      padding: "0px",
                                    }}
                                  >
                                    Keep others informed about your availablity
                                  </span>
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            {/* End Profile  Available At Section   */}

                            {/* Start Profile  Awards and Memberships Section   */}

                            {/* Start Publications Section   */}
                            {windowDimensions.width <= 768 ? (
                              <div className="conatctDetails">
                                <p>
                                  {!otherUser ||
                                  !(
                                    !userInfo.membership_history ||
                                    userInfo?.membership_history?.length === 0
                                  ) ? (
                                    <Accordion
                                      style={{
                                        boxShadow: " 0px 0px 1px 0px",
                                        border: "1px solid #e8e8e8",
                                        padding: "0px",
                                      }}
                                      expanded={true}
                                      onChange={handleChange1("publications")}
                                    >
                                      <AccordionSummary
                                        expandIcon={
                                          !userInfo.membership_history ||
                                          userInfo?.membership_history
                                            ?.length === 0
                                            ? !otherUser && (
                                                <img
                                                  src={closeIcons}
                                                  style={{ width: "25px" }}
                                                  onClick={openMembershipModal}
                                                />
                                              )
                                            : !otherUser && (
                                                <img
                                                  src={closeIcons}
                                                  style={{ width: "25px" }}
                                                  onClick={openMembershipModal}
                                                />
                                              )
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <Typography
                                          style={{
                                            font: "normal normal normal 18px/18px Roboto",
                                          }}
                                        >
                                          Membership
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Typography>
                                          {userInfo.membership_history
                                            ? userInfo.membership_history
                                                .slice(0, 2)
                                                .map((pub, key) => (
                                                  <div key={key}>
                                                    <div className="line"></div>
                                                    <div
                                                      style={{
                                                        padding: "0px",
                                                        marginLeft: "17px",
                                                      }}
                                                    >
                                                      <p className="print-head">
                                                        <img
                                                          src={ic_membership}
                                                          className="print-img"
                                                          style={{
                                                            width: "35px",
                                                          }}
                                                        ></img>{" "}
                                                        <span
                                                          style={{
                                                            color: "black",
                                                            font: "normal normal bold 14px/14px Roboto",
                                                          }}
                                                        >
                                                          {" "}
                                                          {pub.title}
                                                        </span>
                                                      </p>

                                                      <hr
                                                        style={{
                                                          marginTop: "15px",
                                                          marginBottom: "15px",
                                                        }}
                                                      ></hr>
                                                    </div>
                                                  </div>
                                                ))
                                            : ""}

                                          {userInfo.membership_history &&
                                            userInfo?.membership_history
                                              ?.length > 2 && (
                                              <div
                                                style={{
                                                  border:
                                                    "1px solid rgb(30 138 121) ",
                                                  color: "rgb(30 138 121)",
                                                  backgroundColor: "#ddfdf7",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  fontWeight: "bold",
                                                  fontSize: "1.3rem",
                                                  padding: "4px 4px ",
                                                  cursor: "pointer",
                                                  margin: "15px 15px",
                                                }}
                                                onClick={openMembershipModal}
                                              >
                                                View More
                                              </div>
                                            )}
                                          {(!userInfo.membership_history ||
                                            userInfo?.membership_history
                                              ?.length === 0) && (
                                            <div
                                              style={{
                                                fontSize: "1.5rem",
                                                padding: "0px",
                                                marginLeft: "15px",
                                                marginTop: "-5px",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              Show case your professional
                                              memberships
                                            </div>
                                          )}
                                        </Typography>
                                      </AccordionDetails>
                                    </Accordion>
                                  ) : (
                                    ""
                                  )}
                                </p>
                                <p>
                                  {!otherUser ||
                                  !(
                                    !userInfo.award_history ||
                                    userInfo?.award_history?.length === 0
                                  ) ? (
                                    <Accordion
                                      style={{
                                        boxShadow: " 0px 0px 1px 0px",
                                        border: "1px solid #e8e8e8",
                                        padding: "0px",
                                      }}
                                      expanded={true}
                                      onChange={handleChange1("publications")}
                                    >
                                      <AccordionSummary
                                        expandIcon={
                                          !userInfo.award_history ||
                                          userInfo?.award_history?.length === 0
                                            ? !otherUser && (
                                                <img
                                                  src={closeIcons}
                                                  style={{ width: "25px" }}
                                                  onClick={openAwardsModal}
                                                />
                                              )
                                            : !otherUser && (
                                                <img
                                                  src={closeIcons}
                                                  style={{ width: "25px" }}
                                                  onClick={openAwardsModal}
                                                />
                                              )
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <Typography
                                          style={{
                                            font: "normal normal normal 18px/18px Roboto",
                                          }}
                                        >
                                          Awards
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Typography>
                                          {userInfo.award_history
                                            ? userInfo.award_history
                                                .slice(0, 2)
                                                .map((pub, key) => (
                                                  <div key={key}>
                                                    <div className="line"></div>
                                                    <div
                                                      style={{
                                                        padding: "0px",
                                                        marginLeft: "17px",
                                                      }}
                                                    >
                                                      <p className="print-head">
                                                        <img
                                                          src={ic_award}
                                                          className="print-img"
                                                          style={{
                                                            width: "25px",
                                                          }}
                                                        ></img>{" "}
                                                        {/* <span
                                                        style={{
                                                          color:
                                                            "rgb(30, 138, 121)",
                                                          font:
                                                            "normal normal bold 16px/16px Roboto",
                                                        }}> {pub.title}</span>  */}
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(30, 138, 121)",
                                                            font: "normal normal 14px/14px Roboto",
                                                            display: "block",
                                                            marginLeft: "45px",
                                                            marginTop: "-30px",
                                                          }}
                                                        >
                                                          {" "}
                                                          {pub.title}
                                                        </span>
                                                      </p>
                                                      <div
                                                        className="author-title"
                                                        style={{
                                                          marginLeft: "45px",
                                                          marginTop: "-10px",
                                                          marginBottom: "10px",
                                                        }}
                                                      >
                                                        {pub.presented_at}
                                                      </div>
                                                      <div
                                                        style={{
                                                          color: "black",
                                                          font: "normal normal bold 16px/16px Roboto",
                                                          marginLeft: "45px",
                                                        }}
                                                      >
                                                        {pub.year}
                                                      </div>
                                                      <hr
                                                        style={{
                                                          marginTop: "15px",
                                                          marginBottom: "15px",
                                                        }}
                                                      ></hr>
                                                    </div>
                                                  </div>
                                                ))
                                            : ""}

                                          {userInfo.award_history &&
                                            userInfo?.award_history?.length >
                                              2 && (
                                              <div
                                                style={{
                                                  border:
                                                    "1px solid rgb(30 138 121) ",
                                                  color: "rgb(30 138 121)",
                                                  backgroundColor: "#ddfdf7",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  fontWeight: "bold",
                                                  fontSize: "1.3rem",
                                                  padding: "4px 4px ",
                                                  cursor: "pointer",
                                                  margin: "15px 15px",
                                                }}
                                                onClick={openAwardsModal}
                                              >
                                                View More
                                              </div>
                                            )}
                                          {(!userInfo.award_history ||
                                            userInfo?.award_history?.length ===
                                              0) && (
                                            <div
                                              style={{
                                                fontSize: "1.5rem",
                                                padding: "0px",
                                                marginLeft: "15px",
                                                marginTop: "-5px",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              Add your awards to show your{" "}
                                              <br></br>accomplishments
                                            </div>
                                          )}
                                        </Typography>
                                      </AccordionDetails>
                                    </Accordion>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                            ) : (
                              <div className="conatctDetails">
                                <p>
                                  {!otherUser ||
                                  !(
                                    (!userInfo.membership_history ||
                                      userInfo?.membership_history?.length ===
                                        0) &&
                                    (!userInfo.award_history ||
                                      userInfo?.award_history?.length === 0)
                                  ) ? (
                                    <Accordion
                                      style={{
                                        boxShadow: " 0px 0px 1px 0px",
                                        border: "1px solid #e8e8e8",
                                        padding: "0px",
                                      }}
                                      expanded={true}
                                      onChange={handleChange1("awards")}
                                    >
                                      <AccordionSummary
                                        expandIcon={
                                          !otherUser && (
                                            <img
                                              src={closeIcons}
                                              style={{ width: "30px" }}
                                              onClick={
                                                openAwardsMembershipModal
                                              }
                                            />
                                          )
                                        }
                                        // expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        <Typography className={classes.heading}>
                                          Awards and Memberships
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <div>
                                          {userInfo.award_history
                                            ? userInfo.award_history
                                                .slice(0, noOfAwards)
                                                .map((award, key) => (
                                                  <div key={key}>
                                                    <div className="line"></div>
                                                    <div
                                                      style={{
                                                        padding: "0px",
                                                        marginLeft: "17px",
                                                      }}
                                                    >
                                                      <div className="row">
                                                        <div
                                                          className={
                                                            windowDimensions.width <=
                                                            1200
                                                              ? "col-md-8"
                                                              : "col-9"
                                                          }
                                                        >
                                                          <p className="print-head">
                                                            <img
                                                              src={ic_award}
                                                              className="print-img"
                                                              style={{
                                                                width: "15px",
                                                              }}
                                                            ></img>{" "}
                                                            <span
                                                              style={{
                                                                color: "black",
                                                                font: "normal normal 14px/14px Roboto",
                                                                display:
                                                                  "block",
                                                                marginLeft:
                                                                  "30px",
                                                                marginTop:
                                                                  "-20px",
                                                              }}
                                                            >
                                                              {" "}
                                                              Award:{" "}
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "black",
                                                                  font: "normal normal bold 14px/14px Roboto",
                                                                }}
                                                              >
                                                                {" "}
                                                                {award.title}
                                                              </span>{" "}
                                                            </span>
                                                          </p>
                                                        </div>

                                                        {!otherUser && (
                                                          <div
                                                            className={
                                                              windowDimensions.width <=
                                                              1200
                                                                ? "col-md-2"
                                                                : "col-2"
                                                            }
                                                            style={{
                                                              maxWidth:
                                                                windowDimensions.width <=
                                                                1200
                                                                  ? "11%"
                                                                  : "10%",
                                                              marginLeft:
                                                                windowDimensions.width <=
                                                                1200
                                                                  ? "42px"
                                                                  : "",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={(e) => {
                                                              editAwardsMembership(
                                                                e,
                                                                award
                                                              );
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "13px",
                                                                color: "black",
                                                              }}
                                                            >
                                                              {" "}
                                                              Edit{" "}
                                                            </span>
                                                            <MdEdit
                                                              style={
                                                                iconBackgroundStyle
                                                              }
                                                              size={20}
                                                            />
                                                          </div>
                                                        )}
                                                        {!otherUser && (
                                                          <div
                                                            className={
                                                              windowDimensions.width <=
                                                              1200
                                                                ? "col-md-2"
                                                                : "col-2"
                                                            }
                                                            style={{
                                                              maxWidth:
                                                                windowDimensions.width <=
                                                                1200
                                                                  ? ""
                                                                  : "12%",
                                                              marginLeft:
                                                                windowDimensions.width <=
                                                                1200
                                                                  ? ""
                                                                  : "18px",
                                                              cursor: "pointer",
                                                            }}
                                                            // className="  col-md-2 col-xl-2"
                                                            // style={{
                                                            //   maxWidth: "12%",marginLeft: "18px",
                                                            // }}
                                                            onClick={(e) =>
                                                              deleteAwardsMembership(
                                                                e,
                                                                award
                                                              )
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "13px",
                                                                color: "black",
                                                              }}
                                                            >
                                                              {" "}
                                                              Remove{" "}
                                                            </span>

                                                            <MdDeleteForever
                                                              style={{
                                                                ...iconBackgroundStyle,
                                                                backgroundColor:
                                                                  "red",
                                                              }}
                                                              size={20}
                                                            />
                                                          </div>
                                                        )}
                                                      </div>

                                                      <div
                                                        className="row"
                                                        style={{
                                                          marginLeft: "15px",
                                                        }}
                                                      >
                                                        <div className="col-11">
                                                          <p className="print-head">
                                                            {award.presented_at !=
                                                              "" && (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "black",
                                                                  font: "normal normal 14px/14px Roboto",
                                                                }}
                                                              >
                                                                {" "}
                                                                Presented At:
                                                              </span>
                                                            )}
                                                            {award.presented_at !=
                                                              "" && (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "black",
                                                                  font: "normal normal bold 14px/14px Roboto",
                                                                }}
                                                              >
                                                                {" "}
                                                                {
                                                                  award.presented_at
                                                                }{" "}
                                                              </span>
                                                            )}

                                                            {award.year !=
                                                              undefined && (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "black",
                                                                  font: "normal normal 14px/14px Roboto",
                                                                }}
                                                              >
                                                                | Year:
                                                              </span>
                                                            )}
                                                            {award.year !=
                                                              undefined && (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "black",
                                                                  font: "normal normal bold 14px/14px Roboto",
                                                                }}
                                                              >
                                                                {" "}
                                                                {award.year}
                                                              </span>
                                                            )}
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <hr
                                                        style={{
                                                          marginTop: "15px",
                                                          marginBottom: "15px",
                                                        }}
                                                      ></hr>
                                                    </div>
                                                  </div>
                                                ))
                                            : " "}
                                          {userInfo.membership_history ? (
                                            userInfo.membership_history
                                              .slice(0, noOfMems)
                                              .map((membership, key) => (
                                                <div key={key}>
                                                  <div className="line"></div>
                                                  <div
                                                    style={{
                                                      padding: "0px",
                                                      marginLeft: "17px",
                                                    }}
                                                  >
                                                    <div className="row">
                                                      <div
                                                        className={
                                                          windowDimensions.width <=
                                                          1200
                                                            ? "col-md-8"
                                                            : "col-9"
                                                        }
                                                      >
                                                        <p className="print-head">
                                                          <img
                                                            src={ic_membership}
                                                            style={{
                                                              width: "25px",
                                                            }}
                                                          ></img>{" "}
                                                          <span
                                                            style={{
                                                              color: "black",
                                                              font: "normal normal 14px/14px Roboto",
                                                            }}
                                                          >
                                                            {" "}
                                                            {membership.title}
                                                          </span>
                                                        </p>
                                                      </div>
                                                      {!otherUser && (
                                                        <div
                                                          className={
                                                            windowDimensions.width <=
                                                            1200
                                                              ? "col-md-2"
                                                              : "col-2"
                                                          }
                                                          style={{
                                                            maxWidth:
                                                              windowDimensions.width <=
                                                              1200
                                                                ? "11%"
                                                                : "10%",
                                                            marginLeft:
                                                              windowDimensions.width <=
                                                              1200
                                                                ? "42px"
                                                                : "",
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={(e) => {
                                                            editAwardsMembership(
                                                              e,
                                                              membership
                                                            );
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "13px",
                                                              color: "black",
                                                            }}
                                                          >
                                                            {" "}
                                                            Edit{" "}
                                                          </span>
                                                          <MdEdit
                                                            style={
                                                              iconBackgroundStyle
                                                            }
                                                            size={20}
                                                          />
                                                        </div>
                                                      )}

                                                      {!otherUser && (
                                                        <div
                                                          className={
                                                            windowDimensions.width <=
                                                            1200
                                                              ? "col-md-2"
                                                              : "col-2"
                                                          }
                                                          style={{
                                                            maxWidth:
                                                              windowDimensions.width <=
                                                              1200
                                                                ? ""
                                                                : "12%",
                                                            marginLeft:
                                                              windowDimensions.width <=
                                                              1200
                                                                ? ""
                                                                : "18px",
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={(e) =>
                                                            deleteAwardsMembership(
                                                              e,
                                                              membership
                                                            )
                                                          }
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "13px",
                                                              color: "black",
                                                            }}
                                                          >
                                                            {" "}
                                                            Remove{" "}
                                                          </span>
                                                          <MdDeleteForever
                                                            style={{
                                                              ...iconBackgroundStyle,
                                                              backgroundColor:
                                                                "red",
                                                            }}
                                                            size={20}
                                                          />
                                                          {/* <img
                                                            style={{
                                                              width: "20px",
                                                              height: "20px",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={(e) =>
                                                              deleteAwardsMembership(
                                                                e,
                                                                membership
                                                              )
                                                            }
                                                            src={deleteIcon}
                                                          /> */}
                                                        </div>
                                                      )}
                                                    </div>

                                                    <hr
                                                      style={{
                                                        marginTop: "15px",
                                                        marginBottom: "15px",
                                                      }}
                                                    ></hr>
                                                  </div>
                                                </div>
                                              ))
                                          ) : (
                                            <>
                                              <div className="line"></div>
                                            </>
                                          )}

                                          {(userInfo?.award_history?.length >
                                            2 ||
                                            userInfo?.membership_history
                                              ?.length > 2) &&
                                            windowDimensions.width > 728 && (
                                              <div
                                                style={{
                                                  position: "absolute",
                                                  bottom: "-14px",
                                                  left: "50%",
                                                  transform: "translate(-50%)",
                                                }}
                                              >
                                                {noOfAwards <= 2 &&
                                                noOfMems <= 2 ? (
                                                  <IoMdArrowRoundDown
                                                    style={{
                                                      ...iconBackgroundStyle,
                                                      backgroundColor:
                                                        "lightgray",
                                                      color: "rgb(30 138 121)",
                                                    }}
                                                    size={28}
                                                    onClick={() =>
                                                      setArrowDown()
                                                    }
                                                  />
                                                ) : (
                                                  <IoMdArrowRoundUp
                                                    style={{
                                                      ...iconBackgroundStyle,
                                                      backgroundColor:
                                                        "lightgray",
                                                      color: "rgb(30 138 121)",
                                                    }}
                                                    size={28}
                                                    onClick={() => setArrowUp()}
                                                  />
                                                )}
                                              </div>
                                            )}
                                          {(!userInfo.membership_history ||
                                            userInfo?.membership_history
                                              ?.length === 0) &&
                                            (!userInfo.award_history ||
                                              userInfo?.award_history
                                                ?.length === 0) && (
                                              <div
                                                style={{
                                                  fontSize: "1.5rem",
                                                  padding: "0px",
                                                  marginLeft: "15px",
                                                  marginTop: "-5px",
                                                  marginBottom: "10px",
                                                }}
                                              >
                                                Add your awards and professional
                                                memberships
                                              </div>
                                            )}
                                        </div>
                                      </AccordionDetails>
                                    </Accordion>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                            )}
                            {/* End Profile  Awards and Memberships Section   */}

                            {/* Start Publications Section   */}
                            <div className="conatctDetails">
                              <p>
                                {!otherUser ||
                                !(
                                  (!userInfo.print_pub_history ||
                                    userInfo?.print_pub_history?.length ===
                                      0) &&
                                  (!userInfo.online_pub_history ||
                                    userInfo?.online_pub_history?.length === 0)
                                ) ? (
                                  <Accordion
                                    style={{
                                      boxShadow: " 0px 0px 1px 0px",
                                      border: "1px solid #e8e8e8",
                                      padding: "0px",
                                    }}
                                    expanded={true}
                                    onChange={handleChange1("publications")}
                                  >
                                    <AccordionSummary
                                      expandIcon={
                                        windowDimensions.width <= 768
                                          ? (!userInfo.print_pub_history ||
                                              userInfo?.print_pub_history
                                                ?.length === 0) &&
                                            (!userInfo.online_pub_history ||
                                              userInfo?.online_pub_history
                                                ?.length === 0)
                                            ? !otherUser && (
                                                <img
                                                  src={closeIcons}
                                                  style={{ width: "25px" }}
                                                  onClick={openPublicationModal}
                                                />
                                              )
                                            : !otherUser && (
                                                <img
                                                  src={closeIcons}
                                                  style={{ width: "25px" }}
                                                  onClick={openPublicationModal}
                                                />
                                              )
                                          : !otherUser && (
                                              <img
                                                src={closeIcons}
                                                style={{ width: "30px" }}
                                                onClick={openPublicationModal}
                                              />
                                            )
                                      }
                                      // expandIcon={<AddIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      {windowDimensions.width <= 768 ? (
                                        <Typography
                                          style={{
                                            font: "normal normal normal 18px/18px Roboto",
                                          }}
                                        >
                                          Publications
                                        </Typography>
                                      ) : (
                                        <Typography className={classes.heading}>
                                          Publications
                                        </Typography>
                                      )}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {windowDimensions.width <= 768 ? (
                                        <Typography>
                                          {userInfo.print_pub_history
                                            ? userInfo.print_pub_history
                                                .slice(0, 2)
                                                .map((pub, key) => (
                                                  <div key={key}>
                                                    <div className="line"></div>
                                                    <div
                                                      style={{
                                                        padding: "0px",
                                                        marginLeft: "17px",
                                                      }}
                                                    >
                                                      <p
                                                        className="print-head"
                                                        style={{
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        <img
                                                          src={print}
                                                          className="print-img"
                                                        ></img>{" "}
                                                        Printed
                                                      </p>
                                                      <h6
                                                        className="timeline-title"
                                                        style={{
                                                          color:
                                                            "rgb(30, 138, 121)",
                                                          margin:
                                                            "5px 0px 5px 0px",
                                                          wordBreak:
                                                            "break-all",
                                                          font: "normal normal bold 18px/18px Roboto",
                                                          letterSpacing: "0px",
                                                        }}
                                                      >
                                                        {pub.title}
                                                      </h6>

                                                      <div className="author-title">
                                                        {pub.author_names}
                                                      </div>
                                                      <div
                                                        className="text-muted"
                                                        style={{
                                                          fontSize: "15px",
                                                          wordBreak:
                                                            "break-all",
                                                        }}
                                                      >
                                                        {pub.journal}
                                                      </div>
                                                      <hr
                                                        style={{
                                                          marginTop: "15px",
                                                          marginBottom: "15px",
                                                        }}
                                                      ></hr>
                                                    </div>
                                                  </div>
                                                ))
                                            : ""}

                                          {userInfo.online_pub_history ? (
                                            userInfo.online_pub_history
                                              .slice(0, onlineIndex)
                                              .map((pub, key) => (
                                                <div key={key}>
                                                  <div className="line"></div>
                                                  <div
                                                    style={{
                                                      padding: "0px",
                                                      marginTop: "5px",
                                                    }}
                                                  >
                                                    <p
                                                      className="print-head"
                                                      style={{
                                                        padding: "0px",
                                                        marginLeft: "15px",
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      <img
                                                        src={online}
                                                        className="print-img"
                                                      ></img>{" "}
                                                      Online
                                                    </p>
                                                    <div
                                                      style={{
                                                        marginLeft: "15px",
                                                      }}
                                                    >
                                                      <h6
                                                        className="timeline-title"
                                                        style={{
                                                          color:
                                                            "rgb(30, 138, 121)",
                                                          margin:
                                                            "5px 0px 5px 0px",
                                                          wordBreak:
                                                            "break-all",
                                                          font: "normal normal bold 18px/18px Roboto",
                                                          letterSpacing: "0px",
                                                        }}
                                                      >
                                                        {pub.title}
                                                      </h6>

                                                      <div className="author-title">
                                                        {pub.author_names}
                                                      </div>
                                                      <div
                                                        className="text-muted"
                                                        style={{
                                                          fontSize: "15px",
                                                          wordBreak:
                                                            "break-all",
                                                        }}
                                                      >
                                                        {pub.journal}
                                                      </div>

                                                      <div
                                                        className="text-muted"
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        {pub.webpg_link}
                                                      </div>
                                                      <hr
                                                        style={{
                                                          marginTop: "15px",
                                                          marginBottom: "15px",
                                                        }}
                                                      ></hr>
                                                    </div>
                                                  </div>
                                                </div>
                                              ))
                                          ) : (
                                            <>
                                              <div className="line"></div>
                                            </>
                                          )}
                                          {(userInfo.print_pub_history ||
                                            userInfo.online_pub_history) &&
                                            (userInfo?.print_pub_history
                                              ?.length >= 2 ||
                                              userInfo?.online_pub_history
                                                ?.length >= 2 ||
                                              userInfo?.print_pub_history
                                                ?.length +
                                                userInfo?.online_pub_history
                                                  ?.length >=
                                                2) && (
                                              <div
                                                style={{
                                                  border:
                                                    "1px solid rgb(30 138 121) ",
                                                  color: "rgb(30 138 121)",
                                                  backgroundColor: "#ddfdf7",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  fontWeight: "bold",
                                                  fontSize: "1.3rem",
                                                  padding: "4px 4px ",
                                                  cursor: "pointer",
                                                  margin: "15px 15px",
                                                }}
                                                onClick={openPublicationModal}
                                              >
                                                View More
                                              </div>
                                            )}
                                          {(!userInfo.print_pub_history ||
                                            userInfo?.print_pub_history
                                              ?.length === 0) &&
                                            (!userInfo.online_pub_history ||
                                              userInfo?.online_pub_history
                                                ?.length === 0) && (
                                              <div
                                                style={{
                                                  fontSize: "1.5rem",
                                                  padding: "0px",
                                                  marginLeft: "15px",
                                                  marginTop: "-5px",
                                                  marginBottom: "10px",
                                                }}
                                              >
                                                Add your publications to show
                                                your <br></br>accomplishments
                                              </div>
                                            )}
                                        </Typography>
                                      ) : (
                                        <Typography>
                                          {userInfo.print_pub_history
                                            ? userInfo.print_pub_history
                                                .slice(0, noOfPrints)
                                                .map((pub, key) => (
                                                  <div key={key}>
                                                    <div className="line"></div>
                                                    <div
                                                      style={{
                                                        padding: "0px",
                                                        marginLeft: "17px",
                                                      }}
                                                    >
                                                      <div className="row">
                                                        <div
                                                          className={
                                                            windowDimensions.width <=
                                                            1200
                                                              ? "col-md-8"
                                                              : "col-9"
                                                          }
                                                        >
                                                          <p
                                                            className="print-head"
                                                            style={{
                                                              fontSize: "18px",
                                                            }}
                                                          >
                                                            <img
                                                              src={print}
                                                              className="print-img"
                                                              style={{
                                                                width: "15px",
                                                              }}
                                                            ></img>{" "}
                                                            Printed
                                                          </p>
                                                        </div>
                                                        {!otherUser && (
                                                          <div
                                                            className={
                                                              windowDimensions.width <=
                                                              1200
                                                                ? "col-md-2"
                                                                : "col-2"
                                                            }
                                                            style={{
                                                              maxWidth:
                                                                windowDimensions.width <=
                                                                1200
                                                                  ? "11%"
                                                                  : "10%",
                                                              marginLeft:
                                                                windowDimensions.width <=
                                                                1200
                                                                  ? "42px"
                                                                  : "",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={(e) => {
                                                              editPublication(
                                                                e,
                                                                pub
                                                              );
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "13px",
                                                                color: "black",
                                                              }}
                                                            >
                                                              {" "}
                                                              Edit{" "}
                                                            </span>
                                                            <MdEdit
                                                              style={
                                                                iconBackgroundStyle
                                                              }
                                                              size={20}
                                                            />
                                                          </div>
                                                        )}

                                                        {!otherUser && (
                                                          <div
                                                            className={
                                                              windowDimensions.width <=
                                                              1200
                                                                ? "col-md-2"
                                                                : "col-2"
                                                            }
                                                            style={{
                                                              maxWidth:
                                                                windowDimensions.width <=
                                                                1200
                                                                  ? ""
                                                                  : "12%",
                                                              marginLeft:
                                                                windowDimensions.width <=
                                                                1200
                                                                  ? ""
                                                                  : "18px",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={(e) =>
                                                              deletePublication(
                                                                e,
                                                                pub
                                                              )
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "13px",
                                                                color: "black",
                                                              }}
                                                            >
                                                              {" "}
                                                              Remove{" "}
                                                            </span>

                                                            <MdDeleteForever
                                                              style={{
                                                                ...iconBackgroundStyle,
                                                                backgroundColor:
                                                                  "red",
                                                              }}
                                                              size={20}
                                                            />
                                                            {/* <img
                                                              style={{
                                                                width: "20px",
                                                                height: "20px",
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              
                                                              src={deleteIcon}
                                                            /> */}
                                                          </div>
                                                        )}
                                                      </div>
                                                      <div
                                                        style={{
                                                          color:
                                                            "rgb(30, 138, 121)",
                                                          font: "normal normal bold 16px/18px Roboto",
                                                          letterSpacing: "0px",
                                                        }}
                                                      >
                                                        {pub.title}
                                                      </div>

                                                      <div className="author-title">
                                                        {pub.author_names}
                                                      </div>
                                                      <div
                                                        className="text-muted"
                                                        style={{
                                                          fontSize: "15px",
                                                          wordBreak:
                                                            "break-all",
                                                        }}
                                                      >
                                                        {pub.journal}
                                                      </div>
                                                      <hr
                                                        style={{
                                                          marginTop: "10px",
                                                          marginBottom: "10px",
                                                        }}
                                                      ></hr>
                                                    </div>
                                                  </div>
                                                ))
                                            : ""}
                                          {userInfo.online_pub_history ? (
                                            userInfo.online_pub_history
                                              .slice(0, noOfOnline)
                                              .map((pub, key) => (
                                                <div key={key}>
                                                  <div className="line"></div>
                                                  <div
                                                    style={{
                                                      padding: "0px",
                                                      marginLeft: "17px",
                                                    }}
                                                  >
                                                    <div className="row">
                                                      <div
                                                        className={
                                                          windowDimensions.width <=
                                                          1200
                                                            ? "col-md-8"
                                                            : "col-9"
                                                        }
                                                      >
                                                        <p
                                                          className="print-head"
                                                          style={{
                                                            fontSize: "18px",
                                                          }}
                                                        >
                                                          <img
                                                            src={online}
                                                            className="print-img"
                                                          ></img>{" "}
                                                          Online
                                                        </p>
                                                      </div>
                                                      {!otherUser && (
                                                        <div
                                                          className={
                                                            windowDimensions.width <=
                                                            1200
                                                              ? "col-md-2"
                                                              : "col-2"
                                                          }
                                                          style={{
                                                            maxWidth:
                                                              windowDimensions.width <=
                                                              1200
                                                                ? "11%"
                                                                : "10%",
                                                            marginLeft:
                                                              windowDimensions.width <=
                                                              1200
                                                                ? "42px"
                                                                : "",
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={(e) => {
                                                            editPublication(
                                                              e,
                                                              pub
                                                            );
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "13px",
                                                              color: "black",
                                                            }}
                                                          >
                                                            {" "}
                                                            Edit{" "}
                                                          </span>
                                                          <MdEdit
                                                            style={
                                                              iconBackgroundStyle
                                                            }
                                                            size={20}
                                                          />
                                                        </div>
                                                      )}

                                                      {!otherUser && (
                                                        <div
                                                          className={
                                                            windowDimensions.width <=
                                                            1200
                                                              ? "col-md-2"
                                                              : "col-2"
                                                          }
                                                          style={{
                                                            maxWidth:
                                                              windowDimensions.width <=
                                                              1200
                                                                ? ""
                                                                : "12%",
                                                            marginLeft:
                                                              windowDimensions.width <=
                                                              1200
                                                                ? ""
                                                                : "18px",
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={(e) =>
                                                            deletePublication(
                                                              e,
                                                              pub
                                                            )
                                                          }
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "13px",
                                                              color: "black",
                                                            }}
                                                          >
                                                            {" "}
                                                            Remove{" "}
                                                          </span>

                                                          <MdDeleteForever
                                                            style={{
                                                              ...iconBackgroundStyle,
                                                              backgroundColor:
                                                                "red",
                                                            }}
                                                            size={20}
                                                          />
                                                          {/* <img
                                                            style={{
                                                              width: "20px",
                                                              height: "20px",
                                                              cursor: "pointer",
                                                            }}
                                                            
                                                            src={deleteIcon}
                                                          /> */}
                                                        </div>
                                                      )}
                                                    </div>
                                                    <div>
                                                      <div
                                                        style={{
                                                          color:
                                                            "rgb(30, 138, 121)",
                                                          font: "normal normal bold 16px/18px Roboto",
                                                          letterSpacing: "0px",
                                                        }}
                                                      >
                                                        {pub.title}
                                                      </div>

                                                      <div className="author-title">
                                                        {pub.author_names}
                                                      </div>
                                                      <div
                                                        className="text-muted"
                                                        style={{
                                                          fontSize: "15px",
                                                          wordBreak:
                                                            "break-all",
                                                        }}
                                                      >
                                                        {pub.journal}
                                                      </div>

                                                      <div
                                                        className="text-muted"
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        {pub.webpg_link}
                                                      </div>
                                                      <hr
                                                        style={{
                                                          marginTop: "10px",
                                                          marginBottom: "10px",
                                                        }}
                                                      ></hr>
                                                    </div>
                                                  </div>
                                                </div>
                                              ))
                                          ) : (
                                            <>
                                              <div className="line"></div>
                                            </>
                                          )}

                                          {(userInfo?.print_pub_history
                                            ?.length > 2 ||
                                            userInfo?.online_pub_history
                                              ?.length > 2) &&
                                            windowDimensions.width > 728 && (
                                              <div
                                                style={{
                                                  position: "absolute",
                                                  bottom: "-14px",
                                                  left: "50%",
                                                  transform: "translate(-50%)",
                                                }}
                                              >
                                                {noOfPrints <= 2 &&
                                                noOfOnline <= 2 ? (
                                                  <IoMdArrowRoundDown
                                                    style={{
                                                      ...iconBackgroundStyle,
                                                      backgroundColor:
                                                        "lightgray",
                                                      color: "rgb(30 138 121)",
                                                    }}
                                                    size={28}
                                                    onClick={() =>
                                                      setPubArrowDown()
                                                    }
                                                  />
                                                ) : (
                                                  <IoMdArrowRoundUp
                                                    style={{
                                                      ...iconBackgroundStyle,
                                                      backgroundColor:
                                                        "lightgray",
                                                      color: "rgb(30 138 121)",
                                                    }}
                                                    size={28}
                                                    onClick={() =>
                                                      setPubArrowUp()
                                                    }
                                                  />
                                                )}
                                              </div>
                                            )}
                                          {(!userInfo.print_pub_history ||
                                            userInfo?.print_pub_history
                                              ?.length === 0) &&
                                            (!userInfo.online_pub_history ||
                                              userInfo?.online_pub_history
                                                ?.length === 0) && (
                                              <div
                                                style={{
                                                  fontSize: "1.5rem",
                                                  padding: "0px",
                                                  marginLeft: "15px",
                                                  marginTop: "-5px",
                                                  marginBottom: "10px",
                                                }}
                                              >
                                                Add your publications to show
                                                your accomplishments
                                              </div>
                                            )}
                                        </Typography>
                                      )}
                                    </AccordionDetails>
                                  </Accordion>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                            {/* End Profile  Publications Section   */}

                            {/* Start Profile  Qualifications Section   */}
                            {!otherUser ||
                            !(
                              !userInfo.acad_history ||
                              userInfo?.acad_history?.length === 0
                            ) ? (
                              <div
                                className="profile-card-container"
                                style={{
                                  position: "relative",
                                  marginBottom: "1.8em",
                                }}
                              >
                                <div className="profile-card-header">
                                  Qualifications
                                  {!otherUser && (
                                    <img
                                      src={closeIcons}
                                      style={{
                                        width:
                                          windowDimensions.width < 728
                                            ? "25px"
                                            : "30px",
                                        height:
                                          windowDimensions.width < 728
                                            ? "25px"
                                            : "30px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        if (windowDimensions.width < 768)
                                          history.push("/qualifications");
                                        else openQualificationsModal();
                                      }}
                                    />
                                  )}
                                </div>
                                {sortQualifications(userInfo?.acad_history)
                                  ?.slice(0, noOfQualifications)
                                  .map((qualification) => (
                                    <>
                                      <div
                                        style={{
                                          // padding: "1em",
                                          display: "flex",
                                          marginTop: "1.5em",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width:
                                              windowDimensions.length < 728
                                                ? "25%"
                                                : "10%",
                                            // display: "flex",
                                            // justifyContent: "center",
                                          }}
                                        >
                                          <img
                                            src={qualificationIcon}
                                            style={{
                                              width:
                                                windowDimensions.width < 728
                                                  ? "1.3em"
                                                  : "1.8em",
                                            }}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            width:
                                              windowDimensions.width < 728
                                                ? "75%"
                                                : "85%",
                                          }}
                                        >
                                          <div
                                            style={{
                                              color: "#469b8d",
                                              fontSize:
                                                windowDimensions.width < 728
                                                  ? "1.7rem"
                                                  : "1.8rem",
                                              fontWeight: "bold",
                                              overflowWrap: "break-word",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              if (
                                                windowDimensions.width < 728 &&
                                                !otherUser
                                              )
                                                history.push("/qualifications");
                                            }}
                                          >
                                            {qualification.degree}
                                          </div>
                                          {qualification.university &&
                                            qualification?.university !==
                                              "" && (
                                              <div
                                                style={{
                                                  fontSize: "1.2rem",
                                                  color: "black",
                                                  fontStyle: "italic",
                                                  fontWeight: "bold",
                                                  marginTop: "1em",
                                                  overflowWrap: "break-word",
                                                }}
                                              >
                                                {qualification.university}
                                              </div>
                                            )}
                                          {qualification.college &&
                                            qualification?.college !== "" && (
                                              <div
                                                style={{
                                                  fontSize: "1.2rem",
                                                  color: "gray",
                                                  marginTop: "0.5em",
                                                  overflowWrap: "break-word",
                                                }}
                                              >
                                                {qualification.college}
                                              </div>
                                            )}
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              marginTop: "0.3em",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontWeight: "bold",
                                                color: "black",
                                                fontSize: "1.2rem",
                                              }}
                                            >
                                              Graduation Year :{" "}
                                              {qualification.currently_pursuing
                                                ? "Currently Pursuing"
                                                : qualification.passing_year}
                                            </div>
                                          </div>
                                        </div>
                                        {windowDimensions.width > 728 &&
                                          !otherUser && (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "26%",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  editQualification(
                                                    qualification.acad_id
                                                  )
                                                }
                                              >
                                                <span
                                                  style={{
                                                    color: "black",
                                                    fontSize: "1.3rem",

                                                    marginRight: "0.3em",
                                                  }}
                                                >
                                                  Edit
                                                </span>
                                                <MdEdit
                                                  style={iconBackgroundStyle}
                                                  size={20}
                                                />
                                              </div>

                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  cursor: "pointer",
                                                  padding:"10px"
                                                }}
                                                onClick={
                                                  () =>
                                                    openConfirmDelete(
                                                      qualification.acad_id,
                                                      deleteQualification
                                                    )
                                                  // deleteQualification(
                                                  //   qualification.acad_id
                                                  // )
                                                }
                                              >
                                                <span
                                                  style={{
                                                    color: "black",
                                                    fontSize: "1.3rem",
                                                    marginRight: "0.3em",
                                                  }}
                                                >
                                                  Remove
                                                </span>
                                                <MdDeleteForever
                                                  style={{
                                                    ...iconBackgroundStyle,
                                                    backgroundColor: "red",
                                                  }}
                                                  size={20}
                                                />
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                      {windowDimensions.width < 728 && <hr />}
                                    </>
                                  ))}
                                {userInfo?.acad_history?.length > 2 &&
                                  windowDimensions.width < 728 && (
                                    <div
                                      style={{
                                        border: "1px solid rgb(30 138 121) ",
                                        color: "rgb(30 138 121)",
                                        backgroundColor: "#ddfdf7",
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: "bold",
                                        fontSize: "1.3rem",
                                        padding: "0.5em 0 ",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        if (otherUser) {
                                          if (noOfQualifications <= 2)
                                            setNoOfQualifications(
                                              userInfo?.acad_history?.length
                                            );
                                          else setNoOfQualifications(2);
                                        } else history.push("/qualifications");
                                      }}
                                    >
                                      {noOfQualifications <= 2
                                        ? "View More"
                                        : "View Less"}
                                    </div>
                                  )}
                                {userInfo?.acad_history?.length > 2 &&
                                  windowDimensions.width > 728 && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        bottom: "-14px",
                                        left: "50%",
                                        transform: "translate(-50%)",
                                      }}
                                    >
                                      {noOfQualifications <= 2 ? (
                                        <IoMdArrowRoundDown
                                          style={{
                                            ...iconBackgroundStyle,
                                            backgroundColor: "lightgray",
                                            color: "rgb(30 138 121)",
                                          }}
                                          size={28}
                                          onClick={() =>
                                            setNoOfQualifications(
                                              userInfo?.acad_history?.length
                                            )
                                          }
                                        />
                                      ) : (
                                        <IoMdArrowRoundUp
                                          style={{
                                            ...iconBackgroundStyle,
                                            backgroundColor: "lightgray",
                                            color: "rgb(30 138 121)",
                                          }}
                                          size={28}
                                          onClick={() =>
                                            setNoOfQualifications(2)
                                          }
                                        />
                                      )}
                                    </div>
                                  )}

                                {(!userInfo.acad_history ||
                                  userInfo?.acad_history?.length === 0) && (
                                  <div
                                    style={{
                                      fontSize: "1.5rem",
                                      padding: "0px",
                                      marginTop: "1em",
                                    }}
                                  >
                                    Share your academic qualifications and be
                                    discovered
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            {/* End Profile  Qualifications Section   */}

                            {/* Start Profile  Experiences Section   */}
                            {!otherUser ||
                            !(
                              !userInfo.prof_history ||
                              userInfo?.prof_history?.filter(
                                (exp) => !exp.working_here
                              ).length === 0
                            ) ? (
                              <div
                                className="profile-card-container"
                                style={{
                                  position: "relative",
                                  marginBottom: "1.8em",
                                }}
                              >
                                <div className="profile-card-header">
                                  Experience
                                  {!otherUser && (
                                    <img
                                      src={closeIcons}
                                      style={{
                                        width:
                                          windowDimensions.width < 728
                                            ? "25px"
                                            : "30px",
                                        height:
                                          windowDimensions.width < 728
                                            ? "25px"
                                            : "30px",
                                        cursor: "pointer",
                                      }}
                                      onClick={openAvailableAtDetailsModal}
                                    />
                                  )}
                                </div>
                                {sortExperiences(
                                  userInfo?.prof_history?.filter(
                                    (exp) => !exp.working_here
                                  )
                                )
                                  .slice(0, noOfExperience)
                                  .map((exp, key) => {
                                    if (!exp.working_here)
                                      return (
                                        <>
                                          <div
                                            style={{
                                              // padding: "1em",
                                              display: "flex",
                                              marginTop: "1.5em",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width:
                                                  windowDimensions.width < 728
                                                    ? "15%"
                                                    : "10%",
                                              }}
                                            >
                                              <img
                                                src={expPastIcon}
                                                style={{
                                                  width:
                                                    windowDimensions.width < 728
                                                      ? "1.5em"
                                                      : "1.8em",
                                                }}
                                              />
                                            </div>

                                            <div
                                              style={{
                                                width:
                                                  windowDimensions.width < 728
                                                    ? "75%"
                                                    : "85%",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  color: "#469b8d",
                                                  fontWeight: "bold",
                                                  fontSize:
                                                    windowDimensions.width < 728
                                                      ? "1.7rem"
                                                      : "1.8rem",
                                                  fontWeight: "bold",
                                                  overflowWrap: "break-word",
                                                }}
                                                onClick={() => {
                                                  if (
                                                    windowDimensions.width <
                                                      728 &&
                                                    !otherUser
                                                  )
                                                    openAvailableAtDetailsModal();
                                                }}
                                              >
                                                {exp.workplace}
                                              </div>

                                              {exp.location &&
                                                exp?.location !== "" && (
                                                  <div
                                                    style={{
                                                      fontSize:
                                                        windowDimensions.width <
                                                        728
                                                          ? "1.3rem"
                                                          : "1.5rem",
                                                      color:
                                                        windowDimensions.width <
                                                        728
                                                          ? "gray"
                                                          : "black",
                                                      marginTop: "0.5em",
                                                      overflowWrap:
                                                        "break-word",
                                                    }}
                                                  >
                                                    {exp.location}
                                                  </div>
                                                )}
                                              {exp.designation &&
                                                exp?.designation !== "" && (
                                                  <div
                                                    style={{
                                                      fontSize:
                                                        windowDimensions.width <
                                                        728
                                                          ? "1.3rem"
                                                          : "1.5rem",
                                                      color:
                                                        windowDimensions.width <
                                                        728
                                                          ? "gray"
                                                          : "black",
                                                      marginTop: "0.5em",
                                                      overflowWrap:
                                                        "break-word",
                                                    }}
                                                  >
                                                    {exp.designation}
                                                  </div>
                                                )}

                                              <div
                                                style={{
                                                  // display: "flex",
                                                  // justifyContent:
                                                  //   "space-between",
                                                  fontWeight: "bold",
                                                  color: "black",
                                                  fontSize: "1.3rem",
                                                  marginTop: "0.5em",
                                                  // width:
                                                  //   windowDimensions.width < 728
                                                  //     ? "100%"
                                                  //     : "30%",
                                                }}
                                              >
                                                {/* <div> */}
                                                {`
                                                    ${
                                                      exp.from_date &&
                                                      !exp.to_date
                                                        ? `Since`
                                                        : ""
                                                    }
                                                    ${
                                                      exp.from_date
                                                        ? `
                                                        ${new Date(
                                                          exp.from_date
                                                        ).toLocaleDateString(
                                                          "en-GB"
                                                        )}`
                                                        : " "
                                                    } ${
                                                  exp.from_date &&
                                                  exp.to_date &&
                                                  exp?.from_date < exp?.to_date
                                                    ? "-"
                                                    : " "
                                                } ${
                                                  exp.to_date && exp.from_date
                                                    ? `
                                                    ${new Date(
                                                      exp.to_date
                                                    ).toLocaleDateString(
                                                      "en-GB"
                                                    )}`
                                                    : " "
                                                } `}
                                                {/* </div> */}
                                              </div>
                                            </div>
                                            {windowDimensions.width > 728 &&
                                              !otherUser && (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "26%",
                                                    justifyContent:
                                                      "space-between",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                    onClick={() => {
                                                      editExperience(
                                                        exp.prof_id
                                                      );
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: "black",
                                                        fontSize: "1.3rem",
                                                        marginRight: "0.3em",
                                                      }}
                                                    >
                                                      Edit
                                                    </span>
                                                    <MdEdit
                                                      style={
                                                        iconBackgroundStyle
                                                      }
                                                      size={20}
                                                    />
                                                  </div>

                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      padding:"10px"
                                                    }}
                                                    onClick={
                                                      () => {
                                                        if (
                                                          checkShowOnCard(
                                                            exp.show_on_card
                                                          )
                                                        )
                                                          return;
                                                        openConfirmDelete(
                                                          exp.prof_id,
                                                          deleteExperience
                                                        );
                                                      }
                                                      // deleteExperience(
                                                      //   exp.prof_id
                                                      // )
                                                    }
                                                  >
                                                    <span
                                                      style={{
                                                        color: "black",
                                                        fontSize: "1.3rem",
                                                        marginRight: "0.3em",
                                                      }}
                                                    >
                                                      Remove
                                                    </span>
                                                    <MdDeleteForever
                                                      style={{
                                                        ...iconBackgroundStyle,
                                                        backgroundColor: "red",
                                                      }}
                                                      size={20}
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                          </div>

                                          {windowDimensions.width < 728 && (
                                            <hr />
                                          )}
                                        </>
                                      );
                                  })}
                                {userInfo?.prof_history?.filter(
                                  (exp) => !exp.working_here
                                ).length > 2 &&
                                  windowDimensions.width < 728 && (
                                    <div
                                      style={{
                                        border: "1px solid rgb(30 138 121) ",
                                        color: "rgb(30 138 121)",
                                        backgroundColor: "#ddfdf7",
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: "bold",
                                        fontSize: "1.3rem",
                                        padding: "0.5em 0 ",
                                      }}
                                      onClick={() => {
                                        if (otherUser) {
                                          if (noOfExperience <= 2)
                                            setNoOfExperience(
                                              !userInfo.prof_history ||
                                                userInfo?.prof_history?.filter(
                                                  (exp) => !exp.working_here
                                                ).length
                                            );
                                          else setNoOfExperience(2);
                                        } else openAvailableAtDetailsModal();
                                      }}
                                    >
                                      {noOfExperience <= 2
                                        ? "View More"
                                        : "View Less"}
                                    </div>
                                  )}
                                {(!userInfo.prof_history ||
                                  userInfo?.prof_history?.filter(
                                    (exp) => !exp.working_here
                                  ).length > 2) &&
                                  windowDimensions.width > 728 && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        bottom: "-14px",
                                        left: "50%",
                                        transform: "translate(-50%)",
                                      }}
                                    >
                                      {noOfExperience <= 2 ? (
                                        <IoMdArrowRoundDown
                                          style={{
                                            ...iconBackgroundStyle,
                                            backgroundColor: "lightgray",
                                            color: "rgb(30 138 121)",
                                          }}
                                          size={28}
                                          onClick={() =>
                                            setNoOfExperience(
                                              !userInfo.prof_history ||
                                                userInfo?.prof_history?.filter(
                                                  (exp) => !exp.working_here
                                                ).length
                                            )
                                          }
                                        />
                                      ) : (
                                        <IoMdArrowRoundUp
                                          style={{
                                            ...iconBackgroundStyle,
                                            backgroundColor: "lightgray",
                                            color: "rgb(30 138 121)",
                                          }}
                                          size={28}
                                          onClick={() => setNoOfExperience(2)}
                                        />
                                      )}
                                    </div>
                                  )}

                                {(!userInfo.prof_history ||
                                  userInfo?.prof_history?.filter(
                                    (exp) => !exp.working_here
                                  ).length === 0) && (
                                  <span
                                    style={{
                                      fontSize: "1.5rem",
                                      padding: "0px",
                                    }}
                                  >
                                    Show your work experience with others
                                  </span>
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            {/* <div className="conatctDetails">
                              <p>
                                {" "}
                                {workExperience == false ? (
                                  <Accordion
                                    style={{
                                      boxShadow: " 0px 0px 1px 0px",
                                      border: "1px solid #e8e8e8",
                                    }}
                                    expanded={
                                      userInfo.prof_history
                                        ? true
                                        : expanded === "experiences"
                                    }
                                    onChange={handleChange1("experiences")}
                                  >
                                    <AccordionSummary
                                      expandIcon={
                                        expanded === "experiences" ? (
                                          <img
                                            // src={closeIcons}
                                            style={{ width: "30px" }}
                                          />
                                        ) : (
                                          <img
                                            // src={closeIcons}
                                            style={{ width: "30px" }}
                                          />
                                        )
                                      }
                                      // expandIcon={<AddIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography
                                        className={classes.heading}
                                        className="contact-title"
                                      >
                                        Experiences
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Typography>
                                        {userInfo.prof_history
                                          ? userInfo.prof_history.map(
                                              (exp, key) => (
                                                <>
                                                  {!exp.working_here && (
                                                    <div key={key}>
                                                      <div className="line"></div>
                                                      <div
                                                        style={{
                                                          padding: "10px",
                                                        }}
                                                      >
                                                        <p className="aboutme-title">
                                                          {exp.workplace}
                                                        </p>
                                                        <p className="aboutme-title">
                                                          {exp.location}
                                                          <br />
                                                          {exp.designation}{" "}
                                                          <br />
                                                          <span className="text-muted">
                                                            {`${
                                                              exp.available_days
                                                                .department
                                                                ? exp
                                                                    .available_days
                                                                    .department +
                                                                  ","
                                                                : ""
                                                            } ${
                                                              exp.available_days
                                                                .week_of_days
                                                                .length !== 0
                                                                ? renderDays(
                                                                    exp
                                                                      .available_days
                                                                      .week_of_days
                                                                  ) + ","
                                                                : ""
                                                            } ${
                                                              exp.available_days
                                                                .from_time < 0
                                                                ? ""
                                                                : new Date(
                                                                    exp.available_days.from_time
                                                                  ).toLocaleTimeString()
                                                            } ${
                                                              exp.available_days
                                                                .from_time < 0
                                                                ? ""
                                                                : "-"
                                                            } ${
                                                              exp.available_days
                                                                .to_time < 0
                                                                ? ""
                                                                : new Date(
                                                                    exp.available_days.to_time
                                                                  ).toLocaleTimeString()
                                                            } `}
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            )
                                          : "No records"}
                                      </Typography>
                                    </AccordionDetails>
                                  </Accordion>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div> */}
                            {/* End Profile  Experiences Section   */}
                            {/* Start Profile  Events Section   */}
                            <div className="conatctDetails">
                              <p>
                                {!otherUser ||
                                !(
                                  !userInfo.events ||
                                  userInfo?.events?.length === 0
                                ) ? (
                                  <Accordion
                                    style={{
                                      boxShadow: " 0px 0px 1px 0px",
                                      border: "1px solid #e8e8e8",
                                      padding: "0px",
                                    }}
                                    expanded={true}
                                  >
                                    <AccordionSummary
                                      expandIcon={
                                        windowDimensions.width <= 768
                                          ? !userInfo.events ||
                                            userInfo?.events?.length === 0
                                            ? !otherUser && (
                                                <img
                                                  src={closeIcons}
                                                  style={{ width: "25px" }}
                                                  onClick={openEventsModal}
                                                />
                                              )
                                            : !otherUser && (
                                                <img
                                                  src={closeIcons}
                                                  style={{ width: "25px" }}
                                                  onClick={openEventsModal}
                                                />
                                              )
                                          : !otherUser && (
                                              <img
                                                src={closeIcons}
                                                style={{ width: "30px" }}
                                                onClick={openEventsModal}
                                              />
                                            )
                                      }
                                    >
                                      {windowDimensions.width <= 768 ? (
                                        <Typography
                                          style={{
                                            font: "normal normal normal 18px/18px Roboto",
                                          }}
                                        >
                                          Events Calender
                                        </Typography>
                                      ) : (
                                        <Typography className={classes.heading}>
                                          Events Calender
                                        </Typography>
                                      )}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {windowDimensions.width <= 768 ? (
                                        <Typography>
                                          {userInfo.events
                                            ? userInfo.events
                                                .slice(0, 2)
                                                .map((pub, key) => (
                                                  <div key={key}>
                                                    <div
                                                      style={{
                                                        padding: "0px",
                                                        marginLeft: "17px",
                                                      }}
                                                    >
                                                      <p
                                                        className="print-head"
                                                        style={{
                                                          font: "bold 18px / 18px Roboto",
                                                        }}
                                                      >
                                                        <img
                                                          src={ic_event}
                                                          className="print-img"
                                                          style={{
                                                            width: "30px",
                                                          }}
                                                        ></img>{" "}
                                                        {pub.event_name}
                                                      </p>
                                                      <div
                                                        style={{
                                                          color: "#6c757d",
                                                          margin:
                                                            "0px 0px 10px 45px",
                                                          font: "normal normal bold 12px/12px Roboto",
                                                        }}
                                                      >
                                                        {pub.event_location}
                                                      </div>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          color: "black",
                                                          margin:
                                                            "0px 0px 10px 45px",
                                                          font: "normal normal bold 12px/12px Roboto",
                                                        }}
                                                      >
                                                        <div>
                                                          {new Date(
                                                            pub.event_start_date
                                                          ).toLocaleDateString()}
                                                          {" - "}
                                                          {new Date(
                                                            pub.event_end_date
                                                          ).toLocaleDateString()}
                                                        </div>
                                                      </div>
                                                      <hr
                                                        style={{
                                                          marginTop: "15px",
                                                          marginBottom: "15px",
                                                        }}
                                                      ></hr>
                                                    </div>
                                                  </div>
                                                ))
                                            : ""}

                                          {(userInfo.events ||
                                            userInfo.events) &&
                                            userInfo?.events?.length > 2 && (
                                              <div
                                                style={{
                                                  border:
                                                    "1px solid rgb(30 138 121) ",
                                                  color: "rgb(30 138 121)",
                                                  backgroundColor: "#ddfdf7",
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  fontWeight: "bold",
                                                  fontSize: "1.3rem",
                                                  padding: "4px 4px ",
                                                  cursor: "pointer",
                                                  margin: "15px 15px",
                                                }}
                                                onClick={openEventsModal}
                                              >
                                                View More
                                              </div>
                                            )}
                                          {(!userInfo.events ||
                                            userInfo?.events?.length === 0) && (
                                            <div
                                              style={{
                                                fontSize: "1.5rem",
                                                padding: "0px",
                                                marginLeft: "15px",
                                                marginTop: "-5px",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              Let others know about events that
                                              you are attending
                                            </div>
                                          )}
                                        </Typography>
                                      ) : (
                                        <Typography>
                                          {userInfo.events
                                            ? userInfo.events
                                                .slice(0, noOfEvents)
                                                .map((pub, key) => (
                                                  <div key={key}>
                                                    <div className="line"></div>
                                                    <div
                                                      style={{
                                                        padding: "0px",
                                                        marginLeft: "17px",
                                                      }}
                                                    >
                                                      <div className="row">
                                                        <div
                                                          className={
                                                            windowDimensions.width <=
                                                            1200
                                                              ? "col-md-8"
                                                              : "col-9"
                                                          }
                                                        >
                                                          <p
                                                            style={{
                                                              font: "normal bold 16px/16px Roboto",
                                                            }}
                                                          >
                                                            {" "}
                                                            {pub.event_name}
                                                          </p>
                                                        </div>
                                                        {!otherUser && (
                                                          <div
                                                            className={
                                                              windowDimensions.width <=
                                                              1200
                                                                ? "col-md-2"
                                                                : "col-2"
                                                            }
                                                            style={{
                                                              maxWidth:
                                                                windowDimensions.width <=
                                                                1200
                                                                  ? "11%"
                                                                  : "10%",
                                                              marginLeft:
                                                                windowDimensions.width <=
                                                                1200
                                                                  ? "42px"
                                                                  : "",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={(e) => {
                                                              editEvents(
                                                                e,
                                                                pub
                                                              );
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "13px",
                                                                color: "black",
                                                              }}
                                                            >
                                                              {" "}
                                                              Edit{" "}
                                                            </span>
                                                            <MdEdit
                                                              style={
                                                                iconBackgroundStyle
                                                              }
                                                              size={20}
                                                            />
                                                          </div>
                                                        )}

                                                        {!otherUser && (
                                                          <div
                                                            className={
                                                              windowDimensions.width <=
                                                              1200
                                                                ? "col-md-2"
                                                                : "col-2"
                                                            }
                                                            style={{
                                                              maxWidth:
                                                                windowDimensions.width <=
                                                                1200
                                                                  ? ""
                                                                  : "12%",
                                                              marginLeft:
                                                                windowDimensions.width <=
                                                                1200
                                                                  ? ""
                                                                  : "18px",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={(e) =>
                                                              deleteEvents(
                                                                e,
                                                                pub
                                                              )
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "13px",
                                                                color: "black",
                                                              }}
                                                            >
                                                              {" "}
                                                              Remove{" "}
                                                            </span>

                                                            <MdDeleteForever
                                                              style={{
                                                                ...iconBackgroundStyle,
                                                                backgroundColor:
                                                                  "red",
                                                              }}
                                                              size={20}
                                                            />
                                                          </div>
                                                        )}
                                                      </div>
                                                      <div
                                                        style={{
                                                          font: "normal normal 16px/18px Roboto",
                                                          margin:
                                                            "-3px 0px 6px 0px",
                                                        }}
                                                      >
                                                        {pub.event_location}
                                                      </div>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          font: "bold 14px/14px Roboto",
                                                        }}
                                                      >
                                                        <div>
                                                          From:{" "}
                                                          {new Date(
                                                            pub.event_start_date
                                                          )
                                                            .toDateString()
                                                            .split(" ")
                                                            .slice(1)
                                                            .join(", ")}
                                                        </div>
                                                        <div
                                                          style={{
                                                            marginLeft: "10px",
                                                          }}
                                                        >
                                                          To :{" "}
                                                          {new Date(
                                                            pub.event_end_date
                                                          )
                                                            .toDateString()
                                                            .split(" ")
                                                            .slice(1)
                                                            .join(", ")}
                                                        </div>
                                                      </div>
                                                      <hr
                                                        style={{
                                                          marginTop: "10px",
                                                          marginBottom: "10px",
                                                        }}
                                                      ></hr>
                                                    </div>
                                                  </div>
                                                ))
                                            : ""}
                                          {userInfo?.events?.length > 2 &&
                                            windowDimensions.width > 728 && (
                                              <div
                                                style={{
                                                  position: "absolute",
                                                  bottom: "-14px",
                                                  left: "50%",
                                                  transform: "translate(-50%)",
                                                }}
                                              >
                                                {noOfEvents <= 2 ? (
                                                  <IoMdArrowRoundDown
                                                    style={{
                                                      ...iconBackgroundStyle,
                                                      backgroundColor:
                                                        "lightgray",
                                                      color: "rgb(30 138 121)",
                                                    }}
                                                    size={28}
                                                    onClick={() =>
                                                      setNoOfEvents(
                                                        userInfo?.events?.length
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <IoMdArrowRoundUp
                                                    style={{
                                                      ...iconBackgroundStyle,
                                                      backgroundColor:
                                                        "lightgray",
                                                      color: "rgb(30 138 121)",
                                                    }}
                                                    size={28}
                                                    onClick={() =>
                                                      setNoOfEvents(2)
                                                    }
                                                  />
                                                )}
                                              </div>
                                            )}
                                          {(!userInfo.events ||
                                            userInfo?.events?.length === 0) && (
                                            <div
                                              style={{
                                                fontSize: "1.5rem",
                                                padding: "0px",
                                                marginLeft: "15px",
                                                marginTop: "-5px",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              Let others know about events that
                                              you are attending
                                            </div>
                                          )}
                                        </Typography>
                                      )}
                                    </AccordionDetails>
                                  </Accordion>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                            {/* End Profile  Events Section   */}
                          </TabPanel>
                          <TabPanel value="2">
                            <div
                              className="container"
                              style={{ padding: "0px" }}
                            >
                              <div
                                className="row justify-content-center"
                                style={{ padding: "0px" }}
                              >
                                <div
                                  className="col-lg-8  col-md-12 order-2 order-lg-1"
                                  style={{ padding: "0px" }}
                                >
                                  <Feeds
                                    feeds={feeds}
                                    profile_pic={
                                      otherUser
                                        ? originalUser?.profile_pic_small_url +
                                          `?${Date.now()}`
                                        : userInfo?.profile_pic_small_url +
                                          `?${Date.now()}`
                                    }
                                    updateLikeCount={updateLikeCount}
                                    addComment={addComment}
                                    openFullView={openFullViewModal}
                                    openLikesModal={openLikesModal}
                                    submitSurvey={submitSurvey}
                                    channels={channels}
                                    registerWebinar={registerWebinar}
                                    user_verification_info={
                                      otherUser
                                        ? originalUser?.verificationInfo
                                        : userInfo?.verificationInfo
                                    }
                                  />
                                  {loading && <div> Loading ....</div>}
                                  {error && <div> Error ....</div>}
                                  <div ref={loader} />

                                  <Modal
                                    open={fullViewModal}
                                    blockScroll={true}
                                    onClose={closeFullViewModal}
                                    classNames={{ modal: "feedModel" }}
                                  >
                                    <FullViewFeed
                                      close={closeFullViewModal}
                                      feed={modalData}
                                      setFeeds={setFeeds}
                                      addComment={addComment}
                                      updateLikeCount={updateLikeCount}
                                      submitSurvey={submitSurvey}
                                      updateViewCount={updateViewCount}
                                      channels={channels}
                                      deleteComment={deleteComment}
                                      updateComment={updateComment}
                                      openLikesModal={openLikesModal}
                                      openCommentMobileView={openCommentModal}
                                      registerWebinar={registerWebinar}
                                      user_verification_info={
                                        otherUser
                                          ? originalUser?.verificationInfo
                                          : userInfo?.verificationInfo
                                      }
                                    ></FullViewFeed>
                                  </Modal>

                                  <Modal
                                    open={uploadModel}
                                    onClose={handleCloseUploadMci}
                                    classNames={{ modal: "uploadMci" }}
                                    closeIcon={closeIcon}
                                  >
                                    <MciUpload
                                      closeUploadMci={handleCloseUploadMci}
                                      openSuccessModal={openUploadMciSuccess}
                                    />
                                  </Modal>

                                  <Modal
                                    closeIcon={closeIcon}
                                    open={uploadMciSuccess}
                                    onClose={closeUploadMciSuccess}
                                    classNames={{ modal: "verificationModel" }}
                                  >
                                    <MciUploadSuccess
                                      closeUploadMciSuccess={
                                        closeUploadMciSuccess
                                      }
                                    />
                                  </Modal>

                                  <Modal
                                    open={openVerificationPending}
                                    onClose={handleCloseVerificationPending}
                                    classNames={{ modal: "verificationModel" }}
                                    closeIcon={closeIcon}
                                  >
                                    <MciVerificationStatus
                                      is_user_verified={
                                        otherUser
                                          ? originalUser?.verificationInfo
                                              ?.is_user_verified
                                          : userInfo?.verificationInfo
                                              ?.is_user_verified
                                      }
                                      closeVerificationStatusModal={
                                        handleCloseVerificationPending
                                      }
                                    />
                                  </Modal>

                                  <Modal
                                    open={likesModal}
                                    onClose={closeLikesModal}
                                    classNames="feedModel"
                                    style={{ width: "400px" }}
                                  >
                                    <Likes feed={modalData}></Likes>
                                  </Modal>

                                  {/* Mobile responce Comment list */}
                                  <Modal
                                    open={commentModal}
                                    blockScroll={true}
                                    onClose={closeCommentModal}
                                    classNames={{ modal: "feedsLikeModel" }}
                                    styles={{
                                      modal: {
                                        height: "100vh",
                                        width: "100%",
                                        padding: "0",
                                        margin: "0",
                                        borderRadius: "0",
                                        overflow: "visible",
                                      },
                                      modalContainer: {
                                        overflow: "visible",
                                      },
                                    }}
                                    showCloseIcon={false}
                                    feed={modalData}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "100%",
                                      }}
                                    >
                                      <div
                                        className="mobile-header-wrapper d-block d-lg-none"
                                        style={{
                                          position: "-webkit-sticky",
                                          position: "sticky",
                                          top: "0",
                                          left: "0",
                                          width: "100%",
                                          zIndex: "5",
                                        }}
                                      >
                                        <div className="mobile-header position-relative ">
                                          <div style={{ display: "flex" }}>
                                            <div className="brand-logo ">
                                              <img
                                                onClick={closeCommentModal}
                                                src={send}
                                                style={{
                                                  width: "25px",
                                                  marginTop: "-4px",
                                                  cursor: "pointer",
                                                }}
                                                alt="timeline logo"
                                              />
                                            </div>
                                            <div className="pull-left">
                                              <p style={{ marginLeft: "10px" }}>
                                                Comment &nbsp;
                                                <span
                                                  style={{ color: "green" }}
                                                >
                                                  {`(${modalData?.feed_info?.socialInteraction?.commentsCount} Comments)`}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div style={{ overflowY: "auto" }}>
                                        <CommentsList
                                          feed={modalData}
                                          deleteComment={deleteComment}
                                          updateComment={updateComment}
                                        />
                                      </div>
                                      <div
                                        className="footer-area responsive-footer d-block d-lg-none"
                                        style={{
                                          position: "-webkit-sticky",
                                          position: "sticky",
                                        }}
                                      >
                                        <hr></hr>
                                        <div
                                          className="post-meta"
                                          style={{ padding: "0", margin: "0" }}
                                        >
                                          <CommentInput
                                            profile_pic={
                                              userInfo?.profile_pic_small_url +
                                              `?${Date.now()}`
                                            }
                                            addComment={addComment}
                                            feed_id={
                                              modalData?.feed_info?.feed_id
                                            }
                                            channel_id={modalData.channel_id}
                                            isFullFeedView={false}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>
                                </div>
                              </div>
                            </div>

                            {/* <div className={classes.root} style={{display: 'flex',justifyContent: 'center'}}>
                              <Pagination count={userInfo.length} shape="rounded" />
                            </div> */}
                          </TabPanel>
                        </TabContext>
                      </div>
                    </div>
                  ),
                  help: <Help />,
                }[navigation]
              }
              {otherUser && (
                <div
                  className="col-lg-3 order-2 order-lg-3 d-none d-sm-block "
                  id="right"
                >
                  <DahboardRightside></DahboardRightside>
                </div>
              )}
            </div>
          </div>
        </main>

        <Footer pre_login={false} />
        {/* ashwin */}
        <EditModal
          open={contactDetailsModal}
          closeModal={closeContactDetailsModal}
          modalTitle={"Contact Details"}
        >
          <ContactDetailsForm
            userInfo={userInfo}
            closeModal={closeContactDetailsModal}
            successFunc={contactDetailsSuccessFunc}
            errorFunc={contactDetailsErrorFunc}
          />
        </EditModal>

        <EditModal
          open={qualificationsModal}
          closeModal={closeQualificationsModal}
          modalTitle={"Qualifications"}
          modalClassName={"qualificationsModal"}
        >
          <QualificationsForm
            closeModal={closeQualificationsModal}
            isEdit={isQualificationEdit}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            acadId={acadId}
          />
        </EditModal>

        <EditModal
          open={aoiModal}
          closeModal={closeAoiModal}
          modalTitle={"Area Of Interest"}
          modalClassName={"aoiModal"}
          center
        >
          <AreaOfInterests
            closeModal={closeAoiModal}
            isEdit={isAoiEdit}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            interestId={interestId}
          />
        </EditModal>

        <ConfirmDeleteModal
          open={confirmDelete}
          closeModal={closeConfirmDelete}
          id={deleteData.id}
          deleteEntry={deleteData.delete}
        />

        {/* ashwin */}
        {/* chandra */}
        <EditModal
          open={personalDetailsModal}
          closeModal={closePersonalDetailsModal}
          modalTitle={"Personal Info"}
        >
          <EditPersonalInfo
            getUserData={getUserData}
            userInfo={userInfo}
            closePersonalDetailsModal={closePersonalDetailsModal}
          />
        </EditModal>

        <EditModal
          open={availableAtDetailsModal}
          closeModal={closeAvailableAtDetailsModal}
          modalTitle={"Experience"}
          center
        >
          <AvailableAt
            closeModal={closeAvailableAtDetailsModal}
            isEdit={isExperienceEdit}
            userInfo={userInfo}
            getUserInfo={getUserData}
            profId={profId}
          />
        </EditModal>
        {/* chandra */}
        {/* srini */}

        <Modal
          open={deletePubModal}
          closeModal={closeDeletePubModal}
          showCloseIcon={true}
          onClose={closeDeletePubModal}
          classNames={{ modal: "pubDelete" }}
          styles={{
            modal: { padding: 0, overflow: "hidden", backgroundColor: "white" },
          }}
        >
          <DeleteAvailable
            getUserData={getUserData}
            closeDeletePubModal={closeDeletePubModal}
            deletedData={deletedData}
          />
        </Modal>

        <Modal
          open={deletePubModal}
          closeModal={closeDeletePubModal}
          showCloseIcon={true}
          onClose={closeDeletePubModal}
          classNames={{ modal: "pubDelete" }}
          styles={{
            modal: { padding: 0, overflow: "hidden", backgroundColor: "white" },
          }}
        >
          <DeletePublications
            getUserData={getUserData}
            closeDeletePubModal={closeDeletePubModal}
            deletedData={deletedData}
          />
        </Modal>

        <PublicationsModal
          open={publicationModal}
          closeModal={closePublicationModal}
          modalTitle={"Publications"}
          modalId={editClicked}
          onClose={closePublicationModal}
        >
          <Publications
            getUserData={getUserData}
            userInfo={userInfo}
            editClicked={editClicked}
            editedData={editedData}
            closePublicationModal={closePublicationModal}
          />
        </PublicationsModal>

        <Modal
          open={deleteMembershipModal}
          closeModal={closeDeleteAwardsMembershipModal}
          showCloseIcon={true}
          onClose={closeDeleteAwardsMembershipModal}
          classNames={{ modal: "pubDelete" }}
          styles={{
            modal: { padding: 0, overflow: "hidden", backgroundColor: "white" },
          }}
        >
          <DeleteAwardMember
            getUserData={getUserData}
            closeDeleteAwardsMembershipModal={closeDeleteAwardsMembershipModal}
            deletedData={deletedData}
          />
        </Modal>
        <PublicationsModal
          open={awardsMembershipModal}
          closeModal={closeAwardsMembershipModal}
          modalTitle={"Awards & Memberships"}
          onClose={closeAwardsMembershipModal}
        >
          <AwardsMembership
            getUserData={getUserData}
            userInfo={userInfo}
            editClicked={editClicked}
            editedData={editedData}
            closeAwardsMembershipModal={closeAwardsMembershipModal}
          />
        </PublicationsModal>
        {/* 
        <Modal
          open={deleteEventsModal}
          closeModal={closeDeleteEventsModal}
          showCloseIcon={true}
          onClose={closeDeleteEventsModal}
          classNames={{ modal: "pubDelete" }}
          styles={{
            modal: { padding: 0, overflow: "hidden", backgroundColor: "white" },
          }}
        >
          <DeleteAwardMember
            getUserData={getUserData}
            closeDeleteEventsModal={closeDeleteEventsModal}
            deletedData={deletedData}
          />
        </Modal> */}
        <PublicationsModal
          open={eventsModal}
          closeModal={closeEventsModal}
          modalTitle={"Events"}
          modalId={editClicked}
          onClose={closeEventsModal}
        >
          <Events
            getUserData={getUserData}
            userInfo={userInfo}
            editClicked={editClicked}
            editedData={editedData}
            closeEventsModal={closeEventsModal}
          />
        </PublicationsModal>
        {/* srini */}
      </div>
    </>
  );
}

const iconBackgroundStyle = {
  backgroundColor: "rgb(30 138 121)",
  borderRadius: "50%",
  padding: "3px",
  color: "white",
  cursor: "pointer",
};
