import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { getHeaders, logout } from "../../utils/utils";

const useDiscoverySubCategoryFeedLoader = (
  doc_id,
  nextFeedsData,
  categoryId,
  setFeedsList,
  categoryType,
  searchValue,
  subCategoryId,
  setEmpty,
  setUpdateSubCategoryUnreadCount
) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const getCategoryfeedList = async () => {
    setLoading(true);
    setError(false);

    const payload = {
      user_id: doc_id,
      category_id: categoryId,
      sub_category_id: subCategoryId,
      pg_num: nextFeedsData.pageNum,
    };

    const res = await fetch(baseUrl + "v1/sub-categories/get", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {
      setLoading(false);
      setError(true);
      setHasMore(false);
      return;
    });

    const data = await res.json();

    if (data.status === "success") {
      setEmpty(data.data.feed_data.length <= 0);
      if (payload.pg_num === 0) setFeedsList([...data.data.feed_data]);
      else
        setFeedsList((prevFeeds) => {
          return [...new Set([...prevFeeds, ...data.data?.feed_data])];
        });

      setUpdateSubCategoryUnreadCount({
        categoryId: data.data.category_id,
        newUnreadCount: data.data.unread_count,
      });
      setLoading(false);
      setHasMore(data.data.feed_data.length > 0);
    } else {
      setEmpty(true);
      setLoading(false);
      setError(true);
      setHasMore(false);
      return;
    }
  };
  const searchFeeds = async () => {
    console.log("this is from ");
    setLoading(true);
    setError(false);
    const payload = {
      category_type: categoryType,
      category_id: subCategoryId,
      pg_num: nextFeedsData.pageNum,
      filter: { search_value: searchValue },
    };
    const res = await fetch(baseUrl + "v2/search/feeds", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {
      setLoading(false);
      setError(true);
      setHasMore(false);
      return;
    });
    const data = await res.json();
    if (payload.pg_num === 0) setFeedsList([]);
    if (data.status === "success") {
      if (data.data?.feed_data && data.data?.feed_data?.length > 0) {
        setFeedsList((prevFeeds) => {
          return [...new Set([...prevFeeds, ...data.data?.feed_data])];
        });
      }
      setLoading(false);
      setHasMore(data.data.feed_data.length > 0);
    } else {
      setLoading(false);
      setError(true);
      setHasMore(false);
      return;
    }
  };

  const getSubCategory = async (sub_category_id) => {
    const payload = {
      user_id: doc_id,
      category_id: categoryId,
      sub_category_id,
      pg_num: nextFeedsData.pageNum,
    };
    const res = await fetch(baseUrl + "v1/sub-categories/get", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    if (data.status === "success")
      if (data.data?.feed_data && data.data?.feed_data?.length > 0) {
        setFeedsList(data.data?.feed_data);
      }
  };
  useEffect(() => {
    if (searchValue === "") {
      getCategoryfeedList();
    } else {
      searchFeeds();
    }
  }, [nextFeedsData]);
  return { loading, error, hasMore };
};

export default useDiscoverySubCategoryFeedLoader;
