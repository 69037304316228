import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Badge from "@mui/material/Badge";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { getHeaders, logout, getDocId } from "../utils/utils";
import CategoriesFeeds from "./CategoriesFeeds";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputBase from "@mui/material/InputBase";
import AppBar from "@material-ui/core/AppBar";
import SearchIcon from "@mui/icons-material/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import DistributionCategories from "./DistributionCategories";
import SubCategoriesFeeds from "./SubCategoriesFeeds";
import useDiscoverySubCategoryFeedLoader from "../dashboard/hooks/useDiscoverySubCategoryFeedLoader";
import Progressbar from "../progressbar";
import { useEffect, useState, useRef, useCallback } from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    font: "normal normal medium Roboto",
    "& .css-1mcnwpj-MuiList-root": {
      listStyle: "none",
      margin: 0,
      padding: 0,
      position: "relative",
      paddingTop: "8px",
      paddingBottom: "8px",
      width: "100%",
      maxWidth: "100%",
      backgroundColor: "#fff",
    },
    "&.MuiListItemText-root": {
      margin: 0,
      fontFamily: "Roboto",
      fontWeight: 700,
      fontSize: "14px",
      /* font-size: 1rem; */
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
      display: "block",
      /* font-size: 14px; */
    },
    "&.css-fqe407-MuiBadge-badge": {
      marginRight: "23px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const shapeStyles = { bgcolor: "primary.main", width: 40, height: 40 };
const shapeCircleStyles = { borderRadius: "50%" };
const rectangle = <Box component="span" sx={shapeStyles} />;
const circle = (
  <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles }} />
);
const SubCategoryList = ({
  user_verification_info,
  categoryId,
  setcategoryName,
  channels,
  profile_pic,
  selectedCategoryData,
  categoryNameLabel,
  setUpdateCategoryUnreadCount,
  changeHeader,
}) => {
  const classes = useStyles();
  // const changeHeader = false;
  const [feedsErrorMsg, setFeedsErrorMsg] = useState("Could not load Feeds");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const doc_id = getDocId();
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  // const [category, setCategory] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [feedsList, setFeedsList] = useState([]);
  const [categoryDistList, setCategoryDistList] = useState([]);
  const [shouldDisplayFeeds, setShouldDisplayFeeds] = useState(false);
  const [displaySubCategoryFeeds, setDisplaySubCategoryFeeds] = useState(false);
  const [shouldDisplayCategoryDist, setShouldDisplayCategoryDist] =
    useState(false);
  const [categoryType, setCategoryType] = useState(
    selectedCategoryData?.category_type
  );
  const [categoryName, setCategoryName] = useState(
    selectedCategoryData?.category_name
  );
  // const [categoryNameLabel, setCategoryNameLabel] = useState(
  //   "Search " + selectedCategoryData?.category_name
  // );
  const [searchValue, setSearchValue] = useState("");
  const [isShowSearchFeeds, setIsShowSearchFeeds] = useState(false);
  const [feedsData, setFeedsData] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState();
  const [subCategoryName, setSubCategoryName] = useState();
  const [subCategoryLabelName, setSubCategoryLabelName] = useState();

  const [updateSubCategoryUnreadCount, setUpdateSubCategoryUnreadCount] =
    useState({
      categoryId: undefined,
      newUnreadCount: undefined,
    });

  useEffect(() => {
    setCategoryList([]);
    setFeedsList([]);
    getCategory();
  }, [categoryId]);

  useEffect(() => {
    if (categoryList?.length > 0 && updateSubCategoryUnreadCount.categoryId) {
      categoryList.map((category) => {
        if (
          category.sub_category_id === updateSubCategoryUnreadCount.categoryId
        )
          setUpdateCategoryUnreadCount((prev) => ({
            categoryId,
            newUnreadCount: -1 * category.unread_count,
          }));
      });

      setCategoryList((prevCategories) => {
        const newCategories = [...prevCategories];
        newCategories.map((newCategory) => {
          if (
            newCategory.sub_category_id ===
            updateSubCategoryUnreadCount.categoryId
          ) {
            newCategory.unread_count =
              updateSubCategoryUnreadCount.newUnreadCount;
          }
        });
        return newCategories;
      });
    }
  }, [updateSubCategoryUnreadCount]);

  const getCategory = async () => {
    const payload = {
      user_id: doc_id,
      category_id: categoryId,
      pg_num: 0,
    };
    const res = await fetch(baseUrl + "v1/feed/categories/get", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    if (data.status === "success") setShouldDisplayCategoryDist(false);
    setShouldDisplayFeeds(false);
    setDisplaySubCategoryFeeds(false);
    if (
      data.data?.category_distributions &&
      data.data?.category_distributions?.length > 0
    ) {
      setShouldDisplayFeeds(false);
      setShouldDisplayCategoryDist(true);
      // setCategoryDistList(data.data?.feed_data);
    } else if (
      data.data?.sub_categories &&
      data.data?.sub_categories?.length > 0
    ) {
      setShouldDisplayFeeds(false);
      setShouldDisplayCategoryDist(false);
      setCategoryList(data.data.sub_categories);
    } else if (data.data?.feed_data && data.data?.feed_data?.length >= 0) {
      setShouldDisplayCategoryDist(false);
      setShouldDisplayFeeds(true);
      // setFeedsList(data.data?.feed_data);
    }
    setCategoryName(data.data?.category_name);
    setCategoryType(data.data?.category_type);
  };

  const getSubCategory = async (sub_category_id, sub_category_name) => {
    setSubCategoryId(sub_category_id);
    setSubCategoryName(sub_category_name);
    setSubCategoryLabelName("Search " + sub_category_name);
    const payload = {
      user_id: doc_id,
      category_id: categoryId,
      sub_category_id,
      pg_num: 0,
    };
    const res = await fetch(baseUrl + "v1/sub-categories/get", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    if (data.status === "success")
      // if (data.data?.feed_data && data.data?.feed_data?.length > 0) {
      setShouldDisplayCategoryDist(false);
    setShouldDisplayFeeds(false);

    setDisplaySubCategoryFeeds(true);
    //setFeedsList(data.data?.feed_data);
    // }
  };

  // const observer = useRef(null);
  // const loader = useCallback(
  //   (node) => {
  //     if (loading) return;
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting && hasMore) {
  //         setNextFeedsData((prev) => ({
  //           ...prev,
  //           pageNum: prev.pageNum + 1,
  //           // lastFeedTime: feeds[feeds.length - 1]?.feed_info?.updated_time,
  //         }));
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   },

  //   [loading, hasMore]
  // );

  const searchClick = async (e) => {
    e.stopPropagation();
    const payload = {
      category_type: categoryType,
      category_id: categoryId,
      pg_num: 0,
      filter: { search_value: searchValue },
    };
    const res = await fetch(baseUrl + "v2/search/feeds", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();

    if (data.status === "success")
      if (data.data?.feed_data && data.data?.feed_data?.length > 0) {
        // if(value === '4'){
        // }  else if(value === '5'){
        // }
        // setShouldDisplayDistFeeds(false);
        // setIsShowSearchFeeds(true);
        // setFeedsData(data.data?.feed_data);
      }
  };
  const searchClear = async () => {
    setSearchValue("");
    // setShouldDisplayDistFeeds(true);
    //setIsShowSearchFeeds(false);
    // setNextFeedsData(() => ({
    //   pageNum: 0
    // }));
  };

  return (
    <div>
      <div style={{ backgroundColor: "white", cursor: "pointer" }}>
        {displaySubCategoryFeeds && categoryList.length > 0 && (
          <div
            onClick={() => {
              setShouldDisplayFeeds(false);
              setShouldDisplayCategoryDist(false);
              setDisplaySubCategoryFeeds(false);
            }}
            style={{ padding: "10px" }}
          >
            {" "}
            <ArrowBackIcon size="large" />
            <span
              style={{ marginLeft: "20px", font: "bold 15px / 15px roboto" }}
            >
              {subCategoryName}
            </span>
          </div>
        )}
      </div>

      {!shouldDisplayFeeds &&
        !displaySubCategoryFeeds &&
        !shouldDisplayCategoryDist &&
        categoryList.length > 0 && (
          <List
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "background.paper",
              padding: "10px",
            }}
          >
            {!shouldDisplayFeeds &&
              !displaySubCategoryFeeds &&
              !shouldDisplayCategoryDist &&
              categoryList.map((value) => (
                <ListItem
                  onClick={() =>
                    getSubCategory(
                      value.sub_category_id,
                      value.sub_category_name
                    )
                  }
                  style={{
                    borderTop: "1px solid #e7e5e5",
                    borderBottom: "1px solid #e7e5e5",
                  }}
                  key={value}
                  disableGutters
                  secondaryAction={
                    <IconButton>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  }
                >
                  <div>
                    <Badge
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      color="success"
                      overlap="circular"
                      badgeContent={value.unread_count}
                      style={{ zIndex: "0", marginRight: "23px" }}
                    >
                      <img
                        onClick={() =>
                          getSubCategory(
                            value.sub_category_id,
                            value.sub_category_name
                          )
                        }
                        src={value.image_url}
                        style={{
                          borderRadius: "50%",
                          width: "60px",
                          height: "60px",
                          cursor: "pointer",
                        }}
                      />
                    </Badge>
                  </div>
                  <ListItemText
                    className={classes.root}
                    primary={
                      <Typography
                        style={{ font: "bold 15px / 15px roboto" }}
                      >{` ${value.sub_category_name}`}</Typography>
                    }
                  />
                </ListItem>
              ))}
            {/* {loading && <div> Loading ....</div>}
            {error && <div>{feedsErrorMsg}</div>}
            <div ref={loader} /> */}
          </List>
        )}
      <div>
        {shouldDisplayFeeds && (
          <CategoriesFeeds
            // setCategoryType={setCategoryType}
            // setCategoryName={setCategoryName}
            // setFeedsList={setFeedsList}
            // setCategoryDistList={setCategoryDistList}
            // setShouldDisplayCategoryDist={setShouldDisplayCategoryDist}
            // setShouldDisplayFeeds={setShouldDisplayFeeds}
            setUpdateCategoryUnreadCount={setUpdateCategoryUnreadCount}
            categoryId={categoryId}
            categoryType={categoryType}
            categoryName={categoryName}
            user_verification_info={user_verification_info}
            profile_pic={profile_pic}
            channels={channels}
            categoryNameLabel={categoryNameLabel}
            changeHeader={changeHeader}
          ></CategoriesFeeds>
        )}
      </div>
      <div>
        {displaySubCategoryFeeds && (
          <SubCategoriesFeeds
            categoryId={categoryId}
            categoryType={categoryType}
            categoryName={categoryName}
            user_verification_info={user_verification_info}
            profile_pic={profile_pic}
            channels={channels}
            categoryNameLabel={subCategoryLabelName}
            subCategoryId={subCategoryId}
            subCategoryName={subCategoryName}
            changeHeader={changeHeader}
            setUpdateSubCategoryUnreadCount={setUpdateSubCategoryUnreadCount}
          ></SubCategoriesFeeds>
        )}
      </div>
      <div>
        {shouldDisplayCategoryDist && (
          <DistributionCategories
            user_verification_info={user_verification_info}
            profile_pic={profile_pic}
            channels={channels}
            categoryId={categoryId}
            categoryType={categoryType}
            categoryName={categoryName}
            categoryNameLabel={categoryNameLabel}
            setUpdateCategoryUnreadCount={setUpdateCategoryUnreadCount}
            changeHeader={true}
            // categoryDistList={categoryDistList}
          ></DistributionCategories>
        )}
      </div>
    </div>
  );
};

export default SubCategoryList;
