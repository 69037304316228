import { useState, useEffect, useRef, useCallback } from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import React from "react";
import Progressbar from "../progressbar";
import { Link, useParams, useHistory } from "react-router-dom";
import awards from "../assets/new_profile_icons/awards.svg";
import ic_award from "../assets/new_profile_icons/ic_award.svg";
import { useForm, Controller, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import Select from "react-select";
import { MdEdit } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";
import deleteIcon from "../assets/Delete-1X.png";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { makeStyles } from "@material-ui/core/styles";
import { MdArrowBackIos } from "react-icons/md";
import Typography from "@mui/material/Typography";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { IoArrowBackOutline } from "react-icons/io5";
import { IoIosCloseCircle } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import "../profile/profile.css";
import { Modal } from "react-responsive-modal";
import doneImage from "../assets/Web Icons/Icon material-done.svg";

const useStyles = makeStyles({
  smallRadioButton: {
    "& svg": {
      width: "1.5em",
      height: "1.5em",
    },
  },
});
const useStylesLabel = makeStyles((theme) => ({
  label: {
    fontSize: "1.5rem !important",
  },
}));
const styles = makeStyles((theme) => ({
  radio: {
    colorPrimary: {
      "&$checked": {
        color: "blue",
      },
    },
    checked: {},
  },
}));
const MobileAwards = ({
}) => {
  const {
    submitMandatoryInfo,
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm();

  const { user } = useParams();
  //console.log("data"+setCategoryListInfo)

  const history = useHistory();
  const user_id = getDocId();
  const headers = getHeaders();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  //////////////////////////////
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const [otherUser, setOtherUser] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const classes = useStyles();
  const classesLabel = useStylesLabel();
  const classesRadio = styles;
  const [years, setYears] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState("print");
  const [userInfo, setUserInfo] = useState([]);
  const [editClicked, setEditClicked] = useState(false);
  const [editedData, setEditedData] = useState();
  const [deletedData, setDeletedData] = useState();
  const closeIconHide = <img src={close} ></img>;
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const [openPostSuccessView, setOpenPostSuccessView] = useState(false);
  const [updatePubModal, setUpdatePubModal] = useState(false);
  const closeUpdatePubModal = () => {
      setUpdatePubModal(false);
  };
  
  const showUpdatePubModal = (data) => {  
      setState({ ...state, ["bottom"]: false });   
      setUpdatePubModal(true);
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if(user == "true"){
      setOtherUser(true);
    }else{
      setOtherUser(false);
    }
    getUserInfo();
    autoSuggestions();
  }, []);

  const getUserInfo = async () => {
    setloaderSpinner(true);
    const payload = {
      user_id,
    };
    const res = await fetch(baseUrl + "v1/userProfile/view", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();

    if (data.status === "success") {
      setUserInfo(data.data);
      setloaderSpinner(false);
      //setDefaultFormValue(data.data);
    }
  };

  const autoSuggestions = async () => {
    const payload = { journals: "journals" };

    const res = await fetch(baseUrl + "v2/autoSuggestions", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let journals = [];

    if (data.status === "success") {
      for (const city of data.data.journals) {
        journals.push({ value: city, label: city });
      }
    }
    let minOffset = 0, maxOffset = 122;
    let thisYear = (new Date()).getFullYear();
    let allYears = [];
    for(let x = 0; x <= maxOffset; x++) {
        allYears.push({ value: thisYear - x, label: thisYear - x })
    }
    setYears(allYears);
  };
  const editAwardPop = (e, data) => {  
    console.log(data)  
    setValue("title", data?.title);
    setValue("presented", data?.presented_at);  
    if(data.year != undefined){
      setValue("year", {
        label: data?.year,
        value: data?.year,
      });
    }else{            
      setValue("year", '');
    }
    setEditClicked(true);
    setEditedData(data);
    toggleDrawer(e, "bottom", true);
  };
  
  const hideSuccessView = () => {
    setValue("year",'');
    setDeletedData('');
    setOpenPostSuccessView(false);
  };
  const deleteAwardPop = (event, data) => {
    setOpenPostSuccessView(true);
    setDeletedData(data);
  };
  
  const toggleDrawer = (event, anchor, open) => {
    if(!open){
      setSelectedValue("print"); 
      reset();
      setEditClicked(false); 
      setDeletedData('');
      setEditedData('');
      setValue("year",'');
    }   
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    >
      <AppBar
        position="static"
        style={{
          backgroundColor: "white",
          color: "black",
          marginTop: "0px",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <Typography
            style={{
              font: "bold 20px / 20px roboto",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          ></Typography>
          <IconButton
            style={{ marginLeft: "92%" }}
            onClick={(e) => toggleDrawer(e, anchor, false)}
          >
            <CancelOutlinedIcon fontSize="large" />
          </IconButton>
        </Toolbar>
        <form>
        <div  style={{  padding: "1em" }}>
          <div
            style={{
              marginTop: "1em",
              marginBottom: "0em",
              background: "#ffffff",
              borderRadius: "9px",
              height: "3em",
              border: "1px solid grey",
              width: "100%",
            }}
          >
            <div style={{ paddingLeft: "10px",marginTop:"10px" }}>
              <input
                style={{
                  background: "inherit",
                  width: "100%",
                  border: "none",
                }}
                type="text"
                {...register("title", {
                  required: true,
                  maxLength:500,
                  pattern: /^[''{}&. a-zA-Z_-]*$/,
                })}
                placeholder="Award Title *"
              />
            </div>
          </div>
          {errors.title?.type === "required" && (
            <div className="row error-title" style={{padding:"0px"}}>
              <span className="error-title-text">
                Please enter the title
              </span>
            </div>
          )}
          {errors.title?.type === "maxLength" && (
            <div className="row error-title" style={{padding:"0px"}}>
              <span className="error-title-text">
              Please enter less than 500 characters
              </span>
            </div>
          )}
          
          {errors.title?.type === "pattern" && (
              <div className="row error-title" style={{padding:"0px"}}>
                <span className="error-title-text" >
                Only alpha characters .,''{}& or - are allowed
                </span>
              </div>
            )}
          <div
            style={{
              marginTop: "1em",
              marginBottom: "0em",
              background: "#ffffff",
              borderRadius: "9px",
              height: "3em",
              border: "1px solid grey",
              width: "100%",
            }}
          >
            <div style={{ paddingLeft: "10px",marginTop:"10px" }}>
              <input
                style={{
                  background: "inherit",
                  width: "100%",
                  border: "none",
                }}
                type="text"
                {...register("presented", {
                  maxLength:100,
                  pattern: /^[''{}&. a-zA-Z_-]*$/,
                })}
                placeholder="Presented At"
              />
            </div>
          </div>
          {errors.presented?.type === "pattern" && (
              <div className="row error-title" style={{padding:"0px"}}>
                <span className="error-title-text" >
                Only alpha characters .,''{}& or - are allowed
                </span>
              </div>
            )}
          {errors.presented?.type === "maxLength" && (
              <div className="row error-title" style={{padding:"0px"}}>
              <span className="error-title-text">
                Please enter less than 100 characters
              </span>
              </div>
            )}
          <div>
            <div
              style={{
                borderRadius: "9px",
                marginTop: "1em",
                marginBottom: "0em",
                background: "#ffffff",
                borderRadius: "9px",
                height: "3em",
                border: "1px solid grey",
                width: "100%",
              }}
            >
              <div
                style={{
                  height: "100%",
                  paddingLeft: "0px",
                  marginTop: "-5px",
                }}
              >
                <Controller
                  name="year"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={years}
                      placeholder="Year"
                      styles={{
                        control: (style) => ({
                          ...style,
                          background: "inherit",
                          padding: "10px 10px 10px 0",
                          border: 0,
                          boxShadow: "none",
                        }),
                        valueContainer: (style) => ({
                          ...style,
                          padding: 2,
                        }),
                        placeholder: (style) => ({
                          ...style,
                          fontSize: "13px",
                        }),
                        container: (style) => ({
                          ...style,
                          width: "100%",
                        }),
                        menuList: (style) => ({
                          ...style,
                          overflowY: "scroll",
                        }),
                      }}
                    />
                  )}
                ></Controller>
              </div>
            </div>
          </div>
        </div>
        </form>
        
        {!editClicked &&<div style={{ padding: "1em" }}><button
            type="submit"
            style={{
              backgroundColor: "#469b8d",
              height: "3em",
              width: "100%",
              margintop: "5em",
            }}
            className="post-modal-submit-button"
            onClick={handleSubmit(saveAwards)}
          >
            Add Awards
          </button></div>}
        {/* {!editClicked && <div style={{ margin: "70px 30px" }}>
          <button type="submit" className="profile-save-button" onClick={handleSubmit(savePublication)} >
            Save
          </button>
          <button type="submit" className="profile-close-button" onClick={(e) => toggleDrawer(e, anchor, false)}>
            Close
          </button>
        </div>} */}
        {editClicked && <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between",padding: "1em" }}>         
          <button type="submit" className="pub-close-button" onClick={(e) => toggleDrawer(e, anchor, false)} style={{marginLeft:"0em"}}>
            Cancel
          </button>
          <button type="submit" className="pub-save-button" onClick={handleSubmit(showUpdatePubModal)}>
            Update Awards
          </button>
        </div>}
      </AppBar>
    </Box>
  );

  const deleteAward = async () => {
    console.log(editedData);
    setloaderSpinner(true); 
    let award={
      award_id:deletedData.award_id,
      type: "award"
    }
    let payload = {
      user_id: user_id,
    };
    payload.award_history = [award];
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/delete", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {     
      setloaderSpinner(false);
      setOpenPostSuccessView(false);
      toast.success('Award deleted');
      setEditClicked(false); 
      setDeletedData('');
      setEditedData('');
      reset();
      getUserInfo();
    } else {
      setloaderSpinner(false);
      setOpenPostSuccessView(false);
      toast.error(data.errorMsg);
    }
  };


  const saveAwards = async (post) => {
    console.log(post);
    setloaderSpinner(true);    
    let award={
      presented_at: post?.presented,
      title: post?.title,
      year: post.year == undefined?'':post?.year?.value,
      type: "award"
    }
    let payload = {
      user_id: user_id,
    };
    payload.award_history = [award];
    payload = encodeURIComponent(JSON.stringify(payload));
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/create", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      setValue("year",'');
      setEditClicked(false); 
      setDeletedData('');
      setEditedData('');
      toast.success('Award saved');
      setState({ ...state, ["bottom"]: false });
      reset();
      setloaderSpinner(false);
      getUserInfo();
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };
  
  const updateAward = async (post) => {
    console.log(post);
    setloaderSpinner(true);   
    let award={
      award_id:editedData.award_id,
      presented_at: post?.presented,
      title: post?.title,
      year: post.year == undefined?'':post?.year?.value,
      type: "award"
    }
    let payload = {
      user_id: user_id,
    };
    payload.award_history = [award];
    payload = encodeURIComponent(JSON.stringify(payload));
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/update", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {  
      setValue("year",'');
      setUpdatePubModal(false);
      reset(); 
      setEditClicked(false); 
      setDeletedData('');
      setEditedData('');
      setState({ ...state, ["bottom"]: false });   
      toast.success('Profile updated');
      setSelectedValue("print"); 
      setloaderSpinner(false);
      getUserInfo();
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };
  //toggleDrawer(e, "bottom", true);
///ebiz-web/app/m/v1/userProfile/delete
///ebiz-web/app/m/v1/userProfile/Update
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "white",
          zIndex: 10,
          // overflow: "auto",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100vw",
        }}
      >
        <div
          style={{
            position: "-webkit-sticky",
            position: "relative",
            top: "0",
            left: "0",
            width: "100%",
            zIndex: "25",
            flexShrink: "0",
            marginBottom: "10px",
          }}
          className="mobile-header-wrapper sticky d-block d-lg-none"
        >
          <div
            className="mobile-header position-relative "
            style={{
              backgroundColor: "#469b8d",
              font: "bold 20px / 20px roboto",
              textAlign: "center",
            }}
          >
            <div className="mobile-responsive">
              <div className="brand-logo " style={{ width: "106%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0 1em",
                  }}
                >
                  <MdArrowBackIos
                    color="white"
                    size={25}
                    onClick={() => history.goBack()}
                  />
                  <h2 style={{ color: "white", marginLeft: "1.5em" }}>
                  Awards
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!loaderSpinner && <div
          className="conatctDetails"
          style={{ height: "80%", overflow: "auto" }}
        >
          <div>
            {!(
              (!userInfo.award_history ||
                userInfo?.award_history?.length === 0)
            ) ? (
              <Typography>
                {userInfo.award_history
                  ? userInfo.award_history.map((pub, key) => (
                    <div key={key}>
                                                    <div className="line"></div>
                                                    <div
                                                      style={{
                                                        padding: "0px",
                                                        marginLeft: "17px",
                                                      }}
                                                    >
                                                      <p className="print-head">
                                                        <img
                                                          src={ic_award}
                                                          className="print-img"
                                                          style={{
                                                            width: "35px"
                                                          }}
                                                        ></img>{" "}
                                                      <span
                                                        style={{
                                                          color:"rgb(30, 138, 121)",
                                                          font:"normal normal bold 16px/16px Roboto",
                                                          display: "block",
                                                          marginTop: "-45px",
                                                          marginLeft: "45px"
                                                        }}> {pub.title}</span> 
                                                      </p>
                                                      <div className="author-title" style={{
                                                          marginLeft:"45px",
                                                          marginTop:"15px",
                                                          marginBottom:"10px",
                                                        }}>
                                                        {pub.presented_at}
                                                      </div>
                                                      <div
                                                        style={{
                                                          color:"black",
                                                          font:"normal normal bold 16px/16px Roboto",
                                                          marginLeft:"45px",
                                                        }}
                                                      >
                                                        {pub.year}
                                                      </div>
                                                      
                                                      {!otherUser &&  <div>
                            <p
                              className="print-head edit-delete"
                            >
                              
                            <div
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  color: "red",
                                }}
                                onClick={(e) => deleteAwardPop(e,pub)}
                              >
                                <MdDeleteForever size={25} />
                                </div>
                              <div
                                style={{
                                  height: "25px",
                                  width: "65px",
                                  marginLeft: "30px",
                                  fontSize: "16px",
                                }}
                                onClick={(e) =>{ editAwardPop(e,pub)}}
                              >
                                <MdEdit size={18} />
                                Edit
                              </div>
                            </p>
                          </div>}
                                                      <hr
                                                        style={{
                                                          marginTop: "15px",
                                                          marginBottom: "15px",
                                                        }}
                                                      ></hr>
                                                    </div>
                                                  </div>
                      
                    ))
                  : ""}
              </Typography>
            ) : (
              <div style={{ marginBottom: "30%" }}>
                <img src={awards} className="mPub"></img>
                <div className="pub-head">
                  <p>Show your awards to all </p>
                </div>
              </div>
            )}
          </div>
        </div>}

        <div>
          {" "}
          <Progressbar
            show={loaderSpinner}
            imageUrl={url}
            height="90"
            width="90"
            alignment="middle"
            alttext="Loading..."
          />
        </div>
        {!otherUser &&  <div
          className="post-modal-submit"
          style={{ marginBottom: 0, height: "6em" }}
        >
          <button
            type="submit"
            style={{
              backgroundColor: "#469b8d",
              height: "3.5em",
              width: "20em",
            }}
            onClick={(e) => {
              toggleDrawer(e, "bottom", true);
            }}
            className="post-modal-submit-button"
          >
            Add Awards
          </button>
          <SwipeableDrawer
            anchor="bottom"
            open={state["bottom"]}
            onClose={(e) => toggleDrawer(e, "bottom", false)}
            onOpen={(e) => toggleDrawer(e, "bottom", true)}
          >
            {list("bottom")}
          </SwipeableDrawer>
        </div>}
      </div>
      <Modal
          open={updatePubModal}
          closeModal={closeUpdatePubModal}
          showCloseIcon={true}
          onClose={closeUpdatePubModal}
          classNames={{ modal: "edit-delete-model" }}
          styles={{ modal: { padding: 0, overflow: "hidden",backgroundColor:"white" } }}
        >
          <div className="container-fluid">
          <div
            className="row"
            style={{marginTop:"30px" }}
          >
            <div className="col-sm-12 " style={{marginTop:'35px'}}>
            <div
                style={{
                  color: "black",
                  font: "20px/20px roboto",
                  marginBottom: "30px",
                  fontWeight:"bold"
                }}
              >
                Update Award
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "grey",
                  marginBottom: "10px",
                  font: "18px/18px roboto",
                }}
              >
                {" "}
                <p>Are  you sure want to</p>
                <p>Update your award  entry?</p>
              </div>
            </div>
            <div className="col-sm-12 " >
              
              <div style={{ margin: "30px 0px" }}>         
              <button type="submit" className="pub-close-button"  onClick={closeUpdatePubModal} style={{width:"7em",height:"2.5em"}}>
            Cancel
          </button>
          <button type="submit" className="pub-save-button" onClick={handleSubmit(updateAward)} style={{width:"7em",height:"2.5em"}}>
            Update 
          </button>
          
        </div>
            </div>
          </div>
        </div> 
        </Modal>
      <Modal
        open={openPostSuccessView}
        blockScroll={true}
        onClose={hideSuccessView}
        closeIcon={closeIconHide}
        closeModal={hideSuccessView}
        classNames={{ modal: "edit-delete-model" }}
        styles={{ modal: { backgroundColor:"white" } }}
      >
        <div className="container-fluid">
          <div
            className="row"
            style={{marginTop:"40px" }}
          >
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "black",
                  font: "20px/20px roboto",
                  marginBottom: "30px",
                  fontWeight:"bold"
                }}
              >
                Delete Award
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "grey",
                  marginBottom: "10px",
                  font: "18px/18px roboto",
                  fontWeight:"600px"
                }}
              >
                {" "}
                <p>Do you want delete</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "black",
                  marginBottom: "10px",
                  font: "16px/16px roboto",
                  fontWeight:"bold"
                }}
              >
                {" "}
                <p style={{display: "inline-block",
                    width: "300px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"}}>{deletedData?.title}</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "grey",
                  marginBottom: "10px",
                  font: "18px/18px roboto",
                }}
              >
                {" "}
                <p>Award Entry?</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              
              <div style={{ margin: "20px -15px" }}>         
          <button type="submit" className="pub-close-button"  onClick={(e) => hideSuccessView(e)}>
            Cancel
          </button>
          <button type="submit" className="pub-save-button" style={{backgroundColor:"#FF2F00",marginLeft:"20px"}} onClick={() => deleteAward()}>
            Delete 
          </button>
        </div>
            </div>
          </div>
        </div>
      </Modal> 
    </>
  );
};

export default MobileAwards;
