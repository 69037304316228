import {useState, useEffect, useRef, useCallback} from 'react';
import * as React from 'react';
import { config, getDocId, getHeaders } from "../utils/utils";
import AppBar from "@material-ui/core/AppBar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import Badge from '@mui/material/Badge';
import Progressbar from "../progressbar";
import "./Channels.css";
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SearchOffIcon from '@mui/icons-material/SearchOff';
import useSearchFeedLoader from '../dashboard/hooks/useSearchFeedLoader';
import ChannelMembersList from "../channels/ChannelMembersList";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import toast from "react-hot-toast";
const ChannelMembers = ({selectedChannel}) => {
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const doc_id = getDocId();
  const headers = getHeaders();
  const [membersList, setMembersList] = useState();
  const [deptInfoList, setDeptInfoList] = useState([]);
  const [communityMemberCount, setCommunityMemberCount] = useState();
  const [displaySearchMember, setDisplaySearchMember] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [channelId, setChannelId] = useState('');
  const [deptId, setDeptId] = useState('');
  const [categoryNameLabel, setCategoryNameLabel] = useState('Search ');
  const [selectedDept, setSelectedDept] = useState();
  const [loaderSpinner, setloaderSpinner] = useState(true);
  const feedsErrorMsg = "Could not load Feeds";
  const [nextFeedsData, setNextFeedsData] = useState({
    pageNum: 0,
  
  });
  const loading = true;
  const  error = false;

  useEffect(() => {
    
    getChannelDeptInfo();      
    }, []); 

const getChannelDeptInfo = async () => {
  setloaderSpinner(true);
  const payload = {
    channel_id: selectedChannel.channel_id,
    doc_id: doc_id
  }
  const res = await fetch(baseUrl + "v2/community/deptInfo", {
    method: "POST",
    headers,
    body: "reqData=" + JSON.stringify(payload),
  }).catch((e) => {
    setloaderSpinner(false);
  });
  const data = await res.json();
  setloaderSpinner(false);
  if (data.status === "success") {
    setDisplaySearchMember(false);
    setCommunityMemberCount(data.community_member_count);
    setDeptInfoList(data?.data)
  }
};
const getMemberList = async (e,sendData) => {
  e.stopPropagation();
  setSelectedDept(sendData);
  setDisplaySearchMember(true);
  // const payload = {
  //   channel_id: selectedChannel.channel_id,
  //   department_id:sendData.department_id,
  //   doc_id: doc_id,
  //   last_member_id:0
  // }
  // const res = await fetch(baseUrl + "v2/community/memberInfo", {
  //   method: "POST",
  //   headers,
  //   body: "reqData=" + JSON.stringify(payload),
  // }).catch((e) => {
  // });
  // const data = await res.json();
  // if (data.status === "success") {   
  //   setMembersList([]);
  //   if (data.data && data.data.length > 0) {   
  //     setCategoryNameLabel('Search '+sendData.department_name);
  //     setChannelId(selectedChannel.channel_id);
  //     setDeptId(sendData.department_id);
  //     setDisplaySearchMember(true);
  //     setMembersList((prevFeeds) => {
  //       return [...new Set([...prevFeeds, ...data?.data])];
  //     });
  //   }
  // }
};
const [feedsList, setFeedsList] = useState([]);
  // useEffect(() => {}, [feedsList]);
  // const { loading, error, hasMore } = useSearchFeedLoader(
  //   doc_id,
  //   nextFeedsData,
  //   channelId,
  //   deptId,
  //   setMembersList,
  //   searchValue

  // );
  
  const searchClick = async (key) => {
    if(searchValue == ''){
      toast.error("Please provide a search keyword");
      return;
    }
    console.log(key);
    const payload = {
      category_type: channelId,
      category_id: deptId,
      pg_num: 0,
      filter: { search_value: searchValue },
    };
    const res = await fetch(baseUrl + "v2/search/feeds", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();

    if (data.status === "success") setFeedsList([]);
    if (data.data?.feed_data && data.data?.feed_data?.length > 0) {
      setFeedsList((prevFeeds) => {
        return [...new Set([...prevFeeds, ...data.data?.feed_data])];
      });
    }
  };
  const searchClear = async () => {
    setSearchValue("");
    setNextFeedsData(() => ({
      pageNum: 0,
    }));
  };
    return (
      <div style={{marginBottom:'50px'}}>
        
        {loading && (
              <div>
                <Progressbar
                  show={loaderSpinner}
                  imageUrl={url}
                  height="90"
                  width="90"
                  alignment="middle"
                  alttext="Loading..."
                />
              </div>
            )}
            {error && <div>{feedsErrorMsg}</div>}
      {!displaySearchMember && deptInfoList.length !== 0  &&  <div>         
            <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper', padding: '10px' }}>
              
              <div style={{textAlign:"center",font:"bold 15px / 15px roboto",marginBottom:"14px",fontFamily:"serif"}}>{communityMemberCount} Members</div>
            {deptInfoList.map((deptInfo) =>
            <ListItem onClick={(e) => {getMemberList(e,deptInfo)}}  style={{ borderTop: "1px solid #e7e5e5", borderBottom: "1px solid #e7e5e5",cursor:"pointer" }}
               
                disableGutters
                secondaryAction={
                    <Badge badgeContent={deptInfo.department_member_count} max={100000}  sx={{
                        "& .MuiBadge-badge": {
                          color: "black",
                          backgroundColor: "whitesmoke",
                          marginRight: "20px",
                          fontSize: "12px"
                        }
                      }}>                   
                  </Badge>              
                } >
                <div>
               <p className="member-title">{deptInfo.department_name}</p>
                </div>
                
            </ListItem>
            
          )}
          
    </List> 
    
        </div>}
        
      {displaySearchMember &&  <div>    
        <ChannelMembersList selectedChannel={selectedChannel} selectedDept={selectedDept}/>  
        </div>}
      {/* {displaySearchMember && membersList.length !== 0  &&  <div>         
            <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper', padding: '10px' }}>
            <div >
                
                <AppBar className="mL"  position="sticky"
                        style={{ backgroundColor: "white", color: "black",marginBottom: '15px', top: '55px', display: 'flex', borderRadius: "6px",margin:"10px 0px",height:"40px",padding:"10px"}}>
                      <InputBase style={{fontSize: "15px",marginTop: "-3px"}}
                      placeholder={categoryNameLabel}
                      type="text"  variant="outlined" fullWidth size="small"
                      onChange={(e) => setSearchValue(e.target.value)}
                      value={searchValue}
                       />
                        <IconButton aria-label="search"
                        style={{ fontSize: '40px',marginLeft:'86%',position:"absolute",top:"5px" }}>
                          <SearchIcon onClick={(event)=>searchClick(event.target.value)}/>
                        </IconButton>
                        {searchValue && <IconButton style={{ fontSize: '40px',marginLeft:'90%',position:"absolute",top:"5px" }}
                                onClick={() => searchClear()} >
                                <CancelRoundedIcon />
                        </IconButton>}
                </AppBar>
                </div>
            {membersList.map((member) =>
            <ListItem   style={{ borderTop: "1px solid #e7e5e5", borderBottom: "1px solid #e7e5e5" }} >
              <ListItemAvatar>
                <Avatar alt="profile pic" src={member.profile_pic_name} />
                <img
            alt="profile pic"
            src={member.profile_pic_name}
          />
              </ListItemAvatar>
        <ListItemText
          primary={member.full_name}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {member.speciality}
              </Typography>
              <br></br>{member.workplace}
            </React.Fragment>
            
          }
        />
              
              
                {/* <div>
                <ListItemButton>
              <ListItemIcon>
              <img
            className="feed-header-image"
            alt="profile pic"
            src={member.profile_pic_name}
          />
              </ListItemIcon>
              <ListItemText primary={member.full_name} secondary={member.designation} secondary={member.designation}/>
            </ListItemButton>
               <p className="member-title">{member.location}</p>
                </div> */}
                
            {/* </ListItem>
          )}
          
          {loading && <div> Loading ....</div>}
            {error && <div>{feedsErrorMsg}</div>}
            <div ref={loader} />
    </List> 
     </div>} */} 
        </div>
    )
}

export default ChannelMembers
