
import { useState, useEffect, useRef, useCallback } from 'react';
import ad from "../assets/ad.png";
import addsMobileIcon from "../assets/dashboard_icons/Group 2777.png";
const playstore = "../../assets/images/playstore.png";
const appstore = "../../assets/images/appstore.png";
const DahboardRightside = () => {
  const [urlPlay, setUrlPlay] = useState(
    "https://play.google.com/store/apps/details?id=com.vam.whitecoats"
  );
  const [urlApp, setUrlApp] = useState(
    "https://apps.apple.com/in/app/whitecoats/id1060971730"
  );
  return (
    <aside
      className="widget-area sticky-top"
      style={{ top: "80px", zIndex: "1" }}
    >
      <div className="">
        <div className="widget-body">
          <div className="add-thumb">
            <img
              src={ad}
              alt="ad"
              useMap="#download"
              height="234"
              width="270"
            />
            <map name="#download">
              <area
                coords="12,200,95,226"
                target="_blank"
                alt="IOS"
                href="https://apps.apple.com/in/app/whitecoats/id1060971730"
              />
              <area
                coords="102,200,185,226"
                target="_blank"
                alt="Android"
                href="https://play.google.com/store/apps/details?id=com.vam.whitecoats"
              />
            </map>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default DahboardRightside;
