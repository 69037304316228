import { useState, useRef, useEffect } from "react";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import certificate from "../assets/dashboard_icons/Group 948.png";
import { Modal } from "react-responsive-modal";
import { config, getDocId, getHeaders } from "../utils/utils";
import toast from "react-hot-toast";
import S3 from "react-aws-s3";
import "./common.css";
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Document, Page } from "react-pdf";
import { GrPrevious, GrNext } from "react-icons/gr";
import getWindowDimensions from "./hooks/getWindowDimensions";
import { pdfjs } from "react-pdf";
import Progressbar from "../progressbar";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const MciUpload = ({ closeUploadMci, openSuccessModal }) => {
  const url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const doc_id = getDocId();
  const headers = getHeaders();

  const fileInput = useRef(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState("");
  const [imgPreviewType, setImgPreviewType] = useState("");
  // const [successModal, setSuccessModal] = useState(false);
  // const openSuccessModal = () => setSuccessModal(true);
  // const closeSuccessModal = () => setSuccessModal(false);
  const [imgPreviewModal, setImgPreviewModal] = useState(false);
  const openImgPreviewModal = () => setImgPreviewModal(true);
  const closeImgPreviewModal = () => setImgPreviewModal(false);

  const [loaderSpinner, setloaderSpinner] = useState(false);
  // const [showPdfNavigator, setShowPdfNavigator] = useState(false);

  const [pdfTotalPages, setPdfTotalPages] = useState(null);
  const [pdfPageNum, setPdfPageNum] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPdfTotalPages(numPages);
    setPdfPageNum(1);
  };

  const closeIcon = <img src={close}></img>;

  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const deleteUploadedImage = (e) => {
    e.preventDefault();

    fileInput.current.value = "";
    setImgPreviewUrl("");
    setIsFileUploaded(false);
  };

  const reUploadImage = (e) => {
    deleteUploadedImage(e);
    fileInput.current.click();
  };

  const showImagePreview = () => {
    let type = fileInput.current.files[0].type;
    if (type.includes("image")) type = "image";
    else if (type.includes("video")) type = "video";
    else if (type.includes("audio")) type = "audio";
    else if (type.includes("pdf")) type = "pdf";

    if (type === "image" || type === "pdf") {
      setIsFileUploaded(true);
      setImgPreviewType(type);
      setImgPreviewUrl(URL.createObjectURL(fileInput.current.files[0]));
    } else {
      toast.error("Only images and pdfs are allowed");
    }
  };

  const saveFile = async (url, doc_id) => {
    const payload = {
      doc_id,
      attachment_original_url: url,
    };

    const res = await fetch(baseUrl + "v2/upload", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
      return;
    });

    const data = await res.json();

    if (data.status === "success") {
      setloaderSpinner(false);
      closeUploadMci();
      openSuccessModal();
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };
  const uploadFile = async (e, fileInput, doc_id) => {
    e.preventDefault();
    setloaderSpinner(true);
    let file = fileInput.current.files[0];
    let newFileName = fileInput.current.files[0].name;

    const ReactS3Client = new S3(config);
    const data = await ReactS3Client.uploadFile(file, newFileName);

    saveFile(data.location, doc_id);
  };

  return (
    <>
      <div style={{ marginTop: "15px" }}></div>
      <div className="verifaction">
        <p className="upload-title">List Of ID Proofs</p>
        <p className="upload-sub-title">
          Provide a valid professional ID proof to verify as a doctor and access
          complete WhiteCoats features
        </p>
      </div>
      <div className="verifaction-title">
        <p className="upload-sub-title">
          Attach a copy of any of the ID's below to verify your Profile:
        </p>
        <p className="upload-sub-title">1. Student ID Card</p>
        <p className="upload-sub-title">2. Hospital/Corporate ID Card</p>
        <p className="upload-sub-title">3. Medical Association ID Card</p>
        <p className="upload-sub-title">4. Visiting Card</p>
        <p className="upload-sub-title">5. Prescription</p>
        <p className="upload-sub-title">
          6. MCI Issued Registration Certificate
        </p>
        <p className="upload-sub-title">7. Degree Certificate</p>
        <div
          className="form-group"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            style={{
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              width: "80%",
            }}
          >
            {imgPreviewUrl === "" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={certificate} />
              </div>
            ) : (
              <>
                <div
                  style={{
                    backgroundColor: "rgba(255,255,255,0.6)",
                    width: "100%",
                    height: "13em",
                    // borderRadius: "10px",
                    border: "1px solid black",
                    borderBottom: "0px",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {imgPreviewType === "image" && (
                    <img
                      alt="Mci card preview"
                      src={imgPreviewUrl}
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: "15px 15px 0px 15px",
                      }}
                    />
                  )}
                  {imgPreviewType === "pdf" && (
                    // <embed
                    //   alt="Mci card preview"
                    //   src={imgPreviewUrl}
                    //   style={{
                    //     width: "100%",
                    //     height: "100%",
                    //     padding: "15px 15px 0px 15px",
                    //   }}
                    // />
                    <Document
                      file={fileInput.current.files[0]}
                      onLoadSuccess={onDocumentLoadSuccess}
                      onLoadError={console.error}
                    >
                      <Page
                        width={windowDimensions.width > 728 ? "330" : "255"}
                        pageNumber={1}
                      />
                    </Document>
                  )}
                </div>
                <div
                  style={{
                    backgroundColor: "#F6F6F6",
                    borderRadius: "0px 0px 5px 5px",
                    height: "50px",
                    width: "100%",
                    display: "flex",
                    // alignContent: "center",
                    justifyContent: "space-around",
                    zIndex: "3",
                    border: "1px solid black",
                  }}
                >
                  <button
                    style={{ color: "#F11B1B" }}
                    onClick={(e) => deleteUploadedImage(e)}
                  >
                    Delete
                  </button>
                  <button
                    style={{
                      color: "#B8AC0A",
                      //  marginLeft: "2em"
                    }}
                    onClick={(e) => reUploadImage(e)}
                  >
                    Reupload
                  </button>
                  <button
                    style={{
                      color: "#1B8C7A",
                      // marginLeft: "2em",
                      // marginRight: "2em",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      openImgPreviewModal();
                    }}
                  >
                    Preview
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {!isFileUploaded && (
        <div className="button-container">
          <button
            style={{
              backgroundColor: "#0FB59B",
              width: "300px",
              borderRadius: "9px",
            }}
            className="submit-btn"
            onClick={(e) => {
              e.preventDefault();
              fileInput.current.click();
            }}
          >
            {" "}
            Attach Your file
          </button>
        </div>
      )}
      <input
        type="file"
        accept="image/jpeg,image/png,application/pdf"
        onChange={showImagePreview}
        ref={fileInput}
        style={{
          display: "none",
        }}
        className="submit-btn"
      />

      <div
        style={{
          margin: "1em 0",
          // width: "30%",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isFileUploaded && !loaderSpinner && (
          <button
            onClick={(e) => uploadFile(e, fileInput, doc_id)}
            style={{
              backgroundColor: "#0FB59B",
              width: "30%",
              borderRadius: "9px",
              margin: "0",
            }}
            className="submit-btn"
          >
            Submit
          </button>
        )}
        <Progressbar
          show={loaderSpinner}
          imageUrl={url}
          height="90"
          width="90"
          alignment="middle"
          alttext="Loading..."
        />
      </div>

      <Modal
        open={imgPreviewModal}
        onClose={closeImgPreviewModal}
        center
        closeIcon={closeIcon}
      >
        {imgPreviewType === "image" && (
          <img src={imgPreviewUrl} style={{ height: "100%", width: "100%" }} />
        )}
        {imgPreviewType === "pdf" && (
          <div className="mci-pdf-preview">
            <Document
              file={imgPreviewUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <div
                className="pdf-page-container"
                // onMouseEnter={() => setShowPdfNavigator(true)}
                // onMouseLeave={() => setShowPdfNavigator(false)}
              >
                <Page
                  width={windowDimensions.width > 728 ? "445" : "290"}
                  pageNumber={pdfPageNum}
                />
                <div
                  className="pdf-navigator"
                  // style={{ opacity: showPdfNavigator ? 1 : 0 }}
                >
                  <GrPrevious
                    style={{
                      height: "0.7em",
                      visibility: pdfPageNum > 1 ? "visible" : "hidden",
                    }}
                    onClick={() =>
                      setPdfPageNum((prev) => {
                        if (prev > 1) return prev - 1;
                        else return prev;
                      })
                    }
                  />
                  {pdfPageNum} of {pdfTotalPages}
                  <GrNext
                    style={{
                      height: "0.7em",
                      visibility:
                        pdfPageNum < pdfTotalPages ? "visible" : "hidden",
                    }}
                    onClick={() =>
                      setPdfPageNum((prev) => {
                        if (prev < pdfTotalPages) return prev + 1;
                        else return prev;
                      })
                    }
                  />
                </div>
              </div>
            </Document>
          </div>
        )}
      </Modal>

      {/* <Modal
        closeIcon={closeIcon}
        open={successModal}
        onClose={closeSuccessModal}
        classNames={{ modal: "verificationModel" }}
      >
        <div style={{ marginTop: "15px" }}></div>
        <div className="verifaction">
          <p className="verification-title" style={{ color: "Green" }}>
            Congratulation
          </p>
          <p className="verification-sub-title">
            Your certificate has been upload successfully
          </p>
          <p className="verification-sub-title">
            We will verify your certificate and validate your profile
          </p>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={closeSuccessModal}
            style={{
              backgroundColor: "#ffffff",
              border: "1px solid gray",
              color: "black",
              padding: "11px",
              width: "150px",
              fontsize: "11px",
              marginBottom: "10px",
              borderRadius: "9px",
            }}
            className="submit-btn"
          >
            Close
          </button>
        </div>
      </Modal> */}
    </>
  );
};

export default MciUpload;
