import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  createContext,
} from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { config, getDocId, getHeaders } from "../utils/utils";
import DahboardHeader from "./DahboardHeader";
import DahboardLeftside from "./DahboardLeftside";
import DahboardRightside from "./DahboardRightside";
import DahboardFooter from "./DahboardFooter";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Likes from "./social-interaction/Likes";
import CommentInput from "./social-interaction/CommentInput";
import CommentsList from "./social-interaction/CommentsList";
import FullViewFeed from "./feeds/FullViewFeed";
import useFeedLoader from "./hooks/useFeedsLoader";
import Feeds from "./feeds/Feeds";
import Categories from "../common/Categories.js";
import Createpost from "../createPost/Createpost.js";
import send from "../assets/Icons/Icon feather-arrow-left@2x.png";
import S3 from "react-aws-s3";
import certificate from "../assets/dashboard_icons/Group 948.png";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import Progressbar from "../progressbar";
import SubCategoryList from "../discoveryCategory/SubCategoryList";
import SubCategories from "../discoveryCategory/CategoriesFeeds";
import DistributionCategories from "../discoveryCategory/DistributionCategories";
import getWindowDimensions from "../common/hooks/getWindowDimensions";
import { useHistory } from "react-router-dom";
import React from "react";

import MandatoryInfoScreen from "../authentication/RegisterComponents/MandatoryInfoScreen";
import MciUpload from "../common/MciUpload";
import MciVerificationStatus from "../common/MciVerificationStatus";
import MciUploadSuccess from "../common/MciUploadSuccess";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const NameContext = createContext();

const Dashboard = ({ feeds, setFeeds, setCategoryListInfo, setTabId }) => {
  const value = useContext(NameContext);

  const history = useHistory();
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const doc_id = getDocId();
  const headers = getHeaders();
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [openFullView, setOpenFullView] = useState(false);
  const handleOpenFullView = (feed) => {
    // window.scrollTo(0, 0);
    setModalData(feed);
    setOpenFullView(true);
  };
  const handleCloseFullView = () => {
    setOpenFullView(false);
  };
  const [requestLocation, setRequestLocation] = useState("Home");
  const [commentsListInfo, setCommentsListInfo] = useState(false);
  const handleOpenFullViewComent = (feed) => {
    setModalData(feed);
    setCommentsListInfo(true);
  };
  const handleCloseFullViewComment = () => {
    setCommentsListInfo(false);
  };

  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  const [openVerificationPending, setOpenVerificationPending] = useState(false);

  const handleVerificationPending = (feed) => {
    // handleUploadMci();
    setOpenVerificationPending(true);
  };
  const handleCloseVerificationPending = () => {
    setOpenVerificationPending(false);
  };
  const handleClickOpen = (feed) => {
    setModalData(feed);
    if (feed.feed_info.socialInteraction.likesCount == 0) {
      return;
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const changeHeader = false;
  const [imgPreview, setImgPreview] = useState(false);
  const openImgPreview = () => setImgPreview(true);
  const closeImgPreview = () => setImgPreview(false);
  const [isMciUploaded, setIsMciUploaded] = useState(1);
  const [uploadMciSuccess, setUploadMciSuccess] = useState(false);
  const openUploadMciSuccess = () => setUploadMciSuccess(true);
  const closeUploadMciSuccess = () => {
    setIsMciUploaded(2);
    setUploadMciSuccess(false);
  };
  const [feedsErrorMsg, setFeedsErrorMsg] = useState("Could not load Feeds");

  const [mandatoryInfoScreen, setMandatoryInfoScreen] = useState(false);

  const openMandatoryInfoScreen = () => setMandatoryInfoScreen(true);
  const closeMandatoryInfoScreen = () => setMandatoryInfoScreen(false);

  // const location = useLocation();

  // const mandatoryInfoCheck = location.state
  //   ? location.state.is_user_active
  //   : true;

  const is_user_active = localStorage.getItem("is_user_active");

  const mandatoryInfoCheck = is_user_active
    ? is_user_active === "true"
      ? true
      : false
    : true;

  // const isSpecialityEntered =
  //   location.state && location.state?.is_speciality_added;

  const is_speciality_added = localStorage.getItem("is_speciality_added");

  const isSpecialityEntered = is_speciality_added === "true" ? true : false;

  const [isMandatoryInfoEntered, setIsMandatoryInfoEntered] =
    useState(mandatoryInfoCheck);

  const submitMandatoryInfo = async (dt) => {
    const payload = {
      doc_id,
      location: dt.city.value,
      experience: dt.experience.value,
      speciality: dt.speciality ? dt.speciality.value : "",
      is_exact_city: false,
    };

    const res = await fetch(baseUrl + "v2/updateMandatoryData", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
    });

    const data = await res.json().catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
    });

    console.log(data);

    if (data?.status === "success") {
      // window.history.replaceState(null, document.title);
      setIsMandatoryInfoEntered(true);
      closeMandatoryInfoScreen();
      localStorage.removeItem("is_user_active");
      localStorage.removeItem("is_speciality_added");
    } else {
      toast(data.errorMsg);
    }
  };

  const fileInput = useRef(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState("");
  const [uploadModel, setUploadModel] = useState(false);
  const [categoryId, setCategoryId] = useState(0);
  const [categoryName, setCategoryName] = useState();
  const [headerTitle, setHeaderTitle] = useState(setCategoryListInfo);
  const [selectedCategoryData, setSelectedCategoryData] = useState();
  const [categoryNameLabel, setCategoryNameLabel] = useState();

  const [updateCategoryUnreadCount, setUpdateCategoryUnreadCount] = useState({
    categoryId: null,
    newUnreadCount: null,
  });

  const handleUploadMci = () => {
    setUploadModel(true);
  };
  const handleCloseUploadMci = () => {
    setUploadModel(false);
  };
  const deleteUploadedImage = (e) => {
    e.preventDefault();
    fileInput.current.value = "";
    setImgPreviewUrl("");
    setIsFileUploaded(false);
  };
  const reUploadImage = (e) => {
    deleteUploadedImage(e);
    fileInput.current.click();
  };
  const showImagePreview = () => {
    let type = fileInput.current.files[0].type;
    if (type.includes("image")) type = "image";
    else if (type.includes("video")) type = "video";
    else if (type.includes("audio")) type = "audio";
    else if (type.includes("pdf")) type = "pdf";

    if (type === "image") {
      setIsFileUploaded(true);
      setImgPreviewUrl(URL.createObjectURL(fileInput.current.files[0]));
    } else {
      toast.error("Only images are allowed");
    }
  };

  const saveFile = async (url, doc_id) => {
    const payload = {
      doc_id,
      attachment_original_url: url,
    };

    const res = await fetch(baseUrl + "v2/upload", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        setFeedsErrorMsg(errorMsg);
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        setFeedsErrorMsg(errorMsg);
        toast(errorMsg);
      }
      return;
    });

    const data = await res.json();
    setloaderSpinner(false);
    if (data.status === "success") {
      // addToast("uploaded Successfully", {
      //   appearance: "success",
      //   autoDismiss: true,
      // });
      handleCloseUploadMci();
      openUploadMciSuccess();
      fetchUserData();
    } else toast.error(data.errorMsg);
  };
  const uploadFile = async (e, fileInput, doc_id) => {
    e.preventDefault();
    let file = fileInput.current.files[0];
    let newFileName = fileInput.current.files[0].name;

    const ReactS3Client = new S3(config);

    const data = await ReactS3Client.uploadFile(file, newFileName);

    saveFile(data.location, doc_id);
  };

  const [nextFeedsData, setNextFeedsData] = useState({
    pageNum: 0,
    lastFeedTime: 0,
  });

  const { loading, error, channels, hasMore } = useFeedLoader(
    doc_id,
    nextFeedsData,
    setFeeds,
    isMandatoryInfoEntered
  );

  const observer = useRef(null);

  const loader = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setNextFeedsData((prev) => ({
            ...prev,
            pageNum: prev.pageNum + 1,
            lastFeedTime:
              feeds[feeds.length - 1]?.feed_info?.updated_time === 0
                ? feeds[feeds.length - 1]?.feed_info?.posting_time
                : feeds[feeds.length - 1]?.feed_info?.updated_time,
          }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const [userData, setUserData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }
    if (!isMandatoryInfoEntered) openMandatoryInfoScreen();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMandatoryInfoEntered) {
      fetchUserData();
    }
  }, [isMandatoryInfoEntered]);

  useEffect(() => {
    if (isMciUploaded === 2) fetchUserData();
  }, [isMciUploaded]);

  const fullViewFeedRef = useRef(null);

  useEffect(() => {
    if (fullViewFeedRef.current) fullViewFeedRef.current.scrollTo(0, 0);
  }, [feeds]);

  const fetchUserData = async () => {
    // const IP_ADDRESS = await getClientIp();
    // headers["x-device-id"] = IP_ADDRESS;
    const payload = {
      user_id: doc_id,
    };
    const res = await fetch(baseUrl + "v1/userProfile/view", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    if (data.status === "success") {
      localStorage.setItem("email", data.data.cnt_email);
      localStorage.setItem("phone", data.data.cnt_num);
      setUserData(data.data);
    } else {
      toast.error(data.errorMsg);
    }
  };

  const verifyUser = () => {
    if (!userData.verificationInfo.isCommunityVerified) {
      if (userData.verificationInfo.is_user_verified == 1) {
        handleUploadMci();
        return false;
      }
      if (userData.verificationInfo.is_user_verified == 2) {
        handleVerificationPending();
        return false;
      }
    }
    return true;
  };

  const addComment = async (feed_id, channel_id, comment, attachments = []) => {
    if (!verifyUser()) return;

    if (attachments.length === 0 && comment === "") {
      toast.error("Cannot post empty comment");
      return;
    }

    let payload = JSON.stringify({
      doc_id: doc_id,
      channel_id,
      feedTypeId: feed_id,
      socialInteraction: {
        type: "Comment",
        comment,
        attachment_details: attachments,
      },
    });

    payload = payload.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });

    payload = encodeURIComponent(payload);

    const res = await fetch(baseUrl + "v1/feedSocialInteractions", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    if (data.status === "success")
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed.feed_info.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    else toast.error(data.errorMsg);
  };
  const updateLikeCount = async (feed_id, channel_id, isLike) => {
    if (!verifyUser()) return;

    const payload = {
      doc_id: doc_id,
      channel_id: channel_id,
      feedTypeId: feed_id,
      socialInteraction: {
        type: "Like",
        isLike,
      },
    };

    const res = await fetch(baseUrl + "v1/feedSocialInteractions", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    if (data.status === "success")
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed.feed_info.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    else toast.error(data.errorMsg);
  };
  const updateViewCount = async (channel_id, feed_id) => {
    const payload = {
      doc_id,
      channel_id: channel_id,
      feedId: feed_id,
    };

    const res = await fetch(baseUrl + "feeds/updateViewCount", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((err) => {
      console.log(err);
      return;
    });
    const data = await res.json();

    if (data.status === "success") {
      if (data.data.socialInteraction)
        setFeeds(
          feeds.map((feed) => {
            const newFeed = { ...feed };
            if (feed.feed_info.feed_id === feed_id)
              newFeed.feed_info.socialInteraction = data.data.socialInteraction;
            return newFeed;
          })
        );
      else
        setFeeds(
          feeds.map((feed) => {
            const newFeed = { ...feed };
            if (feed.feed_info.feed_id === feed_id)
              newFeed.feed_info.socialInteraction.viewCount += 1;
            return newFeed;
          })
        );
    } else toast.error(data.errorMsg);
  };
  const submitSurvey = async (feed_id, channel_id, question_id, option_id) => {
    if (option_id.length === 0) {
      toast("Please select an option");
      return;
    }

    const payload = {
      feed_id,
      channel_id,
      user_id: doc_id,
      feed_survey: {
        questions: [
          {
            question_id,
            options: option_id.map((option) => ({
              option_id: option,
            })),
          },
        ],
      },
    };

    const res = await fetch(baseUrl + "v1/feed/survey/update", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();
    if (data.status === "success")
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed?.feed_info?.feed_id === feed_id) {
            newFeed.feed_info.feed_survey.questions =
              newFeed.feed_info.feed_survey.questions.map(
                (question, index) => ({
                  ...question,
                  options: data.data.feed_survey.questions[index].options,
                })
              );
            newFeed.feed_info.feed_survey.is_open =
              data.data.feed_survey.is_open;
            newFeed.feed_info.feed_survey.is_participated =
              data.data.feed_survey.is_participated;
          }

          return newFeed;
        })
      );
    else toast.error(data.errorMsg);
  };
  const deleteComment = async (feed_id, channel_id, social_interaction_id) => {
    const payload = {
      user_id: doc_id,
      channel_id: channel_id,
      feed_id,
      social_interaction_id,
    };
    const res = await fetch(baseUrl + "v1/feeds/comment/delete", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();

    if (data.status === "success")
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed.feed_info.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    else toast.error(data.errorMsg);
  };
  const closeIcon = <img src={close}></img>;
  const updateComment = async (
    feed_id,
    channel_id,
    social_interaction_id,
    comment,
    attachments = []
  ) => {
    if (!verifyUser()) return;

    if (attachments.length === 0 && comment === "") {
      // addToast("Cannot post empty comment", {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
      return;
    }

    let payload = JSON.stringify({
      user_id: doc_id,
      channel_id,
      feed_id,
      social_interaction_id,
      comment: comment,
      attachment_details: attachments,
    });

    payload = payload.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });

    payload = encodeURIComponent(payload);

    const res = await fetch(baseUrl + "v1/feeds/comment/update", {
      method: "POST",
      headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();

    if (data.status === "success")
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed?.feed_info?.feed_id === feed_id)
            newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        })
      );
    else toast.error(data.errorMsg);
  };
  const registerWebinar = async (e, feed_id, verify_user, title) => {
    e.preventDefault();

    if (verify_user) {
      if (!verifyUser()) return;
    }

    const payload = {
      user_id: doc_id,
      feed_id,
    };

    const res = await fetch(baseUrl + "v1/user/webinar/register", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
    });

    const data = await res.json();

    if (data.status === "success") {
      toast.success(`Thank you for registering for ${title}`);
      setFeeds(
        feeds.map((feed) => {
          const newFeed = { ...feed };
          if (feed?.feed_info?.feed_id === feed_id)
            newFeed.feed_info.event_details.is_user_registered = true;
          return newFeed;
        })
      );
      return true;
    } else if (data.status === "error") toast.error("Failed to registered");

    return false;
  };

  const ondisplayCategoryNameHandler = (data) => {
    console.log("dashboard" + data);
    // data.unread_count=9;
    setSelectedCategoryData(data);
    setCategoryName(data.category_name);
    setCategoryNameLabel("Search " + data.category_name);
  };
  const getLatestDashBoardData1 = async () => {};
  const getLatestDashBoardData = async () => {
    const payload = {
      doc_id,
      last_feed_time:
        feeds[0]?.feed_info?.updated_time === 0
          ? feeds[0]?.feed_info?.posting_time
          : feeds[0]?.feed_info?.updated_time,
      load_next: false,
      pg_num: nextFeedsData.pageNum,
    };

    const res = await fetch(baseUrl + "v3/getDashBoardData", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
      return;
    });

    const data = await res.json();
    if (data.status === "success") {
      console.log(data);
      setFeeds((prevFeeds) => {
        return [...new Set([...data.data.feed_data, ...prevFeeds])];
      });
      // setFeeds(
      //   feeds.map((feed) => {
      //     const newFeed = { ...feed };
      //     if (feed?.feed_info?.feed_id === feed_id)
      //       newFeed.feed_info.socialInteraction = data.data.socialInteraction;
      //     return newFeed;
      //   })
      // );
    } else toast.error(data.errorMsg);
  };
  return (
    <>
      {windowDimensions.width <= 425 && categoryId != 0 ? (
        <header id="header d-block d-lg-none">
          <div className="container">
            <div className="navbar-header">
              <div className="mobile-header-wrapper sticky d-block d-lg-none">
                <div
                  className="position-relative "
                  style={{
                    backgroundColor: "green",
                    height: "60px",
                    textAlign: "center",
                  }}
                >
                  <div className="mobile-responsive">
                    <div className="brand-logo ">
                      <div className="row">
                        <div className="col-4">
                          <Link to="/dashboard">
                            <img
                              style={{
                                cursor: "pointer",
                                marginTop: "10px",
                                marginRight: "40px",
                              }}
                              onClick="{()=>{history.goBack()}}"
                              src={send}
                              alt="back"
                            />
                          </Link>
                        </div>
                        <div className="col-8">
                          <p
                            style={{
                              textAlign: "initial",
                              marginTop: "20px",
                              color: "white",
                              font: "normal normal medium Roboto",
                              fontSize: "20px",
                            }}
                          >
                            {categoryName}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <header>
          <DahboardHeader
            userData={userData}
            handleUploadMci={handleUploadMci}
            handleVerificationPending={handleVerificationPending}
            loadDashBoardData={getLatestDashBoardData}
          ></DahboardHeader>
        </header>
      )}
      <main>
        <div className="main-wrapper pt-80" style={{ marginTop: "2em" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-3  d-none d-sm-block" id="left">
                <DahboardLeftside
                  userInfo={userData}
                  handleUploadMci={handleUploadMci}
                ></DahboardLeftside>
              </div>
              <div className="col-lg-6 " id="middle">
                <div className="d-none d-sm-block">
                  <Createpost
                    channels={channels}
                    userInfo={userData}
                    handleUploadMci={handleUploadMci}
                    handleVerificationPending={handleVerificationPending}
                    loadDashBoardData={getLatestDashBoardData}
                  />
                </div>
                {windowDimensions.width <= 425 && categoryId != 0 ? null : (
                  <Categories
                    updateCategoryUnreadCount={updateCategoryUnreadCount}
                    ondisplayCategoryName={ondisplayCategoryNameHandler}
                    setCategoryId={setCategoryId}
                    requestLocation={requestLocation}
                    setSelectedCategoryData={setSelectedCategoryData}
                  />
                )}

                {categoryId === 0 && (
                  <>
                    <Feeds
                      feeds={feeds}
                      profile_pic={userData?.profile_pic_small_url}
                      updateLikeCount={updateLikeCount}
                      addComment={addComment}
                      submitSurvey={submitSurvey}
                      openFullView={handleOpenFullView}
                      commentsListInfo={handleOpenFullViewComent}
                      openLikesModal={handleClickOpen}
                      channels={channels}
                      registerWebinar={registerWebinar}
                      user_verification_info={userData?.verificationInfo}
                    />

                    {loading && <div> Loading ....</div>}
                    {error && <div>{feedsErrorMsg}</div>}
                    <div ref={loader} />
                  </>
                )}

                {categoryId != 0 && (
                  <SubCategoryList
                    user_verification_info={userData?.verificationInfo}
                    setUpdateCategoryUnreadCount={setUpdateCategoryUnreadCount}
                    profile_pic={userData?.profile_pic_small_url}
                    channels={channels}
                    categoryId={categoryId}
                    selectedCategoryData={selectedCategoryData}
                    categoryNameLabel={categoryNameLabel}
                    changeHeader={changeHeader}
                  />
                )}
              </div>
              <div className="col-lg-3 d-none d-sm-block" id="right">
                <DahboardRightside></DahboardRightside>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="d-block d-sm-none">
        <DahboardFooter
          handleUploadMci={handleUploadMci}
          userInfo={userData}
          handleVerificationPending={handleVerificationPending}
        ></DahboardFooter>
      </footer>
      <Modal
        open={openFullView}
        blockScroll={true}
        style={customStyles}
        onClose={handleCloseFullView}
        closeIcon={closeIcon}
        classNames={{ modal: "feedModel" }}
        ref={fullViewFeedRef}
      >
        <FullViewFeed
          profile_pic={userData?.profile_pic_small_url}
          close={handleCloseFullView}
          feed={modalData}
          setFeeds={setFeeds}
          addComment={addComment}
          updateLikeCount={updateLikeCount}
          submitSurvey={submitSurvey}
          updateViewCount={updateViewCount}
          channels={channels}
          deleteComment={deleteComment}
          updateComment={updateComment}
          openLikesModal={handleClickOpen}
          openCommentMobileView={handleOpenFullViewComent}
          registerWebinar={registerWebinar}
          user_verification_info={userData?.verificationInfo}
        ></FullViewFeed>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        classNames={{ modal: "feedsLikeModel" }}
        closeIcon={closeIcon}
      >
        <Likes feed={modalData}></Likes>
      </Modal>

      {/* Mobile responce Comment list */}
      <Modal
        open={commentsListInfo}
        blockScroll={true}
        onClose={handleCloseFullViewComment}
        classNames={{ modal: "feedsLikeModel" }}
        styles={{
          modal: {
            height: "100vh",
            width: "100%",
            padding: "0",
            margin: "0",
            borderRadius: "0",
            overflow: "visible",
          },
          modalContainer: {
            overflow: "visible",
          },
        }}
        showCloseIcon={false}
        feed={modalData}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div
            className="mobile-header-wrapper d-block d-lg-none"
            style={{
              position: "-webkit-sticky",
              position: "sticky",
              top: "0",
              left: "0",
              width: "100%",
              zIndex: "5",
              flexShrink: "0",
            }}
          >
            <div className="mobile-header position-relative ">
              <div style={{ display: "flex" }}>
                <div className="brand-logo ">
                  <img
                    onClick={handleCloseFullViewComment}
                    src={send}
                    style={{ width: "25px", marginTop: "-4px" }}
                    alt="timeline logo"
                  />
                </div>
                <div className="pull-left">
                  <p style={{ marginLeft: "10px" }}>
                    Comment &nbsp;
                    <span style={{ color: "green" }}>
                      {`(${modalData?.feed_info?.socialInteraction?.commentsCount} Comments)`}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div style={{ overflowY: "auto" }}>
            <CommentsList
              feed={modalData}
              deleteComment={deleteComment}
              updateComment={updateComment}
            />
          </div>
          <div
            className="footer-area  d-block d-lg-none"
            style={{
              position: "-webkit-sticky",
              position: "sticky",
              bottom: "0",
              right: "0",
              width: "100%",
              flexShrink: "0",
              background: "white",
              zIndex: "5",
              height: "100px",
            }}
          >
            <hr></hr>
            <div
              className="post-meta"
              style={{
                padding: "0",
                margin: "0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CommentInput
                profile_pic={userData?.profile_pic_small_url}
                addComment={addComment}
                feed_id={modalData?.feed_info?.feed_id}
                channel_id={modalData.channel_id}
                isFullFeedView={false}
              />
            </div>
          </div>
        </div>
      </Modal>

      {/* profile verification model */}
      <Modal
        open={openVerificationPending}
        onClose={handleCloseVerificationPending}
        classNames={{ modal: "verificationModel" }}
        closeIcon={closeIcon}
      >
        <MciVerificationStatus
          is_user_verified={userData?.verificationInfo?.is_user_verified}
          closeVerificationStatusModal={handleCloseVerificationPending}
        />
      </Modal>
      <Modal
        open={uploadModel}
        onClose={handleCloseUploadMci}
        classNames={{ modal: "uploadMci" }}
        closeIcon={closeIcon}
      >
        <MciUpload
          closeUploadMci={handleCloseUploadMci}
          openSuccessModal={openUploadMciSuccess}
        />
      </Modal>

      <Modal
        closeIcon={closeIcon}
        open={uploadMciSuccess}
        onClose={closeUploadMciSuccess}
        classNames={{ modal: "verificationModel" }}
      >
        <MciUploadSuccess closeUploadMciSuccess={closeUploadMciSuccess} />
      </Modal>

      <Modal
        open={mandatoryInfoScreen}
        onClose={closeMandatoryInfoScreen}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        closeOnEsc={false}
      >
        <MandatoryInfoScreen
          isSpecialityEntered={isSpecialityEntered}
          submitMandatoryInfo={submitMandatoryInfo}
        />
      </Modal>
    </>
  );
};

export default Dashboard;
