import { getDocId, getHeaders } from "../utils/utils";

const dashboardServices = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const doc_id = getDocId();
  const headers = getHeaders();

  const addCommentService = async (
    feed_id,
    channel_id,
    comment,
    attachments = []
  ) => {
    let payload = JSON.stringify({
      doc_id: doc_id,
      channel_id,
      feedTypeId: feed_id,
      socialInteraction: {
        type: "Comment",
        comment,
        attachment_details: attachments,
      },
    });

    payload = payload.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });

    payload = encodeURIComponent(payload);

    const res = await fetch(baseUrl + "v1/feedSocialInteractions", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    });

    const data = await res.json();

    return data;
  };

  const updateLikeCountService = async (feed_id, channel_id, isLike) => {
    const payload = {
      doc_id: doc_id,
      channel_id: channel_id,
      feedTypeId: feed_id,
      socialInteraction: {
        type: "Like",
        isLike,
      },
    };

    const res = await fetch(baseUrl + "v1/feedSocialInteractions", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();

    return data;
  };

  const updateViewCountService = async (channel_id, feed_id) => {
    const payload = {
      doc_id,
      channel_id: channel_id,
      feedId: feed_id,
    };

    const res = await fetch(baseUrl + "feeds/updateViewCount", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((err) => {
      console.log(err);
      return;
    });
    const data = await res.json();
    return data;
  };

  const submitSurveyService = async (
    feed_id,
    channel_id,
    question_id,
    option_id
  ) => {
    const payload = {
      feed_id,
      channel_id,
      user_id: doc_id,
      feed_survey: {
        questions: [
          {
            question_id,
            options: option_id.map((option) => ({
              option_id: option,
            })),
          },
        ],
      },
    };

    const res = await fetch(baseUrl + "v1/feed/survey/update", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();
    return data;
  };

  const deleteCommentService = async (
    feed_id,
    channel_id,
    social_interaction_id
  ) => {
    const payload = {
      user_id: doc_id,
      channel_id: channel_id,
      feed_id,
      social_interaction_id,
    };
    const res = await fetch(baseUrl + "v1/feeds/comment/delete", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();

    return data;
  };

  const updateCommentService = async (
    feed_id,
    channel_id,
    social_interaction_id,
    comment,
    attachments = []
  ) => {
    let payload = JSON.stringify({
      user_id: doc_id,
      channel_id,
      feed_id,
      social_interaction_id,
      comment: comment,
      attachment_details: attachments,
    });

    payload = payload.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });

    payload = encodeURIComponent(payload);

    const res = await fetch(baseUrl + "v1/feeds/comment/update", {
      method: "POST",
      headers,
      body: "reqData=" + payload,
    });
    const data = await res.json();
    return data;
  };

  const registerWebinarService = async (feed_id) => {
    const payload = {
      user_id: doc_id,
      feed_id,
    };

    const res = await fetch(baseUrl + "v1/user/webinar/register", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();
    return data;
  };

  return {
    updateLikeCountService,
    updateViewCountService,
    addCommentService,
    deleteCommentService,
    updateCommentService,
    registerWebinarService,
    submitSurveyService,
  };
};

export default dashboardServices;
