import { useState, useEffect, useRef, useCallback } from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import React from "react";
import ReactDOM from "react-dom";
import toast from "react-hot-toast";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import AppBar from "@material-ui/core/AppBar";
import TabList from "@material-ui/lab/TabList";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import useCategoryLoader from "../dashboard/hooks/useCategoryLoader";
//import CircularProgress from '@mui/material/CircularProgress';
import TabScrollButton from "@material-ui/core/TabScrollButton";
import { withStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Progressbar from "../progressbar";
const MyTabScrollButton = withStyles((theme) => ({
  root: {
    width: 15,
    overflow: "hidden",
    display: "none",
    transition: "width 0.5s",
    "&:focus": {
      display: "block !important",
    },
    "&:hover": {
      display: "block !important",
    },
    "&.Mui-disabled": {
      width: 0,
    },
  },
}))(TabScrollButton);
const NameContext = React.createContext();
const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: "black",
  },

  tab: {
    "&.Mui-selected": {
      background: "#f1f1f1",
    },
  },
  root: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-around",
    },
    "&:focus": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&:hover": {
      color: "black",
      opacity: 1,
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: 0,
      padding: "15px",
      marginBottom: "30px",
      webkitBoxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
      boxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
    },
    "& .MuiTypography-body1": {
      font: "normal normal normal 20px/20px Roboto",
      fontWeight: 400,
    },
    "& .PrivateTabIndicator-colorSecondary-4": {
      backgroundColor: "#1e8a79",
      border: "2px solid",
    },
    "& .PrivateTabIndicator-colorSecondary-6": {
      backgroundColor: "#2a8f80",
    },

    "& .MuiTabPanel-root": {
      padding: "2px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    root: {
      "&:hover": {
        backgroundColor: "#f1f1f1",
        color: "#f1f1f1",
      },
    },
    "&.Mui-root": {
      "&:hover": {
        backgroundColor: "#f1f1f1",
        color: "#f1f1f1",
      },
    },
    "&.Mui-selected": {
      background: "#f1f1f1",
    },
    //MuiButtonBase-root MuiTab-root MuiTab-textColorPrimary
    // '&.MuiButtonBase-root': {
    //   "&:hover": {
    //     backgroundColor: "#f1f1f1",
    //     color: "#f1f1f1"
    //   }
    // },
    // '&.MuiTab-root': {
    //   "&:hover": {
    //     backgroundColor: "#f1f1f1",
    //     color: "#f1f1f1"
    //   }
    // },
    "&.MuiTab-textColorPrimary": {
      "&:hover": {
        backgroundColor: "#f1f1f1",
      },
    },
    "&.MuiTab-root:focus": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.MuiTab-root:hover": {
      color: "black",
      opacity: 1,
    },
    "& .MuiTabs-flexContainer": {
      display: "flex",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
    "& .MuiBox-root": {
      padding: "0px",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: "#2a8f80",
      height: "4px",
    },
    "& .MuiTabPanel-root": {
      padding: "2px",
    },
    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      /* font-size: 11px; */
      font: "13px / 40px Roboto",
    },
    "& .MuiTab-root": {
      minWidth: "60px",
      opacity: "0.5px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  skill: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "flex-start",
    },

    "& .MuiBox-root": {
      padding: "0px",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: "#2a8f80",
      height: "4px",
    },
    "& .MuiTabPanel-root": {
      padding: "2px",
    },

    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      /* font-size: 11px; */
      font: "13px / 40px Roboto",
    },
    "& .MuiTab-root": {
      minWidth: "60px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  test: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-around",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: 0,
      padding: "15px",
      marginBottom: "30px",
      webkitBoxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
      boxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
    },
    "& .MuiTypography-body1": {
      font: "normal normal normal 20px/20px Roboto",
      fontWeight: 400,
    },
    "& .PrivateTabIndicator-colorSecondary-4": {
      backgroundColor: "#1e8a79",
      border: "2px solid",
    },
    "& .PrivateTabIndicator-colorSecondary-6": {
      backgroundColor: "#2a8f80",
    },

    "& .MuiTabPanel-root": {
      padding: "2px",
    },
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },

    "& .MuiBox-root": {
      padding: "0px",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: "#2a8f80",
      height: "4px",
    },
    "& .MuiTabPanel-root": {
      padding: "2px",
    },

    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      /* font-size: 11px; */
      font: "13px / 40px Roboto",
    },
    "& .MuiTab-root": {
      minWidth: "27px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
const Categories = ({
  setCategoryId,
  requestLocation,
  setSelectedCategoryData,
  updateCategoryUnreadCount,
  ondisplayCategoryName,
}) => {
  //console.log("data"+setCategoryListInfo)
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";

  const classes = useStyles();
  const history = useHistory();
  const doc_id = getDocId();
  const headers = getHeaders();

  const [categoryList, setCategoryList] = useState([]);
  const [hasMoreFeed, setHasMoreFeed] = useState(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [nextFeedsData, setNextFeedsData] = useState({
    pageNum: 0,
  });
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [feedsErrorMsg, setFeedsErrorMsg] = useState("Could not load Feeds");

  useEffect(() => {
    if (updateCategoryUnreadCount.categoryId) {
      setCategoryList((prevCategories) => {
        const newCategories = [...prevCategories];
        newCategories.map((newCategory) => {
          if (
            newCategory.category_id === updateCategoryUnreadCount.categoryId
          ) {
            if (updateCategoryUnreadCount.newUnreadCount < 0)
              newCategory.unread_count +=
                updateCategoryUnreadCount.newUnreadCount;
            else
              newCategory.unread_count =
                updateCategoryUnreadCount.newUnreadCount;
          }
        });
        return newCategories;
      });
    }
  }, [updateCategoryUnreadCount]);

  useEffect(() => {
    fetchMoreData();
  }, [nextFeedsData]);

  // const getCategoryList = async () => {
  //   const payload = {
  //     user_id,
  //     pg_num: nextFeedsData.pageNum,
  //     request_location : requestLocation
  //   };
  //   const res = await fetch(baseUrl + "v2/feed/categories/list", {
  //     method: "POST",
  //     headers,
  //     body: "reqData=" + JSON.stringify(payload),
  //   }).catch((e) => {
  //     return;
  //   });
  //   const data = await res.json();
  //   if (payload.pg_num === 0) setCategoryList([]);
  //   if (data.status === "success") {
  //     if(data.data.categories){
  //      setCategoryList((prev) => {
  //        return [...prev, ...data.data.categories]

  //      });

  //   }
  //   } else {
  //   }
  // };
  const clickCategoryList = (e, data, key) => {
    e.stopPropagation();
    if (requestLocation === "Home") {
      if (data.tag_id > 0 && data.tag_id <= 3)
        history.push(`/knowledge/${data.tag_id}/${data.category_id}`);
      if (data.tag_id > 3 && data.tag_id <= 7)
        history.push(`/community/${data.tag_id}/${data.category_id}`);
      if (data.tag_id > 7 && data.tag_id <= 10)
        history.push(`/professional/${data.tag_id}/${data.category_id}`);
    }
    //data.unread_count=10;
    setValue(key);
    setSelectedCategory(data.category_id);
    //setSelectedCategoryData(data)
    setCategoryId(data.category_id);
    ondisplayCategoryName(data);

    // setcategoryName(data.category_name)
  };

  // const [nextFeedsData, setNextFeedsData] = useState({
  //   pageNum: 0
  // });

  // const { loading, error, hasMore,categoryList } = useCategoryLoader(
  //   doc_id,
  //   nextFeedsData,
  //   requestLocation
  // );

  // const observer = useRef(null);

  // const loader = useCallback(
  //   (node) => {
  //     if (loading) return;
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting && hasMore) {
  //         setNextFeedsData((prev) => ({
  //           ...prev,
  //           pageNum: prev.pageNum + 1,
  //           // lastFeedTime: feeds[feeds.length - 1]?.feed_info?.updated_time,
  //         }));
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   },

  //   [loading, hasMore]
  // );
  const fetchMoreData = async () => {
    const payload = {
      user_id: doc_id,
      pg_num: nextFeedsData.pageNum,
      request_location: requestLocation,
    };
    const res = await fetch(baseUrl + "v2/feed/categories/list", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    setTimeout(() => {
      if (data.status === "success") {
        if (data.data?.categories && data.data?.categories?.length > 0) {
          setCategoryList((prevFeeds) => {
            return [...new Set([...prevFeeds, ...data.data?.categories])];
          });
          setNextFeedsData((prev) => ({
            pageNum: prev.pageNum + 1,
          }));
        }
      } else {
        return;
      }
    }, 1500);
  };
  return (
    <div>
      {/* <InfiniteScroll
          dataLength={categoryList}
          next={fetchMoreData}
          inverse={true}
          hasMore={hasMoreFeed}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
          >
            */}
      {categoryList && categoryList.length !== 0 && (
        <div style={{ marginBottom: "10px" }}>
          <Box
            style={{
              backgroundColor: "white",
              color: "black",
              top: "85px",
              boxShadow: "0 10px 5px -10px #000000",
              borderBottom: "1px solid #7b7b7e",
            }}
          >
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              style={{ textTransform: "none" }}
              value={value}
              TabIndicatorProps={{ style: { backgroundColor: "green" } }}
              className={classes.tabs}
              indicatorColor="primary"
              textColor="primary"
            >
              {categoryList.map((category, key) => (
                <Tab
                  className="tab-cat"
                  key={key}
                  style={{
                    textTransform: "none",
                    padding: "15px 8px",
                    minWidth: "0px",
                  }}
                  className={classes.tabs}
                  label={
                    <div
                      key={key}
                      className="button-cat"
                      onClick={(e) => {
                        clickCategoryList(e, category, key);
                      }}
                    >
                      <div className="button-div">
                        <Badge
                          badgeContent={category.unread_count}
                          color="success"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          style={{ zIndex: "0" }}
                        >
                          <img
                            style={{
                              width: "40px",
                              height: "40px",
                              marginTop: "5px",
                            }}
                            src={category.image_url}
                            alt="knowledge"
                          />
                        </Badge>
                      </div>
                      <div className="category-text">
                        {selectedCategory === category.category_id ? (
                          <div>{category.category_name}</div>
                        ) : (
                          category.category_name
                        )}
                      </div>
                    </div>
                  }
                />
              ))}
              {/* {loading && <div> Loading ....</div>}
            {error && <div>{feedsErrorMsg}</div>}
            <div ref={loader} /> */}
            </Tabs>
          </Box>
        </div>
      )}
      {/* </InfiniteScroll> */}
    </div>
  );
};

export default Categories;

{
  /* <div>
                     <TabContext
                        value={value}
                        indicatorColor={{ backgroundColor: "green" }}  >
                      <AppBar className="mL" position="static"
                          style={{ backgroundColor: "white", color: "black",marginBottom:'15px',top: '85px'}}> 
                           <div >
                           <TabList onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="tab example" className={classes.tabs} >
                           {categoryList.map((category, key) => (
              <Tab  value={key} style={{  marginTop: '5px', padding: "10px 8px", minWidth: "0px" }}  
              label={
                  <div   className="button-cat" onClick={(e) => {clickCategoryList(e,category)}}> 
                    <div  className="button-div" >
                      <Badge badgeContent={category.unread_count} color="success" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                        <img style={{ width: "40px", height: "40px", marginTop: "5px" }} src={category.image_url} alt="knowledge" />
                      </Badge>
                    </div>
                    <div className="category-text">
                     { selectedCategory === category.category_id ?  <div>{category.category_name}</div> : category.category_name }</div>
                  </div>
                }
              />
              
            ))}
            
                          </TabList >
                          
                          </div>
                          
            {loading && <div> Loading ....</div>}
            {error && <div>{feedsErrorMsg}</div>}
            <div ref={loader} />
                        </AppBar>
                       
                        <TabPanel  className={classes.tabs} style={{display:"none"}}>
                       
                        </TabPanel>
                        
                      </TabContext>
        </div>     */
}
