import { useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useHistory, useParams } from "react-router";
import profile from "../../assets/profile_icons/default-profile.png";
import { MdEdit } from "react-icons/md";
import toast from "react-hot-toast";
import send from "../../assets/Icons/Icon feather-arrow-left@2x.png";
import Progressbar from "../../progressbar";
import edit from "../../assets/profile_icons/ic_cam.svg";
import {

    getDocId,

    getHeaders,

} from "../../utils/utils"
const EditPersonalInfo = ({userInfo, getUserData,closePersonalDetailsModal}) => {
    var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  
    console.log('%cPersonalInfo.js line:15 object', 'color: #007acc;', userInfo);
    const [userData, setUserData] = useState(userInfo);
    const history = useHistory();
    const {
        handleSubmit,
        register,
        reset,
        control,
        setValue: setFormValue,
        trigger,
        formState: { errors },
    } = useForm();
    const profilePicInput = useRef(null);
    const fileInput = useRef(null);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const doc_id = getDocId();
    const headers = getHeaders();
    const [specialities, setSpecialities] = useState();
    const [yearsOfExperience, setYearsOfExperience] = useState();
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [imgPreviewUrl, setImgPreviewUrl] = useState("");
    const [imgPreviewType, setImgPreviewType] = useState("");
    const [loaderSpinner, setloaderSpinner] = useState(false);
    useEffect(() => {
        let experience = [];
        experience.push({ value: 0, label: "0-1 years" });
        for (let i = 1; i <= 70; i++) {
            experience.push({ value: i, label: `${i} years` });
        }
        setYearsOfExperience(experience);
        autoSuggestions();
        setFormValue("speciality", {
            label: userInfo?.specialists,
            value: userInfo?.specialists,
        });
        setFormValue("experience", {
            label: `${userInfo?.experience} years`,
            value: userInfo?.experience,
        });
        setFormValue("experience", {
            label: `${userInfo.experience} years`,
            value: userInfo.experience,
        });
        setFormValue("fname", userInfo.fname);
        setFormValue("lname", userInfo.lname);
        setFormValue("salutation", userInfo.user_salutation);
        setFormValue("specific_ask", userInfo?.socialInfo[0]?.specific_ask);
        setFormValue("subSpeciality", userInfo?.subSpeciality);
        // setFormValue("oneLineUsp", userInfo?.lname);
        setFormValue("oneLineUsp", userInfo.socialInfo[0]?.about_me);
       


    }, []);
    const autoSuggestions = async () => {
        const payload = { cities: "cities", specialities: "specialities" };
        const res = await fetch(baseUrl + "v2/autoSuggestions", {
            method: "POST",
            headers,
            body: "reqData=" + JSON.stringify(payload),
        }).catch((error) => {
            console.error(error);
            if (navigator.onLine)
                toast("Whitecoats is currently unavailable. \n Try again later");
            else toast("You are offline");
            return;
        });

        const data = await res.json();

        let cities = [];
        let specialities = [];
        if (data.status === "success") {
            for (const city of data.data.cities) {
                cities.push({ value: city, label: city });
            }

            for (const speciality of data.data.specialities) {
                specialities.push({ value: speciality, label: speciality });
            }
        }


        setSpecialities(specialities);
       console.log('%cEditPersonalInfo.js line:100 object', 'color: #007acc;',  specialities);
    };
    const showImagePreview = () => {
        let type = fileInput.current.files[0].type;
        if (type.includes("image")) type = "image";


        if (type === "image") {
            setIsFileUploaded(true);
            setImgPreviewType(type);
            setImgPreviewUrl(URL.createObjectURL(fileInput.current.files[0]));
        } else {
            toast.error("Only images and pdfs are allowed");
        }
    };


    

    const uploadProfilePic = async (formData) => {
        // e.preventDefault();
         const reqFormData = new FormData();
        let modifiedHeaders = { ...headers };
        
        
        let speciality= formData.speciality?.value;
        const specArray=[];
        specArray.push(speciality);
       
        delete modifiedHeaders["Content-Type"];
        let image = profilePicInput.current.files[0];
      
        
        // if (test1.includes("[[")) {
        //     console.log('%cEditPersonalInfo.js line:130 object', 'color: #007acc;', test1);

        // };
        // if (test1.includes("[")) {
        //     test1.push(speciality);
        //     console.log('%cEditPersonalInfo.js line:130 object', 'color: #007acc;', test1);
        // };
        let requestData={
        isUpdate:true,
        user_id:doc_id,
        user_first_name:formData.fname?.trim(),
        user_last_name:formData.lname?.trim(),
        user_salutation:formData.salutation?.trim(),
        user_type_id:userData?.user_type_id,
        subSpeciality:formData.subSpeciality?.trim(),
       
        specialities:Array.isArray(speciality)?speciality:specArray,
        specialists:userData?.specialists,
        experience:parseInt(formData.experience?.value),
        profile_pic_original_url: userData?.profile_pic_original_url,
        profile_pic_small_url:userData?.profile_pic_small_url,
        socialInfo: [
            {
              about_me: formData.oneLineUsp?.trim(),
              specific_ask: formData.specific_ask?.trim(),
              blog_pg: userData?.socialInfo[0]?.blog_pg,
              website_pg: userData?.socialInfo[0]?.website_pg,
              fb_pg: userData?.socialInfo[0]?.fb_pg,
              linkedin_pg:userData?.socialInfo[0]?.linkedin_pg,
              twitter_pg:userData?.socialInfo[0]?.twitter_pg,
              instagram_pg: userData?.socialInfo[0]?.instagram_pg,
              pdf_url: userData?.socialInfo[0]?.pdf_url,
            }]
        }

            reqFormData.append("reqData", JSON.stringify(requestData));
    
         if (profilePicInput.current) reqFormData.append("file", image);
         setloaderSpinner(true);
        const res = await fetch(baseUrl + "v1/userProfile/edit", {
            method: "POST",
            headers: modifiedHeaders,
            body: reqFormData
          }).catch((error) => {
            console.error(error);
            setloaderSpinner(false);
            if (navigator.onLine) {
              //toast("Whitecoats is currently unavailable. \n Try again later");
            } else toast("You are offline");
            return false;
          });
      
          const data = await res.json();
      
    
        if (data.status === "success") {
            closePersonalDetailsModal();
            toast.success("Personal Info Updated successfully");
          getUserData();
        } else toast.error("Error Personal Info");
      };
    
    

    const editProfilePic = () => {
        profilePicInput.current.value = "";
        profilePicInput.current.click();
      };
    
      const uploadImage = () => {
        let type = profilePicInput.current.files[0].type;
        if (type.includes("image")) type = "image";
        else if (type.includes("video")) type = "video";
        else if (type.includes("audio")) type = "audio";
        else if (type.includes("pdf")) type = "pdf";
    
        if (type === "image") {
        //   uploadProfilePic();
        setImgPreviewUrl(URL.createObjectURL(profilePicInput.current.files[0]));
        } else {
          toast.error("Please upload an image");
        }
      };
    
    return (
        <>


            <div className="personalInfo_container">
                <div className="forms_container">
                    <form >
                        <div className="row">
                            <div style={{ display: 'flex' }}>
                                <div className="col-2">
                                    <select
                                        {...register("salutation", { required: true })}
                                        className="dr-dropdown"
                                        style={{ marginTop: "1em", marginBottom: "0em", background: "#ffffff", borderRadius: "5px", height: "3em", border: "1px solid gray", width: "60px" }}>
                                        <option value="Dr.">Dr.</option>
                                        <option value="Mr.">Mr.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Ms.">Ms.</option>
                                    </select>
                                </div>
                                <div className="col-5">
                                    <div >
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <input style={{ marginTop: "1em", marginBottom: "0em", background: "#ffffff", borderRadius: "5px", height: "3em", border: "1px solid gray", width: "100%", paddingLeft: '5px' }}
                                                type="text"
                                                {...register("fname", {
                                                    required: true,
                                                    minLength: 1,
                                                    maxLength: 30,
                                                    pattern: /^\s*[A-za-z]+\s*$/,
                                                })}
                                                placeholder="Enter First Name *"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5">
                                    <div
                                        style={{
                                            marginTop: "1em",
                                            marginBottom: "0em",
                                            background: "#ffffff",
                                            borderRadius: "5px",
                                            height: "3em",
                                            border: "1px solid black",
                                            width: "100%",
                                        }}
                                        className="signup-lastname">
                                        <div style={{ display: "flex", justifyContent: "center", height: "100%", paddingLeft: '5px' }} >
                                            <input
                                                style={{
                                                    background: "inherit",
                                                    width: "100%",
                                                    border: "none",
                                                }}
                                                type="text"
                                                {...register("lname", {
                                                    required: true,
                                                    minLength: 1,
                                                    maxLength: 30,
                                                    pattern: /^\s*[A-za-z]+\s*$/,
                                                })}
                                                placeholder="Enter Last Name *"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {errors.fname?.type === "pattern" && (
                            <div className="row error-title">
                                <span className="error-title-text">
                                    Please don't use numbers, special characters or spaces
                                </span>
                            </div>
                        )}
                        {errors.fname?.type === "required" && (
                            <div className="row error-title">
                                <span className="error-title-text">
                                    {" "}
                                    Please enter your first name
                                </span>
                            </div>
                        )}
                        {errors.fname?.type === "maxLength" && (
                            <div className="row error-title">
                                <span className="error-title-text">max length is 30</span>
                            </div>
                        )}
                        {errors.fname?.type === "minLength" && (
                            <div className="row error-title">
                                <span className="error-title-text">min length is 1</span>
                            </div>
                        )}
                        {errors.lname?.type === "pattern" && (
                            <div className="row error-title">
                                <span className="error-title-text">
                                    Please don't use numbers, special characters or spaces
                                </span>
                            </div>
                        )}
                        {errors.lname?.type === "required" && (
                            <div className="row error-title">
                                <span className="error-title-text">
                                    {" "}
                                    Please enter your last name
                                </span>
                            </div>
                        )}
                        {errors.lname?.type === "maxLength" && (
                            <div className="row error-title">
                                <span className="error-title-text">max length is 30</span>
                            </div>
                        )}
                        {errors.lname?.type === "minLength" && (
                            <div className="row error-title">
                                <span className="error-title-text">min length is 1</span>
                            </div>
                        )}
                        <div
                            className="row"
                            style={{
                                marginTop: "1em",
                                marginBottom: "0em",
                                background: "#ffffff",
                                borderRadius: "5px",
                                height: "3em",
                                border: "1px solid black",
                                width: "500px",
                                marginLeft: "1px",

                            }}
                        >
                            <div
                                className="col-12"
                                style={{ paddingLeft: "0px" }}>
                                <Controller
                                    name="speciality"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            placeholder="Enter Speciality "
                                            options={specialities}
                                            styles={{
                                                control: (style) => ({
                                                    ...style,
                                                    background: "inherit",
                                                    width: "100%",
                                                    border: 0,
                                                    fontSize: "15px",
                                                    boxShadow: "none",
                                                }),
                                                input: (style) => ({
                                                    ...style,
                                                    paddingLeft: "0px",
                                                    fontSize: '15x'
                                                }),
                                                container: (style) => ({
                                                    ...style,
                                                    marginTop: "0.45em",
                                                    marginLeft: "-5px",
                                                }),
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {errors.speciality && (
                            <div className="row error-title">
                                <span className="error-title-text">
                                    Please enter your speciality
                                </span>
                            </div>
                        )}

                        <div
                            className="row"
                            style={{
                                marginTop: "1em",
                                marginBottom: "0em",
                                background: "#ffffff",
                                borderRadius: "5px",
                                height: "3em",
                                border: "1px solid gray",
                                width: "500px",
                                marginLeft: "1px",
                            }}
                        >
                            <div
                                className="col-10"
                                style={{ paddingLeft: "0px" }}
                            >
                                <input
                                    style={{
                                        marginTop: "1em",
                                        paddingLeft: "5px",
                                        background: "inherit",
                                        width: "100%",
                                        border: "none",
                                    }}
                                    type="text"
                                    {...register("subSpeciality", {
                                       
                                        maxLength: 35,

                                    })}
                                    placeholder="Enter Sub-speciality"
                                />
                            </div>
                        </div>
                     
                        <div
                            className="row"
                            style={{
                                marginTop: "1em",
                                marginBottom: "0em",
                                background: "#ffffff",
                                borderRadius: "5px",
                                height: "3em",
                                border: "1px solid gray",
                                width: "500px",
                                marginLeft: "1px",
                            }} >
                            <div
                                className="col-12"
                                style={{ paddingLeft: "0px" }}
                            >
                                <Controller
                                    name="experience"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            className="myclass" classNamePrefix="myclass"
                                            options={yearsOfExperience}
                                            placeholder="Overal Experience "
                                            styles={{
                                                control: (style) => ({
                                                    ...style,
                                                    background: "inherit",
                                                    width: "100%",
                                                    border: 0,
                                                    boxShadow: "none",
                                                }),
                                                input: (style) => ({
                                                    ...style,
                                                    paddingLeft: "0px",
                                                }),
                                                container: (style) => ({
                                                    ...style,
                                                    marginTop: "0.45em",
                                                    marginLeft: "-5px",
                                                }),
                                            }}
                                        />
                                    )}
                                ></Controller>
                            </div>
                        </div>
                        {errors.experience?.type === "required" && (
                            <div className="row error-title">
                                <span className="error-title-text">
                                    Please enter your experience
                                </span>
                            </div>
                        )}
                        {(errors.experience?.type === "max" ||
                            errors.experience?.type === "min") && (
                                <div className="row error-title">
                                    <span className="error-title-text">
                                        Please enter value between 0-70
                                    </span>
                                </div>
                            )}
                        <div
                            className="row"
                            style={{
                                marginTop: "1em",
                                marginBottom: "0em",
                                background: "#ffffff",
                                borderRadius: "5px",
                                height: "3em",
                                border: "1px solid gray",
                                width: "500px",
                                marginLeft: "1px",
                            }}
                        >
                            <div
                                className="col-10"
                                style={{ paddingLeft: "0px" }}
                            >
                                <input
                                    style={{
                                        marginTop: "1em",
                                        paddingLeft: "5px",
                                        background: "inherit",
                                        width: "100%",
                                        border: "none",
                                    }}
                                    type="text"
                                    {...register("oneLineUsp", {
                                       
                                        maxLength: 256,

                                    })}
                                    placeholder="One Line USP"
                                />
                            </div>
                        </div>
                        {errors.oneLineUsp?.type === "maxLength" && (
                            <div className="row error-title">
                                <span className="error-title-text">max length is 256</span>
                            </div>
                        )}
                        <div
                            className="row"
                            style={{
                                marginTop: "1em",
                                marginBottom: "0em",
                                background: "#ffffff",
                                borderRadius: "5px",
                                height: "3em",
                                border: "1px solid gray",
                                width: "500px",
                                marginLeft: "1px",
                            }}>
                            <div
                                className="col-10"
                                style={{ paddingLeft: "0px" }}>
                                <input
                                    style={{
                                        marginTop: "1em",
                                        paddingLeft: "5px",
                                        background: "inherit",
                                        width: "100%",
                                        border: "none",
                                    }}
                                    type="text"
                                    {...register("specific_ask", {
                                       
                                        maxLength: 160,
                                        pattern: {
                                            value: /^[a-zA-Z ]+$/,
                                            message: "invalid Specific Ask",
                                        },
                                    })}
                                    placeholder="Specific Ask"
                                />
                            </div>
                        </div>
                        {errors.specific_ask?.type === "maxLength" && (
                            <div className="row error-title">
                                <span className="error-title-text">max length is 160</span>
                            </div>
                        )}
                         {errors.specific_ask?.type === "pattern" && (
                            <div className="row error-title">
                                <span className="error-title-text">
                                    Please don't use numbers, special characters 
                                </span>
                            </div>
                        )}
                        <div className="image_container">
                        <p style={{ marginTop: '10px' }}>Upload Profile Photo</p>
                            <div className="profile-pic">
                              
                                <div className="conatiner">
                                    <div className="profile_container">
                                    {imgPreviewUrl=="" ? <img src={
                                        userInfo?.profile_pic_original_url &&
                                            userInfo?.profile_pic_original_url !== ""
                                            ? userInfo.profile_pic_original_url
                                            : profile
                                    } className="img-responsive profile-photo" style={{width:'130px',borderRadius:'50%', height: '130px'}} />:
                                    <div className="profile-pic" style={{borderRadius:'50%'}}>
                                    <img src={
                                        imgPreviewUrl
                                    } className="img-responsive profile-photo" style={{borderRadius:'50%',width:'130px',    height: '130px',}} />
                                    </div>}
                                         <div
                                      style={{
                                        position: "absolute",
                                        top: "580px",
                                        right: "420px",
                                        cursor:"pointer"
                                      }}
                                      
                                      onClick={editProfilePic}
                                    >
                                      {/* <MdEdit
                                        style={iconBackgroundStyle}
                                        size={25}
                                      /> */}
                                      <img src={edit} style={{width: '50%',background: '#469b8d', padding: '3px',borderRadius: '15%'}}/>
                                      <input
                                        type="file"
                                        accept=",jpg, .jpeg, .png"
                                        onChange={uploadImage}
                                        ref={profilePicInput}
                                        style={{ display: "none" }}
                                      />
                                    </div>
                                    <div>
          
          <Progressbar
            show={loaderSpinner}
            imageUrl={url}
            height="90"
            width="90"
            alignment="middle"
            alttext="Loading..."
          />
                        </div>  
                                    </div>

                                </div>
                            </div>
                        </div>
                        </form>

                       
        </div>
              
               <div style={{ margin: "30px 20px" }}>
                            <button type="submit" onClick={handleSubmit(uploadProfilePic)} className="profile-save-button">
                                Save
                            </button>
                            <button type="submit" onClick={closePersonalDetailsModal} className="profile-close-button">
                                Close
                            </button>
                           
                    
                </div>
               
            </div>

        </>
    )
}
export default EditPersonalInfo;
const iconBackgroundStyle = {
    backgroundColor: "rgb(30 138 121)",
    borderRadius: "50%",
    padding: "3px",
    color: "white",
};