import { useState, useEffect, useRef, useCallback } from "react";
import * as React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { config, getDocId, getHeaders } from "../utils/utils";
import AppBar from "@material-ui/core/AppBar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import Progressbar from "../progressbar";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./Channels.css";
import toast from "react-hot-toast";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import useSearchFeedLoader from "../dashboard/hooks/useSearchFeedLoader";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import mail from "../assets/profile_icons/Icon zocial-email.svg";
import call from "../assets/profile_icons/Icon awesome-phone-square-alt.svg";

const ChannelMembersList = ({ selectedChannel, selectedDept }) => {
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const history = useHistory();
  const doc_id = getDocId();
  const headers = getHeaders();
  const [membersList, setMembersList] = useState([]);
  const [deptInfoList, setDeptInfoList] = useState([]);
  const [communityMemberCount, setCommunityMemberCount] = useState();
  const [displaySearchMember, setDisplaySearchMember] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const channelId = selectedChannel.channel_id;
  const deptId = selectedDept.department_id;
  const categoryNameLabel = "Search " + selectedDept.department_name;
  const [lastMemberId, setLastMemberId] = useState("");
  const [empty, setEmpty] = useState(false);
  //setCategoryNameLabel('Search '+sendData.department_name);
  //     setChannelId(selectedChannel.channel_id);
  //     setDeptId(sendData.department_id);
  //
  const feedsErrorMsg = "Could not load Feeds";
  const [nextFeedsData, setNextFeedsData] = useState({
    pageNum: 0,
  });
  const [userData, setUserData] = useState();
  const [openVerificationPending, setOpenVerificationPending] = useState(false);
  const closeIcon = <img src={close}></img>;
  const handleVerificationPending = (userData) => {
    setUserData(userData);
    if (userData?.doc_id == 0) {
      setOpenVerificationPending(true);
    } else {
      history.push(`/profile/${userData.doc_id}`);
    }
  };
  const handleCloseVerificationPending = () => {
    setOpenVerificationPending(false);
  };

  useEffect(() => {
    // getChannelDeptInfo();
  }, []);

  const getChannelDeptInfo = async () => {
    const payload = {
      channel_id: selectedChannel.channel_id,
      doc_id: doc_id,
    };
    const res = await fetch(baseUrl + "v2/community/deptInfo", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {});
    const data = await res.json();
    if (data.status === "success") {
      setDisplaySearchMember(false);
      setCommunityMemberCount(data.community_member_count);
      setDeptInfoList(data?.data);
    }
  };
  const { loading, error, hasMore } = useSearchFeedLoader(
    doc_id,
    nextFeedsData,
    channelId,
    deptId,
    setMembersList,
    searchValue,
    setLastMemberId,
    setEmpty
  );
  const observer = useRef(null);
  const loader = useCallback(
    (node) => {
      if (loading || lastMemberId == -1) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setNextFeedsData((prev) => ({
            ...prev,
            pageNum: lastMemberId,
            // lastFeedTime: feeds[feeds.length - 1]?.feed_info?.updated_time,
          }));
        }
      });
      if (node) observer.current.observe(node);
    },

    [loading, hasMore]
  );
  const searchClick = async (key) => {
    console.log(key);
    if (searchValue == "") {
      toast.error("Please provide a search keyword");
      return;
    }
    const payload = {
      user_id: doc_id,
      searchText: searchValue,
    };
    const res = await fetch(baseUrl + "v1/globalSearch", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    if (data.status === "success") {
      setMembersList([]);
      if (
        data?.data?.contact_search_results &&
        data?.data?.contact_search_results.length > 0
      ) {
        setMembersList([...data?.data?.contact_search_results]);
      }
    }
  };
  const searchClear = async () => {
    setSearchValue("");
    setNextFeedsData(() => ({
      pageNum: 0,
    }));
  };
  return (
    <div>
      <AppBar
        className="mL"
        position="sticky"
        style={{
          backgroundColor: "white",
          color: "black",
          marginBottom: "15px",
          top: "158px",
          display: "flex",
          borderRadius: "18px",
          margin: "10px 0px",
          height: "40px",
          padding: "10px",
          border: "1px solid rgb(30, 138, 121)",
          backgroundColor: "aliceblue",
        }}
      >
        <InputBase
          style={{ fontSize: "15px", marginTop: "-3px" }}
          placeholder={categoryNameLabel}
          type="text"
          variant="outlined"
          fullWidth
          size="small"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
        />
        <div className="search-button">
          <IconButton aria-label="search">
            <SearchIcon onClick={(event) => searchClick(event.target.value)} />
          </IconButton>
        </div>
        <div className="close-button">
          {searchValue && (
            <IconButton onClick={() => searchClear()}>
              <CancelRoundedIcon />
            </IconButton>
          )}
        </div>
      </AppBar>
      <List
        sx={{
          width: "100%",
          maxWidth: "100%",
          bgcolor: "background.paper",
          padding: "10px",
        }}
      >
        {" "}
        {membersList.length !== 0 && (
          <div>
            {" "}
            {membersList.map((member) => (
              <ListItem
                style={{
                  borderTop: "1px solid #e7e5e5",
                  borderBottom: "1px solid #e7e5e5",
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    alt="profile pic"
                    src={member.profile_pic_original_url}
                  />
                  {/* <img
            alt="profile pic"
            src={member.profile_pic_name}
          /> */}
                </ListItemAvatar>
                <div class="row" style={{ width: "100%" }}>
                  <div class="col-8">
                    <span
                      className="feed-poster"
                      style={{ color: "#333333", cursor: "pointer" }}
                      onClick={() => handleVerificationPending(member)}
                    >
                      {" "}
                      {member.full_name}
                      <br></br>
                    </span>
                    <span
                      style={{
                        color: "rgb(169 164 164)",
                        font: "normal normal normal  16px/19px Roboto",
                      }}
                    >
                      {" "}
                      {member.community_designation}
                      <br></br>
                    </span>
                    <span
                      style={{
                        color: "rgb(169 164 164)",
                        font: "normal normal normal  16px/19px Roboto",
                      }}
                    >
                      {member.location}
                    </span>
                  </div>
                  <div class="col-2"></div>
                  <div class="col-2"></div>
                </div>
              </ListItem>
            ))}
            {loading && (
              <div>
                <Progressbar
                  show={true}
                  imageUrl={url}
                  height="90"
                  width="90"
                  alignment="middle"
                  alttext="Loading..."
                />
              </div>
            )}
            {error && <div>{feedsErrorMsg}</div>}
            <div ref={loader} />
          </div>
        )}
        {searchValue == "" && empty && membersList.length === 0 && (
          <div class="container" style={{ marginBottom: "0px" }}>
            <div class="row">
              <div
                class="col-12"
                style={{ textAlign: "center", marginTop: "8%" }}
              >
                <div style={{ fontSize: "18px" }}>
                  {" "}
                  {selectedDept.department_name} has no Members.
                </div>
              </div>
            </div>
          </div>
        )}
      </List>

      {searchValue != "" && membersList.length === 0 && (
        <div class="container" style={{ marginBottom: "0px" }}>
          <div class="row">
            <div
              class="col-12"
              style={{ textAlign: "center", marginTop: "8%" }}
            >
              <div style={{ fontSize: "18px" }}>
                Sorry no results containing your search term were found
              </div>
            </div>
          </div>
        </div>
      )}

      {/* profile verification model */}
      <Modal
        open={openVerificationPending}
        onClose={handleCloseVerificationPending}
        classNames={{ modal: "uploadMci" }}
        closeIcon={closeIcon}
      >
        <div style={{ marginTop: "15px" }}></div>
        <div className="verifaction">
          <p>{userData?.full_name}</p>

          <p>{userData?.community_designation}</p>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <p
            style={{
              border: "1px solid #ddd",
              borderRadius: "10px",
              backgroundColor: "aliceblue",
              height: "30px",
              padding: "5px",
            }}
          >
            <img
              src={call}
              style={{
                width: "15px",
                marginLeft: "10px",
              }}
            />
            <span style={{ marginLeft: "10px" }}> Not shared</span>
          </p>
          <p
            style={{
              border: "1px solid #ddd",
              borderRadius: "10px",
              backgroundColor: "aliceblue",
              height: "30px",
              padding: "5px",
            }}
          >
            <img
              src={mail}
              style={{
                width: "15px",
                marginLeft: "10px",
              }}
            />{" "}
            <span style={{ marginLeft: "10px" }}>Not shared</span>
          </p>
        </div>
        {/* <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={handleCloseVerificationPending}
            style={{
              backgroundColor: "#ffffff",
              border: "1px solid gray",
              color: "black",
              padding: "11px",
              width: "150px",
              fontsize: "11px",
              marginBottom: "10px",
              borderRadius: "9px",
            }}
            className="submit-btn"
          >
            Close
          </button>
        </div> */}
      </Modal>
    </div>
  );
};

export default ChannelMembersList;
