import { useLayoutEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import select from "../../assets/Icons/Ellipse 32.svg";
import unselect from "../../assets/Icons/Ellipse 33.svg";
import {
  getDate,
  getTime,
  getTimeAndDate,
  getHeaders,
} from "../../utils/utils";
import FeedAttachments from "./FeedAttachments";

const FeedContent = ({
  feed,
  submitSurvey,
  channel_id,
  openFullView,
  registerWebinar,
  attachmentVideoRef,
  attachmentAudioRef,
}) => {
  const [displayViewMore, setDisplayViewMore] = useState(false);
  const contentDiv = useRef(null);

  useLayoutEffect(() => {
    contentDiv.current && shouldDisplayViewMore(contentDiv.current);

    //eslint-disable-next-line
  }, []);

  const shouldDisplayViewMore = ({ clientHeight, scrollHeight }) => {
    if (scrollHeight > clientHeight + 6) setDisplayViewMore(true);
    else setDisplayViewMore(false);
  };

  const [surveyAnswer, setSurveyAnswer] = useState([]);

  const handleSurvey = (val, is_multi_select, checked) => {
    if (is_multi_select)
      if (checked) setSurveyAnswer((prev) => [...prev, val]);
      else
        setSurveyAnswer((prev) => {
          const index = prev.indexOf(val);
          return prev.splice(index, 1);
        });
    else {
      if (checked) setSurveyAnswer([val]);
      else setSurveyAnswer([""]);
    }
  };

  const calcWebinarStatus = () => {
    const currentTime = Date.now();

    if (!feed?.event_details) return "";

    if (feed.event_details.has_cancelled) return "cancelled";

    const webinarStartTime = feed.event_details.from_date;
    const webinarEndTime = feed.event_details.to_date;

    if (currentTime < webinarStartTime) return "register";
    if (currentTime >= webinarStartTime && currentTime < webinarEndTime)
      return "join";
    if (currentTime >= webinarEndTime) return "ended";
  };

  const webinarStatus = calcWebinarStatus();

  const [isRegisterClicked, setIsRegisterClicked] = useState(false);

  const handleWebinarRegister = async (e) => {
    const isRegistered = await registerWebinar(
      e,
      feed.feed_id,
      feed.event_details.verify_user,
      feed.title
    );
    setIsRegisterClicked(isRegistered);
  };

  const createFullPost = () => {
    return (
      <>
        {feed.feed_desc && (
          <div className="feed-description">
            <div
              dangerouslySetInnerHTML={{
                __html: feed.feed_desc,
              }}
            ></div>
          </div>
        )}
      </>
    );
  };

  const eventLink =
    feed?.event_details?.link.includes("https://") ||
    feed?.event_details?.link.includes("http://")
      ? feed?.event_details?.link
      : `https://${feed?.event_details?.link}`;

  if (feed?.feed_type === "EVENT") {
    if (feed?.feed_sub_type === "EOI")
      return (
        <>
          <div className="feed-title">{feed?.title}</div>
          
          {feed?.feed_desc && (
            <div
              className="feed-description"
              onClick={openFullView}
              ref={contentDiv}
            >
              <div
                className="d-block d-lg-none"
                dangerouslySetInnerHTML={{
                  __html: feed.feed_desc
                    ? feed?.feed_desc.slice(0, 110) +
                      (feed?.feed_desc.length > 110
                        ? "<span style='color:#1daf76',text-decoration:'underline',cursor:'pointer''>...Read more </span>"
                        : "")
                    : "",
                }}
              ></div>
              <div
                className="d-none d-sm-block"
                dangerouslySetInnerHTML={{
                  __html: feed?.feed_desc
                    ? feed?.feed_desc.slice(0, 220) +
                      (feed?.feed_desc.length > 220
                        ? "<span style='color:#1daf76',text-decoration:'underline',cursor:'pointer''>...Read more </span>"
                        : "")
                    : "",
                }}
              ></div>
            </div>
          )}

          {!feed?.event_details.has_cancelled &&
            !feed?.event_details.is_user_registered && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <a href={eventLink} target="_blank" style={{ color: "white" }}>
                  <button
                    style={{
                      backgroundColor: "#0FB59B",
                      borderRadius: "5px",
                      width: "150px",
                      height: "3em",
                      margin: "0.5em",
                    }}
                  >
                    I am interested
                  </button>
                </a>
              </div>
            )}
          <div onClick={openFullView}>
            <FeedAttachments
              videoRef={attachmentVideoRef}
              audioRef={attachmentAudioRef}
              attachments={feed.attachment_details}
            />
          </div>
        </>
      );

    if (feed?.feed_sub_type === "Webinar" || feed?.feed_sub_type === "WEBINAR")
      return (
        <>
          <div className="feed-title">{feed.title}</div>
          {feed.feed_desc && (
            <div
              className="feed-description"
              onClick={openFullView}
              ref={contentDiv}
            >
              <div
                className="d-block d-lg-none"
                dangerouslySetInnerHTML={{
                  __html: feed?.feed_desc
                    ? feed?.feed_desc?.slice(0, 110) +
                      (feed?.feed_desc?.length > 110
                        ? "<span style='color:#1daf76',text-decoration:'underline',cursor:'pointer'',cursor:'pointer'',cursor:'pointer'>...Read more </span>"
                        : "")
                    : "",
                }}
              ></div>
              <div
                className="d-none d-sm-block"
                dangerouslySetInnerHTML={{
                  __html: feed?.feed_desc
                    ? feed?.feed_desc?.slice(0, 220) +
                      (feed?.feed_desc?.length > 220
                        ? "<span style='color:#1daf76',text-decoration:'underline',cursor:'pointer'',cursor:'pointer''>...Read more </span>"
                        : "")
                    : "",
                }}
              ></div>
            </div>
          )}

          <p>Webinar Date and Time</p>
          <p>
            {webinarStatus === "join" && `LIVE`}
            {webinarStatus === "ended" && `ENDED`}
            {webinarStatus === "cancelled" && `CANCELLED`}
          </p>

          <p>
            {`${getDate(feed.event_details.from_date)}  ${getTime(
              feed.event_details.from_date
            )} to
                  ${getTime(feed.event_details.to_date)}`}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "0.5em",
            }}
          >
            {webinarStatus === "register" &&
              !isRegisterClicked &&
              !feed.event_details.is_user_registered && (
                <button
                  style={{
                    backgroundColor: "#0FB59B",
                    borderRadius: "5px",
                    width: "150px",
                    height: "3em",
                    margin: "0.5em",
                  }}
                  onClick={(e) => {
                    handleWebinarRegister(e);
                  }}
                >
                  Register now
                </button>
              )}
            {webinarStatus === "join" && (
              <a href={eventLink} target="_blank" style={{ color: "white" }}>
                <button
                  style={{
                    backgroundColor: "#0FB59B",
                    borderRadius: "5px",
                    width: "150px",
                    height: "3em",
                    margin: "0.5em",
                  }}
                >
                  Join
                </button>
              </a>
            )}
            {webinarStatus === "ended" &&
              !feed.event_details.videos_uploaded && (
                <p>The recorded video will be updated shortly</p>
              )}
            {isRegisterClicked && <p>You are registered</p>}
            {feed.event_details.is_user_registered &&
              webinarStatus === "register" &&
              !isRegisterClicked && <p>You are registered</p>}
          </div>
          <div onClick={openFullView}>
            <FeedAttachments
              videoRef={attachmentVideoRef}
              audioRef={attachmentAudioRef}
              attachments={feed.attachment_details}
            />
          </div>
        </>
      );
  }

  if (feed?.feed_type === "ARTICLE")
    return (
      <>
        <div className="dashboard-feed-discription">
          {feed.article_body[0].article_desc && (
            <div className="feed-description" ref={contentDiv}>
              {/* {displayViewMore && (
                <span className="read-more">...... Read more</span>
              )} */}
              <div
                className="d-block d-lg-none"
                dangerouslySetInnerHTML={{
                  __html: feed.article_body[0].article_desc
                    ? feed.article_body[0].article_desc.slice(0, 110) +
                      (feed.article_body[0].article_desc.length > 110
                        ? "<span style='color:#1daf76',text-decoration:'underline',cursor:'pointer''><u>...Read more </u> </span>"
                        : "")
                    : "",
                }}
              ></div>
              <div
                className="d-none d-sm-block"
                dangerouslySetInnerHTML={{
                  __html: feed.article_body[0].article_desc
                    ? feed.article_body[0].article_desc.slice(0, 220) +
                      (feed.article_body[0].article_desc.length > 220
                        ? "<span style='color:#1daf76',text-decoration:'underline',cursor:'pointer''><u>...Read more </u></span>"
                        : "")
                    : "",
                }}
              ></div>
            </div>
          )}

          {/* <ReadMoreReact text={createFullPost}
                // min={minimumLength}
                ideal={idealLength}
                max={maxLength}
                readMoreText="click read more"/> */}
        </div>
      </>
    );

  if (feed?.feed_type === "SURVEY")
    return feed.feed_survey?.questions.map((question, key) => (
      <div style={{ marginLeft: "1.5em" }} key={key}>
        <div className="feed-title" onClick={openFullView}>
          {question.question}
        </div>

        <div onClick={openFullView}>
          <FeedAttachments
            videoRef={attachmentVideoRef}
            audioRef={attachmentAudioRef}
            attachments={feed.feed_survey.questions[0].attachment_details}
          />
        </div>

        {question.description && (
          <div
            className="feed-description"
            ref={contentDiv}
            onClick={openFullView}
          >
            {/* {displayViewMore && (
              <span className="read-more">...... Read more</span>
            )} */}
            {/* <div
              dangerouslySetInnerHTML={{
                __html: question.description
              }}
            ></div> */}
            <div
              className="d-block d-lg-none"
              dangerouslySetInnerHTML={{
                __html: question.description
                  ? question.description.slice(0, 110) +
                    (question.description.length > 110
                      ? "<span style='color:#1daf76',text-decoration:'underline',cursor:'pointer''><u>...Read more </u> </span>"
                      : "")
                  : "",
              }}
            ></div>
            <div
              className="d-none d-sm-block"
              dangerouslySetInnerHTML={{
                __html: question.description
                  ? question.description.slice(0, 220) +
                    (question.description.length > 220
                      ? "<span style='color:#1daf76',text-decoration:'underline',cursor:'pointer''><u>...Read more </u> </span>"
                      : "")
                  : "",
              }}
            ></div>
          </div>
        )}
        <div
          className="text-muted"
          style={{ fontSize: "0.9em", margin: "5px 0" }}
        >
          Survery
          {question.is_multi_select
            ? ` (You can select more than one option)`
            : ` (Select one option)`}
        </div>
        <div>
          {question.options.map((option, key) => (
            <div
              style={{
                border: "1px solid black",
                borderRadius: "4px",
                minHeight: "2.5em",
                marginBottom: "1em",
                width: "90%",
                padding: "0.2em",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                position: "relative",
                justifyContent:
                  ((feed.feed_survey.is_participated &&
                    feed.feed_survey.immediate_results) ||
                    !feed.feed_survey.is_open) &&
                  "space-between",
              }}
              key={key}
            >
              {((feed.feed_survey.is_participated &&
                feed.feed_survey.immediate_results) ||
                !feed.feed_survey.is_open) && (
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    backgroundColor: "rgba(34, 139, 34, 0.25)",
                    height: "100%",
                    width: `${option.participated_pct}%`,
                  }}
                ></div>
              )}
              {!(
                (feed.feed_survey.is_participated &&
                  feed.feed_survey.immediate_results) ||
                !feed.feed_survey.is_open
              ) && (
                <div style={{ padding: "0.5em" }}>
                  {surveyAnswer.indexOf(option.option_id) !== -1 ||
                  option.is_selected ? (
                    <img
                      src={select}
                      onClick={() =>
                        !feed.feed_survey.is_participated &&
                        feed.feed_survey?.is_open &&
                        handleSurvey(
                          option.option_id,
                          question.is_multi_select,
                          false
                        )
                      }
                      alt="checkbox"
                      tabIndex="0"
                    />
                  ) : (
                    <img
                      src={unselect}
                      onClick={() =>
                        !feed.feed_survey.is_participated &&
                        feed.feed_survey?.is_open &&
                        handleSurvey(
                          option.option_id,
                          question.is_multi_select,
                          true
                        )
                      }
                      alt="checkbox"
                      tabIndex="0"
                    />
                  )}
                </div>
              )}
              <div style={{ padding: "0.5em" }}>{option.option}</div>
              {((feed.feed_survey.is_participated &&
                feed.feed_survey.immediate_results) ||
                !feed.feed_survey.is_open) && (
                <div
                  style={{ padding: "0.5em", float: "right" }}
                >{`${option.participated_pct}%`}</div>
              )}
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {!feed.feed_survey.is_participated && feed.feed_survey?.is_open && (
            <button
              style={{
                backgroundColor: "#0FB59B",
                borderRadius: "5px",
                width: "40%",
                height: "3em",
              }}
              className="submit-btn"
              onClick={() =>
                submitSurvey(
                  feed.feed_id,
                  channel_id,
                  question.question_id,
                  surveyAnswer
                )
              }
            >
              Submit
            </button>
          )}
        </div>
        {feed.feed_survey.is_participated && (
          <div
            className="text-muted"
            style={{
              display: "flex",
              justifyContent: "center",
              // fontSize: "0.9em",
              margin: "5px 0",
              fontWeight: "bold",
              width: "100%",
            }}
          >
            Thanks for participating
          </div>
        )}
        {feed.feed_survey.is_participated && feed.feed_survey?.is_open && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "0.9em",
              marginTop: "1em",
              width: "100%",
            }}
            className="text-muted"
          >
            Survey results will be announced once the survey is closed
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "0.9em",
            marginTop: "1em",
            width: "100%",
          }}
          className="text-muted"
        >
          {!feed.feed_survey?.is_open
            ? `Survey closed`
            : feed.feed_survey?.close_time
            ? `Survey closes on ${getTimeAndDate(feed.feed_survey?.close_time)}`
            : null}
        </div>
      </div>
    ));

  return (
    <>
      {feed?.feed_desc && (
        <div className="feed-description" ref={contentDiv}>
          {/* {displayViewMore && (
            <span className="read-more">...... Read more</span>
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: feed.feed_desc,
            }}
          ></div> */}
          <div
            className="d-block d-lg-none"
            dangerouslySetInnerHTML={{
              __html: feed?.feed_desc
                ? feed?.feed_desc.slice(0, 110) +
                  (feed?.feed_desc.length > 110
                    ? "<span style='color:#1daf76',text-decoration:'underline',cursor:'pointer''><u>...Read more </u> </span>"
                    : " ")
                : "",
            }}
          ></div>
          <div
            className="d-none d-sm-block"
            dangerouslySetInnerHTML={{
              __html: feed?.feed_desc
                ? feed?.feed_desc.slice(0, 220) +
                  (feed?.feed_desc.length > 220
                    ? "<span style='color:#1daf76',text-decoration:'underline',cursor:'pointer''><u>...Read more </u> </span>"
                    : "")
                : "",
            }}
          ></div>
        </div>
      )}
    </>
  );
};

export default FeedContent;
