import React from "react";
import { Modal } from "react-responsive-modal";
import success from "../../assets/Icons/success.svg";

const OtpSuccessModal = ({ open, closeModal, successRedirect }) => {
  return (
    <Modal
      open={open}
      onClose={closeModal}
      onHide={closeModal}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      closeOnEsc={false}
      backdrop="static"
      keyboard={false}
      center
      styles={{ modal: { width: "20em" } }}
    >
      <div>
        <div className="otp-container">
          <img
            src={success}
            style={{ marginTop: "10px", marginBottom: "15px" }}
            alt="timeline logo"
          />
        </div>
        <h4
          style={{ color: "#0DC400", marginTop: "20px", textAlign: "center" }}
        >
          Phone Number Verified Successfully
        </h4>
      </div>
      <div className="otp-container">
        <button
          onClick={successRedirect}
          style={{
            backgroundColor: "#ffffff",
            border: "1px solid gray",
            color: "black",
            padding: "11px",
            width: "205px",
            fontsize: "12px",
            marginBottom: "30px",
            borderRadius: "9px",
          }}
          className="submit-btn"
        >
          Continue to Dashboard
        </button>
      </div>
    </Modal>
  );
};

export default OtpSuccessModal;
