import { useState, useRef, useEffect } from "react";
import * as React from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Link, useParams, useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { Modal } from "react-responsive-modal";
import Progressbar from "../progressbar";
import { IoIosCloseCircle } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import "../profile/profile.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  smallRadioButton: {
    "& svg": {
      width: "1.5em",
      height: "1.5em",
    },
  },
});
const useStylesLabel = makeStyles((theme) => ({
  label: {
    fontSize: "1.5rem !important",
  },
}));
const styles = makeStyles((theme) => ({
  radio: {
    colorPrimary: {
      "&$checked": {
        color: "blue",
      },
    },
    checked: {},
  },
}));
const AwardsMembership = ({
  modalTitle,
  open,
  closeAwardsMembershipModal,
  getUserData,
  userInfo,
  editClicked,
  editedData,
}) => {
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const history = useHistory();
  const user_id = getDocId();
  const classes = useStyles();
  const classesLabel = useStylesLabel();
  const classesRadio = styles;
  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm();

  const [years, setYears] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState("awards");
  const [associations, setAssociations] = useState([]);
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    autoSuggestions();
  }, []);

  const autoSuggestions = async () => {
    const payload = { associations: "associations" };
    const res = await fetch(baseUrl + "v2/autoSuggestions", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();
    let associations = [];
    if (data.status === "success") {
      for (const association of data.data.associations) {
        associations.push({ value: association, label: association });
      }     
      setAssociations(associations);
      if(editClicked){        
        if (editedData.type == "award") {
          setSelectedValue("awards");
          setValue("title", editedData?.title);
          setValue("presented", editedData?.presented_at);
          console.log(editedData.year);
          if(editedData.year != undefined){
            setValue("year", {
              label: editedData?.year,
              value: editedData?.year,
            });
          }else{            
            setValue("year", '');
          }
        } else if (editedData.type == "membership") {
          setSelectedValue("memberships");
          setValue("association", {
            label: editedData?.title,
            value: editedData?.title,
          });
        }
      }
    }
    
    let minOffset = 0, maxOffset = 122;
    let thisYear = (new Date()).getFullYear();
    let allYears = [];
    for(let x = 0; x <= maxOffset; x++) {
        allYears.push({ value: thisYear - x, label: thisYear - x })
    }
    setYears(allYears);
  };
  const setEmptyValues = async () => {
      setValue("title", '');
      setValue("presented", '');
      setValue("year", '');
      setValue("association", '');
  }
  const addAwardMembership = async (post) => {
    console.log(post);
    setloaderSpinner(true);
    
    let payload = {
      user_id: user_id,
    };
    if (selectedValue == "awards") {
      let award={
        presented_at: post?.presented,
        title: post?.title,
        year: post.year == undefined?'':post?.year?.value,
        type: "award"
      }  
      payload.award_history = [award];
    } else if (selectedValue == "memberships") {
      let mem={
        title: post.association == undefined?'':post?.association?.value,
        type: "membership"
      }
      payload.membership_history = [mem];
    }
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/create", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      if (selectedValue == "awards") {
        toast.success("Award added");
      }else if (selectedValue == "memberships") {
        toast.success("Membership added");
      }
      reset();
      setEmptyValues();
      setSelectedValue(selectedValue);
      setloaderSpinner(false);
      getUserData();
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };

  const saveAwardMembership = async (post) => {
    console.log(post);
    setloaderSpinner(true);           
    let payload = {
      user_id: user_id,
    };
    
    if (selectedValue == "awards") {
      let award={
        presented_at: post?.presented,
        title: post?.title,
        year: post.year == undefined?'':post?.year?.value,
        type: "award"
      }  
      payload.award_history = [award];
    } else if (selectedValue == "memberships") {
      let mem={
        title: post.association == undefined?'':post?.association?.value,
        type: "membership"
      }
      payload.membership_history = [mem];
    }
    payload = encodeURIComponent(JSON.stringify(payload));
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/create", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      if (selectedValue == "awards") {
        toast.success("Award saved");
      }else if (selectedValue == "memberships") {
        toast.success("Membership saved");
      }
      closeAwardsMembershipModal();
      reset();
      setEmptyValues();
      setSelectedValue(selectedValue);
      setloaderSpinner(false);
      getUserData();
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };

  const updateAwardMembership = async (post) => {
    console.log(post);
    setloaderSpinner(true);
    let payload = {
      user_id: user_id,
    };
    if (editedData.type == "award") {
      let award={
        award_id:editedData.award_id,
        presented_at: post?.presented,
        title: post?.title,
        year: post.year == undefined?'':post?.year?.value,
        type: "award"
      }  
      payload.award_history = [award];
    } else if (editedData.type == "membership") {
      let mem={
        prof_mem_id:editedData.prof_mem_id,
        title: post.association == undefined?'':post?.association?.value,
        type: "membership"
      }
      payload.membership_history = [mem];
    }
    payload = encodeURIComponent(JSON.stringify(payload));
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/update", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      if (selectedValue == "awards") {
        toast.success("Award updated");
      }else if (selectedValue == "memberships") {
        toast.success("Membership updated");
      }
      closeAwardsMembershipModal();
      getUserData();
      reset();
      setSelectedValue(editedData.pub_type);
      setloaderSpinner(false);
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };
  return (
    <>
      <div>
        <form>
          <div className="container" style={{ padding: 0 }}>
            {!editClicked && (
              <div
                style={{
                  marginLeft: "30px",
                }}
              >
                <RadioGroup
                  row
                  name="controlled-radio-buttons-group"
                  value={selectedValue}
                  onChange={handleChange}
                  defaultValue="awards"
                >
                  <FormControlLabel
                    className={classes.smallRadioButton}
                    classes={classesLabel}
                    value="awards"
                    control={<Radio color="success" />}
                    label="Awards"
                  />
                  <FormControlLabel
                    className={classes.smallRadioButton}
                    classes={classesLabel}
                    value="memberships"
                    control={<Radio color="success" />}
                    label="Memberships"
                  />
                </RadioGroup>
              </div>
            )}
            {selectedValue == "awards" && <div
              className="row"
              style={{
                marginTop:editClicked?"1.50em":"0.50em",
                marginBottom: "0em",
                background: "#ffffff",
                borderRadius: "9px",
                height: "2.5em",
                border: "1px solid grey",
                width: "300px",
                marginLeft: "30px",
              }}
            >
              <div className="col-12" style={{ paddingLeft: "0px" }}>
                <input
                  style={{
                    marginTop: "0.50em",
                    paddingLeft: "5px",
                    background: "inherit",
                    width: "100%",
                    border: "none",
                  }}
                  type="text"
                  {...register("title", {
                    required: true,
                    maxLength:500,
                    pattern: /^[''{}&. a-zA-Z_-]*$/,
                  })}
                  placeholder="Award Title *"
                />
              </div>
            </div>}
            {selectedValue == "awards" && errors.title?.type === "pattern" && (
              <div className="row error-title" style={{margin:"5px"}}>
                <span className="error-title-text" style={{ marginLeft: "30px",fontSize:"12px" }}>
                Only alpha characters .,''{}& or - are allowed
                </span>
              </div>
            )}
            {selectedValue == "awards" && errors.title?.type === "required" && (
              <div className="row error-title" style={{margin:"5px"}}>
                <span className="error-title-text" style={{ marginLeft: "30px",fontSize:"12px" }}>
                  Please enter the title
                </span>
              </div>
            )}
            {selectedValue == "awards" && errors.title?.type === "maxLength" && (
              <div className="row error-title" style={{margin:"5px"}}>
              <span className="error-title-text" style={{ marginLeft: "30px",fontSize:"12px" }}>
                Please enter less than 500 characters
              </span>
              </div>
            )}
            {selectedValue == "awards" &&  <div
              className="row"
              style={{
                marginTop: "1em",
                marginBottom: "0em",
                background: "#ffffff",
                borderRadius: "9px",
                height: "2.5em",
                border: "1px solid grey",
                width: "300px",
                marginLeft: "30px",
              }}
            >
              <div className="col-12" style={{ paddingLeft: "0px" }}>
                <input
                  style={{
                    marginTop: "0.50em",
                    paddingLeft: "5px",
                    background: "inherit",
                    width: "100%",
                    border: "none",
                  }}
                  type="text"
                  {...register("presented", {
                    maxLength:100,
                    pattern: /^[''{}&. a-zA-Z_-]*$/,
                  })}
                  placeholder="Presented At"
                />
              </div>
            </div>}
            {selectedValue == "awards" && errors.presented?.type === "pattern" && (
              <div className="row error-title" style={{margin:"5px"}}>
                <span className="error-title-text" style={{ marginLeft: "30px",fontSize:"12px" }}>
                Only alpha characters .,''{}& or - are allowed
                </span>
              </div>
            )}
            {selectedValue == "awards" && errors.presented?.type === "maxLength" && (
              <div className="row error-title" style={{margin:"5px"}}>
              <span className="error-title-text" style={{ marginLeft: "30px",fontSize:"12px" }}>
                Please enter less than 100 characters
              </span>
              </div>
            )}
            <div
              style={{
                display: "flex",
              }}
            >
             {selectedValue == "awards" && <div
                style={{
                  borderRadius: "9px",
                  marginTop: "1em",
                  marginBottom: "0em",
                  background: "#ffffff",
                  borderRadius: "9px",
                  height: "2.5em",
                  border: "1px solid grey",
                  width: "300px",
                  marginLeft: "30px",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    paddingLeft: "0px",
                    marginTop: "-10px",
                  }}
                >
                  <Controller
                    name="year"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={years}
                        placeholder="Year"
                        styles={{
                          control: (style) => ({
                            ...style,
                            background: "inherit",
                            padding: "10px 10px 10px 0",
                            border: 0,
                            boxShadow: "none",
                          }),
                          valueContainer: (style) => ({
                            ...style,
                            padding: 2,
                          }),
                          placeholder: (style) => ({
                            ...style,
                            fontSize: "13px",
                          }),
                          container: (style) => ({
                            ...style,
                            width: "100%",
                          }),
                          menuList: (style) => ({
                            ...style,
                            height: "8em",
                          }),
                        }}
                      />
                    )}
                  ></Controller>
                </div>
              </div>}
              
              {selectedValue == "memberships" && <div
                style={{
                  borderRadius: "9px",
                  marginTop: "1em",
                  marginBottom: "0em",
                  background: "#ffffff",
                  borderRadius: "9px",
                  height: "2.5em",
                  border: "1px solid grey",
                  width: "300px",
                  marginLeft: "30px",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    paddingLeft: "0px",
                    marginTop: "-10px",
                  }}
                >
                  <Controller
                    name="association"
                    control={control}
                    rules={{ required: true}}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={associations}
                        placeholder="e.g Indian Medical Association"
                        styles={{
                          control: (style) => ({
                            ...style,
                            background: "inherit",
                            padding: "10px 10px 10px 0",
                            border: 0,
                            boxShadow: "none",
                          }),
                          valueContainer: (style) => ({
                            ...style,
                            padding: 2,
                          }),
                          placeholder: (style) => ({
                            ...style,
                            fontSize: "14px",
                          }),
                          container: (style) => ({
                            ...style,
                            width: "100%",
                          }),
                          menuList: (style) => ({
                            ...style,
                            height: "9em",
                          }),
                        }}
                      />
                    )}
                  ></Controller>
                </div>
                {errors.association && (
            <div className="row error-title" style={{marginTop:"15px"}}>
              <span className="error-title-text" style={{ marginLeft: "0px",fontSize:"12px" }}>
              Please add your membership to save to your profile
              </span>
            </div>
          )}
              </div>}
              
          
              {!editClicked && (
                <div
                  style={{
                    borderRadius: "9px",
                    marginTop: "1em",
                    height: "2.5em",
                    marginLeft: "30px",
                  }}
                >
                  <button
                    style={{
                      border: "1px solid rgb(30 138 121)",
                      padding: "0.9rem 0.7rem",
                      borderRadius: "5px",
                      float: "right",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      onClick={handleSubmit(addAwardMembership)}
                    >
                      <MdAdd size={20} />
                      <span>Add One More</span>
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>

        </form>
        
        </div>

        <div>
          {" "}
          <Progressbar
            show={loaderSpinner}
            imageUrl={url}
            height="90"
            width="90"
            alignment="middle"
            alttext="Loading..."
          />
        </div>
      <div style={{position: "absolute",marginTop:editClicked?"58%":"61%"}}>
              {!editClicked && (
            <div style={{ margin: "0px 30px" }}>
              <button
                type="submit"
                className="profile-save-button"
                style={{ height: "2.5em" }}
                onClick={handleSubmit(saveAwardMembership)}
              >
                Save
              </button>
              <button
                className="profile-close-button"
                style={{ height: "2.5em" }}
                onClick={closeAwardsMembershipModal}
              >
                Close
              </button>
            </div>
          )}
          {editClicked && (
            <div style={{ margin: "0px -15px" }}>
              <button
                type="submit"
                className="pub-close-button"
                onClick={closeAwardsMembershipModal}
                style={{ height: "2.5em" }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="pub-save-button"
                onClick={handleSubmit(updateAwardMembership)}
                style={{ height: "2.5em" }}
              >
              {selectedValue == "awards" && <span>Update Award</span>} 
              {selectedValue == "memberships" && <span>Update Membership</span>}   
              </button>
            </div>
          )}

      </div>
  </>
  );
};

export default AwardsMembership;

