import { useState, useRef, useEffect } from "react";
import upload from "../../assets/dashboard_icons/social-icons/Camera.svg";
import ReactAudioPlayer from "react-audio-player";
import {
  config,
  getDocId,
  getTimeAndDate,
  showUnicodeChars,
} from "../../utils/utils";
import editIcon from "../../assets/dashboard_icons/social-icons/Icon material-edit.svg";
import deleteIcon from "../../assets/dashboard_icons/social-icons/Icon metro-bin.svg";
import sendIcon from "../../assets/dashboard_icons/social-icons/Icon material-done.svg";
import S3 from "react-aws-s3";
import toast from "react-hot-toast";
import profile_pic_placeholder from "../../assets/profile_icons/default-profile.png";
import Progressbar from "../../progressbar";

const Comment = ({
  comment,
  deleteComment,
  updateComment,
  feed_id,
  channel_id,
  social_interaction_id,
}) => {
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const doc_id = getDocId();
  // const profile_pic_placeholder =
  //   "assets/images/profile/src_assets_images_Avatar Icon_30x30.png";
  const [isfileUploaded, setIsFileUploaded] = useState(false);
  const fileInput = useRef(null);

  const [editComment, setEditComment] = useState(comment.comment);
  const [isEditingComment, setIsEditingComment] = useState(false);

  const [attachments, setAttachments] = useState(comment.attachment_details);
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";

  useEffect(() => {
    if (isEditingComment) setIsFileUploaded(false);
  }, [isEditingComment]);

  useEffect(() => {
    setAttachments(comment.attachment_details);
  }, [comment.attachment_details]);

  useEffect(() => {
    setEditComment(comment.comment);
  }, [comment.comment]);

  const submitComment = async (e) => {
    e.preventDefault();

    // setImgPreviewUrl("");

    if (attachments.length === 0 && editComment === "") {
      toast.error("Cannot post empty comment", {
        appearance: "error",
        autoDismiss: true,
      });
      // setEditComment(comment.comment);
      // setIsEditingComment(false);
      return;
    }

    let attachmentsToSend = [];
    if (isfileUploaded) {
      let file = fileInput.current.files[0];

      let newFileName = file.name;
      let ext = getExtension(newFileName);

      let type = file.type;
      if (type.includes("image")) type = "image";
      else if (type.includes("video")) type = "video";
      else if (type.includes("audio")) type = "audio";
      else if (type.includes("pdf")) type = "pdf";

      setloaderSpinner(true);
      const ReactS3Client = new S3(config);
      const data = await ReactS3Client.uploadFile(file, newFileName);
      console.dir(data);
      setloaderSpinner(false);
      const attachment = {
        attachment_type: type,
        attachment_extension: ext,
        attachment_original_url: data.location,
      };
      console.dir(attachment);
      attachmentsToSend.push(attachment);
    } else {
      setloaderSpinner(false);
      attachmentsToSend = [...attachments];
    }

    updateComment(
      feed_id,
      channel_id,
      social_interaction_id,
      editComment,
      attachmentsToSend
    );
    setIsEditingComment(false);
  };

  const userText = (string) => {
    var count = 15;
    if (string)
      return (
        string.charAt(0).toUpperCase() +
        string.slice(1, 15) +
        (string.length > count ? "..." : "")
      );
  };

  const showImagePreview = () => {
    let type = fileInput.current.files[0].type;
    if (type.includes("image")) type = "image";
    else if (type.includes("video")) type = "video";
    else if (type.includes("audio")) type = "audio";
    else if (type.includes("pdf")) type = "pdf";

    if (type === "image") {
      setIsFileUploaded(true);
      setAttachments((prev) => [
        ...prev,
        {
          attachment_original_url: URL.createObjectURL(
            fileInput.current.files[0]
          ),
          attachment_type: type,
        },
      ]);
    } else {
      toast.error("Only images are allowed");
    }
  };

  const getExtension = (fileName) => {
    const lastDot = fileName.lastIndexOf(".");
    const ext = fileName.substring(lastDot + 1);
    return ext;
  };

  const deleteUploadedImage = (e) => {
    setloaderSpinner(true);
    e.preventDefault();
    fileInput.current.value = "";
    setAttachments((prev) => {
      let newAttachents = prev;
      newAttachents.pop();
      return [...newAttachents];
    });
    setloaderSpinner(false);
    setIsFileUploaded(false);
  };

  // const showUnicodeChars = (text) => {
  //   let res = JSON.stringify(text);
  //   res = res.replace(/\\\\u/g, "\\u");
  //   res = JSON.parse(res);
  //   return res;
  // };

  return (
    <div>
      <div>
        <Progressbar
          show={loaderSpinner}
          imageUrl={url}
          height="90"
          width="90"
          alignment="middle"
          alttext="Loading..."
        />
      </div>
      <div className="comment">
        <img
          className="comment-image"
          src={
            comment.profile_pic_small_url
              ? comment.profile_pic_small_url
              : profile_pic_placeholder
          }
        />
        <div className="comment-info-container">
          <div className="comment-poster">
            {comment.doc_id === parseInt(doc_id)
              ? "You"
              : `${comment.user_salutation} ${userText(
                  comment.user_full_name
                )}`}
          </div>
          <div className="comment-date">
            {getTimeAndDate(comment.timestamp)}
          </div>
          <div className="comment-text" style={{ height: "max-content" }}>
            {isEditingComment ? (
              <div style={{ backgroundColor: "white", height: "100%" }}>
                <input
                  value={showUnicodeChars(editComment)}
                  onChange={(e) => setEditComment(e.target.value)}
                  style={{
                    width: "fit-content",
                    backgroundColor: "inherit",
                    border: "none",
                  }}
                />

                {attachments.length !== 0 && (
                  <div className="comment-attachments">
                    {attachments.map((attachment, index) => (
                      <span key={index}>
                        {attachment.attachment_type === "image" && (
                          <img
                            className="attachment"
                            src={attachment.attachment_original_url}
                          />
                        )}
                        {attachment.attachment_type === "video" && (
                          <video
                            src={attachment.attachment_original_url}
                            className="attachment"
                            controls="controls"
                          />
                        )}
                        {attachment.attachment_type === "audio" && (
                          <ReactAudioPlayer
                            controls
                            src={attachment.attachment_original_url}
                          />
                        )}
                      </span>
                    ))}
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    margin: "1em",
                  }}
                >
                  {
                    <div>
                      {attachments.length !== 0 ? (
                        <p style={{ display: "flex" }}>
                          {" "}
                          <img
                            style={{
                              width: "15px",
                              height: "15px",
                              cursor: "pointer",
                            }}
                            onClick={(e) => deleteUploadedImage(e)}
                            src={deleteIcon}
                          />
                          <span
                            style={{
                              fontSize: "10px",
                              color: "red",
                              marginTop: "4px",
                            }}
                          >
                            Remove Image
                          </span>
                        </p>
                      ) : (
                        <>
                          <p style={{ display: "flex" }}>
                            {" "}
                            <img
                              src={upload}
                              style={{
                                width: "15px",
                                height: "15px",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                fileInput.current.click();
                              }}
                              className="comment-input-image"
                            />{" "}
                            <span
                              style={{
                                fontSize: "10px",
                                color: "black",
                                marginTop: "4px",
                              }}
                            >
                              Upload Image
                            </span>
                          </p>
                        </>
                      )}
                      <input
                        type="file"
                        ref={fileInput}
                        accept=".jpg , .jpeg, .png"
                        onChange={() => showImagePreview()}
                        style={{
                          display: "none",
                        }}
                        className="submit-btn"
                      />
                    </div>
                  }

                  <div
                    style={{ height: "1em", width: "1em" }}
                    onClick={(e) => {
                      submitComment(e);
                    }}
                  >
                    <img src={sendIcon} />
                  </div>
                </div>
              </div>
            ) : (
              showUnicodeChars(comment.comment)
            )}
          </div>

          {comment.attachment_details.length !== 0 && !isEditingComment && (
            <div className="comment-attachments">
              {comment.attachment_details.map((attachment, index) => (
                <span key={index}>
                  {attachment.attachment_type === "image" && (
                    <img
                      className="attachment"
                      style={{ height: "100%" }}
                      src={attachment.attachment_original_url}
                    />
                  )}
                  {attachment.attachment_type === "video" && (
                    <video
                      src={attachment.attachment_original_url}
                      // className="attachment"
                      style={{ height: "100%" }}
                      controls="controls"
                    />
                  )}
                  {attachment.attachment_type === "audio" && (
                    <ReactAudioPlayer
                      controls
                      src={attachment.attachment_original_url}
                    />
                  )}
                </span>
              ))}
            </div>
          )}
          {comment.doc_id === parseInt(doc_id) && !isEditingComment && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "20px",
              }}
            >
              <div
                style={{ height: "1em", width: "1em", marginBottom: "5px" }}
                onClick={() => {
                  deleteComment(feed_id, channel_id, social_interaction_id);
                }}
              >
                <div className="fullview-iocns">
                  <img
                    src={deleteIcon}
                    style={{ width: "15px", height: "15px", cursor: "pointer" }}
                  ></img>
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "2px",
                      marginLeft: "5px",
                    }}
                  >
                    Delete
                  </span>
                </div>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div
                style={{ height: "1em", width: "1em" }}
                onClick={() => setIsEditingComment(true)}
              >
                <div className="fullview-iocns">
                  <img
                    src={editIcon}
                    style={{ width: "15px", height: "15px", cursor: "pointer" }}
                  ></img>
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "2px",
                      marginLeft: "5px",
                    }}
                  >
                    Edit
                  </span>
                </div>
              </div>
              {/* <div >
            <img src={sendIcon}></img></div> &nbsp;&nbsp;&nbsp; */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Comment;
