import React, { useState, useEffect } from "react";
// import { useToasts } from "react-toast-notifications";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { Link, useHistory } from "react-router-dom";
import EmailInput from "./ForgotPasswordComponents/EmailInput";
import PhoneInput2 from "./ForgotPasswordComponents/PhoneInput";
import ResetPassword from "./ForgotPasswordComponents/ResetPassword";
import useCountDown from "react-countdown-hook";
import { formatTime, getHeaders, ttlForDocId } from "../utils/utils";
//import { ttlForDocId } from "../utils/Utils";
import back from "../assets/sign_up_and_sign_in_icons/Back.svg";
import select from "../assets/sign_up_and_sign_in_icons/Select.svg";
import unselect from "../assets/sign_up_and_sign_in_icons/Unselect.svg";
import logo from "../assets/logo4.png";
import bgImage from "../assets/pre_login_bg.png";
import artwork from "../assets/pre_login_artwork.png";
import Progressbar from "../progressbar";
const ForgotPassword = () => {
  const history = useHistory();
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const [otpOption, setOtpOption] = useState({
    otpMode: "PHONE",
  });
  const [docId, setDocId] = useState();
  const [authToken, setAuthToken] = useState();

  const [recievedOtp, setRecievedOtp] = useState("");

  const [inputOtp, setInputOtp] = useState("");

  const [isOtpSent, setIsOtpSent] = useState(false);

  const [loginDetails, setLoginDetails] = useState({});

  const [contactInputError, setContactInputError] = useState({
    error: false,
  });

  const [confirmPasswordError, setConfirmPasswordError] = useState({
    error: false,
  });

  const [otpVerified, setOtpVerified] = useState("");

  const [enableResendOtp, setEnableResenOtp] = useState(false);

  const initialTime = 1800 * 1000;
  const interval = 1000;
  const [timeLeft, { start }] = useCountDown(initialTime, interval);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (to) => {
    reset();
    setContactInputError({
      error: false,
    });
    return setOtpOption({
      ...otpOption,
      otpMode: to,
    });
  };

  const sendOTP = async (formData) => {
    setInputOtp("");
    const payload = {
      contact:
        otpOption.otpMode === "PHONE"
          ? formData.phone.trim().substring(1)
          : formData.email.trim(),
      contactType: otpOption.otpMode,
    };
    setloaderSpinner(true);
    const res = await fetch(baseUrl + "forgotPassword", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      setloaderSpinner(false);
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();
    setloaderSpinner(false);
    if (data.status === "success") {
      start();
      setIsOtpSent(true);
      setRecievedOtp(data.otp);
      setLoginDetails({
        ...loginDetails,
        email: data.cnt_email,
      });
      setContactInputError({
        error: false,
      });
      toast.success("otp sent");
      setEnableResenOtp(false);
      setTimeout(function () {
        setEnableResenOtp(true);
      }, 30000);
    } else {
      console.error(data.errorMsg);
      setContactInputError({
        error: true,
        errorMsg: data.errorMsg,
      });
    }
  };

  const checkOtp = (e) => {
    setloaderSpinner(true);
    e.preventDefault();
    if (timeLeft === 0) return;
    if (recievedOtp === inputOtp) {
      setloaderSpinner(false);
      login();
    } else {
      setloaderSpinner(false);
      setOtpVerified("failed");
    }
  };

  const login = async () => {
    const payload = {
      app_uname: loginDetails.email,
      app_pass: inputOtp,
      stay_logged_in: true,
      isOTP: true,
    };
    setloaderSpinner(true);
    const res = await fetch(baseUrl + "login", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      setloaderSpinner(false);
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();
    setloaderSpinner(false);

    if (data.status === "success") {
      if (data.data.doctor_valid) {
        setDocId(data.data.doc_id);
        setAuthToken(data.data.security_token);
        setOtpVerified("success");
      } else {
      }
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };

  const resetPassword = async (formData) => {
    const doc_id = docId;

    let header = headers;

    header["x-auth-token"] = authToken;

    if (formData.pass !== formData.confirmPass) {
      setConfirmPasswordError({
        error: true,
        errorMsg: "Password doesn't match",
      });
      return;
    } else {
      setConfirmPasswordError({
        error: false,
      });
    }
    const payload = {
      doc_id,
      password: formData.pass,
    };
    setloaderSpinner(true);
    const res = await fetch(baseUrl + "updatePassword", {
      method: "POST",
      headers: header,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      setloaderSpinner(false);
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();
    setloaderSpinner(false);
    if (data.status === "success") {
      const expiryDate = new Date().getTime() + ttlForDocId;
      localStorage.setItem("expiry_date", expiryDate);
      localStorage.setItem("doc_id", docId);
      localStorage.setItem("auth_token", authToken);
      history.push("/");
    } else toast.error(data.errorMsg);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ height: "100vh" }}>
          <div className="col-sm-5 d-none d-sm-block">
            <div
              className="timeline-logo"
              style={{ margin: "10px 0px 0px 20px" }}
            >
              <Link to="/">
                <img
                  src={logo}
                  style={{ width: "150px", height: "auto" }}
                  alt="timeline logo"
                />
              </Link>
            </div>
            <div>
              <div style={{ padding: "1em", margin: "2em 0em" }}>
                <img
                  className="login-image"
                  src={artwork}
                  alt="timeline logo"
                  style={{
                    width: "45%",
                    height: "auto",
                    opacity: "1",
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>
              <div style={{ width: "70%", margin: "auto" }}>
                <h3
                  style={{
                    color: "#1B8C7A",
                    font: "normal normal bold 2em/1.2em Roboto",
                    letterSpacing: "0px",
                    opacity: "1",
                    textAlign: "center",
                    width: "auto",
                    padding: "0.5em",
                  }}
                >
                  Welcome to WhiteCoats
                </h3>
                <h4
                  style={{
                    font: "normal normal normal 1.5em/1.2em Roboto",
                    color: "#6D6D6D",
                    textAlign: "center",
                    opacity: "1",
                  }}
                >
                  A Digital Networking and Collaboration Platform for Doctors
                </h4>
                <br />
                <h6
                  style={{
                    font: "normal normal normal 1em Roboto",
                    color: "#6D6D6D",
                    textAlign: "center",
                    opacity: "1",
                  }}
                >
                  Clinical Discussions | Case Reports | Guidelines | Jobs | Drug
                  References & much more
                </h6>
              </div>
            </div>
          </div>

          <div
            className="col-sm-7 bg-image"
            style={{
              backgroundImage: `url(${bgImage})`,
              height: "auto",
            }}
          >
            <div className="row">
              <div className="col-sm-2"></div>
              <div className="col-sm-8" style={{ padding: "0em 2em" }}>
                <div className="form-group" style={{ marginTop: "20%" }}>
                  <img
                    src={back}
                    onClick={() => history.push("/login")}
                    alt="back"
                  />

                  <div style={{ display: "flex", marginTop: "30px" }}>
                    <h1
                      style={{
                        marginRight: "30px",
                        fontSize: "2em",
                        color: "white",
                        fontWeight: "700px",
                        cursor: "pointer",
                      }}
                    >
                      Reset Password
                    </h1>
                  </div>
                  <div
                    style={{
                      border: "1px solid #FFEE00",
                      backgroundColor: "#FFEE00",
                      opacity: "1",
                      width: "15em",
                      height: "0px",
                      display: "inline-block",
                    }}
                  ></div>
                </div>
                <div className="form-group">
                  <h4 style={{ fontSize: "15px", color: "white" }}>
                    How would you like to reset your password?
                  </h4>
                </div>

                {!isOtpSent && (
                  <div style={{ display: "flex", marginTop: "20px" }}>
                    <fieldset
                      className="radioInput"
                      style={{ marginRight: "80px" }}
                    >
                      {otpOption.otpMode === "PHONE" ? (
                        <img src={select} alt="get sms selected" />
                      ) : (
                        <img
                          src={unselect}
                          alt="get sms unselected"
                          onClick={() => handleChange("PHONE")}
                        />
                      )}
                      <span
                        style={{
                          fontSize: "15px",
                          color: "white",
                          left: "5px",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        Get SMS
                      </span>
                    </fieldset>
                    <fieldset className="radioInput">
                      {otpOption.otpMode === "EMAIL" ? (
                        <img src={select} alt="get email selected" />
                      ) : (
                        <img
                          src={unselect}
                          alt="get email unselected"
                          onClick={() => handleChange("EMAIL")}
                        />
                      )}
                      {/* <label
                        className="radioLabel"
                        htmlFor="email"
                        style={{ color: "white" }}
                      > */}
                      <span
                        style={{
                          fontSize: "15px",
                          color: "white",
                          left: "5px",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        Get Email
                      </span>
                      {/* </label> */}
                    </fieldset>
                  </div>
                )}

                {otpVerified === "success" ? (
                  <ResetPassword
                    confirmPasswordError={confirmPasswordError}
                    resetPassword={resetPassword}
                  />
                ) : (
                  <form onSubmit={handleSubmit(sendOTP)}>
                    {otpOption.otpMode === "PHONE" ? (
                      <PhoneInput2
                        contactInputError={contactInputError}
                        register={register}
                        isOtpSent={isOtpSent}
                        errors={errors}
                        control={control}
                      />
                    ) : (
                      <EmailInput
                        contactInputError={contactInputError}
                        register={register}
                        isOtpSent={isOtpSent}
                        errors={errors}
                      />
                    )}

                    {isOtpSent && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="">
                            <span style={{ color: "white" }}>
                              An OTP has been sent
                            </span>
                          </div>
                          <div className="text-white">
                            <span
                              onClick={() => {
                                setIsOtpSent(false);
                                setOtpVerified("");
                              }}
                              style={{ color: "yellow", cursor: "pointer" }}
                            >
                              {otpOption.otpMode === "PHONE"
                                ? "Edit phone number "
                                : "Edit email"}
                            </span>
                          </div>
                        </div>

                        <div className="form-group">
                          <label style={{ color: "white" }}>Enter OTP</label>
                          <div style={{ display: "flex" }}>
                            <OtpInput
                              className="otp"
                              value={inputOtp}
                              onChange={(otp) => {
                                setOtpVerified("");
                                setInputOtp(otp);
                              }}
                              isInputNum="true"
                              inputStyle={{
                                width: "2em",
                                height: "35px",
                                backgroundColor: "whitesmoke",
                                border: "1px solid",
                              }}
                              numInputs={4}
                              separator={
                                <span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                              }
                            />

                            {enableResendOtp && (
                              <button
                                type="submit"
                                style={{
                                  marginLeft: "30px",
                                  color: "yellow",
                                  cursor: "pointer",
                                }}
                              >
                                Resend OTP?
                              </button>
                            )}
                          </div>
                        </div>
                        {timeLeft === 0 ? (
                          <div className="error-title">
                            <span className="error-title-text">
                              Otp Expired
                            </span>
                          </div>
                        ) : (
                          <span style={{ color: "white" }}>
                            OTP Expires in {formatTime(timeLeft)} mins
                          </span>
                        )}
                        {otpVerified === "failed" && (
                          <div className="error-title">
                            <span className="error-title-text">
                              Otp entered is incorrect
                            </span>
                          </div>
                        )}
                      </>
                    )}

                    {isOtpSent ? (
                      <div className="login-button">
                        <button
                          onClick={(e) => checkOtp(e)}
                          style={{
                            backgroundColor: "#0FB59B",
                            borderRadius: "10px",
                          }}
                          className="submit-btn"
                        >
                          Verify
                        </button>
                      </div>
                    ) : (
                      <div className="login-button">
                        <button
                          type="submit"
                          style={{
                            backgroundColor: "#0FB59B",
                            borderRadius: "10px",
                            height: "3em",
                          }}
                          className="submit-btn"
                        >
                          Request OTP
                        </button>
                      </div>
                    )}
                  </form>
                )}
                <div className="spinner-forgot">
                  <Progressbar
                    show={loaderSpinner}
                    imageUrl={url}
                    height="90"
                    width="90"
                    alignment="middle"
                    alttext="Loading..."
                  />
                </div>
              </div>
              <div className="col-sm-2"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
