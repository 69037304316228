import { useState, useEffect, useRef, useCallback } from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import AppBar from "@material-ui/core/AppBar";
// import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Feeds from "../dashboard/feeds/Feeds";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, withStyles } from "@material-ui/core";
import FullViewFeed from "../dashboard/feeds/FullViewFeed";
import { Modal } from "react-responsive-modal";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import Progressbar from "../progressbar";
import useCommunityFeedLoader from "../dashboard/hooks/useCommunityFeedLoader";
import { color } from "@mui/system";
import Box from "@mui/material/Box";
import { FaTimesCircle } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: 0,
      padding: "15px",
      marginBottom: "30px",
      webkitBoxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
      boxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
    },
    "& .MuiTypography-body1": {
      font: "normal normal normal 20px/20px Roboto",
      fontWeight: 400,
    },
    "& .PrivateTabIndicator-colorSecondary-4": {
      backgroundColor: "#1e8a79",
      border: "2px solid",
    },
    "& .PrivateTabIndicator-colorSecondary-6": {
      backgroundColor: "#2a8f80",
    },

    "& .MuiTabPanel-root": {
      padding: "2px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },

    "& .MuiBox-root": {
      padding: "0px",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: "#2a8f80",
      height: "4px",
    },
    "& .MuiTabPanel-root": {
      padding: "2px",
    },
    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      /* font-size: 11px; */
      font: "13px / 40px Roboto",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  test2: {
    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      /* font-size: 11px; */
      font: "13px / 40px Roboto",
      color: "#2a8f80",
    },
    "& .MuiTab-root": {
      minWidth: "30px",
    },
  },
  test1: {
    "& .MuiTabPanel-root": {
      padding: "2px",
    },
  },

  test: {
    "& .MuiTabPanel-root": {
      padding: "2px",
    },
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-around",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: 0,
      padding: "15px",
      marginBottom: "30px",
      webkitBoxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
      boxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
    },
    "& .MuiTypography-body1": {
      font: "normal normal normal 20px/20px Roboto",
      fontWeight: 400,
    },
    "& .PrivateTabIndicator-colorSecondary-4": {
      backgroundColor: "#1e8a79",
      border: "2px solid",
    },
    "& .PrivateTabIndicator-colorSecondary-6": {
      backgroundColor: "#2a8f80",
    },

    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-around",
    },

    "& .MuiBox-root": {
      padding: "0px",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: "#2a8f80",
      height: "4px",
    },
    "& .MuiTabPanel-root": {
      padding: "2px",
    },

    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      /* font-size: 11px; */
      font: "13px / 40px Roboto",
    },
    "& .MuiTab-root": {
      minWidth: "27px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Skilling = ({
  feeds,
  setFeeds,
  profile_pic,
  updateLikeCount,
  addComment,
  submitSurvey,
  commentsListInfo,
  openLikesModal,
  registerWebinar,
  user_verification_info,
  feedsErrorMsg,
  deleteComment,
  updateComment,
  updateViewCount,
}) => {
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";

  const doc_id = getDocId();

  const [value, setValue] = useState("9");

  const [clearIcon, setClearIcon] = useState(false);

  const clearFilters = () => {
    let newUncheckedArr = [];
    for (let i = 0; i < subCategoryChecked.length; i++)
      newUncheckedArr.push(false);
    setSubCategoryChecked(newUncheckedArr);
    setClearIcon(false);
    setSubCategoryId([]);
    setNextFeedsData((prev) => ({
      ...prev,
      pageNum: 0,
    }));
  };

  const applyFilter = (key, sub_category_id) => {
    if (subCategoryChecked[key] === true) {
      setSubCategoryChecked((prev) => {
        let newArray = [...prev];
        newArray[key] = false;
        return newArray;
      });

      let newSubCategoryId = subCategoryId.filter((x) => x != sub_category_id);
      setSubCategoryId(newSubCategoryId);
      setNextFeedsData((prev) => ({
        ...prev,
        pageNum: 0,
      }));

      if (newSubCategoryId.length === 0) setClearIcon(false);
      return;
    }

    let newCheckedArray = [...subCategoryChecked];
    // for (let i = 0; i < subCategoryChecked.length; i++)
    //   if (i === key) newCheckedArray.push(true);
    //   else newCheckedArray.push(false);
    newCheckedArray[key] = true;

    setSubCategoryId((prev) => [...prev, sub_category_id]);
    setSubCategoryChecked(newCheckedArray);
    setNextFeedsData((prev) => ({
      ...prev,
      pageNum: 0,
    }));
    setClearIcon(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [modalData, setModalData] = useState({});
  const fullViewFeedRef = useRef(null);
  const [openFullView, setOpenFullView] = useState(false);
  const handleOpenFullView = (feed) => {
    // window.scrollTo(0, 0);
    setModalData(feed);
    setOpenFullView(true);
  };
  const handleCloseFullView = () => {
    setOpenFullView(false);
  };

  const [subCategoryId, setSubCategoryId] = useState([]);
  const [nextFeedsData, setNextFeedsData] = useState({
    pageNum: 0,
    tabId: 9,
  });
  const {
    loading,
    error,
    channels,
    hasMore,
    categoryList,
    subCategoryChecked,
    setSubCategoryChecked,
  } = useCommunityFeedLoader(doc_id, nextFeedsData, setFeeds, subCategoryId);

  const observer = useRef(null);
  const loader = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setNextFeedsData((prev) => ({
            ...prev,
            pageNum: prev.pageNum + 1,
            // lastFeedTime: feeds[feeds.length - 1]?.feed_info?.updated_time,
          }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  const closeIcon = <img src={close}></img>;
  return (
    <div>
      {/* 
        {(categoryList && categoryList.length !== 0 )&& 
        <div style={{ marginBottom: '10px', padding: '0px 5px 0px 5px', border: '1px solid #efe5e5' }}>
          <TabList className={classes.test} variant="scrollable" scrollButtons="auto" TabIndicatorProps={{ style: { display: "none", minWidth: '27px' } }} onChange={handleChange} aria-label="tab example"  >
            {categoryList?.map((res, key) => (
              <p key={key} style={{ marginBottom: "0px", height: "20px" }}>
                <input type="checkbox" name="abc" checked={subCategoryChecked[key]} onClick={() => { test(key); setSubCategoryId(res.sub_category_id) }}></input>
                <Tab label={res.sub_category_name} value="8" className={subCategoryChecked[key] ? classes.test2 : classes.test1}></Tab>
              </p>))}
          </TabList>
        </div>} */}

      {categoryList && categoryList.length !== 0 && (
        <Box
          style={{
            marginBottom: "10px",
            padding: "0px 5px 0px 5px",
            border: "1px solid #efe5e5",
          }}
        >
          <div style={{ display: "flex" }}>
            {clearIcon && (
              <Tab
                label={
                  <div
                    onClick={() => {
                      clearFilters();
                    }}
                    // style={{ color: "#469b8d" }}
                  >
                    clear
                  </div>
                }
              />
            )}
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              style={{ textTransform: "none" }}
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              {categoryList.map((res, key) => (
                <Tab
                  key={key}
                  label={
                    // <div key={key}>
                    //   <div >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        style={{ margin: "0 8px 1px 0" }}
                        type="checkbox"
                        name="abc"
                        checked={subCategoryChecked[key]}
                        onClick={() => {
                          applyFilter(key, res.sub_category_id);
                          // setSubCategoryId(res.sub_category_id);
                        }}
                      />
                      <span>{res.sub_category_name}</span>
                    </div>
                    //   </div>
                    // </div>
                  }
                />
              ))}
            </Tabs>
          </div>
        </Box>
      )}
      <Feeds
        feeds={feeds}
        profile_pic={profile_pic}
        updateLikeCount={updateLikeCount}
        addComment={addComment}
        submitSurvey={submitSurvey}
        openFullView={handleOpenFullView}
        commentsListInfo={commentsListInfo}
        openLikesModal={openLikesModal}
        channels={channels}
        registerWebinar={registerWebinar}
        deleteComment={deleteComment}
        updateComment={updateComment}
        updateViewCount={updateViewCount}
        user_verification_info={user_verification_info}
      />
      {loading && (
        <div>
          {" "}
          <Progressbar
            show={true}
            imageUrl={url}
            height="90"
            width="90"
            alignment="middle"
            alttext="Loading..."
          />
        </div>
      )}
      {error && <div>{feedsErrorMsg}</div>}
      <div ref={loader} />

      <Modal
        open={openFullView}
        blockScroll={true}
        style={customStyles}
        onClose={handleCloseFullView}
        closeIcon={closeIcon}
        classNames={{ modal: "feedModel" }}
        ref={fullViewFeedRef}
      >
        <FullViewFeed
          profile_pic={profile_pic}
          close={handleCloseFullView}
          feed={modalData}
          setFeeds={setFeeds}
          addComment={addComment}
          updateLikeCount={updateLikeCount}
          submitSurvey={submitSurvey}
          updateViewCount={updateViewCount}
          channels={channels}
          deleteComment={deleteComment}
          updateComment={updateComment}
          openLikesModal={openLikesModal}
          openCommentMobileView={commentsListInfo}
          registerWebinar={registerWebinar}
          user_verification_info={user_verification_info}
        ></FullViewFeed>
      </Modal>
    </div>
  );
};

export default Skilling;
