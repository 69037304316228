import { useState, useEffect, useRef, useCallback, createContext } from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import { Link, useParams, useHistory } from "react-router-dom";
import { Tabs, Tab, withStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Card from "@mui/material/Card";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Badge from "@mui/material/Badge";
import { makeStyles } from "@material-ui/core/styles";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import Progressbar from "../progressbar";
import useChannelsLoader from "../dashboard/hooks/useChannelsLoader";
import ChannelMembers from "../channels/ChannelMembers";
import AboutChannel from "../channels/AboutChannel";
import ChannelFeeds from "../channels/ChannelFeeds";
import ChannelMedia from "../channels/ChannelMedia";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import getWindowDimensions from "../common/hooks/getWindowDimensions";
import send from "../assets/Icons/Icon feather-arrow-left@2x.png";
import PartnerChannelMedia from "../channels/PartnerChannelMedia";
import Createpost from "../createPost/Createpost";
import toast from "react-hot-toast";

export const FeedType = createContext("content");

const Partner = ({
  feeds,
  setFeeds,
  userInfo,
  handleUploadMci,
  handleVerificationPending,
}) => {
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const doc_id = getDocId();
  const headers = getHeaders();
  const classes = useStyles();
  const [value, setValue] = useState("5");
  const [tabid, setTabid] = useState(4);
  const [aboutData, setAboutData] = useState();
  const [showCommunity, setShowCommunity] = useState(false);
  const handleChange = (event, newValue) => {
    setTabid(newValue);
    setValue(newValue);
  };

  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );
  const [subCategoryId, setSubCategoryId] = useState(undefined);
  const [selectedChannel, setSelectedChannel] = useState();
  const [nextFeedsData, setNextFeedsData] = useState({
    pageNum: 0,
    tabId: 11,
  });
  const { loading, error, hasMore, channelsData } = useChannelsLoader(
    doc_id,
    nextFeedsData
  );
  const observer = useRef(null);
  const loader = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setNextFeedsData((prev) => ({
            ...prev,
            pageNum: prev.pageNum + 1,
            // lastFeedTime: feeds[feeds.length - 1]?.feed_info?.updated_time,
          }));
        }
      });
      if (node) observer.current.observe(node);
    },

    [loading, hasMore]
  );

  const [channels, setChannels] = useState([]);
  useEffect(() => {
    getChannels();
  }, []);

  const getChannels = async () => {
    const payload = {
      doc_id,
      last_feed_time: 0,
      load_next: true,
      pg_num: 0,
    };
    const res = await fetch(baseUrl + "v3/getDashBoardData", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
      return;
    });

    const data = await res.json();
    if (data.status === "success") {
      if (data.data.listofChannels)
        setChannels((prev) => {
          return [...prev, ...data.data.listofChannels];
        });
    } else toast.error(data.errorMsg);
  };

  // useEffect(() => {
  //   // getChannelsData();
  // }, []);
  const handleClick = (event) => {
    event.preventDefault();
    setShouldDisplayTimelIneFeeds(false);
  };

  const getChannelsData = async () => {
    const payload = {
      user_id: doc_id,
      tag_id: nextFeedsData.tabId,
      pg_num: nextFeedsData.pageNum,
      category_id: subCategoryId,
    };
    const res = await fetch(baseUrl + "v1/app/tag/content", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {});
    const data = await res.json();
    if (data.status === "success") {
      // setChannelsData(data.data.channels)
    }
  };
  const [shouldTimelIneFeeds, setShouldDisplayTimelIneFeeds] = useState(false);
  const [timelIneFeeds, setTimelIneFeeds] = useState([]);
  const getChannelTimeline = (e, data) => {
    setSelectedChannel(data);
    setShowCommunity(false);
    if (data.feed_provider_type === "Community") setShowCommunity(true);
    setShouldDisplayTimelIneFeeds(true);
  };
  const getTimelineFeedsData = async (items) => {
    const payload = {
      user_id: doc_id,
      channel_id: items.channel_id,
      last_feed_id: 0,
      load_next: "true",
    };
    const res = await fetch(baseUrl + "v3/channelTimeline", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {});
    const data = await res.json();
    if (data.status === "success") {
      // setChannelsData(data.data.channels)
    }
  };

  const getAboutChannel = async (items) => {
    const payload = {
      doc_id: doc_id,
      channel_id: items.channel_id,
    };
    const res = await fetch(baseUrl + "v2/community/about", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {});
    const data = await res.json();
    if (data.status === "success") {
      setAboutData(data.data.about_community);
    }
  };
  const closeIcon = <img src={close}></img>;
  return (
    <FeedType.Provider value={showCommunity ? "community" : "content"}>
      <div>
        {!shouldTimelIneFeeds && (
          <div>
            {channelsData.map((res) => (
              <Card
                sx={{
                  minHeight: "120px",
                  padding: "5px",
                  marginBottom: "15px",
                  overflow: "visible",
                }}
                className={classes.test}
                key={res.channel_id}
              >
                <div className="container" style={{ marginBottom: "0px" }}>
                  <div className="row">
                    <div
                      className="col-4"
                      onClick={(e) => {
                        getChannelTimeline(e, res);
                      }}
                      style={{ color: "#1daf76", cursor: "pointer" }}
                    >
                      <img
                        style={{
                          maxWidth: "155px",
                          height: "120px",
                          width: "100%",
                        }}
                        src={res.channel_logo}
                      />
                    </div>

                    <div className="col-6" style={{ paddingLeft: "0px" }}>
                      <p style={{ overflowWrap: "break-word" }}> </p>
                      <div style={{ overflowWrap: "break-word" }}>
                        <h3
                          onClick={(e) => {
                            getChannelTimeline(e, res);
                          }}
                          style={{ color: "#1daf76", cursor: "pointer" }}
                        >
                          {res.feed_provider_name}
                        </h3>
                        <h5 style={{ color: "black", marginTop: "5px" }}>
                          {res.members_count === 1
                            ? res.members_count > 1
                              ? `Members ` + res.members_count
                              : `Member ` + res.members_count
                            : res.members_count === 0
                            ? null
                            : `Members ` + res.members_count}
                        </h5>
                      </div>
                      {res.is_admin === true &&
                        res.feed_provider_type.toLowerCase() ===
                          "community" && (
                          <Createpost
                            userInfo={userInfo}
                            handleUploadMci={handleUploadMci}
                            handleVerificationPending={
                              handleVerificationPending
                            }
                            channels={channels}
                            type="button"
                            home="false"
                            selectedChannel={res.channel_id}
                          />
                        )}
                    </div>
                    <div className="col-2">
                      <Badge
                        badgeContent={res.unread_feeds}
                        color="success"
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        style={{ zIndex: "0" }}
                      ></Badge>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
            {loading && (
              <div>
                <Progressbar
                  show={true}
                  imageUrl={url}
                  height="90"
                  width="90"
                  alignment="middle"
                  alttext="Loading..."
                />
              </div>
            )}
            {error && <div>Error..</div>}
            <div ref={loader} />
          </div>
        )}
        {shouldTimelIneFeeds && (
          <div>
            {windowDimensions.width <= 425 ? (
              <header id="header d-block d-lg-none">
                <div className="container">
                  <div className="navbar-header">
                    <div className="mobile-header-wrapper sticky d-block d-lg-none">
                      <div
                        className="position-relative "
                        style={{
                          backgroundColor: "#fff",
                          height: "60px",
                          textAlign: "center",
                        }}
                      >
                        <div className="mobile-responsive">
                          <div className="brand-logo ">
                            <div className="row">
                              <div className="col-3">
                                <Link>
                                  <img
                                    style={{
                                      cursor: "pointer",
                                      marginTop: "10px",
                                      marginRight: "40px",
                                    }}
                                    onClick={handleClick}
                                    src={send}
                                    alt="back"
                                  />
                                </Link>
                              </div>
                              <div className="col-6">
                                <p style={{ marginTop: "20px" }}>
                                  {selectedChannel?.feed_provider_name}
                                </p>
                              </div>
                              <div className="col-2"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
            ) : (
              <AppBar
                className="mL"
                position="sticky"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  marginBottom: "15px",
                  top: "112px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    cursor: "pointer",
                    padding: "10px",
                  }}
                >
                  <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        style={{ font: "14px Roboto" }}
                        underline="hover"
                        key="1"
                        color="inherit"
                        href="/"
                        onClick={handleClick}
                      >
                        Partner
                      </Link>
                      <Typography
                        style={{ font: "14px Roboto" }}
                        key="2"
                        color="text.primary"
                      >
                        {selectedChannel?.feed_provider_name}
                      </Typography>
                    </Breadcrumbs>
                  </div>
                </div>
              </AppBar>
            )}
            <TabContext
              style={{ padding: "0px" }}
              value={value}
              indicatorColor={{ backgroundColor: "green" }}
              className={classes.skill}
            >
              <AppBar
                className="mL"
                position="sticky"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  marginBottom: "15px",
                  top: "106px",
                }}
              >
                {!showCommunity && (
                  <div style={{ padding: "0px" }}>
                    <TabList
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="tab example"
                      className={classes.skill}
                    >
                      <Tab label="About" value="4" />
                      <Tab label="Feeds" value="5" />
                      <Tab label="Media" value="7" />
                    </TabList>
                  </div>
                )}
                {showCommunity && (
                  <div style={{ padding: "0px" }}>
                    <TabList
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="tab example"
                      className={classes.skill}
                    >
                      <Tab label="About" value="4" />
                      <Tab label="Feeds" value="5" />
                      <Tab label="Members" value="6" />
                      <Tab label="Media" value="7" />
                    </TabList>
                  </div>
                )}
              </AppBar>
              <TabPanel value="4" className={classes.skill}>
                <AboutChannel selectedChannel={selectedChannel} />
              </TabPanel>
              <TabPanel value="5" className={classes.skill}>
                <ChannelFeeds
                  channel={selectedChannel}
                  channels={channels}
                  feeds={feeds}
                  setFeeds={setFeeds}
                  userInfo={userInfo}
                  handleUploadMci={handleUploadMci}
                  handleVerificationPending={handleVerificationPending}
                />
              </TabPanel>
              {showCommunity && (
                <>
                  <TabPanel value="6" className={classes.skill}>
                    <ChannelMembers selectedChannel={selectedChannel} />
                  </TabPanel>
                </>
              )}
              <TabPanel value="7" className={classes.skill}>
                <PartnerChannelMedia channel={selectedChannel} />
              </TabPanel>
            </TabContext>
          </div>
        )}
      </div>
    </FeedType.Provider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: 0,
      padding: "15px",
      marginBottom: "30px",
      webkitBoxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
      boxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
    },
    "& .MuiTypography-body1": {
      font: "normal normal normal 20px/20px Roboto",
      fontWeight: 400,
    },
    "& .PrivateTabIndicator-colorSecondary-4": {
      backgroundColor: "#1e8a79",
      border: "2px solid",
    },
    "& .PrivateTabIndicator-colorSecondary-6": {
      backgroundColor: "#2a8f80",
    },

    "& .MuiTabPanel-root": {
      padding: "2px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },

    "& .MuiBox-root": {
      padding: "0px",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: "#2a8f80",
      height: "4px",
    },
    "& .MuiTabPanel-root": {
      padding: "2px",
    },
    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      /* font-size: 11px; */
      font: "13px / 40px Roboto",
    },
    "& .MuiTab-root": {
      minWidth: "127px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  test: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-around",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: 0,
      padding: "15px",
      marginBottom: "30px",
      webkitBoxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
      boxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
    },
    "& .MuiTypography-body1": {
      font: "normal normal normal 20px/20px Roboto",
      fontWeight: 400,
    },
    "& .PrivateTabIndicator-colorSecondary-4": {
      backgroundColor: "#1e8a79",
      border: "2px solid",
    },
    "& .PrivateTabIndicator-colorSecondary-6": {
      backgroundColor: "#2a8f80",
    },

    "& .MuiTabPanel-root": {
      padding: "2px",
    },
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-around",
    },

    "& .MuiBox-root": {
      padding: "0px",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: "#2a8f80",
      height: "4px",
    },
    "& .MuiTabPanel-root": {
      padding: "2px",
    },

    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      /* font-size: 11px; */
      font: "13px / 40px Roboto",
    },
    "& .MuiTab-root": {
      minWidth: "27px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default Partner;
