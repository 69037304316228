import { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { getDocId, getHeaders } from "../utils/utils";
import toast from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import { AiOutlinePlus } from "react-icons/ai";

const QualificationsForm = ({
  isMobile = false,
  closeModal,
  userInfo,
  setUserInfo,
  isEdit,
  acadId,
}) => {
  const user_id = getDocId();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();

  const [degrees, setDegrees] = useState([]);
  const [universities, setUniversities] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm();

  const pursuing = watch("pursuing");

  useEffect(() => {
    if (isEdit) setEditValues();
  }, [isEdit]);

  useEffect(() => {
    const resetYearOfGraduation = async () => {
      if (pursuing) {
        setValue("graduation", "");
        const res = await trigger("graduation");
      }
    };

    resetYearOfGraduation();
  }, [pursuing]);

  useEffect(() => {
    autoSuggestions();
  }, []);

  const setEditValues = () => {
    const [res] = userInfo.acad_history.filter(
      (qualification) => qualification.acad_id === acadId
    );
    if (res) {
      setValue("degree", { label: res.degree, value: res.degree });
      setValue("college", res.college);
      if (res.university !== "")
        setValue("university", {
          label: res.university,
          value: res.university,
        });
      setValue("pursuing", res.currently_pursuing);
      if (!res.currently_pursuing) setValue("graduation", res.passing_year);
    }
  };

  const autoSuggestions = async () => {
    const payload = { degrees: "degrees", universities: "universities" };

    const res = await fetch(baseUrl + "v2/autoSuggestions", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let universities = [];
    let degrees = [];

    if (data.status === "success") {
      for (const university in data.data.universities) {
        universities.push({ value: university, label: university });
      }
      for (const degree of data.data.degrees) {
        degrees.push({ value: degree, label: degree });
      }
    }

    setDegrees(degrees);
    setUniversities(universities);
  };

  const addQualification = async (formData, addOneMore = false) => {
    const payload = {
      user_id: user_id,
      acad_history: [
        {
          degree: encodeURIComponent(formData.degree.value.trim()),
          college: encodeURIComponent(formData?.college?.trim()),
          university: formData.university
            ? encodeURIComponent(formData.university.value.trim())
            : "",
          currently_pursuing: formData.pursuing,
          passing_year: formData.pursuing ? 0 : formData.graduation,
        },
      ],
    };

    const res = await fetch(baseUrl + "v1/userProfile/create", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    console.log(data);
    if (data.status === "success") {
      setUserInfo((prev) => ({
        ...prev,
        acad_history: insertNewQualification(
          prev.acad_history,
          data.data.acad_history
        ),
      }));
      if (addOneMore)
        reset({
          degree: null,
          college: "",
          university: null,
          graduation: "",
          pursuing: false,
        });
      else closeModal();

      toast.success("Qualification added");
    } else toast.error(data.errorMsg);
  };

  const editQualification = async (formData) => {
    const payload = {
      user_id: user_id,
      acad_history: [
        {
          acad_id: acadId,
          degree: encodeURIComponent(formData.degree.value.trim()),
          college: encodeURIComponent(formData?.college?.trim()),
          university: formData.university
            ? encodeURIComponent(formData.university.value.trim())
            : "",
          currently_pursuing: formData.pursuing,
          passing_year: formData.pursuing ? 0 : formData.graduation,
        },
      ],
    };

    const res = await fetch(baseUrl + "/v1/userProfile/update", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    console.log(data);
    if (data.status === "success") {
      setUserInfo((prev) => ({
        ...prev,
        acad_history: prev.acad_history.map((qualification) => {
          if (qualification.acad_id === acadId)
            return data.data.acad_history[0];
          else return qualification;
        }),
      }));
      closeModal();
      toast.success("Qualification updated");
    } else toast.error(data.errorMsg);
  };

  const insertNewQualification = (oldArray, newItem) => {
    if (!oldArray) return [...newItem];

    const res = [...oldArray, ...newItem];
    res.sort((a, b) => {
      if (b.currently_pursuing) return 1;
      else if (a.currently_pursuing) return -1;
      else if (a.currently_pursuing && b.currently_pursuing) return 0;
      return b.passing_year - a.passing_year;
    });
    return res;
  };

  return (
    <>
      <div
        style={
          isMobile
            ? { overflowY: "auto", padding: "1em" }
            : {
                padding: "2em 1.5em",
                height: "100%",
                width: "100%",
                overflow: "auto",
              }
        }
      >
        <div style={{ ...inputDivStyle, marginTop: 0 }}>
          {/* <input
            style={inputStyle}
            type="text"
       
            placeholder="Degree"
          /> */}
          <Controller
            name="degree"
            control={control}
            rules={{
              required: true,
              validate: {
                mlen: (val) => !(val?.value?.length > 100),
                specialChar: (val) => /^[a-zA-Z&.(),/\s-]*$/.test(val?.value),
              },
            }}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                placeholder="Degree *"
                isClearable={true}
                options={degrees}
                styles={{
                  control: (style) => ({
                    ...style,
                    background: "inherit",
                    border: 0,
                    boxShadow: "none",
                    // padding: "10px 10px 10px 0",
                  }),
                  container: (style) => ({
                    ...style,
                    width: "100%",
                  }),
                  placeholder: (style) => ({
                    ...style,
                    fontSize: "14px",
                  }),
                  indicatorsContainer: (style) => ({
                    ...style,
                    display: "none",
                  }),
                  valueContainer: (style) => ({
                    ...style,
                    padding: 0,
                  }),
                  menuList: (style) => ({
                    ...style,
                    height: "9em",
                  }),
                }}
              />
            )}
          />
        </div>
        {errors.degree?.type === "required" && (
          <div style={errorMsg}>Please enter your degree</div>
        )}
        {errors.degree?.type === "mlen" && (
          <div style={errorMsg}>Please enter less than 100 characters</div>
        )}
        {errors.degree?.type === "specialChar" && (
          <div style={errorMsg}>
            Only letters, period & - ( ) / and comma are allowed
          </div>
        )}

        <div style={inputDivStyle}>
          {/* <input
            style={inputStyle}
            type="text"
        
            placeholder="University"
          /> */}
          <Controller
            name="university"
            control={control}
            rules={{
              validate: {
                mlen: (val) => !(val?.value?.length > 100),
                specialChar: (val) => /^[a-zA-Z().,\s]*$/.test(val?.value),
              },
            }}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                placeholder="University"
                options={universities}
                isClearable={true}
                styles={{
                  control: (style) => ({
                    ...style,
                    background: "inherit",
                    border: 0,
                    boxShadow: "none",
                    // padding: "10px 10px 10px 0",
                  }),
                  container: (style) => ({
                    ...style,
                    width: "100%",
                  }),
                  placeholder: (style) => ({
                    ...style,
                    fontSize: "14px",
                  }),
                  indicatorsContainer: (style) => ({
                    ...style,
                    display: "none",
                  }),
                  valueContainer: (style) => ({
                    ...style,
                    padding: 0,
                  }),
                  menuList: (style) => ({
                    ...style,
                    height: "9em",
                  }),
                }}
              />
            )}
          />
        </div>
        {errors?.university?.type === "mlen" && (
          <div style={errorMsg}>Please enter less than 100 characters</div>
        )}
        {errors?.university?.type === "specialChar" && (
          <div style={errorMsg}>
            Only letters, brackets, period and comma are allowed
          </div>
        )}
        <div style={inputDivStyle}>
          <input
            style={inputStyle}
            type="text"
            {...register("college", {
              maxLength: 100,
              pattern: /^[a-zA-Z&,\s]*$/,
            })}
            placeholder="College"
            maxLength="100"
          />
        </div>
        {errors.college?.type === "maxLength" && (
          <div style={errorMsg}>Please enter less than 100 characters</div>
        )}
        {errors.college?.type === "pattern" && (
          <div style={errorMsg}>Only letters, & and comma are allowed</div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // marginBlock: "0.5em",
            marginTop: "0.5em",
            marginBottom: "0.5em",
          }}
        >
          <input
            style={{ width: "20px", height: "20px", margin: 0 }}
            type="checkbox"
            name="pursuing"
            {...register("pursuing")}
          />
          <span
            className="form-check-label"
            style={{
              marginLeft: "5px",
              fontSize: "1.1rem",
            }}
            htmlFor="pursuing"
          >
            Currently Pursuing
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // marginBlock: "1em",
            marginTop: "1em",
            marginBottom: "1em",
          }}
        >
          <div
            style={{
              ...inputDivStyle,
              // marginBlock: 0,
              marginTop: 0,
              marginBottom: 0,
              backgroundColor: pursuing ? "lightgray" : "#ffffff",
            }}
          >
            {/* <input
              style={inputStyle}
              type="text"
          
              placeholder="Year of Graduation"
            /> */}
            <input
              style={inputStyle}
              type="number"
              {...register("graduation", {
                required: !pursuing,
                min: 1900,
                max: new Date().getFullYear(),
              })}
              disabled={pursuing}
              placeholder="Year of Graduation *"
            />
          </div>

          {!isEdit && (
            <button
              style={{
                border: "1px solid #469b8d",
                borderRadius: "5px",
                marginLeft: "1em",
                padding: "0.8em",
                fontSize: "1.3rem",
              }}
              onClick={handleSubmit((dt) => addQualification(dt, true))}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <AiOutlinePlus size={20} style={{ marginRight: "5px" }} /> Add
                One More
              </div>
            </button>
          )}
        </div>
        {errors.graduation?.type === "max" && (
          <div style={errorMsg}>
            Year of graduation cannot be greater than current year
          </div>
        )}
        {errors.graduation?.type === "min" && (
          <div style={errorMsg}>Please provide valid year above 1900</div>
        )}
        {errors.graduation?.type === "required" && (
          <div style={errorMsg}>Please enter graduation year</div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          //   justifyContent: "space-between",
          width: "100%",
          // paddingInline: "2em",
          paddingLeft: "2em",
          paddingRight: "2em",
          marginBottom: "2em",
        }}
      >
        <button
          style={{
            backgroundColor: "#469b8d",
            padding: "0.8em 2.5em",
            borderRadius: "15px",
            color: "white",
            fontSize: "1.2rem",
          }}
          type="submit"
          onClick={handleSubmit(
            isEdit ? editQualification : (dt) => addQualification(dt, false)
          )}
        >
          Save
        </button>

        <button
          style={{
            backgroundColor: "white",
            padding: "0.8em 2.5em",
            borderRadius: "15px",
            fontSize: "1.2rem",
            border: "1px solid #469b8d",
            marginLeft: "2em",
          }}
          type="button"
          onClick={() => closeModal()}
        >
          Close
        </button>
      </div>
    </>
  );
};

const inputStyle = {
  background: "inherit",
  width: "100%",
  border: "none",
};

const errorMsg = {
  color: "red",
  fontSize: "1.1rem",
  // marginBlock: "1em",
  marginTop: "1em",
  marginBottom: "1em",
};

const inputDivStyle = {
  background: "#ffffff",
  borderRadius: "5px",
  height: "3.125em",
  border: "1px solid slategray",
  width: "70%",
  display: "flex",
  alignItems: "center",
  padding: "1em",
  // marginBlock: "1em",
  marginTop: "1em",
  marginBottom: "1em",
};

export default QualificationsForm;
