import { getAwsDetails, getClientIp, getHeaders, config } from "../utils/utils";
import S3 from "react-aws-s3";

const baseUrl = process.env.REACT_APP_BASE_URL;
const headers = getHeaders();

export const loginService = async (payload) => {
  const IP_ADDRESS = await getClientIp();
  headers["X-DEVICE-IP"] = IP_ADDRESS;
  // headers["x-device-id"] = IP_ADDRESS;
  const res = await fetch(baseUrl + "login", {
    method: "POST",
    headers,
    body: "reqData=" + JSON.stringify(payload),
  });
  const data = await res.json();
  return data;
};

export const sendForgotPasswordOtp = async (payload) => {
  const res = await fetch(baseUrl + "forgotPassword", {
    method: "POST",
    headers,
    body: "reqData=" + JSON.stringify(payload),
  });

  const data = await res.json();
  return data;
};

export const resetPassword = async (payload) => {
  const res = await fetch(baseUrl + "updatePassword", {
    method: "POST",
    headers,
    body: "reqData=" + JSON.stringify(payload),
  });

  const data = await res.json();
  return data;
};

export const sendRegistrationOtp = async (payload) => {
  const res = await fetch(baseUrl + "sendOTP", {
    method: "POST",
    headers,
    body: "reqData=" + JSON.stringify(payload),
  });
  const data = await res.json();
  return data;
};

export const register = async (payload) => {
  const res = await fetch(baseUrl + "v2/register", {
    method: "POST",
    headers,
    body: "reqData=" + JSON.stringify(payload),
  });

  const data = await res.json();
  return data;
};

export const saveProfessionalInfo = async (formData) => {
  const res = await fetch(baseUrl + "updateMandatoryData", {
    method: "POST",
    headers,
    body: formData,
  });
  const data = await res.json();
  return data;
};

export const uploadFileToS3 = async (file, newFileName) => {
  const config = getAwsDetails();
  const ReactS3Client = new S3(config);

  const data = await ReactS3Client.uploadFile(file, newFileName);
  return data;
};

export const saveMciCard = async (payload) => {
  const res = await fetch(baseUrl + "v2/upload", {
    method: "POST",
    headers,
    body: "reqData=" + JSON.stringify(payload),
  });

  const data = await res.json();
  return data;
};
