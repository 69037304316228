import { useState, useRef } from "react";
import upload from "../../assets/dashboard_icons/social-icons/Camera.svg";
import post from "../../assets/dashboard_icons/social-icons/Post.svg";
import S3 from "react-aws-s3";
import toast from "react-hot-toast";
import { Loader } from "../../common/Loader";
import Progressbar from "../../progressbar";
import profile_pic_placeholder from "../../assets/profile_icons/default-profile.png";
import {
  getAuthToken,
  getDocId,
  getTimeAndDate,
  getHeaders,
  config,
} from "../../utils/utils";

const CommentInput = ({
  profile_pic = "",
  addComment,
  feed_id,
  channel_id,
  isFullFeedView = false,
}) => {
  const doc_id = getDocId();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const [comment, setComment] = useState("");
  const [isfileUploaded, setIsFileUploaded] = useState(false);
  const fileInput = useRef(null);
  const [imgPreviewUrl, setImgPreviewUrl] = useState("");
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const showImagePreview = () => {
    let type = fileInput.current.files[0].type;
    if (type.includes("image")) type = "image";
    else if (type.includes("video")) type = "video";
    else if (type.includes("audio")) type = "audio";
    else if (type.includes("pdf")) type = "pdf";

    if (type === "image") {
      setIsFileUploaded(true);
      setImgPreviewUrl(URL.createObjectURL(fileInput.current.files[0]));
    } else {
      toast.error("Only images are allowed");
    }
  };
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";

  // const profile_pic_placeholder =
  //   "assets/images/profile/src_assets_images_Avatar Icon_30x30.png";

  const uploadFile = async (e) => {
    setloaderSpinner(true);
    e.preventDefault();
    setComment("");
    setImgPreviewUrl("");
    const attachments = [];
    if (isfileUploaded) {
      let file = fileInput.current.files[0];
      let newFileName = fileInput.current.files[0].name;
      let ext = getExtension(newFileName);
      let type = fileInput.current.files[0].type;
      if (type.includes("image")) type = "image";
      else if (type.includes("video")) type = "video";
      else if (type.includes("audio")) type = "audio";
      else if (type.includes("pdf")) type = "pdf";

      const ReactS3Client = new S3(config);
      const data = await ReactS3Client.uploadFile(file, newFileName);
      console.dir(data);
      const attachment = {
        attachment_type: type,
        attachment_extension: ext,
        attachment_original_url: data.location,
      };
      console.dir(attachment);
      attachments.push(attachment);
    }
    fileInput.current.value = "";
    setIsFileUploaded(false);
    addComment(feed_id, channel_id, comment, attachments);
    setloaderSpinner(false);
  };

  const getExtension = (fileName) => {
    const lastDot = fileName.lastIndexOf(".");
    const ext = fileName.substring(lastDot + 1);
    return ext;
  };

  return (
    <>
      <Progressbar
        show={loaderSpinner}
        imageUrl={url}
        height="90"
        width="90"
        alignment="middle"
        alttext="Loading..."
      />

      <div
        className="comment-input-box"
        style={{ padding: isFullFeedView && "0px" }}
      >
        {!isFullFeedView && (
          <img
            className="comment-image"
            src={profile_pic === "" ? profile_pic_placeholder : profile_pic}
          />
        )}

        <div className="comment-input-container">
          <img
            src={upload}
            onClick={(e) => {
              e.preventDefault();
              fileInput.current.click();
            }}
            className="comment-input-image"
          />
          <input
            type="file"
            ref={fileInput}
            onChange={() => showImagePreview()}
            style={{
              display: "none",
            }}
            className="submit-btn"
            accept=".jpg , .jpeg, .png"
          />
          <input
            type="text"
            className="comment-input"
            placeholder="Type your comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></input>
          <input
            type="image"
            src={post}
            onClick={(e) => uploadFile(e)}
            className="comment-input-image"
          />
          <br />
          <img
            src={imgPreviewUrl}
            style={imgPreviewUrl ? { width: "20%" } : { width: "0%" }}
          />
        </div>
      </div>
    </>
  );
};

export default CommentInput;
