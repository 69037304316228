import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { getDocId, getHeaders } from "../utils/utils";
import { AiOutlinePlus } from "react-icons/ai";

const AreaOfInterests = ({
  closeModal,
  userInfo,
  setUserInfo,
  interestId,
  isEdit,
}) => {
  const user_id = getDocId();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const setEditValue = (id) => {
    const [res] = userInfo?.areas_of_interest?.filter(
      (interest) => interest.interest_id === id
    );
    if (res) {
      setValue("aoi", res.area_of_interest);
    }
  };

  useEffect(() => {
    if (isEdit) setEditValue(interestId);
  }, [isEdit]);

  const addInterests = async (formData, addOneMore = false) => {
    if (userInfo?.areas_of_interest?.length >= 20) {
      toast("Reached max limit");
      return;
    }

    if (formData.aoi.trim() === "") {
      toast("Please enter area of interest");
      return;
    }
    const payload = {
      user_id,
      areas_of_interest: [{ area_of_interest: formData.aoi.trim() }],
    };

    const res = await fetch(baseUrl + "v1/userProfile/create", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();
    console.log(data);

    if (data.status === "success") {
      setUserInfo((prev) => ({
        ...prev,
        areas_of_interest: [
          ...prev.areas_of_interest,
          ...data.data.areas_of_interest,
        ],
      }));
      if (addOneMore)
        reset({
          aoi: "",
        });
      else closeModal();
      toast.success("Area Of Interest added");
    } else {
      let error = data.errorMsg;
      if (data.errorMsg === "Duplicate Entry")
        error = "Area of Interest already exists";
      toast.error(error);
    }
  };

  const editInterests = async (formData) => {
    if (formData.aoi.trim() === "") {
      toast("Please enter area of interest");
      return;
    }
    const payload = {
      user_id,
      areas_of_interest: [
        {
          interest_id: interestId,
          area_of_interest: formData.aoi.trim(),
        },
      ],
    };

    const res = await fetch(baseUrl + "v1/userProfile/update", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();
    console.log(data);

    if (data.status === "success") {
      setUserInfo((prev) => ({
        ...prev,
        areas_of_interest: prev.areas_of_interest.map((interest) => {
          if (interest.interest_id === interestId)
            return data.data.areas_of_interest[0];
          else return interest;
        }),
      }));
      closeModal();
      toast.success("Area Of Interest updated");
    } else {
      let error = data.errorMsg;
      if (data.errorMsg === "Duplicate Entry")
        error = "Area of Interest already exists";
      toast.error(error);
    }
  };

  return (
    <div
      style={{
        padding: "1.5em",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1em",
        }}
      >
        <div style={{ ...inputDivStyle, marginTop: 0, marginBottom: 0 }}>
          <input
            style={inputStyle}
            type="text"
            {...register("aoi", {
              required: true,
              maxLength: 25,
              pattern: /^[a-zA-Z\s]*$/,
            })}
            placeholder="Area of Interest"
            maxLength="100"
          />
        </div>
        {!isEdit && (
          <button
            style={{
              border: "1px solid #469b8d",
              borderRadius: "5px",
              marginLeft: "1em",
              padding: "0.8em",
              fontSize: "1.3rem",
            }}
            onClick={handleSubmit((dt) => addInterests(dt, true))}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <AiOutlinePlus size={20} style={{ marginRight: "5px" }} /> Add One
              More
            </div>
          </button>
        )}
      </div>
      {errors.aoi?.type === "maxLength" && (
        <div style={errorMsg}>Please enter less that 25 characters</div>
      )}
      {errors.aoi?.type === "pattern" && (
        <div style={errorMsg}>Only letters allowed</div>
      )}
      {errors.aoi?.type === "required" && (
        <div style={errorMsg}>Please enter area of interest</div>
      )}

      <div
        style={{
          display: "flex",
          //   justifyContent: "space-between",
          width: "100%",
          marginBottom: "1em",
        }}
      >
        <button
          style={{
            backgroundColor: "#469b8d",
            padding: "0.8em 2.5em",
            borderRadius: "15px",
            color: "white",
            fontSize: "1.2rem",
          }}
          type="submit"
          onClick={handleSubmit(
            isEdit ? editInterests : (dt) => addInterests(dt, false)
          )}
        >
          Save
        </button>

        <button
          style={{
            backgroundColor: "white",
            padding: "0.8em 2.5em",
            borderRadius: "15px",
            fontSize: "1.2rem",
            border: "1px solid #469b8d",
            marginLeft: "2em",
          }}
          type="button"
          onClick={() => closeModal()}
        >
          Close
        </button>
      </div>
    </div>
  );
};

const inputDivStyle = {
  background: "#ffffff",
  borderRadius: "5px",
  height: "3em",
  border: "1px solid slategray",
  width: "60%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1em",
  marginBottom: "1em",
};

const inputStyle = {
  background: "inherit",
  width: "100%",
  border: "none",
};

const errorMsg = {
  color: "red",
  fontSize: "1.1rem",
  marginTop: "1em",
  marginBottom: "1em",
};

export default AreaOfInterests;
