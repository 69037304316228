import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import toast from "react-hot-toast";
import logo from "../assets/logo4.png";
import logo1 from "../assets/mobile-logo.png";
import Progressbar from "../progressbar";
import PersonalInfo from "./RegisterComponents/PersonalInfo";
import OtpModal from "./RegisterComponents/OtpModal";
import OtpSuccessModal from "./RegisterComponents/OtpSuccessModal";

import bgImage from "../assets/pre_login_bg.png";
import "./css/signup.css";

import { getHeaders, ttlForDocId } from "../utils/utils";
import artwork from "../assets/pre_login_artwork.png";
const Signup = () => {
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const headers = getHeaders();

  const history = useHistory();

  const [otp, setOTP] = useState("");
  const [formdata, setFormData] = useState({});

  const [otpSuccessModal, setOtpSuccessModal] = useState(false);
  const openOtpSuccessModal = () => setOtpSuccessModal(true);
  const closeOtpSuccessModal = () => setOtpSuccessModal(false);
  const [otpModal, setOtpModal] = useState(false);
  const openOtpModal = () => setOtpModal(true);
  const closeOtpModal = () => setOtpModal(false);
  const [spinner, setSpinner] = useState(false);
  // const isUserActive =  useRef(false)

  const successRedirect = () => {
    history.push({
      pathname: "/dashboard",
      // state: { is_user_active: false, is_speciality_added: true },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendOTP = async (dt) => {
    let reqPayload = {
      contact: dt.phone.substring(1),
      contactType: "PHONE",
      isRegister: true,
      email: dt.email,
    };

    setSpinner(true);

    const res = await fetch(baseUrl + "sendOTP", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(reqPayload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();
    if (data.status === "success") {
      setSpinner(false);
      setOTP(data.otp);
      setFormData({ ...dt });
      openOtpModal();
      //   signup(dt);
    } else {
      setSpinner(false);
      toast.error(data.errorMsg);
    }
  };

  const signup = async (dt) => {
    closeOtpModal();
    let reqPayload = {
      user_salutation: dt.salutation,
      fname: dt.fname.trim(),
      lname: dt.lname.trim(),
      email: dt.email.trim(),
      contact_details: [
        {
          contact: dt.phone.trim().substring(1),
          type: "PHONE",
        },
      ],
      speciality: dt.speciality.value,
      stay_logged_in: true,
    };

    setSpinner(true);
    const res = await fetch(baseUrl + "v3/signup", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(reqPayload),
    }).catch((error) => {
      setSpinner(false);
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();
    setSpinner(false);
    const expiryDate = new Date().getTime() + ttlForDocId;
    if (data.status === "success") {
      localStorage.setItem("expiry_date", expiryDate);
      localStorage.setItem("doc_id", data.data.doc_id);
      localStorage.setItem("auth_token", data.data.security_token);
      localStorage.setItem("is_user_active", "false");
      localStorage.setItem("is_speciality_added", "true");
      openOtpSuccessModal();
    } else {
      setSpinner(false);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-sm-5 d-none d-sm-block"
            style={{ backgroundColor: "#f1f1f1" }}
          >
            <div
              className="timeline-logo"
              style={{ margin: "10px 0px 0px 20px" }}
            >
              <Link to="/">
                <img
                  src={logo}
                  style={{ width: "150px", height: "auto" }}
                  alt="timeline logo"
                />
              </Link>
            </div>
            <div>
              <div style={{ padding: "1em", margin: "2em 0em" }}>
                <img
                  className="login-image"
                  src={artwork}
                  alt="timeline logo"
                  style={{
                    width: "45%",
                    height: "auto",
                    opacity: "1",
                    display: "block",
                    margin: "auto",
                  }}
                />
              </div>
              <div style={{ width: "70%", margin: "auto" }}>
                <h3
                  style={{
                    color: "#1B8C7A",
                    font: "normal normal bold 2em/1.2em Roboto",
                    letterSpacing: "0px",
                    opacity: "1",
                    textAlign: "center",
                    width: "auto",
                    padding: "0.5em",
                  }}
                >
                  Welcome to WhiteCoats
                </h3>
                <h4
                  style={{
                    font: "normal normal normal 1.5em/1.2em Roboto",
                    color: "#6D6D6D",
                    textAlign: "center",
                    opacity: "1",
                  }}
                >
                  A Digital Networking and Collaboration Platform for Doctors
                </h4>
                <br />
                <h6
                  style={{
                    font: "normal normal normal 1em Roboto",
                    color: "#6D6D6D",
                    textAlign: "center",
                    opacity: "1",
                  }}
                >
                  Clinical Discussions | Case Reports | Guidelines | Jobs | Drug
                  References & much more
                </h6>
              </div>
            </div>
          </div>
          <div
            className="col-sm-7 "
            style={{
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "100%",
              backgroundColor: "#1e8a79",
            }}
          >
            <div className="row">
              <header id="header d-block d-lg-none">
                <div className="container">
                  <div className="navbar-header">
                    <div className="mobile-header-wrapper sticky d-block d-lg-none">
                      <div className="mobile-header position-relative ">
                        <div
                          className="mobile-responsive"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <img
                            style={{
                              marginLeft: "10px",
                              width: "30%",
                              cursor: "pointer",
                            }}
                            src={logo1}
                            alt="home logo"
                          />
                          <h5 style={{ margin: "9px", color: "black" }}>
                            {"Already have an account? "}
                            <Link style={{ color: "#1e8a7e" }} to="/login">
                              Sign In
                            </Link>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <div className="col-sm-6"></div>
              <div className="col-sm-4 " style={{ padding: "0px" }}>
                <div style={{ margin: "25px" }} className="d-none d-sm-block">
                  <h5 className="d-none d-sm-block">
                    {"Already have an account? "}
                    <Link style={{ color: "yellow" }} to="/login">
                      Sign In
                    </Link>
                  </h5>
                </div>
              </div>
              {/* responsive height */}
              <div
                className=" col-sm-4 d-block d-lg-none"
                style={{ margin: "35px" }}
              ></div>
              <div className="col-sm-2"></div>
            </div>

            <div className="row">
              <div className="col-sm-2"></div>
              <div className="col-sm-8">
                <div className="signup-container">
                  <h1 style={{ margin: "0px 0px 5px" }}>Create Account</h1>
                </div>
              </div>
              <div className="col-sm-2"></div>
            </div>
            <div className="row">
              <div className="col-sm-2"></div>
              <div className="col-sm-8">
                <div className="signup-container">
                  <h5
                    className="header-tag"
                    style={{ margin: "10px 0px 15px" }}
                  >
                    Fill up the form below and get going on WhiteCoats Network
                  </h5>
                </div>
              </div>
              <div className="col-sm-2"></div>
            </div>

            <div className="row">
              <div className="col-sm-2"></div>
              <div className="col-sm-8">
                <PersonalInfo sendOTP={sendOTP} />
              </div>
              <div className="col-sm-2"></div>
            </div>
            <Progressbar
              show={spinner}
              imageUrl={url}
              height="90"
              width="90"
              alignment="middle"
              alttext="Loading..."
            />
          </div>
        </div>
      </div>
      <OtpModal
        open={otpModal}
        closeModal={closeOtpModal}
        formData={formdata}
        signup={signup}
        recievedOtp={otp}
        setRecievedOtp={(dt) => setOTP(dt)}
      />
      <OtpSuccessModal
        open={otpSuccessModal}
        closeModal={closeOtpSuccessModal}
        successRedirect={successRedirect}
      />
    </>
  );
};

export default Signup;
