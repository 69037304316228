import { useState, useEffect } from "react";

import toast from "react-hot-toast";

import "../../profile/profile.css";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { IoIosRadioButtonOn, IoIosRadioButtonOff } from "react-icons/io";
import { getDocId, getHeaders } from "../../utils/utils";
import moment from "moment";
import { AiOutlinePlus } from "react-icons/ai";
import { FaCalendarAlt } from "react-icons/fa";

const AvailableAt = ({ profId, closeModal, isEdit, userInfo, getUserInfo }) => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    watch,
    trigger,
    formState: { errors },
  } = useForm();

  const user_id = getDocId();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();

  const [cities, setCities] = useState([]);

  const working = watch("working_here");

  const defaultAvailableDays = {
    MON: false,
    TUE: false,
    WED: false,
    THU: false,
    FRI: false,
    SAT: false,
    SUN: false,
  };

  const [availableDays, setAvailableDays] = useState(defaultAvailableDays);
  const [department, setDepartment] = useState("");
  const [showOnCard, setShowOnCard] = useState(false);
  const [isShowOnCard, setIsShowOnCard] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const handleFromDate = (newValue) => setFromDate(newValue);

  const [startedOn, setStartedOn] = useState(false);
  const openStartedOn = () => setStartedOn(true);
  const closeStartedOn = () => setStartedOn(false);

  const [toDate, setToDate] = useState(null);
  const handleToDate = (newValue) => setToDate(newValue);

  const [workedTill, setWorkedTill] = useState(false);
  const openWorkedTill = () => {
    if (fromDate) setWorkedTill(true);
  };
  const closeWorkedTill = () => setWorkedTill(false);

  const [toTiming, setToTiming] = useState(null);
  const handleToTiming = (newValue) => setToTiming(newValue);

  const [fromTiming, setFromTiming] = useState(null);
  const handleFromTiming = (newValue) => setFromTiming(newValue);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleDays = (day) => (e) => {
    setAvailableDays((prev) => ({
      ...prev,
      [day]: !prev[day],
    }));
  };

  // useEffect(() => {
  //   if (!working && checkShowOnCard(isShowOnCard))
  //     setValue("working_here", true);
  // }, [working]);

  useEffect(() => {
    if (isEdit) setEditValues(profId);
  }, [isEdit]);

  useEffect(() => {
    autoSuggestions();
  }, []);

  const checkShowOnCard = (show_on_card) => {
    if (show_on_card)
      toast(
        "WhiteCoats requires at least one workplace to be displayed on your card"
      );
    return show_on_card;
  };

  const setEditValues = (prof_id) => {
    const [res] = userInfo.prof_history.filter(
      (exp) => exp.prof_id === prof_id
    );
    if (res) {
      setValue("location", { label: res.location, value: res.location });
      setValue("workplace", res.workplace);
      setValue("designation", res.designation);
      setValue("working_here", res.working_here);
      if (res.from_date && res?.from_date > 0) setFromDate(res.from_date);
      if (!res.working_here)
        if (res.to_date && res?.to_date > 0) setToDate(res.to_date);

      setIsShowOnCard(res.show_on_card);

      if (res.working_here && res.available_days) {
        if (
          res.available_days.to_time &&
          res.available_days?.to_time > -19800001
        )
          setToTiming(res.available_days.to_time);
        if (
          res.available_days.from_time &&
          res.available_days?.from_time > -19800001
        )
          setFromTiming(res.available_days.from_time);
        setDepartment(res.available_days.department);
        extractAvailableDays(res.available_days.week_of_days);
        setShowOnCard(res.show_on_card);
      }
    }
  };

  const resetForm = () => {
    reset({
      designation: "",
      workplace: "",
      location: null,
      working_here: false,
    });
    setFromDate(null);
    setToDate(null);
    setToTiming(null);
    setFromTiming(null);
    setAvailableDays(defaultAvailableDays);
    setShowOnCard(false);
    setDepartment("");
  };

  const getAvailableDays = () => {
    let available_days = [];

    let dayMapping = {
      MON: 1,
      TUE: 2,
      WED: 3,
      THU: 4,
      FRI: 5,
      SAT: 6,
      SUN: 7,
    };

    for (let day in availableDays) {
      if (availableDays[day]) available_days.push(dayMapping[day]);
    }

    return available_days;
  };

  const extractAvailableDays = (available_days) => {
    let dayMapping = {
      1: "MON",
      2: "TUE",
      3: "WED",
      4: "THU",
      5: "FRI",
      6: "SAT",
      7: "SUN",
    };
    setAvailableDays(defaultAvailableDays);
    for (let day of available_days) {
      setAvailableDays((prev) => ({
        ...prev,
        [dayMapping[day]]: true,
      }));
    }
  };

  const autoSuggestions = async () => {
    const payload = { cities: "cities" };

    const res = await fetch(baseUrl + "v2/autoSuggestions", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let cities = [];

    if (data.status === "success") {
      for (let city of data.data.cities) {
        cities.push({ value: city, label: city });
      }
    }

    setCities(cities);
  };

  const addExperience = async (formData, addMore = false) => {
    const payload = {
      user_id: user_id,
      prof_history: [
        {
          workplace: formData.workplace,
          location: formData?.location?.value,
          designation: formData.designation,
          from_date: fromDate ? fromDate : 0,
          to_date: toDate && !working ? toDate : 0,
          working_here: formData.working_here,
          show_on_card: working ? showOnCard : false,
          user_google_places_info: {},
          user_google_city_info: {},
          is_exact_location: false,
          is_exact_city: false,
          available_days: {
            week_of_days: working ? getAvailableDays() : [],
            from_time: working ? (fromTiming ? fromTiming : -19800001) : 0,
            to_time: working ? (toTiming ? toTiming : -19800001) : 0,
            department: working ? department : "",
          },
        },
      ],
    };

    console.dir(payload);

    const res = await fetch(baseUrl + "v1/userProfile/create", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);

      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      getUserInfo();
      if (addMore) resetForm();
      else closeModal();
      toast.success("Experience added");
    } else {
      // toggleDrawer(false);
      toast.error(data.errorMsg);
    }
  };

  const updateExperience = async (formData) => {
    const payload = {
      user_id: user_id,
      prof_history: [
        {
          prof_id: profId,
          workplace: formData.workplace,
          location: formData?.location?.value,
          designation: formData.designation,
          from_date: fromDate ? fromDate : 0,
          to_date: toDate && !working ? toDate : 0,
          working_here: formData.working_here,
          show_on_card: working ? showOnCard : false,
          user_google_places_info: {},
          user_google_city_info: {},
          is_exact_location: false,
          is_exact_city: false,
          available_days: {
            week_of_days: working ? getAvailableDays() : [],
            from_time: working ? (fromTiming ? fromTiming : -19800001) : 0,
            to_time: working ? (toTiming ? toTiming : -19800001) : 0,
            department: working ? department : "",
          },
        },
      ],
    };

    console.log(payload);

    const res = await fetch(baseUrl + "v1/userProfile/update", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);

      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      toast.success("Experience updated");
      getUserInfo();
      // toggleDrawer(false);
      closeModal();
      console.log(data);
    } else {
      toast.error(data.errorMsg);
    }
  };

  return (
    <>
      <div
        style={{
          padding: "2em 1.5em",
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <div style={{ ...inputDivStyle }}>
          <input
            style={inputStyle}
            type="text"
            {...register("workplace", {
              required: true,
              maxLength: 80,
              pattern: /^[a-zA-Z\s&-',]*$/,
            })}
            placeholder="Name of the Hospital or Clinic"
          />
        </div>
        {errors.workplace?.type === "required" && (
          <div style={errorMsg}>Please enter name of hospital or clinic</div>
        )}
        {errors.workplace?.type === "maxLength" && (
          <div style={errorMsg}>Please enter less than 80 characters</div>
        )}
        {errors.workplace?.type === "pattern" && (
          <div style={errorMsg}>Only letters & - ' , are allowed</div>
        )}

        <div style={inputDivStyle}>
          <Controller
            name="location"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Select
                {...field}
                placeholder="City or Town"
                isClearable={true}
                options={cities}
                styles={{
                  control: (style) => ({
                    ...style,
                    background: "inherit",
                    border: 0,
                    boxShadow: "none",
                    // padding: "10px 10px 10px 0",
                  }),
                  container: (style) => ({
                    ...style,
                    width: "100%",
                  }),
                  placeholder: (style) => ({
                    ...style,
                    fontSize: "14px",
                  }),
                  indicatorsContainer: (style) => ({
                    ...style,
                    display: "none",
                  }),
                  valueContainer: (style) => ({
                    ...style,
                    padding: 0,
                  }),
                  menuList: (style) => ({
                    ...style,
                    height: "9em",
                  }),
                }}
              />
            )}
          />
        </div>

        {errors.location?.type === "required" && (
          <div style={errorMsg}>Please enter your city of residence</div>
        )}

        <div style={{ ...inputDivStyle }}>
          <input
            style={inputStyle}
            type="text"
            {...register("designation", {
              maxLength: 50,
              pattern: /^[a-zA-Z\s]*$/,
            })}
            placeholder="Designation"
          />
        </div>

        {errors.designation?.type === "maxLength" && (
          <div style={errorMsg}>Please enter less than 50 characters</div>
        )}
        {errors.designation?.type === "pattern" && (
          <div style={errorMsg}>Only letters allowed</div>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            // marginBlock: "0.5em",
            marginTop: "0.5em",
            marginBottom: "0.5em",
          }}
        >
          <input
            style={{ width: "20px", height: "20px", margin: 0 }}
            type="checkbox"
            name="working"
            {...register("working_here")}
            onChange={(e) => {
              if (checkShowOnCard(isShowOnCard)) {
                e.target.checked = true;
                return;
              }
              setValue("working_here", e.target.checked);
            }}
          />
          {/* <MdCheckBoxOutlineBlank size={25}  /> */}
          <span
            className="form-check-label"
            style={{
              marginLeft: "5px",
              fontSize: "1.1rem",
            }}
            htmlFor="working"
          >
            Presently working here
          </span>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "1em",
            marginBottom: "1em",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "70%",
            }}
          >
            <div style={{ width: "45%" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MobileDatePicker
                  clearable
                  label="Started On"
                  inputFormat="DD/MM/YYYY"
                  sx={{
                    "& .MuiGrid-container": { display: "none" },
                  }}
                  value={fromDate}
                  open={startedOn}
                  onOpen={openStartedOn}
                  onClose={closeStartedOn}
                  maxDate={toDate ? moment(toDate) : moment(new Date())}
                  minDate={moment(new Date(1970, 0, 1))}
                  onChange={handleFromDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={openStartedOn}>
                              <FaCalendarAlt style={{ color: "#469b8d" }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        label: {
                          zIndex: 0,
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            {working ? (
              <div
                style={{ fontSize: "1.3rem", fontWeight: "bold", width: "45%" }}
              >
                - Till now
              </div>
            ) : (
              <div style={{ width: "45%" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileDatePicker
                    clearable
                    label="Worked Till"
                    inputFormat="DD/MM/YYYY"
                    sx={{
                      "& .MuiGrid-container": { display: "none" },
                    }}
                    value={toDate}
                    open={workedTill}
                    onOpen={openWorkedTill}
                    onClose={closeWorkedTill}
                    maxDate={moment(new Date())}
                    minDate={
                      fromDate ? moment(fromDate) : moment(new Date(1970, 0, 1))
                    }
                    onChange={handleToDate}
                    disabled={!fromDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end" onClick={openWorkedTill}>
                                <FaCalendarAlt style={{ color: "#469b8d" }} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          label: {
                            zIndex: 0,
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            )}
          </div>

          {!isEdit && !working && (
            <button
              style={{
                border: "1px solid #469b8d",
                borderRadius: "5px",
                marginLeft: "1em",
                padding: "0.7em",
                fontSize: "1.3rem",
              }}
              onClick={handleSubmit((dt) => addExperience(dt, true))}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <AiOutlinePlus size={20} style={{ marginRight: "5px" }} /> Add
                One More
              </div>
            </button>
          )}
        </div>

        {working && (
          <>
            <div style={{ marginTop: "1em", marginBottom: "1em" }}>
              <span style={{ fontSize: "1.4rem" }}>Available Days</span>
            </div>
            <div style={{ marginTop: "1em", marginBottom: "1em" }}>
              <span style={{ color: "gray", fontSize: "1.2rem" }}>
                Tap on days to select
              </span>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "1em",
                marginBottom: "1em",
                gap: "10px",
                justifyContent: "space-between",
                width: "70%",
              }}
            >
              {Object.keys(availableDays).map((key) => (
                <div
                  style={{
                    ...dayStyle,
                    backgroundColor: availableDays[key]
                      ? "#469b8d"
                      : "darkgray",
                    color: availableDays[key] ? "white" : "black",
                  }}
                  onClick={handleDays(key)}
                >
                  {key}
                </div>
              ))}
            </div>

            <div style={{ marginTop: "1em", marginBottom: "1em" }}>
              <span style={{ fontSize: "1.4rem" }}>Timings</span>
            </div>
            <div
              style={{
                marginTop: "1em",
                marginBottom: "1em",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "70%",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  clearable
                  label="From"
                  value={fromTiming}
                  onChange={handleFromTiming}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <span style={{ whiteSpace: "pre" }}> - </span>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                  clearable
                  label="to"
                  value={toTiming}
                  onChange={handleToTiming}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div
              style={{
                marginTop: "1em",
                marginBottom: "1em",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => setDepartment("OPD")}
              >
                {department === "OPD" ? (
                  <IoIosRadioButtonOn
                    style={{ color: "rgb(70, 155, 141)" }}
                    size={20}
                  />
                ) : (
                  <IoIosRadioButtonOff size={20} />
                )}
                <span
                  style={{
                    marginLeft: "1em",
                    fontSize: "1.3rem",
                    color: department === "OPD" ? "rgb(70, 155, 141)" : "black",
                  }}
                >
                  OPD
                </span>
              </div>
              <div
                style={{
                  marginLeft: "2em",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => setDepartment("OT")}
              >
                {department === "OT" ? (
                  <IoIosRadioButtonOn
                    size={20}
                    style={{ color: "rgb(70, 155, 141)" }}
                  />
                ) : (
                  <IoIosRadioButtonOff size={20} />
                )}
                <span
                  style={{
                    marginLeft: "1em",
                    fontSize: "1.3rem",
                    color: department === "OT" ? "rgb(70, 155, 141)" : "black",
                  }}
                >
                  OT
                </span>
              </div>
            </div>
            <div
              style={{
                marginTop: "1em",
                marginBottom: "1em",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: "2em", fontSize: "1.3rem" }}>
                Show on my card
              </span>
              <Switch
                checked={showOnCard}
                onChange={(e) => {
                  if (checkShowOnCard(isShowOnCard)) return;
                  setShowOnCard(e.target.checked);
                }}
                sx={{
                  "& .Mui-checked+.MuiSwitch-track": {
                    backgroundColor: "#469b8d !important",
                  },
                  "& .Mui-checked": {
                    color: "#469b8d !important",
                  },
                }}
                {...label}
              />
            </div>
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          //   justifyContent: "space-between",
          width: "100%",
          paddingInline: "2em",
          marginBottom: "2em",
        }}
      >
        <button
          style={{
            backgroundColor: "#469b8d",
            padding: "0.8em 2.5em",
            borderRadius: "15px",
            color: "white",
            fontSize: "1.2rem",
          }}
          type="submit"
          onClick={handleSubmit(
            isEdit ? updateExperience : (dt) => addExperience(dt, false)
          )}
        >
          Save
        </button>

        <button
          style={{
            backgroundColor: "white",
            padding: "0.8em 2.5em",
            borderRadius: "15px",
            fontSize: "1.2rem",
            border: "1px solid #469b8d",
            marginLeft: "2em",
          }}
          type="button"
          onClick={() => closeModal()}
        >
          Close
        </button>
      </div>
    </>
  );
};

const inputDivStyle = {
  background: "#ffffff",
  borderRadius: "5px",
  height: "3.125em",
  border: "1px solid slategray",
  width: "70%",
  display: "flex",
  alignItems: "center",
  padding: "1em",
  marginBlock: "1em",
};

const inputStyle = {
  background: "inherit",
  width: "100%",
  border: "none",
};

const errorMsg = {
  color: "red",
  fontSize: "1.1rem",
  marginBlock: "1em",
};

const dayStyle = {
  // border: "1px solid black ",
  // borderRadius: "5px",
  backgroundColor: "white",
  width: "4em",
  padding: "0.6em",
  fontSize: "1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
};

export default AvailableAt;
