import { useCallback, useState, useRef, useContext } from "react";
import { Modal } from "react-responsive-modal";
import FullViewFeed from "../dashboard/feeds/FullViewFeed";
import { closeIcon, getDocId, getHeaders } from "../utils/utils";
import ChannelFeed from "./ChannelFeed";
import { FeedFuncs } from "../professional/Professional";
import Createpost from "../createPost/Createpost";
import useChannelFeedsLoader from "./hooks/useChannelFeedsLoader";

const ChannelFeeds = ({
  feeds,
  setFeeds,
  channel,
  channels,
  userInfo,
  handleUploadMci,
  handleVerificationPending,
}) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const doc_id = getDocId();
  const headers = getHeaders();
  const [modalData, setModalData] = useState({});

  const {
    updateLikeCount,
    addComment,
    profile_pic,
    submitSurvey,
    updateViewCount,
    deleteComment,
    updateComment,
    registerWebinar,
    user_verification_info,
    openLikesModal,
    commentsListInfo,
  } = useContext(FeedFuncs);

  const fullViewFeedRef = useRef(null);

  const [fullView, setFullView] = useState(false);
  const openFullView = (feed) => {
    if(feed.feed_info?.about_us == null){
      feed.feed_info.about_us = "";
    }
    setModalData(feed);
    setFullView(true);
  };
  const closeFullView = () => {
    setFullView(false);
  };

  const [lastFeedId, setLastFeedId] = useState(0);

  const { loading, error, hasMore, nextLastFeedId } = useChannelFeedsLoader(
    channel.channel_id,
    lastFeedId,
    setFeeds
  );

  const observer = useRef(null);

  const loader = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setLastFeedId(nextLastFeedId);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <>
      {channel.is_admin === true &&
        channel.feed_provider_type.toLowerCase() === "community" && (
          <Createpost
            channels={channels}
            userInfo={userInfo}
            handleUploadMci={handleUploadMci}
            handleVerificationPending={handleVerificationPending}
            selectedChannel={channel.channel_id}
          />
        )}
      {feeds.map((feed, index) => (
        <ChannelFeed key={index} feed={feed} openFullView={openFullView} />
      ))}
      {loading && <div> Loading ....</div>}
      {error && <div>Could not load feeds</div>}
      <div ref={loader} />

      <Modal
        open={fullView}
        blockScroll={true}
        style={customStyles}
        onClose={closeFullView}
        closeIcon={closeIcon}
        classNames={{ modal: "feedModel" }}
        ref={fullViewFeedRef}
      >
        <FullViewFeed
          profile_pic={profile_pic}
          close={closeFullView}
          feed={modalData}
          setFeeds={setFeeds}

          addComment={addComment}
          updateLikeCount={updateLikeCount}
          submitSurvey={submitSurvey}
          updateViewCount={updateViewCount}
          deleteComment={deleteComment}
          updateComment={updateComment}
          openLikesModal={openLikesModal}
          openCommentMobileView={commentsListInfo}
          registerWebinar={registerWebinar}
          user_verification_info={user_verification_info}
        ></FullViewFeed>
      </Modal>
    </>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default ChannelFeeds;
