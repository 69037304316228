import Feed from "./Feed";

const Feeds = ({
  feeds,
  profile_pic,
  updateLikeCount,
  addComment,
  openFullView,
  commentsListInfo,
  openLikesModal,
  channels,
  submitSurvey,
  registerWebinar,
  user_verification_info,
}) => {
  return (
    <>
      {feeds.map((feed, index) => (
        <Feed
          key={index}
          feed={feed}
          profile_pic={profile_pic}
          updateLikeCount={updateLikeCount}
          addComment={addComment}
          openFullView={openFullView}
          commentsListInfo={commentsListInfo}
          submitSurvey={submitSurvey}
          openLikesModal={openLikesModal}
          channels={channels}
          registerWebinar={registerWebinar}
          user_verification_info={user_verification_info}
        />
      ))}
    </>
  );
};

export default Feeds;
