import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dropdown from "react-bootstrap/Dropdown";
import SplitButton from "react-bootstrap/SplitButton";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { logout } from "../utils/utils";
import logo from "../assets/logo4.png";
import humberg from "../assets/humberg.png";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import profile_pic_default from "../assets/profile_icons/default-profile.png";
import send from "../assets/Icons/Icon feather-arrow-left@2x.png";
import { getDocId, getHeaders } from "../utils/utils";
import { FaBeer } from "react-icons/fa";
import home from "../assets/mobile_headerIcons/home.svg";
import knowledge from "../assets/mobile_headerIcons/Group 7.svg";
import community from "../assets/mobile_headerIcons/Group 9.svg";
import professional from "../assets/mobile_headerIcons/Group 11.svg";
import knowledgeInactive from "../assets/mobile_headerIcons/Group 8.svg";
import communityInactive from "../assets/mobile_headerIcons/Group 10.svg";
import professionalInactive from "../assets/mobile_headerIcons/Group 12.svg";
import homeInactive from "../assets/mobile_headerIcons/home_inactive.png";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Createpost from "../createPost/Createpost";
import { BsSearch } from "react-icons/bs";
import { MdClear } from "react-icons/md";
import dummy from "../assets/profile_icons/default-profile.png";
import { Modal } from "react-responsive-modal";
import FullViewFeed from "./feeds/FullViewFeed";
import { closeIcon } from "../utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPopover-root": {
      position: "fixed",
      zIndex: "1300",
      inset: "0px",

      marginLeft: "-15px",
    },
    "& .MuiList-padding": {
      width: "240px",
      paddingTop: "8px",
      paddingBottom: "8px",
    },
    "& .MuiMenu-paper": {
      marginLeft: "-14px",
      marginTop: "-14px",
      maxHeight: "calc(100% - 96px)",
      webkitOverflowScrolling: "touch",
    },
    "& .MuiListItem-button": {
      marginLeft: "5px",
      backgroundColor: "#eeeeee",
      color: "gray",
      /* background-color: gray; */
      transition: "backgroundcolor 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
const DahboardHeader = ({
  isMandatoryInfoEntered = true,
  userData,
  handleUploadMci,
  handleVerificationPending,
  loadDashBoardData = () => {},
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const doc_id = getDocId();
  const headers = getHeaders();
  const [iconState, setIconState] = useState("dashboard");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [userInfo, setUserInfo] = useState({});

  const [searchValue, setSearchValue] = useState("");
  const [expandSearch, setExpandSearch] = useState(false);

  const clearSearch = () => {
    console.log("wut");
    setSearchValue("");
    setExpandSearch(false);
  };

  const getLatestDashBoardData = async () => {
    loadDashBoardData();
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // const mandatoryInfoCheck = location.state
  //   ? location.state.is_user_active
  //   : true;

  // const [isMandatoryInfoEntered, setIsMandatoryInfoEntered] =
  //   useState(mandatoryInfoCheck);

  const userText = (string) => {
    var count = 10;
    if (string)
      return (
        string.charAt(0).toUpperCase() +
        string.slice(1, count) +
        (string.length > count ? ".." : "")
      );
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    getPage();
  }, []);

  // useEffect(() => {
  //   if (isMandatoryInfoEntered) fetchUserData();
  // }, [isMandatoryInfoEntered]);

  const [sidebar, setSidebar] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setSidebar(open);
  };

  const [channels, setChannels] = useState([]);
  useEffect(() => {
    getChannels();
    fetchUserData();
  }, []);

  // useEffect(() => {
  //   fetchUserData();
  // }, [userData]);

  const getChannels = async () => {
    const payload = {
      doc_id,
      last_feed_time: 0,
      load_next: true,
      pg_num: 0,
    };
    const res = await fetch(baseUrl + "v3/getDashBoardData", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
      return;
    });

    const data = await res.json();
    if (data.status === "success") {
      if (data.data.listofChannels)
        setChannels((prev) => {
          return [...prev, ...data.data.listofChannels];
        });
    } else toast.error(data.errorMsg);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {/* <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
      <div className="container">
        <div className="row">
          <div className="col-4">
            <img
              style={{
                cursor: "pointer",
                width: "45px",
                height: "45px",
                borderRadius: "50%",
                marginTop: "12px",
              }}
              className="profile-image"
              src={
                userData?.profile_pic_original_url &&
                userData.profile_pic_original_url !== ""
                  ? userData.profile_pic_original_url + `?${Date.now()}`
                  : profile_pic_default
              }
              alt="profile pic"
            />
          </div>
          <div className="col-8" style={{ padding: "5px 0px 0px 0px" }}>
            <span
              className="timeline-title"
              style={{
                font: "normal normal  20px/29px Roboto",
                fontWeight: "800",
                wordBreak: "break-word",
                fontWeight: "900",
                color: "#2a8f80",
              }}
            >
              {userData?.user_salutation} {userText(userData?.user_full_name)}
            </span>
            <br />
            <span
              className="timeline-title"
              style={{
                font: "normal normal  15px/15px Roboto",
                color: "#484848",
              }}
            >
              {userData?.specialities}
            </span>
          </div>
        </div>
      </div>

      <MenuItem
        onClick={handleClose}
        style={{ marginTop: "10px", backgroundColor: "whitesmoke" }}
      >
        <Link
          to="/profile"
          style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
        >
          My Profile Settings
        </Link>
      </MenuItem>
      <MenuItem
        onClick={handleClose}
        style={{ marginTop: "0.3px", backgroundColor: "whitesmoke" }}
      >
        <Link
          to="/help"
          style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
        >
          Help
        </Link>
      </MenuItem>
      {/* <MenuItem onClick={() => logout(history)}>
          <span style={{ cursor: "pointer", color: "black", fontSize: "15px" }}>
            Logout
          </span>
        </MenuItem> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          margin: "15px 0px 15px 0px",
        }}
      >
        <a
          href="https://www.whitecoats.com/termsofusedoctorsnetwork"
          target="blank"
          style={{
            color: "black",
          }}
        >
          Terms of Use
        </a>

        {/* <Link onClick={() => logout(history)}>
            <span style={{ cursor: "pointer", color: "black", fontSize: "15px" }}>
              Change Password
            </span>
          </Link> */}

        <span
          onClick={() => logout(history)}
          style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
        >
          Logout
        </span>
      </div>
      <MenuItem
        onClick={handleClose}
        style={{ marginTop: "0.3px", backgroundColor: "whitesmoke" }}
      ></MenuItem>
      <MenuItem
        onClick={handleClose}
        style={{ marginTop: "0px", backgroundColor: "whitesmoke" }}
      ></MenuItem>
      <MenuItem
        onClick={handleClose}
        style={{ marginTop: "0px", backgroundColor: "whitesmoke" }}
      ></MenuItem>
      <MenuItem
        onClick={handleClose}
        style={{ marginTop: "0px", backgroundColor: "whitesmoke" }}
      ></MenuItem>
      <MenuItem
        onClick={handleClose}
        style={{ marginTop: "0px", backgroundColor: "whitesmoke" }}
      ></MenuItem>
      <MenuItem
        onClick={handleClose}
        style={{ marginTop: "0px", backgroundColor: "whitesmoke" }}
      ></MenuItem>
      <MenuItem
        onClick={handleClose}
        style={{ marginTop: "0px", backgroundColor: "whitesmoke" }}
      ></MenuItem>
      <MenuItem
        onClick={handleClose}
        style={{ marginTop: "0px", backgroundColor: "whitesmoke" }}
      ></MenuItem>

      <div
        style={{
          textAlign: "center",
          // backgroundColor: "whitesmoke",
          position: "fixed",
          left: 0,
          bottom: "15px",
          // width: "68%",
        }}
      >
        <MenuItem>
          <p style={{ textAlign: "center" }}>
            <img
              src={logo}
              style={{
                height: "3em",
                width: "auto",
                padding: "3px",
                marginLeft: "50px",
              }}
              alt="Logo"
            />
          </p>
        </MenuItem>
      </div>
    </Box>
  );

  const fetchUserData = async () => {
    // const IP_ADDRESS = await getClientIp();
    // headers["x-device-id"] = IP_ADDRESS;
    const payload = {
      user_id: doc_id,
    };
    const res = await fetch(baseUrl + "v1/userProfile/view", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();

    if (data.status === "success") {
      localStorage.setItem("email", data.data.cnt_email);
      localStorage.setItem("phone", data.data.cnt_num);
      setUserInfo(data.data);
    } else {
      toast.error(data.errorMsg);
    }
  };

  const getPage = () => {
    if (location.pathname.includes("/knowledge")) setIconState("knowledge");
    else if (location.pathname === "/dashboard" || location.pathname === "/")
      setIconState("dashboard");
    else if (location.pathname.includes("/professional"))
      setIconState("professional");
    else if (location.pathname.includes("/community"))
      setIconState("community");
    else if (location.pathname.includes("/search")) setIconState("search");
    else setIconState("none");
  };

  return (
    <div>
      {/* desktop header */}
      <div className="header-top sticky bg-white d-none d-sm-block">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-2">
              <div className="brand-logo " style={{ cursor: "pointer" }}>
                <img
                  onClick={() => {
                    if (location.pathname.includes("/dashboard"))
                      window.location.reload();
                    else history.push("/dashboard");
                  }}
                  src={logo}
                  style={{ height: "3em", width: "auto", padding: "3px" }}
                  alt="Logo"
                />
              </div>
            </div>
            <div className="col-md-8" style={{ paddingLeft: 0 }}>
              <div className="header-top-navigation dashboard-header">
                {/* <form> */}
                {/* <form
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: expandSearch ? "100%" : "auto",
                    margin: expandSearch ? "5px" : 0,
                  }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (!expandSearch) setExpandSearch(true);
                    else {
                      if (searchValue === "") {
                        toast("Please provide a search keyword", {
                          duration: 1500,
                        });
                        return;
                      }
                      if (searchValue.length < 3) {
                        toast("Please enter minimum 3 characters for search", {
                          duration: 1500,
                        });
                        return;
                      }
                      history.push(`/search/${searchValue}`);
                    }
                  }}
                >
                  <div
                    style={
                      expandSearch
                        ? searchExpandedStyles
                        : searchCollapsedStyles
                    }

                  >
                    <input
                      type="text"
                      placeholder="Search cases or articles"
                      value={searchValue}
                      style={{
                        border: "none",
                        backgroundColor: "inherit",
                      
                        flex: 1,
                      }}
                      onClick={() => setExpandSearch(true)}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <button type="submit">
                      <BsSearch size={17} />
                    </button>

                    {expandSearch && (
                      <MdClear
                        onClick={clearSearch}
                        style={{ marginLeft: "1em" }}
                        size={18}
                      />
                    )}
                  </div>
                </form> */}
                {/* </form> */}
                {/* {!expandSearch && ( */}
                <nav>
                  <ul style={{ margin: "2px 0px 0px 0px" }}>
                    <li className="active" style={{ textAlign: "center" }}>
                      <span
                        style={{ padding: "0", cursor: "pointer" }}
                        onClick={() => {
                          if (location.pathname.includes("/dashboard"))
                            window.location.reload();
                          else history.push("/dashboard");
                        }}
                      >
                        {iconState === "dashboard" ? (
                          <span>
                            <img
                              style={{ cursor: "pointer" }}
                              src={home}
                              alt="home"
                            />
                            <br />
                            <span
                              style={{ fontSize: "12px", color: "#2a8f80" }}
                            >
                              Home
                            </span>
                          </span>
                        ) : (
                          <span>
                            <img
                              style={{ cursor: "pointer" }}
                              src={homeInactive}
                              alt="knowledge"
                            />
                            <br />
                            <span style={{ fontSize: "12px" }}>Home</span>
                          </span>
                        )}
                      </span>
                    </li>
                    <li className="active" style={{ textAlign: "center" }}>
                      <span
                        style={{ padding: "0", cursor: "pointer" }}
                        onClick={() => {
                          if (location.pathname.includes("/knowledge"))
                            window.location.reload();
                          else history.push("/knowledge");
                        }}
                      >
                        {iconState === "knowledge" ? (
                          <span>
                            <img
                              style={{ cursor: "pointer" }}
                              src={knowledge}
                              alt="knowledge"
                            />
                            <br />
                            <span
                              style={{ fontSize: "12px", color: "#2a8f80" }}
                            >
                              Knowledge
                            </span>
                          </span>
                        ) : (
                          <span>
                            <img
                              style={{ cursor: "pointer" }}
                              src={knowledgeInactive}
                              alt="knowledge"
                            />
                            <br />
                            <span style={{ fontSize: "12px" }}>Knowledge</span>
                          </span>
                        )}
                      </span>
                    </li>
                    <li className="active" style={{ textAlign: "center" }}>
                      <span
                        style={{ padding: "0", cursor: "pointer" }}
                        onClick={() => {
                          if (location.pathname.includes("/community"))
                            window.location.reload();
                          else history.push("/community");
                        }}
                      >
                        {iconState === "community" ? (
                          <span>
                            <img
                              style={{ cursor: "pointer" }}
                              src={community}
                              alt="knowledge"
                            />
                            <br />
                            <span
                              style={{ fontSize: "12px", color: "#2a8f80" }}
                            >
                              Community
                            </span>
                          </span>
                        ) : (
                          <span>
                            <img
                              style={{ cursor: "pointer" }}
                              src={communityInactive}
                              alt="knowledge"
                            />
                            <br />
                            <span style={{ fontSize: "12px" }}>Community</span>
                          </span>
                        )}
                      </span>
                    </li>
                    <li className="active" style={{ textAlign: "center" }}>
                      <span
                        style={{ padding: "0", cursor: "pointer" }}
                        onClick={() => {
                          if (location.pathname.includes("/professional"))
                            window.location.reload();
                          else history.push("/professional");
                        }}
                      >
                        {iconState === "professional" ? (
                          <span>
                            <img
                              style={{ cursor: "pointer" }}
                              src={professional}
                              alt="knowledge"
                            />
                            <br />
                            <span
                              style={{ fontSize: "12px", color: "#2a8f80" }}
                            >
                              Professional
                            </span>
                          </span>
                        ) : (
                          <span>
                            <img
                              style={{ cursor: "pointer" }}
                              src={professionalInactive}
                              alt="knowledge"
                            />
                            <br />
                            <span style={{ fontSize: "12px" }}>
                              Professional
                            </span>
                          </span>
                        )}
                      </span>
                    </li>
                    <li className="active" style={{ textAlign: "center" }}>
                      <span
                        style={{ cursor: "pointer", padding: 0 }}
                        onClick={() => {
                          if (location.pathname.includes("/search"))
                            window.location.reload();
                          else history.push("/search");
                        }}
                      >
                        <span>
                          <BsSearch
                            style={{
                              color:
                                iconState === "search" ? "#2a8f80" : "#333333",
                            }}
                            size={20}
                          />
                          <br />
                          <span
                            style={{
                              fontSize: "12px",
                              color:
                                iconState === "search" ? "#2a8f80" : "#333333",
                            }}
                          >
                            Search
                          </span>
                        </span>
                      </span>
                    </li>

                    <li className="active">
                      <div style={{ marginTop: "10px" }}>
                        <Createpost
                          channels={channels}
                          type="button"
                          home="true"
                          userInfo={userData}
                          handleUploadMci={handleUploadMci}
                          handleVerificationPending={handleVerificationPending}
                          loadDashBoardData={getLatestDashBoardData}
                        />
                      </div>
                    </li>
                  </ul>
                </nav>
                {/* )} */}
              </div>
            </div>
            <div
              className="col-md-2"
              style={{ borderLeft: "1px solid #bdb3b3" }}
            >
              <div
                className="pull-right"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div className="profile-thumb-middle">
                  <img
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                    }}
                    className="profile-image"
                    src={
                      userData?.profile_pic_original_url &&
                      userData.profile_pic_original_url !== ""
                        ? userData.profile_pic_original_url + `?${Date.now()}`
                        : profile_pic_default
                    }
                    alt="profile pic"
                  />
                </div>
                <div className="profile" style={{ display: "contents" }}>
                  <span
                    className="timeline-title"
                    style={{
                      font: "normal normal  17px/40px Roboto",
                      color: "#484848",
                    }}
                  >
                    {userData?.user_salutation}
                    {userText(userData?.user_first_name)}
                  </span>
                </div>
                <div style={{ color: "black" }}>
                  {[""].map((variant) => (
                    <SplitButton
                      key={variant}
                      id={`dropdown-split-variants-${variant}`}
                      variant={variant.toLowerCase()}
                      title={variant}
                    >
                      <div style={{ width: "200px" }}>
                        <Dropdown.Item
                          eventKey="1"
                          style={{ marginBottom: "5px" }}
                        >
                          <Link
                            to="/profile"
                            style={{
                              cursor: "pointer",
                              color: "#8D8D8D",
                              fontSize: "12px",
                            }}
                          >
                            My Profile Settings
                          </Link>
                        </Dropdown.Item>

                        <Dropdown.Item
                          eventKey="2"
                          style={{ marginBottom: "5px" }}
                        >
                          <Link
                            to="/help"
                            style={{
                              color: "#8D8D8D",
                              fontSize: "12px",
                            }}
                          >
                            <span
                              style={{
                                cursor: "pointer",
                                color: "#8D8D8D",
                                fontSize: "12px",
                              }}
                            >
                              Help
                            </span>
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="3"
                          style={{ marginBottom: "5px" }}
                          onClick={() => logout(history)}
                          className="profile-setting"
                        >
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#8D8D8D",
                              fontSize: "12px",
                            }}
                          >
                            Logout
                          </span>{" "}
                        </Dropdown.Item>
                      </div>
                    </SplitButton>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header id="header d-block d-lg-none">
        <div className="container">
          <div className="navbar-header">
            <div className="mobile-header-wrapper sticky d-block d-lg-none">
              {/* <div className="mobile-header position-relative "> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  padding: "10px",
                  justifyContent: "space-around",
                }}
              >
                {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                <div className="mobile-responsive">
                  <div className="brand-logo ">
                    <span
                    // onClick={() => {
                    //   if (location.pathname.includes("/dashboard"))
                    //     window.location.reload();
                    //   else history.push("/dashboard");
                    // }}
                    >
                      {location.pathname.includes("/help") ||
                      location.pathname.includes("/profile") ? (
                        <img
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                          src={send}
                          alt="back"
                          onClick={() => history.goBack()}
                        />
                      ) : (
                        <>
                          <Button onClick={toggleDrawer(true)}>
                            <img
                              style={{
                                marginLeft: "10px",
                                // marginTop: "9px",
                                cursor: "pointer",
                              }}
                              src={humberg}
                              alt="home logo"
                            />
                          </Button>
                          <Drawer
                            anchor={"left"}
                            open={sidebar}
                            onClose={toggleDrawer(false)}
                          >
                            {list()}
                          </Drawer>
                        </>
                      )}
                    </span>
                  </div>
                </div>
                <div className="navbar-header" style={{ flex: 1 }}>
                  <div className="mobile-responsive">
                    <div className="brand-logo ">
                      <span
                        onClick={() => {
                          if (location.pathname.includes("/dashboard"))
                            window.location.reload();
                          else history.push("/dashboard");
                        }}
                      >
                        {location.pathname.includes("/help") ||
                        location.pathname.includes("/profile") ? null : (
                          <img
                            style={{
                              marginLeft: "10px",
                              height: "40px",
                              paddingRight: "5px",
                              cursor: "pointer",
                            }}
                            src={logo}
                            alt="home logo"
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                {/* </div> */}

                <BsSearch size={20} onClick={() => history.push("/search")} />
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* <Menu className={classes.root} 
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className="container">
          <div className="row">
            <div className="col-4">
              <img
                style={{
                  cursor: "pointer",
                  width: "45px",
                  borderRadius: "50%",
                  marginTop: "12px",
                }}
                className="profile-image"
                src={
                  userInfo?.profile_pic_original_url &&
                  userInfo.profile_pic_original_url !== ""
                    ? userInfo.profile_pic_original_url
                    : profile_pic_default
                }
                alt="profile pic"
              />
            </div>
            <div className="col-8" style={{ padding: "5px 0px 0px 0px" }}>
              <span
                className="timeline-title"
                style={{
                  font: "normal normal  20px/29px Roboto",
                  fontWeight: "800",
                  wordBreak: "break-word",
                  fontWeight: "900",
                  color: "#2a8f80",
                }}
              >
                {userText(userInfo?.user_full_name)}
              </span>
              <br />
              <span
                className="timeline-title"
                style={{
                  font: "normal normal  15px/15px Roboto",
                  color: "#484848",
                }}
              >
                {userInfo?.specialities}
              </span>
            </div>
          </div>
        </div>
        <MenuItem onClick={handleClose} style={{ marginTop: "10px" }}>
          <Link
            to="/profile"
            style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
          >
            My Profile Settings
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link
            to="/help"
            style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
          >
            Help
          </Link>
        </MenuItem>
    
        <div style={{display: "flex",justifyContent: "space-around",margin:"15px 0px 15px 0px"}}>
        
        <a href="https://www.whitecoats.com/terms-of-use.html" target="blank"
            style={{
              color: "black",
            }} >
            Terms of Use</a>
          
      
          <Link onClick={() => logout(history)}>
            <span
              style={{ cursor: "pointer", color: "black", fontSize: "15px" }}
            >
              Logout
            </span>
          </Link>
        </div>
        
      
       <div style={{textAlign: "center"}}>
       <MenuItem >
         <p style={{textAlign: "center"}}><img src={logo} style={{ height: "3em", width: "auto", padding: "3px" }}  alt="Logo" /></p> 
       </MenuItem>
       </div>
      </Menu> */}

      {/* <div>
  <h1>djkn</h1>
<Link onClick={() => logout(history)}>
          <span style={{ cursor: "pointer", color: "black", fontSize: "15px" }}>
            Logout
          </span>
        </Link>
</div> */}
    </div>
  );
};

const searchCollapsedStyles = {
  border: "1px solid gray ",
  borderRadius: "5px",
  width: "16em",
  display: "flex",
  alignItems: "center",
  // justifyContent: "space-between",
  gap: "1em",
  marginRight: "1em",
  padding: "0.3em 0.8em",
  marginTop: "5px",
  // marginBottom: "5px",
  height: "80%",
  justifyContent: "space-around",
};

const searchExpandedStyles = {
  border: "1px solid rgb(30, 138, 121) ",
  borderRadius: "5px",
  width: "80%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "1em",
  marginRight: "1em",
  padding: "0.4em 0.8em",
  // marginTop: "5px",
  // marginBottom: "5px",
  // height: "80%",
};

export default DahboardHeader;
