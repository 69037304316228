import React from "react";
import { useWatch } from "react-hook-form";

const InputTextLimit = ({ control, name }) => {
  const title = useWatch({
    control,
    name,
  });

  const charLimit = 500;
  const titleLength = title ? title.length : 0;
  const charLeft = charLimit - titleLength <= 0 ? 0 : charLimit - titleLength;

  return (
    <div
      className="post-modal-help-text"
      style={{
        textAlign: "right",
        margin: "0.3em 0.3em 0.3em",
        color: charLeft <= 0 ? "red" : undefined,
      }}
    >
      {charLeft}
    </div>
  );
};

export default InputTextLimit;
