import { useRef } from "react";
import styles from "./search.module.css";
import dummy from "../assets/profile_icons/default-profile.png";
import { BsSearch } from "react-icons/bs";
import { MdClear } from "react-icons/md";
import { IoArrowBack } from "react-icons/io5";
import { useHistory, useLocation } from "react-router-dom";
import DahboardFooter from "../dashboard/DahboardFooter";
import audio_placeholder from "../assets/audio_placeholder.png";
import clip from "text-clipper";
import noResultsIcon from "../assets/searchIcons/Empty_Box.svg";
import SearchIcon from "../assets/searchIcons/Search_Feeds.svg";
import { IoIosArrowUp } from "react-icons/io";
import loading2 from "../assets/loading2.gif";

const MobileSearch = ({
  filters,
  searchFeeds,
  searchText,
  setSearchText,
  clearSearch,
  showFeeds,
  selectedFilter,
  setSelectedFilter,
  searchResults,
  userData,
  scrollData,
  loader,
  handleUploadMci,
  handleVerificationPending,
  channels,
}) => {
  const history = useHistory();
  const location = useLocation();

  const { loading, hasMore, error, noResults } = scrollData;
  const feedsContainer = useRef();

  const scrollFeedToTop = () =>
    feedsContainer.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  const openFullView = (feed_id, channel_id) => {
    return (e) =>
      history.push({
        pathname: `/feed/${feed_id}/${channel_id}`,
        state: {
          background: location,
          profile_pic: userData?.profile_pic_small_url,
          channels: channels,
          user_verification_info: userData?.verificationInfo,
        },
      });
  };

  return (
    <div className={styles.main}>
      <form onSubmit={searchFeeds} style={{ flexShrink: 0 }}>
        <div className={styles.header}>
          <IoArrowBack size={25} onClick={() => history.goBack()} />
          <div
            className={styles.searchBar}
            style={{
              borderColor: searchText.length < 3 ? "gray" : "rgb(30, 138, 121)",
            }}
          >
            <input
              className={styles.searchInput}
              placeholder="Search"
              type="text"
              value={searchText}
              maxLength={70}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button type="submit">
              <BsSearch size={16} />
            </button>
            {searchText !== "" && <MdClear onClick={clearSearch} size={18} />}
          </div>
        </div>
      </form>
      {/* <hr style={{ margin: 0 }} /> */}
      {/* {!showFeeds && (
        <div className={styles.searchOptions}>
          <button
            className={styles.searchButton}
            style={{
              backgroundColor:
                searchText.length < 3 ? "slategray" : "rgb(30, 138, 121)",
            }}
          >
            Search feeds
          </button>
        </div>
      )} */}

      {!showFeeds && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
            flexDirection: "column",
          }}
        >
          <img style={{ width: "10em" }} src={SearchIcon} />
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.8rem",
              margin: "1em",
            }}
          >
            {" "}
            Search for feeds
          </div>
          <div style={{ textAlign: "center", fontSize: "1.5rem" }}>
            Find feeds related to articles, cases, job posting <br /> and many
            more
          </div>
        </div>
      )}

      {showFeeds && (
        <>
          <div className={styles.filters} style={{ flexShrink: 0 }}>
            {filters?.searchType?.map((filter) => (
              <div
                className={styles.filter}
                style={{
                  backgroundColor:
                    selectedFilter === filter.id
                      ? "rgb(30, 138, 121)"
                      : "white",
                  color: selectedFilter === filter.id ? "white" : "black",
                }}
                onClick={() => setSelectedFilter(filter.id)}
              >
                <div style={{ whiteSpace: "nowrap" }}>{filter.name}</div>
              </div>
            ))}
          </div>
          <div
            style={{
              marginBottom: "3em",
              overflowY: "auto",
              flexGrow: 1,
              overflowX: "hidden",
            }}
            ref={feedsContainer}
          >
            {noResults && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <img style={{ width: "10em" }} src={noResultsIcon} />
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.8rem",
                    margin: "1em",
                  }}
                >
                  {" "}
                  No Result Found
                </div>
              </div>
            )}
            {searchResults.map((item, i) => (
              <>
                <div
                  className={styles.feed}
                  onClick={openFullView(item.feed_id, item.channel_id)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "1em",
                      flex: 1,
                    }}
                  >
                    <div className={styles.title}>{clip(item.title, 100)}</div>

                    <div
                      className={styles.description}
                      dangerouslySetInnerHTML={{
                        __html: clip(item.short_desc, 100, {
                          html: true,
                          maxLines: 3,
                        }),
                      }}
                    ></div>
                    {/* <div className={styles.feedType}>Article</div> */}
                  </div>
                  {item.attachment_details &&
                    item.attachment_details.length > 0 && (
                      <img
                        className={styles.feedImage}
                        src={
                          item.attachment_details[0].attachment_type.toLowerCase() ===
                          "audio"
                            ? audio_placeholder
                            : item.attachment_details[0].attachment_small_url
                        }
                      />
                    )}
                </div>
                {/* <hr style={{ margin: 0 }} /> */}
              </>
            ))}
            <div ref={loader} />
            {loading && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={loading2} />
              </div>
            )}
            {error && <div>Error</div>}

            {searchResults?.length > 5 && (
              <div
                onClick={scrollFeedToTop}
                style={{
                  position: "absolute",
                  bottom: "4em",
                  right: "2em",
                  zIndex: 2,
                  backgroundColor: "rgb(30, 138, 121)",
                  borderRadius: "50%",
                  boxShadow: "2px 3px 5px rgba(0 ,0 ,0 , 0.3)",
                  padding: "1em",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IoIosArrowUp size={18} style={{ color: "white" }} />
              </div>
            )}
          </div>
        </>
      )}
      <DahboardFooter
        handleUploadMci={handleUploadMci}
        userInfo={userData}
        handleVerificationPending={handleVerificationPending}
      ></DahboardFooter>
    </div>
  );
};

export default MobileSearch;
