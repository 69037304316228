import { Link } from "react-router-dom";
import React, { useState } from "react";
import Progressbar from "../progressbar";
import { formatNumberToDisplay } from "../utils/utils";
import profile from "../assets/profile_icons/default-profile.png";
import verifyImage from "../assets/dashboard_icons/Verify.svg";
import pending from "../assets/dashboard_icons/pending.png";
// import certificate from "../assets/dashboard_icons/Group 948.png";
import { Modal } from "react-responsive-modal";
// import certificate from "../assets/dashboard_icons/certificate.svg";
// import S3 from "react-aws-s3";
// import certificate from "../assets/Icons/certificate.svg";
const DahboardLeftside = ({ userInfo, handleUploadMci }) => {
  // const profile_pic_placeholder = "assets/images/photos/Group 1019.png";
  // const baseUrl = process.env.REACT_APP_BASE_URL;
  // const doc_id = getDocId();
  // const headers = getHeaders();

  // const [isFileUploaded, setIsFileUploaded] = useState(false);
  // const fileInput = useRef(null);
  // const [imgPreviewUrl, setImgPreviewUrl] = useState("");
  // const [uploadModel, setUploadModel] = useState(false);
  // const [open, setOpen] = useState(false);
  // const closeModal = () => setOpen(false);
  // const openModal = () => setOpen(true);

  // const handleUploadMci = () => {
  //   setUploadModel(true);
  // };
  // const handleCloseUploadMci = () => {
  //   setUploadModel(false);
  // };
  // const showImagePreview = () => {
  //   setIsFileUploaded(true);
  //   setImgPreviewUrl(URL.createObjectURL(fileInput.current.files[0]));
  // };
  const [openVerificationPending, setOpenVerificationPending] = useState(false);
  const handleVerificationPending = (feed) => {
    setOpenVerificationPending(true);
  };
  const handleCloseVerificationPending = () => {
    setOpenVerificationPending(false);
  };

  const userText = (string) => {
    var count = 10;
    if (string)
      return (
        string.charAt(0).toUpperCase() +
        string.slice(1, 10) +
        (string.length > count ? "..." : "")
      );
  };

  return (
    <>
      <aside
        className="widget-area sticky-top"
        style={{ top: "80px", zIndex: "1" }}
      >
        <div
          className="card-profile widget-item p-0"
          style={{ borderRadius: "6px", width: "95%" }}
        >
          <div className="profile-banner">
            <div
              style={{
                // backgroundImage:
                //   "url('/assets/icons/MicrosoftTeams-image.png')",
                height: "90px",
                width: "100%",
                borderRadius: "7px 7px 0px 0px",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#2a8f80",
              }}
            >
              <div
                style={{
                  position: "relative",
                  top: "3em",
                  borderRadius: "50%",
                  height: "100px",
                  width: "100px",
                }}
              >
                <Link to="/profile">
                  <img
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "50%",
                    }}
                    src={
                      userInfo?.profile_pic_original_url &&
                      userInfo?.profile_pic_original_url !== ""
                        ? userInfo?.profile_pic_original_url
                        : profile
                    }
                    alt="profile pic"
                  />
                </Link>
              </div>
            </div>
            <div
              className="text-center"
              style={{ marginTop: "55px", padding: "10px" }}
            >
              <h6 className="speciality-title">Welcome!</h6>
              <h6 className="timeline-title">
                <Link
                  className="timeline-title"
                  to="/profile"
                  style={{ color: "#484848" }}
                >
                  {userInfo?.user_salutation}&nbsp;
                  {userText(userInfo?.user_full_name)}
                </Link>
              </h6>
              <h6 className="speciality-title">{userInfo?.specialities}</h6>
            </div>
            <div className="text-center">
              <hr></hr>
              <div className="row">
                <div className="col-sm-3" style={{ padding: "0px" }}>
                  <p
                    className="profile-count"
                    style={{
                      textAlign: "left",
                      paddingLeft: "25px",
                      font: " normal normal normal bold 20px/20px  roboto",
                    }}
                  >
                    {formatNumberToDisplay(
                      userInfo?.activity_count?.connects_cnt
                    )}
                  </p>
                  <div
                    style={{
                      font: "normal normal normal 14px/17px Roboto",
                      color: "rgb(51, 51, 51)",
                      paddingLeft: "19px",
                    }}
                  >
                    Connects
                  </div>
                </div>
                <div className="col-sm-3" style={{ padding: "0px" }}>
                  <p
                    className="profile-count"
                    style={{
                      textAlign: "left",
                      paddingLeft: "11px",
                      font: " normal normal normal bold 20px/20px  roboto",
                    }}
                  >
                    {formatNumberToDisplay(
                      userInfo?.activity_count?.followers_count
                    )}
                  </p>
                  <p
                    className="profile-connects"
                    style={{
                      font: "normal normal normal 14px/17px  Roboto",
                      color: "rgb(51, 51, 51)",
                      paddingLeft: "1px",
                    }}
                  >
                    Followers
                  </p>
                </div>
                <div className="col-sm-3" style={{ padding: "0px" }}>
                  <p
                    className="profile-count"
                    style={{
                      textAlign: "left",
                      paddingLeft: "11px",
                      fontWeight: "700",
                      font: " normal normal normal bold 20px/20px  roboto",
                    }}
                  >
                    {formatNumberToDisplay(
                      userInfo?.activity_count?.following_count
                    )}
                  </p>
                  <p
                    className="profile-connects"
                    style={{
                      font: "normal normal normal 14px/17px Roboto",
                      color: "rgb(51, 51, 51)",
                      paddingLeft: "1px",
                    }}
                  >
                    Following
                  </p>
                </div>
                <div className="col-sm-3" style={{ padding: "0px" }}>
                  <p
                    className="profile-count"
                    style={{
                      textAlign: "left",
                      paddingLeft: "5px",
                      fontWeight: "700",
                      font: " normal normal normal bold 20px/21px roboto",
                    }}
                  >
                    {formatNumberToDisplay(userInfo?.activity_count?.feedCount)}
                  </p>
                  <p
                    className="profile-connects"
                    style={{
                      font: "normal normal normal 14px/17px Roboto",
                      color: "rgb(51, 51, 51)",
                      paddingRight: "15px",
                    }}
                  >
                    My Posts
                  </p>
                </div>
              </div>
              <hr></hr>
              {userInfo?.verificationInfo?.is_user_verified === 1 && (
                <>
                  <div className="profile-verification">
                    <div>
                      <img
                        src="/assets/icons/error.svg"
                        style={{ height: "1em", marginLeft: "10px" }}
                        alt="timeline logo"
                      />
                    </div>
                    <div>
                      <p className="profile-verification-progress">
                        Your Profile Verification is pending, Verify now to get
                        full access to WhiteCoats Network.
                      </p>
                    </div>
                    <div>
                      <p
                        className="verification-logo"
                        onClick={handleUploadMci}
                      >
                        <img
                          src={verifyImage}
                          onClick={handleUploadMci}
                          style={{
                            marginRight: "8px",
                            cursor: "pointer",
                            height: "2.3em",
                            marginTop: "-6px",
                          }}
                          alt="timeline logo"
                        />
                      </p>
                    </div>
                  </div>
                  <hr></hr>
                </>
              )}
              {userInfo?.verificationInfo?.is_user_verified === 2 && (
                <>
                  <div className="profile-verification">
                    <div>
                      <img
                        src={pending}
                        style={{ height: "1em", marginLeft: "5px" }}
                        alt="pending "
                      />
                    </div>
                    <div>
                      <p
                        className="profile-verification-progress"
                        onClick={handleVerificationPending}
                      >
                        Your Profile is under verification.
                      </p>
                    </div>
                    <div>
                      <p className="verification-logo">
                        <img
                          onClick={handleVerificationPending}
                          src={verifyImage}
                          style={{
                            height: "1.3em",
                            marginRight: "8px",
                            cursor: "pointer",
                          }}
                          alt="timeline logo"
                        />
                      </p>
                    </div>
                  </div>
                  <hr></hr>
                </>
              )}
              <div
                className="text-center"
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  font: "normal normal normal bold 17px/18px Roboto",
                }}
              >
                <Link
                  style={{
                    color: "#2A8F80",
                    font: "normal normal normal 14px/17px roboto",
                  }}
                  to="/profile"
                >
                  Go To Profile Page
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="">
        <div className="widget-body">
          <div className="add-thumb">
            <Link to="#">
              <img src={ad} alt="profile picture" />
            </Link>
          </div>
        </div>
      </div> */}
        <div className="" style={{ marginTop: "15px" }}>
          <div className="widget-body" style={{ padding: "0px 16px 0px 5px" }}>
            <div className="add-thumb">
              <div className="footer-conatiner">
                <p className="footer-title-mobile">
                  <span
                    className="link"
                    onClick={() => {
                      window.open("https://www.whitecoats.com/about-us.php");
                    }}
                  >
                    About WhiteCoats
                  </span>{" "}
                </p>
                <p className="footer-title-mobile">
                  <span
                    className="link"
                    onClick={() => {
                      window.open(
                        "https://www.whitecoats.com/whitecoats-network-benefits.php"
                      );
                    }}
                  >
                    Benefits of WhiteCoats
                  </span>{" "}
                </p>
                {/* <p className="footer-title-mobile">
                  {" "}
                  <Link to="#" className="footer-title-mobile">
                    Help
                  </Link>{" "}
                </p> */}
              </div>
              <div className="footer-conatiner">
                <p className="footer-title-mobile">
                  {" "}
                  <span
                    className="link"
                    onClick={() => {
                      window.open("https://www.whitecoats.com/contact-us.php");
                    }}
                  >
                    Contact Us
                  </span>
                </p>
                <p className="footer-title-mobile">
                  <span
                    className="link"
                    onClick={() => {
                      window.open(
                        "https://www.whitecoats.com/terms-of-use.php"
                      );
                    }}
                  >
                    Terms of Use
                  </span>
                </p>
                <p className="footer-title-mobile">
                  <span
                    className="link"
                    onClick={() => {
                      window.open(
                        "https://www.whitecoats.com/privacy-policy.php"
                      );
                    }}
                  >
                    Privacy Policy
                  </span>
                </p>
              </div>
              <div className="footer-conatiner">
                <p className="footer-title-mobile">
                  {" "}
                  <span
                    className="link"
                    onClick={() => {
                      window.open(
                        "https://www.whitecoats.com/community-guidelines.php"
                      );
                    }}
                    style={{ fontSize: "11px" }}
                  >
                    Community Guidelines
                  </span>
                </p>
                <p className="footer-title-mobile">
                  <span
                    className="link"
                    onClick={() => {
                      window.open(
                        "https://www.whitecoats.com/compliance-certifications.php"
                      );
                    }}
                    style={{ fontSize: "11px" }}
                  >
                    Compliances & Certifications
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </aside>

      <Modal
        open={openVerificationPending}
        onClose={handleCloseVerificationPending}
        classNames={{ modal: "verificationModel" }}
      >
        <div style={{ marginTop: "15px" }}></div>
        <div className="verifaction">
          <p className="verification-title">
            {userInfo?.verificationInfo?.is_user_verified === 1 &&
              "Profile Verification Pending"}
            {userInfo?.verificationInfo?.is_user_verified === 2 &&
              "Profile Under Verification"}
          </p>
          <p className="verification-sub-title">
            {userInfo?.verificationInfo?.is_user_verified === 1 &&
              "Your profile verification is pending"}
            {userInfo?.verificationInfo?.is_user_verified === 2 &&
              "Your certificate has been upload successfully"}
          </p>
          <p className="verification-sub-title">
            {userInfo?.verificationInfo?.is_user_verified === 1 &&
              "Please verify your profile to get full access"}
            {userInfo?.verificationInfo?.is_user_verified === 2 &&
              "We will verify your certificate and validate your profile"}
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={handleCloseVerificationPending}
            style={{
              backgroundColor: "#ffffff",
              border: "1px solid gray",
              color: "black",
              padding: "11px",
              width: "150px",
              fontsize: "11px",
              marginBottom: "10px",
              borderRadius: "9px",
            }}
            className="submit-btn"
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DahboardLeftside;
