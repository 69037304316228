import { useState, useRef, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import exp from "../../assets/Icons/Icon map-doctor.svg";
import cityscape from "../../assets/Icons/cityscape.svg";
import toast from "react-hot-toast";
import { getHeaders } from "../../utils/utils";
import doctor from "../../assets/Icons/Doctor.svg";

const ProfessionalInfo = ({ submitMandatoryInfo, isSpecialityEntered }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [cities, setCities] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [yearsOfExperience, setYearsOfExperience] = useState([]);

  useEffect(() => {
    autoSuggestions();
    getSpecialties();
    // TODO: optimize later
    let experience = [];
    experience.push({ value: 0, label: "0-1 years" });
    for (let i = 1; i <= 70; i++) {
      experience.push({ value: i, label: `${i} years` });
    }
    setYearsOfExperience(experience);
    // eslint-disable-next-line
  }, []);

  const getSpecialties = async () => {
    const res = await fetch(baseUrl + "v1/getSpecialties", {
      method: "POST",
      headers,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let specialities = [];
    if (data.status === "success") {
      for (const speciality of data.data.specialities) {
        specialities.push({ value: speciality, label: speciality });
      }
    }
    setSpecialities(specialities);
  };

  const autoSuggestions = async () => {
    const payload = { cities: "cities" };

    const res = await fetch(baseUrl + "v2/autoSuggestions", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let cities = [];

    if (data.status === "success") {
      for (const city of data.data.cities) {
        cities.push({ value: city, label: city });
      }
    }

    setCities(cities);
  };

  return (
    <form onSubmit={handleSubmit((dt) => submitMandatoryInfo(dt))}>
      <div className="container" style={{ padding: 0 }}>
        {errors.practicingAt && (
          <div className="row error-title">
            <span className="error-title-text">
              Please enter where you are practicing at
            </span>
          </div>
        )}

        <div
          style={{
            borderRadius: "9px",
            background: "#F6F6F6",
            height: "3.5em",
            width: "100%",
            margin: "1em 0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div style={{ height: "1.4em", width: "1.4em", marginLeft: "1em" }}>
              <img
                src={exp}
                alt=""
                style={{ maxHeight: "100%", maxWidth: "100%" }}
              />
            </div>

            <Controller
              name="experience"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={yearsOfExperience}
                  placeholder="Years of Experience *"
                  styles={{
                    control: (style) => ({
                      ...style,
                      background: "inherit",
                      padding: "10px 10px 10px 0",
                      border: 0,
                      boxShadow: "none",
                    }),

                    container: (style) => ({
                      ...style,
                      width: "100%",
                    }),
                    menuList: (style) => ({
                      ...style,
                      height: "8.5em",
                      overflowY: "scroll",
                    }),
                  }}
                />
              )}
            ></Controller>
          </div>
        </div>
        {errors.experience?.type === "required" && (
          <div className="row error-title">
            <span className="error-title-text">
              Please enter your experience
            </span>
          </div>
        )}
        {(errors.experience?.type === "max" ||
          errors.experience?.type === "min") && (
          <div className="row error-title">
            <span className="error-title-text">
              Please enter value between 0-70
            </span>
          </div>
        )}

        <div
          style={{
            marginTop: "1em",
            marginBottom: "1em",
            background: "#F6F6F6",
            borderRadius: "9px",
            height: "3.5em",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ height: "1.4em", width: "1.4em", marginLeft: "1em" }}>
              <img
                src={cityscape}
                alt=""
                style={{ maxHeight: "100%", maxWidth: "100%" }}
              />
            </div>
            <Controller
              name="city"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Town/City *"
                  options={cities}
                  styles={{
                    control: (style) => ({
                      ...style,
                      background: "inherit",
                      padding: "10px 10px 10px 0",
                      border: 0,
                      boxShadow: "none",
                    }),
                    container: (style) => ({
                      ...style,
                      width: "100%",
                    }),
                    menuList: (style) => ({
                      ...style,
                      height: "8.5em",
                    }),
                  }}
                />
              )}
            />
          </div>
        </div>
        {errors.city && (
          <div className="row error-title">
            <span className="error-title-text">Please enter your city</span>
          </div>
        )}

        {!isSpecialityEntered && (
          <div
            style={{
              marginTop: "1em",
              marginBottom: "1em",
              background: "#F6F6F6",
              borderRadius: "9px",
              height: "3.5em",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{ height: "1.4em", width: "1.4em", marginLeft: "1em" }}
              >
                <img
                  src={doctor}
                  alt=""
                  style={{ maxHeight: "100%", maxWidth: "100%" }}
                />
              </div>
              <Controller
                name="speciality"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="Enter Speciality *"
                    options={specialities}
                    styles={{
                      control: (style) => ({
                        ...style,
                        background: "inherit",
                        padding: "10px 10px 10px 0",
                        border: 0,
                        boxShadow: "none",
                      }),
                      container: (style) => ({
                        ...style,
                        width: "100%",
                      }),
                      menuList: (style) => ({
                        ...style,
                        height: "8.5em",
                      }),
                    }}
                  />
                )}
              />
            </div>
          </div>
        )}

        {errors.speciality && (
          <div className="row error-title">
            <span className="error-title-text">
              Please enter your speciality
            </span>
          </div>
        )}
      </div>

      <div className="button-container">
        <button
          style={{
            // backgroundColor: "#0FB59B",
            borderRadius: "9px",
            borderColor: "white",
          }}
          className="submit-btn"
        >
          Get Started
        </button>
      </div>
    </form>
  );
};

export default ProfessionalInfo;
