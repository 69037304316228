import { useState, useRef, useEffect } from "react";
import Select from "react-select";
import { FaTimesCircle } from "react-icons/fa";
import toast from "react-hot-toast";
import { getDocId, getHeaders } from "../utils/utils";
import "./Professional.css";

const JobFilters = ({ setNextFeedsData }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const doc_id = getDocId();
  const headers = getHeaders();

  const locationFilterRef = useRef(null);
  const specialityFilterRef = useRef(null);
  const firstRender = useRef(true);

  const [specialities, setSpecialities] = useState([]);
  const [cities, setCities] = useState([]);

  const [clearIcon, setClearIcon] = useState(false);
  const defaultFilters = {
    location: undefined,
    speciality: undefined,
  };
  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    autoSuggestions();
    getSpecialties();
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (filters.location || filters.speciality) {
      setNextFeedsData((prev) => ({
        ...prev,
        pageNum: 0,
        last_feed_id: 0,
        filters,
      }));
      setClearIcon(true);
    } else {
      setNextFeedsData({
        pageNum: 0,
        tabId: 10,
      });
      setClearIcon(false);
    }
  }, [filters]);

  const clearFilters = () => {
    setFilters(defaultFilters);
    locationFilterRef.current.select.clearValue();
    specialityFilterRef.current.select.clearValue();
  };

  const addFilter = (filterType, selectedValue) => {
    let filter = "";
    if (filterType === 1) filter = "location";
    else if (filterType === 2) filter = "speciality";
    else return;

    setFilters((prevFilter) => ({
      ...prevFilter,
      [filter]: selectedValue ? selectedValue.value : undefined,
    }));
  };

  const autoSuggestions = async () => {
    const payload = { cities: "cities" };

    const res = await fetch(baseUrl + "v2/autoSuggestions", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let cities = [];

    if (data.status === "success") {
      for (const city of data.data.cities) {
        cities.push({ value: city, label: city });
      }
    }

    setCities(cities);
  };
  const getSpecialties = async () => {
    const payload = {
      doc_id,
      channel_id: 0,
    };
    const res = await fetch(baseUrl + "v2/getDepartmentsAndSpecialties", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let specialities = [];
    if (data.status === "success") {
      for (const speciality of data.data.specialities) {
        specialities.push({
          value: speciality.speciality_name,
          label: speciality.speciality_name,
        });
        specialities.sort((a, b) =>
          a.label > b.label ? 1 : b.label > a.label ? -1 : 0
        );
      }
    }
    setSpecialities(specialities);
  };

  return (
    <>
      <div className="mobile filter-header inline-header">Filter by </div>
      <div className="filter-container">
        <span className="desktop filter-header ">Filter by </span>
        <div className="filter">
          <Select
            ref={locationFilterRef}
            options={cities}
            placeholder="Location"
            onChange={(selectedValue) => addFilter(1, selectedValue)}
            styles={filterStyle}
            isClearable={true}
          />
        </div>
        <div className="filter">
          <Select
            ref={specialityFilterRef}
            options={specialities}
            onChange={(selectedValue) => addFilter(2, selectedValue)}
            placeholder="Speciality"
            styles={filterStyle}
            isClearable={true}
          />
        </div>
        <div style={{ visibility: clearIcon ? "visible" : "hidden" }}>
          <FaTimesCircle
            onClick={() => {
              clearFilters();
            }}
          />
        </div>
      </div>
    </>
  );
};

const filterStyle = {
  // menu: (style) => ({
  //   ...style,
  //   width: "170px",
  // }),
  indicatorSeparator: (style) => ({
    ...style,
    display: "none",
  }),
  dropdownIndicator: (style) => ({
    ...style,
    display: "none",
  }),
};

export default JobFilters;
