import { useState, useEffect, useRef, useCallback } from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import AppBar from "@material-ui/core/AppBar";
import { Link, useHistory, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Badge from "@mui/material/Badge";
import Feeds from "../dashboard/feeds/Feeds";
import { Tabs, Tab, withStyles } from "@material-ui/core";
import getWindowDimensions from "../common/hooks/getWindowDimensions";
import toast from "react-hot-toast";
import { Modal } from "react-responsive-modal";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import Progressbar from "../progressbar";
import send from "../assets/Icons/Icon feather-arrow-left@2x.png";
import S3 from "react-aws-s3";
import certificate from "../assets/dashboard_icons/Group 948.png";
import Likes from "../dashboard/social-interaction/Likes";
import CommentInput from "../dashboard/social-interaction/CommentInput";
import CommentsList from "../dashboard/social-interaction/CommentsList";
import FullViewFeed from "../dashboard/feeds/FullViewFeed";
import Typography from "@mui/material/Typography";
import CategoriesFeeds from "./CategoriesFeeds";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import useDiscoveryCategoryFeedLoader from "../dashboard/hooks/useDiscoveryCategoryFeedLoader";
import SpecialityFeeds from "./SpecialityFeeds";
import audio_placeholder from "../assets/audio_placeholder.png";
import MciUpload from "../common/MciUpload";
import MciUploadSuccess from "../common/MciUploadSuccess";

const useStyles = makeStyles((theme) => ({
  badge: {
    font: "bold 15px / 15px roboto !important",
  },
  listItemText: {
    color: "black",
    font: "bold 15px / 15px roboto !important",
  },
  listItemTextBad: {
    color: "rgb(30, 138, 121)",
    font: "bold 15px / 15px roboto !important",
    textAlign: "right",
  },
  root: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-around",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: 0,
      padding: "15px",
      marginBottom: "30px",
      webkitBoxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
      boxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
    },
    "& .MuiTypography-body1": {
      font: "normal normal normal 20px/20px Roboto",
      fontWeight: 400,
    },
    "& .PrivateTabIndicator-colorSecondary-4": {
      backgroundColor: "#1e8a79",
      border: "2px solid",
    },
    "& .PrivateTabIndicator-colorSecondary-6": {
      backgroundColor: "#2a8f80",
    },

    "& .MuiTabPanel-root": {
      padding: "2px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-around",
    },

    "& .MuiBox-root": {
      padding: "0px",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: "#2a8f80",
      height: "4px",
    },
    "& .MuiTabPanel-root": {
      padding: "2px",
    },

    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      /* font-size: 11px; */
      font: "13px / 40px Roboto",
    },
    "& .MuiTab-root": {
      minWidth: "60px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  test: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: 0,
      padding: "15px",
      marginBottom: "30px",
      webkitBoxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
      boxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
    },
    "& .MuiTypography-body1": {
      font: "normal normal normal 20px/20px Roboto",
      fontWeight: 400,
    },
    "& .PrivateTabIndicator-colorSecondary-4": {
      backgroundColor: "#1e8a79",
      border: "2px solid",
    },
    "& .PrivateTabIndicator-colorSecondary-6": {
      backgroundColor: "#2a8f80",
    },

    "& .MuiTabPanel-root": {
      padding: "2px",
    },
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },

    "& .MuiBox-root": {
      padding: "0px",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: "#2a8f80",
      height: "4px",
    },
    "& .MuiTabPanel-root": {
      padding: "2px",
    },

    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      /* font-size: 11px; */
      font: "13px / 40px Roboto",
    },
    "& .MuiTab-root": {
      minWidth: "27px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const DistributionCategories = ({
  //   isDistCategory,
  //   categoryDistList,
  //setSpecialityId,
  categoryId,
  user_verification_info,
  channels,
  profile_pic,
  categoryType,
  categoryName,
  categoryNameLabel,
  setUpdateCategoryUnreadCount,
  changeHeader,
  //setSpeciality_id
  //speciality_id
  //   setCategoryType,
  //   setCategoryName,
  //   setCategoryDistList,
  //   setShouldDisplayCategoryDist,
}) => {
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [feedsErrorMsg, setFeedsErrorMsg] = useState("Could not load Feeds");
  const doc_id = getDocId();
  // const changeHeader = true;
  const headers = getHeaders();
  const classes = useStyles();
  const [value, setValue] = useState("4");
  const [feedsList, setFeedsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [shouldDisplayFeeds, setShouldDisplayFeeds] = useState(false);
  const [shouldDisplayDistFeeds, setShouldDisplayDistFeeds] = useState(true);
  const [shouldDisplayCategoryFeeds, setShouldDisplayCategoryFeeds] =
    useState(false);
  const [filterDistFeeds, setFilterDistFeeds] = useState([]);
  const [category_Id, setCategory_Id] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isShowSearchFeeds, setIsShowSearchFeeds] = useState(false);
  const [categoryDistList, setCategoryDistList] = useState([]);
  const [feedsData, setFeedsData] = useState([]);
  const [specialityId, setSpecialityId] = useState();
  const [specialityName, setSpecialityName] = useState();
  const [disrutionNames, setDisrutionNames] = useState([]);
  const [disrutionSpecialites, setDisrutionSpecialities] = useState([]);
  const [nextFeedsData, setNextFeedsData] = useState({
    pageNum: 0,
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [openFullView, setOpenFullView] = useState(false);
  const closeIcon = <img src={close}></img>;

  const handleOpenFullView = (feed) => {
    // window.scrollTo(0, 0);
    setModalData(feed);
    setOpenFullView(true);
  };
  const handleCloseFullView = () => {
    setOpenFullView(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseFullViewComment = () => {
    setCommentsListInfo(false);
  };
  const handleCloseVerificationPending = () => {
    setOpenVerificationPending(false);
  };
  const fullViewFeedRef = useRef(null);
  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);
  const [uploadMciSuccess, setUploadMciSuccess] = useState(false);
  const openUploadMciSuccess = () => setUploadMciSuccess(true);
  const closeUploadMciSuccess = () => setUploadMciSuccess(false);
  const fileInput = useRef(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState("");
  const [uploadModel, setUploadModel] = useState(false);
  const [commentsListInfo, setCommentsListInfo] = useState(false);
  // const [headerTitle, setHeaderTitle] = useState(setCategoryListInfo);
  const [userData, setUserData] = useState([]);
  // const [shouldDisplayFeeds , setShouldDisplayFeeds] = useState(true);
  const location = useLocation();
  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  const getFeedPath = () => {
    if (location?.pathname === "/knowledge") return true;
    if (location?.pathname === "/dashboard") return false;
  };

  const feedPath = getFeedPath();
  useEffect(() => {
    fetchUserData();
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    getSpecialities();
    getCategories();
    // getCategories();
  }, []);
  // console.log("categoryDistList"+categoryDistList)
  const userText = (string) => {
    var count = 100;
    if (string)
      return (
        string.charAt(0).toUpperCase() +
        string.slice(1, 100) +
        (string.length > count ? "..." : "")
      );
  };
  const textTitle = (string) => {
    var count = 100;
    if (string)
      return (
        string.charAt(0).toUpperCase() +
        string.slice(1, 105) +
        (string.length > count ? "<p>Read more</p>" : "")
      );
  };

  const getSpecialities = async () => {
    const payload = {
      user_id: doc_id,
      category_id: categoryId,
      pg_num: 0,
    };
    const res = await fetch(
      baseUrl + "v1/category-distribution/getspecialities",
      {
        method: "POST",
        headers,
        body: "reqData=" + JSON.stringify(payload),
      }
    );
    const data = await res.json();
    console.log("result");
    setCategoryList(data.data.specialities);
    // categoryList.sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }))
    setFilterDistFeeds(categoryList);
  };
  const getCategories = async () => {
    setShouldDisplayFeeds(true);
    //   const payload = {
    //     user_id: doc_id,
    //     category_id: categoryId,
    //     pg_num: 0,
    //   };
    //   const res = await fetch(baseUrl + "v1/feed/categories/get", {
    //     method: "POST",
    //     headers,
    //     body: "reqData=" + JSON.stringify(payload),
    //   });

    // const data = await res.json();
    // if (data.status === "success")
    //   if (data.data?.feed_data && data.data?.feed_data?.length > 0) {
    //     setShouldDisplayFeeds(true);
    //     setFeedsList(data.data?.feed_data);
    //   }
  };

  // const getCategories = async (speciality_id) => {
  //   const payload = {
  //     speciality_id: speciality_id,
  //     pg_num: 0,
  //     category_id: categoryId
  //   };
  //   const res = await fetch(baseUrl + "v1/getFeedsBySpeciality", {
  //     method: "POST",
  //     headers,
  //     body: "reqData=" + JSON.stringify(payload),
  //   });
  //   const data = await res.json();
  //   if (data.status === "success")
  //     if (data.data?.feed_data && data.data?.feed_data?.length > 0) {
  //       setShouldDisplayFeeds(true);
  //       // setFeedsList(data.data?.feed_data);
  //     }
  // };

  const clickCategoryList = (e, speciality_id, speciality_name) => {
    e.stopPropagation();
    setShouldDisplayDistFeeds(false);
    setShouldDisplayCategoryFeeds(true);
    //  getCategories(speciality_id);
    setSpecialityName(speciality_name);
    setSpecialityId(speciality_id);
  };
  useEffect(() => {}, [feedsList]);
  const { loading, error, hasMore } = useDiscoveryCategoryFeedLoader(
    doc_id,
    nextFeedsData,
    categoryId,
    setFeedsList,
    // setShouldDisplayFeeds,
    // setCategoryName,
    // setCategoryList,
    // setCategoryDistList,
    // setShouldDisplayCategoryDist,
    // setCategoryType,
    // isDistCategory
    categoryType,
    searchValue,
    setUpdateCategoryUnreadCount
  );
  const observer = useRef(null);
  const loader = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setNextFeedsData((prev) => ({
            ...prev,
            pageNum: prev.pageNum + 1,
            // lastFeedTime: feeds[feeds.length - 1]?.feed_info?.updated_time,
          }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  const searchClick = async (key) => {
    if (searchValue == "") {
      toast.error("Please provide a search keyword");
      return;
    }
    console.log(key);
    const payload = {
      category_type: categoryType,
      category_id: categoryId,
      pg_num: 0,
      filter: { search_value: searchValue },
    };
    const res = await fetch(baseUrl + "v2/search/feeds", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();

    if (data.status === "success") setFeedsList([]);
    if (data.data?.feed_data && data.data?.feed_data?.length > 0) {
      setIsShowSearchFeeds(true);
      setFeedsList((prevFeeds) => {
        return [...new Set([...prevFeeds, ...data.data?.feed_data])];
      });
    }
  };

  const searchClear = async () => {
    setSearchValue("");
    setShouldDisplayDistFeeds(true);
    setIsShowSearchFeeds(false);
  };

  // const [disrutionNames, setDisrutionNames] = useState([]);
  // const [disrutionSpecialites, setDisrutionSpecialities] = useState([]);
  let testData = <p>No data Found</p>;
  if (categoryList.length > 0) {
    testData = categoryList.reduce((value, index) => {
      const group = index.speciality_name[0];

      // setDisrutionSpecialities(value)
    });
  }
  const onClickBackDistCate = (e, data) => {
    e.stopPropagation();
    setShouldDisplayDistFeeds(true);
    setShouldDisplayCategoryFeeds(false);
  };

  const handleUploadMci = () => {
    setUploadModel(true);
  };

  const handleClickOpen = (feed) => {
    setModalData(feed);
    if (feed.feed_info.socialInteraction.likesCount == 0) {
      return;
    }
    setOpen(true);
  };
  const handleCloseUploadMci = () => {
    setUploadModel(false);
  };
  const deleteUploadedImage = (e) => {
    e.preventDefault();
    fileInput.current.value = "";
    setImgPreviewUrl("");
    setIsFileUploaded(false);
  };
  const reUploadImage = (e) => {
    deleteUploadedImage(e);
    fileInput.current.click();
  };
  const [openVerificationPending, setOpenVerificationPending] = useState(false);
  const handleVerificationPending = (feed) => {
    // handleUploadMci();
    setOpenVerificationPending(true);
  };
  const showImagePreview = () => {
    let type = fileInput.current.files[0].type;
    if (type.includes("image")) type = "image";
    else if (type.includes("video")) type = "video";
    else if (type.includes("audio")) type = "audio";
    else if (type.includes("pdf")) type = "pdf";

    if (type === "image") {
      setIsFileUploaded(true);
      setImgPreviewUrl(URL.createObjectURL(fileInput.current.files[0]));
    } else {
      toast.error("Only images are allowed");
    }
  };
  const uploadFile = async (e, fileInput, doc_id) => {
    e.preventDefault();
    let file = fileInput.current.files[0];
    let newFileName = fileInput.current.files[0].name;

    const ReactS3Client = new S3(config);
    setloaderSpinner(true);
    const data = await ReactS3Client.uploadFile(file, newFileName);
    setloaderSpinner(false);

    saveFile(data.location, doc_id);
  };
  const saveFile = async (url, doc_id) => {
    const payload = {
      doc_id,
      attachment_original_url: url,
    };

    setloaderSpinner(true);
    const res = await fetch(baseUrl + "v2/upload", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        setFeedsErrorMsg(errorMsg);
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        setFeedsErrorMsg(errorMsg);
        toast(errorMsg);
      }
      return;
    });

    const data = await res.json();
    setloaderSpinner(false);
    if (data.status === "success") {
      handleCloseUploadMci();
      openUploadMciSuccess();
      fetchUserData();
    } else toast.error(data.errorMsg);
  };
  const getFullView = async (reqData) => {
    const payload = {
      doc_id: doc_id,
      channel_id: reqData.channel_id,
      type: reqData.feed_type_id,
      feedId: reqData.feed_id,
    };
    const res = await fetch(baseUrl + "v3/fullViewFeed", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    if (data.status === "success") {
      //setModelData(data.data?.feed_info);
      handleOpenFullView(data.data);
    }
  };
  const getFeedContext = () => {
    if (location?.pathname.includes("/profile")) return "profile";
    if (location?.pathname === "/professional") return "professional";
    if (location?.pathname === "/knowledge") return "knowledge";
    if (location?.pathname === "/community") return "community";
    else return "dashboard";
  };

  const feedContext = getFeedContext();
  const fetchUserData = async () => {
    const payload = {
      user_id: doc_id,
    };
    const res = await fetch(baseUrl + "v1/userProfile/view", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });
    const data = await res.json();
    if (data.status === "success") {
      localStorage.setItem("email", data.data.cnt_email);
      localStorage.setItem("phone", data.data.cnt_num);
      setUserData(data.data);
    } else {
      toast.error(data.errorMsg);
    }
  };

  const verifyUser = () => {
    if (!userData.verificationInfo.isCommunityVerified) {
      if (userData.verificationInfo.is_user_verified == 1) {
        handleUploadMci();
        return false;
      }
      if (userData.verificationInfo.is_user_verified == 2) {
        handleVerificationPending();
        return false;
      }
    }
    return true;
  };

  const addComment = async (feed_id, channel_id, comment, attachments = []) => {
    if (!verifyUser()) return;

    if (attachments.length === 0 && comment === "") {
      toast.error("Cannot post empty comment");
      return;
    }

    let payload = JSON.stringify({
      doc_id: doc_id,
      channel_id,
      feedTypeId: feed_id,
      socialInteraction: {
        type: "Comment",
        comment,
        attachment_details: attachments,
      },
    });

    payload = payload.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });

    payload = encodeURIComponent(payload);

    const res = await fetch(baseUrl + "v1/feedSocialInteractions", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    if (data.status === "success")
      setModalData((prev) => {
        const newFeed = { ...prev };
        newFeed.feed_info.socialInteraction = data.data.socialInteraction;
        return newFeed;
      });
    else toast.error(data.errorMsg);
  };
  const updateLikeCount = async (feed_id, channel_id, isLike) => {
    if (!verifyUser()) return;

    const payload = {
      doc_id: doc_id,
      channel_id: channel_id,
      feedTypeId: feed_id,
      socialInteraction: {
        type: "Like",
        isLike,
      },
    };

    const res = await fetch(baseUrl + "v1/feedSocialInteractions", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    if (data.status === "success")
      setModalData((prev) => {
        const newFeed = { ...prev };

        newFeed.feed_info.socialInteraction = data.data.socialInteraction;
        return newFeed;
      });
    else toast.error(data.errorMsg);
  };
  const updateViewCount = async (channel_id, feed_id) => {
    const payload = {
      doc_id,
      channel_id: channel_id,
      feedId: feed_id,
    };

    const res = await fetch(baseUrl + "feeds/updateViewCount", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((err) => {
      console.log(err);
      return;
    });
    const data = await res.json();

    if (data.status === "success") {
      if (data.data.socialInteraction)
        setModalData((prev) => {
          const newFeed = { ...prev };

          newFeed.feed_info.socialInteraction = data.data.socialInteraction;
          return newFeed;
        });
      else
        setModalData((prev) => {
          const newFeed = { ...prev };
          newFeed.feed_info.socialInteraction.viewCount += 1;
          return newFeed;
        });
      // setFeeds(
      //   feeds.map((feed) => {
      //     const newFeed = { ...feed };
      //     if (feed.feed_info.feed_id === feed_id)
      //       newFeed.feed_info.socialInteraction.viewCount += 1;
      //     return newFeed;
      //   })
      // );
    } else toast.error(data.errorMsg);
  };
  const submitSurvey = async (feed_id, channel_id, question_id, option_id) => {
    if (option_id.length === 0) {
      toast("Please select an option");
      return;
    }

    const payload = {
      feed_id,
      channel_id,
      user_id: doc_id,
      feed_survey: {
        questions: [
          {
            question_id,
            options: option_id.map((option) => ({
              option_id: option,
            })),
          },
        ],
      },
    };

    const res = await fetch(baseUrl + "v1/feed/survey/update", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();
    if (data.status === "success")
      setModalData((prev) => {
        const newFeed = { ...prev };
        newFeed.feed_info.feed_survey.questions =
          newFeed.feed_info.feed_survey.questions.map((question, index) => ({
            ...question,
            options: data.data.feed_survey.questions[index].options,
          }));
        newFeed.feed_info.feed_survey.is_open = data.data.feed_survey.is_open;
        newFeed.feed_info.feed_survey.is_participated =
          data.data.feed_survey.is_participated;

        return newFeed;
      });
    else toast.error(data.errorMsg);
  };
  const deleteComment = async (feed_id, channel_id, social_interaction_id) => {
    setloaderSpinner(true);
    const payload = {
      user_id: doc_id,
      channel_id: channel_id,
      feed_id,
      social_interaction_id,
    };
    const res = await fetch(baseUrl + "v1/feeds/comment/delete", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();
    setloaderSpinner(false);
    if (data.status === "success")
      setModalData((prev) => {
        const newFeed = { ...prev };

        newFeed.feed_info.socialInteraction = data.data.socialInteraction;
        return newFeed;
      });
    else toast.error(data.errorMsg);
  };
  const updateComment = async (
    feed_id,
    channel_id,
    social_interaction_id,
    comment,
    attachments = []
  ) => {
    if (!verifyUser()) return;

    if (attachments.length === 0 && comment === "") {
      // addToast("Cannot post empty comment", {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
      return;
    }

    let payload = JSON.stringify({
      user_id: doc_id,
      channel_id,
      feed_id,
      social_interaction_id,
      comment: comment,
      attachment_details: attachments,
    });

    payload = payload.replace(/[\u007F-\uFFFF]/g, function (chr) {
      return "\\\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4);
    });

    payload = encodeURIComponent(payload);

    setloaderSpinner(true);
    const res = await fetch(baseUrl + "v1/feeds/comment/update", {
      method: "POST",
      headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });
    const data = await res.json();
    setloaderSpinner(false);
    if (data.status === "success")
      setModalData((prev) => {
        const newFeed = { ...prev };

        newFeed.feed_info.socialInteraction = data.data.socialInteraction;
        return newFeed;
      });
    else toast.error(data.errorMsg);
  };
  const registerWebinar = async (e, feed_id, verify_user, title) => {
    e.preventDefault();

    if (verify_user) {
      if (!verifyUser()) return;
    }

    const payload = {
      user_id: doc_id,
      feed_id,
    };

    const res = await fetch(baseUrl + "v1/user/webinar/register", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      let errorMsg = "";
      if (navigator.onLine) {
        errorMsg = "Whitecoats is currently unavailable. \n Try again later";
        toast(errorMsg);
      } else {
        errorMsg = "You are offline";
        toast(errorMsg);
      }
    });

    const data = await res.json();

    if (data.status === "success") {
      toast.success(`Thank you for registering for ${title}`);
      // setFeeds(
      //   feeds.map((feed) => {
      //     const newFeed = { ...feed };
      //     if (feed?.feed_info?.feed_id === feed_id)
      //       newFeed.feed_info.event_details.is_user_registered = true;
      //     return newFeed;
      //   })
      // );
      setModalData((prev) => {
        const newFeed = { ...prev };
        newFeed.feed_info.event_details.is_user_registered = true;
        return newFeed;
      });
      return true;
    } else if (data.status === "error") toast.error("Failed to registered");

    return false;
  };

  return (
    <div>
      <div>
        <div style={{ backgroundColor: "white", cursor: "pointer" }}>
          {shouldDisplayCategoryFeeds && (
            <div
              onClick={(e) => {
                onClickBackDistCate(e);
              }}
              style={{ padding: "10px" }}
            >
              {" "}
              <ArrowBackIcon size="large" />
              <span
                style={{ marginLeft: "20px", font: "bold 15px / 15px roboto" }}
              >
                {specialityName}
              </span>
            </div>
          )}
        </div>
        {shouldDisplayDistFeeds && !shouldDisplayCategoryFeeds && (
          <TabContext
            value={value}
            indicatorColor={{ backgroundColor: "green" }}
          >
            <AppBar
              className="mL"
              position="sticky"
              style={
                feedPath
                  ? {
                      backgroundColor: "white",
                      color: "black",
                      marginTop: "30px",
                      top: "161px",
                    }
                  : {
                      backgroundColor: "white",
                      color: "black",
                      marginTop: "30px",
                      top: "85px",
                    }
              }
            >
              <div
                style={
                  feedPath ? { marginTop: "-24px" } : { marginTop: "-31px" }
                }
              >
                <TabList
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="tab example"
                  className={classes.tabs}
                >
                  <Tab label="Recent" value="4" />
                  <Tab label="Specialities" value="5" />
                </TabList>
              </div>
            </AppBar>
            <TabPanel value="4" className={classes.tabs}>
              {shouldDisplayDistFeeds && categoryDistList.length > 0 && (
                <div>
                  {categoryDistList.map((res) => (
                    <div class="container" style={{ marginBottom: "0px" }}>
                      <div class="row">
                        <div class="col-4">
                          {res.attachment_details.map((data) => (
                            <img
                              width="100%"
                              src={data.attachment_original_url}
                            />
                          ))}
                        </div>
                        <div class="col-8" style={{ paddingLeft: "0px" }}>
                          <p>{userText(res.short_desc)} </p>

                          <div className="">
                            {res.title
                              ? res.title.slice(0, 110) +
                                (res.title.length > 110
                                  ? " <span style='color:#1daf76',text-decoration:'underline',cursor:'pointer'>...Read more </span>"
                                  : "")
                              : ""}
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              )}
              {/* {isShowSearchFeeds && feedsData.length > 0 && (
              <div>
                {feedsData.map((res,index) => (
                  <div class="container" style={{ marginBottom: "0px" }}>
                    <div class="row">
                      <div class="col-4">
                        {res.attachment_details.map((data) => (
                          <img
                            width="100%"
                            src={data.attachment_original_url}
                          />
                        ))}
                      </div>
                      <div class="col-8" style={{ paddingLeft: "0px" }}>
                        <p>{userText(res.short_desc)} </p>

                        <div className="">
                          {res.title
                            ? res.title.slice(0, 110) +
                              (res.title.length > 110
                                ? " <span style='color:#1daf76',text-decoration:'underline',cursor:'pointer'>...Read more </span>"
                                : "")
                            : ""}
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                ))}
                {loading && <div> Loading ....</div>}
            {error && <div>{feedsErrorMsg}</div>}
            <div ref={loader} />
              </div>
            )} */}
              {shouldDisplayFeeds && (
                <CategoriesFeeds
                  // setCategoryType={setCategoryType}
                  // setCategoryName={setCategoryName}
                  // setFeedsList={setFeedsList}
                  // setCategoryDistList={setCategoryDistList}
                  // setShouldDisplayCategoryDist={setShouldDisplayCategoryDist}
                  // setShouldDisplayFeeds={setShouldDisplayFeeds}
                  categoryId={categoryId}
                  categoryType={categoryType}
                  categoryName={categoryName}
                  user_verification_info={user_verification_info}
                  profile_pic={profile_pic}
                  channels={channels}
                  categoryNameLabel={categoryNameLabel}
                  setUpdateCategoryUnreadCount={setUpdateCategoryUnreadCount}
                  changeHeader={changeHeader}
                  feedPath={feedPath}
                ></CategoriesFeeds>
              )}
            </TabPanel>
            <TabPanel value="5" className={classes.tabs}>
              <AppBar
                className={feedPath ? "search-header-tab" : "search-header-dis"}
                position="sticky"
              >
                <InputBase
                  style={{ fontSize: "15px", marginTop: "-3px" }}
                  placeholder={categoryNameLabel}
                  type="text"
                  variant="outlined"
                  fullWidth
                  size="small"
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                />
                <div className="search-button">
                  <IconButton aria-label="search">
                    <SearchIcon
                      onClick={(event) => searchClick(event.target.value)}
                    />
                  </IconButton>
                </div>
                <div className="close-button">
                  {searchValue && (
                    <IconButton onClick={() => searchClear()}>
                      <CancelRoundedIcon />
                    </IconButton>
                  )}
                </div>
              </AppBar>
              {shouldDisplayDistFeeds &&
                !isShowSearchFeeds &&
                categoryList.length > 0 && (
                  <div>
                    <p></p>
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: "100%",
                        bgcolor: "background.paper",
                        padding: "10px",
                      }}
                    >
                      {categoryList.map((value, index) => (
                        <ListItem
                          onClick={(e) => {
                            clickCategoryList(
                              e,
                              value.speciality_id,
                              value.speciality_name
                            );
                          }}
                          style={{
                            borderTop: "1px solid #e7e5e5",
                            borderBottom: "1px solid #e7e5e5",
                          }}
                          key={value}
                          disableGutters
                          secondaryAction={
                            <IconButton>
                              <ArrowForwardIosIcon
                                onClick={(e) => {
                                  clickCategoryList(
                                    e,
                                    value.speciality_id,
                                    value.speciality_name
                                  );
                                }}
                              />
                            </IconButton>
                          }
                        >
                          <ListItemText
                            classes={{ primary: classes.listItemText }}
                            primary={` ${value.speciality_name}`}
                          />
                          <ListItemText
                            classes={{ primary: classes.listItemTextBad }}
                            primary={
                              <Badge
                                classes={{ badge: classes.badge }}
                                badgeContent={value.feed_count}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                style={{ zIndex: "0" }}
                              ></Badge>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                )}
              {isShowSearchFeeds && feedsList.length > 0 && (
                <div>
                  {feedsList.map((res) => (
                    <div class="container" style={{ marginBottom: "0px" }}>
                      <div
                        class="row"
                        onClick={(e) => {
                          if (windowDimensions.width <= 768)
                            history.push({
                              pathname: `/feed/${res.feed_id}/${res.channel_id}`,
                              state: {
                                background: location,
                                profile_pic: profile_pic,
                                channels: channels,
                                user_verification_info: user_verification_info,
                                feedContext: feedContext,
                              },
                            });
                          else getFullView(res);
                        }}
                      >
                        <div class="col-4">
                          {res.attachment_details.map((data) => (
                            <img
                              style={{ cursor: "pointer" }}
                              width="100%"
                              src={
                                data.attachment_type === "audio"
                                  ? audio_placeholder
                                  : data.attachment_small_url
                              }
                            />
                          ))}
                        </div>
                        <div class="col-8" style={{ paddingLeft: "0px" }}>
                          <p
                            style={{
                              overflowWrap: "break-word",
                              font: "bold 15px / 15px roboto",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: userText(res?.title),
                            }}
                          ></p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: res.short_desc
                                ? res.short_desc.slice(0, 75) +
                                  (res.short_desc.length > 75
                                    ? "<span style='color:#1daf76',text-decoration:'underline',cursor:'pointer''>...Read more </span>"
                                    : "")
                                : "",
                            }}
                          ></div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}
                  {loading && <div> Loading ....</div>}
                  {error && <div>{feedsErrorMsg}</div>}
                  <div ref={loader} />
                </div>
              )}
            </TabPanel>

            <Modal
              open={openFullView}
              blockScroll={true}
              style={customStyles}
              onClose={handleCloseFullView}
              closeIcon={closeIcon}
              classNames={{ modal: "feedModel" }}
              ref={fullViewFeedRef}
            >
              <FullViewFeed
                profile_pic={profile_pic}
                close={handleCloseFullView}
                feed={modalData}
                setFeeds={setFeedsList}
                addComment={addComment}
                updateLikeCount={updateLikeCount}
                submitSurvey={submitSurvey}
                updateViewCount={updateViewCount}
                channels={channels}
                deleteComment={deleteComment}
                updateComment={updateComment}
                openLikesModal={handleClickOpen}
                openCommentMobileView={commentsListInfo}
                registerWebinar={registerWebinar}
                user_verification_info={user_verification_info}
              ></FullViewFeed>
            </Modal>
            <Modal
              open={open}
              onClose={handleClose}
              classNames={{ modal: "feedsLikeModel" }}
              closeIcon={closeIcon}
            >
              <Likes feed={modalData}></Likes>
            </Modal>

            {/* Mobile responce Comment list */}
            <Modal
              open={commentsListInfo}
              blockScroll={true}
              onClose={handleCloseFullViewComment}
              classNames={{ modal: "feedsLikeModel" }}
              styles={{
                modal: {
                  height: "100vh",
                  width: "100%",
                  padding: "0",
                  margin: "0",
                  borderRadius: "0",
                  overflow: "visible",
                },
                modalContainer: {
                  overflow: "visible",
                },
              }}
              showCloseIcon={false}
              feed={modalData}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <div
                  className="mobile-header-wrapper d-block d-lg-none"
                  style={{
                    position: "-webkit-sticky",
                    position: "sticky",
                    top: "0",
                    left: "0",
                    width: "100%",
                    zIndex: "5",
                    flexShrink: "0",
                  }}
                >
                  <div className="mobile-header position-relative ">
                    <div style={{ display: "flex" }}>
                      <div className="brand-logo ">
                        <img
                          onClick={handleCloseFullViewComment}
                          src={send}
                          style={{ width: "25px", marginTop: "-4px" }}
                          alt="timeline logo"
                        />
                      </div>
                      <div className="pull-left">
                        <p style={{ marginLeft: "10px" }}>
                          Comment &nbsp;
                          <span style={{ color: "green" }}>
                            {`(${modalData?.feed_info?.socialInteraction?.commentsCount} Comments)`}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ overflowY: "auto" }}>
                  <CommentsList
                    feed={modalData}
                    deleteComment={deleteComment}
                    updateComment={updateComment}
                  />
                </div>
                <div
                  className="footer-area  d-block d-lg-none"
                  style={{
                    position: "-webkit-sticky",
                    position: "sticky",
                    bottom: "0",
                    right: "0",
                    width: "100%",
                    flexShrink: "0",
                    background: "white",
                    zIndex: "5",
                    height: "100px",
                  }}
                >
                  <hr></hr>
                  <div
                    className="post-meta"
                    style={{
                      padding: "0",
                      margin: "0",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CommentInput
                      profile_pic={userData?.profile_pic_small_url}
                      addComment={addComment}
                      feed_id={modalData?.feed_info?.feed_id}
                      channel_id={modalData.channel_id}
                      isFullFeedView={false}
                    />
                  </div>
                </div>
              </div>
            </Modal>

            {/* profile verification model */}
            <Modal
              open={openVerificationPending}
              onClose={handleCloseVerificationPending}
              classNames={{ modal: "verificationModel" }}
              closeIcon={closeIcon}
            >
              <div style={{ marginTop: "15px" }}></div>
              <div className="verifaction">
                <p className="verification-title">
                  {userData?.verificationInfo?.is_user_verified === 1 &&
                    "Profile Verification Pending"}
                  {userData?.verificationInfo?.is_user_verified === 2 &&
                    "Profile Under Verification"}
                </p>
                <p className="verification-sub-title">
                  {userData?.verificationInfo?.is_user_verified === 1 &&
                    "Your profile verification is pending"}
                  {userData?.verificationInfo?.is_user_verified === 2 &&
                    "Your certificate has been upload successfully"}
                </p>
                <p className="verification-sub-title">
                  {userData?.verificationInfo?.is_user_verified === 1 &&
                    "Please verify your profile to get full access"}
                  {userData?.verificationInfo?.is_user_verified === 2 &&
                    "We will verify your certificate and validate your profile"}
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  onClick={handleCloseVerificationPending}
                  style={{
                    backgroundColor: "#ffffff",
                    border: "1px solid gray",
                    color: "black",
                    padding: "11px",
                    width: "150px",
                    fontsize: "11px",
                    marginBottom: "10px",
                    borderRadius: "9px",
                  }}
                  className="submit-btn"
                >
                  Close
                </button>
              </div>
            </Modal>
            <Modal
              open={uploadModel}
              onClose={handleCloseUploadMci}
              classNames={{ modal: "uploadMci" }}
              closeIcon={closeIcon}
            >
              <MciUpload
                closeUploadMci={handleCloseUploadMci}
                openSuccessModal={openUploadMciSuccess}
              />
            </Modal>
            <Modal
              closeIcon={closeIcon}
              open={uploadMciSuccess}
              onClose={closeUploadMciSuccess}
              classNames={{ modal: "verificationModel" }}
            >
              <MciUploadSuccess closeUploadMciSuccess={closeUploadMciSuccess} />
            </Modal>
          </TabContext>
        )}

        {shouldDisplayCategoryFeeds && !shouldDisplayDistFeeds && (
          <SpecialityFeeds
            categoryId={categoryId}
            specialityId={specialityId}
            categoryType={categoryType}
            categoryName={categoryName}
            user_verification_info={user_verification_info}
            profile_pic={profile_pic}
            channels={channels}
            changeHeader={changeHeader}
          ></SpecialityFeeds>
        )}
      </div>
      <div>
        {/* {shouldDisplayFeeds && (
          <CategoriesFeeds
            // setCategoryType={setCategoryType}
            // setCategoryName={setCategoryName}
            // setFeedsList={setFeedsList}
            // setCategoryDistList={setCategoryDistList}
            // setShouldDisplayCategoryDist={setShouldDisplayCategoryDist}
            // setShouldDisplayFeeds={setShouldDisplayFeeds}
            categoryId={categoryId}
            categoryType={categoryType}
            categoryName={categoryName}
            is_user_verified={is_user_verified}
            profile_pic={profile_pic}
            channels={channels}
          ></CategoriesFeeds>
        )} */}
      </div>
    </div>
  );
};

export default DistributionCategories;
