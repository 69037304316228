import { useState, useEffect, useRef, useCallback } from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import React from "react";
import Progressbar from "../progressbar";
import { Link, useParams, useHistory } from "react-router-dom";
import membership from "../assets/new_profile_icons/membership.svg";
import ic_membership from "../assets/new_profile_icons/ic_membership.svg";
import "react-responsive-modal/styles.css";
import { useForm, Controller, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import Select from "react-select";
import { MdEdit } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";
import { makeStyles } from "@material-ui/core/styles";
import { MdArrowBackIos } from "react-icons/md";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import "../profile/profile.css";
import { Modal } from "react-responsive-modal";

const useStyles = makeStyles({
  smallRadioButton: {
    "& svg": {
      width: "1.5em",
      height: "1.5em",
    },
  },
});
const useStylesLabel = makeStyles((theme) => ({
  label: {
    fontSize: "1.5rem !important",
  },
}));
const styles = makeStyles((theme) => ({
  radio: {
    colorPrimary: {
      "&$checked": {
        color: "blue",
      },
    },
    checked: {},
  },
}));
const MobileMembership = ({
  defaultChannel,
  background,
  profile_pic,
  user_verification_info,
}) => {
  const {
    submitMandatoryInfo,
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    trigger,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onChange" });

  const { user } = useParams();
  //console.log("data"+setCategoryListInfo)

  const history = useHistory();
  const user_id = getDocId();
  const headers = getHeaders();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  //////////////////////////////
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const [otherUser, setOtherUser] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const classes = useStyles();
  const classesLabel = useStylesLabel();
  const classesRadio = styles;
  const [associations, setAssociations] = useState([]);
  const [selectedValue, setSelectedValue] = React.useState("print");
  const [userInfo, setUserInfo] = useState([]);
  const [editClicked, setEditClicked] = useState(false);
  const [editedData, setEditedData] = useState();
  const [deletedData, setDeletedData] = useState();
  const closeIconHide = <img src={close} ></img>;
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const [openPostSuccessView, setOpenPostSuccessView] = useState(false);
  const [updateMemberModal, setUpdateMemberModal] = useState(false);
  const closeUpdateMemberModal = () => {
      setUpdateMemberModal(false);        
      setValue("association",'');  
      setEditClicked(false); 
      setEditedData('');    
  };
  const cancelUpdateMembership = () => {
    setUpdateMemberModal(false);        
    setValue("association",'');  
    setEditClicked(false); 
    setEditedData('');    
};
  const showUpdateMemberModal = (data) => {   
      setUpdateMemberModal(true);
  };

  useEffect(() => {
    if(user == "true"){
      setOtherUser(true);
    }else{
      setOtherUser(false);
    }
    getUserInfo();
    autoSuggestions();
  }, []);

  const getUserInfo = async () => {
    setloaderSpinner(true);
    const payload = {
      user_id,
    };
    const res = await fetch(baseUrl + "v1/userProfile/view", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    });

    const data = await res.json();

    if (data.status === "success") {
      setUserInfo(data.data);
      setloaderSpinner(false);
      //setDefaultFormValue(data.data);
    }
  };

  const autoSuggestions = async () => {
    const payload = { associations: "associations" };

    const res = await fetch(baseUrl + "v2/autoSuggestions", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      if (navigator.onLine)
        toast("Whitecoats is currently unavailable. \n Try again later");
      else toast("You are offline");
      return;
    });

    const data = await res.json();

    let associations = [];

    if (data.status === "success") {
      for (const association of data.data.associations) {
        associations.push({ value: association, label: association });
      }
    }
    setAssociations(associations);
  };
  
  const hideSuccessView = () => {
    setDeletedData('');
    setOpenPostSuccessView(false);
  };
  const deleteMembershipPop = (event, data) => {   
    setOpenPostSuccessView(true);
    setDeletedData(data);
  };
  const editMembership = async(e, data) => {   
    setValue("association", {
      label: data?.title,
      value: data?.title,
    });  
    setEditClicked(true); 
    setEditedData(data);    
  };
  
  const deleteMembership = async () => {
    console.log(editedData);
    setloaderSpinner(true);
    let mem={
      prof_mem_id:deletedData.prof_mem_id
    }
    let payload = {
      user_id: user_id,
    };
    payload.membership_history = [mem];
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/delete", {
      method: "POST",
      headers: headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {   
      setValue("association", '');
      setloaderSpinner(false);
      setOpenPostSuccessView(false);
      toast.success('Membership deleted');
      setEditClicked(false); 
      setDeletedData('');
      setEditedData('');
      getUserInfo();
    } else {
      setloaderSpinner(false);
      setOpenPostSuccessView(false);
      toast.error(data.errorMsg);
    }
  };
  const addMembership = async (post) => {
    console.log(post);
    setloaderSpinner(true);
    let mem={
      title: post.association == undefined?'':post?.association?.value,
      type: "membership"
    }
    let payload = {
      user_id: user_id,
    };
    payload.membership_history = [mem];
    payload = encodeURIComponent(JSON.stringify(payload));
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/create", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {
      setValue("association",'');
      toast.success('Membership saved');
      setloaderSpinner(false);
      getUserInfo();
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  }; 
  const updateMembership = async (post) => {
    console.log(post);
    setloaderSpinner(true);
    let mem={
      title: post.association == undefined?'':post?.association?.value,
      prof_mem_id:editedData.prof_mem_id,
      type: "membership"
    }
    let payload = {
      user_id: user_id,
    };
    payload.membership_history = [mem];
    payload = encodeURIComponent(JSON.stringify(payload));
    console.log(payload);
    const res = await fetch(baseUrl + "v1/userProfile/update", {
      method: "POST",
      headers: headers,
      body: "reqData=" + payload,
    }).catch((error) => {
      console.error(error);
      setloaderSpinner(false);
      if (navigator.onLine) {
        //toast("Whitecoats is currently unavailable. \n Try again later");
      } else toast("You are offline");
      return false;
    });

    const data = await res.json();
    if (data.status === "success") {  
      setValue("association",'');
      closeUpdateMemberModal();
      setUpdateMemberModal(false);
      setEditClicked(false); 
      setEditedData('');
      toast.success('Profile updated');
      setloaderSpinner(false);
      getUserInfo();
    } else {
      setloaderSpinner(false);
      toast.error(data.errorMsg);
    }
  };
  //toggleDrawer(e, "bottom", true);
///ebiz-web/app/m/v1/userProfile/delete
///ebiz-web/app/m/v1/userProfile/Update
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "white",
          zIndex: 10,
          // overflow: "auto",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100vw",
        }}
      >
        <div
          style={{
            position: "-webkit-sticky",
            position: "relative",
            top: "0",
            left: "0",
            width: "100%",
            zIndex: "25",
            flexShrink: "0",
            marginBottom: "10px",
          }}
          className="mobile-header-wrapper sticky d-block d-lg-none"
        >
          <div
            className="mobile-header position-relative "
            style={{
              backgroundColor: "#469b8d",
              font: "bold 20px / 20px roboto",
              textAlign: "center",
            }}
          >
            <div className="mobile-responsive">
              <div className="brand-logo " style={{ width: "106%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0 1em",
                  }}
                >
                  <MdArrowBackIos
                    color="white"
                    size={25}
                    onClick={() => history.goBack()}
                  />
                  <h2 style={{ color: "white", marginLeft: "1.5em" }}>
                    Membership
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!loaderSpinner && <div
          className="conatctDetails"
          style={{ height: "80%", overflow: "auto" }}
        >
          <div>
            {!(
              (!userInfo.membership_history ||
                userInfo?.membership_history?.length === 0) 
            ) ? (
              <div>
                {userInfo.membership_history
                  ? userInfo.membership_history.map((pub, key) =>(
                    <div key={key}>
                      <div className="container-fluid">
                        <div
                          className="row print-head"
                        >
                            <div className="col-2 ">                              
                          <img
                            src={ic_membership}
                            className="print-img"
                            style={{
                              width: "35px"
                            }}
                          ></img>{" "}
                            </div>
                            <div className="col-7 ">    
                              <span
                                style={{
                                  color:
                                    "black",
                                  font:
                                    "normal normal bold 16px/16px Roboto",
                                }}> {pub.title}</span>
                            </div>
                            {!otherUser && <div className="col-1 " style={{marginLeft:"-20px"}}>  
                            
                            <div
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  color: "red",
                                }}
                                onClick={(e) => deleteMembershipPop(e,pub)}
                              >
                                <MdDeleteForever size={25} />
                                </div>
  
                            </div>}
                            {!otherUser &&<div className="col-2 ">   
                            
                            <div
                                style={{
                                  height: "25px",
                                  width: "65px",
                                  fontSize: "16px",
                                  marginTop:"3px"
                                }}
                                onClick={(e) =>{ editMembership(e,pub)}}
                              >
                                <MdEdit size={20} />
                                Edit
                              </div> 
                              
                            </div>}
                            
                        </div>
                        
                        <hr
                          style={{
                            marginTop: "15px",
                            marginBottom: "15px",
                          }}
                        ></hr>
                      </div>
                    </div>
                  ))
                  : ""}
                
              </div>
            ) : (
              <div style={{ marginBottom: "30%" }}>
                <img src={membership} className="mPub"></img>
                <div className="pub-head">
                  <p>Add your Membership Data like </p>
                  <p>-Indian Medical Association </p>
                  <p>-Dental Association </p>
                </div>
              </div>
            )}
          </div>
        </div>}

        <div>
          {" "}
          <Progressbar
            show={loaderSpinner}
            imageUrl={url}
            height="90"
            width="90"
            alignment="middle"
            alttext="Loading..."
          />
        </div>
        <div
          className="post-modal-submit"
          style={{ marginBottom: 0, height: "20em",display:"block",padding: "1em" }}
        >
          <form>
          {!otherUser && <div
              style={{
                borderRadius: "9px",
                marginTop: "1em",
                background: "#ffffff",
                borderRadius: "9px",
                height: "3em",
                border: "1px solid grey",
                width: "100%",
              }}
            >
                <Controller
                  name="association"
                  control={control}
                  rules={{ required: true}}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={associations}
                      placeholder="e.g Indian Medical Association"
                      styles={{
                        control: (style) => ({
                          ...style,
                          background: "inherit",
                          padding: "5px 10px 10px 0",
                          border: 0,
                          boxShadow: "none",
                        }),

                        container: (style) => ({
                          ...style,
                          width: "100%",
                        }),
                        menuList: (style) => ({
                          ...style,
                          height: "9em",
                        }),
                      }}
                    />
                  )}
                ></Controller>
            </div>}
            
            {!otherUser && errors.association && (
            <div className="row error-title" style={{marginLeft:"0px"}}>
              <span className="error-title-text">
              Please add your membership to save to your profile
              </span>
            </div>
          )}
          </form>
          
          {!otherUser && editClicked &&
          // <div><button
          //   type="submit"
          //   style={{
          //     backgroundColor: "#469b8d",
          //     height: "3.5em",
          //     width: "360px",
          //     marginLeft:"10px",
          //     margintop:"5em"
          //   }}
          //   className="post-modal-submit-button"
          //   onClick={handleSubmit(showUpdateMemberModal)}
          // >
          //   Update Membership
          // </button></div>
          <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between",marginTop: "6em"}}>         
          <button type="submit" className="pub-close-button" onClick={cancelUpdateMembership} style={{marginLeft:"0px"}}>
            Cancel
          </button>
          <button type="submit" className="pub-save-button" onClick={handleSubmit(showUpdateMemberModal)} style={{width:"11em",marginLeft:"0px"}}>
            Update Membership
          </button>
        </div>}
        {!otherUser && !editClicked &&<div><button
            type="submit"
            style={{
              backgroundColor: "#469b8d",
              height: "3em",
              width: "100%",
              marginTop:"6em",
              marginBottom:"1em"
            }}
            className="post-modal-submit-button"
            onClick={handleSubmit(addMembership)}
          >
            Add Membership
          </button></div>}
        </div>
      </div>
      <Modal
          open={updateMemberModal}
          closeModal={closeUpdateMemberModal}
          showCloseIcon={true}
          onClose={closeUpdateMemberModal}
          classNames={{ modal: "edit-delete-model" }}
          styles={{ modal: { padding: 0, overflow: "hidden",backgroundColor:"white" } }}
        >
          <div className="container-fluid">
          <div
            className="row"
            style={{marginTop:"30px" }}
          >
            <div className="col-sm-12 " style={{marginTop:'35px'}}>
            <div
                style={{
                  color: "black",
                  font: "20px/20px roboto",
                  marginBottom: "30px",
                  fontWeight:"bold"
                }}
              >
                Update Membership
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "grey",
                  marginBottom: "10px",
                  font: "18px/18px roboto",
                }}
              >
                {" "}
                <p>Are  you sure want to</p>
                <p>Update your membership  data?</p>
              </div>
            </div>
            <div className="col-sm-12 " >
              
              <div style={{ margin: "30px 0px" }}>         
              <button type="submit" className="pub-close-button"  onClick={closeUpdateMemberModal} style={{width:"7em",height:"2.5em"}}>
            Cancel
          </button>
          <button type="submit" className="pub-save-button" onClick={handleSubmit(updateMembership)} style={{width:"7em",height:"2.5em"}}>
            Update 
          </button>
          
        </div>
            </div>
          </div>
        </div> 
        </Modal>
      <Modal
        open={openPostSuccessView}
        blockScroll={true}
        onClose={hideSuccessView}
        closeIcon={closeIconHide}
        closeModal={hideSuccessView}
        classNames={{ modal: "edit-delete-model" }}
        styles={{ modal: { backgroundColor:"white" } }}
      >
        <div className="container-fluid">
          <div
            className="row"
            style={{marginTop:"40px" }}
          >
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "black",
                  font: "20px/20px roboto",
                  marginBottom: "30px",
                  fontWeight:"bold"
                }}
              >
                Delete Membership
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "grey",
                  marginBottom: "10px",
                  font: "18px/18px roboto",
                }}
              >
                {" "}
                <p>Do you want delete</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "black",
                  marginBottom: "10px",
                  font: "16px/16px roboto",
                  fontWeight:"bold"
                }}
              >
                {" "}
                <p style={{display: "inline-block",
                    width: "300px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"}}>{deletedData?.title}</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              <div
                style={{
                  color: "grey",
                  marginBottom: "10px",
                  font: "18px/18px roboto",
                }}
              >
                {" "}
                <p>Membership?</p>
              </div>
            </div>
            <div className="col-sm-12 ">
              
              <div style={{ margin: "20px -15px" }}>         
          <button type="submit" className="pub-close-button"  onClick={(e) => hideSuccessView(e)}>
            Cancel
          </button>
          <button type="submit" className="pub-save-button" style={{backgroundColor:"#FF2F00",marginLeft:"20px"}} onClick={() => deleteMembership()}>
            Delete 
          </button>
        </div>
            </div>
          </div>
        </div>
      </Modal> 
    </>
  );
};

export default MobileMembership;
