import { useContext, useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "../community/community.css";
import profile_pic_placeholder from "../assets/profile_icons/default-profile.png";
import ReactAudioPlayer from "react-audio-player";
import likeUnselected from "../assets/dashboard_icons/social-icons/Like.svg";
import likeSelected from "../assets/dashboard_icons/selected/Like.svg";
import commentUnselected from "../assets/dashboard_icons/social-icons/Comment.svg";
import shareUnselected from "../assets/dashboard_icons/social-icons/Share.svg";
import { FeedType } from "../professional/Partner";
import {
  getDate,
  getDocId,
  getFeedTypeColor,
  getTime,
  showUnicodeChars,
  getTimeAndDate,
} from "../utils/utils";
import FeedContent from "../dashboard/feeds/FeedContent";
import FeedAttachments from "../dashboard/feeds/FeedAttachments";
import getWindowDimensions from "../common/hooks/getWindowDimensions";
import CommentInput from "../dashboard/social-interaction/CommentInput";
import { FeedFuncs } from "../professional/Professional";

const ChannelFeed = ({ feed, openFullView }) => {
  const [windowDimensions, setWindowDimension] = useState(
    getWindowDimensions()
  );

  const channelType = useContext(FeedType);
  const {
    updateLikeCount,
    addComment,
    profile_pic,
    user_verification_info,
    openLikesModal,
    submitSurvey,
    registerWebinar,
  } = useContext(FeedFuncs);

  const history = useHistory();
  const location = useLocation();

  const doc_id = getDocId();

  const attachmentVideoRef = useRef(null);
  const attachmentAudioRef = useRef(null);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const feedCreatorProfilePic =
    feed?.post_creator?.profile_url && feed?.post_creator?.profile_url !== ""
      ? feed?.post_creator?.profile_url
      : profile_pic_placeholder;

  const feedCreator =
    feed?.post_creator?.doc_id === doc_id
      ? "You"
      : feed?.post_creator?.posted_by;

  const feedCommentUserName = `${
    feed.feed_info.socialInteraction?.comment_info?.user_info?.doc_id ===
    parseInt(doc_id)
      ? "You"
      : feed.feed_info?.socialInteraction?.comment_info?.user_info?.fname
  }`;

  const feedCommentProfilePic =
    feed.feed_info?.socialInteraction?.comment_info?.user_info &&
    feed.feed_info.socialInteraction.comment_info.user_info.profile_url !== ""
      ? feed.feed_info.socialInteraction.comment_info.user_info.profile_url
      : profile_pic_placeholder;

  const feedType =
    feed?.feed_info?.feed_type?.toUpperCase() === "ARTICLE"
      ? feed?.feed_info?.article_type?.toUpperCase()
      : feed?.feed_info?.display_tag?.toUpperCase();

  const feedLabelColor = getFeedTypeColor(feedType);

  const postingDate = new Date(feed.feed_info?.posting_time);
  const today = new Date();

  const feedDate = feed.feed_info?.posting_time
    ? today.toDateString() === postingDate.toDateString()
      ? getTime(postingDate)
      : getDate(postingDate)
    : "";

  const getFeedContext = () => {
    if (location?.pathname.includes("/profile")) return "profile";
    if (location?.pathname.includes("/professional")) return "professional";
    if (location?.pathname.includes("/knowledge")) return "knowledge";
    if (location?.pathname.includes("/community")) return "community";
    else return "dashboard";
  };

  const feedContext = getFeedContext();

  const fullViewRedirect = () => {
    if (attachmentVideoRef.current) attachmentVideoRef.current.pause();
    if (attachmentAudioRef.current)
      attachmentAudioRef.current.audioEl.current.pause();
    if (windowDimensions.width <= 768)
      history.push({
        pathname: `/feed/${feed.feed_info.feed_id}/${feed.channel_id}`,
        state: {
          background: location,
          profile_pic: profile_pic,
          channels: [],
          user_verification_info: user_verification_info,
          feedContext: feedContext,
        },
      });
    else openFullView(feed);
  };

  return (
    <div className="feed-card" style={{ paddingBottom: 0 }}>
      {channelType === "content" &&
        feed?.feed_info?.feed_type?.toUpperCase() != "SURVEY" &&
        feed?.feed_info?.feed_type?.toUpperCase() != "EVENT" && (
          <div onClick={() => fullViewRedirect()}>
            <FeedAttachments
              videoRef={attachmentVideoRef}
              audioRef={attachmentAudioRef}
              attachments={feed.feed_info?.attachment_details}
            />
          </div>
        )}
      {feed?.feed_info?.feed_type?.toUpperCase() != "SURVEY" &&
        feed?.feed_info?.feed_type?.toUpperCase() != "EVENT" && (
          <div class="feed-header" onClick={() => fullViewRedirect()}>
            {feed.feed_info?.feed_type != "Job Postings" && (
              <h3 className="community-feed-title">
                {feed.feed_info.title.slice(0, 110) + " "}
                {feed.feed_info.title.length > 110 && (
                  <span style={{ color: "#1daf76",  textDecoration: "underline", cursor: "pointer" }}>
                    {" "}
                    ...Read More
                  </span>
                )}
              </h3>
            )}
            {feed.feed_info?.feed_type === "Job Postings" && (
              <div className="feed-title">
                <div>
                  JOB OPENING: {feed.feed_info.title.slice(0, 110) + " "}
                  with {feed.feed_info?.organization}
                </div>
              </div>
            )}
          </div>
        )}

      {(feed?.feed_info?.feed_type?.toUpperCase() === "SURVEY" ||
        feed?.feed_info?.feed_type?.toUpperCase() === "EVENT") && (
        <>
          <div className="feed-content">
            <FeedContent
              feed={feed.feed_info}
              submitSurvey={submitSurvey}
              channel_id={feed.channel_id}
              attachmentVideoRef={attachmentVideoRef}
              attachmentAudioRef={attachmentAudioRef}
              openFullView={() => {
                if (attachmentVideoRef.current)
                  attachmentVideoRef.current.pause();
                if (attachmentAudioRef.current)
                  attachmentAudioRef.current.audioEl.current.pause();
                if (windowDimensions.width <= 768)
                  history.push({
                    pathname: `/feed/${feed.feed_info.feed_id}/${feed.channel_id}`,
                    state: {
                      background: location,
                      profile_pic: profile_pic,
                      channels: [],
                      user_verification_info: user_verification_info,
                      feedContext: feedContext,
                    },
                  });
                else openFullView(feed);
              }}
              registerWebinar={registerWebinar}
            />
          </div>
        </>
      )}

      {feed?.feed_info?.feed_type?.toUpperCase() != "SURVEY" &&
        feed?.feed_info?.feed_type?.toUpperCase() != "EVENT" && (
          <div className="feed-content" onClick={() => fullViewRedirect()}>
            <FeedContent
              feed={feed.feed_info}
              channel_id={feed.channel_id}
              attachmentVideoRef={attachmentVideoRef}
              attachmentAudioRef={attachmentAudioRef}
              // registerWebinar={registerWebinar}
            />
          </div>
        )}

      <div className="community-feed-info-container">
        <div
          className="dashboard-feed-type"
          style={{ backgroundColor: feedLabelColor }}
        >
          {feedType}
        </div>
        &nbsp;&nbsp;
        <div className="feed-date">{feedDate}</div>
      </div>
      {channelType === "community" && (
        <div className="community-feed-info-container">
          <div className="feed-poster-container">
            <img
              alt="profile pic"
              className="comment-image"
              src={feedCreatorProfilePic}
            />

            <Link
              className="feed-poster"
              style={{ color: "#333333", marginLeft: "0.6em" }}
              to={
                feed.post_creator.posted_by
                  ? `/profile/${feed.post_creator.doc_id}`
                  : "#"
              }
            >
              {feedCreator}
            </Link>
          </div>
        </div>
      )}

      {channelType === "community" &&
        feed?.feed_info?.feed_type?.toUpperCase() != "SURVEY" &&
        feed?.feed_info?.feed_type?.toUpperCase() != "EVENT" && (
          <div onClick={() => fullViewRedirect()}>
            <FeedAttachments
              videoRef={attachmentVideoRef}
              audioRef={attachmentAudioRef}
              attachments={feed.feed_info.attachment_details}
            />
          </div>
        )}

      <div className="social-information">
        <div
          className="likes"
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (windowDimensions.width <= 768)
              history.push({
                pathname: `/likes/${feed.feed_info.feed_id}/${feed.channel_id}`,
                state: { background: location },
              });
            else openLikesModal(feed);
          }}
        >
          <p className="people-like" style={{ cursor: "pointer" }}>
            {feed.feed_info?.socialInteraction?.isLike
              ? feed.feed_info?.socialInteraction?.likesCount === 1
                ? `You like this`
                : `You and ${
                    feed.feed_info?.socialInteraction?.likesCount - 1
                  }  people like this `
              : feed.feed_info?.socialInteraction?.likesCount === 0
              ? null
              : `${feed.feed_info?.socialInteraction?.likesCount} people like this `}
          </p>
        </div>
        <div className="views">
          {feed.feed_info?.socialInteraction?.viewCount !== 0 && (
            <p className="people-like">
              {feed.feed_info?.socialInteraction?.viewCount}
              {feed.feed_info?.socialInteraction?.viewCount === 1
                ? " view"
                : " views"}
            </p>
          )}
        </div>
      </div>
      <hr style={{ margin: 0 }} />
      <div className="community-social-interaction">
        <div className="community-social-button">
          {feed.feed_info?.socialInteraction?.isLike ? (
            <img
              style={{ cursor: "pointer" }}
              src={likeSelected}
              onClick={() =>
                updateLikeCount(feed.feed_info.feed_id, feed.channel_id, false)
              }
              alt="Like"
              className="community-social-icons"
            />
          ) : (
            <img
              style={{ cursor: "pointer" }}
              onClick={() =>
                updateLikeCount(feed.feed_info.feed_id, feed.channel_id, true)
              }
              src={likeUnselected}
              className="community-social-icons"
              alt="Like"
            />
          )}
          <span className="community-interaction-like">Like</span>
        </div>
        <div
          className="community-social-button"
          onClick={() => {
            if (windowDimensions.width <= 768)
              history.push({
                pathname: `/comments/${feed.feed_info.feed_id}/${feed.channel_id}`,
                state: {
                  background: location,
                  profile_pic: profile_pic,
                  user_verification_info: user_verification_info,
                  feedContext: feedContext,
                },
              });
            else openFullView(feed);
          }}
        >
          <img
            src={commentUnselected}
            alt="Comment"
            className="community-social-icons"
          />

          <span className="interaction-comment">
            {feed?.feed_info?.socialInteraction.commentsCount === 0
              ? null
              : feed?.feed_info?.socialInteraction.commentsCount}
            &nbsp; Comment
            {feed.feed_info?.socialInteraction?.commentsCount > 1 ? "s" : null}
          </span>
        </div>
        <div className="community-social-button" style={{ width: "15%" }}>
          {/* <img
              src={shareUnselected}
              alt="Share"
              className="community-social-icons"
            />
            <span class="community-interaction-share">5 Shares</span> */}
        </div>
      </div>
      <hr style={{ margin: 0 }}></hr>
      <CommentInput
        profile_pic={profile_pic}
        addComment={addComment}
        feed_id={feed.feed_info?.feed_id}
        channel_id={feed.channel_id}
      />
      {feed?.feed_info?.socialInteraction.commentsCount === 0 ? null : (
        <div className="comment">
          <img
            className="comment-image"
            alt="profile pic"
            src={feedCommentProfilePic}
          />
          <div className="comment-info-container">
            <div className="comment-poster">
              <Link
                style={{ color: "black" }}
                to={`/profile/${feed?.feed_info?.socialInteraction?.comment_info?.user_info?.doc_id}`}
              >
                {feedCommentUserName}
              </Link>
            </div>
            <div className="comment-date">
              {getTimeAndDate(
                feed?.feed_info?.socialInteraction.comment_info.timestamp
              )}
            </div>
            <div className="comment-text">
              {showUnicodeChars(
                feed.feed_info?.socialInteraction?.comment_info.comment_text
              )}
            </div>
            {feed.feed_info?.socialInteraction?.comment_info
              .attachment_details &&
              feed.feed_info?.socialInteraction?.comment_info.attachment_details
                .length !== 0 && (
                <div className="comment-attachments">
                  {feed.feed_info?.socialInteraction?.comment_info.attachment_details.map(
                    (attachment, index) => (
                      <span key={index}>
                        {attachment.attachment_type === "image" && (
                          <img
                            className="attachment"
                            style={{ height: "100%" }}
                            alt="attachment"
                            src={attachment.attachment_original_url}
                          />
                        )}
                        {attachment.attachment_type === "video" && (
                          <video
                            src={attachment.attachment_original_url}
                            className="attachment"
                            style={{ height: "100%" }}
                            controls="controls"
                          />
                        )}
                        {attachment.attachment_type === "audio" && (
                          <ReactAudioPlayer
                            style={{ backgroundColor: "whitesmoke" }}
                            controls
                            style={{ width: "100%" }}
                            src={attachment.attachment_original_url}
                          />
                        )}
                      </span>
                    )
                  )}
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChannelFeed;
