import publicIp from "public-ip";
import S3 from "react-aws-s3";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";

export const getClientIp = async () =>
  await publicIp.v4({
    fallbackUrls: ["https://ifconfig.co/ip"],
  });

export const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

export const getAwsDetails = () => config;

const headers = {
  accept: "application/json",
  "x-time-zone": "+5.30",
  "X-USER-AGENT": "Web",
  // "x-device-id": "a439064bdcb9919f", //browser ip
  // "x-app-version": "61", //no
  // "x-app-channel-name": "ANDROID", //no
  // "x-device-model": "CPH1819", //browser name
  // "x-device-os-version": "10", //browser
  "x-device-id": "911422351920594",
  "x-app-version": "1.0.50",
  "x-app-channel-name": "IOS",
  "x-device-model": "iPhone 4S",
  "x-stay-loggedin": "yes",
  // X_APP_NOTIFICATIONS_ID:
  //   "ctC45M9Nfsc:APA91bGCCB2ZiDhyjRjipvDVW1881oD3RTOWg5BtZ5d3YN88hCXh5drr5v411qnTfLXuQRnI7qrrhFNZHeBCiOOj22voChnjLMWV3DbQVuqk887wzmL0KbJvQDyL6a26bK-h1pOZ9RVm",
  "Content-Type": "application/x-www-form-urlencoded",
};

const baseUrl = process.env.REACT_APP_BASE_URL;

export const profile_pic_placeholder = "images/dummy_profile_image.png";

const noOfDaysForLogin = 5;
export const ttlForDocId = noOfDaysForLogin * 24 * 60 * 60 * 1000;

export const checkExpiry = () => {
  const expiryDate = localStorage.getItem("expiry_date");
  const currentTime = new Date().getTime();

  if (expiryDate === null) return;

  if (expiryDate > currentTime) {
    let newDate = currentTime + ttlForDocId;
    localStorage.setItem("expiry_date", newDate);
    return;
  }

  if (expiryDate <= currentTime) localStorage.clear();
};

export const getDocId = () =>
  localStorage.getItem("doc_id") || sessionStorage.getItem("doc_id");

export const getAuthToken = () =>
  localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token");

export const getEmail = () => localStorage.getItem("email");

export const getIsLoggedIn = () => {
  checkExpiry();
  let DOC_ID = getDocId();
  let AUTH_TOKEN = getAuthToken();
  return DOC_ID === null && AUTH_TOKEN === null ? false : true;
};

export const getHeaders = () => {
  const auth_token = getAuthToken();
  let header = { ...headers };
  let browser = getBrowser();
  const ip_address = localStorage.getItem("ip_address");
  header["x-device-id"] = ip_address;
  header["x-device-model"] = browser.name;
  header["x-device-os-version"] = browser.version;
  if (auth_token) {
    header["x-auth-token"] = auth_token;
  }
  return header;
};

export const formatNumberToDisplay = (number) => {
  if (!number) return "0";
  if (number >= 1000000) return (number / 1000000).toFixed(1) + "M";
  if (number >= 1000) return (number / 1000).toFixed(1) + "K";
  return number.toString();
};

export const renderDays = (days) => {
  if (days.length === 0) return null;
  const dayNames = ["MON", "TUE", "WED", "THURS", "FRI", "SAT", "SUN"];
  let res = dayNames[days[0] - 1];
  let toDay = 0;
  for (let i = 1; i < days.length; i++) {
    const currentDay = days[i];
    const prevDay = days[i - 1];
    if (currentDay - prevDay === 1) {
      toDay = days[i];
      continue;
    }
    if (toDay !== 0) {
      res += ` - ${dayNames[toDay - 1]}`;
      toDay = 0;
    }
    res += `, ${dayNames[currentDay - 1]}`;
  }
  if (toDay !== 0) {
    res += ` - ${dayNames[toDay - 1]}`;
  }
  return res;
};

export const formatTime = (timeLeft) => {
  let seconds = parseInt((timeLeft / 1000) % 60);
  let minutes = parseInt((timeLeft / (1000 * 60)) % 60);

  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `00:${minutes}:${seconds}`;
};

export const getTime = (timestamp) => {
  const date = new Date(timestamp);
  let minutes = date.getMinutes();
  let hours = date.getHours();
  const am_pm = hours >= 12 ? "PM" : "AM";

  minutes = minutes < 10 ? `0${minutes}` : minutes;
  hours = hours > 12 ? hours - 12 : hours;
  return `${hours}:${minutes} ${am_pm}`;
};

export const getDate = (timestamp) => {
  const date = new Date(timestamp);
  const dateString = date.toLocaleDateString("en-GB");

  return dateString;
};

export const getTimeAndDate = (timestamp) => {
  const timeString = getTime(timestamp);
  const dateString = getDate(timestamp);

  return `${timeString}, ${dateString}`;
};

export const getTimeLeft = (timestamp) => {
  const close_date = new Date(timestamp);
  const now = Date.now();
  const time_left = close_date.getTime() - now;
  if (time_left < 0) return;
};

export const getOsInfo = () => {
  let os = "not known";
  if (navigator.userAgent.indexOf("Win") !== -1) os = "Windows OS";

  if (navigator.userAgent.indexOf("Mac") !== -1) os = "MAC";

  if (navigator.userAgent.indexOf("Linux") !== -1) os = "Linux";

  if (navigator.userAgent.indexOf("Android") !== -1) os = "Android";

  if (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  )
    os = "IOS";
  return os;
};

export const getBrowser = () => {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
};

export const logout = async (history) => {
  const res = await fetch(baseUrl + "logout", {
    method: "POST",
    headers: getHeaders(),
  });

  const email = getEmail();
  const phone = localStorage.getItem("phone");
  const ip_address = localStorage.getItem("ip_address");
  const remember = localStorage.getItem("remember");
  localStorage.clear();
  sessionStorage.clear();

  localStorage.setItem("email", email);
  localStorage.setItem("phone", phone);
  localStorage.setItem("ip_address", ip_address);
  localStorage.setItem("remember", remember);

  //Redirecting to login page
  history.push("/login");
};

export const uploadFileToS3 = async (file, newFileName) => {
  const config = getAwsDetails();
  const ReactS3Client = new S3(config);

  const data = await ReactS3Client.uploadFile(file, newFileName);
  return data;
};

export const resolvePromise = async (promise) => {
  try {
    const data = await promise();
    return [data, null];
  } catch (error) {
    console.error(error);
    return [null, error];
  }
};

export const showUnicodeChars = (text) => {
  if (text === "" || !text) return text;
  let res = JSON.stringify(text);
  res = res.replace(/\\\\u/g, "\\u");
  res = JSON.parse(res);
  return res;
};

export const tagColors = {
  JOURNAL: "#f2cf67",
  "CONFERENCE NEWS": "#9bcb68",
  CONFERENCE: "#9bcb68",
  "MONTHLY BRIEFING": "#69cdcd",
  "CONFERENCE HIGHLIGHTS": "#67a6d2",
  INSTITUTIONAL: "#5cb0d5",
  CAPSULES: "#ff9368",
  MATTERS: "#0bc7c2",
  MOMENTS: "#488ef7",
  BYTES: "#3ac36a",
  UPDATE: "#89C659",
  CASE: "#47AFFF",
  "HEALTH TIPS": "#19b867",
  "MEDICAL NEWS": "#917fde",
  SURVEY: "#987EBB",
  "FACILITY UPDATES": "#8d6e63",
  "FACULTY UPDATES": "#ba68c8",
  INITIATIVES: "#afb42b",
  OPPORTUNITIES: "#546e7a",
  COLLABORATION: "#ffb300",
  "EXPRESSION OF INTEREST": "#ff9a76",
  WEBINAR: "#89C659",
  // WEBINAR: "#89C659",
};

export const getFeedTypeColor = (feedType) => {
  return tagColors[feedType] ? tagColors[feedType] : "#888686";
};

export const closeIcon = <img src={close}></img>;
