import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { getHeaders, logout } from "../../utils/utils";

const useChannelsLoader = (user_id, nextFeedsData) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = getHeaders();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [channelsData, setChannelsData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  
  const getChannelsList = async () => {
    setLoading(true);
    setError(false);
    const payload = {
      user_id,     
      tag_id: nextFeedsData.tabId,
      pg_num: nextFeedsData.pageNum
    };
    const res = await fetch(baseUrl + "v1/app/tag/content", {
      method: "POST",
      headers,
      body: "reqData=" + JSON.stringify(payload),
    }).catch((e) => {
      setLoading(false);
      setError(true);
      setHasMore(false);
     // logout(history);
      return;
    });
    const data = await res.json();
    if (payload.pg_num === 0) setChannelsData([]);
    if (data.status === "success") {
      if(data.data.channels){
        setChannelsData((prev) => {
          return [...prev, ...data.data.channels];
        });
       
      setLoading(false);
      setHasMore(data.data.channels.length > 0);
    }
    } else {
      setLoading(false);
      setError(true);
      setHasMore(false);
     // logout(history);
      return;
    }
  };
  useEffect(() => {
    getChannelsList();
  }, [nextFeedsData]);
  return { loading, error, hasMore ,channelsData};
};

export default useChannelsLoader;
