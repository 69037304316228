import { useState, useEffect, useRef, useCallback } from "react";
import { config, getDocId, getHeaders } from "../utils/utils";
import AppBar from "@material-ui/core/AppBar";
import Feeds from "../dashboard/feeds/Feeds";
import { makeStyles } from "@material-ui/core/styles";
import FullViewFeed from "../dashboard/feeds/FullViewFeed";
import useFeedLoader from "../dashboard/hooks/useFeedsLoader";
import toast from "react-hot-toast";
import { Modal } from "react-responsive-modal";
import close from "../assets/dashboard_icons/Icon ionic-ios-close-circle.png";
import Progressbar from "../progressbar";
import send from "../assets/Icons/Icon feather-arrow-left@2x.png";
import S3 from "react-aws-s3";
import certificate from "../assets/dashboard_icons/Group 948.png";
import Likes from "../dashboard/social-interaction/Likes";
import CommentInput from "../dashboard/social-interaction/CommentInput";
import CommentsList from "../dashboard/social-interaction/CommentsList";
import useCommunityFeedLoader from "../dashboard/hooks/useCommunityFeedLoader";
import SubCategoryList from "../discoveryCategory/SubCategoryList";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: 0,
      padding: "15px",
      marginBottom: "30px",
      webkitBoxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
      boxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
    },
    "& .MuiTypography-body1": {
      font: "normal normal normal 20px/20px Roboto",
      fontWeight: 400,
    },
    "& .PrivateTabIndicator-colorSecondary-4": {
      backgroundColor: "#1e8a79",
      border: "2px solid",
    },
    "& .PrivateTabIndicator-colorSecondary-6": {
      backgroundColor: "#2a8f80",
    },

    "& .MuiTabPanel-root": {
      padding: "2px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },

    "& .MuiBox-root": {
      padding: "0px",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: "#2a8f80",
      height: "4px",
    },
    "& .MuiTabPanel-root": {
      padding: "2px",
    },
    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      /* font-size: 11px; */
      font: "13px / 40px Roboto",
    },
    "& .MuiTab-root": {
      minWidth: "127px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  test: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-around",
    },
    "& .MuiAccordion-rounded:last-child": {
      borderRadius: 0,
      padding: "15px",
      marginBottom: "30px",
      webkitBoxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
      boxShadow: "0px 1px 15px 0px rgb(51 51 51 / 20%)",
    },
    "& .MuiTypography-body1": {
      font: "normal normal normal 20px/20px Roboto",
      fontWeight: 400,
    },
    "& .PrivateTabIndicator-colorSecondary-4": {
      backgroundColor: "#1e8a79",
      border: "2px solid",
    },
    "& .PrivateTabIndicator-colorSecondary-6": {
      backgroundColor: "#2a8f80",
    },

    "& .MuiTabPanel-root": {
      padding: "2px",
    },
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-around",
    },

    "& .MuiBox-root": {
      padding: "0px",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: "#2a8f80",
      height: "4px",
    },
    "& .MuiTabPanel-root": {
      padding: "2px",
    },

    "& .MuiTab-wrapper": {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      /* font-size: 11px; */
      font: "13px / 40px Roboto",
    },
    "& .MuiTab-root": {
      minWidth: "27px",
    },

    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
// const KnowledgePosts = ({feeds , setFeeds, profile_pic ,updateLikeCount,
//   addComment,
//   openFullView,
//   commentsListInfo,
//   openLikesModal,
//   submitSurvey,
//   registerWebinar,
//   is_user_verified,
//   feedsErrorMsg
//  }) => {

const KnowledgePosts = ({
  feeds,
  setFeeds,
  profile_pic,
  updateLikeCount,
  addComment,
  submitSurvey,
  setUpdateCategoryUnreadCount,
  commentsListInfo,
  openLikesModal,
  registerWebinar,
  user_verification_info,
  feedsErrorMsg,
  deleteComment,
  updateComment,
  updateViewCount,
  categoryId,
  selectedCategoryData,
  categoryNameLabel,
}) => {
  var url =
    "https://stackblitz.com/files/react-spinner-sample/github/RahmanM/react-spinner-sample/master/loading.gif";
  const [loaderSpinner, setloaderSpinner] = useState(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const doc_id = getDocId();
  const headers = getHeaders();
  const classes = useStyles();
  const [value, setValue] = useState("1");
  const [tabid, setTabid] = useState(1);
  const [checked, setChecked] = useState(false);
  const changeHeader = true;
  const handleClick = (event) => {
    setChecked(!checked);
  };
  const [id, setId] = useState();

  const handleChange = (event, newValue) => {
    setTabid(newValue);
    setValue(newValue);
  };

  const [modalData, setModalData] = useState({});
  const fullViewFeedRef = useRef(null);
  const [openFullView, setOpenFullView] = useState(false);
  const handleOpenFullView = (feed) => {
    // window.scrollTo(0, 0);
    setModalData(feed);
    setOpenFullView(true);
  };
  const handleCloseFullView = () => {
    setOpenFullView(false);
  };
  const [subCategoryId, setSubCategoryId] = useState(undefined);

  const [nextFeedsData, setNextFeedsData] = useState({
    pageNum: 0,
    tabId: 1,
  });

  const { loading, error, channels, hasMore, categoryList } =
    useCommunityFeedLoader(doc_id, nextFeedsData, setFeeds, subCategoryId);

  const observer = useRef(null);

  const loader = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setNextFeedsData((prev) => ({
            ...prev,
            pageNum: prev.pageNum + 1,
            // lastFeedTime: feeds[feeds.length - 1]?.feed_info?.updated_time,
          }));
        }
      });
      if (node) observer.current.observe(node);
    },

    [loading, hasMore]
  );

  const closeIcon = <img src={close}></img>;

  return (
    <>
      {categoryId === 0 && (
        <>
          <Feeds
            feeds={feeds}
            profile_pic={profile_pic}
            updateLikeCount={updateLikeCount}
            addComment={addComment}
            submitSurvey={submitSurvey}
            openFullView={handleOpenFullView}
            commentsListInfo={commentsListInfo}
            openLikesModal={openLikesModal}
            channels={channels}
            registerWebinar={registerWebinar}
            user_verification_info={user_verification_info}
          />
          {loading && (
            <div>
              {" "}
              <Progressbar
                show={loaderSpinner}
                imageUrl={url}
                height="90"
                width="90"
                alignment="middle"
                alttext="Loading..."
              />
            </div>
          )}
          {error && <div>{feedsErrorMsg}</div>}
          <div ref={loader} />
        </>
      )}

      {categoryId != 0 && (
        <SubCategoryList
          user_verification_info={user_verification_info}
          profile_pic={profile_pic}
          channels={channels}
          categoryId={categoryId}
          selectedCategoryData={selectedCategoryData}
          categoryNameLabel={categoryNameLabel}
          setUpdateCategoryUnreadCount={setUpdateCategoryUnreadCount}
          changeHeader={changeHeader}
        />
      )}

      <Modal
        open={openFullView}
        blockScroll={true}
        style={customStyles}
        onClose={handleCloseFullView}
        closeIcon={closeIcon}
        classNames={{ modal: "feedModel" }}
        ref={fullViewFeedRef}
      >
        <FullViewFeed
          profile_pic={profile_pic}
          close={handleCloseFullView}
          feed={modalData}
          setFeeds={setFeeds}

          addComment={addComment}
          updateLikeCount={updateLikeCount}
          submitSurvey={submitSurvey}
          updateViewCount={updateViewCount}
          channels={channels}
          deleteComment={deleteComment}
          updateComment={updateComment}
          openLikesModal={openLikesModal}
          openCommentMobileView={commentsListInfo}
          registerWebinar={registerWebinar}
          user_verification_info={user_verification_info}
        ></FullViewFeed>
      </Modal>
    </>
  );
};

export default KnowledgePosts;
